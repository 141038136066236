import React from "react";
import styles from "./styles.module.scss";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import CategoryCard from "./CategoryCard";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const SortCategories = ({
  isFetchingMenuCategories,
  sortMenuCategories,
  setSortMenuCategories,
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const getCategoryPosition = (id) =>
    sortMenuCategories?.findIndex((item) => item.id === id);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id === over.id) return;

    setSortMenuCategories((categories) => {
      const originalPos = getCategoryPosition(active.id);
      const newPos = getCategoryPosition(over.id);

      return arrayMove(categories, originalPos, newPos);
    });
  };

  return (
    <div className={styles.sortContainer}>
      <secondaryComponents.LoaderHelper
        classNames={"mt-5"}
        isLoading={isFetchingMenuCategories || sortMenuCategories.length === 0}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragEnd={handleDragEnd}
        >
          <div className={styles.categoryContainer}>
            <SortableContext
              items={sortMenuCategories}
              strategy={verticalListSortingStrategy}
            >
              {sortMenuCategories?.map((item) => (
                <CategoryCard
                  key={item.id}
                  id={item.id}
                  text={item.category.name}
                />
              ))}
            </SortableContext>
          </div>
        </DndContext>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default SortCategories;
