const jobTitleOptions = [
  "Chief Executive Officer (CEO)",
  "Managing Director (MD)",
  "Chief Operations Officer (COO)",
  "Head of Operations",
  "Business Development Manager",
  "Sales Executive",
  "Store Manager",
  "Product Manager",
  "Product Markeing Manager",
  "Research Analyst",
  "Data Analyst",
  "Sales Manager",
  "Business Developement Manager",
  "Social Media Manager",
  "Sofware Engineer",
  "Product Designer",
  "Product Researcher",
  "Product Specialist",
  "Data Engineer",
  "Business Developer",
  "Manager",
  "Product Analyst",
  "Product Specialist",
  "Brand Manager",
  "Others",
];
export default jobTitleOptions;
