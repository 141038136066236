import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { pathConstants } from "../../../../../routes/pathContants";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { useGetGiftCards } from "../../../../../hooks/api/queries/useLoyalty";

const AllGiftCards = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useGetGiftCards({ queries: "" });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - All Giftcards"
      description="View giftcards created"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div className={globalStyles.pageColumnGaps}>
          <div>
            <h4 className="mb-0">Giftcard</h4>
            <p style={{ maxWidth: "600px" }}>
              Personalize your giftcards and control their status and
              availability.
            </p>
          </div>

          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Loyalty"}
            permissionChildKey={"can_view_and_create_gift_cards"}
          >
            <primaryComponents.Button
              onClick={() => navigate(pathConstants.LOYALTY_CREATE_GIFTCARD)}
              classNames="btn btn--primary ml-auto"
            >
              <span className="mr-2" style={{ fontSize: "24px" }}>
                +
              </span>{" "}
              Create Giftcard
            </primaryComponents.Button>
          </secondaryComponents.NavigateWithPermission>

          <secondaryComponents.LoaderHelper
            classNames={"mt-4"}
            isLoading={isLoading}
          >
            <secondaryComponents.GiftCardList giftcards={data?.results} />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllGiftCards;
