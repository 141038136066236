import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const giftcardSchema = yup.object({
  giftcardName: yup
    .string()
    .max(30, "Maximum of 30 charactyers is allowed")
    .required("Giftcard name is required"),
  amount: yup
    .number()
    .typeError("Invalid format")
    .required("amount is required"),
  validity_preiod: yup.string().required("validity period is required"),
  showNairaEquivalent: yup.boolean(),
});

export const useGiftcardFormValidation = ({
  giftcardName,
  amount,
  validity_preiod,
  showNairaEquivalent,
}) => {
  return useForm({
    resolver: yupResolver(giftcardSchema),
    reValidateMode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      giftcardName,
      amount,
      validity_preiod,
      showNairaEquivalent,
    },
  });
};
