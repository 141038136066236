import { useState } from "react";
import { useUpdateOrderDate } from "../../../../hooks/api/mutations/useOrder";
import primaryComponents from "../../../primaryComponents";
import Validation from "./Validation";
import { getDateInUTCFormat } from "../../../../helpers";

const UpdateOrderDate = ({ orderId, closeModal }) => {
  const [formDetails, setFormDetails] = useState({
    date: "",
    time: "",
  });
  const { handleUpdateOrderDate, isLoading: isUpdatingOrderDate } =
    useUpdateOrderDate({ closeModal });

  const updateOrderDate = () => {
    handleUpdateOrderDate({
      id: orderId,
      data: {
        new_order_date: getDateInUTCFormat({
          date: formDetails?.date,
          time: formDetails?.time,
        }),
      },
    });
  };

  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation({ formDetails });
  return (
    <form className="p-3" onSubmit={handleSubmit(updateOrderDate)}>
      <h4 className="mb-4 text-center">Edit Date & Time</h4>

      <div>
        <label>
          <span className="error--text">*</span>Date / Time
        </label>
        <div className="d-flex">
          <primaryComponents.DateInputV2
            name="date"
            control={control}
            value={formDetails.date}
            isRequired={true}
            errorMessage={errors.date}
            onChange={({ target: { value } }) => {
              setFormDetails((prevState) => ({
                ...prevState,
                date: value,
              }));
            }}
            onKeyUp={() => errors.date !== undefined && trigger("date")}
            register={register}
          />
          <primaryComponents.TimeInputV2
            name="time"
            control={control}
            register={register}
            value={formDetails.time}
            isRequired={true}
            errorMessage={errors.time}
            onChange={({ target: { value } }) => {
              setFormDetails((prevState) => ({
                ...prevState,
                time: value,
              }));
            }}
            onKeyUp={() => errors.time !== undefined && trigger("time")}
          />
        </div>
      </div>

      <div
        className="mt-4 d-flex justify-content-between"
        style={{ gap: "10px" }}
      >
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          type="submit"
          isDisabled={isUpdatingOrderDate}
          isLoading={isUpdatingOrderDate}
        >
          update
        </primaryComponents.Button>
      </div>
    </form>
  );
};
export default UpdateOrderDate;
