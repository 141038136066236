import "./StockUnitInput.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import StockUnitInputLogic from "./StockUnitInputLogic";

const StockUnitInput = ({
  label,
  name = "stock-unit",
  onChange = () => {},
  errorMessage,
  defaultUnit = null,
  isRequired = false,
  value = "",
  ...rest
}) => {
  const {
    isOpen,
    selectedUnit,
    stockUnits,
    inputValue,
    setInputValue,
    handleToggle,
    setIsOpen,
    setSelectedUnit,
  } = StockUnitInputLogic({ defaultUnit, value });
  const ref = useRef();

  useEffect(() => {
    const toggleOptions = (event) => {
      if (event.key === "Escape") setIsOpen(false);
      if (ref.current && !ref.current?.contains(event.target)) setIsOpen(false);
    };

    window.addEventListener("mousedown", toggleOptions);
    window.addEventListener("keydown", toggleOptions);

    return () => {
      window.removeEventListener("keydown", toggleOptions);
      window.removeEventListener("mousedown", toggleOptions);
    };
  }, []);

  return (
    <div ref={ref} className="stock-unit-input-field">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div className="stock-unit-container">
        <input
          id={name}
          name={name}
          value={inputValue}
          onChange={({ target: { value } }) => {
            setInputValue(value);
            onChange({
              value,
              unitId: selectedUnit?.id || null,
              activeUnitDetails: selectedUnit,
            });
          }}
          type="numebr"
          {...rest}
        />
        <div className="unit-option" onClick={() => handleToggle()}>
          <span>{selectedUnit?.unit || ""}</span>
          <FontAwesomeIcon
            icon={["fas", isOpen ? "angle-up" : "angle-down"]}
            style={{ fontSize: "12px" }}
            className="ml-2"
          />
        </div>
      </div>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.3 },
        }}
        className={`stock-unit-list ${isOpen ? "active" : ""}`}
      >
        {stockUnits.map((stockUnitDetails) => (
          <li
            className={`${
              selectedUnit?.id === stockUnitDetails.id ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedUnit(stockUnitDetails);
              onChange({
                value: inputValue,
                unitId: stockUnitDetails?.id,
                activeUnitDetails: stockUnitDetails,
              });
              setIsOpen(false);
            }}
            key={stockUnitDetails?.id}
          >
            <span>{stockUnitDetails.name}</span>
            <span>({stockUnitDetails.unit})</span>
          </li>
        ))}
      </motion.ul>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
        data-testid="error-message"
      >
        {errorMessage !== undefined && (
          <>
            <FontAwesomeIcon
              icon={["fas", "exclamation-circle"]}
              className="mr-2"
              style={{ fontSize: "13px" }}
            />
            {errorMessage !== undefined && <span>{errorMessage.message}</span>}
          </>
        )}
      </p>
    </div>
  );
};

export default StockUnitInput;
