import { useTable } from "react-table";
import { useMemo } from "react";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { pathConstants } from "../../../routes/pathContants";
import { convertToTilteCase, removeSpecialCharacter } from "../../../helpers";
import { useNavigate } from "react-router-dom";

const AllStockTransferTableLogic = ({ stockHistory }) => {
  const navigate = useNavigate();
  const statusMap = {
    APPROVED: "success--squared",
    AWAITING_APPROVAL: "pending--squared",
    REVIEW: "pending--squared",
    DECLINED: "failed--text",
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Transferred From",
        accessor: "transfer_from_store",
        Cell: ({ cell: { value } }) => (value ? value?.name : "N/A"),
      },
      {
        Header: "Transferred To",
        accessor: "transfer_to_store",
        Cell: ({ cell: { value } }) => (value ? value?.name : "N/A"),
      },
      {
        Header: "Initiated By",
        accessor: "initiated_by",
        Cell: ({ cell: { value } }) => (value ? value?.first_name : "N/A"),
      },
      {
        Header: "Approved by",
        accessor: "reviewed_by",
        Cell: ({ cell: { value } }) => (value ? value?.first_name : "N/A"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`${statusMap[value]} text-capitalize p-2`}>
            {convertToTilteCase(removeSpecialCharacter(value))}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <div className="d-flex" style={{ gap: "5px" }}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_create_or_update_stock_transfer"
            >
              <primaryComponents.Button
                classNames={"btn btn--outline smallBtn"}
                isDisabled={row?.original?.status === "APPROVED"}
                onClick={() => {
                  navigate(
                    pathConstants.INVENTORY_STOCK_TRANSFER_EDIT({
                      id: row?.original?.id,
                    }),
                  );
                }}
              >
                Edit
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_stock_transfer"
            >
              <primaryComponents.Button
                classNames="btn btn--primary"
                style={{
                  height: "30px",
                  fontSize: "12px",
                  padding: "0 15px",
                }}
                onClick={() => {
                  navigate(
                    pathConstants.INVENTORY_STOCK_TRANSFER_DETAILS({
                      id: row?.original?.id,
                    }),
                  );
                }}
              >
                Review
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: stockHistory });
  return {
    instance,
  };
};

export default AllStockTransferTableLogic;
