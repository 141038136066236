import "./TerminalRequests.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";

const TerminalRequests = () => {
  const { setTerminalHeader } = useOutletContext();
  useEffect(() => {
    setTerminalHeader({
      title: "Terminal Request",
      description:
        "View the list of terminals that your business has requested for",
    });
  }, []);
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Terminal"
      description="View pending terminal to be approved"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0 }}
        >
          <secondaryComponents.TerminalRequestTable />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default TerminalRequests;
