import styles from "./SubscriptionPeriod.module.scss";

const SubscriptionPeriod = ({ subscriptionPeriod, setSubscriptionPeriod }) => {
  return (
    <div className={styles.subscriptionPeriod}>
      <div
        className={`${styles.subscriptionPeriod__item} ${
          subscriptionPeriod === "ONE_MONTH" ? styles.active : ""
        }`}
        onClick={() => setSubscriptionPeriod("ONE_MONTH")}
      >
        <h5>Monthly</h5>
      </div>
      <div
        className={`${styles.subscriptionPeriod__item} ${
          subscriptionPeriod === "ONE_YEAR" ? styles.active : ""
        }`}
        onClick={() => setSubscriptionPeriod("ONE_YEAR")}
      >
        <h5>
          Annually -{" "}
          <span className="error--text small-text-size">Save 20%</span>
        </h5>
      </div>
    </div>
  );
};
export default SubscriptionPeriod;
