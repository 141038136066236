import { useMutation, useQueryClient } from "react-query";
import {
  activateDeliveryCheckout,
  activateStorefrontPayment,
  orderMenuCategoryList,
  updateStorefrontColor,
  uploadLogo,
} from "../../../../../../../../../services/inventory";
import { useContext, useEffect, useRef, useState } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams, useSearchParams } from "react-router-dom";
import { useColor } from "react-color-palette";

export const useBranding = ({ menuProperties }) => {
  const { menuId } = useParams();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const menu_id = searchParams.get("menu_id");

  const [showSettings, setShowSettings] = useState(false);
  const [showBrand, setShowBrand] = useState(false);
  const [showSort, setShowSort] = useState(false);

  const fileRef = useRef(null);

  const [color, setColor] = useColor("#7647ee");
  const [showColorPalette, setShowColorPalette] = useState(null);

  const [primaryColor, setPrimaryColor] = useState(
    menuProperties?.primary_colour ? menuProperties?.primary_colour : "#7647ee",
  );
  const [secondaryColor, setSecondaryColor] = useState(
    menuProperties?.secondary_colour
      ? menuProperties?.secondary_colour
      : "#fef7eb",
  );

  const [selectedLogo, setSelectedLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState(null);

  // useEffect(() => {
  //   queryClient.invalidateQueries(["getMenusDetails"]);
  // }, [queryClient]);

  useEffect(() => {
    if (menuProperties) {
      setPrimaryColor(
        menuProperties.primary_colour
          ? menuProperties.primary_colour
          : "#7647ee",
      );
      setSecondaryColor(
        menuProperties.secondary_colour
          ? menuProperties.secondary_colour
          : "#fef7eb",
      );
      setLogoUrl(menuProperties.logo_url);
    }
  }, [menuProperties]);

  const { mutate: mutateActivate } = useMutation({
    mutationFn: () => activateStorefrontPayment(menuId ? menuId : menu_id),
    onSuccess: (data) => {
      triggerToast(data?.data?.detail, "success");
      queryClient.invalidateQueries(["getMenusDetails"]);
    },
  });

  const { mutate: mutateActivateDeliveryCheckout } = useMutation({
    mutationFn: () => activateDeliveryCheckout(menuId ? menuId : menu_id),
    onSuccess: (data) => {
      triggerToast(data?.data?.detail, "success");
      queryClient.invalidateQueries(["getMenusDetails"]);
    },
  });

  const { isLoading: isLoadingSortCategories, mutate: mutateSortCategories } =
    useMutation({
      mutationFn: (data) =>
        orderMenuCategoryList({ id: menuId ? menuId : menu_id, data }),
      onSuccess: () => {
        triggerToast("Categories sorted successfully", "success");
        setShowSort((prev) => !prev);
      },
    });

  const { isLoading: isLoadingStorefrontColor, mutate: mutateStorefrontColor } =
    useMutation({
      mutationFn: (data) => updateStorefrontColor(data),
      onSuccess: () => {
        triggerToast("Storefront brand updated successfully", "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
        setShowBrand((prev) => !prev);
      },
    });

  const { mutate: mutateUploadLogo } = useMutation({
    mutationFn: (data) => uploadLogo(data),
    onSuccess: (data) => {
      setLogoUrl(data?.data?.data?.url);
    },
  });

  return {
    isLoadingSortCategories,
    isLoadingStorefrontColor,
    menuId,
    menu_id,
    fileRef,
    showSettings,
    showBrand,
    showSort,
    color,
    showColorPalette,
    primaryColor,
    secondaryColor,
    selectedLogo,
    logoUrl,
    setShowSettings,
    setShowBrand,
    setShowSort,
    setColor,
    setShowColorPalette,
    setPrimaryColor,
    setSecondaryColor,
    setSelectedLogo,
    mutateActivate,
    mutateActivateDeliveryCheckout,
    mutateSortCategories,
    mutateStorefrontColor,
    mutateUploadLogo,
    triggerToast,
  };
};
