// import and export all data here
import businessSizeOptions from "./businessSizeOptions";
import jobTitleOptions from "./jobTitleOptions";
import usersRole from "./usersRole";
import campaignObjectives from "./campaignObjectives";
import brandCategories from "./brandCategories";
import referralOptions from "./referralOptions";

const localData = {
  businessSizeOptions: businessSizeOptions,
  jobTitleOptions: jobTitleOptions,
  usersRole: usersRole,
  campaignObjectives: campaignObjectives,
  brandCategories: brandCategories,
  referralOptions,
};

export default localData;
