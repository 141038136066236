import httpClient from "../axiosBase/axiosHandler";

export const addInvoiceCredit = (data, id) => {
  return httpClient.patch(`/invoices/${id}/add_invoice_payment/`, data);
};

export const getSupplierInvoices = (supplierId, query = "") => {
  return httpClient.get(`/invoices/?supplier_id=${supplierId}&${query}`);
};

export const getInvoiceDetails = (id) => {
  return httpClient.get(`/invoices/${id}/`);
};
