import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ToastContext } from "../../../../../../../hooks/context";
import {
  createPaymentGateway,
  editPaymentGateway,
  getPaymentGatewayDetail,
} from "../../../../../../../services/inventory";
import { useNavigate, useParams } from "react-router-dom";

export const usePaymentSetup = () => {
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { paymentId } = useParams();

  const [selectPayment, setSelectPayment] = useState("Paystack");
  const [paystackSecretKey, setPaystackSecretKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");

  const [showYouTubeModal, setShowYouTubeModal] = useState(false);

  const { isLoading: isLoadingPaymentGateway, data: paymentGateway } = useQuery(
    ["payment-gateway-detail", paymentId],
    () => getPaymentGatewayDetail(paymentId).then((res) => res.data?.data),
    {
      enabled: paymentId ? true : false,
    },
  );

  const {
    isLoading,
    mutate,
    data: dataCreatePaymentGateway,
  } = useMutation({
    mutationFn: (data) => createPaymentGateway(data),
    onSuccess: (data) => {
      triggerToast(
        `Webhook URL generated successfully. 'Copy URL' and follow the setup guide to setup your webhook url on your ${data.data.data.type} account`,
        "success",
      );
      // navigate(-1);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const {
    isLoading: isLoadingEditPaymentGateway,
    mutate: mutateEditPaymentGateway,
  } = useMutation({
    mutationFn: (data) => editPaymentGateway({ paymentId, data }),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      navigate(-1);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleSave = () => {
    if (paymentId) {
      if (
        selectPayment === "Paystack"
          ? paystackSecretKey.includes("*")
          : stripeSecretKey.includes("*")
      ) {
        return triggerToast(
          "Payment secret key includes '***'. Enter a valid payment secret key",
        );
      }
      mutateEditPaymentGateway({
        secret_key:
          selectPayment === "Paystack" ? paystackSecretKey : stripeSecretKey,
        type: selectPayment.toUpperCase(),
      });
    } else {
      mutate({
        secret_key:
          selectPayment === "Paystack" ? paystackSecretKey : stripeSecretKey,
        type: selectPayment.toUpperCase(),
      });
    }
  };

  useEffect(() => {
    if (paymentGateway) {
      if (paymentGateway.type === "PAYSTACK") {
        setSelectPayment("Paystack");
        setPaystackSecretKey(paymentGateway.secret_key);
      } else {
        setSelectPayment("Stripe");
        setStripeSecretKey(paymentGateway.secret_key);
      }
    }
  }, [paymentGateway]);

  return {
    isLoading,
    isLoadingPaymentGateway,
    paymentGateway,
    dataCreatePaymentGateway,
    isLoadingEditPaymentGateway,
    paystackSecretKey,
    stripeSecretKey,
    selectPayment,
    showYouTubeModal,
    setShowYouTubeModal,
    setPaystackSecretKey,
    setStripeSecretKey,
    setSelectPayment,
    handleSave,
  };
};
