import { useState } from "react";

const useWalletOptionLogic = ({ onChange }) => {
  const [walletOption, setWalletOption] = useState(null);

  const handleWalletOptionChange = (event) => {
    setWalletOption(event.target.value);
    onChange(event.target.value);
  };

  return {
    walletOption,
    handleWalletOptionChange,
  };
};

export default useWalletOptionLogic;
