export const ScanIcon = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
    >
      <path
        d="M11.3335 11.3335L14.6668 14.6668L11.3335 11.3335ZM13.0002 7.16683C13.0002 10.3885 10.3885 13.0002 7.16683 13.0002C3.94517 13.0002 1.3335 10.3885 1.3335 7.16683C1.3335 3.94517 3.94517 1.3335 7.16683 1.3335C10.3885 1.3335 13.0002 3.94517 13.0002 7.16683Z"
        stroke={color ? color : "#7647ee"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
