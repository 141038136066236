export const ReconciliationIcon = ({ width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.50024 22.9999H18.5002C19.6048 22.9999 20.5002 22.0598 20.5002 20.8999V4.09994C20.5002 2.94014 19.6048 1.99994 18.5002 1.99994H6.50024C5.39567 1.99994 4.50024 2.94014 4.50024 4.09994V20.8999C4.50024 22.0598 5.39567 22.9999 6.50024 22.9999Z"
        stroke="white"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.45386 19.5849H8.88555H10.3172"
        stroke="white"
        strokeWidth="0.53312"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1325 20.9152V18.0518M14.7008 19.5985H17.5642"
        stroke="white"
        strokeWidth="0.53312"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4881 9.3266C13.4881 9.87888 13.0404 10.3266 12.4881 10.3266C11.9358 10.3266 11.4881 9.87888 11.4881 9.3266C11.4881 8.77431 11.9358 8.3266 12.4881 8.3266C13.0404 8.3266 13.4881 8.77431 13.4881 9.3266ZM9.48791 9.32667C9.48791 9.87895 9.0402 10.3267 8.48791 10.3267C7.93563 10.3267 7.48792 9.87895 7.48792 9.32667C7.48792 8.77438 7.93563 8.32667 8.48791 8.32667C9.0402 8.32667 9.48791 8.77438 9.48791 9.32667ZM9.48802 12.3266C9.48802 12.8789 9.0403 13.3266 8.48802 13.3266C7.93573 13.3266 7.48802 12.8789 7.48802 12.3266C7.48802 11.7743 7.93573 11.3266 8.48802 11.3266C9.0403 11.3266 9.48802 11.7743 9.48802 12.3266ZM8.51208 16.0868C9.06437 16.0868 9.51208 15.6391 9.51208 15.0868C9.51208 14.5345 9.06437 14.0868 8.51208 14.0868C7.9598 14.0868 7.51208 14.5345 7.51208 15.0868C7.51208 15.6391 7.9598 16.0868 8.51208 16.0868ZM13.4881 12.3266C13.4881 12.8789 13.0404 13.3266 12.4881 13.3266C11.9358 13.3266 11.4881 12.8789 11.4881 12.3266C11.4881 11.7743 11.9358 11.3266 12.4881 11.3266C13.0404 11.3266 13.4881 11.7743 13.4881 12.3266ZM12.5122 16.0868C13.0644 16.0868 13.5122 15.6391 13.5122 15.0868C13.5122 14.5345 13.0644 14.0868 12.5122 14.0868C11.9599 14.0868 11.5122 14.5345 11.5122 15.0868C11.5122 15.6391 11.9599 16.0868 12.5122 16.0868ZM17.4877 12.3266C17.4877 12.8789 17.04 13.3266 16.4877 13.3266C15.9355 13.3266 15.4877 12.8789 15.4877 12.3266C15.4877 11.7743 15.9355 11.3266 16.4877 11.3266C17.04 11.3266 17.4877 11.7743 17.4877 12.3266ZM16.5118 16.0868C17.0641 16.0868 17.5118 15.6391 17.5118 15.0868C17.5118 14.5345 17.0641 14.0868 16.5118 14.0868C15.9595 14.0868 15.5118 14.5345 15.5118 15.0868C15.5118 15.6391 15.9595 16.0868 16.5118 16.0868ZM16.4877 10.3266C17.04 10.3266 17.4877 9.87888 17.4877 9.3266C17.4877 8.77431 17.04 8.3266 16.4877 8.3266C15.9355 8.3266 15.4877 8.77431 15.4877 9.3266C15.4877 9.87888 15.9355 10.3266 16.4877 10.3266Z"
        fill="white"
      />
      <rect x="4.09998" y="6" width="16.8" height="1.2" rx="0.6" fill="white" />
    </svg>
  );
};
