import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ToastContext } from "../../../../../../../../../../hooks/context";
import {
  activatePayWithPaystack,
  activatePayWithStripe,
  createPaymentGateway,
  editPaymentGateway,
  getPaymentGatewayDetail,
} from "../../../../../../../../../../services/inventory";
import { useOutletContext } from "react-router-dom";

export const usePaymentSetup = ({
  paymentId,
  setShowSetupStripe,
  setShowSetupPaystack,
}) => {
  const { menuProperties } = useOutletContext();
  const queryClient = useQueryClient();
  const triggerToast = useContext(ToastContext);

  const [paystackSecretKey, setPaystackSecretKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [saveAndActivate, setSaveAndActivate] = useState(false);

  const [showYouTubeModal, setShowYouTubeModal] = useState(false);

  const { isLoading: isLoadingPaymentGateway, data: paymentGateway } = useQuery(
    ["payment-gateway-detail", paymentId],
    () => getPaymentGatewayDetail(paymentId).then((res) => res.data?.data),
    {
      enabled: paymentId ? true : false,
    },
  );

  const { isLoading: isLoadingPayWithPaystack, mutate: mutatePayWithPaystack } =
    useMutation({
      mutationFn: () => activatePayWithPaystack(menuProperties?.id),
      onSuccess: (data) => {
        triggerToast(data.data.detail, "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
        setShowSetupPaystack(null);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const { isLoading: isLoadingPayWithStripe, mutate: mutatePayWithStripe } =
    useMutation({
      mutationFn: () => activatePayWithStripe(menuProperties?.id),
      onSuccess: (data) => {
        triggerToast(data.data.detail, "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
        setShowSetupStripe(null);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const {
    isLoading,
    mutate,
    data: dataCreatePaymentGateway,
  } = useMutation({
    mutationFn: (data) => createPaymentGateway(data),
    onSuccess: (data) => {
      triggerToast(
        `Webhook URL generated successfully. 'Copy URL' and follow the setup guide to setup your webhook url on your ${data.data.data.type} account`,
        "success",
      );
      queryClient.invalidateQueries(["payment-gateways"]);
      queryClient.invalidateQueries(["getMenusDetails"]);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const {
    isLoading: isLoadingEditPaymentGateway,
    mutate: mutateEditPaymentGateway,
  } = useMutation({
    mutationFn: (data) => editPaymentGateway({ paymentId, data }),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      if (setShowSetupPaystack) {
        if (saveAndActivate && !menuProperties?.pay_with_paystack) {
          mutatePayWithPaystack();
        } else {
          setShowSetupPaystack(null);
        }
      } else {
        if (saveAndActivate && !menuProperties?.pay_with_stripe) {
          mutatePayWithStripe();
        } else {
          setShowSetupStripe(null);
        }
      }
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleSave = () => {
    if (paymentId) {
      if (
        setShowSetupPaystack
          ? paystackSecretKey.includes("*")
          : stripeSecretKey.includes("*")
      ) {
        return triggerToast(
          "Payment secret key includes '***'. Enter a valid payment secret key",
        );
      }
      mutateEditPaymentGateway({
        secret_key: setShowSetupPaystack ? paystackSecretKey : stripeSecretKey,
        type: setShowSetupPaystack ? "PAYSTACK" : "STRIPE",
      });
    } else {
      if (dataCreatePaymentGateway) {
        if (setShowSetupPaystack) {
          return triggerToast("Paystack payment gateway already exists");
        } else if (setShowSetupStripe) {
          return triggerToast("Stripe payment gateway already exists");
        }
      } else {
        mutate({
          secret_key: setShowSetupPaystack
            ? paystackSecretKey
            : stripeSecretKey,
          type: setShowSetupPaystack ? "PAYSTACK" : "STRIPE",
        });
      }
    }
  };

  useEffect(() => {
    if (paymentGateway) {
      if (paymentGateway.type === "PAYSTACK") {
        setPaystackSecretKey(paymentGateway.secret_key);
      } else {
        setStripeSecretKey(paymentGateway.secret_key);
      }
    }
  }, [paymentGateway]);

  return {
    isLoading,
    isLoadingPaymentGateway,
    isLoadingPayWithPaystack,
    isLoadingPayWithStripe,
    paymentGateway,
    dataCreatePaymentGateway,
    isLoadingEditPaymentGateway,
    paystackSecretKey,
    stripeSecretKey,
    showYouTubeModal,
    setShowYouTubeModal,
    setPaystackSecretKey,
    setStripeSecretKey,
    setSaveAndActivate,
    handleSave,
  };
};
