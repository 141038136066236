import secondaryComponents from "../../../../components/secondaryComponents";
import useEditStoreDetails from "./useEditStoreDetails";
import styles from "./EditStoreDetails.module.scss";

const EditStoreDetails = () => {
  const { data, isFetchingStoreInfo } = useEditStoreDetails();

  return (
    <div className={styles.editStoreDetails}>
      <secondaryComponents.LoaderHelper
        isLoading={isFetchingStoreInfo}
        classNames={"mt-3"}
      >
        <secondaryComponents.StoreForm storeInfo={data} isEditMode={true} />
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default EditStoreDetails;
