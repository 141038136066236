import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import { Controller } from "react-hook-form";
// import Icon from "../../../../../assets/icons";
import Validation from "./Validation";
import useCheckoutForm from "./useCheckoutModalForm";
import {
  ChevronLeftArrow,
  OrderNoteIcon,
  RestaurantIcon,
} from "../../../../../assets/svgIcons";

const CheckoutModal = ({
  setShowCheckoutModal,
  setShowOrderSummaryModal,
  setShowOrderSummary,
  selectedOrderType,
  setSelectedOrderType,
  menuObjectDetail,
}) => {
  const {
    name,
    email,
    phoneNumber,
    numberOfDiners,
    tableNumber,
    orderNotes,
    isLoadingOrder,
    isLoadingConfirmCustomer,
    countryCode,
    dataTables,
    country,
    state,
    postalCode,
    streetAddress,
    apartment,
    setName,
    setEmail,
    setPhoneNumber,
    setPhoneCode,
    setNumberOfDiners,
    setTableNumber,
    setOrderNotes,
    setCountry,
    setState,
    setPostalCode,
    setStreetAddress,
    setApartment,
    shippingLocation,
    setShippingId,
    cities,
    states,
    setCities,
    setStates,
    mergeCountries,
    submitDetails,
  } = useCheckoutForm({
    setShowCheckoutModal,
    setShowOrderSummaryModal,
    setShowOrderSummary,
    selectedOrderType,
    setSelectedOrderType,
    menuObjectDetail,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    setValue,
  } = Validation(
    name,
    email,
    phoneNumber,
    country,
    state,
    streetAddress,
    selectedOrderType,
  );
  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";

  useEffect(() => {
    setValue("orderType", selectedOrderType);
  }, [selectedOrderType]);

  return (
    <div className={styles.storeCustomerForm}>
      <div
        onClick={() => setShowCheckoutModal(false)}
        className={styles.back_icon_container}
        style={{ borderColor: primaryColor }}
      >
        <ChevronLeftArrow color={primaryColor} />
      </div>
      <div className={styles.checkout_heading_container}>
        <h4>Checkout</h4>
        <p>Provide the following details for your order</p>
      </div>
      <div className={styles.checkout_selection}>
        {menuObjectDetail?.allow_payment && (
          <div className={styles.selection_container}>
            {menuObjectDetail?.allow_in_store_checkout && (
              <div
                onClick={() => setSelectedOrderType("DINE_IN")}
                className={styles.selection}
                style={{
                  borderColor:
                    selectedOrderType === "DINE_IN"
                      ? primaryColor
                      : "#95959559",
                }}
              >
                <div>
                  {selectedOrderType === "DINE_IN" ? (
                    <div
                      style={{
                        borderColor: primaryColor,
                      }}
                      className={styles.radio_selected}
                    >
                      <div
                        style={{
                          background: primaryColor,
                        }}
                        className={styles.radio_inner}
                      />
                    </div>
                  ) : (
                    <div className={styles.radio_unselected} />
                  )}
                </div>
                <div className={styles.text_container}>
                  <h6>In-store</h6>
                  <p>Pick up your order at local store</p>
                </div>
              </div>
            )}
            {menuObjectDetail?.allow_delivery && (
              <div
                onClick={() => setSelectedOrderType("DELIVERY")}
                className={styles.selection}
                style={{
                  borderColor:
                    selectedOrderType === "DELIVERY"
                      ? primaryColor
                      : "#95959559",
                }}
              >
                <div>
                  {selectedOrderType === "DELIVERY" ? (
                    <div
                      style={{
                        borderColor: primaryColor,
                      }}
                      className={styles.radio_selected}
                    >
                      <div
                        style={{
                          background: primaryColor,
                        }}
                        className={styles.radio_inner}
                      />
                    </div>
                  ) : (
                    <div className={styles.radio_unselected} />
                  )}
                </div>
                <div className={styles.text_container}>
                  <h6>Delivery</h6>
                  <p>Have your order delivered to your doorstep</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.existing_user}>
        <h5>Enter Your Details</h5>
        <div>
          <Controller
            name="Name"
            control={control}
            defaultValue={name}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Name"
                placeholder="Enter your name"
                name="name"
                isRequired
                errorMessage={errors.name}
                value={name}
                onKeyUp={() => errors.name !== undefined && trigger("name")}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setName(value);
                }}
                register={register}
              />
            )}
          />

          <Controller
            name="phoneNumber"
            control={control}
            defaultValue={phoneNumber}
            render={({ field: { onChange } }) => (
              <primaryComponents.PhoneNumberInputField
                label="Phone Number"
                placeholder="Enter your phone number"
                name="phoneNumber"
                isRequired
                value={phoneNumber}
                errorMessage={errors.phoneNumber}
                onKeyUp={() =>
                  errors.phoneNumber !== undefined && trigger("phoneNumber")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setPhoneNumber(value);
                }}
                register={register}
                countryCode={countryCode}
                phoneCodeEditable={true}
                onChangePhoneCode={(val) => setPhoneCode(val)}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            defaultValue={email}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Email Address"
                placeholder="Enter your email address"
                name="email"
                isRequired
                errorMessage={errors.email}
                value={email}
                onKeyUp={() => errors.email !== undefined && trigger("email")}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setEmail(value);
                }}
                register={register}
              />
            )}
          />
        </div>

        {selectedOrderType === "DELIVERY" && (
          <div className="mt-4">
            <h5>Address Details</h5>
            <div>
              <Controller
                name="country"
                control={control}
                defaultValue={country}
                render={({ field: { onChange } }) => {
                  const uniqueCountries = mergeCountries(shippingLocation);

                  return (
                    <primaryComponents.SelectField
                      name="country"
                      isRequired
                      options={uniqueCountries?.map((item) => ({
                        value: item?.country?.id,
                        label: item?.country?.name,
                      }))}
                      label="Country"
                      nameKey={"label"}
                      idKey={"value"}
                      value={country === "" ? "Select country" : country}
                      onChange={(value) => {
                        onChange(value);
                        setCountry(value);
                        setState("");
                        setPostalCode("");

                        const selectedCountryData = uniqueCountries?.find(
                          (location) => location?.country?.id === value,
                        );
                        setStates(selectedCountryData?.states || []);

                        setCities([]);
                      }}
                      register={register}
                      errorMessage={errors.country}
                      backgroundColor={primaryColor}
                    />
                  );
                }}
              />

              <Controller
                name="state"
                control={control}
                defaultValue={state}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    name="state"
                    isRequired
                    options={states?.map((state) => ({
                      value: state?.id,
                      label: state?.name,
                    }))}
                    label="State"
                    nameKey={"label"}
                    idKey={"value"}
                    value={state}
                    onChange={(value) => {
                      onChange(value);
                      setState(value);

                      // const selectedStateData = states.find(
                      //   (s) => s.id === value,
                      // );
                      const citiesInState = shippingLocation
                        ?.flatMap((location) => location?.cities || [])
                        .filter((city) => city?.state?.id === value);

                      setCities(citiesInState || []);
                    }}
                    register={register}
                    errorMessage={errors.state}
                    backgroundColor={primaryColor}
                  />
                )}
              />
              <Controller
                name="city"
                control={control}
                defaultValue={postalCode}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    name="state"
                    isRequired
                    options={cities.map((city) => ({
                      value: city.id,
                      label: city.name,
                    }))}
                    label="City"
                    nameKey={"label"}
                    idKey={"value"}
                    value={state || ""}
                    onChange={(value) => {
                      // const selectedCity = cities.find((city) => city.id === value)
                      const selectedRegion = shippingLocation.find((location) =>
                        location.cities.some((city) => city.id === value),
                      );

                      const regionId = selectedRegion?.id;
                      setShippingId(regionId);
                      onChange(value);
                      setPostalCode(value);
                    }}
                    register={register}
                    errorMessage={errors.city}
                    backgroundColor={primaryColor}
                  />
                )}
              />
              {/* <Controller
                name="state"
                control={control}
                defaultValue={state}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="State / Province / Region"
                    placeholder="Enter state or province or region"
                    name="state"
                    isRequired
                    errorMessage={errors.state}
                    value={state}
                    onKeyUp={() =>
                      errors.state !== undefined && trigger("state")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setState(value);
                    }}
                    register={register}
                  />
                )}
              />

              <Controller
                name="postalCode"
                control={control}
                defaultValue={postalCode}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Zip code / Postal code"
                    placeholder="Enter Zip or Postal code"
                    name="postalCode"
                    errorMessage={errors.postalCode}
                    value={postalCode}
                    onKeyUp={() =>
                      errors.postalCode !== undefined && trigger("postalCode")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setPostalCode(value);
                    }}
                    register={register}
                  />
                )}
              /> */}

              <Controller
                name="streetAddress"
                control={control}
                defaultValue={streetAddress}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Street address / P.O. box / Company Name"
                    name="streetAddress"
                    isRequired
                    errorMessage={errors.streetAddress}
                    value={streetAddress}
                    onKeyUp={() =>
                      errors.streetAddress !== undefined &&
                      trigger("streetAddress")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setStreetAddress(value);
                    }}
                    register={register}
                  />
                )}
              />

              <Controller
                name="apartment"
                control={control}
                defaultValue={apartment}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Apartment, Suite, Unit, Building, Floor, etc "
                    name="apartment"
                    errorMessage={errors.apartment}
                    value={apartment}
                    onKeyUp={() =>
                      errors.apartment !== undefined && trigger("apartment")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setApartment(value);
                    }}
                    register={register}
                  />
                )}
              />
            </div>
          </div>
        )}
      </div>
      {selectedOrderType === "DINE_IN" && (
        <div>
          {menuObjectDetail &&
            menuObjectDetail?.store?.allow_table &&
            dataTables &&
            dataTables.length > 0 && (
              <div className={styles.restaurant_table_container}>
                <div className={styles.restaurant_heading_container}>
                  <div className={styles.restaurant_heading}>
                    <RestaurantIcon color={primaryColor} />
                    <p className={styles.restaurant_text}>Restaurant Table</p>
                  </div>
                </div>
                <Controller
                  name="tableNumber"
                  control={control}
                  defaultValue={tableNumber}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.SelectField
                      name="tableNumber"
                      options={dataTables?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))}
                      label="Select Your Table"
                      nameKey={"label"}
                      idKey={"value"}
                      value={tableNumber}
                      onChange={(value) => {
                        onChange(value);
                        setTableNumber(value);
                      }}
                      backgroundColor={primaryColor}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="numberOfDiners"
                  defaultValue={numberOfDiners}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.SelectField
                      name="numberOfDiners"
                      options={
                        dataTables?.find((item) => item.id === tableNumber)
                          ? new Array(
                              dataTables?.find(
                                (item) => item.id === tableNumber,
                              )?.seat_capacity,
                            )
                              .fill("-")
                              .map((_, index) => index + 1)
                          : []
                      }
                      label="Select Number of Diners"
                      nameKey={"numberOfDiners"}
                      idKey={"id"}
                      value={numberOfDiners}
                      onChange={(value) => {
                        onChange(value);
                        setNumberOfDiners(value);
                      }}
                      backgroundColor={primaryColor}
                    />
                  )}
                />
              </div>
            )}
        </div>
      )}

      <div className={styles.order_notes_container}>
        <div className={styles.restaurant_heading}>
          <OrderNoteIcon color={primaryColor} />
          <p className={styles.restaurant_text}>Order Notes</p>
        </div>
        <Controller
          name="orderNotes"
          control={control}
          defaultValue={orderNotes}
          render={({ field: { onChange } }) => (
            <primaryComponents.TextArea
              name="orderNotes"
              type="text"
              placeholder="Order note"
              value={orderNotes}
              classNames={styles.text_area}
              isRequired={true}
              errorMessage={errors.orderNotes}
              onChange={({ target: { value } }) => {
                onChange(value);
                setOrderNotes(value);
              }}
              onKeyUp={() =>
                errors.orderNotes !== undefined && trigger("orderNotes")
              }
              register={register}
            />
          )}
        />
      </div>

      {/* <div className={styles.order_notes_container}>
        <div className={styles.restaurant_heading}>
          <img src={Icon.biker} alt="" />
          <p className={styles.restaurant_text}>Delivery Notes</p>
        </div>
        <Controller
          name="deliveryNotes"
          control={control}
          defaultValue={deliveryNotes}
          render={({ field: { onChange } }) => (
            <primaryComponents.TextArea
              name="deliveryNotes"
              type="text"
              placeholder="Give instructions to the rider "
              value={deliveryNotes}
              classNames={styles.text_area}
              isRequired={true}
              errorMessage={errors.deliveryNotes}
              onChange={({ target: { value } }) => {
                onChange(value);
                setDeliveryNotes(value);
              }}
              onKeyUp={() =>
                errors.deliveryNotes !== undefined && trigger("deliveryNotes")
              }
              register={register}
            />
          )}
        />
      </div> */}

      <div className={styles.storeCustomerForm__bottom}>
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isDisabled={
            isLoadingOrder ||
            isLoadingConfirmCustomer ||
            !menuObjectDetail?.allow_payment ||
            (!menuObjectDetail?.allow_in_store_checkout &&
              !menuObjectDetail?.allow_delivery)
          }
          isLoading={isLoadingOrder || isLoadingConfirmCustomer}
          loadingText="Submitting..."
          onClick={handleSubmit(submitDetails)}
          style={{ background: primaryColor }}
        >
          Submit
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default CheckoutModal;
