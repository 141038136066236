import "./EditProduct.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useEditProduct } from "./useEditProduct";

const EditProduct = () => {
  const navigate = useNavigate();
  const {
    isUpdatingProduct,
    isFetchingProduct,
    productDetails,
    handleProductUpdate,
  } = useEditProduct();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Product"
      description="Edit product details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          className="edit-single-product-page"
        >
          <primaryComponents.Button
            classNames="btn btn--outline back__btn"
            onClick={() => navigate(-1)}
            type="button"
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>
          <div className="edit-single-product-page__content">
            <div className="edit-single-product-page__content-heeader">
              <h3 className="font-weight-semibold">Edit product</h3>
              <p>Please provide the following information about your product</p>
            </div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingProduct}
              classNames="mt-5"
            >
              <secondaryComponents.SingleProductForm
                isEditMode={true}
                productDetails={productDetails}
                isUpdatingProduct={isUpdatingProduct}
                handleProductUpdate={handleProductUpdate}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditProduct;
