import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ToastContext } from "../../../hooks/context";
import { deleteMenu } from "../../../services/inventory";

const useAllFoodMenusList = () => {
  const triggerToast = useContext(ToastContext);
  const onCopy = () => triggerToast("Copied", "success");
  const [isFoodMenuWidget, setIsFoodMenuWidget] = useState(null);
  const [isDeleteFoodMenu, setIsDeleteFoodMenu] = useState(null);
  const queryClient = useQueryClient();

  const deleteMenuMutation = useMutation({
    mutationKey: ["deleteMenu"],
    mutationFn: (menuId) => deleteMenu(menuId),
    onSuccess: (res) => {
      queryClient.invalidateQueries(["menuList"]);
      queryClient.refetchQueries(["getMenus"]);
      triggerToast(res.data?.detail, "success");
      setIsDeleteFoodMenu(null);
    },
  });

  const handleDeleteMenu = () => {
    return deleteMenuMutation.mutate(isDeleteFoodMenu);
  };

  return {
    isDeletingMenu: deleteMenuMutation?.isLoading,
    isFoodMenuWidget,
    isDeleteFoodMenu,
    setIsFoodMenuWidget,
    setIsDeleteFoodMenu,
    onCopy,
    handleDeleteMenu,
  };
};
export default useAllFoodMenusList;
