import { useState } from "react";
import { getProductUnits } from "../../../services/inventory";
import { useQuery } from "react-query";

const StockUnitInputLogic = ({ defaultUnit, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(defaultUnit);
  const [inputValue, setInputValue] = useState(value);

  const { data: stockUnits } = useQuery(["getStockUnits"], () =>
    getProductUnits().then((res) => res.data?.data),
  );

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  return {
    isOpen,
    selectedUnit,
    stockUnits: stockUnits || [],
    inputValue,
    setInputValue,
    handleToggle,
    setIsOpen,
    setSelectedUnit,
  };
};

export default StockUnitInputLogic;
