import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";
import {
  useCreateCampaign,
  useEditCampaign,
} from "../../../hooks/api/mutations/useLoyalty";
import { cleanObject } from "../../../helpers";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";

const useCampaignForm = ({ campaign, isEditMode = false }) => {
  const [showImageModal, setShowImageModal] = useState(false);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [campaignDetails, setCampaignDetails] = useState({
    campaign_name: campaign?.campaign_name || "",
    brand_name: campaign?.brand_name || "",
    description: campaign?.description || "",
    brand_logo_url: campaign?.brand_logo_url || "",
    business_slug: campaign?.business_slug || "",
    offers: storeList?.map((store) => ({
      name: store?.name,
      id: store?.id,
      isDisabled: campaign?.offers.find((item) => item.store?.id === store?.id)
        ? false
        : true,
      reward_per_transaction: campaign?.offers.find(
        (item) => item.store?.id === store?.id,
      )
        ? campaign?.offers.find((item) => item.store?.id === store?.id)
            ?.reward_per_transaction
        : "",
    })),
  });
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const editCampaignMutation = useEditCampaign();
  const createCampaignMutation = useCreateCampaign();
  const queryClient = useQueryClient();

  const serializeOffers = (offers = []) => {
    return offers?.map((offer) => ({
      store_id: offer?.id,
      reward_per_transaction: offer?.reward_per_transaction || 0,
    }));
  };

  const handleCreateOrUpdateCampaign = () => {
    setRequestLoaderProgress(100);
    if (isEditMode) {
      editCampaignMutation.mutate(
        cleanObject({
          ...campaignDetails,
          id: campaign?.id,
          offers: serializeOffers(campaignDetails?.offers),
        }),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getCampaignDetails", campaign?.id]);
            onSuccessHandler({ message: "Campaign updated successfully" });
          },
        },
      );
    } else {
      createCampaignMutation.mutate(
        cleanObject({
          ...campaignDetails,
          offers: serializeOffers(campaignDetails?.offers),
        }),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getCampaigns"]);
            onSuccessHandler({ message: "Campaign created successfully" });
          },
        },
      );
    }
  };

  const onSuccessHandler = ({ message = "" }) => {
    setRequestLoaderProgress(100);
    triggerToast(message, "success");
    navigate(pathConstants.LOYALTY_MANAGE_CAMPAIGN);
  };

  return {
    showImageModal,
    campaignDetails,
    isLoading:
      editCampaignMutation?.isLoading || createCampaignMutation?.isLoading,
    setCampaignDetails,
    setShowImageModal,
    handleCreateOrUpdateCampaign,
  };
};

export default useCampaignForm;
