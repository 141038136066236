import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const resetPasswordSchema = yup
  .object({
    password: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number",
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords do not match!")
      .required("Confirm password is required"),
  })
  .required();

const ResetPasswordFormValidation = (password, confirmPassword) => {
  return useForm({
    resolver: yupResolver(resetPasswordSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      password: password,
      confirmPassword: confirmPassword,
    },
  });
};

export default ResetPasswordFormValidation;
