import styles from "./GeneratedPrescriptions.module.scss";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import { formatDateToString } from "../../../../helpers";
import { useGeneratedPrescriptions } from "./useGeneratedPrescriptions";

const PrescriptionsDetails = ({ orderId }) => {
  const { prescriptions, isFetchingPrescriptions } = useGeneratedPrescriptions({
    orderId,
  });

  return (
    <div className={styles.generatedPrescriptions}>
      <secondaryComponents.LoaderHelper isLoading={isFetchingPrescriptions}>
        <div className={styles.generatedPrescriptions__items}>
          {prescriptions &&
            prescriptions.map((prescription) => (
              <div
                className={styles["generatedPrescriptions__items-item"]}
                key={prescription?.id}
              >
                <div>
                  <h5>{prescription?.id}</h5>
                </div>
                <div className="d-flex align-items-center">
                  <p style={{ fontSize: "14px" }}>
                    {formatDateToString(prescription?.created_at)}
                  </p>
                  <primaryComponents.Button
                    classNames={`btn btn--primary ${styles.printBtn} ml-auto`}
                    onClick={() =>
                      window.open(prescription.prescription_url, "_blank")
                    }
                  >
                    View Details
                  </primaryComponents.Button>
                </div>
              </div>
            ))}
        </div>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default PrescriptionsDetails;
