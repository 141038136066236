import "./TerminalDetails.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import TerminalDetailsLogic from "./TerminalDetailsLogic";
// import { formatCurrency } from "../../../../helpers";

const TerminalDetails = () => {
  const navigate = useNavigate();
  const { isLoading, terminalDetails } = TerminalDetailsLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Terminal"
      description="View the details about a terminal"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className="terminal-details">
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            <secondaryComponents.LoaderHelper
              isLoading={isLoading}
              classNames="mt-5"
            >
              <div className="terminal-details__header">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <h4 className="font-weight-semibold">
                    {terminalDetails.terminal_id}
                  </h4>
                  <span
                    className={`${
                      terminalDetails.status === "ACTIVE" ||
                      terminalDetails.status === "RETRIEVED"
                        ? "success--squared"
                        : terminalDetails.status === "PENDING"
                          ? "pending--squared"
                          : "failed--text"
                    }`}
                  >
                    {terminalDetails.status}
                  </span>
                </div>
                <p>
                  {new Date(terminalDetails.last_activity).toLocaleString(
                    "en-us",
                  )}
                </p>
              </div>

              <div className="terminal-details__content">
                <div className="terminal-details__content-item">
                  <p className="font-weight-semibold dark--text">Terminal ID</p>
                  <span>{terminalDetails.terminal_id}</span>
                </div>
                <div className="terminal-details__content-item">
                  <p className="font-weight-semibold dark--text">
                    Bank Provider
                  </p>
                  <span>{terminalDetails.bank_provider}</span>
                </div>
                <div className="terminal-details__content-item">
                  <p className="font-weight-semibold dark--text">
                    Request Date
                  </p>
                  <span>
                    {new Date(terminalDetails.requested_at).toLocaleString(
                      "en-us",
                    )}
                  </span>
                </div>
                <div className="terminal-details__content-item">
                  <p className="font-weight-semibold dark--text">
                    Approval Date
                  </p>
                  <span>
                    {new Date(terminalDetails.approved_at).toLocaleString(
                      "en-us",
                    )}
                  </span>
                </div>
              </div>
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default TerminalDetails;
