import useSubscriptionPlans from "../SubscriptionPlans/useSubscriptionPlans";

const useSubscriptionSelection = () => {
  const {
    isLoading,
    subscriptionTiers,
    subscriptionPeriod,
    isPayModal,
    upgradeDetails,
    activeSubscription,
    setUpgradeDetails,
    setIsPayModal,
    setSubscriptionPeriod,
  } = useSubscriptionPlans();

  return {
    isLoading,
    subscriptionTiers,
    subscriptionPeriod,
    isPayModal,
    upgradeDetails,
    activeSubscription,
    setUpgradeDetails,
    setIsPayModal,
    setSubscriptionPeriod,
  };
};
export default useSubscriptionSelection;
