export const ConnectIcon = () => {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91667 12.0833L2.91667 16.9167C2.91667 17.584 3.45766 18.125 4.125 18.125L8.95834 18.125C9.62568 18.125 10.1667 17.584 10.1667 16.9167L10.1667 12.0833C10.1667 11.416 9.62568 10.875 8.95834 10.875L4.125 10.875C3.45766 10.875 2.91667 11.416 2.91667 12.0833Z"
        fill="#F7AD3C"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.25 24.1667L17.4167 24.1667C16.7493 24.1667 16.2083 23.6257 16.2083 22.9583L16.2083 6.04166C16.2083 5.3743 16.7493 4.83333 17.4167 4.83333L22.25 4.83333"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0833 24.1667C27.0833 22.832 26.0013 21.75 24.6667 21.75C23.332 21.75 22.25 22.832 22.25 24.1667C22.25 25.5014 23.332 26.5833 24.6667 26.5833C26.0013 26.5833 27.0833 25.5014 27.0833 24.1667Z"
        fill="#47BE79"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0833 4.83333C27.0833 3.49865 26.0014 2.41667 24.6667 2.41667C23.332 2.41667 22.25 3.49865 22.25 4.83333C22.25 6.16802 23.332 7.25 24.6667 7.25C26.0014 7.25 27.0833 6.16802 27.0833 4.83333Z"
        fill="#47BE79"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0833 14.5C27.0833 13.1653 26.0013 12.0833 24.6667 12.0833C23.332 12.0833 22.25 13.1653 22.25 14.5C22.25 15.8347 23.332 16.9167 24.6667 16.9167C26.0013 16.9167 27.0833 15.8347 27.0833 14.5Z"
        fill="#47BE79"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1667 14.5L22.25 14.5"
        stroke="#15112D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
