import { useTable } from "react-table";
import { useMemo, useContext } from "react";
import { convertToTilteCase, formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { platformHandler } from "../../../hooks/context";
import { getPlatform } from "../../../helpers";

const useCustomerLoyaltyTable = ({ customers }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { platform } = useContext(platformHandler);
  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "reference",
        Cell: ({ cell: { value }, row }) => {
          const targetSubstrings = ["DINT-", "CINT-"];
          const containsTarget =
            value &&
            targetSubstrings.some((substring) => value.includes(substring));

          return containsTarget ? (
            <span
              className="purple--text"
              style={{ fontSize: "12px", textDecoration: "none" }}
            >
              {value}
            </span>
          ) : (
            <Link
              className="purple--text"
              to={
                getPlatform(platform) +
                `/inventory/sales-transactions?orderId=${value}`
              }
              style={{ fontSize: "12px", textDecoration: "underline" }}
            >
              {value ? value : "N/A"}
            </Link>
          );
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${value === "debit" ? "cancell--red" : "time--green"}`}
            style={{ fontSize: "12px" }}
          >
            {value ? convertToTilteCase(value) : "N/A"}
          </span>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Points",
        accessor: "points",
        Cell: ({ cell: { value } }) => (value ? value + "pts" : "N/A"),
      },
      {
        Header: "Amount",
        accessor: "naira_equiv",
        Cell: ({ cell: { value } }) =>
          value
            ? formatCurrency({
                value: value || 0,
                currencyCode: currency_code,
              })
            : "N/A",
      },
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: customers });
  return {
    instance,
  };
};

export default useCustomerLoyaltyTable;
