import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updatePersonalInfo as updateInfo } from "../../../services/settings";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getProfile } from "../../../services/settings";
import { setProfileDetails } from "../../../store/modules/profile";

const ProfilePersonalInfoFormLogic = () => {
  const profile = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const [email, setEmail] = useState(profile.email);
  const [firstName, setFirstName] = useState(profile.first_name);
  const [lastName, setLastName] = useState(profile.last_name);
  const [phoneNumber, setPhoneNumber] = useState(profile.phone_number);
  const [jobTitle, setJobTitle] = useState(profile.job_title);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const dispatch = useDispatch();

  const updatePersonalInfo = () => {
    if (!isEdited) return;
    setIsSubmitting(true);
    setRequestLoaderProgress(20);
    updateInfo({
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      job_title: jobTitle,
    })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsSubmitting(false);
        triggerToast("Personal information update successful", "success");
        setIsEdited(false);
        getProfile().then((response) => {
          dispatch(setProfileDetails(response.data.data));
        });
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsSubmitting(false);

        if (error.response.status === 422) {
          window.setPersonalInfoError(
            "phoneNumber",
            {
              type: "validate",
              message: error.response.data.errors.non_field_errors[0],
            },
            { shouldFocus: true },
          );
          triggerToast(
            error.response.data.errors.non_field_errors[0],
            "warning",
          );
        }
      });
  };

  return {
    setEmail,
    email,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phoneNumber,
    setPhoneNumber,
    jobTitle,
    setJobTitle,
    updatePersonalInfo,
    isSubmitting,
    setIsEdited,
    isEdited,
  };
};
export default ProfilePersonalInfoFormLogic;
