import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .max(50, "Maximum of 50 characters is allowed")
    .required("Email is required"),
  phone_number: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
    .min(10, "Invalid phone number format")
    .max(10, "Invalid phone number format"),
});

const Validation = ({ riderDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      ...riderDetails,
    },
  });
};

export default Validation;
