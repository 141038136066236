import React from "react";
import styles from "./styles.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import {
  formatCurrency,
  getPriceWithDiscout,
  getVariantPriceWithDiscout,
} from "../../../../../helpers";
import usePayForOrder from "./usePayForOrder";
import { ChevronLeftArrow } from "../../../../../assets/svgIcons";

const PayForOrderModal = ({
  setShowPayForOrder,
  setShowOrderSummaryModal,
  setShowCartItems,
  selectedOrderType,
  showOrderSummary,
  menuObjectDetail,
}) => {
  const {
    isLoadingPayInstoreDelivery,
    isLoadingProcessPayment,
    isLoadingPaymentGateways,
    dataPaymentGateways,
    menuCartItems,
    paymentMethod,
    preferredPaymentMethod,
    store,
    setPaymentMethod,
    setPreferredPaymentMethod,
    handleProcessPayment,
  } = usePayForOrder({
    selectedOrderType,
    setShowPayForOrder,
    setShowCartItems,
    menuObjectDetail,
  });

  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";
  const shippingRate = Number(
    showOrderSummary?.data?.data?.delivery_fee_amount || 0,
  );

  const instorePaymentMethods = [
    (menuObjectDetail?.pay_with_paystack ||
      menuObjectDetail?.pay_with_stripe) && {
      label: "Pay Online",
      description:
        "Complete your payment online with card, bank transfer, USSD, and more.",
    },
    menuObjectDetail?.allow_pay_instore && {
      label: "Pay In-store",
      description:
        "Make a physical payment directly to the cashier when you visit the store.",
    },
  ];

  const deliveryPaymentMethods = [
    (menuObjectDetail?.pay_with_paystack ||
      menuObjectDetail?.pay_with_stripe) && {
      label: "Pay Online",
      description:
        "Complete your payment online with card, bank transfer, USSD, and more.",
    },
    menuObjectDetail?.allow_pay_on_delivery && {
      label: "Pay On Delivery",
      description:
        "Make a payment to dispatch rider when your order is delivered.",
    },
  ];

  return (
    <div className={styles.storeCustomerForm}>
      <div
        onClick={() => {
          if (preferredPaymentMethod) {
            setPreferredPaymentMethod(null);
          } else {
            setShowPayForOrder(false);
            setShowOrderSummaryModal(true);
          }
        }}
        className={styles.back_icon_container}
        style={{ borderColor: primaryColor }}
      >
        <ChevronLeftArrow color={primaryColor} />
      </div>
      <div className={styles.checkout_heading_container}>
        <h4>Pay For Your Order</h4>
        <p>Choose your preferred payment method</p>
      </div>
      {preferredPaymentMethod === "Pay Online" ? (
        <secondaryComponents.LoaderHelper
          isLoading={isLoadingPaymentGateways || !dataPaymentGateways}
          classNames="mt-4"
        >
          <div className={styles.payment_type_container}>
            {dataPaymentGateways?.data?.data?.map((gateway, index) => (
              <div
                key={index}
                onClick={() => setPaymentMethod(gateway)}
                className={styles.payment_type}
              >
                <p>{gateway.type}</p>
                <div className={styles.radio}>
                  {paymentMethod === gateway && (
                    <div
                      style={{ background: primaryColor }}
                      className={styles.radio_inside}
                    ></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </secondaryComponents.LoaderHelper>
      ) : selectedOrderType === "DINE_IN" ? (
        <div className={styles.payment_type_container}>
          {instorePaymentMethods?.map((item, index) => {
            if (item) {
              return (
                <div
                  key={index}
                  onClick={() => setPreferredPaymentMethod(item?.label)}
                  className={styles.payment_type}
                >
                  <div>
                    <h5>{item?.label}</h5>
                    <p>{item?.description}</p>
                  </div>
                  <div className={styles.radio}>
                    {preferredPaymentMethod === item?.label && (
                      <div
                        style={{ background: primaryColor }}
                        className={styles.radio_inside}
                      ></div>
                    )}
                  </div>
                </div>
              );
            }
            return <></>;
          })}
        </div>
      ) : (
        <div className={styles.payment_type_container}>
          {deliveryPaymentMethods.map((item, index) => {
            if (item) {
              return (
                <div
                  key={index}
                  onClick={() => setPreferredPaymentMethod(item?.label)}
                  className={styles.payment_type}
                >
                  <div>
                    <h5>{item?.label}</h5>
                    <p>{item?.description}</p>
                  </div>
                  <div className={styles.radio}>
                    {preferredPaymentMethod === item?.label && (
                      <div
                        style={{ background: primaryColor }}
                        className={styles.radio_inside}
                      ></div>
                    )}
                  </div>
                </div>
              );
            }
            return <></>;
          })}
        </div>
      )}

      {!menuObjectDetail?.allow_pay_instore &&
        !menuObjectDetail?.pay_with_paystack &&
        !menuObjectDetail?.pay_with_stripe &&
        !menuObjectDetail?.allow_pay_on_delivery && <p>Payment not enabled</p>}

      <div className={styles.storeCustomerForm__bottom}>
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isDisabled={
            !preferredPaymentMethod ||
            (dataPaymentGateways?.data?.data?.length === 0 &&
              preferredPaymentMethod === "Pay Online") ||
            isLoadingPaymentGateways ||
            isLoadingProcessPayment ||
            isLoadingPayInstoreDelivery
          }
          isLoading={
            isLoadingPaymentGateways ||
            isLoadingProcessPayment ||
            isLoadingPayInstoreDelivery
          }
          loadingText={isLoadingPaymentGateways ? "..." : "Submitting..."}
          onClick={handleProcessPayment}
          style={{ background: primaryColor }}
        >
          Pay{" "}
          {formatCurrency({
            value:
              menuCartItems.reduce(
                (sum, product) =>
                  sum +
                  (product?.is_variant
                    ? getVariantPriceWithDiscout(product) * product.quantity
                    : getPriceWithDiscout(product) * product.quantity),
                0,
              ) + shippingRate,
            currencyCode: store?.currency_code,
          })}
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default PayForOrderModal;
