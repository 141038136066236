import httpClient from "../axiosBase/axiosHandler";

export const getCreditLogs = (queries) => {
  return httpClient.get(`/credits/${queries}`);
};

export const addDebt = (data) => {
  return httpClient.post("/credits/add-debt/", data);
};

export const pay = (customerId, data) => {
  return httpClient.post(`/credits/${customerId}/pay/`, data);
};
