import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./ExpenseDetails.module.scss";
// import useExpenses from "./useExpenses";
import primaryComponents from "../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../../helpers";
import { useNavigate } from "react-router-dom";
import useExpenseDetails from "./useExpenseDetails";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const ExpenseDetails = () => {
  const navigate = useNavigate();
  const {
    isFetchingExpenseDetails,
    expenseDetails,
    isReviewExpense,
    setIsReviewExpense,
  } = useExpenseDetails();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Expense Details"
      description="View expense details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.expenseDetails} ${globalStyles?.pageColumnGaps} ${globalStyles.pagePaddings}`}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingExpenseDetails}
          >
            <div className={styles.expenseDetails__header}>
              <div>
                <h3 style={{ maxWidth: "600px" }}>{expenseDetails?.name}</h3>
              </div>

              <div className="d-flex ml-auto flex-wrap" style={{ gap: "10px" }}>
                <primaryComponents.Button
                  classNames="btn btn--outline ml-auto"
                  isDisabled={!expenseDetails?.proof_url}
                  onClick={() =>
                    window.open(expenseDetails?.proof_url, "_blank")
                  }
                >
                  <FontAwesomeIcon icon="download" className="mr-2" />
                  <span> Downlaod Proof Of Expense</span>
                </primaryComponents.Button>
                <primaryComponents.Button
                  classNames="btn btn--primary ml-auto"
                  onClick={() => setIsReviewExpense(true)}
                >
                  <span> Review</span>
                </primaryComponents.Button>
              </div>
            </div>

            <div>
              <h4>Expense Details</h4>
              <div className={styles.expenseDetails__card}>
                <div>
                  <h5>Amount</h5>
                  <p>
                    {formatCurrency({
                      value: expenseDetails?.amount,
                      currencyCode: expenseDetails?.store?.currency_code,
                    })}
                  </p>
                </div>
                <div>
                  <h5>Category</h5>
                  <p>{expenseDetails?.category?.name}</p>
                </div>
                <div>
                  <h5>Payment Method</h5>
                  <p>{expenseDetails?.payment_method}</p>
                </div>
                <div>
                  <h5>Expense Date</h5>
                  <p>
                    {new Date(expenseDetails?.expense_date).toLocaleString(
                      "en-us",
                    )}
                  </p>
                </div>
                <div>
                  <h5>Expense Description</h5>
                  <p>
                    {expenseDetails?.description === ""
                      ? "N/A"
                      : expenseDetails?.description}
                  </p>
                </div>
                <div>
                  <h5>Reason for Declination</h5>
                  <p>{expenseDetails?.decline_reason || "N/A"}</p>
                </div>
                <div>
                  <h5>Requested By</h5>
                  <p>
                    {expenseDetails?.initiated_by?.first_name}{" "}
                    {expenseDetails?.initiated_by?.last_name}
                  </p>
                </div>
                <div>
                  <h5>Approved By</h5>
                  <p>
                    {expenseDetails?.reviewed_by
                      ? `${expenseDetails?.reviewed_by?.first_name} ${expenseDetails?.reviewed_by?.last_name}`
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>

            {expenseDetails?.make_refund ? (
              <div>
                <h4>Disbursement Details</h4>
                <div className={styles.expenseDetails__card}>
                  <div>
                    <h5>Bank</h5>
                    <p>
                      {expenseDetails?.refund_trx?.bank_name ||
                        expenseDetails?.bank_code}
                    </p>
                  </div>
                  <div>
                    <h5>Account Number</h5>
                    <p>{expenseDetails?.account_number}</p>
                  </div>
                  <div>
                    <h5>Amount</h5>
                    <p>
                      {formatCurrency({
                        value: expenseDetails?.amount,
                        currencyCode: expenseDetails?.store?.currency_code,
                      })}
                    </p>
                  </div>
                  <div>
                    <h5>Date</h5>
                    <p>
                      {expenseDetails?.refunded_at
                        ? new Date(expenseDetails?.refunded_at).toLocaleString(
                            "en-us",
                          )
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </secondaryComponents.LoaderHelper>

          <secondaryComponents.SideModal
            isActive={isReviewExpense && !isFetchingExpenseDetails}
            closeModal={() => setIsReviewExpense(false)}
          >
            <secondaryComponents.ExpenseReview
              closeWidget={() => setIsReviewExpense(false)}
              expense={expenseDetails}
              handleFetchExpenses={() => {}}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default ExpenseDetails;
