import { useSelector } from "react-redux";

const useGetPermission = ({ permissionParentKey, permissionChildKey }) => {
  const { permissions } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );

  const isPermitted = () => {
    if (permissions?.[permissionParentKey]?.includes(permissionChildKey))
      return true;
    else if (
      permissions?.[permissionParentKey]?.length > 0 &&
      permissionChildKey === undefined
    )
      return true;
    else if (
      permissionChildKey === undefined &&
      permissionParentKey === undefined
    )
      return true;
    else return false;
  };

  return { isPermitted: isPermitted() };
};
export default useGetPermission;
