import Signup from "./Signup/Signup";
import Login from "./Login/Login";
import ResetPassword from "./ResetPassword/ResetPassword";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import EmailVerification from "./EmailVerification/EmailVerification";
import InviteSignup from "./InviteSignup/InviteSignup";
import LoginOtpVerification from "./LoginOtpVerification/LoginOtpVerification";

const authPages = {
  Signup,
  Login,
  ResetPassword,
  ForgotPassword,
  EmailVerification,
  InviteSignup: InviteSignup,
  LoginOtpVerification: LoginOtpVerification,
};

export default authPages;
