import React from "react";

export const FreeDeliveryCheckoutIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#E8F7EE" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#75CE9A" />
      <path
        d="M6.59961 11.9999L10.1996 15.5999L17.3996 8.3999"
        stroke="#19AE57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
