import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const settlementSchema = yup.object({
  account_currency: yup.string().required("currency is required"),
  bank: yup.string().required("bank is required"),
  account_number: yup
    .number()
    .typeError("account number must contain only integers")
    .required("account number is required"),
  is_primary: yup.boolean(),
  business_id: yup
    .array()
    .min(1, "Invalid sub account")
    .required("field is required"),
});

const EditSettlementAccountValidation = ({
  account_currency,
  bank,
  account_number,
  business_id,
}) => {
  return useForm({
    resolver: yupResolver(settlementSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      account_currency,
      bank,
      account_number,
      business_id,
    },
  });
};

export default EditSettlementAccountValidation;
