import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  note: yup.string().required(),
});

export const usePrescriptionFormValidator = (formData) => {
  return useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      note: formData.note,
    },
  });
};
