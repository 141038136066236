import { useEffect } from "react";
import { useGetSectionOrders } from "../../../hooks/api/queries/useSection";
import { useParams, useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const useStaffSection = () => {
  const { section_id } = useParams();
  const [search, setSearch] = useSearchParams();
  const { ref: autoScrollRef, inView } = useInView();

  const queries = (() => {
    let params = `?section_id=${section_id}`;
    for (const entry of search.entries()) {
      params = `${params}&${entry[0]}=${entry[1]}`;
    }
    return params;
  })();

  useEffect(() => {
    if (!search.get("status")) {
      search.set("status", "RECEIVED");
      setSearch(search, { replace: true });
    }
  }, []);

  const {
    isLoading: isfetchingOrders,
    data,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetSectionOrders(queries);

  const sectionItems = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    sectionItems.set(`${page.meta.current_page}`, page.data);
  });
  const sections = [].concat(...sectionItems.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  const setDateFilter = (data) => {
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isfetchingOrders,
    isFetchingNextPage,
    search,
    autoScrollRef,
    sectionOrders: sections || [],
    setDateFilter,
  };
};

export default useStaffSection;
