import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    shippingName: yup.string().required("Shipping name is required"),
    shippingCountryId: yup.string().required("Shipping country is required"),
    shippingRate: yup.string().required("Shipping rate is required"),
    shippingDeliveryWindow: yup
      .string()
      .required("Shipping delivery window is required"),
    shippingMenuIds: yup
      .array()
      .transform((value) => (typeof value === "string" ? [value] : value))
      .of(yup.string().required("Menu is required"))
      .min(1, "At least one menu is required")
      .nullable(),
    shippingCities: yup.array().nullable(),
    freeShipping: yup.string().when("selectedFreeShipping", {
      is: true,
      then: yup
        .string()
        .required("Free shipping eligibility price is required"),
    }),
    shippingStateIds: yup
      .mixed()
      .test(
        "is-valid",
        "Select a country above to enable a state",
        function (value) {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return typeof value === "string" && value.trim() !== "";
        },
      )
      .required("Minimun of one shipping state is required"),
  })
  .required("All fields are required");

const Validation = ({ shippingDetails, selectedFreeShipping }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      shippingName: shippingDetails?.name || "",
      shippingCountryId: shippingDetails?.countryId || "",
      shippingDeliveryWindow: shippingDetails?.delivery_window || "",
      shippingRate: shippingDetails?.rate || "",
      shippingMenuIds: shippingDetails?.storefront_ids,
      shippingStateIds: shippingDetails?.stateIds || [],
      shippingCities: shippingDetails?.cityIds || [],
      freeShipping: shippingDetails?.free_delivery_min_order_amount || "",
      selectedFreeShipping: selectedFreeShipping || false,
    },
  });
};

export default Validation;
