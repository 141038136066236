import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const verificationSchema = yup.object({
  business_type: yup.string().required("Please choose an option to continue"),
});

const KYBFormValidation = (business_type) => {
  return useForm({
    resolver: yupResolver(verificationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      business_type,
    },
  });
};

export default KYBFormValidation;
