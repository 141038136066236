import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ArrowButton.module.scss";

const ArrowButton = ({
  children,
  isDisabled,
  isLoading,
  loadingText = "",
  onClick,
}) => {
  return (
    <button
      className={styles.arrowButton}
      onClick={onClick}
      disabled={isDisabled}
    >
      {isLoading ? loadingText : children}
      <FontAwesomeIcon
        className="purple--text"
        icon="angle-right"
        style={{ fontSize: "20px" }}
      />
    </button>
  );
};
export default ArrowButton;
