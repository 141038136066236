import React from "react";

export const MoreIcon = ({ strokeColor = "#15112D" }) => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="3"
        width="26"
        height="23"
        rx="3"
        stroke={strokeColor}
        strokeWidth="2"
      />
      <path
        d="M7.00016 15.5417C7.6445 15.5417 8.16683 15.0753 8.16683 14.5C8.16683 13.9247 7.6445 13.4583 7.00016 13.4583C6.35583 13.4583 5.8335 13.9247 5.8335 14.5C5.8335 15.0753 6.35583 15.5417 7.00016 15.5417Z"
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0002 15.5417C14.6445 15.5417 15.1668 15.0753 15.1668 14.5C15.1668 13.9247 14.6445 13.4583 14.0002 13.4583C13.3558 13.4583 12.8335 13.9247 12.8335 14.5C12.8335 15.0753 13.3558 15.5417 14.0002 15.5417Z"
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0002 15.5417C21.6445 15.5417 22.1668 15.0753 22.1668 14.5C22.1668 13.9247 21.6445 13.4583 21.0002 13.4583C20.3558 13.4583 19.8335 13.9247 19.8335 14.5C19.8335 15.0753 20.3558 15.5417 21.0002 15.5417Z"
        fill={strokeColor}
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
