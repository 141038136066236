import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelectField } from "../SelectField/SelectFieldLogic";
import styles from "./FancySelectField.module.scss";
import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";

const FancySelectField = ({
  selectedItem = null,
  onChange = () => {},
  options = [],
  idKey,
  nameKey,
  bgKey,
  placeholder = "Select...",
  isDisabled = false,
  isSearchable = true,
  giveControl = true,
  classNames = "",
  width = "140px",
  style = {},
  ...rest
}) => {
  const { items } = useSelectField({
    options,
    valueKey: idKey,
    labelKey: nameKey,
    bgKey,
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target))
        setShowDropdown(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [inputRef]);

  return (
    <span
      className={`${styles.fancySelectField}`}
      style={{ ...style, width: width }}
      {...rest}
      ref={inputRef}
    >
      <span
        className={styles.fancySelectField__input}
        onClick={() => setShowDropdown((prev) => !prev)}
        style={{ borderColor: showDropdown ? "#7647ee" : "#979797" }}
      >
        {selectedItem ? (
          <span
            className={`${styles.item} ${styles.input}`}
            style={{
              background: selectedItem?.bgColor
                ? selectedItem?.bgColor
                : "#9797974d",
              color: selectedItem?.bgColor ? "#fff" : "#000",
            }}
          >
            {selectedItem?.label}
          </span>
        ) : (
          <span className="gray--text">{placeholder}</span>
        )}
        <FontAwesomeIcon
          icon={["fas", "angle-down"]}
          className="ml-2 gray--text"
          style={{ fontSize: "15px" }}
        />
      </span>
      {showDropdown ? (
        <motion.span
          initial={{ y: -5 }}
          animate={{ y: 0 }}
          className={styles.fancySelectField__dropdown}
          style={{ width: width }}
        >
          {items?.map((item) => (
            <span
              key={uuidv4()}
              className={styles.item}
              style={{
                background: item?.bgColor ? item?.bgColor : "#9797974d",
              }}
              onClick={() => onChange(item)}
            >
              {item?.label}
            </span>
          ))}
        </motion.span>
      ) : null}
    </span>
  );
};
export default FancySelectField;
