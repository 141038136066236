import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const bulkTransferSchema = yup.object({
  transfer_from_store_id: yup.string().required("Please select a store"),
  transfer_to_store_id: yup.string().required("Please select a store"),
  transferData: yup.array().of(
    yup.object().shape({
      quantity: yup.string().required("Enter quantity"),
    }),
  ),
});

const useValidation = ({
  transferData,
  transfer_from_store_id,
  transfer_to_store_id,
}) => {
  return useForm({
    resolver: yupResolver(bulkTransferSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      transferData,
      transfer_from_store_id,
      transfer_to_store_id,
    },
  });
};
export default useValidation;
