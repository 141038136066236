import authHttpClient from "../axiosBase/authHandler";
import httpClient from "../axiosBase/axiosHandler";
import axios from "axios";
// eslint-disable-next-line no-undef
const baseUrlBusiness = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;
const authHtppClientWithAccess = axios.create({
  baseURL: baseUrlBusiness,
});

export const createUser = (payload) => {
  const response = authHttpClient.post("/register/", payload);
  return response;
};

export const validateData = (payload) => {
  const response = authHttpClient.get(
    `/register/validate-data/?email=${payload.email}&phone_number=${payload.phone_number}&country_code=${payload.country_code}`,
  );
  return response;
};

export const getServiceOption = () => {
  const response = authHttpClient.get("/register/services-of-interest/");
  return response;
};

export const fetchToken = (payload) => {
  const response = authHttpClient.post("/tokens/", payload);
  return response;
};

export const forgetPassword = (payload) => {
  const response = authHttpClient.post("/users/password-reset-link/", payload);
  return response;
};

export const resetPassword = (payload) => {
  const response = authHttpClient.post("/users/reset-password/", payload);
  return response;
};

export const createUserViaInvite = (payload) => {
  const response = authHttpClient.post("/register/invitee/", payload);
  return response;
};

export const getInviteDetails = (refId) => {
  const response = authHttpClient.get(`/register/invite-ref/?ref=${refId}`);
  return response;
};

export const verifyUserEmail = (token, payload) => {
  authHtppClientWithAccess.interceptors.request.use((config) => {
    const ACCESS_TOKEN = token;
    config.headers.Authorization = `Bearer ${ACCESS_TOKEN}`;
    return config;
  });
  const response = authHtppClientWithAccess.post(
    "/users/verify-email/otp/",
    payload,
  );
  return response;
};

export const sendOTP = (token) => {
  authHtppClientWithAccess.interceptors.request.use((config) => {
    const ACCESS_TOKEN = token;
    config.headers.Authorization = `Bearer ${ACCESS_TOKEN}`;
    return config;
  });
  const response = authHtppClientWithAccess.post(
    "/users/verify-email/resend-otp/",
    {},
  );
  return response;
};

export const getProfileDetails = (token) => {
  authHtppClientWithAccess.interceptors.request.use((config) => {
    const ACCESS_TOKEN = token;
    config.headers.Authorization = `Bearer ${ACCESS_TOKEN}`;
    return config;
  });
  const path = "/profile/detail/";
  const response = token
    ? authHtppClientWithAccess.get(path)
    : httpClient.get(path);
  return response;
};

export const loginUser = ({ email, password }) => {
  const response = authHttpClient.post("/auth/login/", { email, password });
  return response;
};

export const loginUserVerification = ({ otp, sessionId }) => {
  const response = authHttpClient.post("/auth/login-otp/", {
    code: otp,
    id: sessionId,
  });
  return response;
};

export const thirdPartyLogin = (clientToken) => {
  const response = authHttpClient.post("/client/auth/login/", clientToken);
  return response;
};
