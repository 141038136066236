import React from "react";
import styles from "./styles.module.scss";
import Icon from "../../../../../../../assets/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const CategoryCard = ({ id, text }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={styles.categoryCard}
    >
      <p className={styles.categoryText}>{text}</p>
      <img src={Icon.hamburger} alt="" />
    </div>
  );
};

export default CategoryCard;
