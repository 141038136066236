import React from "react";
import secondaryComponents from "..";
import styles from "./MainDashboardAnalyticsCard.module.scss";
import { COLORS } from "../../../helpers/constants";

const MainDashboardAnalyticsCard = ({
  title,
  value,
  text = null,
  color,
  isFetchingAnalytics,
  backgroundColor = null,
  customer = null,
}) => {
  return (
    <div
      className={styles.analyticsCard}
      style={{ backgroundColor: backgroundColor ? backgroundColor : "#fff" }}
    >
      <div
        className={styles.analyticsCard__left}
        style={{ borderLeft: `4px solid ${COLORS[color % COLORS.length]}` }}
      >
        {title}
      </div>
      <secondaryComponents.LoaderHelper isLoading={isFetchingAnalytics}>
        {customer ? (
          <h6
            className="mb-0"
            style={{
              color: customer?.outstanding_balance <= 0 ? "#19AE57" : "#E02020",
            }}
          >
            {value}
            <br />
            <p>{text === null ? "" : text}</p>
          </h6>
        ) : (
          <h6 className="mb-0">
            {value}
            <br />
            <p>{text === null ? "" : text}</p>
          </h6>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default MainDashboardAnalyticsCard;
