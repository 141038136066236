import useEndOfDayForm from "./useEndOfDayForm";
import Validation from "./Validation";
import { motion } from "framer-motion";
import styles from "../SalesEndOfDay.module.scss";
import primaryComponents from "../../../primaryComponents";

const EndOfDayForm = ({
  setIsEndOfDayDetails,
  getEndOfDayDetails,
  setEndOfDayFormDetails,
  endOfDayFormDetails,
  isGeneratingDetails,
}) => {
  const { storeList, userList } = useEndOfDayForm({ setIsEndOfDayDetails });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation({ endOfDayFormDetails });

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        className="mt-4"
      >
        <h4>End Of Day</h4>
        <p>
          You can customize how you want to receive your end of day with the
          options below
        </p>
        <form className={styles.formSection}>
          {/* store */}

          <primaryComponents.MultipleSelectFieldV2
            name="store"
            control={control}
            options={storeList}
            idKey="id"
            nameKey="name"
            placeholder="Select store(s)"
            errorMessage={errors.store}
            value={endOfDayFormDetails?.stores}
            onChange={(values) => {
              setEndOfDayFormDetails((prevState) => ({
                ...prevState,
                stores: values,
              }));
            }}
          />

          <primaryComponents.SelectFieldV2
            name="Order source"
            control={control}
            options={[
              { name: "Point of Sale", id: "INTERNAL" },
              { name: "Storefront", id: "STOREFRONT" },
            ]}
            idKey="id"
            nameKey="name"
            value={
              endOfDayFormDetails.order_source?.label || "Select Order Source"
            }
            onChange={(value, label) => {
              setEndOfDayFormDetails((prevState) => ({
                ...prevState,
                order_source: { value, label },
                initiators:
                  value === "INTERNAL" ? endOfDayFormDetails?.initiators : [],
              }));
            }}
          />

          {endOfDayFormDetails?.order_source?.value === "INTERNAL" ? (
            <primaryComponents.MultipleSelectFieldV2
              name="initiator"
              control={control}
              options={userList}
              idKey="id"
              nameKey="first_name"
              placeholder="Select Initiator(s)"
              value={endOfDayFormDetails?.initiators}
              onChange={(values) => {
                setEndOfDayFormDetails((prevState) => ({
                  ...prevState,
                  initiators: values,
                }));
              }}
            />
          ) : null}

          {/* Date range */}
          <div className={styles.daterangeSection}>
            <div>
              <label>
                <span className="error--text">*</span>Start Date / Time
              </label>
              <div className="d-flex">
                <primaryComponents.DateInput
                  name="startDate"
                  control={control}
                  classNames={`white ${styles.dateInput}`}
                  value={endOfDayFormDetails.startDate}
                  isRequired={true}
                  errorMessage={errors.startDate}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      startDate: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.startDate !== undefined && trigger("startDate")
                  }
                  register={register}
                />
                <primaryComponents.TimeInputV2
                  name="startTime"
                  control={control}
                  register={register}
                  classNames={styles.timeInput}
                  value={endOfDayFormDetails.startTime}
                  isRequired={true}
                  errorMessage={errors.startTime}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      startTime: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.startTime !== undefined && trigger("startTime")
                  }
                />
              </div>
            </div>

            <span>To</span>

            <div>
              <label>
                <span className="error--text">*</span>End Date / Time
              </label>
              <div className="d-flex">
                <primaryComponents.DateInput
                  name="endDate"
                  control={control}
                  value={endOfDayFormDetails.endDate}
                  classNames={`white ${styles.dateInput}`}
                  isRequired={true}
                  errorMessage={errors.endDate}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      endDate: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.endDate !== undefined && trigger("endDate")
                  }
                  register={register}
                />
                <primaryComponents.TimeInputV2
                  name="endTime"
                  control={control}
                  register={register}
                  classNames={styles.endTime}
                  value={endOfDayFormDetails.endTime}
                  isRequired={true}
                  errorMessage={errors.endTime}
                  onChange={({ target: { value } }) => {
                    setEndOfDayFormDetails((prevState) => ({
                      ...prevState,
                      endTime: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.endTime !== undefined && trigger("endTime")
                  }
                />
              </div>
            </div>
          </div>
        </form>
      </motion.div>

      <primaryComponents.Button
        onClick={handleSubmit(getEndOfDayDetails)}
        classNames="btn btn--primary cover mt-auto"
        isDisabled={isGeneratingDetails}
        isLoading={isGeneratingDetails}
        loadingText="Generating..."
        style={{ minHeight: "48px" }}
      >
        Generate End of Day
      </primaryComponents.Button>
    </>
  );
};
export default EndOfDayForm;
