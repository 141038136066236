import { useState } from "react";
import { useGetStoreWalletTransactionDetails } from "../../../../../hooks/api/queries";
import { useParams } from "react-router-dom";

const useStoreWalletTransactionDetails = () => {
  const params = useParams();
  const [tnxId, setTnxId] = useState(false);
  const {
    data: storeWalletTnxDetails,
    isLoading: isLoadingStoreWalletTnxDetails,
  } = useGetStoreWalletTransactionDetails(params?.id);

  return {
    storeWalletTnxDetails,
    isLoadingStoreWalletTnxDetails,
    tnxId,
    setTnxId,
  };
};

export default useStoreWalletTransactionDetails;
