import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetProductDetails,
  useGetTrackItems,
} from "../../../../hooks/api/queries";
import { cleanObject } from "../../../../helpers";
import { useEffect } from "react";

const useTrackItem = () => {
  const { id: product_id } = useParams();
  const [search, setSearch] = useSearchParams();
  const queries = (() => {
    let params = `?product_id=${product_id}`;
    for (const entry of search.entries()) {
      if (entry[0] !== "status") params = `${params}&${entry[0]}=${entry[1]}`;
    }
    return params;
  })();
  const { isLoading: isFetchingProductDetails, data: productDetails } =
    useGetProductDetails(product_id);
  const { isLoading: isFetchingTrackItems, data } = useGetTrackItems({
    isEnabled:
      productDetails?.variants?.length && search.get("variant_id")
        ? true
        : productDetails?.variants?.length
          ? false
          : productDetails
            ? true
            : false,
    queries,
    otherParams: cleanObject({ status: search.get("status") }),
  });

  useEffect(() => {
    if (productDetails?.variants?.length > 0)
      filterByVariant(productDetails?.variants?.[0]?.id);
  }, [productDetails]);

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const filterByVariant = (variantId) => {
    search.set("variant_id", variantId);
    setSearch(search, { replace: true });
  };

  const filterByStore = (storeId) => {
    search.delete("page");
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const filterByStatus = (value) => {
    search.delete("page");
    search.set("status", value);
    setSearch(search, { replace: true });
  };

  const searchTrackItem = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    search,
    storeList: productDetails?.stores,
    isFetchingTrackItems: isFetchingTrackItems || isFetchingProductDetails,
    trackedItems: data?.data || [],
    metaDetails: data?.meta || {},
    productDetails,
    filterByVariant,
    filterByStatus,
    filterByStore,
    gotoPage,
    searchTrackItem,
  };
};
export default useTrackItem;
