import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FundWallerValidationSchema = yup.object({
  customer: yup.string().required("Customer is required"),
  store: yup.string().required("Store is required"),
  amount: yup.string().required("Amount is required"),
  note: yup.string(),
});

export const Validation = ({ debitDetails }) => {
  return useForm({
    resolver: yupResolver(FundWallerValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customer: debitDetails?.customer?.customerId,
      store: debitDetails?.store_id,
      amount: debitDetails?.amount,
      note: debitDetails?.description,
    },
  });
};
