export const PlateIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79988 0.636402C2.79988 0.517053 2.75247 0.402593 2.66807 0.318201C2.49234 0.142463 2.20741 0.142463 2.03167 0.318201C1.94728 0.402593 1.89987 0.517053 1.89987 0.636402V4.95004H0.999862V0.604062C0.999862 0.50542 0.960676 0.410819 0.890926 0.341069C0.702559 0.152702 0.397156 0.152702 0.208789 0.341069C0.139039 0.410819 0.0998535 0.50542 0.0998535 0.604062V6.05006C0.0998535 6.93372 0.816205 7.65007 1.69987 7.65007C1.81033 7.65007 1.89987 7.73961 1.89987 7.85007V17.3823C1.89987 17.4898 1.94256 17.5929 2.01856 17.6688C2.20154 17.8518 2.49821 17.8518 2.68119 17.6688C2.75718 17.5929 2.79988 17.4898 2.79988 17.3823V7.85007C2.79988 7.73961 2.88942 7.65007 2.99988 7.65007C3.88354 7.65007 4.59989 6.93372 4.59989 6.05005V0.548947C4.59989 0.485595 4.57473 0.424837 4.52993 0.380041C4.32004 0.17015 3.97974 0.17015 3.76985 0.380041C3.72505 0.424837 3.69989 0.485595 3.69989 0.548947V4.95004H2.79988V0.636402Z"
        fill="#7647EE"
      />
      <path
        d="M19.8644 0.449829C18.3929 0.449829 17.2 1.64277 17.2 3.11433V9.27344C17.2 9.3709 17.279 9.44991 17.3764 9.44991H18.7055C18.8681 9.44991 19 9.58174 19 9.74436V17.4291C19 17.5065 19.0307 17.5807 19.0855 17.6355C19.2868 17.8368 19.6132 17.8368 19.8145 17.6355C19.8692 17.5807 19.9 17.5065 19.9 17.4291V0.485356C19.9 0.465735 19.8841 0.449829 19.8644 0.449829Z"
        fill="#7647EE"
      />
      <path
        d="M17.3037 11.9525C17.4018 11.7554 17.36 11.5241 17.2161 11.3575C16.9064 10.9989 16.2428 11.0761 16.021 11.4948C14.9639 13.4905 12.8658 14.85 10.4501 14.85C8.12594 14.85 6.09571 13.5915 5.00376 11.7187C4.75434 11.2909 4.01654 11.2672 3.75285 11.6863C3.65031 11.8493 3.64036 12.0446 3.73271 12.2136C5.03094 14.5889 7.55238 16.2001 10.4501 16.2001C13.4528 16.2001 16.0515 14.4701 17.3037 11.9525Z"
        fill="#7647EE"
      />
      <path
        d="M14.6828 3.88357C15.0385 4.20632 15.6996 4.04638 15.8603 3.59383C15.9324 3.39079 15.8897 3.16664 15.7339 3.01779C14.3606 1.70573 12.4995 0.899918 10.4501 0.899918C8.59757 0.899918 6.89881 1.55842 5.57515 2.65412C5.4349 2.77021 5.36944 2.94618 5.3913 3.12692C5.45285 3.63562 6.17294 3.89617 6.57687 3.58087C7.64531 2.74686 8.98969 2.24993 10.4501 2.24993C12.0795 2.24993 13.5644 2.86848 14.6828 3.88357Z"
        fill="#7647EE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5002 8.54999C13.5002 10.2345 12.1346 11.6 10.4501 11.6C8.76564 11.6 7.4001 10.2345 7.4001 8.54999C7.4001 6.8655 8.76564 5.49996 10.4501 5.49996C12.1346 5.49996 13.5002 6.8655 13.5002 8.54999ZM12.1501 8.54999C12.1501 9.48888 11.389 10.25 10.4501 10.25C9.51124 10.25 8.75011 9.48888 8.75011 8.54999C8.75011 7.61109 9.51124 6.84997 10.4501 6.84997C11.389 6.84997 12.1501 7.61109 12.1501 8.54999Z"
        fill="#7647EE"
      />
    </svg>
  );
};
