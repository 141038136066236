import { RequestLoader } from "../../../hooks/context";
import { useContext, useEffect } from "react";
import { getRandomIntInclusive } from "../../../helpers";

const RequestLoaderTriggerLogic = () => {
  const { setRequestLoaderProgress, requestLoaderProgress } =
    useContext(RequestLoader);

  useEffect(() => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    setTimeout(() => {
      setRequestLoaderProgress(getRandomIntInclusive(51, 70));
    }, 200);
    setTimeout(() => {
      setRequestLoaderProgress(90);
    }, 300);
    setTimeout(() => {
      setRequestLoaderProgress(100);
    }, 400);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (requestLoaderProgress === 0 || requestLoaderProgress > 90)
        return clearInterval(interval);
      setRequestLoaderProgress((prevState) => prevState + 10);
    }, 1000);

    return () => clearInterval(interval);
  });

  return null;
};
export default RequestLoaderTriggerLogic;
