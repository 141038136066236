export const StorefrontIcon = () => {
  return (
    <svg
      width="66"
      height="56"
      viewBox="0 0 66 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3991 53.9447C25.2836 53.9447 26.8114 52.4169 26.8114 50.5324C26.8114 48.6478 25.2836 47.1201 23.3991 47.1201C21.5145 47.1201 19.9868 48.6478 19.9868 50.5324C19.9868 52.4169 21.5145 53.9447 23.3991 53.9447Z"
        stroke="#696677"
        strokeWidth="3.23268"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.6967 53.9447C52.5812 53.9447 54.109 52.4169 54.109 50.5324C54.109 48.6478 52.5812 47.1201 50.6967 47.1201C48.8122 47.1201 47.2844 48.6478 47.2844 50.5324C47.2844 52.4169 48.8122 53.9447 50.6967 53.9447Z"
        stroke="#696677"
        strokeWidth="3.23268"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.05566 2.22266H13.9986C13.9986 2.22266 15.4077 8.46176 16.3105 12.4595"
        stroke="#F7AD3C"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0912 37.8558L10.3557 12.46H17.1045H23.8534H30.6022H37.351H44.0999H50.8487H57.5975H64.3464L58.7093 37.8265C58.0156 40.9487 55.2462 43.1704 52.0475 43.1704H48.3849H44.7223H41.0597H37.3971H33.7344H30.0718H26.4092H22.7466C19.5567 43.1704 16.7939 40.9671 16.0912 37.8558Z"
        fill="#F7AD3C"
      />
      <path
        d="M22.7466 43.1704C19.5567 43.1704 16.7939 40.9671 16.0912 37.8558C14.6375 31.4193 12.1597 20.4481 10.3557 12.46H17.1045M22.7466 43.1704H26.4092H30.0718M22.7466 43.1704L17.1045 12.46M52.0475 43.1704C55.2462 43.1704 58.0156 40.9487 58.7093 37.8265L64.3464 12.46H57.5975M52.0475 43.1704H48.3849H44.7223M52.0475 43.1704L57.5975 12.46M17.1045 12.46H23.8534H30.6022M57.5975 12.46H50.8487H44.0999M44.0999 12.46H37.351H30.6022M44.0999 12.46L44.7223 43.1704M30.6022 12.46L30.0718 43.1704M30.0718 43.1704H33.7344H37.3971H41.0597H44.7223"
        stroke="white"
        strokeWidth="3.23268"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
