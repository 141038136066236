import { motion } from "framer-motion";
import secondaryComponents from "../../../../../components/secondaryComponents";
import styles from "./AllReconciliations.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import Icon from "../../../../../assets/icons";
import useAllReconciliations from "./useAllReconciliations";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const AllReconciliations = () => {
  const {
    isFetchingReconciliations,
    reconciliations,
    metaDetails,
    storeList,
    storeHasMultipleCurrency,
    isOpenReconciliation,
    search,
    filterByStoreId,
    handleFetchReconciliations,
    setIsOpenReconciliation,
    gotoPage,
  } = useAllReconciliations();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Reconciliations"
      description="View all reconciliation records"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.allReconciliations} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Reconciliations</h4>
            <p>
              Compare and adjust your inventory records with your physical
              inventory counts here
            </p>
          </div>
          <div className={styles.allReconciliations__header}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_create_and_submit_reconciliation"
            >
              <primaryComponents.Button
                classNames="btn btn--primary ml-auto"
                onClick={() => setIsOpenReconciliation(true)}
              >
                <img
                  className="mr-2"
                  src={Icon.reconsileIcon}
                  alt="reconcile icon"
                />
                Reconcile Stock
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <div className={styles.allReconciliations__content}>
            <div className={styles.allReconciliations__content__header}>
              <div style={{ width: "200px" }}>
                <primaryComponents.SelectField
                  options={
                    storeHasMultipleCurrency
                      ? [...storeList]
                      : [
                          {
                            name: "All stores",
                            id: "ALL_STORES",
                          },
                          ...storeList,
                        ]
                  }
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    filterByStoreId(value);
                  }}
                  value={
                    storeList?.find(
                      (storeItem) =>
                        `${storeItem?.id}` === `${search.get("store_id")}`,
                    )?.name || "All stores"
                  }
                  floatError={false}
                  classNames="store-select"
                />
              </div>
            </div>
            {/* table section */}
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingReconciliations}
              classNames="pt-5"
            >
              <secondaryComponents.ReconciliationsTable
                reconciliations={reconciliations}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                handleFetchReconciliations={handleFetchReconciliations}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          <secondaryComponents.Modal
            isActive={isOpenReconciliation}
            closeModal={() => setIsOpenReconciliation(false)}
            width="450px"
          >
            <secondaryComponents.ReconcilePrompt
              closeModal={() => setIsOpenReconciliation(false)}
              handleFetchReconciliations={handleFetchReconciliations}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllReconciliations;
