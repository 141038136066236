import "./CashflowTrend.scss";
import {
  LineChart,
  XAxis,
  Line,
  CartesianGrid,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CashflowTrendLogic from "./CashflowTrendLogic";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";
import Images from "../../../assets/images";
import { useSelector } from "react-redux";

const CashflowTooltip = ({ active, payload, label }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  if (active) {
    return (
      <div className="revenue-chart-tooltip">
        <p style={{ fontSize: "12px", color: "#19AE57" }}>
          {formatCurrency({
            value: payload?.[0]?.value,
            currencyCode: currency_code,
          })}
        </p>
        <p style={{ fontSize: "12px", color: "#FD561F" }}>
          {formatCurrency({
            value: payload?.[1]?.value,
            currencyCode: currency_code,
          })}
        </p>
        <p className="dark--text" style={{ fontSize: "13px" }}>
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

const CashflowTrend = ({ cashflowTrend, cashFlowData }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    yAxisFormat,
    tickFormatter,
    showInfowLine,
    showOutfowLine,
    setShowInflowLine,
    setShowOutflowLine,
  } = CashflowTrendLogic();

  return (
    <div className="cashflow-trend">
      <h4 style={{ fontSize: "20px" }} className="font-weight-semibold">
        Cash Flow Analytics
      </h4>

      {cashflowTrend.length > 0 ? (
        <>
          <div className="cashflow-trend__actions">
            <div
              className={`cashflow-trend__actions__item ${
                showInfowLine && "active"
              } inflow`}
              onClick={() => setShowInflowLine(!showInfowLine)}
            >
              <span className="icon inflow"></span>
              <span className="title inflow">
                Inflow -{" "}
                {formatCurrency({
                  value: cashFlowData.cash_inflow,
                  currencyCode: currency_code,
                })}
              </span>
            </div>
            <div
              className={`cashflow-trend__actions__item ${
                showOutfowLine && "active"
              } cashflow`}
              onClick={() => setShowOutflowLine(!showOutfowLine)}
            >
              <span className="icon cashflow"></span>
              <span className="title cashflow">
                Outflow -{" "}
                {formatCurrency({
                  value: cashFlowData.cash_outflow,
                  currencyCode: currency_code,
                })}
              </span>
            </div>
          </div>
          <ResponsiveContainer
            width="100%"
            aspect={2}
            className="cashflow-trend__chart"
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <LineChart width={500} height={300} data={cashflowTrend}>
              <CartesianGrid
                stroke="#E8E9EB"
                strokeDasharray="5 0"
                horizontal={true}
                vertical={false}
              />
              <YAxis
                tick={{ fontSize: "10px" }}
                tickLine={false}
                axisLine={false}
                tickFormatter={yAxisFormat}
              />
              <XAxis
                dataKey="date"
                tickLine={false}
                minTickGap={2}
                tick={{ fontSize: "10px" }}
                axisLine={false}
                tickFormatter={tickFormatter}
              />
              <Tooltip
                wrapperStyle={{ border: "none", outline: "none" }}
                content={<CashflowTooltip />}
              />
              <Line
                dataKey="total_inflow"
                stroke={`${showInfowLine ? "#19AE57" : "transparent"}`}
                dot={false}
              />
              <Line
                dataKey="total_outflow"
                stroke={`${showOutfowLine ? "#FD561F" : "transparent"}`}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>{" "}
        </>
      ) : (
        <div className="mt-5 text-center">
          <img
            width={"150px"}
            src={Images.transaction_empty_state}
            alt="No transactions"
          />
          <p className="font-weight-semibold mt-3 dark--text">
            No data available
          </p>
        </div>
      )}
    </div>
  );
};

export default CashflowTrend;
