import style from "./BulkEditProduct.module.scss";
import primaryComponents from "../../primaryComponents";
import Validation from "./Validation";
import BulkEditProductLogic from "./useBulkEditProductLogic";
import LoaderHelper from "../LoaderHelper/LoaderHelper";

const BulkEditProduct = ({
  selectedProducts,
  closeModal,
  handleFetchproducts,
  setSelectedProducts,
  currentStore,
}) => {
  const {
    pricingTypes,
    isLoadingPricingTypes,
    productsDetails,
    controlPrice,
    setControlPrice,
    setProductsDetails,
    handleBulkEditProduct,
    createBulkUpdateProductPriceMutation,
  } = BulkEditProductLogic({
    selectedProducts,
    closeModal,
    handleFetchproducts,
    setSelectedProducts,
  });
  const {
    handleSubmit,
    control,
    trigger,
    register,
    formState: { errors },
  } = Validation({
    productsDetails,
  });
  return (
    <>
      <div className={style.container}>
        <div className="items-center">
          <h4 className="text-center font-weight-bold">Bulk Edit</h4>
          <p className="text-center smallest-text-size">
            Update multiple product properties
          </p>
        </div>

        <h5>Current Store: {currentStore}</h5>
        <form
          onSubmit={handleSubmit(() => {
            handleBulkEditProduct();
          })}
        >
          <div
            className="d-flex"
            style={{
              flexDirection: "column",
              gap: "15px",
              marginBottom: "11em",
            }}
          >
            <div className="w-100">
              <primaryComponents.SelectField
                value={"Select Action"}
                control={control}
                name="pricing"
                options={[
                  "Increase Selling Pricing",
                  "Decrease Selling Pricing",
                ]}
                onChange={(value) => {
                  value === "Increase Selling Pricing"
                    ? setControlPrice("+")
                    : setControlPrice("-");
                }}
                isSearchable={false}
                floatError={false}
                nameKey="label"
                idKey="id"
                register={register}
                errorMessage={errors.pricing}
              />
            </div>
            <div className={`white w-100 ${style.pricing}`}>
              <div className="d-flex justify-content-between p-2">
                <h5>
                  {controlPrice === "+"
                    ? "Increase"
                    : controlPrice === "-"
                      ? "Decrease"
                      : "Increase/Decrease"}{" "}
                  Selling Pricing by
                </h5>
              </div>

              <primaryComponents.InputFieldV2
                control={control}
                name="percentage_change"
                classNames="white"
                isDisabled={false}
                placeholder="Enter value from 1-100%"
                onChange={({ target: { value } }) => {
                  setProductsDetails((prev) => ({
                    ...prev,
                    percentage_change: controlPrice + value,
                  }));
                }}
                giveControl={false}
                onKeyUp={() =>
                  errors.percentage_change !== undefined &&
                  trigger("percentage_change")
                }
                register={register}
                errorMessage={errors.percentage_change}
              />
            </div>
            <div className="w-100">
              <LoaderHelper
                isLoading={isLoadingPricingTypes}
                classNames={"mt-2"}
              >
                <primaryComponents.SelectField
                  value={"Select Pricing type(s)"}
                  control={control}
                  name="pricing_type_id"
                  options={pricingTypes}
                  onChange={(value, label) => {
                    setProductsDetails((prev) => ({
                      ...prev,
                      pricing_type_id: value,
                    }));
                  }}
                  isSearchable={false}
                  floatError={false}
                  nameKey="label"
                  idKey="id"
                  register={register}
                />
              </LoaderHelper>
            </div>
          </div>

          <primaryComponents.Button
            classNames={"btn btn--primary w-100"}
            isDisabled={createBulkUpdateProductPriceMutation.isLoading}
            isLoading={createBulkUpdateProductPriceMutation.isLoading}
          >
            Apply
          </primaryComponents.Button>
        </form>
      </div>
    </>
  );
};

export default BulkEditProduct;
