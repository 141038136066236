import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    isWalletPayment: yup.boolean(),
    subAccount: yup.string().when("isWalletPayment", {
      is: true,
      then: yup.string().required("Select sub account"),
    }),
  })
  .required();

const Validation = ({ walletDetails, isWalletPayment }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      subAccount: walletDetails?.wallet_id,
      isWalletPayment,
    },
  });
};

export default Validation;
