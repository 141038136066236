import { usePDF } from "@react-pdf/renderer";
import { useEffect } from "react";
import { MobileDoc } from "./MobileDoc";

export const MobilePrint = ({
  address,
  business_name,
  sectionDetails,
  clearAfterPrint,
}) => {
  const [instance] = usePDF({
    document: (
      <MobileDoc
        address={address}
        business_name={business_name}
        sectionDetails={sectionDetails}
      />
    ),
  });

  useEffect(() => {
    if (sectionDetails && !instance?.loading)
      handlePrintOnMobile({ url: instance?.url });
  }, [sectionDetails, instance?.loading]);

  const handlePrintOnMobile = ({ url }) => {
    window.open(url);
    clearAfterPrint();
  };

  return <></>;
};
