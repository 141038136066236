/* eslint-disable react/jsx-key */
import "./TerminalsTable.scss";
import useTerminalsTable from "./TerminalsTableLogic";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import Images from "../../../assets/images";

const TerminalsTable = () => {
  const { instance, isLoading, search, searchTerminal } = useTerminalsTable();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="terminals-table">
      <div className="terminals-table__header">
        {/* header section */}
        <div style={{ minWidth: "250px", width: "700px" }}>
          <primaryComponents.SearchField
            trigger={(value) => searchTerminal(value)}
            value={search.get("query") || ""}
            placeholder="Search for a terminal"
            classNames="search-section"
          />
        </div>
      </div>
      <div className="table-section">
        <secondaryComponents.LoaderHelper
          isLoading={isLoading}
          classNames="mt-5"
        >
          <secondaryComponents.TableHelper
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          >
            <div className="text-center mt-5 mb-5">
              <img
                width="250px"
                height="262px"
                src={Images.tnxEmptyState}
                alt="empty state"
              />
              <h4>No data available</h4>
            </div>
          </secondaryComponents.TableHelper>
        </secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};

export default TerminalsTable;
