/* eslint-disable react/jsx-key */
import styles from "./LoyaltyCustomerTransactionTable.module.scss";
import useLoyaltyCustomerTransactionTable from "./useLoyaltyCustomerTransactionTable";
import Images from "../../../assets/images";

const LoyaltyCustomerTransactionTable = ({ data = [] }) => {
  const { instance, customers } = useLoyaltyCustomerTransactionTable({ data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.loyaltyTable}>
      <div className={styles.tableSection}>
        {customers.length > 0 ? (
          <>
            {/* show on desktop */}
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps({ key: i })}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* display on mobile */}
            <div className={styles.mobileTable}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div
                    className={styles.mobileTable__row}
                    {...row.getRowProps({ key: i })}
                  >
                    {row.cells.map((cell, j) => (
                      <div
                        className={styles.rowItem}
                        {...cell.getCellProps({ key: j })}
                      >
                        <h6 className={styles.rowItem__title}>
                          {cell.column.Header}
                        </h6>
                        <p className={styles.rowItem__value}>
                          {cell.render("Cell")}
                        </p>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="text-center mt-5">
            <img
              src={Images.customersEmptyState}
              alt="No history Found"
              width="250px"
              height="262px"
            />
            <h4>You have no history</h4>
            <p>When you do, they would appear here.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoyaltyCustomerTransactionTable;
