import { useState, useContext } from "react";
import { updateTransfer } from "../../../services/transfers";
import { getRandomIntInclusive } from "../../../helpers";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useQueryClient } from "react-query";

const TransferApprovalModalLogic = ({ closeModal, transferDetails }) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [password, setPassword] = useState("");
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();

  const updateTransferStatus = (status) => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 50));
    if (status === "accepted") {
      setIsApproving(true);
    } else setIsRejecting(true);
    updateTransfer({ status: status, password: password }, transferDetails.id)
      .then(() => {
        let toastType = status === "accepted" ? "success" : "warning";
        let toastMessage =
          status === "accepted"
            ? "Transfer approved successfully"
            : "Transfer rejected succesfully";
        triggerToast(toastMessage, toastType);
        if (status === "accepted") {
          setIsApproving(false);
        } else setIsRejecting(false);
        setRequestLoaderProgress(100);
        queryClient.invalidateQueries(["getTransfers"]);
        queryClient.invalidateQueries(["getDashboardDetails"]);
        queryClient.invalidateQueries(["getProfileDetails"]);
        queryClient.invalidateQueries(["businessDetails"]);
        closeModal();
      })
      .catch(() => {
        setRequestLoaderProgress(100);
        if (status === "accepted") {
          setIsApproving(false);
        } else setIsRejecting(false);
      });
  };

  return {
    isRejecting,
    isApproving,
    password,
    updateTransferStatus,
    setPassword,
  };
};
export default TransferApprovalModalLogic;
