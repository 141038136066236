import React from "react";

export const One = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13.0004C1 10.926 1.53775 8.88703 2.56077 7.08244C3.5838 5.27785 5.05716 3.76928 6.83709 2.70392C8.61702 1.63857 10.6427 1.05282 12.7166 1.00384C14.7904 0.95485 16.8415 1.4443 18.6697 2.42443"
        stroke="#7647EE"
        strokeWidth="0.888883"
      />
      <path
        d="M24.9995 13C24.9995 15.6151 24.1452 18.1586 22.5667 20.2435C20.9881 22.3284 18.7717 23.8406 16.2547 24.55C13.7377 25.2594 11.0578 25.1271 8.62285 24.1733C6.1879 23.2195 4.13118 21.4964 2.76562 19.2662"
        stroke="#7647EE"
        strokeWidth="0.888883"
      />
      <circle cx="13.0005" cy="13.0005" r="10.2856" fill="#1E016F" />
      <path
        d="M12.3889 17.7148V9.76061L10.8392 10.1172V9.04747L12.9649 8.11491H13.8974V17.7148H12.3889Z"
        fill="white"
      />
    </svg>
  );
};
