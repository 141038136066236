import TableHelper from "../TableHelper";
import Images from "../../../assets/images";
import styles from "./InvoiceHistoryTable.module.scss";

import { useInvoiceHistoryTable } from "./useInvoiceHistoryTable";

const InvoiceHistoryTable = ({ historyData }) => {
  const { instance } = useInvoiceHistoryTable({ historyData });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.invoiceHistoryTable}>
      <TableHelper
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        handleScroll={true}
      >
        <div
          className="px-4 text-center mt-5 mb-5 mx-auto"
          style={{ maxWidth: "650px" }}
        >
          <img src={Images.bulkRestockEmptyState} width="250px" alt="" />
          <h5 className="mt-4">
            Restock products by using barcode scanning or simply searching by
            product name, ID or SKU
          </h5>
          <p>
            1.Click on the search box to use barcode scanning or to search for a
            product <br />
            2.Products you search with barcode scanning will automatically be
            pre-filled for restock
          </p>
        </div>
      </TableHelper>
    </div>
  );
};

export default InvoiceHistoryTable;
