import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useGetCreditLog } from "../../../../../hooks/api/queries/useInventory";
import { useSelector } from "react-redux";
import { useStoreCurrency } from "../../../../../hooks/useStoreCurrency";

export const useCreditLog = () => {
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const queryParams = new URLSearchParams(window.location.search);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      search.set("store_id", storeList[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  useEffect(() => {
    if (!search.get("credit_only")) {
      search.set("credit_only", "true");
      setSearch(search, { replace: true });
    }
  }, []);

  const {
    isLoading: isFetchingCredits,
    data: creditLog,
    refetch: handleFetchCredits,
  } = useGetCreditLog(queries);

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchCredits = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isFetchingCredits,
    creditLog: creditLog?.data || [],
    metaDetails: creditLog?.meta || {},
    search,
    filterByStoreId,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    setCurrentStore,
    handleFetchCredits,
    gotoPage,
    searchCredits,
  };
};
