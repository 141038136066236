import "./SubAccounts.scss";
import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import SubAccountsLogic from "./SubAccountsLogic";
import Images from "../../../../../assets/images";
import { useState, useEffect } from "react";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const AllSubAccounts = () => {
  const { isFetchingAccounts, subAccountsList, triggerToast } =
    SubAccountsLogic();
  const [copiedAccount, setCopiedAccount] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (copiedAccount === false) return;
    setTimeout(() => {
      setCopiedAccount(false);
    }, 5000);
  }, [copiedAccount]);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Sub Accounts"
      description="View all sub accounts created"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={globalStyles.pageColumnGaps}
        >
          <div>
            <h4 className="mb-0">Sub Accounts</h4>
            <p>
              A flexible way to create and operate different business accounts
            </p>
          </div>

          <div>
            <primaryComponents.Button
              classNames="ml-auto btn btn--primary"
              onClick={() => navigate("create")}
            >
              + Add Sub-account
            </primaryComponents.Button>
          </div>

          <div>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingAccounts}
              classNames="mt-5"
            >
              {subAccountsList?.length > 0 ? (
                <div className="sub-accounts__users">
                  {subAccountsList?.map((account, index) => (
                    <secondaryComponents.SubAccountCard
                      account={account}
                      index={index % 4}
                      key={account.business_id + index}
                      triggerToast={triggerToast}
                    />
                  ))}
                </div>
              ) : (
                <div className="mt-5 text-center">
                  <img
                    width="250px"
                    className="mb-4 mx-auto"
                    src={Images.subAccountEmptyState}
                    alt="empty state"
                  />
                  <h4>You don&apos;t have any sub-account yet</h4>
                </div>
              )}
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllSubAccounts;
