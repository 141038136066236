import { useEffect, useState } from "react";
import {
  useGetStoreWalletDetails,
  useGetStoreWalletTransaction,
} from "../../../../../hooks/api/queries";
import { useParams, useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useUrlSearch } from "../../../../../hooks/useUrlSearch";

const useStoreWalletsDetails = () => {
  const params = useParams();
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const [isFundWallet, setIsFundWallet] = useState(null);
  const [isDebitWallet, setIsDebitWallet] = useState(null);
  const [isDownloadStatement, setIsDownloadStatement] = useState(false);
  const { data: storeWalletDetails, isLoading: isLoadingStoreWalletDetails } =
    useGetStoreWalletDetails(params?.id);
  const { data, isLoading: isLoadingStoreWalletTnx } =
    useGetStoreWalletTransaction({ queries });
  const { gotoPage } = useUrlSearch();

  useEffect(() => {
    if (!search.get("wallet_id")) {
      search.set("store_wallet_id", params?.id);
      setSearch(search, { replace: true });
    }
  }, []);

  return {
    storeWalletDetails,
    isLoadingStoreWalletDetails,
    isLoadingStoreWalletTnx,
    storeWalletTransactions: data?.data || [],
    metaDetails: data?.meta || {},
    isFundWallet,
    isDebitWallet,
    isDownloadStatement,
    setIsDownloadStatement,
    setIsDebitWallet,
    setIsFundWallet,
    gotoPage,
  };
};

export default useStoreWalletsDetails;
