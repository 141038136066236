import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import styles from "./SubscribeUpgradeNotification.module.scss";
import secondaryComponents from "..";
import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import { formatCurrency, getPlatform } from "../../../helpers";
import { useActiveSubscriptionQuery } from "../../../hooks/api/queries";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { platformHandler } from "../../../hooks/context";
import { useDispatch, useSelector } from "react-redux";
import { setApiErrorMessage } from "../../../store/modules/apiErrorHandler";

const SubscribeUpgradeNotification = ({ children }) => {
  const { data: activeSubscription } = useActiveSubscriptionQuery(false);
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const dispatch = useDispatch();
  const { message, isSubscriptionNotification } = useSelector(
    (state) => state.apiErrorReducer,
  );

  const closeApiErrorHandler = () => {
    dispatch(
      setApiErrorMessage({ message: null, isSubscriptionNotification: false }),
    );
  };

  return (
    <>
      {children}
      <secondaryComponents.Modal
        width={"600px"}
        closeModal={closeApiErrorHandler}
        isActive={isSubscriptionNotification}
      >
        <div className={`px-4 py-4 ${styles.subscribeUpgradeNotification}`}>
          <h4 className="text-center">Upgrade Your Plan </h4>

          <div
            className={`${globalStyles.cardWithBorder} ${styles.messageSection}`}
          >
            <img src={Icon.errorAlarm} alt="notify" />
            <p className="dark--text">{message}</p>
          </div>

          <div
            className={`${globalStyles.cardWithBackground} ${styles.planSection}`}
          >
            <primaryComponents.Button
              classNames={`btn btn--outline ${styles.currentPlan}`}
            >
              Your current Plan
            </primaryComponents.Button>

            <p
              className={`${globalStyles.plan} ${
                activeSubscription?.pricing?.tier?.name === "Essential"
                  ? globalStyles.essentialPlan
                  : activeSubscription?.pricing?.tier?.name === "Growth"
                    ? globalStyles.growthPlan
                    : activeSubscription?.pricing?.tier?.name === "Scale"
                      ? globalStyles.scalePlan
                      : ""
              } mx-auto`}
            >
              {activeSubscription?.pricing?.tier?.name}
            </p>

            <h5>
              {formatCurrency({
                value: activeSubscription?.amount_paid,
                currencyCode: "NGN",
                decimal: 0,
              })}
            </h5>
          </div>

          <div className="mt-4">
            <h5>What you can do:</h5>
            <div className={`${globalStyles.cardWithBorder}`}>
              <secondaryComponents.CountryItemPermission>
                <p className="dark--text">
                  1. Click the{" "}
                  <span className="font-weight-semibold">“Upgrade”</span> button
                  below to upgrade to a different plan.
                </p>
                <p className="dark--text text-center">OR</p>
              </secondaryComponents.CountryItemPermission>

              <p className="dark--text">
                <secondaryComponents.CountryItemPermission>
                  2.{" "}
                </secondaryComponents.CountryItemPermission>{" "}
                Contact our support team
                <span className="purple--text">
                  {" "}
                  customerservice@enlumidata.com
                </span>{" "}
                for assistance.
              </p>
            </div>
          </div>

          <secondaryComponents.CountryItemPermission>
            <primaryComponents.Button
              classNames="mt-auto btn btn--primary cover"
              onClick={() => {
                closeApiErrorHandler();
                navigate(
                  `${getPlatform(platform)}/settings/subscriptions/plans`,
                );
              }}
            >
              Upgrade
            </primaryComponents.Button>
          </secondaryComponents.CountryItemPermission>
        </div>
      </secondaryComponents.Modal>
    </>
  );
};
export default SubscribeUpgradeNotification;
