import { useMemo, useState, useEffect, useContext } from "react";
import { platformHandler, RequestLoader } from "../../../hooks/context";
import { useTable } from "react-table";
import { useNavigate } from "react-router-dom";
import { fetchPendingRequest } from "../../../services/pos-terminals";
import {
  getPlatform,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";

const useTerminalRequestTable = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [terminalsRequest, setTerminalsRequest] = useState([]);
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : "",
  );
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);

  useEffect(() => {
    getPendingTerminalsRequest();
  }, []);

  const getPendingTerminalsRequest = () => {
    setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    let queries = getUrlQuerysection();
    fetchPendingRequest(queries)
      .then((response) => {
        setTerminalsRequest(response.data.data);
        setRequestLoaderProgress(100);
        setIsLoading(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
      });
  };

  const searchTerminal = (value) => {
    navigate(`${getPlatform(platform)}/pos/request?query=${value}`);
    getPendingTerminalsRequest();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Request Date",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "No of Requested Devices",
        accessor: "quantity",
      },
      {
        Header: "Requested By",
        accessor: "requested_by",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${
              value === "APPROVED"
                ? "success--squared"
                : value === "PENDING"
                  ? "pending--squared"
                  : "failed--text"
            }`}
          >
            {value}
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: terminalsRequest });

  return {
    instance,
    isLoading,
    searchTerminal,
    searchValue,
    terminalsRequest,
  };
};

export default useTerminalRequestTable;
