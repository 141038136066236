import styles from "./CampaignList.module.scss";
import CampaignCard from "../CampaignCard/CampaignCard";
import Images from "../../../assets/images";

const CampaignList = ({ campaigns }) => {
  return (
    <>
      {campaigns?.length > 0 ? (
        <div className={styles.campaignList}>
          {campaigns?.map((campaign) => (
            <CampaignCard key={campaign.id} campaignDetails={campaign} />
          ))}
        </div>
      ) : (
        <div className="text-center mt-5">
          <img
            width="250px"
            height="262px"
            src={Images.campaignEmptyState}
            alt="empty state"
          />
          <h4>You are yet to setup loyalty for your business</h4>
          <p>Click the setup Loyalty button above to get started.</p>
        </div>
      )}
    </>
  );
};

export default CampaignList;
