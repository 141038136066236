import secondaryComponents from "../..";
import Validation from "./Validation";
import { useEffect } from "react";
import primaryComponents from "../../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const TransferForm = ({ fundTransferLogic, closeWidget, externalRequest }) => {
  const {
    transferDetails,
    setTransferDetails,
    bankList,
    isFetchingBankList,
    handleResolveAccountNumber,
    businessList,
    tagsList,
    handleNextStep,
  } = fundTransferLogic;

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    trigger,
    register,
    formState: { errors },
  } = Validation({
    bank: transferDetails?.bank_code,
    accountNumber: transferDetails?.account_number,
    amount: transferDetails?.amount,
    transferDescription: transferDetails?.description,
    subAccount: transferDetails?.business_id,
    useExternalDetails: externalRequest?.useExternalDetails || false,
  });

  useEffect(() => {
    window.setAccountError = setError;
    window.clearAccountErrors = clearErrors;
  }, []);

  return (
    <motion.div
      initial={{ y: 50 }}
      animate={{ y: 0 }}
      data-testid="transfer-form"
    >
      <div className="d-flex justify-content-between">
        <h4 className="font-weight-semibold">
          {externalRequest?.transferTitle || "Transfer Funds"}
        </h4>
        <FontAwesomeIcon
          onClick={() => closeWidget(false)}
          style={{ fontSize: "25px" }}
          icon="times"
        />
      </div>
      <p className="mb-4">
        {externalRequest?.transferDesc ||
          "Provide the following details for your withdrawal"}
      </p>

      {!externalRequest?.useExternalDetails ? (
        <primaryComponents.Button
          className="btn btn--outline ml-auto"
          style={{ height: "35px", padding: "0 15px", fontSize: "12px" }}
          onClick={() => fundTransferLogic.navigation.go(2)}
          data-testid="find-beneficiary"
        >
          Find Beneficiary
        </primaryComponents.Button>
      ) : null}

      <form onSubmit={handleSubmit(handleNextStep)} className="transfer-form">
        {/* select sub account */}
        <primaryComponents.SelectFieldV2
          name="subAccount"
          control={control}
          label="Account"
          isRequired={true}
          value={
            transferDetails?.business_id === ""
              ? "Select account..."
              : transferDetails?.business_name
          }
          options={businessList}
          nameKey="tag"
          idKey="id"
          onChange={(value, label) => {
            setTransferDetails((prev) => ({
              ...prev,
              business_id: value,
              business_name: label,
            }));
          }}
          errorMessage={errors.subAccount}
        />

        {!externalRequest?.useExternalDetails ? (
          <>
            {/* select bank name */}
            {transferDetails?.business_id !== "" ? (
              <secondaryComponents.LoaderHelper isLoading={isFetchingBankList}>
                <primaryComponents.SelectFieldV2
                  control={control}
                  name="bank"
                  label="Bank"
                  isRequired={true}
                  value={
                    transferDetails?.bank_code === ""
                      ? "Select Bank"
                      : transferDetails?.bank_name
                  }
                  options={bankList}
                  nameKey="bank_name"
                  idKey="bank_code"
                  onChange={(value, label) => {
                    setTransferDetails((prev) => ({
                      ...prev,
                      bank_code: value,
                      bank_name: label,
                    }));
                    if (transferDetails?.account_number !== "")
                      handleResolveAccountNumber(
                        value,
                        transferDetails?.account_number,
                      );
                  }}
                  errorMessage={errors.bank}
                />
              </secondaryComponents.LoaderHelper>
            ) : null}
          </>
        ) : (
          <primaryComponents.InputField
            value={transferDetails?.bank_name}
            classNames="white"
            isDisabled={true}
            label="Bank Name"
          />
        )}

        {/* account number */}
        <div className="mb-3" style={{ position: "relative" }}>
          <primaryComponents.InputFieldV2
            control={control}
            name="accountNumber"
            label="Account Number"
            classNames="white"
            isRequired
            isDisabled={externalRequest?.useExternalDetails}
            placeholder="Enter 10-digit account number"
            value={transferDetails?.account_number}
            floatError={false}
            onChange={({ target: { value } }) => {
              setTransferDetails((prev) => ({
                ...prev,
                account_number: value,
              }));
              if (value.length === 10)
                handleResolveAccountNumber(transferDetails?.bank_code, value);
            }}
            onKeyUp={() =>
              errors.account_number !== undefined && trigger("account_number")
            }
            register={register}
            errorMessage={errors.account_number}
          />
          {transferDetails?.account_name ? (
            <p className="purple--text">{transferDetails?.account_name}</p>
          ) : null}
        </div>

        {/* amount field */}
        <primaryComponents.AmountFieldV2
          control={control}
          name="amount"
          label="Amount"
          classNames="white"
          isRequired
          isDisabled={externalRequest?.useExternalDetails}
          placeholder="Enter Amount"
          value={transferDetails?.amount}
          onChange={({ target: { value } }) => {
            setTransferDetails((prev) => ({
              ...prev,
              amount: value,
            }));
          }}
          onKeyUp={() => errors.amount !== undefined && trigger("amount")}
          register={register}
          errorMessage={errors.amount}
        />

        {/* tag  */}
        <primaryComponents.MultipleSelectFieldV2
          control={control}
          label="Add tag"
          isRequired={false}
          placeholder="Add tag"
          name="tags"
          errorMessage={errors.tags}
          options={tagsList}
          idKey="id"
          nameKey="name"
          value={(() => {
            let newArray = [];
            transferDetails?.tag_ids?.forEach((id) => {
              newArray.push({ value: id, label: id });
            });
            return newArray;
          })()}
          onChange={(values) => {
            let newValues = [];
            values?.forEach((item) => {
              newValues.push(item.value);
            });
            setTransferDetails((prev) => ({
              ...prev,
              tag_ids: newValues,
            }));
          }}
        />

        {/* transfer description */}
        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          label="Transfer purpose"
          isRequired={true}
          placeholder="Enter reason for transfer"
          name="transferDescription"
          value={transferDetails?.narration}
          onChange={({ target: { value } }) => {
            setTransferDetails((prev) => ({
              ...prev,
              narration: value,
            }));
          }}
          onKeyUp={() =>
            errors.transferDescription !== undefined &&
            trigger("transferDescription")
          }
          errorMessage={errors.transferDescription}
          register={register}
        />

        <div className="d-flex justify-content-between align-items-center">
          <p className="dark--text">Save beneficiary</p>

          <primaryComponents.Switch
            isChecked={transferDetails?.save_beneficiary}
            name="saveBeneficiary"
            id="saveBeneficiary"
            onChange={() =>
              setTransferDetails((prev) => ({
                ...prev,
                save_beneficiary: !transferDetails?.save_beneficiary,
              }))
            }
          />
        </div>

        <primaryComponents.Button
          type="submit"
          classNames="btn btn--primary cover mt-4"
          isDisabled={!transferDetails?.account_name}
          // isLoading={isFetchingCharges}
          loadingText="Processing..."
        >
          Continue
        </primaryComponents.Button>
      </form>
    </motion.div>
  );
};
export default TransferForm;
