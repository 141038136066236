import { useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";
import globaStyles from "../../../assets/styles/base/globalPage.module.scss";

const useSubscriptionTable = ({ subscriptions }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value }, row }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Plan",
        accessor: "pricing",
        Cell: ({ cell: { value } }) => {
          const newValue = value?.tier?.name;
          return (
            <span
              className={`smallest-text-size ${globaStyles.plan} ${
                newValue === "Essential"
                  ? globaStyles.essentialPlan
                  : newValue === "Growth"
                    ? globaStyles.growthPlan
                    : newValue === "Scale"
                      ? globaStyles.scalePlan
                      : ""
              } `}
            >
              {newValue}
            </span>
          );
        },
      },
      {
        Header: "Service Period",
        accessor: "start_date",
        Cell: ({ cell: { value }, row }) => (
          <span>
            {value ? new Date(value).toLocaleString("en-us") : "N/A"} -{" "}
            {new Date(row?.original?.end_date).toLocaleString("en-us")}
          </span>
        ),
      },
      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        Cell: ({ cell: { value, row } }) =>
          formatCurrency({
            value: value,
            currencyCode: row?.original?.currency,
            decimal: 0,
          }),
      },
      {
        Header: "Plan Price",
        accessor: "tier_price",
        Cell: ({ cell: { value, row } }) =>
          formatCurrency({
            value: value,
            currencyCode: row?.original?.currency,
            decimal: 0,
          }),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: subscriptions });

  return { instance };
};
export default useSubscriptionTable;
