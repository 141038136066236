import styles from "./SendReceipt.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import primaryComponents from "../../../primaryComponents";
import useSendReceiptV2 from "./useSendReceiptV2";
import Validation from "./Validation";
import { motion } from "framer-motion";

const SendReceiptV2 = ({
  isOrderFlow = true,
  closeModal = () => {},
  orderId,
}) => {
  const {
    sendReceiptOption,
    isSendingReceipt,
    setSendReceiptOption,
    handleSendReceipt,
    setCustomerPhoneCode,
  } = useSendReceiptV2({ closeModal, orderId });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation();

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      className={styles.sendReceipt}
    >
      {!isOrderFlow ? (
        <FontAwesomeIcon
          onClick={closeModal}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      ) : null}

      <div>
        <h4 className="font-weight-semibold">Send receipt</h4>
        <p>
          You can send your receipt to your beneficiary&apos;s email or phone
          number
        </p>
      </div>

      <form
        id="receiptForm"
        onSubmit={handleSubmit(handleSendReceipt)}
        className={styles.sendReceipt__form}
      >
        <div className="d-flex" style={{ gap: "10px" }}>
          <Controller
            name="option"
            control={control}
            defaultValue={sendReceiptOption}
            render={({ field: { onChange } }) => (
              <primaryComponents.BoxedRadioInput
                name="option"
                value={sendReceiptOption}
                id="email"
                isChecked={sendReceiptOption === "email"}
                setRadioValue={() => {
                  setSendReceiptOption("email");
                  onChange("email");
                }}
              >
                <span className="font-weight-semibold">Email</span>
              </primaryComponents.BoxedRadioInput>
            )}
          />
          <Controller
            name="option"
            control={control}
            defaultValue={sendReceiptOption}
            render={({ field: { onChange } }) => (
              <primaryComponents.BoxedRadioInput
                name="option"
                value={sendReceiptOption}
                id="sms"
                isChecked={sendReceiptOption === "sms"}
                setRadioValue={() => {
                  setSendReceiptOption("whatsapp");
                  onChange("whatsapp");
                }}
              >
                <span className="font-weight-semibold">WhatsApp</span>
              </primaryComponents.BoxedRadioInput>
            )}
          />
        </div>

        {sendReceiptOption === "email" && (
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label="Email"
                placeholder="Enter customer email address"
                isRequired={true}
                type="email"
                name="email"
                errorMessage={errors.email}
                onKeyUp={() => errors.email !== undefined && trigger("email")}
                onChange={({ target: { value } }) => {
                  onChange(value);
                }}
                register={register}
              />
            )}
          />
        )}
        {sendReceiptOption === "whatsapp" && (
          <Controller
            name="phone_number"
            control={control}
            render={({ field: { onChange } }) => (
              <primaryComponents.PhoneNumberInputField
                label="Customers Phone Number"
                placeholder="Enter customer phone number"
                isRequired={true}
                name="phone_number"
                errorMessage={errors.phone_number}
                onKeyUp={() =>
                  errors.phone_number !== undefined && trigger("phone_number")
                }
                onChange={({ target: { value } }) => {
                  onChange(value);
                }}
                register={register}
                phoneCodeEditable={true}
                onChangePhoneCode={(val) => setCustomerPhoneCode(val)}
              />
            )}
          />
        )}
      </form>

      <primaryComponents.Button
        classNames={"btn btn--primary cover mt-auto"}
        isDisabled={isSendingReceipt}
        isLoading={isSendingReceipt}
        loadingText="Sending Receipt..."
        form="receiptForm"
      >
        Send Receipt
      </primaryComponents.Button>
    </motion.div>
  );
};

export default SendReceiptV2;
