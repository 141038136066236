import { Controller } from "react-hook-form";
import PercentField from "../PercentField";

const PercentFieldV2 = ({
  onChange = () => {},
  name = "",
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onControllerChange } }) => (
        <PercentField
          name={name}
          onChange={(objectValues) => {
            onControllerChange(objectValues.percentValue);
            onChange(objectValues);
          }}
          {...props}
        />
      )}
    />
  );
};

export default PercentFieldV2;
