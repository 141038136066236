import "../ProductCategoryModal/ProductCategoryModal.scss";
import primaryComponents from "../../primaryComponents";
import useUpdateCreditLogLogic from "./useUpdateCityLogic";
import Validation from "./useValidation";

const UpdateCityModal = ({ country_id, state_id, closeModal }) => {
  const {
    setCityName,
    cityName,
    handleAddCityToLocation,
    isLoadingcityLocationData,
  } = useUpdateCreditLogLogic({ country_id, state_id, closeModal });
  const {
    register,
    formState: { errors },
    trigger,
    control,
    handleSubmit,
  } = Validation(cityName);
  return (
    <div className="product-category-modal-wrapper">
      <div className="d-flex justify-content-center align-items-center">
        <h5 className="default-text-size items-center">Add More City</h5>
      </div>

      <form onSubmit={handleSubmit(handleAddCityToLocation)} className="mt-4">
        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          name="cityName"
          label="Update City"
          isRequired={true}
          value={cityName}
          placeholder={"Update City"}
          onChange={({ target: { value } }) => {
            setCityName(value);
          }}
          errorMessage={errors.cityName}
          onKeyUp={() => errors.cityName !== undefined && trigger("cityName")}
          register={register}
        />

        <div className="modal-buttons">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </primaryComponents.Button>

          <primaryComponents.Button
            classNames={"btn btn--primary"}
            type="submit"
            isLoading={isLoadingcityLocationData}
          >
            Proceed
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};
export default UpdateCityModal;
