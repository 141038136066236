import styles from "./DeleteStockWidget.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import { useRemoveStock } from "./DeleteStockLogic";
import { useDeleteStockFormValidation } from "./validation";

import primaryComponents from "../../primaryComponents";

const DeleteStockWidget = ({ closeWidget }) => {
  const {
    isDeletingStock,
    formData,
    selectedProductVariants,
    stores,
    setStores,
    setFormData,
    setSelectedProductVariants,
    handleRemoveStock,
  } = useRemoveStock({ closeWidget });
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useDeleteStockFormValidation(formData);

  return (
    <div className={styles.deleteStockWidget}>
      <button onClick={closeWidget} className={styles.backButton}>
        <FontAwesomeIcon
          icon="angle-left"
          className="purple--text"
          style={{ fontSize: "20px" }}
        />
      </button>
      <h3 className="mt-4">Remove Stock</h3>
      <p>
        Effortlessly dispose of expired products or adjust overstocked
        quantities
      </p>
      <form
        onSubmit={handleSubmit(handleRemoveStock)}
        className={styles.deleteStockForm}
      >
        <div>
          <Controller
            name="product_id"
            control={control}
            defaultValue={formData.product_id}
            render={({ field: { onChange } }) => (
              <primaryComponents.ProductComboBox
                label={"Search and select the product you want to remove"}
                onChange={(selectedProduct) => {
                  if (selectedProduct) {
                    const { variants, stores, id } = selectedProduct;
                    if (variants) {
                      const productVariants = variants.map((variant) => ({
                        label: `${variant.option} - ${variant.value}`,
                        value: variant?.id,
                      }));
                      setSelectedProductVariants(productVariants);
                    } else {
                      setSelectedProductVariants([]);
                      resetField("variant_id", { defaultValue: "" });
                    }
                    if (stores) {
                      setStores(stores);
                    }

                    onChange(id);
                    setFormData((prev) => ({ ...prev, product_id: id }));
                  }
                }}
                error={errors?.product_id?.message}
              />
            )}
          />
        </div>
        {selectedProductVariants.length ? (
          <motion.div
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ delay: 0.25 }}
          >
            <Controller
              name="variant_id"
              control={control}
              defaultValue={formData.variant_id}
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  options={selectedProductVariants}
                  isRequired
                  label={"Select The Variant of The Product You Want To Remove"}
                  nameKey="label"
                  idKey="value"
                  onChange={(value) => {
                    onChange(value);
                    setFormData((prev) => ({ ...prev, variant_id: value }));
                  }}
                  errorMessage={errors.variant_id}
                />
              )}
            />
          </motion.div>
        ) : null}
        <div>
          <Controller
            name="store_id"
            control={control}
            defaultValue={formData.store_id}
            render={({ field: { onChange } }) => (
              <primaryComponents.SelectField
                isRequired
                label={"Select The Store You Want To Remove This Product From"}
                options={stores}
                nameKey="name"
                idKey="id"
                onChange={(value) => {
                  onChange(value);
                  setFormData((prev) => ({ ...prev, store_id: value }));
                }}
                errorMessage={errors.store_id}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="quantity"
            control={control}
            defaultValue={formData.quantity}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                classNames="white"
                isRequired
                label={"Enter The Quantity You Want To Delete"}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setFormData((prev) => ({ ...prev, quantity: value }));
                }}
                value={formData.quantity}
                errorMessage={errors.quantity}
              />
            )}
          />
        </div>
        <div>
          <Controller
            name="reason"
            control={control}
            defaultValue={formData.reason}
            render={({ field: { onChange } }) => (
              <primaryComponents.SelectField
                isRequired
                nameKey="label"
                idKey="value"
                label={"Why Do You Want To Delete This Product?"}
                options={[
                  { value: "expired", label: "Expired" },
                  { value: "overstocked", label: "Overstocked" },
                ]}
                onChange={(value) => {
                  onChange(value);
                  setFormData((prev) => ({ ...prev, reason: value }));
                }}
                errorMessage={errors.reason}
              />
            )}
          />
        </div>
        <primaryComponents.Button
          classNames={"btn btn--danger mt-auto"}
          isDisabled={isDeletingStock}
          isLoading={isDeletingStock}
          loadingText={"Removing..."}
        >
          Remove
        </primaryComponents.Button>
      </form>
    </div>
  );
};

export default DeleteStockWidget;
