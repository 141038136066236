import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

const usePrintLogic = ({ allowPrint = false, setAllowPrint, itemDetails }) => {
  // Detect if the device is mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const sectionPrintComponent = useRef();
  const { business_name, address } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const handlePrint = useReactToPrint({
    content: () => sectionPrintComponent.current,
    onAfterPrint: () => clearAfterPrint(),
    removeAfterPrint: true,
  });

  useEffect(() => {
    if (allowPrint && itemDetails && !isMobile) handlePrint();
  }, [allowPrint, itemDetails]);

  const clearAfterPrint = () => {
    setAllowPrint(false);
  };

  return {
    isMobile,
    business_name,
    address,
    sectionPrintComponent,
    clearAfterPrint,
  };
};

export default usePrintLogic;
