import { useTable } from "react-table";
import { useEffect, useMemo } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useGetTerminals } from "../../../hooks/api/queries/useTerminals";
import { getUrlQuerysection } from "../../../helpers";
import { pathConstants } from "../../../routes/pathContants";

const useTerminalsTable = () => {
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const { data: allTerminals, isLoading } = useGetTerminals({ queries });

  useEffect(() => {
    if (!search.get("all_business")) {
      search.set("all_business", true);
      setSearch(search, { replace: true });
    }
  }, []);

  const searchTerminal = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Terminal ID",
        accessor: "id",
        Cell: ({ cell: { value, row } }) => (
          <Link
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
            className="purple--text"
            to={pathConstants.PAYMENT_POS_TERMINAL_DETAILS({
              terminalId: row?.original?.id,
            })}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Bank Provider",
        accessor: "bank_provider",
      },
      {
        Header: "Request Date",
        accessor: "requested_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Approval Date",
        accessor: "approved_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Volume Processed",
        accessor: "volume_processed",
      },
      {
        Header: "Last Activity",
        accessor: "last_activity",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${
              value === "ACTIVE" || value === "RETRIEVED"
                ? "success--squared"
                : value === "PENDING"
                  ? "pending--squared"
                  : "failed--text"
            }`}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <Link
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
            className="purple--text"
            to={pathConstants.PAYMENT_POS_TERMINAL_CREDENTIALS({
              terminalId: row?.original?.id,
            })}
          >
            View credentials
          </Link>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: allTerminals || [] });

  return {
    instance,
    isLoading,
    allTerminals: allTerminals || [],
    search,
    searchTerminal,
  };
};

export default useTerminalsTable;
