import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getExpenseCategories } from "../../../../services/expenses";

const useExpenseCategories = () => {
  const queries = getUrlQuerysection();
  // const [metaDetails, setMetaDetails] = useState({});
  const [search, setSearch] = useSearchParams();
  const [isNewExpenseCategory, setIsNewExpenseCategory] = useState(false);

  const {
    isLoading: isFetchingCategories,
    data: expenseCategories,
    refetch: handleFetchCategories,
  } = useQuery(["getExpenseCategories", queries], () =>
    getExpenseCategories(queries).then((res) => {
      // setMetaDetails(res.data?.meta);
      return res.data?.data;
    }),
  );

  // const gotoPage = (pageNumber) => {
  //   search.set("page", pageNumber);
  //   setSearch(search, { replace: true });
  // };

  return {
    isFetchingCategories,
    expenseCategories: expenseCategories || [],
    // metaDetails,
    search,
    setSearch,
    isNewExpenseCategory,
    setIsNewExpenseCategory,
    handleFetchCategories,
    // gotoPage,
  };
};

export default useExpenseCategories;
