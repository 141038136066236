import "./ForgotPassword.scss";
import { Link } from "react-router-dom";
import layouts from "../../../layouts";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import ForgetPasswordLogic from "./ForgotPasswordLogic";
import ForgetPasswordFormValidation from "./ForgetPasswordValidation";
import { Controller } from "react-hook-form";

const ForgotPassword = () => {
  const { email, setEmail, isLoading, errorMessage, submitEmail } =
    ForgetPasswordLogic();
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = ForgetPasswordFormValidation(email);
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Forgot Password"
      description="Reset your lumi merchant password"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          data-testid="forgot-password-page"
        >
          <layouts.LoginLayout
            linkedPage={
              <p>
                Remember password?
                <Link
                  className="purple--text ml-1"
                  to="/login"
                  data-testid="login-btn"
                >
                  Log In
                </Link>
              </p>
            }
          >
            <div className="forgot-password-page">
              <div className="forgot-password-page__container">
                <h3 className="purple--text text-center font-weight-normal">
                  Forgot Password
                </h3>
                <p className="text-center">
                  Enter the email address associated with your account and
                  we&apos;ll send you a link to reset your password
                </p>
                <form
                  onSubmit={handleSubmit(submitEmail)}
                  className="forgot-password-page__container__form"
                >
                  {/* error message */}
                  {errorMessage !== null && (
                    <p className="text-danger text-center mb-1">
                      {errorMessage}
                    </p>
                  )}

                  <div>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={email}
                      render={({ field: { onChange } }) => (
                        <primaryComponents.InputField
                          label="Email Address"
                          placeholder="Enter your email address"
                          isRequired={true}
                          type="email"
                          name="email"
                          errorMessage={errors.email}
                          onKeyUp={() =>
                            errors.email !== undefined && trigger("email")
                          }
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            setEmail(value);
                          }}
                          register={register}
                        />
                      )}
                    />
                  </div>
                  <div className="mt-2">
                    <primaryComponents.Button
                      classNames="btn btn--primary cover"
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      loadingText="Submitting..."
                      type="submit"
                    >
                      Submit
                    </primaryComponents.Button>
                  </div>
                </form>
                <p className="route text-center mt-3">
                  Remember password?
                  <Link className="purple--text ml-1" to="/login">
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </layouts.LoginLayout>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ForgotPassword;
