import styles from "./SellModeSideModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import secondaryComponents from "..";

const SellModeSideModal = ({ children, title = "", closeWidget, ...props }) => {
  return (
    <secondaryComponents.SideModal
      isSellMode={true}
      {...props}
      closeModal={closeWidget}
    >
      <div className="d-flex align-items-center">
        <button
          onClick={() => {
            closeWidget();
          }}
          className={styles.backButton}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="purple--text"
            style={{ fontSize: "20px" }}
          />
        </button>
        <h4 className="ml-3">{title}</h4>
      </div>

      {children}
    </secondaryComponents.SideModal>
  );
};

export default SellModeSideModal;
