import { useState } from "react";

const useItemTracking = () => {
  const [productProperties, setProductProperties] = useState(null);
  const closeModal = () => {
    setProductProperties(null);
  };

  return { productProperties, setProductProperties, closeModal };
};
export default useItemTracking;
