import primaryComponents from "../../../primaryComponents";
import "./WarehousePayment.scss";
import WarehousePaymentLogic from "./WarehousePaymentLogic";

const WarehousePayment = ({ closePaymentSession }) => {
  const { isConfirmingPayment, payViaWarehouseCheckout } =
    WarehousePaymentLogic({ closePaymentSession });

  return (
    <>
      <div className="warehouse-payment">
        <p className="dark--text mx-auto black--text warehouse-payment__text_title">
          Attention: (PR) Checkout
        </p>
        <p
          className="dark--text mx-auto small-text-size"
          style={{ textAlign: "justify" }}
        >
          Please be aware that selecting this payment method will classify the
          items as unpaid, resulting in them not being recorded as sales for
          your business. To accurately track direct sales from the public
          relation, we recommend using an alternative payment method during
          checkout.
        </p>
      </div>
      <div className="card-payment__bottom">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isConfirmingPayment}
          loadingText="Confirming..."
          isDisabled={isConfirmingPayment}
          onClick={payViaWarehouseCheckout}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </>
  );
};
export default WarehousePayment;
