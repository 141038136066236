import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../primaryComponents";
import styles from "./SubscriptionFeatureCollapse.module.scss";

const SubscriptionFeatureCollapse = ({
  toggleView,
  isActive,
  isMobile = false,
}) => {
  return (
    <primaryComponents.Button
      classNames={`btn btn--outline ${styles.subscriptionFeatureCollapse} ${
        isMobile ? styles.mobile : ""
      }`}
      onClick={toggleView}
    >
      <FontAwesomeIcon
        icon={isActive ? "angle-up" : "angle-down"}
        className="purple--text"
        fontSize={isMobile ? "15px" : "22px"}
        fontWeight="500"
      />
    </primaryComponents.Button>
  );
};
export default SubscriptionFeatureCollapse;
