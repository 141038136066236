import React from "react";

const EditIconV2 = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 2H3.33333C2.59695 2 2 2.59695 2 3.33333V12.6667C2 13.4031 2.59695 14 3.33333 14H12.6667C13.4031 14 14 13.4031 14 12.6667V8.66667"
        stroke="#7647EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33203 7.66662L11.6654 2.33328C12.2176 1.781 13.1131 1.781 13.6654 2.33328C14.2176 2.88557 14.2176 3.781 13.6654 4.33328L8.33203 9.66662L5.33203 10.6666L6.33203 7.66662Z"
        stroke="#7647EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIconV2;
