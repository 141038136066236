import { useContext, useState } from "react";
import { ToastContext } from "../../../hooks/context";
import { useAddCityToLocation } from "../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";

const useUpdateCityLogic = ({ country_id, state_id, closeModal }) => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [cityName, setCityName] = useState("");
  const {
    mutate: addCityToLocationMutation,
    data: cityLocationData,
    isLoading: isLoadingcityLocationData,
  } = useAddCityToLocation();

  const handleAddCityToLocation = () => {
    const data = {
      country_id: country_id,
      state_id: state_id,
      name: cityName,
    };

    addCityToLocationMutation(data, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCountryDetails", country_id]);
        triggerToast("city added successfully", "success");
        closeModal();
      },
    });
  };

  return {
    setCityName,
    cityLocationData,
    handleAddCityToLocation,
    isLoadingcityLocationData,
  };
};
export default useUpdateCityLogic;
