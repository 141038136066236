import { useGetSections } from "../../../hooks/api/queries/useSection";

const useAllSection = () => {
  const {
    isLoading: isfetchingSections,
    data: sections,
    refetch: handleFetchSections,
  } = useGetSections();
  return {
    isfetchingSections,
    sections,
    handleFetchSections,
  };
};

export default useAllSection;
