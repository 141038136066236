import { useState } from "react";

const useFileInputLogic = ({ inputRef, onChange }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    if (e.target.files[0].size > 10000000) {
      window.setError(
        "file",
        {
          type: "validate",
          message: "File size should be less than 10MB",
        },
        { shouldFocus: true },
      );

      return;
    }

    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    onChange(e.target.files[0]);
  };

  const handleClick = () => inputRef.current.click();

  return {
    file,
    handleChange,
    fileName,
    handleClick,
  };
};

export default useFileInputLogic;
