import { useQuery } from "react-query";
import { fetchAllTerminals } from "../../../services/pos-terminals";

export const useGetTerminals = ({ queries = "" }) => {
  return useQuery(
    ["fetchTerminals", queries],
    () => fetchAllTerminals(queries).then((res) => res?.data.data),
    {
      staleTime: Infinity,
    },
  );
};
