import React, { useEffect } from "react";
import globalStyles from "./../../../assets/styles/base/globalPage.module.scss";
import styles from "./CreateShipping.module.scss";
import primaryComponents from "../../primaryComponents";
import useShippingForm from "./useShippingForm";
import Validation from "./Validation";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import {
  CloseIcon,
  MinusCircleIcon,
  PlusCircle,
} from "../../../assets/svgIcons";
import Modal from "../Modal/Modal";
import UpdateCityModal from "../UpdateCityModal";

const CreateShippingForm = ({
  closeWidget,
  refetch,
  isEditMode,
  shippingInfo,
}) => {
  const {
    shippingDetails,
    includesHolidayOptions,
    selectedIncludesHoliday,
    selectInputRef,
    selectedState,
    countryDetails,
    isLoadingCountryDetails,
    isLoadingCountries,
    isLoadingMenus,
    cities,
    isAddingShipping,
    isEditingShipping,
    countries,
    menus,
    selectedCountry,
    setShippingDetails,
    handleIncludesChange,
    setCities,
    setSelectedState,
    handleAddShipping,
    handleEditShipping,
    setSelectedCountry,
    addCity,
    setCurrency,
    currency,
    addCityModal,
    setAddCityModal,
    handleAddCityToLocation,
    selectedFreeShipping,
    setSelectedFreeShipping,
  } = useShippingForm({
    shippingInfo,
    isEditMode,
    refetch,
    closeWidget,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    clearErrors,
    setValue,
    formState: { errors },
  } = Validation({ shippingDetails, selectedFreeShipping, shippingInfo });

  useEffect(() => {
    setValue("selectedFreeShipping", selectedFreeShipping);
  }, [selectedFreeShipping, setValue]);

  return (
    <div className={`${globalStyles.pagePaddings} p-4`}>
      <div className={globalStyles.pageColumnGaps}>
        <div>
          <div
            className="d-flex flex-wrap"
            style={{
              gap: "50px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <CloseIcon onClick={closeWidget} />
          </div>
          <h4 className="text-center">
            {isEditMode ? "Update Shipping Group" : "Create New Shipping Group"}
          </h4>
        </div>
        <div className={globalStyles.pageColumnGaps}>
          <div className="mt-3">
            <h5>Details</h5>
            <div className={styles.location}>
              <div className="w-100 cover">
                <primaryComponents.InputFieldV2
                  control={control}
                  classNames="white"
                  name="shippingName"
                  label="Zone Name"
                  placeholder="E.g Country, state or city name - Group 1"
                  isRequired={true}
                  value={shippingDetails?.name}
                  onChange={({ target: { value } }) => {
                    setShippingDetails((prev) => ({
                      ...prev,
                      name: value,
                    }));
                  }}
                  errorMessage={errors.shippingName}
                  onKeyUp={() =>
                    errors.shippingName !== undefined && trigger("shippingName")
                  }
                  register={register}
                />
              </div>
              <div className="w-100 cover">
                <LoaderHelper
                  classNames={"mt-4"}
                  isLoading={isLoadingCountries}
                >
                  <primaryComponents.SelectFieldV2
                    control={control}
                    label="Select country"
                    isRequired={true}
                    value={shippingDetails?.countryId || "Select country"}
                    onChange={(value) => {
                      setSelectedCountry(value);
                      setSelectedState([]);
                      selectInputRef.current.clearValue();
                    }}
                    name="shippingCountryId"
                    defaultValue={
                      shippingDetails?.countryId ||
                      shippingInfo?.country?.id ||
                      ""
                    }
                    options={countries?.map((country) => ({
                      id: country?.id,
                      name: `${country?.emoji} ${country?.name}`,
                    }))}
                    nameKey="name"
                    idKey="id"
                    errorMessage={errors.shippingCountryId}
                    register={register}
                  />
                </LoaderHelper>
              </div>
            </div>
            <LoaderHelper classNames={"mt-4"} isLoading={isLoadingMenus}>
              <primaryComponents.SelectFieldV2
                control={control}
                label="Select applicable storefront"
                isRequired={true}
                value={
                  shippingDetails?.storefront_ids ||
                  "Select applicable storefront"
                }
                onChange={(selectedValue) => {
                  const selectedMenu = menus?.data?.find(
                    (menu) => menu.id === selectedValue,
                  );
                  setCurrency(selectedMenu?.store?.currency_code);

                  setShippingDetails((prev) => ({
                    ...prev,
                    storefront_ids: [selectedValue],
                  }));
                }}
                name="shippingMenuIds"
                defaultValue={shippingDetails?.storefront_ids}
                options={menus?.data?.map((menu) => ({
                  id: menu?.id,
                  name: menu?.name,
                }))}
                nameKey="name"
                idKey="id"
                errorMessage={errors.shippingMenuIds}
                register={register}
              />
            </LoaderHelper>
          </div>

          <div>
            <div className="mt-2">
              <div className="mt-2">
                <LoaderHelper
                  classNames={"mt-4"}
                  isLoading={isLoadingCountryDetails}
                >
                  <primaryComponents.MultipleSelectFieldV2
                    control={control}
                    classNames="white"
                    name="shippingStateIds"
                    label="Select State"
                    placeholder="Select State"
                    value={shippingDetails?.stateIds}
                    onFocus={() => {
                      if (!shippingDetails?.countryId) {
                        trigger("shippingCountryId");
                      } else {
                        clearErrors("shippingCountryId");
                      }
                    }}
                    onChange={(values) => {
                      setCities({});
                      setSelectedState(values);
                      const updatedCities = { ...cities };
                      for (const key in updatedCities) {
                        if (
                          !values?.find(
                            (value) => `${value?.value}` === `${key}`,
                          )
                        )
                          delete updatedCities[key];
                      }
                      setCities(updatedCities);
                    }}
                    nameKey="name"
                    idKey="id"
                    isRequired={true}
                    options={
                      isLoadingCountryDetails ? [] : countryDetails?.states
                    }
                    defaultValue={shippingDetails?.stateIds}
                    errorMessage={errors.shippingStateIds}
                    register={register}
                    includeSelectAll={true}
                    selectInputRef={selectInputRef}
                    creatableLabel={"All State"}
                  />
                </LoaderHelper>
              </div>
            </div>

            {selectedState?.length > 0 ? (
              <div className={`mt-2 ${globalStyles.cardWithBorder}`}>
                {selectedState?.map((stateData) => (
                  <div key={stateData?.value}>
                    <div
                      className="d-flex justify-content-end"
                      onClick={() => {
                        setAddCityModal(stateData?.value);
                      }}
                    >
                      <p
                        className="smallest-text-size purple--text font-weight-semibold"
                        style={{ cursor: "pointer" }}
                      >
                        Add City
                      </p>
                    </div>
                    <LoaderHelper
                      classNames={"mt-4"}
                      isLoading={isLoadingCountryDetails}
                    >
                      <primaryComponents.MultipleSelectFieldV2
                        name={`shippingCities-${stateData?.label}`}
                        control={control}
                        options={
                          countryDetails?.states?.find(
                            (data) => stateData?.value === data?.id,
                          )?.cities
                        }
                        condition={true}
                        idKey="id"
                        label={`${stateData?.label}`}
                        nameKey="name"
                        placeholder={`Select cities from ${stateData?.label}`}
                        isRequired={false}
                        Creatable={true}
                        value={
                          cities[stateData?.value]?.map((cityId) => {
                            const city = countryDetails?.states
                              ?.find((data) => stateData?.value === data?.id)
                              ?.cities?.find((city) => city?.id === cityId);
                            return { value: city?.id, label: city?.name };
                          }) || []
                        }
                        defaultValue={
                          cities[stateData?.value]?.map((cityId) => {
                            const city = countryDetails?.states
                              ?.find((data) => stateData?.value === data?.id)
                              ?.cities?.find((city) => city?.id === cityId);
                            return { value: city?.id, label: city?.name };
                          }) || []
                        }
                        onChange={(values) => {
                          const cityId = values?.map((city) => {
                            return city.value;
                          });
                          setCities((prev) => ({
                            ...prev,
                            [stateData?.value]: cityId,
                          }));
                        }}
                        errorMessage={
                          errors[`shippingCities-${stateData?.label}`]
                        }
                        register={register}
                        includeSelectAll={true}
                        onCreateNewOption={handleAddCityToLocation}
                        state_id={stateData?.value}
                        country_id={selectedCountry}
                        creatableLabel={"All Cities"}
                        addCity={addCity}
                      />
                    </LoaderHelper>
                  </div>
                ))}
              </div>
            ) : (
              <div
                className={`${globalStyles.cardWithBorder} d-flex justify-content-center align-items-center`}
              >
                <p className="default-text-size">
                  Select state(s) above to configure towns/cities
                </p>
              </div>
            )}
          </div>

          {/* window */}
          <div className="mt-4">
            <h5>Delivery Rates & Window Estimate</h5>
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="shippingRate"
              label={`Rates ${
                shippingDetails?.storefront_ids
                  ? currency
                  : "No storefront selected"
              }`}
              placeholder="Enter rate for this shipping zones"
              isRequired={true}
              value={shippingDetails?.rate || ""}
              onChange={({ target: { value } }) => {
                setShippingDetails((prev) => ({
                  ...prev,
                  rate: value,
                }));
              }}
              errorMessage={errors.shippingRate}
              onKeyUp={() =>
                errors.shippingRate !== undefined && trigger("shippingRate")
              }
              register={register}
            />

            <div
              className="d-flex items-align-center mb-3 mt-4"
              style={{ gap: "5px", cursor: "pointer" }}
              onClick={() => setSelectedFreeShipping((prev) => !prev)}
            >
              {selectedFreeShipping ? (
                <MinusCircleIcon />
              ) : (
                <PlusCircle stroke="#7647EE" />
              )}
              <span
                className={`${
                  selectedFreeShipping ? "error--text" : "purple--text"
                } small-text-size`}
                style={{ textDecoration: "underline" }}
              >
                {selectedFreeShipping
                  ? "Remove free shipping"
                  : "Add free shipping"}
              </span>
            </div>

            {selectedFreeShipping && (
              <div className="mb-3">
                <primaryComponents.AmountFieldV2
                  control={control}
                  classNames="white"
                  name="freeShipping"
                  label={"Set the minimum price for free shipping eligibility."}
                  placeholder="Enter price"
                  isRequired
                  value={shippingDetails?.free_delivery_min_order_amount}
                  onChange={({ target: { value } }) => {
                    setShippingDetails((prev) => ({
                      ...prev,
                      free_delivery_min_order_amount: value,
                    }));
                  }}
                  errorMessage={errors.freeShipping}
                  onKeyUp={() =>
                    errors.freeShipping !== undefined && trigger("freeShipping")
                  }
                  register={register}
                />
              </div>
            )}

            <primaryComponents.EstimatedInput
              label={"Enter Estimated Delivery Duration (in days)"}
              name="shippingDeliveryWindow"
              control={control}
              type="number"
              placeholder="Enter delivery days, e.g., 5"
              isRequired={true}
              isDisabled={false}
              value={shippingDetails?.delivery_window}
              errorMessage={errors.shippingDeliveryWindow}
              onChange={(e) => {
                setShippingDetails((prev) => ({
                  ...prev,
                  delivery_window: e.target.value,
                }));
              }}
              domainOptions={includesHolidayOptions}
              onDomainChange={handleIncludesChange}
              selectedDomain={selectedIncludesHoliday}
              register={register}
              onKeyUp={() =>
                errors.shippingDeliveryWindow !== undefined &&
                trigger("shippingDeliveryWindow")
              }
              min={1}
            />
          </div>

          <div className={`${styles.bottom}`}>
            {isEditMode ? (
              <primaryComponents.Button
                onClick={handleSubmit(handleEditShipping)}
                isLoading={isEditingShipping}
                isDisabled={isEditingShipping}
                classNames="btn btn--primary cover"
                loadingText={"Updating..."}
              >
                Update shipping group
              </primaryComponents.Button>
            ) : (
              <>
                <div></div>
                <primaryComponents.Button
                  onClick={handleSubmit(handleAddShipping)}
                  isLoading={isAddingShipping}
                  isDisabled={isAddingShipping}
                  classNames="btn btn--primary"
                  loadingText={"Adding..."}
                >
                  Create
                </primaryComponents.Button>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        isActive={addCityModal !== null}
        width={500}
        closeModal={() => setAddCityModal(null)}
      >
        <UpdateCityModal
          country_id={selectedCountry}
          state_id={addCityModal}
          closeModal={() => setAddCityModal(null)}
        />
      </Modal>
    </div>
  );
};

export default CreateShippingForm;
