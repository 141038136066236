import styles from "../SectionView/SectionView.module.scss";
import {
  defaultDateDisplay,
  formatCurrency,
  truncateString,
} from "../../../helpers";
import usePrintLogic from "../usePrintLogic";
import { useGetStoreWalletTransactionDetails } from "../../../hooks/api/queries";
import Barcode from "react-barcode";
import { MobilePrint } from "../MobilePrint";
import { MobileDoc } from "./MobileDoc";

export const StoreWalletTransactionView = ({
  tnx_id,
  allowPrint = false,
  setAllowPrint,
}) => {
  const { data: storeWalletTnxDetails } =
    useGetStoreWalletTransactionDetails(tnx_id);

  const {
    isMobile,
    business_name,
    address,
    sectionPrintComponent,
    clearAfterPrint,
  } = usePrintLogic({
    itemDetails: storeWalletTnxDetails,
    allowPrint,
    setAllowPrint,
  });

  return (
    <>
      <div className="d-none">
        <div>
          <div className={styles.sectionView} ref={sectionPrintComponent}>
            <div className="text-center mb-2">
              <p>{truncateString(business_name, 150)}</p>
              <p className="mb-0">{truncateString(address, 150)}</p>
            </div>
            <h5 className="mb-4">Store Walllet Transaction Receipt</h5>

            <div className={styles.sectionView__item}>
              <p className="small-text-size">Date/time:</p>
              <p className="mb-0">
                {defaultDateDisplay(storeWalletTnxDetails?.created_at) || "N/A"}
              </p>
            </div>
            <div className={styles.sectionView__item}>
              <p className="small-text-size">Transaction Ref:</p>
              <p className="mb-0">{storeWalletTnxDetails?.id || "N/A"}</p>
            </div>
            <div className={styles.sectionView__item}>
              <p className="small-text-size">Type:</p>
              <p
                className={`mb-0 ${storeWalletTnxDetails?.type === "debit" ? "error--text" : "success--text"}`}
              >
                {storeWalletTnxDetails?.type || "N/A"}
              </p>
            </div>
            <div className={styles.sectionView__item}>
              <p className="small-text-size">Amount:</p>
              <p className={"mb-0"}>
                {" "}
                {formatCurrency({
                  value: storeWalletTnxDetails?.amount,
                  currencyCode: storeWalletTnxDetails?.store?.currency_code,
                })}
              </p>
            </div>
            <div className={styles.sectionView__item}>
              <p className="small-text-size dark--text">Balance:</p>
              <p className={"mb-0 purple--text"}>
                {formatCurrency({
                  value: storeWalletTnxDetails?.balance,
                  currencyCode: storeWalletTnxDetails?.store?.currency_code,
                })}
              </p>
            </div>

            <div className="text-center mt-3">
              <Barcode value={storeWalletTnxDetails?.id} />
            </div>
          </div>
        </div>
      </div>

      {isMobile && storeWalletTnxDetails && allowPrint ? (
        <MobilePrint
          itemDetails={storeWalletTnxDetails}
          clearAfterPrint={clearAfterPrint}
          mobileDoc={
            <MobileDoc
              address={address}
              business_name={business_name}
              storeWalletTnxDetails={storeWalletTnxDetails}
            />
          }
        />
      ) : null}
    </>
  );
};
