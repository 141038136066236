import { useContext } from "react";
import { useMutation } from "react-query";
import { ToastContext } from "../../../hooks/context";
import { deleteExpenseCategory } from "../../../services/expenses";

const useExpensCategoryDeletion = ({
  categoryDetails,
  handleFetchCategories,
  closeWidget,
}) => {
  const triggerToast = useContext(ToastContext);
  const { mutate: deleteCategory, isLoading: isDeletingCategory } = useMutation(
    (data) => deleteExpenseCategory(data),
  );
  const handleDeleteExpenseCategory = () => {
    deleteCategory(
      {
        id: categoryDetails?.id,
      },
      {
        onSuccess: () => {
          handleFetchCategories();
          triggerToast("Category Deleted successfully", "success");
          closeWidget();
        },
      },
    );
  };

  return {
    isDeletingCategory,
    handleDeleteExpenseCategory,
  };
};
export default useExpensCategoryDeletion;
