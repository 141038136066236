import { useState, useContext } from "react";
import { refreshTransactions } from "../../../../../services/transaction";
import { RequestLoader } from "../../../../../hooks/context";
import { getRandomIntInclusive } from "../../../../../helpers";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useGetTransactions } from "../../../../../hooks/api/queries/useTransactions";

const useAllTransaction = () => {
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const [isRefreshingTransactions, setIsRefreshingTransactions] =
    useState(false);

  const {
    isLoading: isFetchingTnx,
    data,
    refetch: handleFetchTnx,
  } = useGetTransactions({ queries });

  const RefreshTransactions = () => {
    setRequestLoaderProgress(getRandomIntInclusive(5, 20));
    setIsRefreshingTransactions(true);
    refreshTransactions()
      .then(() => {
        handleFetchTnx();
      })
      .finally(() => {
        setRequestLoaderProgress(100);
        setIsRefreshingTransactions(false);
      });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const filterByDate = (data) => {
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    setSearch(search, { replace: true });
  };

  const searchTnx = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isRefreshingTransactions,
    isFetchingTnx,
    transactions: data?.data || [],
    metaDetails: data?.meta || {},
    search,
    setSearch,
    RefreshTransactions,
    handleFetchTnx,
    gotoPage,
    searchTnx,
    filterByDate,
  };
};
export default useAllTransaction;
