import primaryComponents from "../../primaryComponents";
import useSectionForm from "./useSectionForm";
import Validation from "./Validation";

const SectionForm = ({ closeModal, sectionDetails, isEditMode = false }) => {
  const {
    updateSectionById,
    sectionName,
    setSectionName,
    isUpdating,
    isAdding,
    createSection,
  } = useSectionForm({ closeModal, sectionDetails });
  const {
    register,
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation(sectionName);

  return (
    <div className="m-4" data-testid="add-new section">
      <h5 className="text-center">
        {isEditMode ? "Update Section" : "Create Section"}
      </h5>
      <form
        onSubmit={handleSubmit(() => {
          if (isEditMode) {
            updateSectionById();
          } else {
            createSection();
          }
        })}
        className="mt-4"
      >
        {/* section name */}

        <primaryComponents.InputFieldV2
          label="Section Name"
          isRequired={true}
          placeholder="Enter Section Name"
          name="name"
          control={control}
          value={sectionName}
          errorMessage={errors.name}
          register={register}
          onChange={({ target: { value } }) => {
            setSectionName(value);
          }}
          onKeyUp={() => errors.name !== undefined && trigger("name")}
        />

        <div className="d-flex justify-content-between mt-5">
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => {
              closeModal();
            }}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isUpdating || isAdding}
            isDisabled={isUpdating || isAdding}
            loadingText={isEditMode ? "Updating..." : "Creating.."}
            type="submit"
          >
            {isEditMode ? "Update" : "Create"}
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};

export default SectionForm;
