export const CustomerIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4997 13.3333C13.077 13.3333 15.1663 11.244 15.1663 8.66667C15.1663 6.08934 13.077 4 10.4997 4C7.92235 4 5.83301 6.08934 5.83301 8.66667C5.83301 11.244 7.92235 13.3333 10.4997 13.3333Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.33301 25V20.3333C2.33301 19.0446 3.37768 18 4.66634 18H16.333C17.6217 18 18.6663 19.0446 18.6663 20.3333V25"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.667 4C19.6708 4.25702 20.5605 4.84082 21.1959 5.65936C21.8312 6.47791 22.1761 7.48463 22.1761 8.52083C22.1761 9.55703 21.8312 10.5638 21.1959 11.3823C20.5605 12.2008 19.6708 12.7846 18.667 13.0417"
        stroke={strokeColor}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.167 18H23.3337C24.6224 18 25.667 19.0446 25.667 20.3333V25"
        stroke={strokeColor}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
