import "./ProductCategories.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import ProductCategoryLogic from "./ProductCategoryLogic";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";

const ProductCategories = () => {
  const { isModalOpen, isLoading, categories, setIsModalOpen } =
    ProductCategoryLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Product Categories"
      description="View all product categories"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`product-categories-page ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Product Categories</h4>
            <p>
              Create and assign custom categories to your inventory products to
              organize and manage them easily.
            </p>
          </div>

          <div className="product-categories-page__header">
            <p>{categories?.length} categories</p>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_create_product_category"
            >
              <primaryComponents.Button
                onClick={() => setIsModalOpen(true)}
                classNames={"btn btn--primary"}
              >
                + Add new category
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames={"mt-4"}
          >
            <secondaryComponents.ProductCategoryTable categories={categories} />
          </secondaryComponents.LoaderHelper>

          {isModalOpen && (
            <secondaryComponents.ProductCategoryModal
              isActive={isModalOpen}
              setIsActive={setIsModalOpen}
            />
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ProductCategories;
