import styles from "./styles.module.scss";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../../../components/secondaryComponents";
import { usePayment } from "./usePayment";
import PaystackSetup from "./components/Paystack";
import StripeSetup from "./components/stripe";
import { useState } from "react";
import Icon from "../../../../../../../../../assets/icons";
import { useOutletContext } from "react-router-dom";

const Payment = () => {
  const { menuProperties } = useOutletContext();
  const {
    dataPaymentMethods,
    isLoadingPayInstore,
    isLoadingPayOnDelivery,
    isLoadingPayWithPaystack,
    isLoadingPayWithStripe,
    showSetupPaystack,
    showSetupStripe,
    setShowSetupPaystack,
    setShowSetupStripe,
    mutatePayInstore,
    mutatePayOnDelivery,
    mutatePayWithPaystack,
    mutatePayWithStripe,
  } = usePayment();
  const [paymentId, setPaymentId] = useState(null);
  const paystackMethod = dataPaymentMethods?.find(
    (item) => item.type === "PAYSTACK",
  );
  const stripeMethod = dataPaymentMethods?.find(
    (item) => item.type === "STRIPE",
  );

  return (
    <secondaryComponents.LoaderHelper
      isLoading={!dataPaymentMethods}
      classNames="mt-4"
    >
      <div className={styles.container}>
        <div
          style={{ opacity: menuProperties?.allow_payment ? 1 : 0.4 }}
          className={styles.itemContainer}
        >
          <div>
            <h4>Pay on Delivery</h4>
            <p>Give your customers the option to pay on delivery.</p>
          </div>
          <primaryComponents.Button
            classNames={`btn ${
              menuProperties?.allow_pay_on_delivery
                ? "btn--outline-red"
                : "btn--success"
            }`}
            onClick={() => mutatePayOnDelivery()}
            loadingText={
              menuProperties?.allow_pay_on_delivery
                ? "De-activating..."
                : "Activating..."
            }
            isLoading={isLoadingPayOnDelivery}
            isDisabled={
              isLoadingPayOnDelivery || !menuProperties?.allow_payment
            }
            showSpinner={false}
          >
            <span>
              {menuProperties?.allow_pay_on_delivery
                ? "De-activate"
                : "Activate"}
            </span>
          </primaryComponents.Button>
        </div>
        <div className={styles.divider} />
        <div
          style={{ opacity: menuProperties?.allow_payment ? 1 : 0.4 }}
          className={styles.itemContainer}
        >
          <div>
            <h4>Pay in Store</h4>
            <p>Give your customers the option for in-person payments</p>
          </div>
          <primaryComponents.Button
            classNames={`btn ${
              menuProperties?.allow_pay_instore
                ? "btn--outline-red"
                : "btn--success"
            }`}
            onClick={() => mutatePayInstore()}
            loadingText={
              menuProperties?.allow_pay_instore
                ? "De-activating..."
                : "Activating..."
            }
            isLoading={isLoadingPayInstore}
            isDisabled={isLoadingPayInstore || !menuProperties?.allow_payment}
            showSpinner={false}
          >
            <span>
              {menuProperties?.allow_pay_instore ? "De-activate" : "Activate"}
            </span>
          </primaryComponents.Button>
        </div>
        <div className={styles.divider} />
        <div
          style={{ opacity: menuProperties?.allow_payment ? 1 : 0.4 }}
          className={styles.itemContainer}
        >
          <div>
            <div className={styles.itemEditContainer}>
              <h4>Paystack</h4>
              {paystackMethod && (
                <div
                  onClick={() => {
                    setShowSetupPaystack(true);
                    setPaymentId(paystackMethod?.id);
                  }}
                  className={styles.editContainer}
                >
                  <img src={Icon.editWrite} alt="" />
                  <span>Edit</span>
                </div>
              )}
            </div>
            <p>Give your customers the option to pay with paystack.</p>
          </div>
          {paystackMethod ? (
            <primaryComponents.Button
              classNames={`btn ${
                menuProperties?.pay_with_paystack
                  ? "btn--outline-red"
                  : "btn--success"
              }`}
              onClick={() => mutatePayWithPaystack()}
              loadingText={
                menuProperties?.pay_with_paystack
                  ? "De-activating..."
                  : "Activating..."
              }
              isLoading={isLoadingPayWithPaystack}
              isDisabled={
                isLoadingPayWithPaystack || !menuProperties?.allow_payment
              }
              showSpinner={false}
            >
              <span>
                {menuProperties?.pay_with_paystack ? "De-activate" : "Activate"}
              </span>
            </primaryComponents.Button>
          ) : (
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => {
                setShowSetupPaystack(true);
                setPaymentId(paystackMethod?.id);
              }}
              isDisabled={!menuProperties?.allow_payment}
            >
              <span>Set up</span>
            </primaryComponents.Button>
          )}
        </div>
        <div className={styles.divider} />
        <div
          style={{ opacity: menuProperties?.allow_payment ? 1 : 0.4 }}
          className={styles.itemContainer}
        >
          <div>
            <div className={styles.itemEditContainer}>
              <h4>Stripe</h4>
              {stripeMethod && (
                <div
                  onClick={() => {
                    setShowSetupStripe(true);
                    setPaymentId(stripeMethod?.id);
                  }}
                  className={styles.editContainer}
                >
                  <img src={Icon.editWrite} alt="" />
                  <span>Edit</span>
                </div>
              )}
            </div>
            <p>Give your customers the option to pay with stripe.</p>
          </div>
          {stripeMethod ? (
            <primaryComponents.Button
              classNames={`btn ${
                menuProperties?.pay_with_stripe
                  ? "btn--outline-red"
                  : "btn--success"
              }`}
              onClick={() => mutatePayWithStripe()}
              loadingText={
                menuProperties?.pay_with_stripe
                  ? "De-activating..."
                  : "Activating..."
              }
              isLoading={isLoadingPayWithStripe}
              isDisabled={
                isLoadingPayWithStripe || !menuProperties?.allow_payment
              }
              showSpinner={false}
            >
              <span>
                {menuProperties?.pay_with_stripe ? "De-activate" : "Activate"}
              </span>
            </primaryComponents.Button>
          ) : (
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => {
                setShowSetupStripe(true);
                setPaymentId(stripeMethod?.id);
              }}
              isDisabled={!menuProperties?.allow_payment}
            >
              <span>Set up</span>
            </primaryComponents.Button>
          )}
        </div>

        <secondaryComponents.SideModal
          isActive={showSetupPaystack !== null}
          closeModal={() => setShowSetupPaystack(null)}
          width={1000}
        >
          <PaystackSetup
            paymentId={paymentId}
            setShowSetupPaystack={setShowSetupPaystack}
          />
        </secondaryComponents.SideModal>

        <secondaryComponents.SideModal
          isActive={showSetupStripe !== null}
          closeModal={() => setShowSetupStripe(null)}
          width={1000}
        >
          <StripeSetup
            paymentId={paymentId}
            setShowSetupStripe={setShowSetupStripe}
          />
        </secondaryComponents.SideModal>
      </div>
    </secondaryComponents.LoaderHelper>
  );
};

export default Payment;
