const ProductStepperLogic = () => {
  const steps = [
    {
      id: 0,
      title: "Store",
      label: "Store",
    },
    {
      id: 1,
      title: "Category",
      label: "Category",
    },
    {
      id: 2,
      title: "Product",
      label: "Product",
    },
  ];

  return { steps };
};

export default ProductStepperLogic;
