import React from "react";

export const AppDisconnectionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="24" height="24" rx="12" fill="#FD561F" />
      <path
        d="M13.75 5.75H11.25C10.9048 5.75 10.625 6.02982 10.625 6.375V8.875C10.625 9.22018 10.9048 9.5 11.25 9.5H13.75C14.0952 9.5 14.375 9.22018 14.375 8.875V6.375C14.375 6.02982 14.0952 5.75 13.75 5.75Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 15.75V13.25C7.5 12.9048 7.77982 12.625 8.125 12.625H16.875C17.2202 12.625 17.5 12.9048 17.5 13.25V15.75"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 18.25C8.19036 18.25 8.75 17.6904 8.75 17C8.75 16.3096 8.19036 15.75 7.5 15.75C6.80964 15.75 6.25 16.3096 6.25 17C6.25 17.6904 6.80964 18.25 7.5 18.25Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 18.25C18.1904 18.25 18.75 17.6904 18.75 17C18.75 16.3096 18.1904 15.75 17.5 15.75C16.8096 15.75 16.25 16.3096 16.25 17C16.25 17.6904 16.8096 18.25 17.5 18.25Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18.25C13.1904 18.25 13.75 17.6904 13.75 17C13.75 16.3096 13.1904 15.75 12.5 15.75C11.8096 15.75 11.25 16.3096 11.25 17C11.25 17.6904 11.8096 18.25 12.5 18.25Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9.5V15.75"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4854 3.51471L3.51479 20.4853"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
