import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { addTag, updateTag } from "../../../services/tags";
import { useQueryClient } from "react-query";

const AddTransactionsTagLogic = ({ tagDetails, closeModal, isEditMode }) => {
  const [tag_name, setTagName] = useState(tagDetails ? tagDetails.name : "");
  const [isAddingTag, setIsAddingTag] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();

  const handleAddTagName = () => {
    setIsAddingTag(true);
    setRequestLoaderProgress(40);

    if (isEditMode) {
      return updateTag(tagDetails.id, { name: tag_name })
        .then(() => {
          setRequestLoaderProgress(100);
          setIsAddingTag(false);
          triggerToast("Tag updated successfully", "success");
          queryClient.invalidateQueries(["getTagList"]);
          closeModal();
        })
        .catch(() => {
          setIsAddingTag(false);
          setRequestLoaderProgress(100);
        });
    }

    return addTag({ name: tag_name })
      .then(() => {
        setRequestLoaderProgress(100);
        setIsAddingTag(false);
        triggerToast("Tag created successfully", "success");
        queryClient.invalidateQueries(["getTagList"]);
        closeModal();
      })
      .catch(() => {
        setIsAddingTag(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    tag_name,
    isAddingTag,
    setTagName,
    handleAddTagName,
  };
};

export default AddTransactionsTagLogic;
