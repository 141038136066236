import styles from "./CashOnHand.module.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { AddIcon } from "../../../../assets/svgIcons";
import useAllTransaction from "./useCashOnHand";
import AddCreditToBankAccount from "../../../../components/secondaryComponents/AddCreditToBankAccount";
import AddDebitToBankAccount from "../../../../components/secondaryComponents/AddDebitToBankAccount";
import { useSelector } from "react-redux";
import { formatCurrency } from "../../../../helpers";
const AccountingCashOnHand = () => {
  const {
    filterByStore,
    currentStore,
    storeList,
    showAddCredit,
    setShowAddCredit,
    handleFetchBankTransaction,
    showAddDebit,
    setShowAddDebit,
    isLoadingBankTransaction,
    bankTransaction,
    handleFetchCashTransactionList,
    Transactions,
    handleFetchTransactions,
  } = useAllTransaction();
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Cash Accountings Transaction"
      description="View all Cash accountings Transaction"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={` ${globalStyles.pageColumnGaps}`}
        >
          <div className="mt-2">
            <h4 className="font-weight-semibold">Cash On Hand</h4>
            <p className="default-text-size">
              Track debit and credit transactions made with cash
            </p>
          </div>
          <div className={`${styles.card} mt-3`}>
            <div className="d-flex flex-wrap justify-content-between w-100">
              <div className="w-50">
                <p className={`${styles.title} smallest-text-size p-1 mb-2`}>
                  {"Available Balance"}
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={handleFetchCashTransactionList}
                >
                  <h5>
                    {formatCurrency({
                      value: bankTransaction[0]?.balance || "0",
                      currencyCode: currency_code,
                    })}
                  </h5>{" "}
                </secondaryComponents.LoaderHelper>
              </div>
              <div style={{ width: "200px" }}>
                <primaryComponents.SelectField
                  options={storeList}
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    filterByStore(value);
                  }}
                  value={
                    storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.name || "Select store"
                  }
                  floatError={false}
                  classNames="store-name"
                  isSearchable={false}
                />
              </div>
            </div>
          </div>

          {/* table session */}
          <div className={styles.tableBody}>
            <div
              className={`m-1 d-flex justify-content-end ${styles.tableBody__header}`}
            >
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => setShowAddCredit(true)}
              >
                <div className="d-flex align-items-center">
                  <AddIcon color="#7647ee" />
                  <span className="default-text-size pl-2">Add Credit</span>
                </div>
              </primaryComponents.Button>

              <primaryComponents.Button
                classNames="btn btn--primary"
                onClick={() => setShowAddDebit(true)}
              >
                <div className="d-flex align-items-center">
                  <AddIcon />
                  <span className="default-text-size pl-2">Add Debit</span>
                </div>
              </primaryComponents.Button>
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isLoadingBankTransaction}
              classNames={"mt-5"}
            >
              <secondaryComponents.AllBankTransactionTable
                banks={Transactions}
                handleFetchbanks={handleFetchTransactions}
                type="cash"
              />
            </secondaryComponents.LoaderHelper>

            <secondaryComponents.SideModal
              isActive={showAddCredit}
              closeModal={() => setShowAddCredit(false)}
              width={500}
            >
              <AddCreditToBankAccount
                closeWidget={() => setShowAddCredit(false)}
                isLoading={isLoadingBankTransaction}
                handleFetchBanks={handleFetchTransactions}
                type="cash"
                handleFetchBankTransaction={handleFetchBankTransaction}
              />
            </secondaryComponents.SideModal>

            {/* debit */}
            <secondaryComponents.SideModal
              isActive={showAddDebit}
              closeModal={() => setShowAddDebit(false)}
              width={500}
            >
              <AddDebitToBankAccount
                closeWidget={() => setShowAddDebit(false)}
                isLoading={isLoadingBankTransaction}
                handleFetchBanks={handleFetchTransactions}
                type="cash"
                handleFetchBankTransaction={handleFetchBankTransaction}
              />
            </secondaryComponents.SideModal>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AccountingCashOnHand;
