import "./UserTableActions.scss";
import { Fragment } from "react";
import useModal from "../Modal/ModalLogic";
import secondaryComponents from "..";

const UserTableActions = () => {
  const { modalState, openModal, closeModal } = useModal();

  return (
    <Fragment>
      <div className="user-table-actions">
        <p className="user-table-actions__item dark--text">Remove</p>
        <p
          onClick={() => openModal()}
          className="user-table-actions__item dark--text"
        >
          View permissions
        </p>
      </div>
      <secondaryComponents.Modal
        isActive={modalState}
        closeModal={() => closeModal()}
        width="414px"
      >
        <secondaryComponents.UserRoles closeModal={closeModal} />
      </secondaryComponents.Modal>
    </Fragment>
  );
};

export default UserTableActions;
