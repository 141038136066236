import styles from "./RestaurantTable.module.scss";
import DeletOrderTableDialog from "../DeleteOrderTableDialog";
import { useRestaurantTable } from "./useRestaurantTable";
import secondaryComponents from "..";

const RestaurantTable = ({
  orderTables,
  handleFetchOrderTables,
  metaDetails,
  gotoPage,
}) => {
  const {
    instance,
    isopenDeleteDialog,
    tableDetails,
    isDeletingTable,
    setIsOpenDeleteDialog,
    handleDeleteOrderTable,
  } = useRestaurantTable({
    orderTables,
    handleFetchOrderTables,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.restaurantTable}>
      <div>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center pt-5 pb-5">
            {/* <img src={Images.expense2EmptyState} width="250px" alt="" /> */}
            <h4 className="font-weight-semibold text-center mt-4">
              No Data Available
            </h4>
          </div>
        </secondaryComponents.TableHelper>
      </div>

      {orderTables?.length > 0 && (
        <div className="mt-auto">
          <secondaryComponents.Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        </div>
      )}

      {isopenDeleteDialog && (
        <DeletOrderTableDialog
          isOpen={isopenDeleteDialog}
          setIsOpen={() => setIsOpenDeleteDialog(false)}
          tableDetails={tableDetails}
          handleDeleteTable={() => handleDeleteOrderTable(tableDetails?.id)}
          isLoading={isDeletingTable}
        />
      )}
    </div>
  );
};

export default RestaurantTable;
