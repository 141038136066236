import styles from "./SupplierForm.module.scss";
import primaryComponents from "../../primaryComponents";
import useSupplierForm from "./useSupplierForm";
import Validation from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SupplierForm = ({
  supplierInfo = null,
  isEditMode = false,
  handleFetchSuppliers = () => {},
  closeWidget = () => {},
}) => {
  const {
    isAddingSupplier,
    isEditingSupplier,
    supplierDetails,
    setSupplierDetails,
    submitSupplierInfo,
  } = useSupplierForm({
    supplierInfo,
    isEditMode,
    handleFetchSuppliers,
    closeWidget,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ supplierDetails });

  return (
    <div className={styles.supplierForm}>
      {isEditMode ? (
        <div className="mb-3">
          <FontAwesomeIcon
            onClick={() => {
              closeWidget();
            }}
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        </div>
      ) : null}

      <h4
        className={`font-weight-semibold ${!isEditMode ? "text-center" : ""}`}
      >
        {isEditMode ? "Supplier Details" : "Add New Supplier"}
      </h4>
      {/* form section */}
      <form
        onSubmit={handleSubmit(submitSupplierInfo)}
        className={styles.formSection}
      >
        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          name="supplierName"
          label="Supplier Name"
          placeholder="Enter your supplier name"
          isRequired={true}
          value={supplierDetails.name}
          onChange={({ target: { value } }) => {
            setSupplierDetails((prev) => ({
              ...prev,
              name: value,
            }));
          }}
          errorMessage={errors.supplierName}
          onKeyUp={() =>
            errors.supplierName !== undefined && trigger("supplierName")
          }
          register={register}
        />

        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          name="supplierContactName"
          label="Supplier Contact Name"
          placeholder="Enter supplier contact name"
          value={supplierDetails?.contact_name}
          onChange={({ target: { value } }) => {
            setSupplierDetails((prev) => ({
              ...prev,
              contact_name: value,
            }));
          }}
          errorMessage={errors.supplierContactName}
          onKeyUp={() =>
            errors.supplierContactName !== undefined &&
            trigger("supplierContactName")
          }
          register={register}
        />

        <primaryComponents.PhoneNumberInputFieldV2
          control={control}
          classNames="white"
          name="supplierPhone"
          label="Supplier Phone Number"
          placeholder="Enter your supplier phone number"
          isRequired={true}
          value={supplierDetails.supplierPhone}
          onChange={({ target: { value } }) => {
            setSupplierDetails((prev) => ({
              ...prev,
              phone_number: value,
            }));
          }}
          errorMessage={errors.supplierPhone}
          onKeyUp={() =>
            errors.supplierPhone !== undefined && trigger("supplierPhone")
          }
          register={register}
          phoneCodeEditable={true}
          onChangePhoneCode={(val) => {
            setSupplierDetails((prev) => ({
              ...prev,
              phone_code: val,
            }));
          }}
          countryCode={supplierDetails.phone_code}
        />

        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          name="supplierAddress"
          label="Supplier Address"
          placeholder="Enter supplier address"
          value={supplierDetails?.address}
          onChange={({ target: { value } }) => {
            setSupplierDetails((prev) => ({
              ...prev,
              address: value,
            }));
          }}
          errorMessage={errors.supplierAddress}
          onKeyUp={() =>
            errors.supplierAddress !== undefined && trigger("supplierAddress")
          }
          register={register}
        />

        <div className="d-flex justify-content-between mt-3">
          {!isEditMode ? (
            <primaryComponents.Button
              type="button"
              classNames={"btn btn--outline"}
              onClick={closeWidget}
            >
              Cancel
            </primaryComponents.Button>
          ) : null}
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isEditMode ? isEditingSupplier : isAddingSupplier}
            isDisabled={isEditMode ? isEditingSupplier : isAddingSupplier}
            loadingText={isEditMode ? "Updating..." : "Adding..."}
          >
            {isEditMode ? "Update" : "Add Supplier"}
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};
export default SupplierForm;
