import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  cleanObject,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import {
  useCreateBankMutation,
  useEditBankMutation,
} from "../../../hooks/api/mutations/useAccounting";
import { useQueryClient } from "react-query";

const useBankAccountLogic = ({ closeWidget, bankInfo, handleFetchBanks }) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const createBankMutation = useCreateBankMutation();
  const editBankMutation = useEditBankMutation();
  const queries = getUrlQuerysection();
  const queryClient = useQueryClient();

  const [bankDetails, setBankDetails] = useState({
    account_name: bankInfo?.account_name || "",
    bank_name: bankInfo?.bank_name || "",
    account_number: bankInfo?.account_number || "",
    account_type: bankInfo?.account_type || "bank_account",
    balance: bankInfo?.balance || "",
  });

  const handleAddBank = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...bankDetails };
    createBankMutation.mutate(cleanObject(data), {
      onSuccess: () => {
        handleFetchBanks();
        triggerToast("Bank added successfully", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // update bank
  const handleEditBank = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject(bankDetails);
    editBankMutation.mutate(
      { id: bankInfo?.id, data },
      {
        onSuccess: () => {
          handleFetchBanks();
          queryClient.invalidateQueries(["getAccountingBanksList", queries]);
          triggerToast("Bank updated successfully", "success");
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    bankDetails,
    setBankDetails,
    storeList,
    handleAddBank,
    handleEditBank,
    editBankMutation,
    createBankMutation,
  };
};

export default useBankAccountLogic;
