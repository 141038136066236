import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FundWallerValidationSchema = yup.object({
  customer: yup.string().required("Customer is required"),
});

export const Validation = ({ customerDetails }) => {
  return useForm({
    resolver: yupResolver(FundWallerValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customer: customerDetails?.customerId,
    },
  });
};
