import "./PasswordInputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import usePasswordInputField from "./PasswordInputFieldLogic";

const PasswordInputField = ({
  name = "",
  placeholder = "",
  label = null,
  isRequired = false,
  register = () => {},
  onChange = () => {},
  isDisabled = false,
  errorMessage = undefined,
  value = "",
  onKeyUp = () => {},
}) => {
  const { showPassword, handleShowPassword } = usePasswordInputField();

  return (
    <div className="password-container">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`password-container__input-section ${
          errorMessage !== undefined && "error-border"
        }`}
      >
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          placeholder={placeholder}
          id={name}
          {...register(name, { required: isRequired })}
          onChange={onChange}
          disabled={isDisabled}
          defaultValue={value}
          autoComplete="on"
          onKeyUp={onKeyUp}
        />
        <div onClick={handleShowPassword} style={{ background: "transparent" }}>
          <FontAwesomeIcon
            icon={`${showPassword ? "eye-slash" : "eye"}`}
            style={{ fontSize: "20px" }}
            className="gray--text"
          />
        </div>
      </div>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
        data-testid="error-message"
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default PasswordInputField;
