import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  statementFormat: yup.string().label("statementFormat").oneOf(["PDF", "CSV"]),
  startDate: yup.string().required("Start date is required"),
  endDate: yup.string().required("End date is required"),
});

const Validation = (statementFormat, startDate, endDate) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      statementFormat,
      startDate,
      endDate,
    },
  });
};

export default Validation;
