/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import styles from "./SingleProductForm.module.scss";
import primaryComponents from "../../primaryComponents";
import { Validation } from "./Validation";
import useSingleProductForm from "./useSingleProductForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Images from "../../../assets/images";
import Icon from "../../../assets/icons";
import { useEffect } from "react";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "..";
import { ImagePlaceHolder } from "../../../assets/svgIcons";

const SingleProductForm = ({
  isEditMode = false,
  productDetails = null,
  isUpdatingProduct = false,
  handleProductUpdate = () => {},
}) => {
  const {
    productImages,
    booleanLabel,
    inputRef,
    rootFormDetails,
    categories,
    storeList,
    isUploadingImage,
    variantProperties,
    storeProperties,
    stockUnits,
    isLoadingStockUnit,
    pricingTypes,
    isLoadingPricingTypes,
    isLoadingCategories,
    sections,
    isLoadingSections,
    variantImgRef,
    modalViews,
    resetModalViews,
    setProductImages,
    handleDeleteVariantFromBackend,
    setStoreProperties,
    setVariantProperties,
    addNewVariantOption,
    setRootFormDetails,
    handleVariantImageUpload,
    createOrUpdateProduct,
    setIsStorePropertiesUpdated,
    setIsVariantPropertiesUpdated,
    deleteVariantProperty,
    addNewCategory,
    addNewPricingForStoreProperty,
    removePricingForStoreProperty,
    addNewPricingPropertyForVariantStoreProperty,
    removePricingPropertyForVariantStoreProperty,
    handleMultipleImageUpload,
    deleteImage,
  } = useSingleProductForm({ isEditMode, productDetails, handleProductUpdate });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
  } = Validation({
    rootFormDetails,
    storeProperties,
    variantProperties,
  });

  useEffect(() => {
    window.setProductFormValue = setValue;
  }, []);

  return (
    <>
      <div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          className={styles.singleProductForm}
        >
          <form id="productForm" onSubmit={handleSubmit(createOrUpdateProduct)}>
            <div className={styles.topFormSection}>
              <div className={styles.topFormSection__left}>
                {/* product name */}
                <primaryComponents.InputFieldV2
                  name="productName"
                  control={control}
                  register={register}
                  placeholder={"Enter product name"}
                  classNames="white"
                  label="Product Name"
                  value={rootFormDetails.name}
                  isRequired
                  errorMessage={errors?.productName}
                  onKeyUp={() => errors?.productName && trigger("productName")}
                  onChange={({ target: { value } }) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      name: value,
                    }));
                  }}
                />

                {/* category field */}
                <secondaryComponents.LoaderHelper
                  isLoading={isLoadingCategories}
                >
                  <div className="mb-2">
                    <label>
                      <span className="error--text">*</span>Category
                    </label>
                    <Controller
                      name="productCategory"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <primaryComponents.TagsMultiSelect
                          options={categories}
                          idKey="id"
                          nameKey="name"
                          placeholder="Select category"
                          onChange={(item) => {
                            onChange(item?.label);
                            setRootFormDetails((prevState) => ({
                              ...prevState,
                              category: item,
                            }));
                          }}
                          onCreateOption={(value) => {
                            addNewCategory(value);
                            onChange(value);
                          }}
                          isSearchable={true}
                          isMulti={false}
                          value={rootFormDetails?.category}
                          giveControl={false}
                          height="50px"
                          fontSize="16px"
                        />
                      )}
                    />
                    {/* error message */}
                    {errors.productCategory?.message && (
                      <p className="text-danger">
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-circle"]}
                          className="mr-2"
                          style={{ fontSize: "13px" }}
                        />
                        <span style={{ fontSize: "13px" }}>
                          {errors.productCategory?.message}
                        </span>
                      </p>
                    )}
                  </div>
                </secondaryComponents.LoaderHelper>

                {/* store field */}
                <primaryComponents.MultipleSelectFieldV2
                  name="stores"
                  control={control}
                  options={storeList}
                  isRequired={true}
                  value={rootFormDetails.stores}
                  placeholder="Select store..."
                  errorMessage={errors?.stores}
                  idKey="id"
                  nameKey="name"
                  label="Select Store To Make Product Available At"
                  onChange={(values) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      stores: values,
                    }));
                    if (rootFormDetails?.has_variant)
                      setIsVariantPropertiesUpdated(true);
                    else setIsStorePropertiesUpdated(true);
                  }}
                />
                <div className={styles.flexArrange}>
                  {/* unit */}
                  <div className="w-100">
                    <div className="d-flex justify-content-between align-items-center">
                      <label>
                        <span className="error--text">*</span>Product Unit
                      </label>
                      <span
                        className="purple--text small-text-size cursor-pointer"
                        onClick={() => resetModalViews("isCreateUnit")}
                      >
                        Create unit
                      </span>
                    </div>
                    <primaryComponents.SelectFieldV2
                      name="unit"
                      control={control}
                      value={rootFormDetails.unit?.label || "Select..."}
                      options={stockUnits}
                      nameKey="unit"
                      idKey="id"
                      classNames="w-100 white"
                      onChange={(value, label) => {
                        setRootFormDetails((prevState) => ({
                          ...prevState,
                          unit: { value, label },
                        }));
                      }}
                      isSearchable={true}
                      isDisabled={isLoadingStockUnit}
                    />
                  </div>

                  <secondaryComponents.LoaderHelper
                    isLoading={isLoadingSections}
                    classNames={"mt-2"}
                  >
                    {sections?.length > 0 ? (
                      <primaryComponents.SelectFieldV2
                        name="section"
                        control={control}
                        label="Select section"
                        value={
                          sections?.find(
                            (section) =>
                              section.id === rootFormDetails?.section,
                          )?.name || "Select..."
                        }
                        options={sections}
                        nameKey="name"
                        idKey="id"
                        classNames="w-100"
                        onChange={(value) => {
                          setRootFormDetails((prevState) => ({
                            ...prevState,
                            section: value,
                          }));
                        }}
                        isSearchable={true}
                      />
                    ) : null}
                  </secondaryComponents.LoaderHelper>
                </div>

                <div className={styles.flexArrange}>
                  {/* has variants */}
                  <primaryComponents.SelectFieldV2
                    classNames="w-100"
                    name="hasVariants"
                    control={control}
                    label="Does This Product Have Variants?"
                    isRequired={true}
                    value={booleanLabel[rootFormDetails.has_variant]}
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    nameKey="label"
                    idKey="value"
                    onChange={(value) => {
                      setRootFormDetails((prevState) => ({
                        ...prevState,
                        has_variant: value,
                      }));
                      if (value) setIsVariantPropertiesUpdated(true);
                      else setIsStorePropertiesUpdated(true);
                    }}
                    isSearchable={false}
                    errorMessage={errors?.hasVariants}
                  />

                  {/* show on sell-mode */}
                  <primaryComponents.SelectFieldV2
                    classNames="w-100"
                    name="showProductOnSellMode"
                    control={control}
                    label="Show this product in sell mode?"
                    isRequired={true}
                    value={
                      booleanLabel[rootFormDetails.show_product_in_sell_mode]
                    }
                    options={[
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    nameKey="label"
                    idKey="value"
                    onChange={(value) => {
                      setRootFormDetails((prevState) => ({
                        ...prevState,
                        show_product_in_sell_mode: value,
                      }));
                    }}
                    isSearchable={false}
                  />
                </div>

                <div className={styles.flexArrange}>
                  {/* product sku */}
                  <primaryComponents.InputFieldV2
                    name="productSku"
                    control={control}
                    label="Product SKU"
                    placeholder="Enter SKU"
                    classNames="white"
                    value={rootFormDetails.sku}
                    errorMessage={errors?.productSku}
                    onKeyUp={() => errors?.productSku && trigger("productSku")}
                    onChange={({ target: { value } }) => {
                      setRootFormDetails((prevState) => ({
                        ...prevState,
                        sku: value,
                      }));
                    }}
                  />
                  {/* bar code */}
                  {!rootFormDetails.has_variant ? (
                    <primaryComponents.InputFieldV2
                      inputIcon={Icon.barCodeIcon}
                      name="barcode"
                      control={control}
                      label="Scan Barcode"
                      placeholder="Scan barcode"
                      classNames="white"
                      value={rootFormDetails.barcode}
                      errorMessage={errors?.barcode}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") e.preventDefault();
                        errors?.barcode && trigger("barcode");
                      }}
                      onChange={(e) => {
                        setRootFormDetails((prevState) => ({
                          ...prevState,
                          barcode: e?.target?.value,
                        }));
                      }}
                    />
                  ) : null}
                </div>

                <primaryComponents.TextAreaV2
                  classNames="white"
                  label="Description"
                  placeholder="Add description to this product"
                  control={control}
                  name="description"
                  value={rootFormDetails?.description}
                  register={register}
                  errorMessage={errors?.description}
                  onKeyUp={() => errors?.description && trigger("description")}
                  onChange={({ target: { value } }) => {
                    setRootFormDetails((prevState) => ({
                      ...prevState,
                      description: value,
                    }));
                  }}
                />
              </div>

              {/* profile section */}
              <div className={styles.topFormSection__right}>
                <input
                  style={{ display: "none" }}
                  ref={inputRef}
                  type="file"
                  name="product-img"
                  id="product-img"
                  onChange={handleMultipleImageUpload}
                />
                <primaryComponents.Button
                  classNames={`btn btn--outline ${styles.uploadBtn}`}
                  onClick={() => inputRef.current.click()}
                  isLoading={isUploadingImage}
                  loadingText={"uploading..."}
                  primarySpinner={false}
                  type="button"
                >
                  <img className="mr-1" src={Icon.addCriclePlus} alt="" />
                  Add Product Images
                </primaryComponents.Button>
                <div className={styles.imagesContainer}>
                  {productImages?.length > 0 ? (
                    productImages?.map((image, index) => (
                      <div key={index} className={styles.imageDeleteContainer}>
                        <div className={styles.imageContainer}>
                          <img
                            src={
                              image.url
                                ? image.url
                                : image.preview
                                  ? image.preview
                                  : Images.product_avatar
                            }
                            alt=""
                          />
                        </div>
                        <div
                          onClick={() => {
                            if (image.url) {
                              setProductImages((prev) =>
                                prev.filter((item) => item.id !== image.id),
                              );
                              deleteImage(image.id);
                            } else {
                              setProductImages((prev) =>
                                prev.filter(
                                  (item) => item.preview !== image.preview,
                                ),
                              );
                            }
                          }}
                          className={styles.deleteContainer}
                        >
                          <img src={Icon.deleteIcon} alt="" />
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.imageDeleteContainer}>
                      <div className={styles.imageContainer}>
                        <img src={Images.product_avatar} alt="" />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* display if variant is true */}
            {rootFormDetails?.has_variant ? (
              <h5 className="mt-5">Add Product Variants</h5>
            ) : null}

            <div
              className={`${styles.bottomFormSection} ${rootFormDetails?.has_variant ? "" : styles.showBorder}`}
            >
              {/* product with variants */}
              {rootFormDetails.has_variant ? (
                <>
                  {variantProperties?.map((item, variantIndex) => (
                    <div
                      className={`${styles.priceCover} ${styles.thickTopBorder}`}
                      key={item?.uuid}
                    >
                      <div className={styles.variantOption}>
                        <input
                          style={{ display: "none" }}
                          ref={(el) =>
                            (variantImgRef.current[variantIndex] = el)
                          }
                          type="file"
                          name={`variant-img-${variantIndex}`}
                          id={`variant-img-${variantIndex}`}
                          onChange={(params) =>
                            handleVariantImageUpload(params, variantIndex)
                          }
                        />
                        {/* variant image */}
                        <div
                          className={styles.imageContainer}
                          onClick={() =>
                            variantImgRef.current[variantIndex].click()
                          }
                        >
                          {variantProperties[variantIndex]?.image_url ? (
                            <img
                              src={variantProperties[variantIndex]?.image_url}
                              alt="variant"
                            />
                          ) : (
                            <ImagePlaceHolder />
                          )}
                        </div>
                        {/* varaint option */}
                        <primaryComponents.InputFieldV2
                          labelClassNames={"labelHighlightBg"}
                          name={`variantProperties.${variantIndex}.option`}
                          control={control}
                          label="Variant Type"
                          register={register}
                          classNames="white"
                          placeholder="Enter your option eg. size, color, flavor etc"
                          isRequired
                          errorMessage={
                            errors?.variantProperties?.[variantIndex]?.option
                          }
                          onKeyUp={() =>
                            errors?.variantProperties?.[variantIndex]?.option &&
                            trigger(`variantProperties.${variantIndex}.option`)
                          }
                          onChange={({ target: { value } }) => {
                            const newVariantProperties = [...variantProperties];
                            newVariantProperties[variantIndex].option = value;
                            setVariantProperties(newVariantProperties);
                          }}
                        />
                        {/* variant value */}
                        <primaryComponents.InputFieldV2
                          labelClassNames={"labelHighlightBg"}
                          name={`variantProperties.${variantIndex}.value`}
                          control={control}
                          label="Variant Value"
                          register={register}
                          classNames="white"
                          placeholder="Enter your values eg. small, followed by a comma"
                          isRequired
                          errorMessage={
                            errors?.variantProperties?.[variantIndex]?.value
                          }
                          onKeyUp={() =>
                            errors?.variantProperties?.[variantIndex]?.value &&
                            trigger(`variantProperties.${variantIndex}.value`)
                          }
                          onChange={({ target: { value } }) => {
                            const newVariantProperties = [...variantProperties];
                            newVariantProperties[variantIndex].value = value;
                            setVariantProperties(newVariantProperties);
                          }}
                        />

                        {/* bar code */}
                        <primaryComponents.InputFieldV2
                          labelClassNames={"labelHighlightBg"}
                          inputIcon={Icon.barCodeIcon}
                          name={`variantProperties.${variantIndex}.barcode`}
                          control={control}
                          label="Scan Barcode"
                          placeholder="Scan barcode"
                          classNames="white"
                          errorMessage={
                            errors?.variantProperties?.[variantIndex]?.barcode
                          }
                          value={
                            variantProperties?.[variantIndex]?.barcode || ""
                          }
                          onKeyUp={(e) => {
                            if (e.key === "Enter") e.preventDefault();
                            errors?.variantProperties?.[variantIndex]
                              ?.barcode &&
                              trigger(
                                `variantProperties.${variantIndex}.barcode`,
                              );
                          }}
                          onChange={(e) => {
                            const newVariantProperties = [...variantProperties];
                            newVariantProperties[variantIndex].barcode =
                              e?.target?.value;
                            setVariantProperties(newVariantProperties);
                          }}
                        />

                        {/* delete variant btn */}
                        {variantProperties?.length > 1 ? (
                          <button
                            type="button"
                            className="btn btn--outline-red"
                            style={{
                              height: "30px",
                              minWidth: "28px",
                              padding: "0",
                              borderRadius: "3px",
                            }}
                            onClick={() => {
                              deleteVariantProperty(variantIndex);
                              if (item?.id)
                                handleDeleteVariantFromBackend(item?.id);
                            }}
                          >
                            <img src={Icon.deleteIcon} alt="" />
                          </button>
                        ) : null}
                      </div>

                      {/* product with different store price */}
                      {item?.store_properties?.length > 0 ? (
                        <>
                          {item?.store_properties.map(
                            (item, storePropertyIndex) => (
                              <div
                                key={item?.store_id}
                                className={styles.variantStoreCover}
                              >
                                <h6>Enter {item?.store_name || ""} pricing</h6>
                                <div
                                  className={`${styles.priceSection} ${styles.priceSectionBigInput}`}
                                >
                                  <primaryComponents.InputFieldV2
                                    name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.cost_price`}
                                    control={control}
                                    type="number"
                                    step={0.01}
                                    label={`Cost Price (${
                                      storeList.find(
                                        (store) => store?.id === item?.store_id,
                                      )?.currency_symbol
                                    })`}
                                    placeholder="0"
                                    classNames="white"
                                    isRequired
                                    isDisabled={
                                      variantProperties[variantIndex]
                                        .useSameVariantPricing &&
                                      storePropertyIndex > 0
                                    }
                                    value={
                                      variantProperties[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.cost_price
                                    }
                                    errorMessage={
                                      errors?.variantProperties?.[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.cost_price
                                    }
                                    onKeyUp={() =>
                                      errors?.variantProperties?.[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.cost_price &&
                                      trigger(
                                        `variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.cost_price`,
                                      )
                                    }
                                    onChange={({ target: { value } }) => {
                                      const newVariantProperties = [
                                        ...variantProperties,
                                      ];
                                      newVariantProperties[
                                        variantIndex
                                      ].store_properties[
                                        storePropertyIndex
                                      ].cost_price = value;

                                      setVariantProperties(
                                        newVariantProperties,
                                      );
                                      setIsVariantPropertiesUpdated(true);
                                    }}
                                  />

                                  {/* rebate */}
                                  <primaryComponents.InputFieldV2
                                    name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.rebate`}
                                    control={control}
                                    type="number"
                                    step={0.01}
                                    min={0}
                                    label="Rebate"
                                    placeholder="0"
                                    classNames="white"
                                    showLabelIcon={true}
                                    labelTootipText="Rebate"
                                    value={
                                      variantProperties[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.rebate || ""
                                    }
                                    errorMessage={
                                      errors?.variantProperties?.[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.rebate
                                    }
                                    onKeyUp={() =>
                                      errors?.variantProperties?.[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.rebate &&
                                      trigger(
                                        `variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.rebate`,
                                      )
                                    }
                                    onChange={({ target: { value } }) => {
                                      const newVariantProperties = [
                                        ...variantProperties,
                                      ];
                                      newVariantProperties[
                                        variantIndex
                                      ].store_properties[
                                        storePropertyIndex
                                      ].rebate =
                                        value === "" ? undefined : value;
                                      setVariantProperties(
                                        newVariantProperties,
                                      );
                                    }}
                                  />

                                  {/* stock quantity */}
                                  {!isEditMode ? (
                                    <primaryComponents.InputFieldV2
                                      name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.stock_quantity`}
                                      control={control}
                                      type="number"
                                      step={0.01}
                                      min={0.01}
                                      label="Initial Stock"
                                      placeholder="0"
                                      classNames="white"
                                      value={
                                        variantProperties[variantIndex]
                                          ?.store_properties?.[
                                          storePropertyIndex
                                        ]?.stock_quantity || ""
                                      }
                                      errorMessage={
                                        errors?.variantProperties?.[
                                          variantIndex
                                        ]?.store_properties?.[
                                          storePropertyIndex
                                        ]?.stock_quantity
                                      }
                                      onKeyUp={() =>
                                        errors?.variantProperties?.[
                                          variantIndex
                                        ]?.store_properties?.[
                                          storePropertyIndex
                                        ]?.stock_quantity &&
                                        trigger(
                                          `variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.stock_quantity`,
                                        )
                                      }
                                      onChange={({ target: { value } }) => {
                                        const newVariantProperties = [
                                          ...variantProperties,
                                        ];
                                        newVariantProperties[
                                          variantIndex
                                        ].store_properties[
                                          storePropertyIndex
                                        ].stock_quantity =
                                          value === "" ? undefined : value;
                                        setVariantProperties(
                                          newVariantProperties,
                                        );
                                      }}
                                    />
                                  ) : null}

                                  {/* minimum stock */}
                                  <primaryComponents.InputFieldV2
                                    name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.minimum_stock_quantity`}
                                    control={control}
                                    type="number"
                                    step={0.01}
                                    min={0}
                                    label="Stock Level Alert"
                                    placeholder="0"
                                    classNames="white"
                                    value={
                                      variantProperties[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.minimum_stock_quantity || ""
                                    }
                                    errorMessage={
                                      errors?.variantProperties?.[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.minimum_stock_quantity
                                    }
                                    onKeyUp={() =>
                                      errors?.variantProperties?.[variantIndex]
                                        ?.store_properties?.[storePropertyIndex]
                                        ?.minimum_stock_quantity &&
                                      trigger(
                                        `variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.minimum_stock_quantity`,
                                      )
                                    }
                                    onChange={({ target: { value } }) => {
                                      const newVariantProperties = [
                                        ...variantProperties,
                                      ];
                                      newVariantProperties[
                                        variantIndex
                                      ].store_properties[
                                        storePropertyIndex
                                      ].minimum_stock_quantity =
                                        value === "" ? undefined : value;
                                      setVariantProperties(
                                        newVariantProperties,
                                      );
                                    }}
                                  />

                                  {/* expiry date */}
                                  {variantProperties[variantIndex]
                                    ?.store_properties?.[storePropertyIndex]
                                    ?.stock_quantity > 0 || isEditMode ? (
                                    <primaryComponents.DateInputV2
                                      name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.expiry_date`}
                                      control={control}
                                      label="Expiry Date"
                                      placeholder="Enter date"
                                      classNames="white"
                                      value={
                                        variantProperties[variantIndex]
                                          ?.store_properties?.[
                                          storePropertyIndex
                                        ]?.expiry_date || ""
                                      }
                                      errorMessage={
                                        errors?.variantProperties?.[
                                          variantIndex
                                        ]?.store_properties?.[
                                          storePropertyIndex
                                        ]?.expiry_date
                                      }
                                      onKeyUp={() =>
                                        errors?.variantProperties?.[
                                          variantIndex
                                        ]?.store_properties?.[
                                          storePropertyIndex
                                        ]?.expiry_date &&
                                        trigger(
                                          `variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.expiry_date`,
                                        )
                                      }
                                      onChange={({ target: { value } }) => {
                                        const newVariantProperties = [
                                          ...variantProperties,
                                        ];
                                        newVariantProperties[
                                          variantIndex
                                        ].store_properties[
                                          storePropertyIndex
                                        ].expiry_date =
                                          value === "" ? undefined : value;
                                        setVariantProperties(
                                          newVariantProperties,
                                        );
                                      }}
                                    />
                                  ) : null}

                                  {item?.pricings?.map(
                                    (pricingProperty, pricingIndex) => {
                                      const pricingDetailsProperty =
                                        pricingTypes?.find(
                                          (pricingItem) =>
                                            pricingItem?.id ===
                                            pricingProperty?.pricing_type_id,
                                        );

                                      return (
                                        <div
                                          className={`${styles.priceSection} ${globalStyles.borderTopPurple} ${globalStyles.borderTop} mt-0 pt-3`}
                                          style={{ minWidth: "100%" }}
                                          key={pricingProperty?.uuid}
                                        >
                                          {/* pricing type */}
                                          <secondaryComponents.LoaderHelper
                                            isLoading={isLoadingPricingTypes}
                                            classNames={"mt-2"}
                                          >
                                            <div style={{ maxWidth: "180px" }}>
                                              <label htmlFor="">
                                                <span className="text-danger">
                                                  *
                                                </span>

                                                <span>
                                                  Price Type
                                                  {item?.pricings?.length ===
                                                  pricingIndex + 1 ? (
                                                    <>
                                                      {" ( "}
                                                      <span
                                                        className="purple--text"
                                                        style={{
                                                          textDecoration:
                                                            "underline",
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                          resetModalViews(
                                                            "isCreatePricing",
                                                          )
                                                        }
                                                      >
                                                        Create new
                                                      </span>
                                                      {" )"}
                                                    </>
                                                  ) : null}
                                                </span>
                                              </label>
                                              <primaryComponents.SelectFieldV2
                                                name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.pricings.${pricingIndex}.pricing_type_id`}
                                                control={control}
                                                giveControl={false}
                                                value={{
                                                  label:
                                                    pricingDetailsProperty?.label,
                                                  value:
                                                    pricingDetailsProperty?.id,
                                                }}
                                                options={pricingTypes}
                                                nameKey="label"
                                                idKey="id"
                                                onChange={(value) => {
                                                  const newVariantProperties = [
                                                    ...variantProperties,
                                                  ];
                                                  newVariantProperties[
                                                    variantIndex
                                                  ].store_properties[
                                                    storePropertyIndex
                                                  ].pricings[
                                                    pricingIndex
                                                  ].pricing_type_id = value;
                                                  setStoreProperties(
                                                    newVariantProperties,
                                                  );
                                                  setIsStorePropertiesUpdated(
                                                    true,
                                                  );
                                                }}
                                                errorMessage={
                                                  errors?.variantProperties?.[
                                                    variantIndex
                                                  ]?.store_properties?.[
                                                    storePropertyIndex
                                                  ]?.pricings?.[pricingIndex]
                                                    ?.pricing_type_id
                                                }
                                                isDisabled={
                                                  (variantProperties[
                                                    variantIndex
                                                  ].useSameVariantPricing &&
                                                    storePropertyIndex > 0) ||
                                                  pricingIndex === 0
                                                }
                                                isSearchable={false}
                                              />
                                            </div>
                                          </secondaryComponents.LoaderHelper>

                                          {/* selling price */}
                                          <primaryComponents.InputFieldV2
                                            name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.pricings.${pricingIndex}.price`}
                                            control={control}
                                            type="number"
                                            step={0.01}
                                            label={`Selling Price (${
                                              storeList.find(
                                                (store) =>
                                                  store?.id === item?.store_id,
                                              )?.currency_symbol
                                            })`}
                                            placeholder="0"
                                            classNames="white"
                                            isRequired
                                            isDisabled={
                                              variantProperties[variantIndex]
                                                .useSameVariantPricing &&
                                              storePropertyIndex > 0
                                            }
                                            value={pricingProperty?.price}
                                            errorMessage={
                                              errors?.variantProperties?.[
                                                variantIndex
                                              ]?.store_properties?.[
                                                storePropertyIndex
                                              ]?.pricings?.[pricingIndex]?.price
                                            }
                                            onKeyUp={() =>
                                              errors?.variantProperties?.[
                                                variantIndex
                                              ]?.store_properties?.[
                                                storePropertyIndex
                                              ]?.pricings?.[pricingIndex]
                                                ?.price &&
                                              trigger(
                                                `variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.pricings.${pricingIndex}.price`,
                                              )
                                            }
                                            onChange={({
                                              target: { value },
                                            }) => {
                                              const newVariantProperties = [
                                                ...variantProperties,
                                              ];
                                              newVariantProperties[
                                                variantIndex
                                              ].store_properties[
                                                storePropertyIndex
                                              ].pricings[pricingIndex].price =
                                                value;
                                              setVariantProperties(
                                                newVariantProperties,
                                              );
                                              setIsVariantPropertiesUpdated(
                                                true,
                                              );
                                            }}
                                          />

                                          {/* discount */}
                                          <primaryComponents.PercentField
                                            label="Selling price discount"
                                            name={`variantProperties.${variantIndex}.store_properties.${storePropertyIndex}.pricings.${pricingIndex}.discount_rate`}
                                            control={control}
                                            isDisabled={
                                              variantProperties[variantIndex]
                                                .useSameVariantPricing &&
                                              storePropertyIndex > 0
                                            }
                                            value={
                                              pricingProperty?.discount_rate
                                            }
                                            onChange={({ percentValue }) => {
                                              const newVariantProperties = [
                                                ...variantProperties,
                                              ];
                                              newVariantProperties[
                                                variantIndex
                                              ].store_properties[
                                                storePropertyIndex
                                              ].pricings[
                                                pricingIndex
                                              ].discount_rate = percentValue;
                                              setVariantProperties(
                                                newVariantProperties,
                                              );
                                              setIsVariantPropertiesUpdated(
                                                true,
                                              );
                                            }}
                                            dividedValue={
                                              pricingProperty?.price === ""
                                                ? 0
                                                : pricingProperty.price
                                            }
                                          />

                                          {/* discounted selling price */}
                                          <primaryComponents.InputField
                                            type="number"
                                            classNames="white"
                                            label={`Discounted selling price (${
                                              storeList.find(
                                                (store) =>
                                                  store?.id === item?.store_id,
                                              )?.currency_symbol
                                            })`}
                                            isDisabled={true}
                                            value={(() => {
                                              let sellingPrice =
                                                pricingProperty?.price === ""
                                                  ? 0
                                                  : pricingProperty?.price;
                                              let discountPercent =
                                                pricingProperty?.discount_rate ===
                                                ""
                                                  ? 0
                                                  : pricingProperty?.discount_rate;
                                              let discount_rate =
                                                (sellingPrice *
                                                  discountPercent) /
                                                100;

                                              let discountedSellingPrice =
                                                sellingPrice - discount_rate;

                                              return discountedSellingPrice;
                                            })()}
                                          />

                                          {/* profit */}
                                          <primaryComponents.InputField
                                            classNames="white"
                                            label={`Profit (${
                                              storeList.find(
                                                (store) =>
                                                  store?.id === item?.store_id,
                                              )?.currency_symbol
                                            })`}
                                            isDisabled={true}
                                            value={(
                                              pricingProperty?.price -
                                              (pricingProperty.price *
                                                pricingProperty?.discount_rate) /
                                                100 -
                                              item?.cost_price
                                            ).toFixed(2)}
                                          />

                                          {pricingIndex > 0 ? (
                                            <div
                                              className="d-flex align-items-center my-auto"
                                              style={{
                                                minWidth: "fit-content",
                                                maxWidth: "fit-content",
                                              }}
                                            >
                                              <button
                                                type="button"
                                                className="btn btn--outline-red"
                                                style={{
                                                  height: "30px",
                                                  width: "28px",
                                                  padding: "0",
                                                  borderRadius: "3px",
                                                }}
                                                onClick={() =>
                                                  removePricingPropertyForVariantStoreProperty(
                                                    pricingIndex,
                                                    storePropertyIndex,
                                                    variantIndex,
                                                  )
                                                }
                                              >
                                                <img
                                                  src={Icon.deleteIcon}
                                                  alt=""
                                                />
                                              </button>
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    },
                                  )}
                                </div>

                                <div className={styles.useAcrossSection}>
                                  <a
                                    className="purple--text"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      addNewPricingPropertyForVariantStoreProperty(
                                        storePropertyIndex,
                                        variantIndex,
                                      )
                                    }
                                  >
                                    + Add another price option
                                  </a>
                                  {storePropertyIndex === 0 ? (
                                    <label
                                      className="d-flex align-items-center"
                                      style={{ cursor: "pointer" }}
                                      htmlFor={`useSameVarianyPricing${variantIndex}`}
                                    >
                                      <primaryComponents.Checkbox
                                        name="useSameVariantPricing"
                                        id={`useSameVariantPricing${variantIndex}`}
                                        isChecked={
                                          variantProperties?.[variantIndex]
                                            ?.useSameVariantPricing
                                        }
                                        onChange={(value) => {
                                          const newVariantProperties = [
                                            ...variantProperties,
                                          ];
                                          newVariantProperties[
                                            variantIndex
                                          ].useSameVariantPricing = value;
                                          setVariantProperties(
                                            newVariantProperties,
                                          );

                                          if (value) {
                                            if (rootFormDetails.has_variant)
                                              setIsVariantPropertiesUpdated(
                                                true,
                                              );
                                          }
                                        }}
                                      />
                                      <span className="ml-2 mt-1">
                                        Use this pricing properties across other
                                        stores for this variant
                                      </span>
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            ),
                          )}
                        </>
                      ) : (
                        <h5 className="text-center my-4">
                          Please select stores to proceed with pricing
                        </h5>
                      )}
                    </div>
                  ))}
                  {/* add variant option */}
                  {variantProperties?.length > 0 && (
                    <div className={styles.optionContainer}>
                      <a
                        className="purple--text"
                        onClick={() => addNewVariantOption()}
                      >
                        + Add variant
                      </a>
                    </div>
                  )}
                </>
              ) : (
                // product without variants
                <>
                  {storeProperties?.length > 0 ? (
                    <>
                      {storeProperties.map((item, storeIndex) => (
                        <div className={styles.priceCover} key={item.store_id}>
                          <div>
                            <h6>Enter {item?.store_name || ""} pricing</h6>
                            <div
                              className={`${styles.priceSection} ${styles.priceSectionBigInput}`}
                            >
                              {/* cost price */}
                              <primaryComponents.InputFieldV2
                                name={`storeProperties.${storeIndex}.cost_price`}
                                control={control}
                                type="number"
                                step={0.01}
                                label={`Cost Price (${
                                  storeList.find(
                                    (store) => store?.id === item?.store_id,
                                  )?.currency_symbol
                                })`}
                                placeholder="0"
                                classNames="white"
                                isRequired
                                isDisabled={
                                  storeIndex > 0 &&
                                  rootFormDetails?.use_same_pricing
                                }
                                value={storeProperties[storeIndex].cost_price}
                                errorMessage={
                                  errors?.storeProperties?.[storeIndex]
                                    ?.cost_price
                                }
                                onKeyUp={() =>
                                  errors?.storeProperties?.[storeIndex]
                                    ?.cost_price &&
                                  trigger(
                                    `storeProperties.${storeIndex}.cost_price`,
                                  )
                                }
                                onChange={({ target: { value } }) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[storeIndex].cost_price =
                                    value;
                                  setStoreProperties(newStoreProperties);
                                  setIsStorePropertiesUpdated(true);
                                }}
                              />

                              {/* rebate */}
                              <primaryComponents.InputFieldV2
                                name={`storeProperties.${storeIndex}.rebate`}
                                control={control}
                                type="number"
                                step={0.01}
                                min={0}
                                label="Rebate"
                                placeholder="0"
                                classNames="white"
                                value={
                                  storeProperties[storeIndex]?.rebate || ""
                                }
                                errorMessage={
                                  errors?.storeProperties?.[storeIndex]?.rebate
                                }
                                onKeyUp={() =>
                                  errors?.storeProperties?.[storeIndex]
                                    ?.rebate &&
                                  trigger(
                                    `storeProperties.${storeIndex}.rebate`,
                                  )
                                }
                                onChange={({ target: { value } }) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[storeIndex].rebate =
                                    value === "" ? undefined : value;
                                  setStoreProperties(newStoreProperties);
                                  setIsStorePropertiesUpdated(true);
                                }}
                              />

                              {!isEditMode ? (
                                // stock quantity
                                <primaryComponents.InputFieldV2
                                  name={`storeProperties.${storeIndex}.stock_quantity`}
                                  control={control}
                                  type="number"
                                  step={0.01}
                                  min={0.01}
                                  label="Initial Stock"
                                  placeholder="0"
                                  classNames="white"
                                  value={
                                    storeProperties[storeIndex]
                                      ?.stock_quantity || ""
                                  }
                                  errorMessage={
                                    errors?.storeProperties?.[storeIndex]
                                      ?.stock_quantity
                                  }
                                  onKeyUp={() =>
                                    errors?.storeProperties?.[storeIndex]
                                      ?.stock_quantity &&
                                    trigger(
                                      `storeProperties.${storeIndex}.stock_quantity`,
                                    )
                                  }
                                  onChange={({ target: { value } }) => {
                                    const newStoreProperties = [
                                      ...storeProperties,
                                    ];
                                    newStoreProperties[
                                      storeIndex
                                    ].stock_quantity =
                                      value === "" ? undefined : value;
                                    setStoreProperties(newStoreProperties);
                                    setIsStorePropertiesUpdated(true);
                                  }}
                                />
                              ) : null}

                              {/* minimum stock quantity */}
                              <primaryComponents.InputFieldV2
                                name={`storeProperties.${storeIndex}.minimum_stock_quantity`}
                                control={control}
                                type="number"
                                step={0.01}
                                min={0}
                                label="Stock Level Alert"
                                placeholder="0"
                                classNames="white"
                                value={
                                  storeProperties[storeIndex]
                                    ?.minimum_stock_quantity || ""
                                }
                                errorMessage={
                                  errors?.storeProperties?.[storeIndex]
                                    ?.minimum_stock_quantity
                                }
                                onKeyUp={() =>
                                  errors?.storeProperties?.[storeIndex]
                                    ?.minimum_stock_quantity &&
                                  trigger(
                                    `storeProperties.${storeIndex}.minimum_stock_quantity`,
                                  )
                                }
                                onChange={({ target: { value } }) => {
                                  const newStoreProperties = [
                                    ...storeProperties,
                                  ];
                                  newStoreProperties[
                                    storeIndex
                                  ].minimum_stock_quantity =
                                    value === "" ? undefined : value;
                                  setStoreProperties(newStoreProperties);
                                  setIsStorePropertiesUpdated(true);
                                }}
                              />

                              {/* Date input*/}
                              {storeProperties[storeIndex]?.stock_quantity >
                                0 || isEditMode ? (
                                <primaryComponents.DateInputV2
                                  name={`storeProperties.${storeIndex}.expiry_date`}
                                  control={control}
                                  label="Expiry Date"
                                  placeholder="Expiry date"
                                  classNames="white"
                                  value={
                                    storeProperties?.[storeIndex]
                                      ?.expiry_date || ""
                                  }
                                  errorMessage={
                                    errors?.storeProperties?.[storeIndex]
                                      ?.expiry_date
                                  }
                                  onKeyUp={() =>
                                    errors?.storeProperties?.[storeIndex]
                                      ?.expiry_date &&
                                    trigger(
                                      `storeProperties.${storeIndex}.expiry_date`,
                                    )
                                  }
                                  onChange={({ target: { value } }) => {
                                    const newStoreProperties = [
                                      ...storeProperties,
                                    ];
                                    newStoreProperties[storeIndex].expiry_date =
                                      value === "" ? undefined : value;
                                    setStoreProperties(newStoreProperties);
                                    setIsStorePropertiesUpdated(true);
                                  }}
                                />
                              ) : null}

                              {item?.pricings?.map(
                                (pricingProperty, pricingIndex) => {
                                  const pricingDetailsProperty =
                                    pricingTypes?.find(
                                      (pricingItem) =>
                                        pricingItem?.id ===
                                        pricingProperty?.pricing_type_id,
                                    );

                                  return (
                                    <div
                                      className={`${styles.priceSection} ${globalStyles.borderTopPurple} ${globalStyles.borderTop} mt-0 pt-3`}
                                      style={{ minWidth: "100%" }}
                                      key={pricingProperty?.uuid}
                                    >
                                      {/* pricing type */}
                                      <secondaryComponents.LoaderHelper
                                        isLoading={isLoadingPricingTypes}
                                        classNames={"mt-2"}
                                      >
                                        <div style={{ maxWidth: "180px" }}>
                                          <label htmlFor="">
                                            <span className="text-danger">
                                              *
                                            </span>

                                            <span>
                                              Price Type
                                              {item?.pricings?.length ===
                                              pricingIndex + 1 ? (
                                                <>
                                                  {" ( "}
                                                  <span
                                                    className="purple--text"
                                                    style={{
                                                      textDecoration:
                                                        "underline",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      resetModalViews(
                                                        "isCreatePricing",
                                                      )
                                                    }
                                                  >
                                                    Create new
                                                  </span>
                                                  {" )"}
                                                </>
                                              ) : null}
                                            </span>
                                          </label>
                                          <primaryComponents.SelectFieldV2
                                            name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.pricing_type_id`}
                                            control={control}
                                            giveControl={false}
                                            value={{
                                              label:
                                                pricingDetailsProperty?.label,
                                              value: pricingDetailsProperty?.id,
                                            }}
                                            options={pricingTypes}
                                            nameKey="label"
                                            idKey="id"
                                            onChange={(value) => {
                                              const newStoreProperties = [
                                                ...storeProperties,
                                              ];
                                              newStoreProperties[
                                                storeIndex
                                              ].pricings[
                                                pricingIndex
                                              ].pricing_type_id = value;
                                              setStoreProperties(
                                                newStoreProperties,
                                              );
                                              setIsStorePropertiesUpdated(true);
                                            }}
                                            errorMessage={
                                              errors?.storeProperties?.[
                                                storeIndex
                                              ]?.pricings?.[pricingIndex]
                                                ?.pricing_type_id
                                            }
                                            isDisabled={
                                              (storeIndex > 0 &&
                                                rootFormDetails?.use_same_pricing) ||
                                              pricingIndex === 0
                                            }
                                            isSearchable={false}
                                          />
                                        </div>
                                      </secondaryComponents.LoaderHelper>

                                      {/* selling price */}
                                      <primaryComponents.InputFieldV2
                                        name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.price`}
                                        control={control}
                                        type="number"
                                        step={0.01}
                                        label={`Selling Price (${
                                          storeList.find(
                                            (store) =>
                                              store?.id === item?.store_id,
                                          )?.currency_symbol
                                        })`}
                                        placeholder="0"
                                        classNames="white"
                                        isRequired
                                        isDisabled={
                                          storeIndex > 0 &&
                                          rootFormDetails?.use_same_pricing
                                        }
                                        value={pricingProperty?.price}
                                        errorMessage={
                                          errors?.storeProperties?.[storeIndex]
                                            ?.pricings?.[pricingIndex]?.price
                                        }
                                        onKeyUp={() =>
                                          errors?.storeProperties?.[storeIndex]
                                            ?.pricings?.[pricingIndex]?.price &&
                                          trigger(
                                            `storeProperties.${storeIndex}.pricings.${pricingIndex}.price`,
                                          )
                                        }
                                        onChange={({ target: { value } }) => {
                                          const newStoreProperties = [
                                            ...storeProperties,
                                          ];
                                          newStoreProperties[
                                            storeIndex
                                          ].pricings[pricingIndex].price =
                                            value;
                                          setStoreProperties(
                                            newStoreProperties,
                                          );
                                          setIsStorePropertiesUpdated(true);
                                        }}
                                      />

                                      {/* discount */}
                                      <primaryComponents.PercentField
                                        label="Selling price discount"
                                        name={`storeProperties.${storeIndex}.pricings.${pricingIndex}.discount_rate`}
                                        control={control}
                                        value={pricingProperty?.discount_rate}
                                        isDisabled={
                                          storeIndex > 0 &&
                                          rootFormDetails?.use_same_pricing
                                        }
                                        onChange={({ percentValue }) => {
                                          const newStoreProperties = [
                                            ...storeProperties,
                                          ];
                                          newStoreProperties[
                                            storeIndex
                                          ].pricings[
                                            pricingIndex
                                          ].discount_rate = percentValue;
                                          setStoreProperties(
                                            newStoreProperties,
                                          );
                                          setIsStorePropertiesUpdated(true);
                                        }}
                                        dividedValue={
                                          pricingProperty?.price === ""
                                            ? 0
                                            : pricingProperty?.price
                                        }
                                      />

                                      {/* discounted selling price */}
                                      <primaryComponents.InputField
                                        type="number"
                                        classNames="white"
                                        label={`Discounted selling price (${
                                          storeList.find(
                                            (store) =>
                                              store?.id === item?.store_id,
                                          )?.currency_symbol
                                        })`}
                                        isDisabled={true}
                                        value={(() => {
                                          let sellingPrice =
                                            pricingProperty?.price === ""
                                              ? 0
                                              : pricingProperty?.price;
                                          let discountPercent =
                                            pricingProperty?.discount_rate ===
                                            ""
                                              ? 0
                                              : pricingProperty?.discount_rate;
                                          let discount_rate =
                                            (sellingPrice * discountPercent) /
                                            100;

                                          let discountedSellingPrice =
                                            sellingPrice - discount_rate;

                                          return discountedSellingPrice;
                                        })()}
                                      />

                                      {/* profit */}
                                      <primaryComponents.InputField
                                        classNames="white"
                                        label={`Profit (${
                                          storeList.find(
                                            (store) =>
                                              store?.id === item?.store_id,
                                          )?.currency_symbol
                                        })`}
                                        isDisabled={true}
                                        value={(
                                          pricingProperty?.price -
                                          (pricingProperty?.price *
                                            pricingProperty?.discount_rate) /
                                            100 -
                                          item?.cost_price
                                        ).toFixed(2)}
                                      />

                                      {pricingIndex > 0 ? (
                                        <div
                                          className="d-flex align-items-center my-auto"
                                          style={{
                                            minWidth: "fit-content",
                                            maxWidth: "fit-content",
                                          }}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn--outline-red"
                                            style={{
                                              height: "30px",
                                              width: "28px",
                                              padding: "0",
                                              borderRadius: "3px",
                                            }}
                                            onClick={() =>
                                              removePricingForStoreProperty(
                                                storeIndex,
                                                pricingIndex,
                                              )
                                            }
                                          >
                                            <img src={Icon.deleteIcon} alt="" />
                                          </button>
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                },
                              )}
                            </div>

                            {/* use across */}

                            <div className={styles.useAcrossSection}>
                              <a
                                className="purple--text"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  addNewPricingForStoreProperty(storeIndex)
                                }
                              >
                                + Add another price option
                              </a>
                              {storeIndex === 0 ? (
                                <label
                                  className="d-flex align-items-center"
                                  style={{ cursor: "pointer" }}
                                  htmlFor="useSamePricing"
                                >
                                  <primaryComponents.Checkbox
                                    name="useSamePricing"
                                    id="useSamePricing"
                                    isChecked={
                                      rootFormDetails?.use_same_pricing
                                    }
                                    onChange={(value) => {
                                      setRootFormDetails((prevState) => ({
                                        ...prevState,
                                        use_same_pricing: value,
                                      }));
                                      if (value) {
                                        if (!rootFormDetails.has_variant)
                                          setIsStorePropertiesUpdated(true);
                                      }
                                    }}
                                  />
                                  <span className="ml-2 mt-1">
                                    Use this pricing properties across other
                                    stores
                                  </span>
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <h5 className="text-center my-4">
                      Please select stores to proceed with pricing
                    </h5>
                  )}
                </>
              )}
            </div>
          </form>
        </motion.div>
        {/* button section */}
        <div className={styles.buttonSection}>
          <primaryComponents.Button
            classNames={`btn btn--primary ${styles.createBtn}`}
            type="submit"
            form="productForm"
            isDisabled={isUpdatingProduct}
            isLoading={isUpdatingProduct}
            loadingText={isEditMode ? "Updating..." : "Creating..."}
          >
            {isEditMode ? "Update Product" : "Create Product"}
          </primaryComponents.Button>
        </div>
      </div>

      {/* pricing form */}
      <secondaryComponents.Modal
        isActive={modalViews?.isCreatePricing}
        closeModal={() => resetModalViews("isCreatePricing", false)}
        width={400}
      >
        <secondaryComponents.CreatePricingType
          closeWidget={() => resetModalViews("isCreatePricing", false)}
        />
      </secondaryComponents.Modal>

      {/* unit form */}
      <secondaryComponents.Modal
        isActive={modalViews?.isCreateUnit}
        closeModal={() => resetModalViews("isCreateUnit", false)}
        width={400}
      >
        <secondaryComponents.ProductUnitForm
          closeWidget={() => resetModalViews("isCreateUnit", false)}
        />
      </secondaryComponents.Modal>
    </>
  );
};
export default SingleProductForm;
