import axios from "axios";
import store from "../store";
import { setAccessToken, setRequestingTokens } from "../store/modules/profile";
import { setApiErrorMessage } from "../store/modules/apiErrorHandler";
import {
  logoutHandler,
  getUrlQuerysection,
  get422errorMessage,
} from "../helpers";

// base url
// eslint-disable-next-line no-undef
let baseUrlBusiness = process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL;

const httpClient = axios.create({
  baseURL: baseUrlBusiness,
});

// setting authorization header
const httpRequest = (config) => {
  const state = store.getState();
  const { accessToken } = state.profileDetailsReducer;
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
    config.headers["X-CHANNEL"] = "web";
  }
  return config;
};

// Response interceptors
const onFulfilled = (response) => {
  return Promise.resolve(response);
};

const onRejected = async (error) => {
  if (error?.response) {
    if (error?.response?.status) {
      const originalRequest = error.config;
      if (error?.response?.status === 401 && !originalRequest._retry) {
        const state = store.getState();
        const { requestingTokens } = state.profileDetailsReducer;
        if (requestingTokens) return httpClient(originalRequest);
        if (localStorage.getItem("refreshToken")) {
          originalRequest._retry = true;
          store.dispatch(setRequestingTokens(true));
          // eslint-disable-next-line no-undef
          return axios
            .post(
              `${process.env.REACT_APP_LUMI_MERCHANT_APP_BASE_URL}/tokens/refresh/`,
              {
                refresh: localStorage.getItem("refreshToken"),
              },
            )
            .then((res) => {
              store.dispatch(setRequestingTokens(false));
              localStorage.setItem("refreshToken", res.data.refresh);
              store.dispatch(setAccessToken(res.data.access));
              // Change Authorization header
              httpClient.defaults.headers.common["Authorization"] =
                `Bearer ${res.data.access}`;
              // return originalRequest object with Axios.
              return httpClient(originalRequest);
            })
            .catch(() => {
              store.dispatch(setRequestingTokens(false));
              localStorage.setItem(
                "nextRoute",
                `${window.location.pathname}${getUrlQuerysection()}`,
              );
              logoutHandler();
            });
        }
        localStorage.setItem(
          "nextRoute",
          `${window.location.pathname}${getUrlQuerysection()}`,
        );

        logoutHandler();
      }

      if (error?.response?.status === 403) {
        store.dispatch(
          setApiErrorMessage({
            message: error?.response?.data?.detail,
            isSubscriptionNotification: error?.response?.data?.error_code
              ? true
              : false,
          }),
        );
      }

      if (error?.response?.status === 400) {
        if (error?.response?.config?.url.endsWith("/view-storefront-item/")) {
          window.location.replace(
            `${window.location.origin}/storefront-not-found`,
          );
        } else {
          store.dispatch(
            setApiErrorMessage({ message: error?.response?.data?.detail }),
          );
        }
      }

      if (error?.response?.status === 422) {
        const errors = error?.response?.data?.errors;
        if (Object.keys(errors)?.length > 0) {
          const errorMessage = get422errorMessage(errors);
          store.dispatch(setApiErrorMessage({ message: errorMessage }));
        } else {
          store.dispatch(
            setApiErrorMessage({ message: error?.response?.data?.detail }),
          );
        }
      }

      if (error?.response?.status === 500)
        store.dispatch(
          setApiErrorMessage({
            message: "Something went wrong, please try again",
          }),
        );

      if (error?.response?.status === 503)
        store.dispatch(setApiErrorMessage({ message: "Service unavailable" }));

      if (error?.response?.status === 404)
        store.dispatch(
          setApiErrorMessage({ message: "This resource was not found" }),
        );
    }
  }

  if (!navigator.onLine) {
    store.dispatch(
      setApiErrorMessage({
        message:
          "No internet connection, please check your internet connectivity",
      }),
    );
  }

  return Promise.reject(error);
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(httpRequest);
/** Adding the response interceptors */
httpClient.interceptors.response.use(onFulfilled, onRejected);

export default httpClient;
