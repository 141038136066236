import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    store: yup
      .array()
      .required("Store is required")
      .min(1, "Store is required"),
  })
  .required();

const Validation = (store) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      store,
    },
  });
};

export default Validation;
