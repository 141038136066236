import React, { useContext, useEffect } from "react";
import styles from "./styles.module.scss";
import Icon from "../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import { Validation } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { usePaymentSetup } from "./usePaymentSetup";
import { getPlatform, truncateString } from "../../../../../../../helpers";
import {
  ToastContext,
  platformHandler,
} from "../../../../../../../hooks/context";
import CopyToClipboard from "react-copy-to-clipboard";

const PaymentSetup = () => {
  const triggerToast = useContext(ToastContext);
  const { platform } = useContext(platformHandler);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    isLoading,
    isLoadingPaymentGateway,
    paymentGateway,
    dataCreatePaymentGateway,
    isLoadingEditPaymentGateway,
    paystackSecretKey,
    stripeSecretKey,
    selectPayment,
    showYouTubeModal,
    setShowYouTubeModal,
    setPaystackSecretKey,
    setStripeSecretKey,
    setSelectPayment,
    handleSave,
  } = usePaymentSetup();

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = Validation();

  useEffect(() => {
    if (paymentGateway) {
      setValue("key", paymentGateway.secret_key);
    }
  }, [paymentGateway]);

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isLoadingPaymentGateway}
      classNames="mt-4"
    >
      <div className={styles.mainContainer}>
        {location.pathname.includes("payment-method") && (
          <div className={styles.btnSaveBtnContainer}>
            <div className={styles.backBtnContainer}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() =>
                  navigate(getPlatform(platform) + "/inventory/storefront", {
                    state: { menuTab: "Payment Method Settings" },
                  })
                }
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
            </div>
          </div>
        )}
        <div className={styles.paymentMainContainer}>
          {location.pathname.includes("payment-method") && (
            <div className={styles.paymentHead}>
              <div>
                <h4>Payment Method</h4>
                <p>
                  Select Your Preferred Payment Method{" "}
                  {location.pathname.includes("payment-method")
                    ? ""
                    : "For This Menu"}
                </p>
              </div>
              <div className={styles.saveBtnContainer}>
                <primaryComponents.Button
                  classNames="btn btn--primary mt-auto"
                  onClick={handleSubmit(handleSave)}
                  loadingText={"Saving..."}
                  isDisabled={
                    isLoading || isLoadingEditPaymentGateway || paymentGateway
                      ? !isDirty
                      : false
                  }
                  isLoading={isLoading || isLoadingEditPaymentGateway}
                >
                  {paymentGateway ? (
                    <>
                      <img className="mr-2" src={Icon.saveIconAlt} alt="" />
                      <span>Save</span>
                    </>
                  ) : (
                    <>
                      <span>Generate Webhook Url</span>
                    </>
                  )}
                </primaryComponents.Button>
              </div>
            </div>
          )}

          <div className={styles.selectPaymentMethod}>
            <div
              className={styles.payment}
              onClick={() => setSelectPayment("Paystack")}
            >
              {selectPayment === "Paystack" ? (
                <div className={styles.radioSelect}>
                  <div className={styles.inside} />
                </div>
              ) : (
                <div className={styles.radioUnselect} />
              )}
              <p className={styles.paymentText}>Paystack</p>
            </div>
            <div
              className={styles.payment}
              onClick={() => setSelectPayment("Stripe")}
            >
              {selectPayment === "Stripe" ? (
                <div className={styles.radioSelect}>
                  <div className={styles.inside} />
                </div>
              ) : (
                <div className={styles.radioUnselect} />
              )}
              <p className={styles.paymentText}>Stripe</p>
            </div>
          </div>
          <div className={styles.paymentSectionContainer}>
            <div className={styles.keyMainContainer}>
              <div className={styles.keyContainer}>
                <div className={styles.secretHeading}>
                  <p className={styles.title}>
                    Payment Secret Key <span>(Required)</span>
                  </p>
                  <p className={styles.description}>
                    Input your{" "}
                    {selectPayment === "Paystack" ? "Paystack" : "Stripe"}{" "}
                    Secret Key below to set up your payment processes with one
                    single click.
                  </p>
                </div>
                <div className={styles.inputContainer}>
                  <primaryComponents.InputFieldV2
                    control={control}
                    name="key"
                    value={
                      selectPayment === "Paystack"
                        ? paystackSecretKey
                        : stripeSecretKey
                    }
                    classNames="white"
                    label={""}
                    placeholder={`Enter your ${
                      selectPayment === "Paystack" ? "paystack" : "stripe"
                    } secret key here`}
                    onChange={({ target: { value } }) => {
                      selectPayment === "Paystack"
                        ? setPaystackSecretKey(value)
                        : setStripeSecretKey(value);
                    }}
                    errorMessage={errors.key}
                  />
                </div>
              </div>
              <div className={styles.keyContainer}>
                <div className={styles.secretHeading}>
                  <p className={styles.title}>Your Unique Webhook URL</p>
                  <p className={styles.description}>
                    Log into your{" "}
                    {selectPayment === "Paystack" ? "Paystack" : "Stripe"}{" "}
                    account and go to Settings{">"}API Keys & Webhooks then
                    paste in your unique webhook url.{" "}
                    <a
                      href="https://dashboard.paystack.com/#/settings/developers"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Learn more</span>
                    </a>
                  </p>
                </div>
                {(paymentGateway || dataCreatePaymentGateway) && (
                  <div className={styles.webhookContainer}>
                    <span>
                      {paymentGateway || dataCreatePaymentGateway
                        ? truncateString(
                            `${
                              paymentGateway?.payment_gateway_webhook_url ||
                              dataCreatePaymentGateway?.data?.data
                                ?.payment_gateway_webhook_url
                            }`,
                            30,
                          )
                        : "http://webhookurl.com"}
                    </span>

                    <div>
                      <CopyToClipboard
                        text={
                          paymentGateway || dataCreatePaymentGateway
                            ? `${
                                paymentGateway?.payment_gateway_webhook_url ||
                                dataCreatePaymentGateway?.data?.data
                                  ?.payment_gateway_webhook_url
                              }`
                            : ""
                        }
                        onCopy={() => triggerToast("Copied", "success")}
                      >
                        <div className={styles.copyButton}>
                          <img src={Icon.copy} alt="" />
                          <span>Copy URL</span>
                        </div>
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              </div>

              <div className={styles.saveBtnContainer}>
                <primaryComponents.Button
                  classNames="btn btn--primary mt-auto"
                  onClick={handleSubmit(handleSave)}
                  loadingText={"Saving..."}
                  isDisabled={
                    isLoading || isLoadingEditPaymentGateway || paymentGateway
                      ? !isDirty
                      : false
                  }
                  isLoading={isLoading || isLoadingEditPaymentGateway}
                >
                  {paymentGateway ? (
                    <>
                      <img className="mr-2" src={Icon.saveIconAlt} alt="" />
                      <span>Save</span>
                    </>
                  ) : (
                    <>
                      <span>Generate Webhook Url</span>
                    </>
                  )}
                </primaryComponents.Button>
              </div>
            </div>
            <div className={styles.videoContainer}>
              <div className={styles.keySetupContainer}>
                <p className={styles.setupTitle}>
                  How to find your secret key:
                </p>
                {selectPayment === "Paystack" ? (
                  <div className={styles.keySetupSteps}>
                    <div className={styles.step}>
                      <img src={Icon.keysetupone} alt="" />
                      <p>
                        Create account on{" "}
                        <a
                          href="http://paystack.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>https://paystack.com</span>
                        </a>
                      </p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetuptwo} alt="" />
                      <p>Login to your Paystack dashboard</p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetupthree} alt="" />
                      <p>
                        Goto Settings under{" "}
                        <a
                          href="https://dashboard.paystack.com/#/settings/developers"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>API Keys & Webhooks</span>
                        </a>{" "}
                        section
                      </p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetupfour} alt="" />
                      <p>Paste your secret key in the above field</p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetupfive} alt="" />
                      <p>
                        Generate and copy your webhook url and paste it on
                        Paystack platform
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className={styles.keySetupSteps}>
                    <div className={styles.step}>
                      <img src={Icon.keysetupone} alt="" />
                      <p>
                        Create account on{" "}
                        <a
                          href="http://stripe.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>https://stripe.com</span>
                        </a>
                      </p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetuptwo} alt="" />
                      <p>Login to your Stripe dashboard</p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetupthree} alt="" />
                      <p>
                        Goto Developer page and copy your secret key on the API
                        Key section
                      </p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetupfour} alt="" />
                      <p>Paste your secret key in the above key</p>
                    </div>
                    <div className={styles.step}>
                      <img src={Icon.keysetupfive} alt="" />
                      <p>
                        Generate and copy your webhook url and paste it on
                        Stripe platform and select the following event
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.divider} />
              {selectPayment === "Paystack" ? (
                <div className={styles.heading}>
                  <p className={styles.title}>
                    Don’t Have a Paystack Secret Key?
                  </p>
                  <p className={styles.paystackLink}>
                    Visit{" "}
                    <a
                      href="http://paystack.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Paystack.com</span>
                    </a>
                    <img src={Icon.link} alt="" />
                  </p>
                </div>
              ) : (
                <div className={styles.heading}>
                  <p className={styles.title}>
                    Don’t Have a Stripe Secret Key?
                  </p>
                  <p className={styles.paystackLink}>
                    Visit{" "}
                    <a
                      href="http://stripe.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Stripe.com</span>
                    </a>
                    <img src={Icon.link} alt="" />
                  </p>
                </div>
              )}

              <span className={styles.or}>OR</span>
              <p
                onClick={() => setShowYouTubeModal(true)}
                className={styles.videoTopText}
              >
                Watch this short explainer video below.
              </p>
              <div
                onClick={() => setShowYouTubeModal(true)}
                className={styles.video}
              >
                <iframe
                  width="100%"
                  height="100%"
                  src={
                    selectPayment === "Paystack"
                      ? "https://www.youtube.com/embed/SwiclYYKHBQ"
                      : "https://www.youtube.com/embed/4fogq7gsBmE"
                  }
                  title={
                    selectPayment === "Paystack"
                      ? "Lumi Merchant Paystack Setup"
                      : "Lumi Merchant Stripe Setup"
                  }
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
                <div
                  className={styles.overlay}
                  onClick={() => setShowYouTubeModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <secondaryComponents.Modal
        width={"850px"}
        isActive={showYouTubeModal}
        closeModal={() => setShowYouTubeModal(false)}
      >
        <div className="px-2">
          <div className={"d-flex justify-content-end align-items-center mb-3"}>
            <primaryComponents.Button
              classNames="btn btn--outline smallBtn"
              type="button"
              onClick={() => setShowYouTubeModal(false)}
            >
              Close
            </primaryComponents.Button>
          </div>
          <iframe
            width="100%"
            height="500px"
            src={
              selectPayment === "Paystack"
                ? "https://www.youtube.com/embed/SwiclYYKHBQ"
                : "https://www.youtube.com/embed/4fogq7gsBmE"
            }
            title={
              selectPayment === "Paystack"
                ? "Lumi Merchant Paystack Setup"
                : "Lumi Merchant Stripe Setup"
            }
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </secondaryComponents.Modal>
    </secondaryComponents.LoaderHelper>
  );
};

export default PaymentSetup;
