import styles from "./PasswordCreation.module.scss";
import Icon from "../../../../assets/icons";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import PasswordFormValidation from "./Validation.js";
import { Controller } from "react-hook-form";

const PasswordCreationForm = ({ SignupLogic }) => {
  const {
    handlePreviousStep,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    agreeToTerms,
    setAgreeToTerms,
    createAccount,
    isCreatingAccount,
  } = SignupLogic;
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
  } = PasswordFormValidation(password, confirmPassword);

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className={styles.passwordForm}
      onSubmit={handleSubmit(createAccount)}
    >
      <div>
        <h3 className="mb-0 font-weight-normal">Create your password</h3>
        <p>Kindly provide the following details below.</p>
      </div>
      <div className={styles.passwordForm__body}>
        <Controller
          name="password"
          control={control}
          defaultValue={password}
          render={({ field: { onChange } }) => (
            <primaryComponents.PasswordInputField
              name="password"
              placeholder="Enter your chosen password"
              isRequired={true}
              label="Create Password"
              errorMessage={errors.password}
              onChange={({ target: { value } }) => {
                onChange(value);
                setPassword(value);
              }}
              onKeyUp={() =>
                errors.password !== undefined && trigger("password")
              }
              register={register}
            />
          )}
        />

        <Controller
          name="confirmPassword"
          control={control}
          defaultValue={confirmPassword}
          render={({ field: { onChange } }) => (
            <primaryComponents.PasswordInputField
              name="confirmPassword"
              placeholder="Confirm your password"
              isRequired={true}
              label="Confirm Password"
              errorMessage={errors.confirmPassword}
              onChange={({ target: { value } }) => {
                onChange(value);
                setConfirmPassword(value);
              }}
              onKeyUp={() =>
                errors.confirmPassword !== undefined &&
                trigger("confirmPassword")
              }
              register={register}
            />
          )}
        />

        <div className="d-flex mt-3">
          <primaryComponents.Checkbox
            name="agreeToTerms"
            id="agreeToTerms"
            isChecked={agreeToTerms}
            onChange={(value) => setAgreeToTerms(value)}
          />
          <label
            htmlFor="agreeToTerms"
            style={{ fontSize: "16px", marginLeft: "10px" }}
          >
            I agree to the{" "}
            <a
              className="purple--text"
              href="https://www.lumibusiness.io/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>
          </label>
        </div>
        <div className="mt-4 d-flex justify-content-between">
          {/* back button */}
          <primaryComponents.Button
            type="button"
            onClick={handlePreviousStep}
            classNames={"btn btn--outline"}
          >
            <img src={Icon.arrow_left} alt="" className="mr-2" width="15px" />
            <span>Previous</span>
          </primaryComponents.Button>
          {/* next button */}
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            type="submit"
            isDisabled={!agreeToTerms || isCreatingAccount}
            isLoading={isCreatingAccount}
            loadingText="Creating..."
          >
            <span>Finish</span>
            <img src={Icon.arrow_right} alt="" width="15px" className="ml-2" />
          </primaryComponents.Button>
        </div>
      </div>
    </motion.form>
  );
};
export default PasswordCreationForm;
