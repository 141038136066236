import { useSearchParams } from "react-router-dom";
import { useUrlSearch } from "../../../../../hooks/useUrlSearch";
import { useGetStoreWallets } from "../../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../../helpers";
import { useState } from "react";

const useAllStoreWallets = () => {
  const [search, setSearch] = useSearchParams();
  const { gotoPage } = useUrlSearch();
  const queries = getUrlQuerysection();
  const [modals, setModals] = useState({
    isCreateWallet: false,
  });
  const { data, isLoading: isLoadingStoreWallets } = useGetStoreWallets({
    queries,
  });

  const handleSearchWallets = (value) => {
    if (value?.length > 0) search.set("query", value);
    else search.delete("query");
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const resetModals = (key, value = true) => {
    setModals({
      isCreateWallet: false,
      [key]: value,
    });
  };

  return {
    isLoadingStoreWallets,
    search,
    storeWallets: data?.data || [],
    metaDetails: data?.meta || {},
    modals,
    resetModals,
    handleSearchWallets,
    gotoPage,
  };
};

export default useAllStoreWallets;
