import { useState, useContext } from "react";
import { createGiftCard } from "../../../services/giftcards";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { useGetCampaigns } from "../../../hooks/api/queries/useLoyalty";
import { pathConstants } from "../../../routes/pathContants";
import { useQueryClient } from "react-query";

export const useGiftcardFormLogic = () => {
  const [giftcardName, setGiftcardName] = useState("");
  const [giftcardAmount, setGiftcardAmount] = useState("");
  const [giftcardPoints, setGiftcardPoints] = useState(0);
  const [giftcardValidity, setGiftcardValidity] = useState("");
  // const [showNairaEquivalent, setShowNairaEquivalent] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { data: campaigns } = useGetCampaigns({ queries: "" });
  const queryClient = useQueryClient();

  const handleCreateGiftCard = () => {
    if (campaigns.data?.length <= 0)
      return triggerToast("Please create a campaign to continue.");
    setIsSubmitting(true);
    setRequestLoaderProgress(30);
    createGiftCard({
      name: giftcardName,
      monetary_value: giftcardAmount,
      redemption_period: giftcardValidity,
      // show_naira_value: showNairaEquivalent,
    })
      .then(() => {
        queryClient.invalidateQueries(["getGiftCardList"]);
        triggerToast("Giftcard created successfully", "success");
        navigate(pathConstants.LOYALTY_MANAGE_GIFTCARDS);
      })
      .catch(() => {})
      .finally(() => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    giftcardPoints,
    giftcardAmount,
    giftcardName,
    giftcardValidity,
    isSubmitting,
    // showNairaEquivalent,
    // setShowNairaEquivalent,
    setGiftcardAmount,
    setGiftcardName,
    setGiftcardValidity,
    setGiftcardPoints,
    handleCreateGiftCard,
  };
};
