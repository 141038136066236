import { useState } from "react";
import primaryComponents from "../../primaryComponents";

const DeliveryFee = ({
  setDeliveryFee,
  deliveryFee,
  currency_symbol,
  closeModal,
}) => {
  const [deliveryVal, setDeliveryVal] = useState(deliveryFee);
  return (
    <div
      className="p-3"
      style={{ display: "flex", flexDirection: "column", gap: "25px" }}
    >
      <h4>Apply Delivery</h4>

      <div
        style={{
          border: "1px solid #ddd",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <primaryComponents.InputField
          label={"Amount"}
          classNames="white"
          placeholder={`Enter delivery fee e.g. ${currency_symbol}1000`}
          isRequired={false}
          value={deliveryVal}
          type="number"
          min="0"
          name="deliveryFee"
          floatError={false}
          onChange={({ target: { value } }) => {
            setDeliveryVal(value);
          }}
        />
      </div>

      <primaryComponents.Button
        classNames={"btn btn--primary cover"}
        onClick={() => {
          setDeliveryFee(deliveryVal);
          closeModal();
        }}
      >
        Apply
      </primaryComponents.Button>
    </div>
  );
};
export default DeliveryFee;
