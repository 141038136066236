import { useContext } from "react";
import { ToastContext } from "../../../../../hooks/context";
import { useGetSubAccounts } from "../../../../../hooks/api/queries/useBusiness";

const SubAccountsLogic = () => {
  const triggerToast = useContext(ToastContext);
  const { isLoading: isFetchingAccounts, data } = useGetSubAccounts();

  return {
    isFetchingAccounts,
    subAccountsList: data?.data || [],
    triggerToast,
  };
};
export default SubAccountsLogic;
