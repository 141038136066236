import React from "react";

export const MarketsIcon = ({ width = "28", height = "29" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1029 3.5H3.78922C3.35334 3.5 3 3.85334 3 4.28922V10.6029C3 11.0388 3.35334 11.3922 3.78922 11.3922H10.1029C10.5388 11.3922 10.8922 11.0388 10.8922 10.6029V4.28922C10.8922 3.85334 10.5388 3.5 10.1029 3.5Z"
        stroke="#7647EE"
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5343 3.72549H18.2206C17.7847 3.72549 17.4314 4.07884 17.4314 4.51471V10.8284C17.4314 11.2643 17.7847 11.6177 18.2206 11.6177H24.5343C24.9702 11.6177 25.3236 11.2643 25.3236 10.8284V4.51471C25.3236 4.07884 24.9702 3.72549 24.5343 3.72549Z"
        stroke="#7647EE"
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3285 17.9314H4.01474C3.57887 17.9314 3.22552 18.2847 3.22552 18.7206V25.0343C3.22552 25.4702 3.57887 25.8235 4.01474 25.8235H10.3285C10.7643 25.8235 11.1177 25.4702 11.1177 25.0343V18.7206C11.1177 18.2847 10.7643 17.9314 10.3285 17.9314Z"
        stroke="#7647EE"
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 21.5588H25.1176M21.0588 17.5V25.6176V17.5Z"
        stroke="#7647EE"
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
