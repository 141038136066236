import React from "react";
import styles from "../../MenuPage.module.scss";
import { useRef } from "react";
import MainsCard from "../MainsCard";

const CategorySection = ({
  index,
  categoryItem,
  menuObjectDetail,
  menuSearchText,
}) => {
  const categoryRef = useRef(null);
  const categoryName = categoryItem?.category?.name
    .toLowerCase()
    .split(" ")
    .map((word) =>
      word.length === 2 ? word : word.charAt(0).toUpperCase() + word.slice(1),
    )
    .join(" ");

  const secondaryColor = menuObjectDetail?.secondary_colour
    ? menuObjectDetail?.secondary_colour
    : index % 2
      ? "#f3f1fc"
      : "#fef7eb";

  return (
    <div
      ref={categoryRef}
      style={{
        marginTop: index > 0 ? 24 : 32,
      }}
      className={styles.mainsContainer}
    >
      <h4>{categoryName}</h4>
      <div
        className={styles.mainsCard}
        style={{
          // background: index % 2 ? "#f3f1fc" : "#fef7eb",
          background: secondaryColor,
        }}
      >
        {categoryItem?.storefront_items
          .filter((prod) =>
            prod?.name.toLowerCase().includes(menuSearchText.toLowerCase()),
          )
          .map((item, index) => (
            <MainsCard
              key={item.id}
              productIndex={index}
              productItem={item}
              store={menuObjectDetail?.store}
              menuObjectDetail={menuObjectDetail}
            />
          ))}
      </div>
    </div>
  );
};

export default CategorySection;
