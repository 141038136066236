import styles from "./CustomersView.module.scss";
import secondaryComponents from "../../../components/secondaryComponents";
import { useParams } from "react-router-dom";

const CustomersView = () => {
  const params = useParams();

  return (
    <div className={styles.customersView}>
      <secondaryComponents.SectionWidget store_id={params?.store_id} />
    </div>
  );
};
export default CustomersView;
