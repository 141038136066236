import { useTable } from "react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { addTransferTag, removeTransferTag } from "../../../services/transfers";
import { addTag } from "../../../services/tags";
import { useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { formatCurrency } from "../../../helpers";
import { useGetTagList } from "../../../hooks/api/queries/useInventory";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";
import primaryComponents from "../../primaryComponents";
import { useQueryClient } from "react-query";

const ApprovedTransfersTableLogic = ({ statusType, transfers }) => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { data: tags } = useGetTagList();
  const queryClient = useQueryClient();

  const handleAddTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    addTransferTag(id, tags)
      .then(() => {
        triggerToast("Tag added successfully", "success");
      })
      .finally(() => setRequestLoaderProgress(100));
  };

  const handleRemoveTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    removeTransferTag(id, tags)
      .then(() => {
        triggerToast("Tag removed successfully", "success");
      })
      .finally(() => setRequestLoaderProgress(100));
  };

  const handleCreateTag = ({ value, transfer_id }) => {
    setRequestLoaderProgress(40);
    addTag({ name: value })
      .then((response) => {
        queryClient.invalidateQueries(["getTagList"]);
        handleAddTag({ id: transfer_id, tags: [response.data?.data.id] });
      })
      .finally(() => setRequestLoaderProgress(100));
  };

  const handleTagChange = (data) => {
    const { transfer_id, datas } = data;
    if (datas.action === "select-option") {
      handleAddTag({ id: transfer_id, tags: [datas.option.value] });
    } else if (datas.action === "remove-value") {
      const { removedValue } = datas;
      handleRemoveTag({ id: transfer_id, tags: [removedValue.value] });
    } else if (datas.action === "clear") {
      const { removedValues } = datas;
      const tags = removedValues.map((removedValue) => removedValue.value);
      handleRemoveTag({ id: transfer_id, tags: tags });
    }
  };

  const columns = useMemo(() => {
    let headers = [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Beneficiary",
        accessor: "account_name",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={pathConstants.PAYMENT_TRANSFER_DETAILS({
              transferId: row.original["id"],
            })}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_number",
      },
      {
        Header: "Bank",
        accessor: "bank_name",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }),
      },
      {
        Header: "Initiated By",
        accessor: "initiator_name",
        Cell: ({ cell: { value } }) => (value ? value.split(" ")[0] : "N/A"),
      },
      {
        Header: "Approved By",
        accessor: "approver_name",
        Cell: ({ cell: { value } }) => (value ? value.split(" ")[0] : "N/A"),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            className={`${
              value === "successful" || value === "accepted"
                ? "success--squared"
                : value === "pending_approval" || value === "pending"
                  ? "pending--squared"
                  : "failed--text"
            }`}
          >
            {value === "accepted" ? "successful" : value}
          </span>
        ),
      },
    ];

    if (statusType === "accepted")
      headers.push({
        Header: "Tags",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <span style={{ maxWidth: "200px" }}>
            <primaryComponents.TagsMultiSelect
              options={tags}
              idKey="id"
              nameKey="name"
              value={row.original?.tag.map(({ id, name }) => ({
                value: id,
                label: name,
              }))}
              onChange={(value, datas) => {
                const transfer_id = row.original?.id;
                handleTagChange({
                  inputValue: value,
                  transfer_id,
                  datas,
                });
              }}
              onCreateOption={(value) => {
                handleCreateTag({
                  value,
                  transfer_id: row.original.id,
                });
              }}
            />
          </span>
        ),
      });
    return headers;
  }, []);

  const instance = useTable({ columns, data: transfers });
  return {
    instance,
    tags: tags || [],
    handleAddTag,
    handleTagChange,
    handleCreateTag,
  };
};

export default ApprovedTransfersTableLogic;
