import { useState, useContext } from "react";
import { loginUser } from "../../../services/auth";
import { RequestLoader } from "../../../hooks/context";
import { useNavigate, createSearchParams } from "react-router-dom";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";

const LoginLogic = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const login = () => {
    setIsLogging(true);
    setErrorMessage(null);
    setRequestLoaderProgress(getRandomIntInclusive(15, 25));

    loginUser({
      email: email,
      password: password,
      is_web: true,
    })
      .then((response) => {
        navigate({
          pathname: "/login/otp-verification",
          search: createSearchParams(
            cleanObject({
              session_id: response?.data?.data?.id,
              email: email,
              sent_to: response?.data?.data?.sent_to,
            }),
          ).toString(),
        });
      })
      .catch((error) => setErrorMessage(error?.response?.data?.detail))
      .finally(() => {
        setIsLogging(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    email,
    password,
    login,
    setPassword,
    setEmail,
    isLogging,
    errorMessage,
  };
};
export default LoginLogic;
