import "./TransactionCount.scss";
import {
  LineChart,
  XAxis,
  Line,
  CartesianGrid,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import TransactionCountLogic from "./TransactionCountLogic";
import { setToDateMonthYearInWords } from "../../../helpers";
import Images from "../../../assets/images";

const TransactionTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="revenue-chart-tooltip">
        <p style={{ fontSize: "12px", color: "#19AE57" }}>
          {payload?.[0]?.value}
        </p>
        <p style={{ fontSize: "12px", color: "#FD561F" }}>
          {payload?.[1]?.value}
        </p>
        <p className="dark--text" style={{ fontSize: "13px" }}>
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

const TransactionCount = ({ transactionCount, cashFlowData }) => {
  const {
    yAxisFormat,
    tickFormatter,
    showInfowLine,
    showOutfowLine,
    setShowInflowLine,
    setShowOutflowLine,
  } = TransactionCountLogic();

  return (
    <div className="transaction-count">
      <h4 className="font-weight-semibold" style={{ fontSize: "20px" }}>
        Transaction Count
      </h4>

      {transactionCount?.length > 0 ? (
        <>
          <div className="transaction-count__actions">
            <div
              className={`transaction-count__actions__item ${
                showInfowLine && "active"
              } inflow`}
              onClick={() => setShowInflowLine(!showInfowLine)}
            >
              <span className="icon inflow"></span>
              <span className="title inflow">
                Inflow - {cashFlowData.inflow_count}
              </span>
            </div>
            <div
              className={`transaction-count__actions__item ${
                showOutfowLine && "active"
              } cashflow`}
              onClick={() => setShowOutflowLine(!showOutfowLine)}
            >
              <span className="icon cashflow"></span>
              <span className="title cashflow">
                Outflow - {cashFlowData.outflow_count}
              </span>
            </div>
          </div>
          <ResponsiveContainer
            width="100%"
            aspect={2}
            className="transaction-count__chart"
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <LineChart width={500} height={300} data={transactionCount}>
              <CartesianGrid
                stroke="#E8E9EB"
                strokeDasharray="5 0"
                horizontal={true}
                vertical={false}
              />
              <YAxis
                tick={{ fontSize: "10px" }}
                tickLine={false}
                axisLine={false}
                tickFormatter={yAxisFormat}
              />
              <XAxis
                dataKey="date"
                tickLine={false}
                minTickGap={2}
                tick={{ fontSize: "10px" }}
                axisLine={false}
                tickFormatter={tickFormatter}
              />
              <Tooltip
                wrapperStyle={{ border: "none", outline: "none" }}
                content={<TransactionTooltip />}
              />
              <Line
                dataKey="inflow_count"
                stroke={`${showInfowLine ? "#19AE57" : "transparent"}`}
                dot={false}
              />
              <Line
                dataKey="outflow_count"
                stroke={`${showOutfowLine ? "#FD561F" : "transparent"}`}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </>
      ) : (
        <div className="mt-5 text-center">
          <img
            width={"150px"}
            src={Images.transaction_empty_state}
            alt="No transactions"
          />
          <p className="font-weight-semibold mt-3 dark--text">
            No data available
          </p>
        </div>
      )}
    </div>
  );
};

export default TransactionCount;
