import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getStockHistory } from "../../../../services/inventory";
import { useGetUserList } from "../../../../hooks/api/queries/useSettings";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

export const StockHistoryLogic = () => {
  const [search, setSearch] = useSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );
  const [initiator, setInitiator] = useState(
    search.get("initiator_id") || null,
  );
  const [storeType, setStoreType] = useState(
    search.get("order_source") || null,
  );
  const [isDownLoadWidget, setIsDownLoadWidget] = useState(false);
  const queries = getUrlQuerysection();

  const { data: userList, isLoading: isLoadingUsers } = useGetUserList();

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      search.set("store_id", storeList[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  const {
    isLoading: isFetchingStockHistory,
    data: stockHistory,
    refetch: handleFetchStockHistory,
  } = useQuery(["getStockHistory", queries], () =>
    getStockHistory(queries).then((res) => res?.data),
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const filterHistoryByStore = (storeId) => {
    search.delete("page");
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const filterByInitiator = (value) => {
    search.delete("page");
    if (value === "ALL_INITIATORS") search.delete("initiator_id");
    else search.set("initiator_id", value);
    setSearch(search, { replace: true });
  };

  const searchStockHistory = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterByStoreType = (value) => {
    search.delete("page");
    if (value === "ALL_STORES") search.delete("order_source");
    else search.set("order_source", value);
    setSearch(search, { replace: true });
  };

  return {
    search,
    isFetchingStockHistory,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    metaDetails: stockHistory?.meta || {},
    isDownLoadWidget,
    stockHistory: stockHistory?.data || [],
    initiator,
    storeType,
    userList: userList || [],
    isLoadingUsers,
    setInitiator,
    setStoreType,
    filterByInitiator,
    handleFetchStockHistory,
    setIsDownLoadWidget,
    filterHistoryByStore,
    filterByStoreType,
    setCurrentStore,
    gotoPage,
    searchStockHistory,
  };
};
