import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../../../hooks/context";
import { createSubAccount } from "../../../../../services/business";
import { useSelector, useDispatch } from "react-redux";
import { getRandomIntInclusive } from "../../../../../helpers";
import { useNavigate } from "react-router-dom";
import { getProfile } from "../../../../../services/settings";
import { setProfileDetails } from "../../../../../store/modules/profile";
import { pathConstants } from "../../../../../routes/pathContants";

const CreateSubAccountLogic = () => {
  const { email, phone_number } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [subAccountName, setSubAccountName] = useState("");
  const [subAccountDescription, setSubAccountDescription] = useState("");
  const [subAccountPhoneNumber, setSubAccountPhoneNumber] =
    useState(phone_number);
  const [subAccountEmail, setSubAccountEmail] = useState(email);
  const [bankProvider, setBankProvider] = useState("Select Bank provider");
  const [subAccountBankList, setSubAccountBankList] = useState([
    { value: "vbank", label: "VFD Microfinance Bank" },
    { value: "kuda", label: "Kuda Microfinance Bank" },
  ]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const submission = () => {
    setIsSubmitting(true);
    setRequestLoaderProgress(getRandomIntInclusive(0, 30));
    createSubAccount({
      sub_account_tag: subAccountName,
      account_description: subAccountDescription,
      sub_account_phone_number: `${subAccountPhoneNumber}`,
      sub_account_phone_code: `${business_phone_code}`,
      sub_account_email: subAccountEmail,
      provider: bankProvider,
    })
      .then(() => {
        setRequestLoaderProgress(65);
        triggerToast("Account creation successful", "success");
        navigate(pathConstants.PAYMENT_SUBACCOUNTS);
        getProfile()
          .then((response) => {
            dispatch(setProfileDetails(response.data.data));
            setRequestLoaderProgress(100);
          })
          .catch(() => {
            setRequestLoaderProgress(100);
          });
      })
      .catch(() => {
        setIsSubmitting(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    subAccountName,
    subAccountDescription,
    subAccountPhoneNumber,
    subAccountEmail,
    bankProvider,
    setBankProvider,
    setSubAccountName,
    setSubAccountDescription,
    setSubAccountPhoneNumber,
    setSubAccountEmail,
    submission,
    setSubAccountBankList,
    subAccountBankList,
    isSubmitting,
    verification_status,
  };
};

export default CreateSubAccountLogic;
