import "./TransactionsLayout.scss";
import { Outlet } from "react-router-dom";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";

const TransactionsLayout = () => {
  return (
    <div className={`${globalStyles?.pagePaddings} transactions-layout`}>
      <Outlet />
    </div>
  );
};

export default TransactionsLayout;
