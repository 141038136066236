import React from "react";

const Spinner = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 15V18.3333M13.3334 13.3333L15.8334 15.8333L13.3334 13.3333ZM15.0001 10H18.3334H15.0001ZM6.66675 6.66666L4.16675 4.16666L6.66675 6.66666ZM13.3334 6.66666L15.8334 4.16666L13.3334 6.66666ZM6.66675 13.3333L4.16675 15.8333L6.66675 13.3333ZM1.66675 10H5.00008H1.66675ZM10.0001 1.66666V5V1.66666Z"
        stroke="url(#paint0_linear_10069_1177)"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_10069_1177"
          x1="10.0001"
          y1="1.66666"
          x2="10.0001"
          y2="18.3333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7647EE" />
          <stop offset="1" stopColor="#F2EDFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Spinner;
