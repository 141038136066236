import styles from "./GenerateWaybillForm.module.scss";
import primaryComponents from "../../../primaryComponents";
import { useGenerateWaybill } from "./useGenerateWaybill";
import { useValidateForm } from "./validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { truncateString } from "../../../../helpers";
// import AddCustomerSection from "../AddCustomerSection";

const GenerateWaybillForm = ({ orderId }) => {
  const {
    driverName,
    driverPhoneNumber,
    vehicleNumber,
    logisticsCompany,
    currentStep,
    waybillItems,
    isGeneratingWaybill,
    hasSelectedAnItem,
    isValidatingWaybillItems,
    customerAddress,
    // customerDetails,
    // setCustomerDetails,
    setCustomerAddress,
    setWaybillItems,
    setCurrentStep,
    setDriverName,
    setDriverPhoneNumber,
    setDriverPhoneCode,
    setLogisticsCompany,
    setVehicleNumber,
    handleGenerateWaybill,
    handleValidateWaybillItems,
  } = useGenerateWaybill({ orderId });

  const {
    register,
    // handleSubmit,
    formState: { errors },
    control,
    trigger,
    handleSubmit,
  } = useValidateForm({
    driverName,
    driverPhoneNumber,
    vehicleNumber,
    logisticsCompany,
  });

  return (
    <div className={styles.generateWaybillForm}>
      <div>
        {currentStep === "waybill_info" ? (
          <FontAwesomeIcon
            onClick={() => setCurrentStep("waybill_items")}
            className="d-block"
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        ) : null}
      </div>
      {currentStep === "waybill_items" ? (
        <>
          <div className="mt-3">
            <h4>Waybill Items</h4>
            <p>Select and confirm the items for your waybill</p>
          </div>
          <div className={styles.generateWaybillForm__orderItems}>
            {waybillItems.map((order, index) => (
              <div
                key={order?.id}
                className={styles["generateWaybillForm__orderItems-item"]}
              >
                <label
                  className="d-flex align-items-center gap-5"
                  htmlFor={order?.id}
                >
                  <primaryComponents.Checkbox
                    name={order?.cart_item_name}
                    id={order?.id}
                    isChecked={order?.isDisabled === false}
                    onChange={(value) => {
                      const order_items = [...waybillItems];
                      order_items[index].isDisabled = !value;
                      setWaybillItems(order_items);
                    }}
                  />
                  <span className="ml-2">
                    {truncateString(waybillItems[index]?.cart_item_name, 45)}
                  </span>
                </label>
                <div>
                  <input
                    type="number"
                    defaultValue={
                      waybillItems[index]?.waybill_remaining_quantity
                    }
                    value={waybillItems[index]?.quantity}
                    disabled={waybillItems[index]?.isDisabled}
                    onChange={(event) => {
                      const value = event.target.value;
                      const order_items = [...waybillItems];
                      order_items[index].quantity = value;
                      setWaybillItems(order_items);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mt-3">
            <h4>Logistics Details</h4>
            <p>Enter required details for customer&apos;s delivery</p>
          </div>
          {/* Customer information */}
          <div className="mt-5">
            {/* <div className="mb-3">
              <AddCustomerSection
                setCustomerDetails={setCustomerDetails}
                customerDetails={customerDetails}
              />
            </div> */}

            <Controller
              name="customerAddress"
              control={control}
              defaultValue={customerAddress}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  placeholder="Enter Delivery address"
                  isRequired={true}
                  name="customerAddress"
                  errorMessage={errors.customerAddress}
                  value={customerAddress}
                  onKeyUp={() =>
                    errors.customerAddress !== undefined &&
                    trigger("customerAddress")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCustomerAddress(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>

          {/* Logistics information */}
          <div style={{ marginTop: "40px" }}>
            <h5 className="dark--text font-semibold mb-3">
              Add Driver Information
            </h5>
            {/* <Controller
              name="waybillNumber"
              control={control}
              defaultValue={waybillNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Name"}
                  placeholder="Enter Waybill Number"
                  isRequired={true}
                  name="waybillNumber"
                  errorMessage={errors.waybillNumber}
                  value={waybillNumber}
                  onKeyUp={() =>
                    errors.waybillNumber !== undefined &&
                    trigger("waybillNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setWaybillNumber(value);
                  }}
                  register={register}
                />
              )}
            /> */}
            <Controller
              name="driverName"
              control={control}
              defaultValue={driverName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Name"}
                  placeholder="Driver's name"
                  isRequired={true}
                  name="driverName"
                  errorMessage={errors.driverName}
                  value={driverName}
                  onKeyUp={() =>
                    errors.driverName !== undefined && trigger("driverName")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setDriverName(value);
                  }}
                  register={register}
                />
              )}
            />
            <Controller
              name="vehicleNumber"
              control={control}
              defaultValue={vehicleNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Vehicle Number"}
                  placeholder="Vehicle/bike number"
                  isRequired={true}
                  name="vehicleNumber"
                  errorMessage={errors.vehicleNumber}
                  value={vehicleNumber}
                  onKeyUp={() =>
                    errors.vehicleNumber !== undefined &&
                    trigger("vehicleNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setVehicleNumber(value);
                  }}
                  register={register}
                />
              )}
            />
            <Controller
              name="driverPhoneNumber"
              control={control}
              defaultValue={driverPhoneNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.PhoneNumberInputField
                  // label={"Driver's Phone Number"}
                  placeholder="Driver's phone number"
                  isRequired={true}
                  name="driverPhoneNumber"
                  classNames="mb-1"
                  value={driverPhoneNumber}
                  errorMessage={errors.driverPhoneNumber}
                  onKeyUp={() =>
                    errors.driverPhoneNumber !== undefined &&
                    trigger("driverPhoneNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setDriverPhoneNumber(value);
                  }}
                  register={register}
                  phoneCodeEditable={true}
                  onChangePhoneCode={(val) => setDriverPhoneCode(val)}
                />
              )}
            />
            <Controller
              name="logisticsCompany"
              control={control}
              defaultValue={logisticsCompany}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Logisitcs Company"}
                  placeholder="Delivery company"
                  isRequired={false}
                  name="logisticsCompany"
                  errorMessage={errors.logisticsCompany}
                  value={logisticsCompany}
                  onKeyUp={() =>
                    errors.logisticsCompany !== undefined &&
                    trigger("logisticsCompany")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setLogisticsCompany(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>
        </>
      )}

      <div className={styles.generateWaybillForm__bottom}>
        {currentStep === "waybill_items" ? (
          <primaryComponents.Button
            onClick={() => handleValidateWaybillItems()}
            classNames={"btn btn--primary cover"}
            isDisabled={!hasSelectedAnItem || isValidatingWaybillItems}
            isLoading={isValidatingWaybillItems}
            loadingText={"Validating selected items..."}
          >
            Next
          </primaryComponents.Button>
        ) : (
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            isDisabled={isGeneratingWaybill}
            isLoading={isGeneratingWaybill}
            loadingText="Generating..."
            onClick={handleSubmit(handleGenerateWaybill)}
          >
            Submit
          </primaryComponents.Button>
        )}
      </div>
    </div>
  );
};

export default GenerateWaybillForm;
