export const CircleIcon = () => {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="3"
        cy="3"
        r="2.7"
        fill="white"
        stroke="#7647EE"
        strokeWidth="0.6"
      />
    </svg>
  );
};
