import { useState } from "react";
import { cleanObject } from "../../../helpers";
import useSingleProductForm from "../SingleProductForm/useSingleProductForm";
import { v4 as uuidv4 } from "uuid";

const reArrangeCompositeItems = (items) => {
  const newItems = [];
  items?.forEach((item) => {
    newItems.push({
      product_id: item?.product_id,
      variant_id: item?.variant_id,
      name: item?.product_name || item?.variant_name,
      itemId: `${item?.product_id || item?.variant_id}${uuidv4()}`,
      stock_quantity: item?.quantity,
      unit: item?.unit,
    });
  });
  return newItems;
};

const booleanLabel = {
  false: "No",
  true: "Yes",
};

const useCompositeForm = ({
  compositeDetails,
  createCompositeProduct,
  isEditMode,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState(
    reArrangeCompositeItems(compositeDetails?.base_products || []),
  );
  const [compositeRootFormDetails, setCompositeRootFormDetails] = useState({
    type: compositeDetails?.type || "ON_DEMAND",
  });

  const handleCompositeSubmit = (rootFormDetails) => {
    const submissionData = { ...rootFormDetails };
    submissionData.type = compositeRootFormDetails.type;
    const variantDetails = [];
    const productDetails = [];
    selectedProducts?.forEach((item) => {
      if (item?.variant_id) {
        variantDetails.push({
          variant_id: item?.variant_id,
          quantity: item?.stock_quantity,
        });
      } else {
        productDetails.push({
          product_id: item?.product_id,
          quantity: item?.stock_quantity,
        });
      }
    });
    createCompositeProduct(
      cleanObject(
        {
          ...submissionData,
          products_details: productDetails,
          variants_details: variantDetails,
          store_properties: [...submissionData?.store_properties]?.map(
            (item) => {
              return cleanObject(
                item,
                submissionData?.type === "ON_DEMAND"
                  ? ["stock_quantity", "minimum_stock_quantity"]
                  : [],
              );
            },
          ),
        },
        ["has_variant"],
      ),
    );
  };

  const formProperties = useSingleProductForm({
    isEditMode,
    productDetails: compositeDetails,
    isServiceType: false,
    storePropertiesKey: "store_composite_product_properties",
    handleProductUpdate: handleCompositeSubmit,
  });

  return {
    currentStep,
    selectedProducts,
    compositeRootFormDetails,
    ...formProperties,
    setCompositeRootFormDetails,
    setSelectedProducts,
    setCurrentStep,
    handleCompositeSubmit,
    booleanLabel,
  };
};
export default useCompositeForm;
