import { useMutation, useQueryClient } from "react-query";
import { uploadImage } from "../../../services/media";

export const useUploadImageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => uploadImage(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getUploadedImage"]);
    },
  });
};
