import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faAngleRight,
  faAngleLeft,
  faCheck,
  faLock,
  faExclamation,
  faBars,
  faChevronLeft,
  faHeart,
  faEllipsisV,
  faArrowRight,
  faRedoAlt,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faTimes,
  faCheckCircle,
  faAngleUp,
  faAngleDown,
  faUpload,
  faCopy,
  faDownload,
  faRefresh,
  faShop,
  faCartShopping,
  faMapMarkerAlt,
  faCaretDown,
  faCaretUp,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

library.add(
  faPenToSquare,
  faSpinner,
  faAngleLeft,
  faArrowRight,
  faAngleRight,
  faCheck,
  faLock,
  faExclamation,
  faBars,
  faChevronLeft,
  faHeart,
  faEllipsisV,
  faRedoAlt,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faTimes,
  faCheckCircle,
  faAngleUp,
  faAngleDown,
  faUpload,
  faCopy,
  faDownload,
  faRefresh,
  faShop,
  faCartShopping,
  faMapMarkerAlt,
  faCaretDown,
  faCaretUp,
  faEdit,
);
