import { useContext, useEffect, useState } from "react";
import { toggleCampaignStatus } from "../../../services/campaign";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useDeleteCampaign } from "../../../hooks/api/mutations/useLoyalty";

export const useCampaignCardLogic = ({ campaign_id }) => {
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const {
    isSuccess,
    isLoading: isLoadingDeleteCampaign,
    mutate: mutateDeleteCampaign,
  } = useDeleteCampaign();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setShowDeleteModal(false);
    }
  }, [isSuccess]);

  const toggleCampaign = () => {
    toggleCampaignStatus(campaign_id)
      .then((response) => {
        setRequestLoaderProgress(100);
        triggerToast(response.data.detail, "success");
      })
      .catch(() => {
        setRequestLoaderProgress(100);
      });
  };

  const handleCopyUrl = () => triggerToast("Copied url", "success");

  return {
    isLoadingDeleteCampaign,
    showDeleteModal,
    setShowDeleteModal,
    toggleCampaign,
    handleCopyUrl,
    mutateDeleteCampaign,
  };
};
