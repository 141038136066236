import styles from "./styles.module.scss";
import Icon from "../../../../../../../../../assets/icons";
import { useState, useEffect, useRef } from "react";

const MoreActions = ({
  setIsDeleteFoodMenu,
  setIsFoodMenuWidget,
  productId,
  isMobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <span className={styles.container} ref={menuRef}>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img src={Icon.option_alt} alt="" />
      </span>

      {isOpen ? (
        <span className={styles.dropdrown}>
          <p
            onClick={() => {
              setIsOpen(false);
              setIsDeleteFoodMenu(productId);
            }}
            className="error--text"
          >
            Delete
          </p>
          <p
            onClick={() => {
              setIsOpen(false);
              setIsFoodMenuWidget(productId);
            }}
          >
            {!isMobile ? "QR Code" : "Self checkout"}
          </p>
        </span>
      ) : null}
    </span>
  );
};

export default MoreActions;
