import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import primaryComponents from "../../../primaryComponents";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../../helpers";
import { pathConstants } from "../../../../routes/pathContants";
import MoreActions from "./MoreAction";
import secondaryComponents from "../..";

const useStoreWalletsTable = ({ storeWallets }) => {
  const [isFundWallet, setIsFundWallet] = useState(null);
  const [isDebitWallet, setIsDebitWallet] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "customer",
        Cell: ({ cell: { value }, row }) => (
          <Link
            className="purple--text"
            to={pathConstants.INVENTORY_STORE_WALLET_DETAILS({
              id: row?.original?.id,
            })}
            style={{ fontSize: "12px" }}
          >
            {value?.full_name || "N/A"}
          </Link>
        ),
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ row }) => (
          <span>
            {row?.original?.customer?.phone
              ? `(${row?.original?.customer?.phone_code}) ${row?.original?.customer?.phone}`
              : "N/A"}
          </span>
        ),
      },
      {
        Header: "Wallet Balance",
        accessor: "balance",
        Cell: ({ cell: { value }, row }) => (
          <span>
            {formatCurrency({
              value: value,
              currencyCode: row?.original?.parent_store?.currency_code,
            })}
          </span>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }) => (
          <div className="d-flex" style={{ gap: "10px" }}>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_credit_store_wallet"
            >
              <primaryComponents.Button
                classNames={"btn btn--primary smallBtn"}
                onClick={() =>
                  setIsFundWallet({
                    customerEmail: row?.original?.customer?.email,
                    customerId: row?.original?.customer?.id,
                    customerName: row?.original?.customer?.full_name,
                    customerPhoneCode: row?.original?.customer?.phone_code,
                    customerPhoneNumber: row?.original?.customer?.phone,
                  })
                }
              >
                Fund Wallet
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>

            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_debit_store_wallet"
            >
              <primaryComponents.Button
                classNames={"btn btn--outline smallBtn"}
                onClick={() =>
                  setIsDebitWallet({
                    customerEmail: row?.original?.customer?.email,
                    customerId: row?.original?.customer?.id,
                    customerName: row?.original?.customer?.full_name,
                    customerPhoneCode: row?.original?.customer?.phone_code,
                    customerPhoneNumber: row?.original?.customer?.phone,
                  })
                }
              >
                Debit Wallet
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>

            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_manage_store_wallet_settings"
            >
              <MoreActions walletDetails={row?.original} />
            </secondaryComponents.NavigateWithPermission>
          </div>
        ),
      },
    ],
    [],
  );

  const instance = useTable({
    columns,
    data: storeWallets,
  });

  return {
    instance,
    isFundWallet,
    isDebitWallet,
    setIsFundWallet,
    setIsDebitWallet,
  };
};

export default useStoreWalletsTable;
