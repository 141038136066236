import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape(
  {
    customerName: yup.string().required(),
    customerPhoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required(),
    customerEmail: yup
      .string()
      .notRequired()
      .nullable("Email is required")
      .email("Invalid email address")
      .max(40, "Maximum of 30 characters is allowed"),
    driverName: yup.string().required(),
    vehicleNumber: yup.string().required(),
    driverPhoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required(),
    logisticsCompany: yup.string().required(),
    // waybillNumber: yup
    //   .number()
    //   .nullable(true)
    //   .transform((_, val) => (val ? Number(val) : null)),
  },
  ["customerPhoneNumber", "customerPhoneNumber"],
);

export const useValidateForm = (formData) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { ...formData },
  });
};
