import "./EditInviteUser.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import EditInviteUserLogic from "./EditInviteUserLogic";
import EditInviteValidation from "./EditInviteUserValidation";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const EditInviteUser = ({
  showInviteModal,
  setShowInviteModal,
  userEmail = "",
  userRole = "",
  subAccountList,
  storeAccessList,
  fetchUsersList = () => {},
}) => {
  const {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    updateUser,
    resetForm,
    businessId,
    subAccounts,
    setBusinessId,
    userRoles,
    defaultBusinesses,
    storeList,
    storeId,
    defaultStores,
    setStoreId,
  } = EditInviteUserLogic({
    setShowInviteModal,
    userEmail,
    userRole,
    fetchUsersList,
    subAccountList,
    storeAccessList,
  });
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    reset,
    formState: { errors },
    trigger,
    setError,
  } = EditInviteValidation(email, userRole, businessId, storeId);

  useEffect(() => {
    window.setInviteError = setError;
    window.clearInviteError = clearErrors;
    window.resetInviteForm = reset;
  }, []);

  return (
    <secondaryComponents.Modal
      width="550px"
      isActive={showInviteModal}
      closeModal={() => {
        resetForm();
        setShowInviteModal(false);
      }}
    >
      <div className="invite-user__modal">
        <h3>Update User Details</h3>
        {/* error message */}
        {errorMessage !== null && (
          <p className="text-danger text-center mb-1">{errorMessage}</p>
        )}
        <form
          onSubmit={handleSubmit(updateUser)}
          className="invite-user__modal__form"
        >
          {/* email address */}
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Email Address"
                  placeholder="Enter your email address"
                  isRequired={true}
                  type="email"
                  name="email"
                  isDisabled={true}
                  errorMessage={errors.email}
                  onKeyUp={() => errors.email !== undefined && trigger("email")}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setEmail(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>

          {/* user sub accounts */}
          <div className="mt-2">
            <Controller
              control={control}
              defaultValue={businessId}
              name="businessId"
              render={({ field: { onChange } }) => (
                <primaryComponents.MultipleSelectField
                  label="Assign Subaccounts"
                  isRequired={true}
                  placeholder="Select subaccount"
                  value={defaultBusinesses}
                  name="businessId"
                  errorMessage={errors.businessId}
                  options={subAccounts}
                  idKey="id"
                  nameKey="tag"
                  onChange={(values) => {
                    onChange(values);
                    let newValues = [];
                    values?.forEach((item) => {
                      newValues.push(item.value);
                    });
                    setBusinessId(newValues);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              defaultValue={storeId}
              name="storeId"
              render={({ field: { onChange } }) => (
                <primaryComponents.MultipleSelectField
                  label="Assign Store"
                  placeholder="Select store"
                  isRequired={true}
                  name="storeId"
                  errorMessage={errors.storeId}
                  options={storeList}
                  value={defaultStores}
                  idKey="id"
                  nameKey="name"
                  onChange={(values) => {
                    onChange(values);
                    let newValues = [];
                    values?.forEach((item) => {
                      newValues.push(String(item.value));
                    });
                    setStoreId(newValues);
                  }}
                />
              )}
            />
          </div>

          {/* users role */}
          <div>
            <Controller
              control={control}
              defaultValue={role}
              name="role"
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  label="Role"
                  isRequired={true}
                  placeholder="Select role"
                  name="role"
                  value={userRole}
                  errorMessage={errors.role}
                  options={userRoles || []}
                  // isDisabled={role === "CASHIER" || role === "Cashier"}
                  idKey="id"
                  nameKey="label"
                  onChange={(value) => {
                    onChange(
                      userRoles?.find((item) => item.id === value).label,
                    );
                    setRole(value);
                  }}
                />
              )}
            />
          </div>

          <div className="invite-user__modal__actions">
            <primaryComponents.Button
              classNames={"btn btn--primary btnSave"}
              isLoading={isInviting}
              isDisabled={isInviting}
              loadingText="Saving..."
              type="submit"
            >
              Save
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};

export default EditInviteUser;
