import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    store: yup
      .array()
      .required("Store is required")
      .min(1, "Store is required"),
    stockType: yup
      .array()
      .required("Stock type is required")
      .min(1, "Stock type is required"),
    startDate: yup.string().required("Start date is required"),
    endDate: yup.string().required("End date is required"),
  })
  .required();

const Validation = (store, stockType, startDate, endDate) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      store,
      stockType,
      startDate,
      endDate,
    },
  });
};

export default Validation;
