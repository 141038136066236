/* eslint-disable react/jsx-key */
import Images from "../../../assets/images";
import secondaryComponents from "..";
import useRidersTable from "./useRidersTable";

const RidersTable = ({ riders, metaDetails, gotoPage, handleFetchRiders }) => {
  const {
    instance,
    riderDetails,
    riderDeleteDetails,
    setRiderDeleteDetails,
    setRiderDetails,
  } = useRidersTable({
    riders,
    handleFetchRiders,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <secondaryComponents.TableHelper
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-4">
          <img
            src={Images.customersEmptyState}
            width="250px"
            alt="no-dispatch-product"
          />

          <h4 className=" mt-4">You have no Dispatch Riders</h4>
          <p>
            Use the button above to add your riders. When you do, they would
            appear here.
          </p>
        </div>
      </secondaryComponents.TableHelper>

      {/* pagination section */}
      {riders?.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={metaDetails.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails.current_page}
        />
      )}

      {/* update modal */}
      <secondaryComponents.Modal
        isActive={riderDetails != null}
        closeModal={() => setRiderDetails(null)}
        width={550}
      >
        <secondaryComponents.RidersForm
          handleFetchRiders={handleFetchRiders}
          closeModal={() => setRiderDetails(null)}
          isEditMode={true}
          riderInfo={riderDetails}
        />
      </secondaryComponents.Modal>

      {/* delete modal */}
      <secondaryComponents.Modal
        isActive={riderDeleteDetails !== null}
        closeModal={() => setRiderDeleteDetails(null)}
        width={500}
      >
        <secondaryComponents.RidersDelete
          closeModal={() => setRiderDeleteDetails(null)}
          riderDetails={riderDeleteDetails}
          handleFetchRiders={handleFetchRiders}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default RidersTable;
