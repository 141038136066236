import { useState } from "react";

const useLocationModal = ({
  states,
  countries,
  storeDetails,
  setStoreDetails,
}) => {
  const [searchQueryCountry, setSearchQueryCountry] = useState("");
  const [searchQueryState, setSearchQueryState] = useState("");
  const [searchQueryCity, setSearchQueryCity] = useState("");
  const [expandedStates, setExpandedStates] = useState(
    storeDetails?.state_ids || [],
  );
  const [expandedCountry, setExpandedCountry] = useState(
    storeDetails?.country_ids?.[0] || null,
  );

  const toggleStateExpansion = (stateId) => {
    let newExpandedStates = [...expandedStates];
    if (newExpandedStates?.includes(stateId)) {
      const indexOf = newExpandedStates?.indexOf(stateId);
      newExpandedStates?.splice(indexOf, 1);
    } else {
      newExpandedStates = [...newExpandedStates, stateId];
    }
    setExpandedStates(newExpandedStates);
  };

  const toggleStateSelection = (isChecked, state) => {
    let newStateIds = [...storeDetails?.state_ids];
    if (isChecked) newStateIds = [...storeDetails?.state_ids, state?.id];
    else {
      let indexOf = newStateIds.indexOf(state?.id);
      newStateIds.splice(indexOf, 1);
    }

    const updatedCities = {
      ...storeDetails?.city_ids,
    };
    for (const key in updatedCities) {
      if (!newStateIds?.find((id) => `${id}` === `${key}`))
        delete updatedCities[key];
    }

    setStoreDetails((prev) => ({
      ...prev,
      state_ids: newStateIds,
      city_ids: updatedCities,
    }));
  };

  const toggleCitySelection = (isChecked, state, city) => {
    if (!storeDetails?.state_ids?.includes(state?.id))
      toggleStateSelection(isChecked, state);

    const prevCities = storeDetails?.city_ids?.[state?.id] || [];
    let cityIds = prevCities;

    if (isChecked) cityIds = [...cityIds, city?.id];
    else {
      let indexOf = cityIds.indexOf(city?.id);
      cityIds.splice(indexOf, 1);
    }

    setStoreDetails((prev) => ({
      ...prev,
      city_ids: {
        ...storeDetails?.city_ids,
        [state?.id]: cityIds,
      },
    }));
  };

  return {
    searchQueryCountry,
    filteredCountries: countries?.filter((country) =>
      country?.name.toLowerCase().includes(searchQueryCountry.toLowerCase()),
    ),
    filteredStates: states?.filter((state) =>
      state?.name.toLowerCase().includes(searchQueryState.toLowerCase()),
    ),
    expandedStates,
    expandedCountry,
    searchQueryState,
    searchQueryCity,
    setSearchQueryCity,
    setSearchQueryState,
    setSearchQueryCountry,
    setExpandedStates,
    setExpandedCountry,
    toggleStateExpansion,
    toggleStateSelection,
    toggleCitySelection,
  };
};

export default useLocationModal;
