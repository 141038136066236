import { useSelector } from "react-redux";

const useCountryChecker = () => {
  const { country } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return { isNigeria: country === "Nigeria" };
};
export default useCountryChecker;
