import "./LoyaltyNotification.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import LoyaltyNotificationLogic from "./LoyaltyNotificationLogic";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../../routes/pathContants";

const LoyaltyNotification = () => {
  const { has_wallet } = LoyaltyNotificationLogic();
  const navigate = useNavigate();

  return (
    <div className="loyalty-notification-page">
      <h3 className="font-weight-semibold">Whats Next ? </h3>
      <h4 className="font-weight-semibold mt-3">
        You can now start receiving payments while also rewarding your users
      </h4>
      <div className="loyalty-notification-page__steps">
        <div className="loyalty-notification-page__steps__step">
          <div className="loyalty-notification-page__steps__step__number">
            1
          </div>
          <p className="loyalty-notification-page__steps__step__text">
            Ensure that your business account is verified{" "}
          </p>
        </div>
        <div className="loyalty-notification-page__steps__step">
          <div className="loyalty-notification-page__steps__step__number">
            2
          </div>
          <p className="loyalty-notification-page__steps__step__text">
            Below is your bank account number for receiving payments
          </p>
        </div>

        {/* show if account is verified */}
        {has_wallet && <secondaryComponents.AccountDetailWidget />}
        {/* show if account is not verified */}
        {!has_wallet && (
          <secondaryComponents.UnverifiedAccountWidget
            message={
              "It appears your account has not been verified, please verify so that we can generate your account number."
            }
          />
        )}

        <div className="loyalty-notification-page__steps__step">
          <div className="loyalty-notification-page__steps__step__number">
            3
          </div>
          <p className="loyalty-notification-page__steps__step__text">
            For your users to be rewarded they should include their
            <span className="dark--text"> MOBILE NUMBER</span> in their bank
            transfer description
          </p>
        </div>
        <div className="loyalty-notification-page__steps__step">
          <div className="loyalty-notification-page__steps__step__number">
            4
          </div>
          <p className="loyalty-notification-page__steps__step__text">
            We will provide a table top design that makes it easier for your
            users to participate in your loyalty program
          </p>
        </div>
      </div>
      <div className="loyalty-notification-page__button">
        <primaryComponents.Button
          classNames="btn btn--primary ml-auto"
          onClick={() => navigate(pathConstants?.DASHBOARD_MAIN)}
        >
          Return to Dashboard
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default LoyaltyNotification;
