import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useAllProducts from "./useAllProducts";
import Images from "../../../../assets/images";
import primaryComponents from "../../../../components/primaryComponents";
import { useNavigate } from "react-router-dom";
import styles from "./AllProducts.module.scss";
import { getPlatform } from "../../../../helpers";
import { platformHandler } from "../../../../hooks/context";
import { ToastContext } from "../../../../hooks/context";
import { useContext } from "react";
import useGetPermission from "../../../../hooks/useGetPermission";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { pathConstants } from "../../../../routes/pathContants";
import {
  BulkRestockIcon,
  ExportIcon,
  ReconciliationIcon,
  RemoveIcon,
  ReturnIcon,
  TransferIcon,
} from "../../../../assets/svgIcons";
import Icon from "../../../../assets/icons";

const AllProducts = () => {
  const {
    storeList,
    currentStore,
    categories,
    isProductWidget,
    isFetchingProducts,
    products,
    metaDetails,
    search,
    currentCategory,
    inventoryType,
    isLoadingCategories,
    selectedProducts,
    setSelectedProducts,
    filterByCategory,
    filterProductByStore,
    filterInventoryType,
    setIsProductWidget,
    gotoPage,
    searchProduct,
    handleFetchproducts,
    closeSideModals,
    openSideModals,
    isProductBulkUpdate,
    setIsProductBulkUpdate,
  } = useAllProducts();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const { isPermitted: isPermittedToViewStore } = useGetPermission({
    permissionChildKey: "can_view_stores",
    permissionParentKey: "Inventory Management",
  });
  const triggerToast = useContext(ToastContext);

  if (isPermittedToViewStore && storeList?.length === 0) {
    return (
      <div className="mt-5 text-center">
        <img
          src={Images.storeProductEmpty}
          width="250px"
          alt="no-store-product"
          className="mx-auto"
        />
        <h3 className="mt-3">You have no Store(s)</h3>
        <p>You have not created a store yet. Please create one to continue</p>
        <primaryComponents.Button
          onClick={() => navigate(pathConstants.INVENTORY_STORES)}
          classNames="btn btn--primary mx-auto mt-4"
        >
          Create Store
        </primaryComponents.Button>
      </div>
    );
  }

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Products"
      description="View of all Products"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${styles.allProducts} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Products</h4>
            <p>Access and update all your product&apos;s information here</p>
          </div>
          <div className={styles.allProducts__header}>
            <p>{metaDetails?.total} Products</p>

            <div className={styles.headerLeft}>
              {getPlatform(platform) === "/app" ? (
                <>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_export_product"
                  >
                    <secondaryComponents.ToolTipV2 tipText="Export Inventory">
                      <primaryComponents.Button
                        classNames="btn btn--outline-yellow"
                        onClick={() => openSideModals("export")}
                        style={{ width: "60px" }}
                      >
                        <ExportIcon />
                      </primaryComponents.Button>
                    </secondaryComponents.ToolTipV2>
                  </secondaryComponents.NavigateWithPermission>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_transfer_product"
                  >
                    <secondaryComponents.ToolTipV2 tipText="Transfer Stock">
                      <primaryComponents.Button
                        classNames="btn btn--outline-green"
                        onClick={() =>
                          navigate(
                            pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY,
                          )
                        }
                        style={{ width: "60px" }}
                      >
                        <TransferIcon />
                      </primaryComponents.Button>
                    </secondaryComponents.ToolTipV2>
                  </secondaryComponents.NavigateWithPermission>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_remove_products"
                  >
                    <secondaryComponents.ToolTipV2 tipText="Remove Stock">
                      <primaryComponents.Button
                        classNames="btn btn--outline-red"
                        onClick={() => openSideModals("removestock")}
                        style={{ width: "60px" }}
                      >
                        <RemoveIcon />
                      </primaryComponents.Button>
                    </secondaryComponents.ToolTipV2>
                  </secondaryComponents.NavigateWithPermission>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_create_and_submit_reconciliation"
                  >
                    <primaryComponents.Button
                      classNames="btn btn--yellow"
                      onClick={() =>
                        navigate(pathConstants.INVENTORY_RECONCILIATIONS)
                      }
                    >
                      <ReconciliationIcon />
                      <span className="ml-2">Reconciliation</span>
                    </primaryComponents.Button>
                  </secondaryComponents.NavigateWithPermission>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_return_products"
                  >
                    <primaryComponents.Button
                      classNames="btn btn--orange"
                      onClick={() => openSideModals("returnstock")}
                    >
                      <ReturnIcon />
                      <span className="ml-2">Returns</span>
                    </primaryComponents.Button>
                  </secondaryComponents.NavigateWithPermission>
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={"Inventory Management"}
                    permissionChildKey="can_restock_products_bulk_and_single_restock"
                  >
                    <primaryComponents.Button
                      classNames="btn btn--success"
                      onClick={() =>
                        navigate(pathConstants.INVENTORY_PRODUCTS_BULK_RESTOCK)
                      }
                    >
                      <BulkRestockIcon />
                      <span className="ml-2">Restock</span>
                    </primaryComponents.Button>
                  </secondaryComponents.NavigateWithPermission>
                </>
              ) : null}
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_add_products"
              >
                <primaryComponents.Button
                  classNames={`btn btn--primary ${styles.addProductBtn}`}
                  onClick={() => setIsProductWidget(true)}
                >
                  + Add new product
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>
          </div>

          {/* table section */}
          <div className={styles.tableBody}>
            <div className={`${styles.tableBody__header}`}>
              <div className="mt-4">
                <primaryComponents.SearchField
                  classNames={styles.searchField}
                  placeholder="Search by Barcode Scaning or product name, ID & SKU"
                  trigger={(value) => searchProduct(value)}
                  value={
                    search.get("query") !== null ? search.get("query") : ""
                  }
                  triggerOnInput={true}
                  showsearchBtn={true}
                  isScan={true}
                />
              </div>

              <div style={{ width: "140px" }}>
                <primaryComponents.SelectField
                  options={storeList}
                  idKey="id"
                  nameKey="name"
                  label={"Store"}
                  onChange={(value) => {
                    filterProductByStore(value);
                  }}
                  value={currentStore || "Select stores"}
                  floatError={false}
                  classNames="store-select"
                  isSearchable={false}
                />
              </div>
              <div style={{ width: "140px" }}>
                <secondaryComponents.LoaderHelper
                  isLoading={isLoadingCategories}
                  classNames={"mt-2"}
                >
                  <primaryComponents.SelectField
                    options={[{ id: 0, name: "All" }, ...categories]}
                    nameKey={"name"}
                    label={"Category"}
                    idKey={"id"}
                    value={
                      categories?.find(
                        (item) => `${item?.id}` === `${currentCategory}`,
                      )?.name || "Category"
                    }
                    onChange={(value) => filterByCategory(value)}
                    floatError={false}
                  />
                </secondaryComponents.LoaderHelper>
              </div>
              <div style={{ width: "140px" }}>
                <primaryComponents.SelectField
                  value={inventoryType || "Type"}
                  options={["All Inventory", "Products", "Services"]}
                  onChange={(item) => filterInventoryType(item)}
                  isSearchable={false}
                  floatError={false}
                  label={"Inventory Type"}
                />
              </div>
              <div className={styles.tableBody__editButton}>
                <primaryComponents.Button
                  classNames={"btn btn--outline"}
                  onClick={() => {
                    if (selectedProducts.length === 0) {
                      setIsProductBulkUpdate(false);
                      triggerToast("Please select product", "error");
                    } else {
                      setIsProductBulkUpdate(true);
                    }
                  }}
                >
                  <span>Bulk Edit</span>
                  <img src={Icon.editWrite} alt="" width="15px" height="15px" />
                </primaryComponents.Button>
              </div>
            </div>
            {/* table section */}
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingProducts}
              classNames="mt-5"
            >
              <secondaryComponents.AllProductsTable
                products={products}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                handleFetchproducts={handleFetchproducts}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          {/* product widget */}
          <secondaryComponents.Modal
            isActive={isProductWidget}
            closeModal={() => setIsProductWidget(false)}
            width={"800px"}
          >
            <secondaryComponents.AddProductWidget
              closeModal={() => setIsProductWidget(false)}
            />
          </secondaryComponents.Modal>

          {/* delete stock sidebar */}
          <secondaryComponents.SideModal
            width={650}
            isActive={search.get("sidemodal") === "removestock"}
            closeModal={closeSideModals}
          >
            <secondaryComponents.DeleteStockWidget
              closeWidget={closeSideModals}
            />
          </secondaryComponents.SideModal>
          {/* transfer stock sidebar */}
          <secondaryComponents.SideModal
            width={600}
            isActive={search.get("sidemodal") === "transferstock"}
            closeModal={closeSideModals}
          >
            <secondaryComponents.TransferStockWidget
              closeWidget={closeSideModals}
            />
          </secondaryComponents.SideModal>
          {/* return stock sidebar */}
          <secondaryComponents.SideModal
            width={600}
            isActive={search.get("sidemodal") === "returnstock"}
            closeModal={closeSideModals}
          >
            <secondaryComponents.ReturnStockWidget
              closeWidget={closeSideModals}
            />
          </secondaryComponents.SideModal>

          {/* export products sidebar */}
          <secondaryComponents.SideModal
            isActive={search.get("sidemodal") === "export"}
            closeModal={closeSideModals}
          >
            <secondaryComponents.ProductsExport closeWidget={closeSideModals} />
          </secondaryComponents.SideModal>

          {/* bulk update product */}
          <secondaryComponents.Modal
            isActive={isProductBulkUpdate}
            closeModal={() => setIsProductBulkUpdate(false)}
            width={"500px"}
          >
            <secondaryComponents.BulkEditProduct
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
              handleFetchproducts={handleFetchproducts}
              closeModal={() => setIsProductBulkUpdate(false)}
              currentStore={currentStore}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllProducts;
