import { useContext, useRef, useState } from "react";
import { useQuery } from "react-query";
import { ToastContext } from "../../../hooks/context";
import { getMenuDetails } from "../../../services/inventory";

const useFoodMenuWidget = ({ menuId }) => {
  const triggerToast = useContext(ToastContext);
  const nonVariantComponent = useRef();
  const [setSize, setIsSize] = useState("");
  const [printSize, setPrintSize] = useState("");

  const { data: menuDetails, isLoading: isFetchingMenuDetails } = useQuery(
    ["getMenuDetails", menuId],
    () =>
      getMenuDetails(menuId).then((res) => {
        return res.data?.data;
      }),
    { staleTime: Infinity },
  );

  const onCopy = () => triggerToast("Copied", "success");

  const handleShare = async (url) => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "LUMI BUSINESS",
          text: "View and Manage Menu for e.g Restaurant",
          url: url,
        });
        console.log("App shared successfully");
      } catch (error) {
        triggerToast("Error sharing the app:", "error");
      }
    } else {
      const fallbackMessage = "Copy this URL to share: " + url;
      navigator.clipboard.writeText(url);
      triggerToast(fallbackMessage, "success");
    }
  };

  const getQRCodeContainerSize = (size) => {
    switch (size) {
      case "4x4 inches":
        return "24em";
      case "4x5 inches":
        return "24em";
      case "11.69x8.26 Inches (A4 Paper size)":
        return "37.188em";
      default:
        return "24em";
    }
  };

  return {
    isFetchingMenuDetails,
    menuDetails,
    nonVariantComponent,
    setSize,
    setIsSize,
    onCopy,
    handleShare,
    printSize,
    setPrintSize,
    getQRCodeContainerSize,
  };
};
export default useFoodMenuWidget;
