import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./ManageRoles.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { platformHandler } from "../../../../../hooks/context";
import { getPlatform } from "../../../../../helpers";
import CreateRoleModal from "./CreateRole";
import { pathConstants } from "../../../../../routes/pathContants";

const ManageRoles = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { platform } = useContext(platformHandler);
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Manage Roles"
      description="Manage users roles"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={styles.manage_roles_container}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(pathConstants.SETTINGS_USERS)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <div>
            <div className={styles.heading_container}>
              <h3 style={{ maxWidth: "600px" }}>Manage Roles</h3>

              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Settings"}
                permissionChildKey="can_create_custom_roles"
              >
                <primaryComponents.Button
                  onClick={() => setShowCreateRoleModal(true)}
                  classNames="btn btn--primary ml-2"
                >
                  <span className="mr-2">+</span>
                  Create Role
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>
            <p>Last updated Monday 11th June 2022</p>
          </div>

          <div className={styles["settings-page__tab"]}>
            <div className={styles["settings-page__tab__menu"]}>
              <NavLink
                to={
                  getPlatform(platform) + "/settings/users/manage-roles/default"
                }
                className={`${
                  location.pathname ===
                  getPlatform(platform) + "/settings/users/manage-roles"
                    ? "active"
                    : ""
                } settings-page__tab__menu__item`}
              >
                Default Role
              </NavLink>
              <NavLink
                to={
                  getPlatform(platform) + "/settings/users/manage-roles/custom"
                }
                className={`${
                  location.pathname ===
                  getPlatform(platform) + "/settings/users/manage-roles/custom"
                    ? "active"
                    : ""
                } settings-page__tab__menu__item`}
              >
                Custom Role
              </NavLink>
            </div>
            <div className={styles["settings-page__tab__content"]}>
              <Outlet />
            </div>
          </div>

          {showCreateRoleModal && (
            <CreateRoleModal
              showCreateRoleModal={showCreateRoleModal}
              setShowCreateRoleModal={setShowCreateRoleModal}
            />
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default ManageRoles;
