import "./RevenueChartTooltip.scss";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";
import { useSelector } from "react-redux";

const RevenueChartTooltip = ({
  active,
  payload = [],
  label,
  isMoney = true,
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  if (active) {
    return (
      <div className="revenue-chart-tooltip">
        <p className="revenue-chart-tooltip__value purple--text">
          {isMoney
            ? formatCurrency({
                value: payload[0]?.value,
                currencyCode: currency_code,
              })
            : payload[0]?.value}
        </p>
        <p className="revenue-chart-tooltip__label">
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

export default RevenueChartTooltip;
