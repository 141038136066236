import React from "react";

export const PaperNotesIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6H16H8ZM8 10H16H8ZM8 14H11H8ZM6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22Z"
        fill="#FACE8A"
        stroke="#0B0C0E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
