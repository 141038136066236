import React from "react";

export const AccountingsIcon = () => {
  return (
    <svg
      width="26"
      height="23"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49998 25.4167H19.5C20.6966 25.4167 21.6666 24.3981 21.6666 23.1417V4.94166C21.6666 3.68522 20.6966 2.66666 19.5 2.66666H6.49998C5.30336 2.66666 4.33331 3.68522 4.33331 4.94166V23.1417C4.33331 24.3981 5.30336 25.4167 6.49998 25.4167Z"
        stroke="#7647EE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="8.66665" cy="12.4167" r="1.08333" fill="#7647EE" />
      <circle cx="8.66665" cy="15.6667" r="1.08333" fill="#7647EE" />
      <circle cx="8.66665" cy="18.9167" r="1.08333" fill="#7647EE" />
      <circle cx="13" cy="18.9167" r="1.08333" fill="#7647EE" />
      <circle cx="17.3333" cy="18.9167" r="1.08333" fill="#7647EE" />
      <circle cx="13" cy="15.6667" r="1.08333" fill="#7647EE" />
      <circle cx="17.3333" cy="15.6667" r="1.08333" fill="#7647EE" />
      <circle cx="13" cy="12.4167" r="1.08333" fill="#7647EE" />
      <circle cx="17.3333" cy="12.4167" r="1.08333" fill="#7647EE" />
      <rect
        x="7.58331"
        y="22.1671"
        width="6.5"
        height="1.08333"
        rx="0.541667"
        fill="#7647EE"
      />
      <rect
        x="3.90002"
        y="7"
        width="18.2"
        height="1.3"
        rx="0.65"
        fill="#7647EE"
      />
      <rect
        x="16.25"
        y="22.1667"
        width="2.16667"
        height="1.08333"
        rx="0.541667"
        fill="#7647EE"
      />
    </svg>
  );
};
