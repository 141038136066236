import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    date: yup.string().required("Date is required"),
    time: yup.string().required("Time is required"),
  })
  .required();

const Validation = ({ formDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      ...formDetails,
    },
  });
};

export default Validation;
