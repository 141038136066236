import secondaryComponents from "..";
import useCustomerLoyaltyTable from "./useCustomerLoyaltyTableLogic";
import Images from "../../../assets/images";

const CustomerLoyaltyTable = ({ customers, gotoPage, metaDetails }) => {
  const { instance } = useCustomerLoyaltyTable({ customers });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <img
            src={Images.customersEmptyState}
            alt="No Loyalty Activity Found"
            width="200px"
            height="202px"
          />
          <h5>No data available</h5>
        </div>
      </secondaryComponents.TableHelper>
      {customers?.length ? (
        <secondaryComponents.Pagination
          totalPageNumber={metaDetails?.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails?.current_page}
        />
      ) : null}
    </>
  );
};

export default CustomerLoyaltyTable;
