import { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createRole,
  getPermissionCategories,
  getPermissions,
} from "../../../../../../services/settings";
import { ToastContext } from "../../../../../../hooks/context";

const useCreateRole = ({ setShowCreateRoleModal }) => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const [showPermission, setShowPermission] = useState("Inventory Management");
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [selectAllAccounting, setSelectAllAccounting] = useState(false);
  const [selectAllCustomers, setSelectAllCustomers] = useState(false);
  const [selectAllExpense, setSelectAllExpense] = useState(false);
  const [selectAllInventoryManagement, setSelectAllInventoryManagement] =
    useState(false);
  const [selectAllLoyalty, setSelectAllLoyalty] = useState(false);
  const [selectAllPayments, setSelectAllPayments] = useState(false);
  const [selectAllSettings, setSelectAllSettings] = useState(false);
  const [roleName, setRoleName] = useState("");

  const {
    isLoading: isLoadingPermissionCategory,
    data: dataPermissionsCategory,
  } = useQuery(["permissions-categories"], () =>
    getPermissionCategories().then((res) => res.data?.data),
  );

  const { isLoading: isLoadingPermissions, data: dataPermissions } = useQuery(
    ["permissions"],
    () => getPermissions().then((res) => res.data?.data),
  );

  const { isLoading: isLoadingMutate, mutate } = useMutation({
    mutationFn: (data) => createRole(data),
    onSuccess: (data) => {
      queryClient.refetchQueries("roles-custom");
      triggerToast(data.data.detail, "success");
      setShowCreateRoleModal(false);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const handleCreateRole = () => {
    if (selectedPermissions.length === 0) {
      triggerToast("Enter at least one permission", "warning");
      return;
    }

    mutate({ label: roleName, permission_ids: selectedPermissions });
  };

  return {
    isLoadingPermissions,
    isLoadingPermissionCategory,
    isLoadingMutate,
    dataPermissionsCategory,
    dataPermissions,
    roleName,
    showPermission,
    selectedPermissions,
    selectAllAccounting,
    selectAllCustomers,
    selectAllExpense,
    selectAllInventoryManagement,
    selectAllLoyalty,
    selectAllPayments,
    selectAllSettings,
    setRoleName,
    handleCreateRole,
    setShowPermission,
    setSelectedPermissions,
    setSelectAllAccounting,
    setSelectAllCustomers,
    setSelectAllExpense,
    setSelectAllInventoryManagement,
    setSelectAllLoyalty,
    setSelectAllPayments,
    setSelectAllSettings,
  };
};

export default useCreateRole;
