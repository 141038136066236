import { useTable } from "react-table";
import { useMemo, useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getLoyaltyCustomers } from "../../../services/customer";
import { RequestLoader, platformHandler } from "../../../hooks/context";
import {
  formatCurrency,
  getPlatform,
  getUrlQuerysection,
} from "../../../helpers";
import { getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";

const useLoyaltyCustomersTable = ({ isRefresh, setIsRefresh }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [isFetchingCustomers, setIsFetchingCustomers] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersPageDetails, setCustomersPageDetails] = useState({});
  const [searchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : "",
  );
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  useEffect(() => {
    fetchCustomers();
  }, []);

  useEffect(() => {
    if (isRefresh) fetchCustomers();
  }, [isRefresh]);

  const fetchCustomers = () => {
    setIsFetchingCustomers(true);
    setRequestLoaderProgress(getRandomIntInclusive(5, 30));
    let queries = getUrlQuerysection();
    getLoyaltyCustomers(queries)
      .then((response) => {
        setCustomers(response?.data?.data);
        setCustomersPageDetails({
          current_page: response?.data?.current_page,
          next_page: response?.data?.next_page,
        });
        setRequestLoaderProgress(100);
        setIsFetchingCustomers(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsFetchingCustomers(false);
      });
    setIsRefresh(false);
  };

  const gotoPage = (pageNumber) => {
    let queries = getUrlQuerysection();
    const queryParams = new URLSearchParams(window.location.search);
    let page = queryParams.get("page");
    if (page === null) {
      let symbol = queries.includes("?") ? "&" : "?";
      navigate(`${queries}${symbol}page=${pageNumber}`);
      return fetchCustomers();
    }

    let splittedQuery = queries.split(`page=${page}`);
    splittedQuery = splittedQuery[0] + `page=${pageNumber}` + splittedQuery[1];
    navigate(`${splittedQuery}`);
    fetchCustomers();
  };

  const searchCustomer = (value) => {
    let searchQuery = value === "" ? "" : `?query=${value}`;
    navigate(`${getPlatform(platform)}/loyalty/customers${searchQuery}`);
    fetchCustomers();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Customer ID",
        accessor: "loyalty_id",
        Cell: ({ cell: { value } }) => (
          <Link
            className="purple--text"
            to={pathConstants.LOYALTY_CUSTOMERS_DETAILS({ id: value })}
            style={{ fontSize: "12px", textDecoration: "underline !important" }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Customer's Name",
        accessor: "first_name",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Reward Balance",
        accessor: "points_balance",
        Cell: ({ cell: { value } }) =>
          value
            ? formatCurrency({
                value: value?.balance_naira_equiv,
                currencyCode: currency_code,
              })
            : 0.0,
      },
      {
        Header: "Point Balance",
        accessor: "points_balance",
        id: "points_left",
        Cell: ({ cell: { value } }) =>
          value ? value?.balance.toLocaleString("en-US") : 0.0,
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: customers });
  return {
    instance,
    isFetchingCustomers,
    customers,
    gotoPage,
    customersPageDetails,
    searchCustomer,
    searchValue,
  };
};

export default useLoyaltyCustomersTable;
