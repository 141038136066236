import { useSelector } from "react-redux";
import "./PointsInputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PointsInputField = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  value = "",
  register = () => {},
  onKeyUp = () => {},
  point_value = 1,
  ...rest
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className="points-input-field">
      {label !== null && (
        <label htmlFor={name}>
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`points-input-field__input-section ${
          errorMessage !== undefined && "error-border"
        }`}
      >
        <span>
          {point_value} {currency_code} =
        </span>
        <input
          type="number"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={isDisabled}
          value={value}
          {...register(name, { required: isRequired })}
          onChange={onChange}
          onKeyUp={onKeyUp}
          {...rest}
        />
        <span>Points</span>
      </div>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default PointsInputField;
