import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const FundWallerValidationSchema = yup.object({
  customer: yup.string().required("Customer is required"),
  store: yup.string().required("Store is required"),
});

export const Validation = ({ walletDetails }) => {
  return useForm({
    resolver: yupResolver(FundWallerValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customer: walletDetails?.customer?.customerId,
      store: walletDetails?.store_id,
    },
  });
};
