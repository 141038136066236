import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./PercentField.module.scss";
import { formatNumberWithCommas } from "../../../helpers";

const PercentField = ({
  placeholder = "90%",
  name = "",
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  register = () => {},
  onKeyUp = () => {},
  classNames = "",
  floatError = true,
  inputIcon = null,
  dividedValue = 0,
  showEquivalent = true,
  value = 0,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <span className={`${styles.percentField}`}>
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <span className={`${classNames} ${styles.percentField__cover} `}>
        <span
          className={`${styles.percentSection} ${
            errorMessage !== undefined ? styles.errorBorder : ""
          } ${isDisabled ? styles.isDisabled : ""}`}
        >
          <span>%</span>
          <input
            data-testid="input1"
            type={"number"}
            name={`${name}1`}
            placeholder={placeholder}
            id={`${name}1`}
            {...register(`${name}1`, { required: isRequired })}
            onChange={(e) => {
              setInputValue(e?.target?.value);
              onChange({
                percentValue: e?.target?.value,
                equivalentValue: (e?.target?.value * dividedValue) / 100,
              });
            }}
            value={inputValue}
            disabled={isDisabled}
            {...props}
          />
        </span>
        {showEquivalent ? (
          <input
            data-testid="input2"
            className={`${
              errorMessage !== undefined ? styles.errorBorder : ""
            } ${styles.equivalentSection}`}
            placeholder={"Equivalent"}
            id={`${name}2`}
            name={`${name}2`}
            disabled={true}
            value={formatNumberWithCommas(
              ((inputValue * dividedValue) / 100).toFixed(2),
            )}
          />
        ) : null}
      </span>

      {/* error position */}
      {floatError || errorMessage !== undefined ? (
        <p
          className={`${styles.errorMessage} text-danger ${
            errorMessage !== undefined ? styles.show : ""
          }`}
          data-testid="error-message"
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage?.message}</span>}
        </p>
      ) : null}
    </span>
  );
};
export default PercentField;
