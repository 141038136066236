import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  redemption_code: yup.string().required("Redemption code is required."),
});

export const useRedeemGiftCardFormValidation = ({ redemption_code }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { redemption_code },
  });
};
