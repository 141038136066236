import { useTable } from "react-table";
import { useContext, useMemo } from "react";
import { useMutation } from "react-query";
import { deleteRole } from "../../../../../services/settings";
import { ToastContext } from "../../../../../hooks/context";

const useManageRolesTable = ({ rolesData, refetch, setShowDeleteWarning }) => {
  const triggerToast = useContext(ToastContext);

  const columns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "label",
        Cell: ({ cell: { value } }) => (
          <span>
            {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
          </span>
        ),
      },
    ],
    [],
  );

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation({
    mutationFn: (id) => deleteRole(id),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      refetch();
      setShowDeleteWarning(null);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const instance = useTable({ columns, data: rolesData });

  return {
    instance,
    isLoadingDelete,
    mutateDelete,
  };
};

export default useManageRolesTable;
