import styles from "./CreateRole.module.scss";
import secondaryComponents from "../../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../../components/primaryComponents";
import { Controller } from "react-hook-form";
import CreateRoleVaidation from "./CreateRoleValidation";
import useCreateRole from "./useCreateRole";
import PermissionCheckBox from "./PermissionCheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateRoleModal = ({ showCreateRoleModal, setShowCreateRoleModal }) => {
  const {
    showPermission,
    roleName,
    setRoleName,
    handleCreateRole,
    isLoadingPermissionCategory,
    isLoadingPermissions,
    isLoadingMutate,
    dataPermissionsCategory,
    dataPermissions,
    selectedPermissions,
    selectAllAccounting,
    selectAllCustomers,
    selectAllExpense,
    selectAllInventoryManagement,
    selectAllLoyalty,
    selectAllPayments,
    selectAllSettings,
    setShowPermission,
    setSelectedPermissions,
    setSelectAllAccounting,
    setSelectAllCustomers,
    setSelectAllExpense,
    setSelectAllInventoryManagement,
    setSelectAllLoyalty,
    setSelectAllPayments,
    setSelectAllSettings,
  } = useCreateRole({ setShowCreateRoleModal });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    trigger,
    reset,
  } = CreateRoleVaidation(roleName);

  return (
    <secondaryComponents.Modal
      width="550px"
      isActive={showCreateRoleModal}
      closeModal={() => {
        reset();
        setShowCreateRoleModal(false);
      }}
    >
      <div className={styles.modal_container}>
        <h3>Create Role</h3>
        <p>
          Create roles that are unique to your organization and choose what they
          can see and do
        </p>
        <secondaryComponents.LoaderHelper
          classNames="mt-5"
          isLoading={
            isLoadingPermissionCategory ||
            isLoadingPermissions ||
            !dataPermissionsCategory ||
            !dataPermissions
          }
        >
          <form
            onSubmit={handleSubmit(handleCreateRole)}
            className={styles.form_container}
          >
            <div>
              <Controller
                name="label"
                control={control}
                defaultValue={roleName}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Role name"
                    placeholder="Enter the name of the unique role"
                    isRequired={true}
                    type="text"
                    name="label"
                    errorMessage={errors.label}
                    onKeyUp={() =>
                      errors.label !== undefined && trigger("label")
                    }
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setRoleName(value);
                    }}
                    register={register}
                  />
                )}
              />
            </div>
            <div>
              <div className={styles.permissions_text}>Permissions</div>
              <p className={styles.permissions_subtext}>
                This role can have access to:
              </p>

              <div className={styles.permission_container}>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Inventory Management"
                          ? null
                          : "Inventory Management",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Inventory Management</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Inventory Management" &&
                        styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Inventory Management" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllInventoryManagement}
                          id={"selectAllInventoryManagement"}
                          name={"selectAllInventoryManagement"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find(
                                (item) => item.label === "Inventory Management",
                              )
                              ?.permissions.map((item) => item.id);

                            if (selectAllInventoryManagement) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllInventoryManagement(
                              !selectAllInventoryManagement,
                            );
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Inventory Management")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Expense" ? null : "Expense",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Expense</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Expense" && styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Expense" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllExpense}
                          id={"selectAllExpense"}
                          name={"selectAllExpense"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find((item) => item.label === "Expense")
                              ?.permissions.map((item) => item.id);

                            if (selectAllExpense) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllExpense(!selectAllExpense);
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Expense")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Payments" ? null : "Payments",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Payments</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Payments" && styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Payments" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllPayments}
                          id={"selectAllPayments"}
                          name={"selectAllPayments"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find((item) => item.label === "Payments")
                              ?.permissions.map((item) => item.id);

                            if (selectAllPayments) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllPayments(!selectAllPayments);
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Payments")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Loyalty" ? null : "Loyalty",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Loyalty</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Loyalty" && styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Loyalty" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllLoyalty}
                          id={"selectAllLoyalty"}
                          name={"selectAllLoyalty"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find((item) => item.label === "Loyalty")
                              ?.permissions.map((item) => item.id);

                            if (selectAllLoyalty) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllLoyalty(!selectAllLoyalty);
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Loyalty")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Accounting" ? null : "Accounting",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Accounting</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Accounting" && styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Accounting" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllAccounting}
                          id={"selectAllAccounting"}
                          name={"selectAllAccounting"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find((item) => item.label === "Accounting")
                              ?.permissions.map((item) => item.id);

                            if (selectAllAccounting) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllAccounting(!selectAllAccounting);
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Accounting")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Customers" ? null : "Customers",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Customers</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Customers" && styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Customers" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllCustomers}
                          id={"selectAllCustomers"}
                          name={"selectAllCustomers"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find((item) => item.label === "Customers")
                              ?.permissions.map((item) => item.id);

                            if (selectAllCustomers) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllCustomers(!selectAllCustomers);
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Customers")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
                <div className={styles.permission_item}>
                  <div
                    onClick={() =>
                      setShowPermission(
                        showPermission === "Settings" ? null : "Settings",
                      )
                    }
                    className={styles.permission_item_heading}
                  >
                    <p>Settings</p>
                    <FontAwesomeIcon
                      className={`gray--text ${styles.transform_icon} ${
                        showPermission === "Settings" && styles.active
                      }`}
                      icon="angle-down"
                      style={{ fontSize: "18px" }}
                    />
                  </div>
                  {showPermission === "Settings" && (
                    <div className={styles.permission_item_body}>
                      <div className={styles.permission_value_all}>
                        <primaryComponents.Checkbox
                          isChecked={selectAllSettings}
                          id={"selectAllSettings"}
                          name={"selectAllSettings"}
                          onChange={() => {
                            const permissions = dataPermissionsCategory
                              ?.find((item) => item.label === "Settings")
                              ?.permissions.map((item) => item.id);

                            if (selectAllSettings) {
                              const notInPermissions =
                                selectedPermissions.filter(
                                  (item) => !permissions.includes(item),
                                );

                              setSelectedPermissions(notInPermissions);
                            } else {
                              setSelectedPermissions([
                                ...new Set([
                                  ...selectedPermissions,
                                  ...permissions,
                                ]),
                              ]);
                            }
                            setSelectAllSettings(!selectAllSettings);
                          }}
                        />
                        <p className="ml-2 mt-1">Select All</p>
                      </div>
                      {dataPermissionsCategory
                        ?.find((item) => item.label === "Settings")
                        ?.permissions.map((perm) => (
                          <PermissionCheckBox
                            key={perm.id}
                            perm={perm}
                            selectedPermissions={selectedPermissions}
                            setSelectedPermissions={setSelectedPermissions}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <primaryComponents.Button
              classNames={`btn btn--primary ${styles.btnCreate}`}
              isLoading={isLoadingMutate}
              isDisabled={isLoadingMutate}
              loadingText="Creating..."
              type="submit"
            >
              Create new role
            </primaryComponents.Button>
          </form>
        </secondaryComponents.LoaderHelper>
      </div>
    </secondaryComponents.Modal>
  );
};

export default CreateRoleModal;
