import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const StoreCheckoutValidationSchema = yup.object({
  checkout_type: yup
    .string()
    .required("Please choose a payment option to continue"),
});

const StoreCheckoutValidation = (checkout_type) => {
  return useForm({
    resolver: yupResolver(StoreCheckoutValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      checkout_type,
    },
  });
};

export default StoreCheckoutValidation;
