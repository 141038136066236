import "./ProductStepper.scss";
import ProductStepperLogic from "./ProductStepperLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const ProductStepper = ({ currentStep, navigateToStep }) => {
  const { steps } = ProductStepperLogic();
  return (
    <div className="stepper">
      {steps.map((step) => (
        <div
          key={`${step.label} + ${step.id}`}
          className="stepper__step"
          onClick={() => {
            if (currentStep <= step.id) return;
            navigateToStep(step.id);
          }}
        >
          <div
            className={`stepper__step-number ${
              currentStep >= step.id && "purple white--text"
            }`}
          >
            {/* checked icon */}
            {currentStep > step.id && (
              <motion.span
                initial={{ opacity: 0, marginTop: "38px" }}
                animate={{
                  opacity: 1,
                  marginTop: "0px",
                  transition: { duration: 0.3 },
                }}
                exit={{ opacity: 0 }}
              >
                <FontAwesomeIcon icon="check" style={{ fontSize: "18px" }} />
              </motion.span>
            )}

            {/* step number */}
            {currentStep <= step.id && (
              <motion.span
                initial={{ opacity: 0, marginTop: "38px" }}
                animate={{
                  opacity: 1,
                  marginTop: "0px",
                  transition: { duration: 0.3 },
                }}
                exit={{ opacity: 0 }}
              >
                {step.id + 1}
              </motion.span>
            )}
          </div>
          {/* label text */}
          <p
            className={`stepper__step-title ${
              step.id === currentStep && "purple--text"
            }`}
          >
            {step.title}
          </p>
        </div>
      ))}
    </div>
  );
};

export default ProductStepper;
