import { useMutation } from "react-query";
import { addRider, removeRider, updateRider } from "../../../services/riders";

export const useAddRiderMutation = () => {
  return useMutation((data) => addRider(data));
};

export const useUpdateRiderMutation = () => {
  return useMutation((data) => updateRider(data));
};

export const useDeleteRiderMutation = () => {
  return useMutation((data) => removeRider(data));
};
