import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import useTags from "./useTags";

const Tags = () => {
  const { isAddingTag, isFetchingTags, tags, setIsAddingTag } = useTags();

  return (
    <>
      <div className={globalStyles.pageColumnGaps}>
        <div>
          <h4 className="mb-0">Tags</h4>
          <p>A simple way to categorize and find your payments</p>
        </div>

        <secondaryComponents.NavigateWithPermission
          permissionParentKey={"Payments"}
          permissionChildKey="can_create_tags"
        >
          <primaryComponents.Button
            onClick={() => {
              setIsAddingTag(true);
            }}
            classNames={"ml-auto btn btn--primary"}
          >
            Add new tag
          </primaryComponents.Button>
        </secondaryComponents.NavigateWithPermission>

        <secondaryComponents.LoaderHelper
          classNames={"mt-5"}
          isLoading={isFetchingTags}
        >
          <secondaryComponents.TagsTable tags={tags} />
        </secondaryComponents.LoaderHelper>
      </div>

      <secondaryComponents.Modal
        isActive={isAddingTag}
        width={450}
        closeModal={() => setIsAddingTag(false)}
      >
        <secondaryComponents.AddTransactionsTag
          closeModal={() => setIsAddingTag(false)}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default Tags;
