import ApprovedTransfersTableLogic from "./ApprovedTransfersTableLogic";
import secondaryComponents from "..";
import Images from "../../../assets/images";
import styles from "./ApprovedTransfersTable.module.scss";

const ApprovedTransfersTable = ({
  statusType,
  metaDetails,
  gotoPage,
  transfers,
}) => {
  const { instance } = ApprovedTransfersTableLogic({
    statusType,
    transfers,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.transfersTable}>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <img
            width="250px"
            height="262px"
            src={Images.tnxEmptyState}
            alt="empty state"
          />
          <h4>You have no transfers</h4>
          <p>When you do, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>

      {/* pagination section */}
      {transfers?.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={metaDetails?.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails?.current_page}
        />
      )}
    </div>
  );
};
export default ApprovedTransfersTable;
