import { useState, useContext, useEffect } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  useCreateCustomerMutation,
  useEditCustomerMutation,
} from "../../../hooks/api/mutations/useCustomer";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";
import countriesStatesList from "../../../localdata/worldCountryAndState.json";
import citiesList from "../../../localdata/worldCities.json";

const useCustomerForm = ({
  customerInfo,
  handleFetchCustomers,
  isEditMode,
  closeWidget,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const queryParams = new URLSearchParams(window.location.search);
  const [currentStore, setCurrentStore] = useState(queryParams.get("store_id"));
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [country, setCountry] = useState("");
  const [state, setState] = useState([]);
  const [isState, setIsState] = useState([]);
  const [isCity, setIsCity] = useState([]);
  const [currency, setCurrency] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isLoyaltyEligible, setIsLoyaltyEligible] = useState(
    customerInfo?.is_loyalty_eligible ?? false,
  );

  const initialCustomerDetails = cleanObject({
    name: customerInfo?.full_name || "",
    phone_number: customerInfo?.phone || "",
    phone_code: customerInfo?.phone_code || business_phone_code || "",
    email: customerInfo?.email || "",
    country: customerInfo?.country || "",
    date_of_birth: customerInfo?.date_of_birth,
    state: customerInfo?.state || "",
    city: customerInfo?.city,
    store_id: customerInfo?.store?.id || "",
    is_loyalty_eligible: customerInfo?.is_loyalty_eligible ?? false,
  });

  const [customerDetails, setCustomerDetails] = useState(
    initialCustomerDetails,
  );

  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const createCustomerMutation = useCreateCustomerMutation();
  const editCustomerMutation = useEditCustomerMutation();

  const handleAddCustomer = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...customerDetails };
    createCustomerMutation.mutate(data, {
      onSuccess: () => {
        handleFetchCustomers();
        triggerToast("Customer added successfully", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // update customer properties
  const handleEditCustomer = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = cleanObject({ ...customerDetails });
    editCustomerMutation.mutate(
      { id: customerInfo?.id, data },
      {
        onSuccess: () => {
          handleFetchCustomers();
          triggerToast("Customer updated successfully", "success");
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  useEffect(() => {
    setIsLoyaltyEligible(customerDetails.is_loyalty_eligible);
  }, [customerDetails.is_loyalty_eligible]);

  return {
    isLoyaltyEligible,
    currentStore,
    setCurrentStore,
    isAddingCustomer: createCustomerMutation.isLoading,
    isEditingCustomer: editCustomerMutation.isLoading,
    customerDetails,
    setCustomerDetails,
    countriesStatesList,
    country,
    setCountry,
    currency,
    setCurrency,
    countryCode,
    storeList,
    setCountryCode,
    state,
    setState,
    isState,
    setIsState,
    citiesList,
    isCity,
    handleEditCustomer,
    handleAddCustomer,
    setIsCity,
  };
};

export default useCustomerForm;
