export const LocationIcon = () => {
  return (
    <svg
      width="10"
      height="14"
      viewBox="0 0 10 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5"
        cy="10.5"
        r="2.7"
        fill="white"
        stroke="#FE9A79"
        strokeWidth="0.6"
      />
      <path
        d="M2.0162 7.14365L5 10.5L7.9838 7.14365C10.2723 4.5694 8.44465 0.5 5 0.5C1.55534 0.5 -0.272309 4.5694 2.0162 7.14365Z"
        fill="#FD561F"
        stroke="#0B0C0E"
        strokeWidth="0.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 6C5.82843 6 6.5 5.32843 6.5 4.5C6.5 3.67157 5.82843 3 5 3C4.17157 3 3.5 3.67157 3.5 4.5C3.5 5.32843 4.17157 6 5 6Z"
        fill="white"
        stroke="#0B0C0E"
        strokeWidth="0.375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
