import { useState, useContext } from "react";
import { useStep } from "react-hooks-helper";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { sendReceipt } from "../../../services/transaction";
import { getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";

const SendReceiptLogic = ({
  closeWidget,
  transactionId,
  receiptType,
  orderDetails,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [customerPhoneCode, setCustomerPhoneCode] = useState("");
  const [isSendingReceipt, setIsSendingReceipt] = useState(false);
  const [sendReceiptOption, setSendReceiptOption] = useState("email");
  const { index, navigation } = useStep({ initialStep: 0, steps: 2 });
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleSendReceipt = (data) => {
    const { email, phone_number } = data;
    const recipient = sendReceiptOption === "email" ? email : phone_number;

    setIsSendingReceipt(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));

    if (sendReceiptOption === "email") {
      const receiptData = {
        medium: sendReceiptOption,
        send_to: recipient,
      };

      if (receiptType === "storeOrder") receiptData.order_id = transactionId;
      else receiptData.trx_id = transactionId;

      sendReceipt(receiptData, receiptType)
        .then(() => {
          setIsSendingReceipt(false);
          setRequestLoaderProgress(100);
          triggerToast("Receipt sent successfully", "success");
          closeWidget();
        })
        .catch(() => {
          setIsSendingReceipt(false);
          setRequestLoaderProgress(100);
        });
    } else {
      setIsSendingReceipt(false);
      setRequestLoaderProgress(100);

      const receipt_url = orderDetails?.receipt_url;
      const store_name = orderDetails?.store?.name;
      const encodedText = encodeURIComponent(`Dear customer,
Here is your receipt for the recent purchase from (${store_name}). Kindly click the link below to download your receipt.
${receipt_url}
`);
      const url = `https://wa.me/${`+${
        customerPhoneCode || business_phone_code
      }`}${phone_number}?text=${encodedText}`;
      window.open(url, "_blank");
    }
  };

  return {
    isSendingReceipt,
    step: index,
    navigation,
    handleSendReceipt,
    sendReceiptOption,
    setSendReceiptOption,
    setCustomerPhoneCode,
  };
};

export default SendReceiptLogic;
