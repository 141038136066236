export const PauseLocation = () => {
  return (
    <svg
      width="32"
      height="35"
      viewBox="0 0 32 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 17.6L13 14V28.4L7 32V17.6Z"
        fill="#FEF7EB"
        stroke="#0B0C0E"
        strokeWidth="0.412308"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9999 17.6L24.9999 14V28.4L18.9999 32V17.6Z"
        fill="#FEF7EB"
        stroke="#0B0C0E"
        strokeWidth="0.412308"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9999 17.6L12.9999 14V28.4L18.9999 32V17.6Z"
        fill="#FEF7EB"
        stroke="#0B0C0E"
        strokeWidth="0.412308"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.38435 27.0003C9.33649 27.6469 9.4701 26.0092 8.32929 24.5481C7.67656 23.2488 8.97588 22.5959 9.7767 22.8195C10.6772 22.8678 10.6562 24.944 11.4811 24.7173C12.3061 24.4907 12.6907 24.1981 12.6151 23.9231"
        stroke="#C5C5C5"
        strokeWidth="0.285178"
        strokeLinecap="round"
        strokeDasharray="0.57 0.57"
      />
      <path
        d="M7.69189 18.0767C8.92256 19.3076 9.23046 22.9996 11.3842 21.4613C13.5379 19.9229 10.7688 18.692 9.53805 20.2305C8.30728 21.769 8.61497 27.3074 12.615 27.9228"
        stroke="#C5C5C5"
        strokeWidth="0.283077"
        strokeLinecap="round"
        strokeDasharray="0.57 0.57"
      />
      <path
        d="M12.3072 15.3076C11.0763 17.5231 11.2815 15.3076 10.1533 19.6153"
        stroke="#C5C5C5"
        strokeWidth="0.283077"
        strokeLinecap="round"
        strokeDasharray="0.57 0.57"
      />
      <path
        d="M18.4613 19.6156C16.5126 20.5386 13.046 23.1847 14.769 26.3848C16.492 29.5849 17.7433 30.59 18.1535 30.6925M18.4613 27.0002C17.9689 26.5079 14.7689 19.6156 13.2305 16.231"
        stroke="#C5C5C5"
        strokeWidth="0.283077"
        strokeLinecap="round"
        strokeDasharray="0.57 0.57"
      />
      <path
        d="M24 16.5386C23.0768 18.0772 23.077 20.8461 21.5385 20.2309C19.9999 19.6156 20.3077 17.154 21.8462 18.077C23.3846 19.0001 24.6155 20.8463 24 24.2309C23.5076 26.9386 21.3333 29.2565 20.3076 30.077"
        stroke="#C5C5C5"
        strokeWidth="0.283077"
        strokeLinecap="round"
        strokeDasharray="0.57 0.57"
      />
      <path
        d="M19.3848 29.1536C20.3077 27.6153 20.0003 19.615 20.0002 18.9997C20 18.3845 20.6155 16.2305 22.7694 16.5382"
        stroke="#C5C5C5"
        strokeWidth="0.283077"
        strokeLinecap="round"
        strokeDasharray="0.57 0.57"
      />
      <path
        d="M10.0325 15.2873L16.0001 22L21.9677 15.2873C26.5447 10.1388 22.8894 2 16.0001 2C9.11081 2 5.4555 10.1388 10.0325 15.2873Z"
        fill="#FD561F"
        stroke="#FEBBA5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 13C17.6569 13 19 11.6569 19 10C19 8.34315 17.6569 7 16 7C14.3431 7 13 8.34315 13 10C13 11.6569 14.3431 13 16 13Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
