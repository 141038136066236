import { useState } from "react";
import {
  useActiveSubscriptionQuery,
  useSubscriptionTiersQuery,
} from "../../../../../hooks/api/queries";

const useSubscriptionPlans = (callActiveSubscription = true) => {
  const [isPayModal, setIsPayModal] = useState(false);
  const [upgradeDetails, setUpgradeDetails] = useState(null);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("ONE_YEAR");
  const { isLoading: isFetchingSubscriptionsTiers, data: subscriptionTiers } =
    useSubscriptionTiersQuery();
  const { isLoading: isFetchingActiveSUbscription, data: activeSubscription } =
    useActiveSubscriptionQuery(callActiveSubscription);

  return {
    isLoading: isFetchingActiveSUbscription || isFetchingSubscriptionsTiers,
    subscriptionTiers: subscriptionTiers || [],
    subscriptionPeriod,
    isPayModal,
    upgradeDetails,
    activeSubscription,
    setUpgradeDetails,
    setIsPayModal,
    setSubscriptionPeriod,
  };
};
export default useSubscriptionPlans;
