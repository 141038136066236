import primaryComponents from "../../primaryComponents";
import styles from "./CreateExpenseCategory.module.scss";
import Validation from "./Validation";
import useCreateExpenseCategory from "./useCreateExpenseCategory";
import { Controller } from "react-hook-form";

const CreateExpenseCategory = ({
  handleFetchCategories,
  closeWidget,
  categoryDetails = null,
  isEditMode = false,
}) => {
  const {
    isUpdatingCategory,
    isCreatingCategory,
    categoryName,
    setCategoryName,
    handleAddExpenseCategory,
  } = useCreateExpenseCategory({
    handleFetchCategories,
    closeWidget,
    categoryDetails,
    isEditMode,
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = Validation({ categoryName });

  return (
    <div className={styles.createExpenseCategory}>
      <h4 className="mb-4 text-center">
        {!isEditMode ? "Add new expense category" : "Edit expense category"}
      </h4>
      <form
        className={styles.createExpenseCategory__form}
        onSubmit={handleSubmit(handleAddExpenseCategory)}
      >
        <Controller
          name="categoryName"
          control={control}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Category Name"
              isRequired
              placeholder="Enter your category name"
              type="text"
              errorMessage={errors.categoryName}
              value={categoryName}
              onKeyUp={() =>
                errors.categoryName !== undefined && trigger("categoryName")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setCategoryName(value);
              }}
            />
          )}
        />
        <div className={styles.createExpenseCategory__bottom}>
          <primaryComponents.Button
            classNames="btn btn--outline"
            type="button"
            onClick={closeWidget}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames="btn btn--primary"
            type="submit"
            isLoading={isCreatingCategory || isUpdatingCategory}
            isDisabled={isCreatingCategory || isUpdatingCategory}
            loadingText={!isEditMode ? "Creating..." : "Updating..."}
          >
            {!isEditMode ? "Add new expense category" : "Update category"}
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};
export default CreateExpenseCategory;
