import primaryComponents from "../../primaryComponents";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { useDeleteProductTrackItem } from "../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";

const RemoveTrackItem = ({ selectedItem, closeModal }) => {
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useDeleteProductTrackItem();
  const deleteItemTrackNumber = () => {
    mutate(
      { id: selectedItem?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getTrackItems"]);
          closeModal();
        },
      },
    );
  };
  return (
    <div className="d-flex flex-column p-3" style={{ gap: "15px" }}>
      <h5 className="text-center">Are you sure you want to delete this item</h5>

      <div className={globalStyles.cardWithBackground}>
        <p>
          Product name:{" "}
          <span className="dark--text">{selectedItem?.name || "N/A"}</span>
        </p>
        <p>
          Tracking number:{" "}
          <span className="dark--text">{selectedItem?.item_number}</span>
        </p>
      </div>

      <div className="d-flex justify-content-between mt-4">
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--danger"}
          isDisabled={isLoading}
          isLoading={isLoading}
          loadingText={"Deleting..."}
          onClick={() => {
            deleteItemTrackNumber();
          }}
        >
          Delete
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default RemoveTrackItem;
