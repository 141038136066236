import React, { useEffect, useRef, useState } from "react";
import styles from "./MainDashboardDropdown.module.scss";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";
import secondaryComponents from "../../../components/secondaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../primaryComponents";

const MainDasboardDropdown = ({ openModal }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleModal = (key) => {
    openModal(key);
    setShowDropdown(false);
  };
  const navigate = useNavigate();
  const quickMenu = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!quickMenu.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div style={{ position: "relative", zIndex: 1 }} ref={quickMenu}>
      <primaryComponents.Button
        onClick={() => setShowDropdown(!showDropdown)}
        classNames="btn btn--outline"
      >
        <FontAwesomeIcon icon="angle-down" style={{ fontSize: "18px" }} />
      </primaryComponents.Button>
      {showDropdown ? (
        <motion.div
          initial={{ y: -10 }}
          animate={{ y: 0 }}
          className={styles.dropdown}
        >
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Inventory Management"}
            permissionChildKey="can_restock_products_bulk_and_single_restock"
          >
            <p
              className="dark--text"
              onClick={() =>
                navigate(pathConstants.INVENTORY_PRODUCTS_BULK_RESTOCK)
              }
            >
              Restock
            </p>
          </secondaryComponents.NavigateWithPermission>
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Inventory Management"}
            permissionChildKey="can_generate_end_of_day"
          >
            <p className="dark--text" onClick={() => toggleModal("isEndofday")}>
              End of Day
            </p>
          </secondaryComponents.NavigateWithPermission>
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Inventory Management"}
            permissionChildKey="can_create_and_submit_reconciliation"
          >
            <p
              className="dark--text"
              onClick={() => navigate(pathConstants.INVENTORY_RECONCILIATIONS)}
            >
              Reconcile Stock
            </p>
          </secondaryComponents.NavigateWithPermission>
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Inventory Management"}
            permissionChildKey="can_return_products"
          >
            <p
              className="dark--text"
              onClick={() => toggleModal("isReturnProduct")}
            >
              Return Product{" "}
            </p>
          </secondaryComponents.NavigateWithPermission>
          <Link
            to={pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY}
            style={{ textDecoration: "none" }}
          >
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_transfer_product"
            >
              <p
                className="dark--text"
                //onClick={() => toggleModal("isTransferProduct")}
              >
                Transfer Products
              </p>
            </secondaryComponents.NavigateWithPermission>
          </Link>
        </motion.div>
      ) : null}
    </div>
  );
};

export default MainDasboardDropdown;
