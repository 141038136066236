import primaryComponents from "../../primaryComponents";
import styles from "./ReconcilePrompt.module.scss";
import Validation from "./Validation";
import useReconcilePrompt from "./useReconcilePrompt";

const ReconcilePrompt = ({ closeModal, handleFetchReconciliations }) => {
  const {
    isLoading,
    reconsileDetails,
    storeList,
    setReconsileDetails,
    reconcile,
  } = useReconcilePrompt({ handleFetchReconciliations, closeModal });

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = Validation({ reconsileDetails });

  return (
    <form className={styles.reconcilePrompt} onSubmit={handleSubmit(reconcile)}>
      <h4 className="text-center">Reconcile Your Store&apos;s Stock</h4>

      <primaryComponents.SelectFieldV2
        control={control}
        name="store"
        label="Store"
        isRequired
        options={storeList}
        idKey="id"
        nameKey="name"
        onChange={(value) =>
          setReconsileDetails((prevState) => ({
            ...prevState,
            store: value,
          }))
        }
        value={
          reconsileDetails?.store ? reconsileDetails?.store : "Select stores"
        }
        isSearchable={false}
        errorMessage={errors.store}
      />

      <div className="d-flex justify-content-between mt-3">
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={closeModal}
          type="button"
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--primary"
          type="submit"
          isDisabled={isLoading}
          isLoading={isLoading}
          loadingText="Starting..."
        >
          Reconcile
        </primaryComponents.Button>
      </div>
    </form>
  );
};
export default ReconcilePrompt;
