import { useMutation } from "react-query";
import { setUpGuideMarkAsCompleted } from "../../../services/dashboard";
import { useQueryClient } from "react-query";

export const useSetUpGuideMarkAsCompleted = () => {
  const queryCache = useQueryClient();
  return useMutation(setUpGuideMarkAsCompleted, {
    onSuccess: (data) => {
      queryCache.invalidateQueries("setupGuideCategories");
    },
  });
};
