import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const storeSchema = yup
  .object({
    storeName: yup.string().required("Store name is required"),
    storeAddress: yup.string().required("Store address is required"),
    settlementAccount: yup.string().required("Select settlement account"),
    isWarehouse: yup.boolean().required("Field is required"),
    storeCategory: yup.string().required("Select store category"),
  })
  .required("All fields are required");

const StoresTableModalValidation = ({
  storeName,
  storeAddress,
  settlementAccount,
  isWarehouse,
  storeCategory,
}) => {
  return useForm({
    resolver: yupResolver(storeSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      storeName,
      storeAddress,
      settlementAccount,
      isWarehouse,
      storeCategory,
    },
  });
};

export default StoresTableModalValidation;
