import { useContext, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { warehouseCheckout } from "../../../../services/sales-point";

const WarehouseCheckoutPaymentLogic = ({ closePaymentSession }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);

  const payViaWarehouseCheckout = () => {
    setIsConfirmingPayment(true);
    warehouseCheckout({ order_id: orderId })
      .then(() => {
        setIsConfirmingPayment(false);
        triggerToast("Payment confirmation successful", "success");
        closePaymentSession();
      })
      .catch((error) => {
        setIsConfirmingPayment(false);
        if (error?.response === undefined) return;
      });
  };

  return {
    isConfirmingPayment,
    payViaWarehouseCheckout,
  };
};
export default WarehouseCheckoutPaymentLogic;
