import { useState } from "react";
import { useMutation } from "react-query";
import { createOrderTable } from "../../../services/inventory";
import { useSelector } from "react-redux";

export const useAddOrderTable = ({ closeModal, handleFetchOrderTables }) => {
  const [formData, setFormData] = useState({
    name: "",
    store: "",
    seat_capacity: "",
  });
  const orderTableMutation = useMutation(["addOrderTable"], createOrderTable);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const handleAddOrderTable = () => {
    const { name, store, seat_capacity } = formData;
    orderTableMutation.mutate(
      { name, store: store, seat_capacity },
      {
        onSuccess: () => {
          handleFetchOrderTables();
          closeModal();
        },
      },
    );
  };

  return {
    formData,
    isAddingOrderTable: orderTableMutation.isLoading,
    storeList,
    setFormData,
    handleAddOrderTable,
  };
};
