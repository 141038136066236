import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableDetails: null,
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    setTableDetails: (state, action) => {
      state.tableDetails = action.payload;
    },
  },
});

export const { setTableDetails } = tableSlice.actions;
