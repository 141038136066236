import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetTransfers } from "../../../../hooks/api/queries/useTransfers";

const useAllTransfers = () => {
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();

  const {
    isLoading: isFetchingTransfers,
    data,
    refetch: handleFetchTransfers,
  } = useGetTransfers({ queries });

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const filterByDate = (data) => {
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    setSearch(search, { replace: true });
  };

  const searchTransfer = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    search,
    transfers: data?.data || [],
    metaDetails: data?.meta || {},
    isFetchingTransfers,
    searchTransfer,
    filterByDate,
    gotoPage,
    handleFetchTransfers,
  };
};
export default useAllTransfers;
