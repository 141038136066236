import {
  getAccountingBanksList,
  getAccountingBanksListWithSpreadFilter,
  getBankDetails,
  getBankTransactions,
} from "../../../services/accounting";
import { useQuery } from "react-query";

export const useGetAccountingBanksList = ({ queries }) => {
  return useQuery(
    ["getAccountingBanksList", queries],
    () => getAccountingBanksList(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetAccountingBanksListWithSpreadFilter = (queryParams = {}) => {
  return useQuery(
    ["getAccountingBanksListWithSpreadFilter"],
    () =>
      getAccountingBanksListWithSpreadFilter(queryParams).then(
        (res) => res?.data,
      ),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetAccountingBankDetails = (bankId) => {
  return useQuery(
    ["getBankDetails", bankId],
    () => getBankDetails(bankId).then((res) => res?.data?.data),
    {
      enabled: !!bankId,
      staleTime: Infinity,
    },
  );
};

export const useGetBankTransactionList = ({ queries }) => {
  return useQuery(
    ["getBankTransactionList", queries],
    () => getBankTransactions(queries).then((res) => res?.data),
    {
      //staleTime: Infinity,
    },
  );
};
