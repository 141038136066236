import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const campaignSchema = yup
  .object({
    campaign_name: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Campaign name is required"),
    brand_name: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Brand name is required"),
    description: yup.string().max(500, "Maximum of 500 characters is allowed"),
    brand_logo_url: yup.string().required("Brand logo is required"),
    business_slug: yup
      .string()
      .min(3, "Minimum of 3 characters allowed")
      .max(25, "Maximum of 25 characters allowed")
      .required("slug is required"),
  })
  .required();

export const useValidation = ({ campaignDetails }) => {
  return useForm({
    resolver: yupResolver(campaignSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      ...campaignDetails,
    },
  });
};
