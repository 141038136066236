import { useState } from "react";
import "./Switch.scss";

const Switch = ({ isChecked = false, name, id, ...props }) => {
  const [checked, setChecked] = useState(isChecked);
  const onChange = () => {
    setChecked(!checked);
    props.onChange(!checked);
  };
  return (
    <span className="custom-switch custom-switch--rounded">
      <input
        {...props}
        type="checkbox"
        defaultChecked={checked}
        name={name}
        onChange={onChange}
        id={id}
      />
      <label className="custom-switch__control" htmlFor={id}></label>
    </span>
  );
};
export default Switch;
