import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useStoreCurrency = () => {
  const { store_id } = useParams();
  const { currency_code, currency_symbol } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamId = queryParams.get("store_id");

  const [storeCurrency, setStoreCurrency] = useState("NGN");
  const [storeCurrencySymbol, setStoreCurrencySymbol] = useState("₦");

  useEffect(() => {
    if (store_id) {
      setStoreCurrency(
        storeList.find((store) => `${store?.id}` === store_id)?.currency_code,
      );

      setStoreCurrencySymbol(
        storeList.find((store) => `${store?.id}` === store_id)?.currency_symbol,
      );
    } else if (queryParamId) {
      setStoreCurrency(
        storeList.find((store) => `${store?.id}` === queryParamId)
          ?.currency_code,
      );

      setStoreCurrencySymbol(
        storeList.find((store) => `${store?.id}` === queryParamId)
          ?.currency_symbol,
      );
    } else {
      setStoreCurrency(currency_code);

      setStoreCurrencySymbol(currency_symbol);
    }
  }, [storeList, store_id, queryParamId, currency_code, currency_symbol]);

  function hasMultipleCurrency(arr) {
    const currencySet = new Set(arr?.map((item) => item?.currency_code));

    return currencySet.size > 1;
  }

  return {
    storeHasMultipleCurrency: hasMultipleCurrency(storeList),
    store_currency: storeCurrency,
    store_currency_symbol: storeCurrencySymbol,
  };
};
