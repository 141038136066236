import SubscriptionFeatureCollapse from "../SubscriptionFeatureCollapse";
import styles from "./SubscriptionFeatureTable.module.scss";
// import { motion } from "framer-motion";

const SubscriptionFeatureTable = ({
  children,
  title = "",
  isActive,
  toggleView,
}) => {
  return (
    <div className={styles.subscriptionFeatureTable}>
      <SubscriptionFeatureCollapse
        isActive={isActive}
        toggleView={toggleView}
      />
      <div className={styles.header}>
        <p className={`${styles.title} dark--text font-weight-bold`}>
          {title?.replace("_", " ")}
        </p>
        {isActive ? (
          <>
            <p>Free</p>
            <p className="pending--text">Essential</p>
            <p className="success--text">Growth</p>
            <p className="purple--text">Scale</p>
          </>
        ) : null}
      </div>
      {isActive ? <div>{children}</div> : null}
    </div>
  );
};
export default SubscriptionFeatureTable;
