import { useState, useEffect } from "react";
import {
  useGetAccountingBankDetails,
  useGetAccountingBanksList,
} from "../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../helpers";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const useAllAccounts = () => {
  const [showAddAccounting, setShowAddAccounting] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [search, setSearch] = useSearchParams();

  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const queries = getUrlQuerysection();
  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: handleFetchBankAccountsList,
  } = useGetAccountingBanksList({ queries });

  const { isLoading: isFetchingBankInfo, data: bankInfo } =
    useGetAccountingBankDetails(selectedBank);

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (bankAccount?.data?.length > 0 && !selectedBank) {
      setSelectedBank(bankAccount?.data[0]?.id);
    }
  });

  return {
    bankInfo,
    storeList,
    bankAccount: bankAccount?.data || [],
    metaDetails: bankAccount?.meta,
    gotoPage,
    setSelectedBank,
    showAddAccounting,
    isFetchingBankInfo,
    setShowAddAccounting,
    isLoadingBankAccountsList,
    handleFetchBankAccountsList,
  };
};

export default useAllAccounts;
