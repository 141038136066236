export const ExpenseIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9 2.67341L9.18032 2.55621L6.30157 2.08742C5.66562 1.98386 5.06612 2.41543 4.96256 3.0514L3.27492 13.4149L3.06396 14.7103"
        stroke={strokeColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9 3.7229L14.646 2.97858L12.1378 1.48996C11.5837 1.16111 10.8679 1.34367 10.5391 1.89778L5.18001 10.9272L2.50049 15.4419"
        stroke={strokeColor}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.32381 17.5745C0.907937 17.0823 0.969772 16.3462 1.46192 15.9303L17.5021 2.37623C17.9942 1.96034 18.7303 2.02219 19.1462 2.51434L26.6762 11.4255C27.0921 11.9177 27.0303 12.6538 26.5381 13.0697L10.498 26.6238C10.0058 27.0396 9.26976 26.9778 8.85387 26.4857L1.32381 17.5745Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5062 16.2823C16.4905 15.4505 16.6141 13.9783 15.7824 12.994C14.9507 12.0097 13.4785 11.8861 12.4942 12.7178C11.5098 13.5496 11.3862 15.0218 12.2179 16.0061C13.0497 16.9904 14.5219 17.114 15.5062 16.2823Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.27919 21.0432L8.44136 20.1377L8.16656 20.392L7.95792 20.1665L8.23272 19.9123L7.9654 19.6234L7.6906 19.8776L7.48196 19.6522L7.75676 19.3979L6.95153 18.5277L7.31793 18.1886L8.45785 18.7491L8.87358 18.3644L8.06835 17.4942L8.34668 17.2367L9.1519 18.1069L9.4267 17.8526L9.63535 18.0781L9.36055 18.3324L9.62787 18.6213L9.90267 18.367L10.1113 18.5925L9.83651 18.8468L10.6743 19.7522L10.3044 20.0945L9.13189 19.4988L8.71617 19.8834L9.55399 20.7889L9.27919 21.0432ZM8.50752 19.658L8.83869 19.3515L8.46568 19.1604L8.23315 19.3756L8.50752 19.658ZM9.93635 19.513L9.95044 19.4999L9.56171 19.101L9.41374 19.238L9.93635 19.513ZM8.02451 19.1501L8.17248 19.0132L7.62027 18.7133L7.60618 18.7263L8.02451 19.1501ZM9.12054 19.0907L9.35659 18.8723L9.08222 18.5899L8.74753 18.8996L9.12054 19.0907Z"
        fill="#15112D"
      />
      <path
        d="M20.5902 11.2904L17.6746 8.3748L20.5902 11.2904Z"
        fill="#300F88"
      />
      <path
        d="M19.2782 11.7277L20.5173 10.4886C20.7991 10.2068 20.7991 9.74994 20.5173 9.46814C20.2355 9.18635 19.7786 9.18635 19.4968 9.46814L18.7679 10.197C18.4861 10.4788 18.0293 10.4788 17.7475 10.197C17.4657 9.91525 17.4657 9.45838 17.7475 9.17659L18.9866 7.93746M20.5902 11.2904L17.6746 8.3748"
        stroke={strokeColor}
        strokeWidth="0.412326"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
