import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { getCustomersNames } from "../../../services/customer";

const useCustomerSearchField = ({ customerValue }) => {
  const [searchValue, setSearchValue] = useState(customerValue);
  const [isEnableSearch, setIsEnableSearch] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);

  const serachRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (serachRef.current && !serachRef.current.contains(event.target))
        setIsDropDown(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [serachRef]);

  const { data, isLoading } = useQuery(
    ["getCustomersNames", searchValue],
    () => getCustomersNames(searchValue).then((res) => res.data?.data),
    {
      enabled: isEnableSearch,
    },
  );

  return {
    serachRef,
    isLoading,
    searchValue,
    isEnableSearch,
    customersNames: data || [],
    isDropDown,
    setIsDropDown,
    setIsEnableSearch,
    setSearchValue,
  };
};
export default useCustomerSearchField;
