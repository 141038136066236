import { useTable } from "react-table";
import { useMemo } from "react";

const useLoyaltyCustomerTransactionTable = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "-",
      },
      {
        Header: "Gift Card",
        accessor: "description",
        Cell: ({ cell: { value } }) => (
          <span style={{ color: "19ae57" }}>{value ? value : "N/A"}</span>
        ),
      },
      // {
      //   Header: "Phone Number",
      //   accessor: "customer_phone",
      //   Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      // },
      {
        Header: "Points",
        accessor: "points",
        Cell: ({ cell: { value } }) =>
          value ? value?.toLocaleString("en-US") : 0.0,
      },
      {
        Header: "Status",
        accessor: "giftcard_status",
        Cell: ({ cell: { value, row } }) => (
          <span
            className={`${
              row.original["type"] === "credit"
                ? "success--squared"
                : value === "UNUSED"
                  ? "pending--squared"
                  : "failed--text"
            }`}
          >
            {row.original["type"] === "credit"
              ? "Earned"
              : value === "UNUSED"
                ? "Purchased"
                : "Redeemed"}
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: data });
  return {
    instance,
    customers: data,
  };
};

export default useLoyaltyCustomerTransactionTable;
