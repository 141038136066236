export const DocumentationIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.33341 6.5H15.6667H6.33341ZM6.33341 11.1667H15.6667H6.33341ZM6.33341 15.8333H9.83341H6.33341ZM4.00008 25.1667H18.0001C19.2888 25.1667 20.3334 24.122 20.3334 22.8333V4.16666C20.3334 2.878 19.2888 1.83333 18.0001 1.83333H4.00008C2.71142 1.83333 1.66675 2.878 1.66675 4.16666V22.8333C1.66675 24.122 2.71142 25.1667 4.00008 25.1667Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
