import TagsTableLogic from "./TagsTableLogic";
import secondaryComponents from "../../secondaryComponents";
import Images from "../../../assets/images";

const TagsTable = ({ tags }) => {
  const {
    instance,
    isDeleteTag,
    isEditTag,
    tagDetails,
    setIsEditTag,
    setIsDeleteTag,
  } = TagsTableLogic({
    tags,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          hasHeader={false}
        >
          <div className="text-center mt-5 mb-5">
            <img
              width="250px"
              height="262px"
              src={Images.tagEmptyState}
              alt="empty state"
            />
            <h4>No data available</h4>
          </div>
        </secondaryComponents.TableHelper>
      </div>

      <secondaryComponents.Modal
        isActive={isEditTag}
        width={450}
        closeModal={() => setIsEditTag(false)}
      >
        <secondaryComponents.AddTransactionsTag
          closeModal={() => setIsEditTag(false)}
          tagDetails={tagDetails}
          isEditMode={true}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        isActive={isDeleteTag}
        width={450}
        closeModal={() => setIsDeleteTag(false)}
      >
        <secondaryComponents.DeleteTagDialog
          closeModal={() => setIsDeleteTag(false)}
          tagDetails={tagDetails}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default TagsTable;
