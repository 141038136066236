import { useContext, useEffect } from "react";
import Icon from "../../../../../../../../../../../assets/icons";
import styles from "./styles.module.scss";
import primaryComponents from "../../../../../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../../../../../components/secondaryComponents";
import { Validation } from "../Validation";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastContext } from "../../../../../../../../../../../hooks/context";
import { truncateString } from "../../../../../../../../../../../helpers";
import { usePaymentSetup } from "../usePaymentSetup";

const StripeSetup = ({ paymentId, setShowSetupStripe }) => {
  const triggerToast = useContext(ToastContext);
  const {
    isLoading,
    isLoadingPaymentGateway,
    isLoadingPayWithStripe,
    paymentGateway,
    dataCreatePaymentGateway,
    isLoadingEditPaymentGateway,
    stripeSecretKey,
    showYouTubeModal,
    setShowYouTubeModal,
    setStripeSecretKey,
    setSaveAndActivate,
    handleSave,
  } = usePaymentSetup({ paymentId, setShowSetupStripe });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
  } = Validation();

  useEffect(() => {
    if (paymentGateway) {
      setValue("key", paymentGateway.secret_key);
    }
  }, [paymentGateway]);

  return (
    <secondaryComponents.LoaderHelper
      isLoading={isLoadingPaymentGateway}
      classNames="mt-4"
    >
      <div className={styles.container}>
        <div className={styles.backContainer}>
          <div
            onClick={() => {
              setShowSetupStripe(null);
            }}
            className={styles.back}
          >
            <img src={Icon.chevron_left_arrow} alt="" />
          </div>
          <div className={styles.backBtnContainer}>
            <primaryComponents.Button
              classNames={`btn ${
                paymentGateway ? "btn--outline" : "btn--primary"
              } mt-auto`}
              onClick={handleSubmit(handleSave)}
              loadingText={"Saving..."}
              isDisabled={
                isLoading ||
                isLoadingEditPaymentGateway ||
                paymentGateway ||
                isLoadingPayWithStripe
                  ? !isDirty
                  : false
              }
              isLoading={
                isLoading ||
                isLoadingEditPaymentGateway ||
                isLoadingPayWithStripe
              }
              showSpinner={false}
            >
              {paymentGateway ? (
                <>
                  <span>Save</span>
                </>
              ) : (
                <>
                  <span>Generate Webhook Url</span>
                </>
              )}
            </primaryComponents.Button>
            {paymentGateway && (
              <primaryComponents.Button
                classNames="btn btn--primary mt-auto"
                onClick={() => {
                  setSaveAndActivate(true);
                  handleSubmit(handleSave)();
                }}
                loadingText={"Saving..."}
                isDisabled={
                  isLoading ||
                  isLoadingEditPaymentGateway ||
                  paymentGateway ||
                  isLoadingPayWithStripe
                    ? !isDirty
                    : false
                }
                isLoading={
                  isLoading ||
                  isLoadingEditPaymentGateway ||
                  isLoadingPayWithStripe
                }
              >
                <>
                  {/* <img className="mr-2" src={Icon.saveIconAlt} alt="" /> */}
                  <span>Save & Activate</span>
                </>
              </primaryComponents.Button>
            )}
          </div>
        </div>
        <div className={styles.headingContainer}>
          <h3>Set Up Stripe</h3>
          <p>
            Please provide the following details to set up your paystack payment
            gateway.
          </p>
        </div>

        <div className={styles.mainContainer}>
          <div className={styles.contentDescription}>
            <div className={styles.howTo}>
              <p className={styles.setupTitle}>How to find your secret key:</p>
              <div className={styles.keySetupSteps}>
                <div className={styles.step}>
                  <img src={Icon.keysetupone} alt="" />
                  <p>
                    Create account on{" "}
                    <a
                      href="http://stripe.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>https://stripe.com</span>
                    </a>
                  </p>
                </div>
                <div className={styles.step}>
                  <img src={Icon.keysetuptwo} alt="" />
                  <p>Login to your Stripe dashboard</p>
                </div>
                <div className={styles.step}>
                  <img src={Icon.keysetupthree} alt="" />
                  <p>
                    Goto Developer page and copy your secret key on the API Key
                    section
                  </p>
                </div>
                <div className={styles.step}>
                  <img src={Icon.keysetupfour} alt="" />
                  <p>Paste your secret key in the above key</p>
                </div>
                <div className={styles.step}>
                  <img src={Icon.keysetupfive} alt="" />
                  <p>
                    Generate and copy your webhook url and paste it on Stripe
                    platform and select the following event
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.video}>
              <div className={styles.heading}>
                <p className={styles.title}>
                  Don’t Have a Stripe Secret Key?{" "}
                  <span className={styles.paystackLink}>
                    Visit{" "}
                    <a
                      href="http://stripe.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Stripe.com</span>
                    </a>
                    <img src={Icon.link} alt="" />
                  </span>
                </p>
                <div className={styles.videoContainer}>
                  <p
                    onClick={() => setShowYouTubeModal(true)}
                    className={styles.videoTopText}
                  >
                    Alternatively, you can watch this short explainer video
                    below to access your secret key.
                  </p>
                  <div
                    onClick={() => setShowYouTubeModal(true)}
                    className={styles.video}
                  >
                    <iframe
                      width="100%"
                      height="100%"
                      src={"https://www.youtube.com/embed/4fogq7gsBmE"}
                      title={"Lumi Merchant Stripe Setup"}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                    <div
                      className={styles.overlay}
                      onClick={() => setShowYouTubeModal(true)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.keyMainContainer}>
            <div className={styles.keyContainer}>
              <div className={styles.secretHeading}>
                <p className={styles.title}>
                  Stripe Secret Key <span>(Required)</span>
                </p>
                <p className={styles.description}>
                  Input your Stripe Secret Key below to set up your payment
                  processes with one single click.
                </p>
              </div>
              <div className={styles.inputContainer}>
                <primaryComponents.InputFieldV2
                  control={control}
                  name="key"
                  value={stripeSecretKey}
                  classNames="white"
                  label={""}
                  placeholder={"Enter your stripe secret key here"}
                  onChange={({ target: { value } }) =>
                    setStripeSecretKey(value)
                  }
                  errorMessage={errors.key}
                />
              </div>
            </div>
            <div className={styles.keyContainer}>
              <div className={styles.secretHeading}>
                <p className={styles.title}>Your Unique Webhook URL</p>
                <p className={styles.description}>
                  Log into your Stripe account and go to Settings{">"}API Keys &
                  Webhooks then paste in your unique webhook url.{" "}
                  <a
                    href="https://dashboard.stripe.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Learn more</span>
                  </a>
                </p>
              </div>
              {(paymentGateway || dataCreatePaymentGateway) && (
                <div className={styles.webhookContainer}>
                  <span>
                    {paymentGateway || dataCreatePaymentGateway
                      ? truncateString(
                          `${
                            paymentGateway?.payment_gateway_webhook_url ||
                            dataCreatePaymentGateway?.data?.data
                              ?.payment_gateway_webhook_url
                          }`,
                          30,
                        )
                      : "http://webhookurl.com"}
                  </span>

                  <div>
                    <CopyToClipboard
                      text={
                        paymentGateway || dataCreatePaymentGateway
                          ? `${
                              paymentGateway?.payment_gateway_webhook_url ||
                              dataCreatePaymentGateway?.data?.data
                                ?.payment_gateway_webhook_url
                            }`
                          : ""
                      }
                      onCopy={() => triggerToast("Copied", "success")}
                    >
                      <div className={styles.copyButton}>
                        <img src={Icon.copy} alt="" />
                        <span>Copy URL</span>
                      </div>
                    </CopyToClipboard>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={styles.saveBtnContainer}>
          <primaryComponents.Button
            classNames={`btn ${
              paymentGateway ? "btn--outline" : "btn--primary"
            } mt-auto`}
            onClick={handleSubmit(handleSave)}
            loadingText={"Saving..."}
            isDisabled={
              isLoading ||
              isLoadingEditPaymentGateway ||
              paymentGateway ||
              isLoadingPayWithStripe
                ? !isDirty
                : false
            }
            isLoading={
              isLoading || isLoadingEditPaymentGateway || isLoadingPayWithStripe
            }
            showSpinner={false}
          >
            {paymentGateway ? (
              <>
                <span>Save</span>
              </>
            ) : (
              <>
                <span>Generate Webhook Url</span>
              </>
            )}
          </primaryComponents.Button>
          {paymentGateway && (
            <primaryComponents.Button
              classNames="btn btn--primary mt-auto"
              onClick={() => {
                setSaveAndActivate(true);
                handleSubmit(handleSave)();
              }}
              loadingText={"Saving..."}
              isDisabled={
                isLoading ||
                isLoadingEditPaymentGateway ||
                paymentGateway ||
                isLoadingPayWithStripe
                  ? !isDirty
                  : false
              }
              isLoading={
                isLoading ||
                isLoadingEditPaymentGateway ||
                isLoadingPayWithStripe
              }
            >
              <>
                {/* <img className="mr-2" src={Icon.saveIconAlt} alt="" /> */}
                <span>Save & Activate</span>
              </>
            </primaryComponents.Button>
          )}
        </div>

        <secondaryComponents.Modal
          width={"850px"}
          isActive={showYouTubeModal}
          closeModal={() => setShowYouTubeModal(false)}
        >
          <div className="px-2">
            <div
              className={"d-flex justify-content-end align-items-center mb-3"}
            >
              <primaryComponents.Button
                classNames="btn btn--outline smallBtn"
                type="button"
                onClick={() => setShowYouTubeModal(false)}
              >
                Close
              </primaryComponents.Button>
            </div>
            <iframe
              width="100%"
              height="500px"
              src={"https://www.youtube.com/embed/4fogq7gsBmE"}
              title={"Lumi Merchant Stripe Setup"}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </secondaryComponents.Modal>
      </div>
    </secondaryComponents.LoaderHelper>
  );
};

export default StripeSetup;
