import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TransferArrowIcon } from "../../../../../assets/svgIcons";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import useValidation from "../CreateTransfer/useValidation";
import { useEditStockTransfer } from "./useEditStockTransfer";
import { useEffect } from "react";

const EditStockTransfer = () => {
  const navigate = useNavigate();
  const {
    transferData,
    isLoading,
    isTransferUpdating,
    autoScrollRef,
    isFetchingNextPage,
    otherTransferInfo,
    updateTransferProducts,
    setTransferData,
  } = useEditStockTransfer();

  const validation = useValidation({
    transferData,
  });
  const { handleSubmit, setValue } = validation;
  useEffect(() => {
    setValue("transferData", transferData);
  }, [transferData]);

  useEffect(() => {
    setValue(
      "transfer_from_store_id",
      otherTransferInfo?.transfer_from_store_id,
    );
    setValue("transfer_to_store_id", otherTransferInfo?.transfer_to_store_id);
  }, [otherTransferInfo]);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Stock Transfer"
      description="Edit stock transfer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          className={globalStyles.pageColumnGaps}
        >
          <div className={globalStyles.pageHeaders}>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
              <h4 className="ml-3 mb-0">{"Transfer Stock"}</h4>
            </div>

            <primaryComponents.Button
              classNames={"btn btn--primary"}
              isLoading={isTransferUpdating}
              isDisabled={isTransferUpdating}
              loadingText={"Updating..."}
              onClick={handleSubmit(updateTransferProducts)}
            >
              <TransferArrowIcon />
              <span className="ml-2">Update Request Transfer</span>
            </primaryComponents.Button>
          </div>

          {/* content section */}
          <secondaryComponents.LoaderHelper isLoading={isLoading}>
            <secondaryComponents.StockTransferForm
              transferData={transferData}
              setTransferData={setTransferData}
              otherTransferInfo={otherTransferInfo}
              validation={validation}
              isFetchingNextPage={isFetchingNextPage}
              autoScrollRef={autoScrollRef}
              isEditMode={true}
            />
          </secondaryComponents.LoaderHelper>
          {/* table section */}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditStockTransfer;
