import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { formatCurrency, truncateString } from "../../../helpers";

export const MobileDoc = ({
  address,
  business_name,
  storeWalletTnxDetails,
}) => {
  return (
    <Document>
      <Page>
        <View
          className={styles2.sectionView}
          style={{ ...styles2.sectionView }}
        >
          <View
            style={{
              marginBottom: "15px",
            }}
          >
            <Text style={styles2.fontProp}>
              {truncateString(business_name, 150)}
            </Text>
            <Text style={styles2.fontProp}>{truncateString(address, 150)}</Text>
          </View>

          <Text
            style={{
              marginBottom: "10px",
              ...styles2.fontProp,
            }}
          >
            Store Walllet Transaction Receipt
          </Text>

          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Order Date:</Text>
            <Text style={styles2.fontProp}>
              {new Date(storeWalletTnxDetails?.created_at).toLocaleString(
                "en-us",
              )}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Transaction Ref:</Text>
            <Text style={styles2.fontProp}>{storeWalletTnxDetails?.id}</Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Type:</Text>
            <Text
              style={{
                ...styles2.fontProp,
                color:
                  storeWalletTnxDetails?.type === "debit"
                    ? "#dc3545"
                    : "#19ae57",
              }}
            >
              {storeWalletTnxDetails?.type}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={{ ...styles2.fontProp, color: "#000" }}>Amount:</Text>
            <Text style={{ ...styles2.fontProp, color: "#7647ee" }}>
              {formatCurrency({
                value: storeWalletTnxDetails?.amount,
                currencyCode: storeWalletTnxDetails?.store?.currency_code,
              })}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Balance:</Text>
            <Text style={styles2.fontProp}>
              {formatCurrency({
                value: storeWalletTnxDetails?.balance,
                currencyCode: storeWalletTnxDetails?.store?.currency_code,
              })}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles2 = StyleSheet.create({
  sectionView: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "15px",
    width: "100%",
    maxWidth: "400px",
  },
  sectionView__item: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: "10px",
  },
  fontProp: {
    // fontWeight: "bolder !important",
    fontSize: "12px",
  },
});
