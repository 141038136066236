import React from "react";

export const WebIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49967 14.6666C12.1816 14.6666 15.1663 11.6818 15.1663 7.99992C15.1663 4.31802 12.1816 1.33325 8.49967 1.33325C4.81778 1.33325 1.83301 4.31802 1.83301 7.99992C1.83301 11.6818 4.81778 14.6666 8.49967 14.6666Z"
        stroke="#0B0C0E"
        strokeWidth="0.53"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 8H15.1667"
        stroke="#0B0C0E"
        strokeWidth="0.53"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 1.46802C10.1496 3.1514 11.1667 5.45692 11.1667 8.00002C11.1667 10.5431 10.1496 12.8486 8.5 14.532"
        stroke="#0B0C0E"
        strokeWidth="0.53"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.49967 1.46802C6.85009 3.1514 5.83301 5.45692 5.83301 8.00002C5.83301 10.5431 6.85009 12.8486 8.49967 14.532"
        stroke="#0B0C0E"
        strokeWidth="0.53"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
