import secondaryComponents from "../../../components/secondaryComponents";
import primaryComponents from "../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";

const RequestCapital = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    return () => clearTimeout(timeout);
  }, []);
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Request Capital"
      description="View request capital form"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={globalStyles.pagePaddings}
        >
          <div className="d-flex mb-4" style={{ gap: "15px" }}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon="angle-left" style={{ fontSize: "18px" }} />
            </primaryComponents.Button>
            <div className="align-items-center">
              <h5 className="mb-0">Request Capital</h5>
              <p className="small-text-size">Request Capital Form</p>
            </div>
          </div>

          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames={"mt-12"}
          >
            <iframe
              src="https://forms.monday.com/forms/embed/4ea3db161daa834d7df79664c748caad?r=use1"
              width="100%"
              height="900px"
              loading="lazy"
              style={{
                border: "4",
                maxWidth: "100%",
              }}
              className={`${globalStyles.cardWithBorder}`}
              title="Request Capital Form"
              onLoad={() => setIsLoading(false)}
            ></iframe>
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default RequestCapital;
