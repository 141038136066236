// import all logos and export them as an object
import enlumi_logo_white from "./enlumi-primary-logo.svg";
import enlumi_logo_purple from "./enlumi-secondary-logo.svg";
import enlumi_arrows from "./enlumi-arrows.svg";
import enlumi_small_arrows from "./enlumi-small-arrows.svg";
import enlumi_powered_logo from "./enlumi-powered-logo.svg";
import lumiWhiteLogo from "./lumi-primary-logo.svg";
import lumiLogo from "./lumiLogo.svg";

const Logo = {
  primary: enlumi_logo_white,
  secondary: enlumi_logo_purple,
  arrows: enlumi_arrows,
  small_arrows: enlumi_small_arrows,
  powered_logo: enlumi_powered_logo,
  lumiWhiteLogo,
  lumiLogo,
};

export default Logo;
