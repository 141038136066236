import { useGetOrdersWithSection } from "../../../hooks/api/queries/useSection";
import styles from "./SectionWidget.module.scss";
import secondaryComponents from "..";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const SectionWidget = ({ store_id }) => {
  const { ref: autoScrollRefProgress, inView: inViewProgress } = useInView();
  const { ref: autoScrollRefReady, inView: inViewReady } = useInView();

  const {
    isLoading: isLoadingProgressData,
    data: progressData,
    isFetchingNextPage: isFetchingNextPageProgress,
    hasNextPage: hasNextPageProgress,
    fetchNextPage: fetchNextPageProgress,
  } = useGetOrdersWithSection({
    queries: `?store_id=${store_id}&section_items_status=IN_PROGRESS`,
    queryName: "Progress",
  });

  const {
    isLoading: isLoadingReadyData,
    data: readyData,
    isFetchingNextPage: isFetchingNextPageReady,
    hasNextPage: hasNextPageReady,
    fetchNextPage: fetchNextPageReady,
  } = useGetOrdersWithSection({
    queries: `?store_id=${store_id}&section_items_status=PARTIALLY_COMPLETED|COMPLETED&show_only_last_thirty_mins=true`,
    queryName: "Completed",
  });

  const progressOrders = new Map();
  // eslint-disable-next-line array-callback-return
  progressData?.pages?.map((page) => {
    progressOrders.set(`${page.meta.current_page}`, page.data);
  });
  const progressOrdersList = [].concat(...progressOrders.values());
  useEffect(() => {
    if (inViewProgress && hasNextPageProgress) fetchNextPageProgress();
  }, [inViewProgress, fetchNextPageProgress, hasNextPageProgress]);

  const readyOrders = new Map();
  // eslint-disable-next-line array-callback-return
  readyData?.pages?.map((page) => {
    readyOrders.set(`${page.meta.current_page}`, page.data);
  });
  const readyOrdersList = [].concat(...readyOrders.values());
  useEffect(() => {
    if (inViewReady && hasNextPageReady) fetchNextPageReady();
  }, [inViewReady, fetchNextPageReady, hasNextPageReady]);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <h2
            className="pending--squared text-center"
            style={{ maxWidth: "fit-content" }}
          >
            In-Progress
          </h2>
          <secondaryComponents.LoaderHelper
            classNames={"mt-3"}
            isLoading={isLoadingProgressData}
          >
            <div className={styles.orders}>
              {progressOrdersList?.map((item) => (
                <h2 key={item?.id} ref={autoScrollRefProgress}>
                  {item?.queue_number || "-"}
                </h2>
              ))}
            </div>
          </secondaryComponents.LoaderHelper>

          <secondaryComponents.LoaderHelper
            classNames="mt-4"
            isLoading={isFetchingNextPageProgress && !isLoadingProgressData}
          ></secondaryComponents.LoaderHelper>
        </div>

        <div className={styles.vl}></div>

        <div className={styles.wrapper}>
          <h2
            className="success--squared text-center"
            style={{ maxWidth: "fit-content" }}
          >
            Ready
          </h2>

          <secondaryComponents.LoaderHelper
            classNames={"mt-3"}
            isLoading={isLoadingReadyData}
          >
            <div className={styles.orders}>
              {readyOrdersList?.map((item) => (
                <h2 key={item?.id} ref={autoScrollRefReady}>
                  {item?.queue_number || "-"}
                </h2>
              ))}
            </div>
          </secondaryComponents.LoaderHelper>

          <secondaryComponents.LoaderHelper
            classNames="mt-4"
            isLoading={isFetchingNextPageReady && !isLoadingReadyData}
          ></secondaryComponents.LoaderHelper>
        </div>
      </div>
    </>
  );
};

export default SectionWidget;
