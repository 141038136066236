import { useState } from "react";

const HistoryLogic = () => {
  const [isRefresh, setIsRefresh] = useState(false);

  return {
    isRefresh,
    setIsRefresh,
  };
};
export default HistoryLogic;
