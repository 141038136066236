import styles from "./GenerateWaybills.module.scss";
import primaryComponents from "../../../primaryComponents";
import { useGeneratedWaybills } from "./useGeneratedWaybills";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatDateToString } from "../../../../helpers";
import secondaryComponents from "../..";

const WaybillsDetails = ({ orderId }) => {
  const {
    waybills,
    loadingWaybills,
    currentStep,
    selectedWaybill,
    setSelectedWaybill,
    setCurrentStep,
    orderDetails,
  } = useGeneratedWaybills({
    orderId,
  });

  return (
    <div className={styles.geeneratedWaybills}>
      <div className="d-flex align-items-center">
        {currentStep === "waybill_detail" ? (
          <FontAwesomeIcon
            onClick={() => setCurrentStep("waybill_list")}
            className="d-block"
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        ) : null}
      </div>
      {currentStep === "waybill_list" ? (
        <secondaryComponents.LoaderHelper isLoading={loadingWaybills}>
          <div className={styles.geeneratedWaybills__items}>
            {waybills &&
              waybills.map((waybill) => (
                <div
                  className={styles["geeneratedWaybills__items-item"]}
                  key={waybill?.id}
                >
                  <div>
                    <h5>{waybill?.id}</h5>
                  </div>
                  <div className="d-flex align-items-center">
                    <p style={{ fontSize: "14px" }}>
                      {formatDateToString(waybill?.created_at)}
                    </p>
                    <primaryComponents.Button
                      classNames={`btn btn--primary ${styles.printBtn} ml-auto`}
                      onClick={() => {
                        setSelectedWaybill(waybill);
                        setCurrentStep("waybill_detail");
                      }}
                    >
                      View Details
                    </primaryComponents.Button>
                  </div>
                </div>
              ))}
          </div>
        </secondaryComponents.LoaderHelper>
      ) : (
        <>
          <div className="mt-3">
            <p>Waybill Number:</p>
            <h4>{selectedWaybill?.id}</h4>
          </div>
          <div className={styles.waybillDetail}>
            <div className="d-flex justify-content-between mb-2">
              <p>Waybill Date:</p>
              <p className="text-right dark--text">
                {formatDateToString(selectedWaybill?.created_at) || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Transaction Reference:</p>
              <p className="text-right dark--text">
                {orderDetails?.trx_reference || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Payment Mode:</p>
              <p className="text-right dark--text">
                {orderDetails?.payment_method || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Cashier:</p>
              <p className="text-right dark--text">
                {orderDetails?.cashier?.first_name || "N/A"}{" "}
                {orderDetails?.cashier?.last_name || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Customer:</p>
              <p className="text-right dark--text">
                {orderDetails?.customer?.full_name || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Store Name:</p>
              <p className="text-right dark--text">
                {orderDetails?.store?.name || "N/A"}
              </p>
            </div>
            <h5 className="mt-4 mb-3">Logistics</h5>
            <div className="d-flex justify-content-between mb-2">
              <p>Driver Name:</p>
              <p className="text-right dark--text">
                {selectedWaybill?.logistics?.driver_name || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Driver Vehicles Number:</p>
              <p className="text-right dark--text">
                {selectedWaybill?.logistics?.vehicle_number || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Drivers Phone Number:</p>
              <p className="text-right dark--text">
                {selectedWaybill?.logistics?.driver_phone_number || "N/A"}
              </p>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <p>Logistics Company:</p>
              <p className="text-right dark--text">
                {selectedWaybill?.logistics?.company || "N/A"}
              </p>
            </div>
            <h5 className="mt-4 mb-3">Waybill Items</h5>
            <div className={styles.orderDetails}>
              <p>ITEM</p>
              <p>QUANTITY</p>
              <p>UNIT(S)</p>
            </div>
            {orderDetails?.order_items?.map((item) => (
              <div className={styles.orderDetails} key={item?.id}>
                <p>{item?.cart_item_name}</p>
                <p className="text-left">{item?.quantity}</p>
                <p className="text-left">
                  {item?.product?.unit?.unit ||
                    item?.variant?.unit?.unit ||
                    "N/A"}
                </p>
              </div>
            ))}
          </div>
        </>
      )}

      <div className={styles.geeneratedWaybills__bottom}>
        {currentStep === "waybill_detail" ? (
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            onClick={() => window.open(selectedWaybill?.waybill_url, "_blank")}
          >
            Print
          </primaryComponents.Button>
        ) : null}
      </div>
    </div>
  );
};

export default WaybillsDetails;
