import styles from "./CustomerUpdate.module.scss";
import AddCustomerSection from "../../secondaryComponents/StoreCheckout/AddCustomerSection";
import primaryComponents from "../../primaryComponents";
import { useUpdateOrderCustomer } from "../../../hooks/api/mutations/useOrder";
import AddCustomer from "../AddCustomer";
import { useState } from "react";

const CustomerUpdate = ({
  customerOrderDetails,
  setCustomerOrderDetails,
  closeWidget,
  orderId,
}) => {
  const [isAddingCustomer, setIsAddingCustomer] = useState(false);
  const { handleUpdateOrderCustomer, isLoading: isUpdatingOrderCustomer } =
    useUpdateOrderCustomer({ closeWidget });

  const updateOrderCustomer = () => {
    handleUpdateOrderCustomer({
      data: {
        customer_name: customerOrderDetails.customerName,
        customer_phone_code: customerOrderDetails.customerPhoneCode,
        customer_phone_number: customerOrderDetails.customerPhoneNumber,
        customer_email: customerOrderDetails.customerEmail,
        order_id: orderId,
      },
    });
  };

  const toggleAddCustomer = () => {
    setIsAddingCustomer(!isAddingCustomer);
  };
  return (
    <div className={styles.customerUpdate}>
      {!isAddingCustomer && (
        <h4 className="text-center">Update Customer Details</h4>
      )}
      <div>
        {!isAddingCustomer ? (
          <>
            <AddCustomerSection
              customerDetails={customerOrderDetails}
              setCustomerDetails={setCustomerOrderDetails}
              title={"updateCustomerOrderDetails"}
              toggleAddCustomer={toggleAddCustomer}
            />
          </>
        ) : (
          <>
            <AddCustomer
              closeModal={toggleAddCustomer}
              addCustomer={setCustomerOrderDetails}
            />
          </>
        )}
      </div>

      {!isAddingCustomer && (
        <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={closeWidget}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            onClick={updateOrderCustomer}
            isDisabled={isUpdatingOrderCustomer}
            isLoading={isUpdatingOrderCustomer}
          >
            Save
          </primaryComponents.Button>
        </div>
      )}
    </div>
  );
};

export default CustomerUpdate;
