import styles from "./GiftCardList.module.scss";
import GiftCardItem from "../GiftCardItem/GiftCardItem";
import Images from "../../../assets/images";

const GiftCardList = ({ giftcards }) => {
  return (
    <>
      {giftcards?.length > 0 ? (
        <div className={styles.giftcardList}>
          {giftcards?.map((giftcard) => (
            <GiftCardItem key={giftcard.id} giftcardDetails={giftcard} />
          ))}
        </div>
      ) : (
        <div className="text-center mt-5">
          <img
            width="250px"
            height="262px"
            src={Images.campaignEmptyState}
            alt="empty state"
          />
          <h4>You have no giftcards</h4>
          <p>Create your giftcards using the button above.</p>
        </div>
      )}
    </>
  );
};

export default GiftCardList;
