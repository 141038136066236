import { useContext, useState } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { deletTag } from "../../../services/tags";
import { useQueryClient } from "react-query";

const DeleteTagLogic = ({ tagDetails, closeModal }) => {
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();

  const handleDeleteTag = () => {
    setIsDeletingTag(true);
    setRequestLoaderProgress(40);

    deletTag(tagDetails.id)
      .then(() => {
        setIsDeletingTag(false);
        setRequestLoaderProgress(100);
        triggerToast("Tag deleted successfully", "success");
        queryClient.invalidateQueries(["getTagList"]);
        closeModal();
      })
      .catch(() => {
        setIsDeletingTag(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    handleDeleteTag,
    isDeletingTag,
  };
};

export default DeleteTagLogic;
