import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return (state = true);
    case "CLOSE":
      return (state = false);
    default:
      throw new Error("Unhandled action of type: " + action.type);
  }
};

const useModal = (defaultState = false) => {
  const [modalState, dispatch] = useReducer(reducer, defaultState);

  const closeModal = () => {
    dispatch({ type: "CLOSE" });
  };

  const openModal = () => {
    dispatch({ type: "OPEN" });
  };

  return {
    modalState,
    closeModal,
    openModal,
  };
};

export default useModal;
