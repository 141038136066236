export const PaymentIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 28 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1667 6.33331H5.83333C4.54467 6.33331 3.5 7.37798 3.5 8.66665V20.3333C3.5 21.622 4.54467 22.6666 5.83333 22.6666H22.1667C23.4553 22.6666 24.5 21.622 24.5 20.3333V8.66665C24.5 7.37798 23.4553 6.33331 22.1667 6.33331Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 12.1667H24.5"
        stroke={strokeColor}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
