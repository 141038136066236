import { useContext, useState } from "react";
import { printOrderReceipt } from "../../../../services/sales-point";
import { useGenerateInvoice } from "../../../../hooks/api/mutations/useInventory";
import { ToastContext } from "../../../../hooks/context";
import {
  useAcceptOrRejectOrdereMutation,
  useCancelOrderMutation,
} from "../../../../hooks/api/mutations/useOrder";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

const useTransactionDetails = ({ orderId, handleRefetchOrder = () => {} }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [isLoading, setIsloading] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [isEditDate, setIsEditDate] = useState(false);
  const [isOrderCustomer, setIsOrderCustomer] = useState(false);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [customerOrderDetails, setCustomerOrderDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [showRejectionReason, setShowRejectionReason] = useState(false);

  const printReceipt = (billWasUpdated = false) => {
    setIsloading(true);
    printOrderReceipt(orderId, billWasUpdated)
      .then((response) => {
        const receiptLink =
          response?.data?.data?.url || response?.data?.data?.bill_url;
        window.open(receiptLink, "_blank");
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  };

  const { handlegenerateInvoice, isLoading: isGeneratingInvoice } =
    useGenerateInvoice();

  const { mutate: cancelOrder, isLoading: isCancellingOrder } =
    useCancelOrderMutation();
  const cancelCurrentOrder = () => {
    cancelOrder(
      { orderId: orderId },
      {
        onSuccess: () => {
          triggerToast("Order Cancelled Successfully", "warning");
          handleRefetchOrder();
          queryClient.invalidateQueries(["getOrders"]);
        },
      },
    );
  };

  const { isLoading: isProcessingOrder, mutate: mutateAcceptOrReject } =
    useAcceptOrRejectOrdereMutation();
  const acceptOrRejectOrder = ({ isAccept = true, rejectionReason = null }) => {
    mutateAcceptOrReject(
      {
        id: orderId,
        isAccept,
        rejection_reason: rejectionReason,
      },
      {
        onSuccess: (res) => {
          setShowRejectionReason(false);
          handleRefetchOrder();
          triggerToast(res?.data?.detail, "success");
        },
      },
    );
  };

  return {
    isLoading,
    isGeneratingInvoice,
    isCancellingOrder,
    isPrintModal,
    isEditDate,
    isOrderCustomer,
    customerOrderDetails,
    isProcessingOrder,
    showRejectionReason,
    acceptOrRejectOrder,
    setShowRejectionReason,
    setCustomerOrderDetails,
    setIsOrderCustomer,
    setIsEditDate,
    setIsPrintModal,
    cancelCurrentOrder,
    printReceipt,
    handlegenerateInvoice,
  };
};
export default useTransactionDetails;
