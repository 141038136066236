import styles from "../CreateGiftCard/CreateGiftCard.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const EditGiftCard = () => {
  const navigate = useNavigate();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - Create giftcard"
      description="Create a new giftcard"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps} ${styles.createGiftCard}`}
        >
          {/* back btn */}
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline mb-4"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <div className={styles.createGiftCard__content}>
            <h3>Edit giftcard</h3>
            <secondaryComponents.GiftcardForm />
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditGiftCard;
