import primaryComponents from "../../primaryComponents";
import styles from "./TransferStockWidget.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { Controller } from "react-hook-form";
import { useTransferStock } from "./TransferStockLogic";
import { useTransferStockFormValidation } from "./validation";

const TransferStockWidget = ({ closeWidget }) => {
  const {
    formData,
    selectedProductVariants,
    isTransferingStock,
    stores,
    setStores,
    setSelectedProductVariants,
    setFormData,
    handleTransferStock,
  } = useTransferStock({ closeWidget });

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useTransferStockFormValidation(formData);

  return (
    <div className={styles.transferStockWidget}>
      <div className={styles.transferStockHeader}>
        <button onClick={closeWidget} className={styles.backButton}>
          <FontAwesomeIcon
            icon="angle-left"
            className="purple--text"
            style={{ fontSize: "20px" }}
          />
        </button>
        <h3>Transfer Stock</h3>
      </div>
      <p>
        Achieve constant stock availability by transferring inventory between
        your stores effortlessly.
      </p>
      <form
        onSubmit={handleSubmit(handleTransferStock)}
        className={styles.transferStockForm}
      >
        <div className={styles.transferStockFormSection}>
          <div className={styles.transferStockFormGroup}>
            <p>Search and select product</p>
            <div className={styles.dropdownSection}>
              <Controller
                control={control}
                name="product"
                defaultValue={formData.product}
                render={({ field: { onChange } }) => (
                  <primaryComponents.ProductComboBox
                    onChange={(selectedProduct) => {
                      if (selectedProduct) {
                        if (selectedProduct?.stores) {
                          setStores(selectedProduct?.stores);
                        }

                        if (selectedProduct?.variants) {
                          const { variants } = selectedProduct;
                          const productVariants = variants.map((variant) => ({
                            label: `${variant.option} - ${variant.value}`,
                            value: variant?.id,
                          }));
                          setSelectedProductVariants(productVariants);
                        } else {
                          setSelectedProductVariants([]);
                          resetField("variant", { defaultValue: "" });
                        }
                      }
                      onChange(selectedProduct?.id);
                      setFormData((prev) => ({
                        ...prev,
                        product: selectedProduct?.id,
                      }));
                    }}
                    error={errors?.product?.message}
                  />
                )}
              />
            </div>
          </div>
          {selectedProductVariants.length ? (
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ delay: 0.25 }}
              className={styles.transferStockFormGroup}
            >
              <p>Variants available in selected store</p>
              <div className={styles.dropdownSection}>
                <Controller
                  control={control}
                  name="variant"
                  defaultValue={formData.variant}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.SelectField
                      options={selectedProductVariants}
                      nameKey="label"
                      idKey="value"
                      onChange={(value) => {
                        onChange(value);
                        setFormData((prev) => ({ ...prev, variant: value }));
                      }}
                      errorMessage={errors.variant}
                    />
                  )}
                />
              </div>
            </motion.div>
          ) : null}

          <div className={styles.transferStockFormGroup}>
            <p>Transfer From</p>
            <div className={styles.dropdownSection}>
              <Controller
                name="transferFrom"
                control={control}
                defaultValue={formData.transferFrom}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    options={stores}
                    nameKey="name"
                    idKey="id"
                    onChange={(value) => {
                      onChange(value);
                      setFormData((prev) => ({
                        ...prev,
                        transferFrom: value,
                      }));
                    }}
                    errorMessage={errors.transferFrom}
                    placeholder={"Select Store"}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.transferStockFormGroup}>
            <p>Transfer To</p>
            <div className={styles.dropdownSection}>
              <Controller
                name="transferTo"
                control={control}
                defaultValue={formData.transferTo}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    options={stores}
                    nameKey="name"
                    idKey="id"
                    onChange={(value) => {
                      onChange(value);
                      setFormData((prev) => ({
                        ...prev,
                        transferTo: value,
                      }));
                    }}
                    errorMessage={errors.transferTo}
                    placeholder={"Select Store"}
                  />
                )}
              />
            </div>
          </div>
          <div className={styles.transferStockFormGroup}>
            <p>Quantity to Transfer</p>
            <div className={styles.dropdownSection}>
              <Controller
                name="quantity"
                control={control}
                defaultValue={formData.quantity}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    classNames="white"
                    onChange={({ target: { value } }) => {
                      onChange(value);
                      setFormData((prev) => ({ ...prev, quantity: value }));
                    }}
                    value={formData.quantity}
                    errorMessage={errors.quantity}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <primaryComponents.Button
          classNames={"btn btn--primary mt-auto ml-auto w-100"}
          isDisabled={isTransferingStock}
          isLoading={isTransferingStock}
          loadingText={"Tranferring...."}
        >
          Transfer
        </primaryComponents.Button>
      </form>
    </div>
  );
};

export default TransferStockWidget;
