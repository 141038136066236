import React from "react";
import { CloseIcon } from "../../../assets/svgIcons";
import primaryComponents from "../../primaryComponents";
import styles from "./LocationModal.module.scss";
import useLocationModal from "./useLocationModal";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LocationModal = ({
  closeModal,
  isLoadingStates,
  states,
  countries,
  setSelectedCountry,
  storeDetails,
  setStoreDetails,
}) => {
  const {
    filteredCountries,
    isLoadingCountries,
    searchQueryCountry,
    expandedStates,
    expandedCountry,
    searchQueryState,
    searchQueryCity,
    filteredStates,
    setSearchQueryCity,
    setSearchQueryState,
    setSearchQueryCountry,
    setExpandedCountry,
    toggleStateExpansion,
    toggleStateSelection,
    toggleCitySelection,
  } = useLocationModal({
    isLoadingStates,
    states,
    countries,
    setSelectedCountry,
    storeDetails,
    setStoreDetails,
  });

  return (
    <div className="m-2">
      <div className="d-flex justify-content-between align-items-center">
        <h5>Select Regions</h5>
        {storeDetails?.country_ids ? (
          <primaryComponents.Button
            onClick={closeModal}
            type="button"
            classNames="btn btn--outline smallBtn"
          >
            Continue
          </primaryComponents.Button>
        ) : (
          <CloseIcon classNames={"ml-auto d-block"} onClick={closeModal} />
        )}
      </div>

      <hr className={styles.divider} />

      <div className={styles.card}>
        <primaryComponents.SearchField
          showsearchBtn={false}
          triggerOnInput={true}
          borderColor="#97979759"
          placeholder={"Search country"}
          value={searchQueryCountry}
          trigger={(value) => setSearchQueryCountry(value)}
        />
        <hr className={styles.dividerInner} />

        <LoaderHelper isLoading={isLoadingCountries} classNames={"mt-5"}>
          <div className={styles.scrollableCountryDiv}>
            {filteredCountries?.length > 0 ? (
              filteredCountries?.slice(1)?.map((country) => {
                return (
                  <div key={country?.id}>
                    <label
                      htmlFor={country?.id}
                      className="d-flex justify-content-between align-items-center cursor-pointer p-3"
                    >
                      <span
                        className="d-flex align-items-center cursor-pointer"
                        style={{ gap: "15px" }}
                      >
                        <primaryComponents.RadioButton
                          name={"countries"}
                          id={country?.id}
                          value={country?.id}
                          checked={storeDetails?.country_ids?.includes(
                            `${country?.id}`,
                          )}
                          setRadioValue={(value) => {
                            setStoreDetails((prev) => ({
                              ...prev,
                              country_ids: [value],
                              state_ids: [],
                              city_ids: {},
                            }));
                            setSelectedCountry(value);
                            setExpandedCountry(country?.id);
                          }}
                        />

                        <span>{country?.emoji}</span>
                        <span className="small-text-size">{country?.name}</span>
                      </span>

                      {/* <span
                        className="d-flex align-items-center cursor-pointer"
                        style={{ gap: "15px" }}
                        onClick={() => {
                          if (expandedCountry === country?.id)
                            setExpandedCountry(null);
                          else setExpandedCountry(country?.id);
                        }}
                      > */}
                      <span className="gray--text">
                        {storeDetails?.country_ids?.includes(`${country?.id}`)
                          ? storeDetails?.state_ids?.length
                          : 0}{" "}
                        states |{" "}
                        {storeDetails?.country_ids?.includes(`${country?.id}`)
                          ? Object.values({ ...storeDetails?.city_ids }).flat()
                              ?.length
                          : 0}{" "}
                        towns selected
                      </span>

                      {/* <FontAwesomeIcon
                          icon={`${expandedCountry === country?.id ? "angle-up" : "angle-down"}`}
                        /> */}
                      {/* </span> */}
                    </label>

                    {`${expandedCountry}` === `${country?.id}` && (
                      <div className={styles.countryList}>
                        <hr />
                        <div
                          className={`${styles.stateCard} d-flex justify-content-between p-3 align-items-center`}
                        >
                          <label className="smallest-text-size gray--text">
                            Pick States in {country?.name}
                          </label>
                          <primaryComponents.SearchField
                            showsearchBtn={false}
                            triggerOnInput={true}
                            borderColor="#97979759"
                            placeholder={"Search state"}
                            value={searchQueryState || ""}
                            trigger={(value) => setSearchQueryState(value)}
                          />
                        </div>
                        <LoaderHelper
                          isLoading={isLoadingStates}
                          classNames={"py-3"}
                        >
                          {filteredStates?.length > 0 ? (
                            filteredStates?.map((state) => {
                              const filteredCities =
                                states
                                  ?.find((state1) => state1?.id === state?.id)
                                  ?.cities?.filter((city) =>
                                    city?.name
                                      .toLowerCase()
                                      .includes(searchQueryCity.toLowerCase()),
                                  ) || [];
                              return (
                                <div key={state.id}>
                                  <div className="d-flex justify-content-between align-items-center p-3">
                                    <span
                                      className="d-flex align-items-center"
                                      style={{ gap: "15px" }}
                                    >
                                      <primaryComponents.Checkbox
                                        name={`states${country?.id}`}
                                        id={state?.id}
                                        isChecked={storeDetails?.state_ids?.includes(
                                          state?.id,
                                        )}
                                        onChange={(value) => {
                                          toggleStateSelection(value, state);
                                        }}
                                      />
                                      <label
                                        className="gray--text cursor-pointer"
                                        htmlFor={state?.id}
                                      >
                                        {state?.name}
                                      </label>
                                    </span>

                                    <FontAwesomeIcon
                                      icon={`${expandedStates.includes(state?.id) ? "angle-up" : "angle-down"}`}
                                      onClick={() => {
                                        toggleStateExpansion(state?.id);
                                      }}
                                      className="gray--text"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>

                                  {expandedStates?.includes(state?.id) ? (
                                    <div>
                                      <hr />
                                      <div
                                        className={`${styles.stateCard} d-flex justify-content-between p-3 align-items-center`}
                                      >
                                        <label className="smallest-text-size gray--text">
                                          Pick cities in {state?.name}
                                        </label>
                                        <primaryComponents.SearchField
                                          showsearchBtn={false}
                                          triggerOnInput={true}
                                          borderColor="#97979759"
                                          placeholder={"Search city"}
                                          value={searchQueryCity || ""}
                                          trigger={(value) =>
                                            setSearchQueryCity(value)
                                          }
                                        />
                                      </div>
                                      {filteredCities?.map((city) => (
                                        <span
                                          className="d-flex align-items-center p-3 px-5"
                                          style={{ gap: "15px" }}
                                          key={city.id}
                                        >
                                          <primaryComponents.Checkbox
                                            name={`cities${state?.id}`}
                                            id={city?.id}
                                            value={city?.id}
                                            isChecked={storeDetails?.city_ids?.[
                                              state.id
                                            ]?.includes(city?.id)}
                                            onChange={(value) => {
                                              toggleCitySelection(
                                                value,
                                                state,
                                                city,
                                              );
                                            }}
                                          />
                                          <label
                                            className="small-text-size cursor-pointer"
                                            htmlFor={city?.id}
                                          >
                                            {city?.name}
                                          </label>
                                        </span>
                                      ))}
                                    </div>
                                  ) : null}

                                  {filteredStates?.length > 1 && (
                                    <hr className={styles.dividerInner} />
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <p className="py-3 text-center">
                              No Data Available
                            </p>
                          )}
                        </LoaderHelper>
                      </div>
                    )}
                    <hr className={styles.dividerInner} />
                  </div>
                );
              })
            ) : (
              <p className="text-center mt-4">No countries found</p>
            )}
          </div>
        </LoaderHelper>
      </div>
    </div>
  );
};

export default LocationModal;
