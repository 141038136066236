import styles from "./TableDetails.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTableDetails } from "./useTableDetails";
import { useSelector } from "react-redux";
import { getPlatform } from "../../../../helpers";
import { useContext } from "react";
import { platformHandler } from "../../../../hooks/context";

const InventoryTableDetails = () => {
  const navigate = useNavigate();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { isFetchingOrderTable, tableDetails, tableId } = useTableDetails();
  const { platform } = useContext(platformHandler);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Table Details"
      description="View table details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={styles.tableDetails}
        >
          <primaryComponents.Button
            onClick={() =>
              navigate(getPlatform(platform) + "/inventory/tables")
            }
            classNames={"btn btn--outline"}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            Back
          </primaryComponents.Button>
          <div className={styles.tableDetails__content}>
            <h4 className="dark--text">Table Details</h4>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingOrderTable}
              classNames={"mt-5"}
            >
              <secondaryComponents.EditOrderTableForm
                tableDetails={tableDetails}
                storeList={storeList}
                tableId={tableId}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default InventoryTableDetails;
