import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./SupplierDetails.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../../helpers";
import { useNavigate } from "react-router-dom";
import useSupplierDetails from "./useSupplierDetails";
import { useSelector } from "react-redux";

const SupplierDetails = () => {
  const navigate = useNavigate();
  const {
    isFetchingSupplierDetails,
    supplierDetails,
    isFetchingInvoice,
    invoices,
    refetchSupplierInvoices,
    gotoPage,
  } = useSupplierDetails();
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Supplier Details"
      description="View supplier details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={styles.supplierDetails}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingSupplierDetails}
          >
            <div className={styles.supplierDetails__header}>
              <div>
                <h3 style={{ maxWidth: "600px" }}>{supplierDetails?.name}</h3>
              </div>
              <p>
                Last Updated &nbsp;
                {new Date(supplierDetails?.updated_at).toLocaleString("en-us")}
              </p>
            </div>

            <div>
              <div className={styles.supplierDetails__card}>
                <div>
                  <h6>Contact Name</h6>
                  <p>{supplierDetails?.contact_name}</p>
                </div>
                <div>
                  <h6>Contact Address</h6>
                  <p>{supplierDetails?.address}</p>
                </div>
                <div>
                  <h6>Phone Number</h6>
                  <p>{supplierDetails?.phone_number}</p>
                </div>
                <div>
                  <h6>Credit Till Date</h6>
                  <p>
                    {formatCurrency({
                      value: supplierDetails?.total_credit,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
                <div>
                  <h6>Total Repayment</h6>
                  <p>
                    {formatCurrency({
                      value: supplierDetails?.total_repayment,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
                <div>
                  <h6>Outstanding Balance</h6>
                  <p>
                    {formatCurrency({
                      value: supplierDetails?.total_outstanding_balance,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingInvoice}
          >
            <div>
              <h5>Invoices</h5>
              <secondaryComponents.InvoiceTable
                invoices={invoices}
                refetchSupplierInvoices={refetchSupplierInvoices}
                gotoPage={gotoPage}
              />
            </div>
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default SupplierDetails;
