import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SectionSchema = yup
  .object({
    name: yup.string().required("section name is required"),
  })
  .required("All fields are required");

const Validation = (name) => {
  return useForm({
    resolver: yupResolver(SectionSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      name,
    },
  });
};

export default Validation;
