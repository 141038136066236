import "./DateRangeInput.scss";
import DateRangeInputLogic from "./DateRangeInputLogic";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  setToDateMonthYearInWords,
  formatDateYYYYMMDD,
} from "../../../helpers";
import secondaryComponents from "../../secondaryComponents";

const DateRangeInput = ({
  onChange = () => {},
  startDate = null,
  endDate = null,
  classNames = "",
  showPlaceholder = false,
  isToday = false,
}) => {
  const { isActive, isMobile, setIsActive, inputRef, dateRange, setDateRange } =
    DateRangeInputLogic({ startDate, endDate });

  return (
    <div
      ref={inputRef}
      className={`${classNames} lm-date-range ${isActive && "active"}`}
    >
      <div
        data-testid="placeholder"
        className="lm-date-range__input"
        onClick={() => setIsActive((prev) => !prev)}
      >
        {isToday && startDate === null ? (
          "Today"
        ) : (
          <>
            {showPlaceholder && startDate === null
              ? "Start date"
              : setToDateMonthYearInWords(dateRange[0].startDate)}
            <span className="mx-3 gray--text">to</span>
            {showPlaceholder && endDate === null
              ? "End date"
              : setToDateMonthYearInWords(dateRange[0].endDate)}
          </>
        )}
      </div>

      {isMobile ? (
        <secondaryComponents.Modal
          isActive={isActive}
          closeModal={() => setIsActive(false)}
          width={"400px"}
        >
          <div className="d-flex justify-content-center">
            <DateRange
              ranges={dateRange}
              editableDateInputs={true}
              onChange={(item) => {
                setDateRange([item?.selection]);
                onChange({
                  startDate: formatDateYYYYMMDD(item?.selection?.startDate),
                  endDate: formatDateYYYYMMDD(item?.selection?.endDate),
                });
              }}
              rangeColors={["#7647ee"]}
            />
          </div>
        </secondaryComponents.Modal>
      ) : (
        <DateRange
          className="lm-date-range__dropdown"
          ranges={dateRange}
          editableDateInputs={true}
          onChange={(item) => {
            setDateRange([item.selection]);
            onChange({
              startDate: formatDateYYYYMMDD(item?.selection?.startDate),
              endDate: formatDateYYYYMMDD(item?.selection?.endDate),
            });
          }}
          rangeColors={["#7647ee"]}
        />
      )}
    </div>
  );
};
export default DateRangeInput;
