import React from "react";

export const SecurityIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 13V4.22222L4.94372 4.07224C6.6371 3.94158 8.29889 3.54213 9.86666 2.88889L12 2L14.1333 2.88889C15.7011 3.54213 17.3629 3.94158 19.0563 4.07224L21 4.22222V13C21 17.9706 16.9706 22 12 22C7.02944 22 3 17.9706 3 13Z"
        fill="#FACE8A"
        stroke="#0B0C0E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 13.6748C14.169 13.6748 16 14.0317 16 15.41C16 16.7883 14.1572 17.1333 12 17.1333C9.83104 17.1333 8 16.7759 8 15.3981C8 14.0198 9.84225 13.6748 12 13.6748ZM12 7C13.4691 7 14.6467 8.19287 14.6467 9.68098C14.6467 11.1691 13.4691 12.362 12 12.362C10.5309 12.362 9.35326 11.1691 9.35326 9.68098C9.35326 8.19287 10.5309 7 12 7Z"
        fill="#200E32"
      />
    </svg>
  );
};
