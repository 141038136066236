import MainLayout from "./MainLayout/MainLayout";
import SignupLayout from "./SignupLayout/SignupLayout";
import LoginLayout from "./LoginLayout/LoginLayout";
import AppLayout from "./AppLayout";
import SettingsLayout from "./SettingsLayout/SettingsLayout";
import LoyaltyLayout from "./LoyaltyLayout/LoyaltyLayout";
import TransactionsLayout from "./TransactionsLayout/TransactionsLayout";
import CustomersLayout from "./CustomersLayout/CustomersLayout";
import TransfersLayout from "./TransfersLayout/TransfersLayout";
import SubAccountLayout from "./SubAccountLayout/SubAccountLayout";
import TerminalsLayout from "./TerminalsLayout/TerminalsLayout";
import TagsLayout from "./TagsLayout/TagsLayout";
import InventoryLayout from "./InventoryLayout/InventoryLayout";
import SellModeLayout from "./SellModeLayout";
import CustomerSubLayout from "./CustomerSubLayout/CustomerSubLayout";
import StoresLayout from "./StoresLayout";
import ExpensesLayout from "./ExpensesLayout/ExpensesLayout";
import AccountingLayout from "./AccountingLayout/AccountingLayout";
import MobileAppLayout from "./MobileAppLayout";
import MenuPageLayout from "./MenuPageLayout/MenuPageLayout";
import DashboardLayout from "./DashboardLayout";
import SectionLayout from "./SectionLayout";
import AllSectionLayout from "./AllSectionsLayout";

const layouts = {
  MainLayout,
  SignupLayout,
  LoginLayout,
  AppLayout,
  SettingsLayout,
  LoyaltyLayout,
  TransactionsLayout,
  CustomersLayout,
  TransfersLayout,
  SubAccountLayout,
  TerminalsLayout,
  TagsLayout,
  InventoryLayout,
  SellModeLayout,
  CustomerSubLayout,
  StoresLayout,
  ExpensesLayout,
  AccountingLayout,
  MobileAppLayout,
  MenuPageLayout,
  DashboardLayout,
  SectionLayout,
  AllSectionLayout,
};

export default layouts;
