/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet } from "react-router-dom";
import useRootProperties from "../../hooks/useRootProperties";
import secondaryComponents from "../../components/secondaryComponents";
import styles from "./MobileAppLayout.module.scss";

const MobileAppLayout = () => {
  const { isLoading } = useRootProperties();
  return (
    <>
      <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-5">
        <div className={styles.mobileAppLayout__content}>
          <Outlet />
        </div>
      </secondaryComponents.LoaderHelper>
    </>
  );
};

export default MobileAppLayout;
