import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape(
  {
    driverName: yup.string().required(),
    vehicleNumber: yup.string().required(),
    driverPhoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required(),
    logisticsCompany: yup.string().required(),
  },
  ["customerPhoneNumber", "customerPhoneNumber"],
);

export const useValidateForm = (formData) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { ...formData },
  });
};
