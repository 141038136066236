import { useQueries, useQuery } from "react-query";
import {
  fetchAVSUCustomerAnalytics,
  fetchAllCustomerAnalytics,
  fetchCashFlowAnalytics,
  fetchDashboard,
  fetchNewCustomerAnalytics,
  fetchReturningCustomerAnalytics,
  fetchTagsFlowAnalytics,
  fetchTopCustomers,
  getDashboardTutorials,
  getDashboardSetUpGuideCategories,
} from "../../../services/dashboard";
import { getAnalyticsData } from "../../../services/inventory";
import { setDashboardDetails } from "../../../store/modules/dashboard";
import { useDispatch } from "react-redux";
import { getNotifications } from "../../../services/notifications";

export const useGetDashboardDetails = ({
  isEnabled = false,
  queries = "",
  staleTime = null,
  queryName = "getDashboardDetails",
}) => {
  const dispatch = useDispatch();
  return useQuery(
    [queryName, queries],
    () =>
      fetchDashboard(queries).then((res) => {
        if (res?.data?.data) dispatch(setDashboardDetails(res?.data?.data));
        return res?.data?.data;
      }),
    {
      staleTime: staleTime ? staleTime : undefined,
      enabled: isEnabled,
    },
  );
};

export const useGetCustomerAnalytics = ({ queries }) => {
  return useQueries([
    {
      // staleTime: Infinity,
      queryKey: ["allCustomerAnalytics", queries],
      queryFn: () =>
        fetchAllCustomerAnalytics(queries).then((res) => res?.data?.data),
    },
    {
      // staleTime: Infinity,
      queryKey: ["newCustomerAnalytics", queries],
      queryFn: () =>
        fetchNewCustomerAnalytics(queries).then((res) => res?.data?.data),
    },
    {
      // staleTime: Infinity,
      queryKey: ["returningCustomerAnalytics", queries],
      queryFn: () =>
        fetchReturningCustomerAnalytics(queries).then((res) => res?.data?.data),
    },
    {
      // staleTime: Infinity,
      queryKey: ["AVSUCustomerAnalytics", queries],
      queryFn: () =>
        fetchAVSUCustomerAnalytics(queries).then((res) => res?.data?.data),
    },
    {
      // staleTime: Infinity,
      queryKey: ["topCustomers", queries],
      queryFn: () => fetchTopCustomers(queries).then((res) => res?.data?.data),
    },
  ]);
};

export const useGetCashflowAnalytics = ({ queries }) => {
  return useQueries([
    {
      // staleTime: Infinity,
      queryKey: ["cashFlowAnalytics", queries],
      queryFn: () =>
        fetchCashFlowAnalytics(queries).then((res) => res?.data?.data),
    },
    {
      // staleTime: Infinity,
      queryKey: ["tagsFlowAnalytics", queries],
      queryFn: () =>
        fetchTagsFlowAnalytics(queries).then((res) => res?.data?.data),
    },
  ]);
};

export const useMainDashboardData = (
  params = { queries: "", enabledAnalytics: true },
) => {
  return useQueries([
    {
      staleTime: Infinity,
      queryKey: ["tutorials"],
      queryFn: () => getDashboardTutorials().then((res) => res?.data?.data),
    },
    {
      // staleTime: Infinity,
      enabled: params?.enabledAnalytics,
      queryKey: ["inventoryAnalyticsData", params?.queries],
      queryFn: () =>
        getAnalyticsData(params?.queries).then((res) => res?.data?.data),
    },
    {
      staleTime: Infinity,
      queryKey: ["setupGuideCategories"],
      queryFn: () =>
        getDashboardSetUpGuideCategories().then((res) => res?.data?.data),
    },
  ]);
};

export const useGetOrderNofication = () => {
  return useQuery(
    ["notifications"],
    () => getNotifications().then((res) => res?.data?.data),
    { staleTime: Infinity },
  );
};
