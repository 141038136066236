import { useState } from "react";
import { useUrlSearch } from "../../../../../hooks/useUrlSearch";
import { useGetShippingsList } from "../../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../../helpers";
import { useSearchParams } from "react-router-dom";

const useAllShippings = () => {
  const queries = getUrlQuerysection();
  const [isShippingForm, setIsShippingForm] = useState(false);
  const { gotoPage } = useUrlSearch();
  const [search, setSearch] = useSearchParams();
  const {
    isLoading: isLoadingShippings,
    data: shippings,
    refetch: handleFetchShippings,
  } = useGetShippingsList({ queries });

  const searchShipping = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isLoadingShippings,
    shippings: shippings?.data,
    metaDetails: shippings || {},
    isShippingForm,
    search,
    searchShipping,
    setIsShippingForm,
    gotoPage,
    handleFetchShippings,
  };
};
export default useAllShippings;
