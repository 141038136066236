import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlatform } from "../../../helpers";
import { platformHandler } from "../../../hooks/context";
import { startReconciliations } from "../../../services/inventory";

const useReconcilePrompt = ({ handleFetchReconciliations, closeModal }) => {
  const [reconsileDetails, setReconsileDetails] = useState({ store: "" });
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const { mutate: beginReconcile, isLoading } = useMutation((data) =>
    startReconciliations(data),
  );
  const reconcile = () => {
    beginReconcile(
      { store: reconsileDetails?.store },
      {
        onSuccess: (res) => {
          handleFetchReconciliations();
          navigate(
            `${getPlatform(platform)}/inventory/reconciliations/${
              res?.data?.data?.id
            }/edit?store_id=${res?.data?.data?.store?.id}`,
          );
          closeModal();
        },
      },
    );
  };

  return {
    isLoading,
    reconsileDetails,
    storeList,
    setReconsileDetails,
    reconcile,
  };
};
export default useReconcilePrompt;
