import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import styles from "./ProfitAndLost.module.scss";
import useProfitAndLost from "./useProfitAndLost";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pathConstants } from "../../../../routes/pathContants";
import { formatCurrency } from "../../../../helpers";

const ProfitAndLost = () => {
  const navigate = useNavigate();
  const {
    startDate,
    endDate,
    storeList,
    currentStore,
    profitAndLostData,
    isFetchingProfitAndLostData,
    storeHasMultipleCurrency,
    setCurrentStore,
    filterByStoreId,
    setDateFilter,
  } = useProfitAndLost();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Profit and Lost Report"
      description="View profit and lost report"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={styles.profitandloss_details}
        >
          <div className={styles.title_container}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(pathConstants.ACCOUNTING_OVERVIEW)}
            >
              <FontAwesomeIcon icon="angle-left" style={{ fontSize: "18px" }} />
            </primaryComponents.Button>
            <div className={styles.title_content}>
              <h4 className="mb-0">Profit and Loss</h4>
              <p className={`${styles.sub_title} small-text-size`}>
                Analyzing Profitability and Expenditure Trends
              </p>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.picker_container}>
              <div className={styles.profitAndLost__storePicker}>
                <primaryComponents.SelectField
                  options={
                    storeHasMultipleCurrency
                      ? [...storeList]
                      : [
                          {
                            name: "All stores",
                            id: "ALL_STORES",
                          },
                          ...storeList,
                        ]
                  }
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    setCurrentStore(value);
                    filterByStoreId(value);
                  }}
                  value={
                    storeList?.find(
                      (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                    )?.name || "All stores"
                  }
                  floatError={false}
                  classNames="store-select"
                />
              </div>
              <div className={styles.profitAndLost__datePicker}>
                <primaryComponents.DateRangeInput
                  startDate={profitAndLostData?.start_date || startDate}
                  endDate={profitAndLostData?.end_date || endDate}
                  onChange={setDateFilter}
                  showPlaceholder={true}
                />
              </div>
            </div>

            {/* profit and lost */}
            <div className={styles.profitAndLost__summary}>
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingProfitAndLostData}
                classNames="mt-4 mb-4"
              >
                <div className={styles.profitAndLost__summary_detail}>
                  <div
                    className={`${styles.header} d-flex justify-content-between align-items-center mb-1 m-1`}
                  >
                    <h5 className="pl-3 m-2 default-font-size">Revenue</h5>
                    <h5 className="pl-3 m-3 default-font-size">
                      {formatCurrency({
                        value: profitAndLostData?.revenues || 0,
                        currencyCode: profitAndLostData?.store_currency_code,
                      })}
                    </h5>
                  </div>

                  <secondaryComponents.Tree
                    data={profitAndLostData}
                    color={"#FCDEB1"}
                    title={"Cost of Sales"}
                    tipText={"Cost of Goods Available - Closing Stock"}
                  />
                  <secondaryComponents.Tree
                    data={profitAndLostData}
                    color={"#A5A6F6"}
                    title={"Gross Profit"}
                    tipText={"Revenues - Cost of Sales"}
                  />

                  {/* <secondaryComponents.ProfitAndLostListItem
                    title={"Inventory Surplus"}
                    value={profitAndLostData?.inventory_surplus}
                    store_currency_code={profitAndLostData?.store_currency_code}
                  />
                  <hr />
                  <secondaryComponents.ProfitAndLostListItem
                    title={"Inventory Losses"}
                    value={profitAndLostData?.inventory_losses}
                    store_currency_code={profitAndLostData?.store_currency_code}
                  /> */}
                  <hr />
                  <secondaryComponents.Tree
                    data={profitAndLostData}
                    color={"#FFFFFF"}
                    title={"Expenses"}
                  />
                  <hr />
                  <secondaryComponents.ProfitAndLostListItem
                    title={"Discounts"}
                    value={profitAndLostData?.discounts}
                    store_currency_code={profitAndLostData?.store_currency_code}
                  />
                  <hr />
                  <secondaryComponents.ProfitAndLostListItem
                    title={"Returns"}
                    value={profitAndLostData?.returns}
                    store_currency_code={profitAndLostData?.store_currency_code}
                  />
                  <secondaryComponents.Tree
                    data={profitAndLostData}
                    color={"#C5C5C5"}
                    title={"Net Earnings Before Tax"}
                    tipText={
                      "Gross Profit + Inventory Surplus - Inventory Losses - Expenses - Discounts - Returns"
                    }
                  />
                </div>
              </secondaryComponents.LoaderHelper>
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default ProfitAndLost;
