import { Outlet } from "react-router-dom";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { useSelector } from "react-redux";

const AllTransfers = () => {
  const { role: business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const [showStatementOfAccount, setShowStatementOfAccount] = useState(false);
  const [openTransferWidget, setOpenTransferWidget] = useState(false);
  const [terminalHeader, setTerminalHeader] = useState({
    title: "",
    description: "",
  });

  return (
    <>
      <div className={globalStyles.pageColumnGaps}>
        <div>
          <h4 className="mb-0">{terminalHeader?.title}</h4>
          <p>{terminalHeader?.description}</p>
        </div>

        <div className="d-flex ml-auto">
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Payments"}
            permissionChildKey="can_request_transfer"
          >
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => setOpenTransferWidget(true)}
            >
              {business_role !== "OWNER" && business_role !== "ADMIN"
                ? "Request Transfer"
                : "Transfer Funds"}
            </primaryComponents.Button>
          </secondaryComponents.NavigateWithPermission>

          <primaryComponents.Button
            classNames="ml-2 btn btn--primary"
            onClick={() => setShowStatementOfAccount(true)}
          >
            Statement
            <FontAwesomeIcon icon="download" className="ml-3" />
          </primaryComponents.Button>
        </div>

        <secondaryComponents.SubAccountsList />

        {/* outlet section */}
        <Outlet context={{ setTerminalHeader }} />
      </div>

      {/* transfer widget */}
      <secondaryComponents.SideModal
        isActive={openTransferWidget}
        closeWidget={() => setOpenTransferWidget(false)}
      >
        <secondaryComponents.FundTransfer closeWidget={setOpenTransferWidget} />
      </secondaryComponents.SideModal>

      <secondaryComponents.SideModal
        isActive={showStatementOfAccount}
        closeModal={() => setShowStatementOfAccount(false)}
      >
        <secondaryComponents.StatementOfAccount
          closeWidget={() => setShowStatementOfAccount(false)}
        />
      </secondaryComponents.SideModal>
    </>
  );
};
export default AllTransfers;
