import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export const useStockTransfer = ({ setTransferData, otherTransferInfo }) => {
  const [search, setSearch] = useSearchParams();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [storeError, setStoreError] = useState("");
  const handleAppendTransferData = (item) => {
    setTransferData((prevData) => [
      ...prevData,
      {
        name: item?.name,
        product_id: item?.product_id,
        variant_id: item?.variant_id,
        stock_quantity: item?.stock_quantity,
        variant_name: item?.variant,
        unit: item?.unit,
        quantity: "",
      },
    ]);
  };

  const filterByStore = (value) => {
    search.set("store_id", value);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (
      otherTransferInfo?.transfer_from_store_id &&
      otherTransferInfo?.transfer_from_store_id !== ""
    )
      filterByStore(otherTransferInfo?.transfer_from_store_id);
  }, [otherTransferInfo?.transfer_from_store_id]);

  return {
    storeList,
    storeError,
    setStoreError,
    handleAppendTransferData,
    filterByStore,
  };
};
