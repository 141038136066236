import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Unit name is required"),
  unit: yup.string().required("Unit is required"),
});

export const useValidation = ({ unitDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      ...unitDetails,
    },
  });
};
