import styles from "./SubscriptionPlans.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import useSubscriptionPlans from "./useSubscriptionPlans";
import React from "react";
import { PERIOD } from "../../../../../helpers/constants";

const SubscriptionPlans = () => {
  const {
    isLoading,
    subscriptionTiers,
    subscriptionPeriod,
    isPayModal,
    upgradeDetails,
    activeSubscription,
    setUpgradeDetails,
    setIsPayModal,
    setSubscriptionPeriod,
  } = useSubscriptionPlans();
  const navigate = useNavigate();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Subscription"
      description="View subscription plans"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={styles.subscriptionPlans}
        >
          <div className="mb-3">
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          {/* period toggler */}
          <secondaryComponents.SubscriptionPeriod
            subscriptionPeriod={subscriptionPeriod}
            setSubscriptionPeriod={setSubscriptionPeriod}
          />

          <secondaryComponents.SubscriptionPlanHeader
            subscriptionTiers={subscriptionTiers}
            activeSubscription={activeSubscription}
            subscriptionPeriod={subscriptionPeriod}
            setUpgradeDetails={setUpgradeDetails}
            setIsPayModal={setIsPayModal}
          />

          {/* Inventory */}
          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isLoading}
          >
            <div className={styles.subscriptionPlans__desktop}>
              <secondaryComponents.PlanInventory
                subscriptionPeriod={subscriptionPeriod}
                subscription={subscriptionTiers?.[0]}
              />
            </div>
            <div className={styles.subscriptionPlans__mobile}>
              {subscriptionTiers?.map((item, index) => (
                <React.Fragment key={item?.name}>
                  <secondaryComponents.FreePlanMobileView
                    subscription={subscriptionTiers?.[index]}
                    activeSubscription={activeSubscription}
                    subscriptionPeriod={subscriptionPeriod}
                    setUpgradeDetails={setUpgradeDetails}
                    setIsPayModal={setIsPayModal}
                    accessKey={
                      index === 0
                        ? "free"
                        : index === 1
                          ? "growth"
                          : index === 2
                            ? "essential"
                            : index === 3
                              ? "scale"
                              : ""
                    }
                  />
                </React.Fragment>
              ))}
            </div>
          </secondaryComponents.LoaderHelper>

          {/* subscription payment section */}
          <secondaryComponents.SideModal
            isActive={isPayModal}
            closeModal={() => setIsPayModal(false)}
          >
            <secondaryComponents.SubscriptionPay
              upgradeDetails={upgradeDetails}
              subscriptionPeriod={subscriptionPeriod}
              setSubscriptionPeriod={setSubscriptionPeriod}
              period={PERIOD}
              subscriptionTiers={subscriptionTiers}
              setIsPayModal={setIsPayModal}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default SubscriptionPlans;
