import Icon from "../assets/icons";

export const MONTHS_OF_YEAR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTHS_OF_YEAR_FULL = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_OF_WEEK = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const COLORS = [
  "#F7AD3C",
  "#FD561F",
  "#7647EE",
  "#19AE57",
  "#EF5DA8",
  "#7879F1",
  "#55D6FF",
  "#AD91F5",
  "#E28424",
  "#65F5A9",
  "#FE9A79",
  "#C724E2",
];

export const DOCUMENT_UPLOAD_STATUS = {
  pending_approval: "pending",
  rejected: "rejected",
  approved: "approved",
};

export const PRODUCT_DEFAULT_ICONS = [
  Icon.productIcon1,
  Icon.productIcon2,
  Icon.productIcon3,
  Icon.productIcon4,
  Icon.productIcon5,
];

export const GRID_PRODUCT_DEFAULT_ICONS = [
  Icon.productAvatar1,
  Icon.productAvatar2,
  Icon.productAvatar3,
  Icon.productAvatar4,
  Icon.productAvatar5,
];

export const PERIOD = {
  ONE_MONTH: "month",
  ONE_YEAR: "year",
};

export const BOOLEAN_LABEL = {
  false: "No",
  true: "Yes",
};

export const PROPERTIES = {
  cost_price: "",
  selling_price: "",
  minimum_stock_quantity: 0,
  stock_quantity: 0,
  expiry_date: "",
  supplier_id: "",
  discount_rate: "",
  store_id: null,
  store_name: null,
};

export const PRODUCT_VARIANT = {
  option: "",
  value: "",
  barcode: "",
  store_properties: [],
};

export const DEFAULT_PRICING_ID = 1;
