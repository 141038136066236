const brandCategories = [
  { value: "Restaurant", label: "Restaurant" },
  { value: "Supermarket", label: "Supermarket" },
  { value: "Grocery stores", label: "Grocery stores" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Fashion Store", label: "Fashion Store" },
  { value: "Retail Stores", label: "Retail Stores" },
];

export default brandCategories;
