import { useEffect, useRef, useState } from "react";
import { useGetAllProductsList } from "../../../hooks/api/queries";

const useProductSearch = ({
  orderDetails,
  filterByStore,
  filterByComposite,
  excludeManageModeOnlyProducts,
}) => {
  const [isDropDown, setIsDropDown] = useState(false);
  const serachRef = useRef(null);
  const [queries, setQueries] = useState(
    (() => {
      let params = "?";
      if (filterByStore)
        params = `${params}store_id=${orderDetails?.store?.id}`;

      if (filterByComposite) {
        if (params === "?") params = `${params}include_composite=false`;
        else params = `${params}&include_composite=false`;
      }

      if (excludeManageModeOnlyProducts) {
        if (params === "?")
          params = `${params}exclude_manage_mode_only_products=${true}`;
        else params = `${params}&exclude_manage_mode_only_products=${true}`;
      }
      return params;
    })(),
  );
  const [isVariantOpenId, setIsVariantOpenId] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (serachRef.current && !serachRef.current.contains(event.target))
        setIsDropDown(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [serachRef]);

  const { data, isLoading } = useGetAllProductsList({
    queries,
    enabled: isDropDown,
  });

  const searchProduct = (value) => {
    if (queries === "?") setQueries(`${queries}query=${value}`);
    else setQueries(`${queries}&query=${value}`);
  };

  return {
    serachRef,
    isLoading,
    productList: data?.data || [],
    isDropDown,
    isVariantOpenId,
    setIsDropDown,
    searchProduct,
    setIsVariantOpenId,
  };
};
export default useProductSearch;
