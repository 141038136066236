import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./CheckItem.module.scss";

const CheckItem = ({ children }) => {
  return (
    <div className={styles.checkItem}>
      <span className={styles.checked}>
        <FontAwesomeIcon icon="check" />
      </span>
      {children}
    </div>
  );
};
export default CheckItem;
