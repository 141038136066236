import React from "react";
import Images from "../../../assets/images";
import secondaryComponents from "../../../components/secondaryComponents";
import styles from "./ProductsPage.module.scss";

const ProductsGridList = ({
  products,
  handleAddToCart,
  cartItems,
  handleReduceCartItem,
  getCartItems,
  pricingType,
}) => {
  return (
    <>
      {products?.length > 0 ? (
        <div className={styles.productsGridList}>
          {products?.map((product, index) => (
            <React.Fragment key={product?.id}>
              {product.variants?.length > 0 ? (
                <secondaryComponents.ProductGridVariantCard
                  product={product}
                  key={product?.id}
                  handleAddToCart={handleAddToCart}
                  cartItems={cartItems}
                  handleReduceCartItem={handleReduceCartItem}
                  productIndex={index}
                  pricingType={pricingType}
                />
              ) : (
                <secondaryComponents.ProductGridCard
                  product={product}
                  key={product?.id}
                  cartDetails={getCartItems(product?.id)}
                  handleAddToCart={handleAddToCart}
                  handleReduceCartItem={handleReduceCartItem}
                  productIndex={index}
                  pricingType={pricingType}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div style={{ marginTop: "100px" }} className="text-center">
          <img src={Images.productsEmptyState} alt="" width={"250px"} />
          <h5 className="mt-3 text-center">No data available</h5>
        </div>
      )}
    </>
  );
};

export default ProductsGridList;
