import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../helpers";
import {
  useGetOrderNofication,
  useMainDashboardData,
} from "../../../hooks/api/queries/useDashboard";
import useGetPermission from "../../../hooks/useGetPermission";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const MainDashboardLogic = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const { storeHasMultipleCurrency } = useStoreCurrency();
  const {
    storeList,
    profileDetails: { role: business_role, first_name },
    businessDetails: { currency_code },
  } = useSelector((state) => state.profileDetailsReducer);
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails,
  );

  const { data: dataNotifications } = useGetOrderNofication();

  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [startDate, setStartDate] = useState(queryParams.get("start_date"));
  const [endDate, setEndDate] = useState(queryParams.get("end_date"));
  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );
  const [activeModals, setActiveModals] = useState({
    isProductWidget: false,
    openTransferWidget: false,
    isGetStartedVideo: {
      isActive: false,
      title: "",
      url: "",
      setupGuideCategories: [],
      isGetStarted: false,
    },
    isRestock: false,
    isEndofday: false,
    isReturnProduct: false,
    isTransferProduct: false,
  });
  const { isPermitted: isPermittedToAnalytics } = useGetPermission({
    permissionChildKey: "can_view_analytics",
    permissionParentKey: "Inventory Management",
  });

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      search.set("store_id", storeList[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  const [tutorials, inventoryAnalyticsData, setupGuideCategories] =
    useMainDashboardData({
      queries,
      enabledAnalytics: isPermittedToAnalytics,
    });

  const setDateFilter = (data) => {
    search.set("start_date", data?.startDate);
    search.set("end_date", data?.endDate);
    setSearch(search, { replace: true });
  };

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const closeModal = (key) => {
    setActiveModals((prev) => ({
      ...prev,
      [key]: false,
    }));
  };

  const openModal = (key) => {
    setActiveModals((prev) => ({
      ...prev,
      [key]: true,
    }));
  };

  useEffect(() => {
    if (!inventoryAnalyticsData?.isLoading) {
      setStartDate(inventoryAnalyticsData?.data?.[0]?.start_date);
      setEndDate(inventoryAnalyticsData?.data?.[0]?.end_date);
    }
  }, [inventoryAnalyticsData?.data?.[0], setupGuideCategories?.data]);

  return {
    dataNotifications,
    first_name,
    business_role,
    startDate,
    endDate,
    storeList,
    currentStore,
    isFetchingTutorials: tutorials?.isLoading,
    isFetchingAnalytics: inventoryAnalyticsData?.isLoading,
    tutorials: tutorials?.data,
    setupGuideCategories: setupGuideCategories?.data,
    analyticsData: inventoryAnalyticsData?.data?.[0] || {},
    verification_status,
    activeModals,
    search,
    currency_code,
    showNotificationModal,
    openModal,
    closeModal,
    setActiveModals,
    setCurrentStore,
    setDateFilter,
    setShowNotificationModal,
    filterByStoreId,
  };
};

export default MainDashboardLogic;
