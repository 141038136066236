import { useMemo, useState } from "react";
import { useTable } from "react-table";
import { convertToTilteCase, formatCurrency } from "../../../helpers";
import { Link } from "react-router-dom";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import "./CreditLogTable.scss";

export const useCreditLogTable = ({ creditLog }) => {
  const [selectedCredit, setSelectedCredit] = useState({});
  const [isUpdateCreditWidget, setIsUpdateCreditWidget] = useState(false);
  const [addDebt, setAddDebt] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "full_name",
        Cell: ({ cell }) =>
          cell ? (
            <>
              <Link
                className="purple--text"
                to={`${cell.row.original["id"]}`}
                style={{ fontSize: "12px" }}
              >
                {cell.value ? convertToTilteCase(cell.value) : "N/A"}
              </Link>
              <br />
              <span>{cell?.row?.original?.phone}</span>
            </>
          ) : (
            "N/A"
          ),
      },
      // {
      //   Header: "Phone Number",
      //   accessor: "phone",
      //   Cell: ({ cell }) => (cell.value ? cell.value : "N/A"),
      // },
      {
        Header: "Credit Limit",
        accessor: "credit_limit",
        Cell: ({ cell }) =>
          formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          }),
      },
      {
        Header: "Credit Till Date",
        accessor: "total_credit",
        Cell: ({ cell }) =>
          formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          }),
      },
      {
        Header: "Payments",
        accessor: "total_repayment",
        Cell: ({ cell }) =>
          formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          }),
      },
      {
        Header: "Balance",
        accessor: "outstanding_balance",
        Cell: ({ cell }) => (
          <span
            style={{
              color: cell?.value <= 0 ? "#19AE57" : "#E02020",
            }}
          >
            {formatCurrency({
              value:
                cell?.value <= 0
                  ? Math.abs(cell?.value)
                  : -Math.abs(cell?.value),
              currencyCode: cell?.row?.original?.store?.currency_code,
            })}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "",
        Cell: ({ cell }) => {
          const balance = cell?.row?.original?.outstanding_balance;
          if (balance > 0) {
            return "Debt Owed";
          } else if (balance < 0) {
            return "Excess Deposit";
          } else {
            return "Settled";
          }
        },
      },
      {
        Header: "Actions",
        accessor: "action",
        Cell: ({ cell: { row } }) =>
          row?.depth === 1 ? null : (
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_and_update_credits"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  position: "relative",
                }}
              >
                <primaryComponents.Button
                  classNames="btn btn--success smallBtn"
                  onClick={() => {
                    setSelectedCredit(row?.original);
                    setIsUpdateCreditWidget(true);
                  }}
                >
                  Add Payment
                </primaryComponents.Button>
                <primaryComponents.Button
                  classNames="btn btn--outline-red smallBtn"
                  onClick={() => {
                    setSelectedCredit(row?.original);
                    setAddDebt(true);
                  }}
                >
                  Add Debt
                </primaryComponents.Button>
              </div>
            </secondaryComponents.NavigateWithPermission>
          ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: creditLog });

  return {
    selectedCredit,
    instance,
    isUpdateCreditWidget,
    addDebt,
    setIsUpdateCreditWidget,
    setAddDebt,
    setSelectedCredit,
  };
};
