import { useParams } from "react-router-dom";
import { getSuppliersDetails } from "../../../../services/suppliers";

import { useQuery } from "react-query";

const useEditSupplier = () => {
  const { id: supplierId } = useParams();

  const { data, isLoading: isFetchingSupplierInfo } = useQuery(
    ["getSuppliersDetails", supplierId],
    () => getSuppliersDetails(supplierId).then((res) => res.data?.data),
    {
      cacheTime: 0,
    },
  );

  return {
    data,
    isFetchingSupplierInfo,
  };
};

export default useEditSupplier;
