import { useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";
import secondaryComponents from "..";
import { useSearchParams } from "react-router-dom";
import { UpDownArrow } from "../../../assets/svgIcons";

export const usePerformingProductsLogic = ({ products }) => {
  const [search, setSearch] = useSearchParams();
  const columns = useMemo(() => {
    return [
      {
        Header: "No.",
        accessor: (_, index) => {
          return index + 1;
        },
      },
      {
        Header: (
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Name</span>
            <secondaryComponents.ToolTipV2
              tipText={
                search.get("order_by") === "name"
                  ? "Sort descendly"
                  : search.get("order_by") === "-name"
                    ? "Sort ascendly"
                    : "Sort ascendly"
              }
              position="right"
            >
              <span
                className="cursor-pointer"
                onClick={() =>
                  sortBy(
                    "order_by",
                    search.get("order_by") === "name" ? "-name" : "name",
                  )
                }
              >
                <UpDownArrow />
              </span>
            </secondaryComponents.ToolTipV2>
          </span>
        ),
        accessor: "name",
      },
      {
        Header: (
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Revenue</span>
            <secondaryComponents.ToolTipV2
              tipText={
                search.get("order_by") === "revenue"
                  ? "Sort descendly"
                  : search.get("order_by") === "-revenue"
                    ? "Sort ascendly"
                    : "Sort ascendly"
              }
              position="right"
            >
              <span
                className="cursor-pointer"
                onClick={() =>
                  sortBy(
                    "order_by",
                    search.get("order_by") === "revenue"
                      ? "-revenue"
                      : "revenue",
                  )
                }
              >
                <UpDownArrow />
              </span>
            </secondaryComponents.ToolTipV2>
          </span>
        ),
        accessor: "revenue",
        Cell: ({ cell }) => {
          return (
            formatCurrency({
              value: cell.value,
              currencyCode: cell?.row?.original?.store_currency_code,
            }) || "N/A"
          );
        },
      },
      {
        Header: (
          <span style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>Sales Count</span>
            <secondaryComponents.ToolTipV2
              tipText={
                search.get("order_by") === "sales_count"
                  ? "Sort descendly"
                  : search.get("order_by") === "-sales_count"
                    ? "Sort ascendly"
                    : "Sort ascendly"
              }
              position="right"
            >
              <span
                className="cursor-pointer"
                onClick={() =>
                  sortBy(
                    "order_by",
                    search.get("order_by") === "sales_count"
                      ? "-sales_count"
                      : "sales_count",
                  )
                }
              >
                <UpDownArrow />
              </span>
            </secondaryComponents.ToolTipV2>
          </span>
        ),
        accessor: "sales_count",
      },
    ];
  }, [search.get("order_by")]);

  const sortBy = (key, value) => {
    search.set(key, value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const instance = useTable({ columns, data: products });

  return { instance };
};
