import styles from "./FoodMenuTable.module.scss";
import { useFoodMenuTable } from "./useFoodMenuTable";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import Images from "../../../assets/images";

const FoodMenuTable = ({
  menuItems,
  selectedMenuItems,
  setSelectedMenuItems,
  autoScrollRef,
  menuProperties,
  selectedPricingType,
}) => {
  const { selectAllItems, setSelectAllItems, instance } = useFoodMenuTable({
    menuItems,
    menuProperties,
    selectedMenuItems,
    setSelectedMenuItems,
    selectedPricingType,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.foodMenuTable}>
      <div className={styles.selectAll}>
        <p className="mr-2">Select all items</p>
        <primaryComponents.Checkbox
          name="selectAll"
          id="selectAll"
          isChecked={selectAllItems}
          onChange={(value) => setSelectAllItems(value)}
        />
      </div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        autoScrollRef={autoScrollRef}
        handleScroll={true}
      >
        <div className="text-center mt-5 mb-5">
          <img src={Images.productsEmptyState} width="250px" alt="" />
          <h4 className="font-weight-semibold text-center mt-4">
            You have no products
          </h4>
          <p className="text-center">When you do, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>
    </div>
  );
};

export default FoodMenuTable;
