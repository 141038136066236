/* eslint-disable react/jsx-key */
import "./TerminalRequestTable.scss";
import useTerminalRequestTable from "./TerminalRequestTableLogic";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import Images from "../../../assets/images";

const TerminalRequestTable = () => {
  const { instance, isLoading } = useTerminalRequestTable();
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="terminal-request-table">
      <div className="terminal-request-table__header">
        {/* header section */}
        <div style={{ minWidth: "250px", width: "700px" }}>
          <primaryComponents.SearchField
            trigger={() => {}}
            value=""
            placeholder="Search for a terminal"
          />
        </div>
      </div>
      <div className="table-section">
        <secondaryComponents.LoaderHelper
          isLoading={isLoading}
          classNames="mt-5"
        >
          <secondaryComponents.TableHelper
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          >
            <div className="text-center mt-5 mb-5">
              <img
                width="250px"
                height="262px"
                src={Images.tnxEmptyState}
                alt="empty state"
              />
              <h4>No data available</h4>
            </div>
          </secondaryComponents.TableHelper>
        </secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};

export default TerminalRequestTable;
