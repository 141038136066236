/* eslint-disable react/jsx-key */
import "./TopFiveCustomersTable.scss";
import { Link } from "react-router-dom";
import useTopFiveCustomersTable from "./TopFiveCustomersTableLogic";
import secondaryComponents from "..";
import Images from "../../../assets/images";
import { getPlatform } from "../../../helpers";
import { useContext } from "react";
import { platformHandler } from "../../../hooks/context";

const TopFiveCustomersTable = ({ isLoadingTopCustomers, topCustomers }) => {
  const { instance, getRouteQuery } = useTopFiveCustomersTable({
    topCustomers,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;
  const { platform } = useContext(platformHandler);

  return (
    <div className="top-five-customers-table">
      <div className="top-five-customers-table__header">
        <h4 className="font-weight-semibold" style={{ fontSize: "20px" }}>
          Top 5 Customers
        </h4>
        <Link
          style={{ fontSize: "14px", color: "#7647EE" }}
          to={`${getPlatform(platform)}/customers${getRouteQuery()}`}
        >
          View more
        </Link>
      </div>
      <secondaryComponents.LoaderHelper
        isLoading={isLoadingTopCustomers}
        classNames="mt-5 mb-5"
      >
        {topCustomers.length > 0 ? (
          <div className="top-five-customers-table__section">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getFooterGroupProps()}>
                    <th className="font-weight-semibold">No.</th>
                    {headerGroup.headers.map((column) => {
                      return (
                        <th
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps({ key: i })}>
                      <td>{i + 1}</td>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center mt-5">
            <img
              src={Images.customersEmptyState}
              alt="No Customers Found"
              width="150px"
              //height="px"
            />
            <p className="font-weight-semibold mt-3 dark--text">
              No data available
            </p>
          </div>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default TopFiveCustomersTable;
