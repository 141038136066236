import { useContext, useEffect } from "react";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { DeleteIcon } from "../../../assets/svgIcons";
import styles from "./Loyalty.module.scss";
import { useValidation } from "./useLoyaltyValidation";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLayalty } from "./useLoyalty";
import { ToastContext } from "../../../hooks/context";

const Loyalty = ({
  setLoyaltyDetails,
  loyaltyDetails,
  currency_symbol,
  closeModal,
}) => {
  const triggerToast = useContext(ToastContext);
  const {
    dataCustomerWallet,
    dataValidate,
    isLoadingValidate,
    mutateValidate,
  } = useLayalty({ loyaltyDetails });

  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
    register,
    setValue,
    setError,
  } = useValidation({
    loyaltyDetails,
  });

  const handleApplyLoyalty = () => {
    if (loyaltyDetails.loyaltyType === "GIFT_CARD" && !dataValidate) {
      return triggerToast(
        "Click 'Retrieve' to retrieve gift card details",
        "warning",
      );
    }

    if (
      dataValidate &&
      Number(loyaltyDetails.giftcard_amount) >
        dataValidate.data.data.giftcard_available_balance
    ) {
      return triggerToast(
        "Gift card amount is greater than available balance",
        "warning",
      );
    }

    if (
      dataCustomerWallet &&
      Number(loyaltyDetails.loyalty_point_amount) >
        dataCustomerWallet.balance_naira_equiv
    ) {
      return triggerToast(
        "Loyalty point amount is greater than available balance",
        "warning",
      );
    }

    closeModal();
  };

  useEffect(() => {
    setValue("loyaltyType", loyaltyDetails.loyaltyType);

    if (loyaltyDetails.customer_id.length > 0) {
      setError("customer_id", "");
    }
  }, [loyaltyDetails]);

  return (
    <form
      onSubmit={handleSubmit(handleApplyLoyalty)}
      className={styles.container}
    >
      <h4 className={styles.heading}>Apply Loyalty</h4>
      <div className={styles.checkboxContainer}>
        <primaryComponents.BoxedRadioInput
          value={loyaltyDetails.loyaltyType}
          name={"loyaltyType"}
          id="GIFT_CARD"
          isChecked={loyaltyDetails.loyaltyType === "GIFT_CARD"}
          classNames={styles.checkbox}
          setRadioValue={() => {
            setLoyaltyDetails((prev) => ({
              ...prev,
              loyaltyType: "GIFT_CARD",
            }));
          }}
        >
          Gift Card
        </primaryComponents.BoxedRadioInput>
        <primaryComponents.BoxedRadioInput
          value={loyaltyDetails.loyaltyType}
          name={"loyaltyType"}
          id="LOYALTY_POINT"
          isChecked={
            loyaltyDetails.loyaltyType === "LOYALTY_POINT" ? true : false
          }
          classNames={styles.checkbox}
          setRadioValue={() => {
            setLoyaltyDetails((prev) => ({
              ...prev,
              loyaltyType: "LOYALTY_POINT",
            }));
          }}
        >
          Loyalty
        </primaryComponents.BoxedRadioInput>
      </div>

      {loyaltyDetails.loyaltyType === "GIFT_CARD" ? (
        <div className={styles.contentContainer}>
          <div>
            <label>
              <span className="text-danger">*</span>
              <span>Gift Card Code</span>
            </label>
            <Controller
              name={"redemption_code"}
              control={control}
              render={({ field: { onChange: onControllerChange } }) => (
                <div className={styles.giftCardInputContainer}>
                  <input
                    name="redemption_code"
                    className={styles.giftCardInput}
                    type="text"
                    placeholder="Enter gift card code"
                    {...register("redemption_code", { required: true })}
                    onChange={(val) => {
                      onControllerChange(val.target.value);
                      setLoyaltyDetails((prev) => ({
                        ...prev,
                        redemption_code: val.target.value,
                      }));
                    }}
                  />
                  <primaryComponents.Button
                    classNames={"btn btn--outline smallBtn"}
                    onClick={() =>
                      mutateValidate({
                        redemption_code: loyaltyDetails.redemption_code,
                      })
                    }
                    isDisabled={
                      loyaltyDetails.redemption_code.length === 0 ||
                      isLoadingValidate
                    }
                  >
                    {isLoadingValidate ? "Validating..." : "Retrieve"}
                  </primaryComponents.Button>
                </div>
              )}
            />
            {errors.redemption_code && errors.redemption_code.message && (
              <p className="error-message text-danger small-text-size">
                <FontAwesomeIcon
                  icon={["fas", "exclamation-circle"]}
                  className="mr-2"
                  style={{ fontSize: "13px" }}
                />
                <span>{errors.redemption_code.message}</span>
              </p>
            )}
          </div>
          <div
            className={globalStyles.cardWithBackground}
            style={{ background: "rgba(243, 243, 243, 1)" }}
          >
            <h6 className="mb-0">Gift Card Details</h6>
            <div className="d-flex w-100" style={{ gap: "10px" }}>
              {/* <div
                className={`${globalStyles.cardWithBackground} ${styles.whiteBackground} white w-100`}
              >
                 <div className={styles.availableBalanceContainer}>
                  <LoyaltyPoint />
                  <p className={styles.availableBalance}>Available Points</p>
                </div>
                <h5
                  className={`pending--text text-center mb-0 ${styles.balanceText}`}
                >
                  {dataValidate
                    ? `${dataValidate.data.data.points} points`
                    : "Enter Gift Card Code Above"}
                </h5>
              </div> */}
              <div
                className={`${globalStyles.cardWithBackground} ${styles.whiteBackground} white w-100`}
              >
                <div className={styles.availableBalanceContainer}>
                  <span className={styles.currencySymbol}>
                    ({`${currency_symbol}`})
                  </span>
                  <p className={styles.availableBalance}>Available Balance</p>
                </div>
                <h5 className="text-center mb-0">
                  {dataValidate
                    ? `${currency_symbol} ${dataValidate.data.data.giftcard_available_balance}`
                    : "-"}
                </h5>
              </div>
            </div>
          </div>
          <Controller
            control={control}
            name="giftcard_amount"
            defaultValue={loyaltyDetails.giftcard_amount}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={`Amount (${currency_symbol})`}
                classNames="white"
                placeholder="Enter amount to take out of your giftcard balance"
                isRequired={true}
                value={loyaltyDetails.giftcard_amount}
                type="number"
                min="0"
                name="giftcard_amount"
                floatError={false}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setLoyaltyDetails((prev) => ({
                    ...prev,
                    giftcard_amount: Number(value),
                  }));
                }}
                register={register}
                errorMessage={errors.giftcard_amount}
                onKeyUp={() =>
                  errors.giftcard_amount !== undefined &&
                  trigger("giftcard_amount")
                }
              />
            )}
          />
        </div>
      ) : (
        <div className={styles.contentContainer}>
          <div>
            <h6>Search Existing Customer</h6>
            <secondaryComponents.CustomersSearchField
              setCustomerDetails={(customer) => {
                setValue("customer_id", customer.customer_id);
                setLoyaltyDetails((prev) => ({
                  ...prev,
                  customer_id: customer.customer_id,
                  customerDetails: {
                    customerName: customer.customer_full_name,
                    customerPhoneNumber: customer.customer_phone_number,
                    customerPhoneCode: customer.customer_phone_code,
                    customerEmail: customer.customer_email,
                  },
                }));
              }}
              customerValue={loyaltyDetails.customerDetails?.customerName}
              title={"customer"}
            />
            {errors.customer_id && errors.customer_id.message && (
              <p className="error-message text-danger">
                <FontAwesomeIcon
                  icon={["fas", "exclamation-circle"]}
                  className="mr-2"
                  style={{ fontSize: "13px" }}
                />
                <span>{errors.customer_id.message}</span>
              </p>
            )}
            {loyaltyDetails.customerDetails?.customerName ? (
              <>
                <hr />

                <div
                  className="d-flex flex-wrap justify-content-between"
                  style={{ gap: "15px" }}
                >
                  <h6 className="mb-0">
                    {loyaltyDetails.customerDetails?.customerName}
                  </h6>
                  <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <div className="text-right">
                      <h6 className="mb-0 smallest-text-szie">
                        {loyaltyDetails.customerDetails?.customerPhoneNumber !==
                        ""
                          ? loyaltyDetails.customerDetails?.customerPhoneCode
                          : ""}{" "}
                        {loyaltyDetails.customerDetails?.customerPhoneNumber}
                      </h6>
                      <p className="smallest-text-szie mb-0">
                        {loyaltyDetails.customerDetails?.customerEmail}
                      </p>
                    </div>
                    <span
                      onClick={() => {
                        setValue("customer_id", "");
                        setLoyaltyDetails((prev) => ({
                          ...prev,
                          customer_id: "",
                          customerDetails: {
                            customerName: "",
                            customerPhoneNumber: "",
                            customerPhoneCode: "",
                            customerEmail: "",
                          },
                        }));
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <div
            className={globalStyles.cardWithBackground}
            style={{ background: "rgba(243, 243, 243, 1)" }}
          >
            <h6 className="mb-0">Loyalty Details</h6>
            <div className="d-flex w-100" style={{ gap: "10px" }}>
              {/* <div
                className={`${globalStyles.cardWithBackground} ${styles.whiteBackground} white w-100`}
              >
                <div className={styles.availableBalanceContainer}>
                  <LoyaltyPoint />
                  <p className={styles.availableBalance}>Available</p>
                </div>
                <h5
                  className={`pending--text text-center mb-0 ${styles.balanceText}`}
                >
                  {dataCustomerWallet
                    ? `${dataCustomerWallet.balance} points`
                    : "Select Customer Above"}
                </h5>
              </div> */}
              <div
                className={`${globalStyles.cardWithBackground} ${styles.whiteBackground} white w-100`}
              >
                <div className={styles.availableBalanceContainer}>
                  <span className={styles.currencySymbol}>
                    ({`${currency_symbol}`})
                  </span>
                  <p className={styles.availableBalance}>Available Balance</p>
                </div>
                <h5 className="text-center mb-0">
                  {dataCustomerWallet
                    ? `${currency_symbol} ${dataCustomerWallet.balance_naira_equiv}`
                    : "-"}
                </h5>
              </div>
            </div>
          </div>
          <Controller
            control={control}
            name="loyalty_point_amount"
            defaultValue={loyaltyDetails.loyalty_point_amount}
            render={({ field: { onChange } }) => (
              <primaryComponents.InputField
                label={`Amount (${currency_symbol})`}
                classNames="white"
                placeholder="Enter amount to take out of your giftcard balance"
                isRequired={true}
                value={loyaltyDetails.loyalty_point_amount}
                type="number"
                min="0"
                name="loyalty_point_amount"
                floatError={false}
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setLoyaltyDetails((prev) => ({
                    ...prev,
                    loyalty_point_amount: Number(value),
                  }));
                }}
                register={register}
                errorMessage={errors.loyalty_point_amount}
                onKeyUp={() =>
                  errors.loyalty_point_amount !== undefined &&
                  trigger("loyalty_point_amount")
                }
              />
            )}
          />
        </div>
      )}

      <primaryComponents.Button classNames={"btn btn--primary"} type="submit">
        Apply
      </primaryComponents.Button>
    </form>
  );
};
export default Loyalty;
