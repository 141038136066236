import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const businessSchema = yup
  .object({
    businessName: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Business name is required"),
    businessSize: yup.string().required("Business size is required"),
    businessEmail: yup
      .string()
      .email("Invalid email address")
      .max(40, "Maximum of 40 characters is allowed"),
    supportEmail: yup
      .string()
      .email("Invalid email address")
      .max(40, "Maximum of 40 characters is allowed"),
    businessPhoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format"),
    supportPhoneNumber: yup.string(),
    //.matches(/^[0-9]+$/, "Phone number must contain only digits")
    // .test(
    //     "num",
    //     "Invalid phone number format",
    //     (val) => !val.startsWith("0")
    // )
    // .min(10, "Invalid phone number format")
    // .max(10, "Invalid phone number format"),
    businessAddress: yup.string().max(200, "A maximum of 200 characters"),
  })
  .required();

const ProfileBusinessInfoFormValidation = (
  businessName,
  businessSize,
  businessEmail,
  supportEmail,
  businessPhoneNumber,
  supportPhoneNumber,
  businessAddress,
) => {
  return useForm({
    resolver: yupResolver(businessSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      businessName: businessName,
      businessSize: businessSize,
      businessEmail: businessEmail,
      supportEmail: supportEmail,
      businessPhoneNumber: businessPhoneNumber,
      supportPhoneNumber: supportPhoneNumber,
      businessAddress: businessAddress,
    },
  });
};

export default ProfileBusinessInfoFormValidation;
