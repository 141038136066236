export const HomeIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 10.4583V21.8333C1.5 22.4777 2.02233 23 2.66667 23H7.83333C8.47767 23 9 22.4777 9 21.8333V14H15V21.8333C15 22.4777 15.5223 23 16.1666 23H21.3333C21.9777 23 22.5 22.4777 22.5 21.8333V10.4583C22.5 10.0911 22.3271 9.74532 22.0333 9.525L12 2L1.96667 9.525C1.67289 9.74532 1.5 10.0911 1.5 10.4583Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
