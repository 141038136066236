import { useGetProductBatchDetails } from "../../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../../helpers";

const useEditProductBatch = () => {
  const queries = getUrlQuerysection();
  const { isLoading, data: batchInfo } = useGetProductBatchDetails(queries);

  return {
    isLoading,
    batchInfo,
  };
};

export default useEditProductBatch;
