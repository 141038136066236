import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const individualSchema = yup.object({
  business_category: yup.string().required("Business category is required"),
  business_description: yup
    .string()
    .required("Business description is required"),
  user_bvn: yup
    .string()
    .max(11, "11 digits is required")
    .required("BVN is required"),
  user_fullname: yup
    .string()
    .min(3, "minimum character is 3")
    .required("Please, enter your fullname"),
  business_state: yup.string().required("State is required"),
  business_lga: yup.string().required("Local government is required"),
  user_address: yup.string().required("Please, enter your address"),
  agree_terms: yup
    .boolean()
    .typeError("invalid format")
    .required("Please accept terms and conditions"),
});

const useIndividualKybFormValidation = (fields) => {
  return useForm({
    resolver: yupResolver(individualSchema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: { ...fields },
  });
};

export default useIndividualKybFormValidation;
