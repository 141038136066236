// import styles from "../styles.module.scss";
// import Icon from "../../../../../../assets/icons";
import AddCustomerSection from "../../StoreCheckout/AddCustomerSection";
import useStoreWalletDebit from "./useStoreWalletDebit";
import primaryComponents from "../../../primaryComponents";
import { Validation } from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../..";

const StoreWalletDebit = ({ setIsDebitWallet, customerDetails = {} }) => {
  const {
    debitDetails,
    storeList,
    isDebitingWallet,
    currencyCode,
    isOtpWidget,
    setIsOtpWidget,
    setDebitDetails,
    handleDebitWallet,
  } = useStoreWalletDebit({ setIsDebitWallet, customerDetails });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
    setValue,
    setError,
  } = Validation({ debitDetails });

  useEffect(() => {
    if (debitDetails?.customer) setError("customer", "");
    setValue("customer", debitDetails?.customer?.customerId);
  }, [debitDetails?.customer]);

  return (
    <>
      <div
        className={globalStyles.pageColumnGaps}
        style={{ minHeight: "100%" }}
      >
        <div>
          <primaryComponents.Button
            classNames={"btn btn--outline smallBtn"}
            onClick={() => setIsDebitWallet(null)}
          >
            <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
          </primaryComponents.Button>
        </div>

        <div>
          <h4 className="mb-0">Debit Wallet</h4>
          <p>
            Provide the following information to debit wallet for your customer.
          </p>
        </div>

        <form
          id="debitWalletForm"
          className={globalStyles.pageColumnGaps}
          style={{ gap: "10px" }}
          onSubmit={handleSubmit(handleDebitWallet)}
        >
          <div>
            <AddCustomerSection
              setCustomerDetails={(info) => {
                setDebitDetails((prev) => ({ ...prev, customer: info }));
              }}
              customerDetails={debitDetails?.customer}
              showAddCustomer={false}
              showLoyalty={false}
            />

            <p
              className={`smallest-text-size error-message text-danger ${errors.customer?.message ? "show" : "invisible"}`}
            >
              <FontAwesomeIcon
                icon={["fas", "exclamation-circle"]}
                className="mr-2"
                style={{ fontSize: "13px" }}
              />
              <span>{errors.customer?.message}</span>
            </p>
          </div>

          <primaryComponents.SelectFieldV2
            control={control}
            name="store"
            label={"Which store is the customer making the payment to?"}
            options={storeList}
            idKey="id"
            nameKey="name"
            isRequired={true}
            onChange={(value) => {
              setDebitDetails((prev) => ({ ...prev, store_id: value }));
            }}
            value={"Select store"}
            classNames="store-select"
            errorMessage={errors.store}
          />

          <primaryComponents.AmountFieldV2
            name="amount"
            control={control}
            label={"Debit Amount"}
            isRequired={true}
            placeholder="Enter total debit amount"
            value={debitDetails?.amount}
            onChange={({ target: { value } }) => {
              setDebitDetails((prev) => ({ ...prev, amount: value }));
            }}
            classNames="white"
            errorMessage={
              errors.amount && {
                message: errors.amount.message,
              }
            }
            onKeyUp={() =>
              errors.amount !== undefined && trigger("accountNumber")
            }
            currency_code={currencyCode}
          />

          <primaryComponents.TextAreaV2
            control={control}
            type="text"
            label={"Description"}
            name="note"
            placeholder="Add description for this debit transcation"
            value={debitDetails?.note}
            onChange={({ target: { value } }) => {
              setDebitDetails((prev) => ({ ...prev, description: value }));
            }}
            errorMessage={errors.note}
            onKeyUp={() => errors.note !== undefined && trigger("note")}
            register={register}
          />
        </form>

        <primaryComponents.Button
          classNames="btn btn--primary cover mt-auto"
          loadingText="Debiting..."
          type="submit"
          form="debitWalletForm"
          isLoading={isDebitingWallet}
          isDisabled={isDebitingWallet}
        >
          Proceed
        </primaryComponents.Button>
      </div>

      <secondaryComponents.Modal
        isActive={isOtpWidget !== null}
        closeModal={() => setIsOtpWidget(null)}
      >
        <secondaryComponents.StoreWalletOtpWidget
          debitSessionDetails={isOtpWidget}
          closeModal={() => setIsOtpWidget(null)}
          debitDetails={debitDetails}
          currencyCode={currencyCode}
          setIsDebitWallet={setIsDebitWallet}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default StoreWalletDebit;
