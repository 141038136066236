import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export const serviceSchema = yup.object({
  serviceName: yup.string().required("service name is required"),
  serviceCategory: yup.string().required("service category is required"),
  store: yup.string().required("Store is required"),
  costPrice: yup.string(),
  sellingPrice: yup.string().required("selling price is required"),
});

export const useAddSingleServiceValidation = ({
  serviceCategory,
  sellingPrice,
  serviceName,
  costPrice,
}) => {
  return useForm({
    resolver: yupResolver(serviceSchema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      serviceCategory,
      serviceName,
      sellingPrice,
      costPrice,
    },
  });
};
