import styles from "./MarketsCard.module.scss";

const MarketsCard = () => {
  return (
    <div className="d-flex" style={{ gap: "10px", maxHeight: "200px" }}>
      <div className="comingSoon--rounded" style={{ alignContent: "center" }}>
        <img src="" alt="Insert App Logo Here" />
      </div>
      <div className={` ${styles.card}`}>
        <h5>App Name</h5>
        <p className="small-text-size">
          Lorem ipsum dolor sit amet consectetur. Vestibulum interdum a et
          lacinia.
        </p>
        <span className="">Coming soon</span>
      </div>
    </div>
  );
};

export default MarketsCard;
