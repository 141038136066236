import { useContext, useState } from "react";
import {
  addSingleProduct,
  createService,
} from "../../../../../services/inventory";
import { useMutation, useQueryClient } from "react-query";
import { getRandomIntInclusive } from "../../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { useMultipleImageUpload } from "../useMultipeImageUpload";

export const useAddSingleProduct = () => {
  const [option, setOption] = useState("products");
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleUploadMultipleMediaFiles, isUploadingImage } =
    useMultipleImageUpload();

  // create product function
  const { mutate: createNewProduct, isLoading: isCreatingProduct } =
    useMutation((data) => addSingleProduct(data));
  const handleProductCreation = (submissionData) => {
    const { media_files, ...otherSubmissionData } = submissionData;
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    createNewProduct(otherSubmissionData, {
      onSuccess: (data) => {
        handleUploadMultipleMediaFiles({
          media_files: media_files,
          productId: data.data.data.id,
          item_type: "product",
        });
        triggerToast("Product Created Sucessfully", "success");
        queryClient.invalidateQueries(["getProductList"]);
        navigate(-1);
      },
      onSettled: setRequestLoaderProgress(100),
    });
  };

  // create service function
  const { mutate: createNewService, isLoading: isCreatingService } =
    useMutation((data) => createService(data));
  const handleServiceCreation = (submissionData) => {
    const { media_files, ...otherSubmissionData } = submissionData;
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    createNewService(otherSubmissionData, {
      onSuccess: (data) => {
        handleUploadMultipleMediaFiles({
          media_files: media_files,
          productId: data.data.data.id,
          item_type: "service",
        });
        triggerToast("Service Created Sucessfully", "success");
        queryClient.invalidateQueries(["getProductList"]);
        navigate(-1);
      },
      onSettled: setRequestLoaderProgress(100),
    });
  };

  return {
    option,
    isCreatingProduct: isCreatingProduct || isUploadingImage,
    isCreatingService,
    setOption,
    handleProductCreation,
    handleServiceCreation,
  };
};
