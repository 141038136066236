import styles from "./CustomersLayout.module.scss";
import { Outlet } from "react-router-dom";

const CustomersLayout = () => {
  return (
    <div className={styles.customersLayout}>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default CustomersLayout;
