import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { truncateString } from "../../../helpers";

export const MobileDoc = ({ address, business_name, sectionDetails }) => {
  return (
    <Document>
      <Page>
        <View
          className={styles2.sectionView}
          style={{ ...styles2.sectionView }}
        >
          <View
            style={{
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            <Text style={styles2.fontProp}>
              {truncateString(business_name, 150)}
            </Text>
            <Text style={styles2.fontProp}>{truncateString(address, 150)}</Text>
          </View>

          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Queue Number:</Text>
            <Text style={{ ...styles2.fontProp, fontSize: "16px" }}>
              {sectionDetails?.order?.queue_number || "N/A"}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Order Date:</Text>
            <Text style={styles2.fontProp}>
              {new Date(sectionDetails?.created_at).toLocaleString("en-us")}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Order For:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.section?.name}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Order ID:</Text>
            <Text style={styles2.fontProp}>{sectionDetails?.order?.id}</Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Table:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.order?.table?.name || "N/A"}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Order Type:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.order?.order_type?.replace("_", " ")}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Cashier Name:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.order?.initiator?.first_name || "N/A"}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Customer Name:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.order?.customer?.full_name || "N/A"}
            </Text>
          </View>
          <View style={styles2.sectionView__item}>
            <Text style={styles2.fontProp}>Order:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.docket_items?.map((item, index) => (
                <Text key={item?.id}>
                  {" "}
                  {item?.quantity} x{" "}
                  <Text style={styles2.fontProp}>
                    {item?.cart_item?.cart_item_name}
                  </Text>
                  {index === sectionDetails?.docket_items?.length - 1
                    ? ""
                    : ","}
                </Text>
              ))}
            </Text>
          </View>
          <View>
            <Text style={styles2.fontProp}>Notes:</Text>
            <Text style={styles2.fontProp}>
              {sectionDetails?.order?.notes || "N/A"}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles2 = StyleSheet.create({
  sectionView: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    padding: "15px",
    width: "100%",
    maxWidth: "400px",
  },
  sectionView__item: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    gap: "10px",
  },
  fontProp: {
    fontWeight: "bolder !important",
    fontSize: "12px",
  },
});
