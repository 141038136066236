import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getProfitAndLostData } from "../../../../services/accounting";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const useProfitAndLost = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const queryParams = new URLSearchParams(window.location.search);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const [startDate, setStartDate] = useState(search.get("start_date"));
  const [endDate, setEndDate] = useState(search.get("end_date"));
  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      search.set("store_id", storeList[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  const { isLoading: isFetchingProfitAndLostData, data: profitAndLostData } =
    useQuery(["getProfitAndLostData", queries], () =>
      getProfitAndLostData(queries).then((res) => {
        return res.data?.data;
      }),
    );

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const setDateFilter = (data) => {
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    search.set("start_date", data.startDate);
    search.set("end_date", data.endDate);
    setSearch(search, { replace: true });
  };

  return {
    startDate,
    endDate,
    storeList,
    currentStore,
    profitAndLostData,
    isFetchingProfitAndLostData,
    storeHasMultipleCurrency,
    setCurrentStore,
    setSearch,
    filterByStoreId,
    setDateFilter,
  };
};
export default useProfitAndLost;
