import { nFormatter, setToDateMonthYearInWords } from "../../../helpers";

const RevenueChartLogic = () => {
  const tickFormatter = (tick) => {
    return setToDateMonthYearInWords(tick);
  };

  const yAxisFormat = (tick) => {
    return nFormatter(tick);
  };

  return {
    tickFormatter,
    yAxisFormat,
  };
};

export default RevenueChartLogic;
