import { useContext, useRef, useState } from "react";
import {
  RequestLoader,
  ToastContext,
} from "../../../../../../../hooks/context";
import {
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../../../../../helpers";
import {
  useAddCityToLocation,
  useCreateShippingMutation,
} from "../../../../../../../hooks/api/mutations/useInventory";
import { useGetMenus } from "../../../../../../../hooks/api/queries";
import {
  useGetCountries,
  useGetCountryDetails,
} from "../../../../../../../hooks/api/queries/useBusiness";
import { useQueryClient } from "react-query";
import { pathConstants } from "../../../../../../../routes/pathContants";
import { useNavigate, useParams } from "react-router-dom";

const useShippingForm = ({ shippingInfo, refetch }) => {
  const { menuId } = useParams();
  const navigate = useNavigate();
  const queries = getUrlQuerysection();
  const { data: countries, isLoading: isLoadingCountries } = useGetCountries();
  const queryClient = useQueryClient();
  const reLabelDefault = (menus, nameKey = "name", idKey = "id") => {
    let newArray = [];
    menus?.forEach((menu) => {
      newArray?.push({
        value: menu?.[idKey],
        label: menu?.[nameKey],
      });
    });
    return newArray;
  };
  const [shippingDetails, setShippingDetails] = useState({
    name: shippingInfo?.name || "",
    countryId: shippingInfo?.country
      ? shippingInfo?.country?.emoji + " " + shippingInfo?.country?.name
      : "",
    delivery_window: shippingInfo?.delivery_window || "",
    storefront_ids: menuId ? [menuId] : [],
    stateIds: shippingInfo?.states ? reLabelDefault(shippingInfo?.states) : [],
    rate: shippingInfo?.rate || "",
    includes_holiday: shippingInfo?.includes_holiday || false,
    cityIds: shippingInfo?.cities ? reLabelDefault(shippingInfo?.cities) : [],
  });
  const { data: menus, isLoading: isLoadingMenus } = useGetMenus({
    queries,
  });
  const [addCity, setAddCity] = useState(null);
  const [addCityModal, setAddCityModal] = useState(null);
  const [currency, setCurrency] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState([]);
  const [cities, setCities] = useState({});
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const createShippingMutation = useCreateShippingMutation();
  const {
    mutate: addCityToLocationMutation,
    data: cityLocationData,
    isLoading: isLoadingcityLocationData,
  } = useAddCityToLocation();
  const { data: countryDetails, isLoading: isLoadingCountryDetails } =
    useGetCountryDetails(selectedCountry);
  const selectInputRef = useRef();

  const [includesValue, setIncludesValue] = useState("");
  const [selectedIncludesHoliday, setSelectedIncludesHoliday] = useState({
    value: "Working days excl. Holidays",
    label: "Working days excl. Holidays",
  });

  const includesHolidayOptions = [
    {
      value: "Working days excl. Holidays",
      label: "Working days excl. Holidays",
    },
    {
      value: "Working days incl. Holidays",
      label: "Working days incl. Holidays",
    },
  ];

  const handleIncludesHolidayChange = (e) => {
    setIncludesValue(e.target.value);
  };

  const handleIncludesChange = (selectedOption) => {
    if (selectedOption.value === "Working days excl. Holidays") {
      setShippingDetails((prev) => ({
        ...prev,
        includes_holiday: true,
      }));
    }
    setSelectedIncludesHoliday(selectedOption);
  };

  const handleAddShipping = (check) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const { cityIds, stateIds, countryId, ...otherShippingDetails } =
      shippingDetails;
    const country_id = selectedCountry;
    const city_ids = Object.values(cities).flat();
    const state_ids = selectedState?.map((data) => data?.value);
    const data = { ...otherShippingDetails, country_id, city_ids, state_ids };
    createShippingMutation.mutate(data, {
      onSuccess: () => {
        setRequestLoaderProgress(100);
        refetch();
        triggerToast("shipping added successfully", "success");
        setSelectedState([]);
        setCities({});
        navigate(pathConstants.INVENTORY_MENU);
      },
    });
  };

  const handleAddCityToLocation = (data) => {
    addCityToLocationMutation(data, {
      onSuccess: (data) => {
        setAddCity(data);
        queryClient.invalidateQueries(["getCountryDetails", selectedCountry]);
        triggerToast("city added successfully", "success");
      },
    });
  };

  return {
    menuId,
    menus: menus,
    isLoadingMenus,
    countryDetails: countryDetails,
    isLoadingCountryDetails,
    countries: countries,
    cityLocationData,
    addCity,
    isLoadingcityLocationData,
    isLoadingCountries,
    setSelectedState,
    selectInputRef,
    shippingDetails,
    includesHolidayOptions,
    includesValue,
    selectedIncludesHoliday,
    selectedCountry,
    selectedState,
    cities,
    isAddingShipping: createShippingMutation.isLoading,
    handleIncludesHolidayChange,
    handleAddCityToLocation,
    handleIncludesChange,
    setSelectedCountry,
    setCities,
    setShippingDetails,
    handleAddShipping,
    setCurrency,
    currency,
    addCityModal,
    setAddCityModal,
  };
};

export default useShippingForm;
