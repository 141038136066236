import primaryComponents from "../../../primaryComponents";

const PrintSelect = ({ setIsPrintModal, printReceipt }) => {
  return (
    <div className="px-4 py-4">
      <h5 className="text-center mb-4">Select print method</h5>
      <div className="d-flex" style={{ gap: "15px" }}>
        <primaryComponents.Button
          classNames={"btn btn--outline cover"}
          onClick={() => {
            printReceipt(true);
            setIsPrintModal(false);
          }}
        >
          Print New Orders
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          onClick={() => {
            printReceipt();
            setIsPrintModal(false);
          }}
        >
          Print Full Bill
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default PrintSelect;
