import { useEffect } from "react";
import styles from "./StockTransferForm.module.scss";
import { useStockTransfer } from "./useStockTransfer";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";

const StockTransferForm = ({
  transferData = [],
  setTransferData,
  setOtherTransferInfo = () => {},
  otherTransferInfo = {},
  validation = {},
  isEditMode = false,
  isFetchingNextPage,
  autoScrollRef,
}) => {
  const {
    storeList,
    storeError,
    setStoreError,
    handleAppendTransferData,
    filterByStore,
  } = useStockTransfer({ setTransferData, otherTransferInfo });

  const {
    formState: { errors },
    control,
    setValue,
    setError,
  } = validation;

  useEffect(() => {
    setValue("transferProperties", transferData);
  }, [transferData]);

  useEffect(() => {
    if (storeError !== "")
      setError(
        "transfer_from_store_id",
        {
          type: "validate",
          message: storeError,
        },
        { shouldFocus: true },
      );
  }, [storeError]);
  // console.log(
  //   storeList?.find(
  //     (storeItem) =>
  //       `${storeItem?.id}` === `${otherTransferInfo?.transfer_to_store_id}`,
  //   )?.name,
  //   storeList,
  //   otherTransferInfo,
  // );

  return (
    <div className={`${globalStyles.tableWrapper} ${styles.stockTransferForm}`}>
      <div className={styles.stockTransferForm__header}>
        <div className="w-100">
          <primaryComponents.SelectFieldV2
            control={control}
            name="transfer_from_store_id"
            options={storeList}
            idKey="id"
            nameKey="name"
            label={"Transfer From"}
            isRequired={true}
            onChange={(value) => {
              filterByStore(value);
              setOtherTransferInfo((prev) => ({
                ...prev,
                transfer_from_store_id: value,
              }));
            }}
            value={
              storeList?.find(
                (storeItem) =>
                  `${storeItem?.id}` ===
                  `${otherTransferInfo?.transfer_from_store_id}`,
              )?.name || "Select store"
            }
            errorMessage={errors?.transfer_from_store_id}
            isDisabled={isEditMode}
          />
        </div>
        <div className="w-100">
          <primaryComponents.SelectFieldV2
            options={storeList}
            control={control}
            idKey="id"
            nameKey="name"
            name="transfer_to_store_id"
            label={"Transfer To"}
            isRequired={true}
            onChange={(value) => {
              setOtherTransferInfo((prev) => ({
                ...prev,
                transfer_to_store_id: value,
              }));
            }}
            value={
              storeList?.find(
                (storeItem) =>
                  `${storeItem?.id}` ===
                  `${otherTransferInfo?.transfer_to_store_id}`,
              )?.name || "Select store"
            }
            errorMessage={errors?.transfer_to_store_id}
            isDisabled={isEditMode}
          />
        </div>
      </div>

      <hr />
      <div style={{ maxWidth: "750px", margin: "20px" }}>
        <secondaryComponents.BulkRestockComboBox
          handleAppendRestockData={handleAppendTransferData}
          setStoreError={setStoreError}
        />
      </div>
      <hr />

      <secondaryComponents.BulkTransferTable
        transferData={transferData}
        setTransferData={setTransferData}
        validation={validation}
        autoScrollRef={autoScrollRef}
      />
    </div>
  );
};

export default StockTransferForm;
