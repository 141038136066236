export const LogoutIcon = ({
  fill = "None",
  width = "16",
  height = "16",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66675 14.5H17.5001"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.33333 8.66669L3.5 14.5L9.33333 20.3334"
        stroke={strokeColor}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 4V25"
        stroke={strokeColor}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
