import httpClient from "../axiosBase/axiosHandler";

export const createExpense = (data) => {
  return httpClient.post("expenses/", data);
};

export const getExpenses = (queries) => {
  return httpClient.get(`expenses/${queries}`);
};
export const createExpenseCategory = (data) => {
  return httpClient.post("expenses-categories/", data);
};

export const editExpenseCategory = ({ data, id }) => {
  return httpClient.patch(`expenses-categories/${id}/`, data);
};

export const deleteExpenseCategory = ({ id }) => {
  return httpClient.delete(`expenses-categories/${id}/`);
};

export const getExpenseCategories = () => {
  return httpClient.get("expenses-categories/");
};

export const getVideos = () => {
  return httpClient.get("tutorials/");
};

export const getExpenseDetails = (id) => {
  return httpClient.get(`expenses/${id}/`);
};

export const approveOrRejectExpenseWithoutDisbursement = ({
  status,
  data,
  id,
}) => {
  return httpClient.patch(`expenses/${id}/${status}/`, data);
};

export const disburseExpenseFunds = ({ data, id }) => {
  return httpClient.patch(`expenses/${id}/disburse/`, data);
};

export const getExpenseAnalytics = (queries) => {
  return httpClient.get(`expenses-analytics/analytics-data/${queries}`);
};
