import { motion } from "framer-motion";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import useAllProductsBatch from "./useAllProductsBatch";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const AllProductsBatch = () => {
  const {
    search,
    isFetchingProductsBatch,
    productsBatch,
    metaDetails,
    filterByStoreId,
    storeList,
    currentStore,
    handleFetchProductsBatch,
    gotoPage,
    searchProduct,
  } = useAllProductsBatch();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory -ProductsBatch"
      description="View all products batch records"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Products Batch</h4>
            <p>Compare and adjust your Product Batch records</p>
          </div>
          <div className={`${globalStyles.tableWrapper}`}>
            <div className={`${globalStyles.tableHeader}`}>
              <primaryComponents.SearchField
                placeholder="Search by product name"
                trigger={(value) => searchProduct(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn
                classNames={`${globalStyles.searchField}`}
              />
              <div style={{ width: "200px" }}>
                <primaryComponents.SelectField
                  options={storeList}
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    filterByStoreId(value);
                  }}
                  value={currentStore || "Select store"}
                  floatError={false}
                  classNames="store-select"
                />
              </div>
            </div>
            {/* table section */}
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingProductsBatch}
              classNames="pt-5"
            >
              <secondaryComponents.AllProductsBatchTable
                productsBatch={productsBatch}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                handleFetchProductsBatch={handleFetchProductsBatch}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllProductsBatch;
