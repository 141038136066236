import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetCashflowAnalytics } from "../../../../hooks/api/queries/useDashboard";

const useCashflowAnalytics = () => {
  const queries = getUrlQuerysection();
  const [cashFlowAnalytics, tagsFlowAnalytics] = useGetCashflowAnalytics({
    queries,
  });
  const [search] = useSearchParams();

  return {
    search,
    isLoading: cashFlowAnalytics?.isLoading,
    cashFlowData: cashFlowAnalytics?.data,
    cashflowTrend: cashFlowAnalytics?.data?.cashflow_total_trend || [],
    transactionCount: cashFlowAnalytics?.data?.cashflow_count_trend || [],
    isLoadingTags: tagsFlowAnalytics?.isLoading,
    tagsData: tagsFlowAnalytics?.data || { revenue_tags: [], spend_tags: [] },
  };
};
export default useCashflowAnalytics;
