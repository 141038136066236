/* eslint-disable react/jsx-key */
import styles from "./SectionsTable.module.scss";
import useSectionsTableLogic from "./SectionsTableLogic";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const SectionsTable = ({ sections, refetch }) => {
  const {
    instance,
    isSectionProperties,
    setIsSectionProperties,
    isSectionDeleteProperties,
    setIsSectionDeleteProperties,
  } = useSectionsTableLogic({ sections });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.usersTable}>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          classNames={styles.tableCover}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.sectionManageEmpty} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no sections
            </h4>
            <p className="text-center">
              Use the button above to create an section. When you do, they would
              appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>
      </div>

      {/* modal */}
      <secondaryComponents.Modal
        isActive={isSectionDeleteProperties !== null}
        closeModal={() => setIsSectionDeleteProperties(null)}
        width={500}
      >
        <secondaryComponents.SectionDeletion
          closeWidget={() => setIsSectionDeleteProperties(null)}
          sectionDetails={isSectionDeleteProperties}
          handleFetchSections={refetch}
        />
      </secondaryComponents.Modal>

      {/* edit section modal section */}
      <secondaryComponents.Modal
        isActive={isSectionProperties !== null}
        closeModal={() => {
          setIsSectionProperties(null);
        }}
        width={500}
      >
        <secondaryComponents.SectionForm
          sectionDetails={isSectionProperties}
          closeModal={() => {
            setIsSectionProperties(null);
          }}
          isEditMode={true}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default SectionsTable;
