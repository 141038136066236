import { useState, useEffect, useContext } from "react";
import { RequestLoader } from "../../../../hooks/context";
import { fetchTerminalDetails } from "../../../../services/pos-terminals";
import { getRandomIntInclusive } from "../../../../helpers";
import { useParams } from "react-router-dom";

const TerminalDetailsLogic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [terminalDetails, setTerminalDetails] = useState({});
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { terminalId } = useParams();

  useEffect(() => {
    getTerminalDetails();
  }, []);

  const getTerminalDetails = () => {
    setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    fetchTerminalDetails(terminalId)
      .then((response) => {
        setTerminalDetails(response.data.data);
      })
      .finally(() => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    terminalDetails,
  };
};
export default TerminalDetailsLogic;
