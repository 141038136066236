import styles from "./Users.module.scss";
import { useContext, useState } from "react";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import Icon from "../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { getPlatform } from "../../../../helpers";
import { platformHandler } from "../../../../hooks/context";
import { getTeamMembers } from "../../../../services/settings";
import { useQuery } from "react-query";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const Users = () => {
  const { platform } = useContext(platformHandler);
  const navigate = useNavigate();
  const [showInviteModal, setShowInviteModal] = useState(false);

  const { isLoading, data } = useQuery(["team-members"], () =>
    getTeamMembers().then((res) => res.data?.data),
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Users"
      description="Add, edit and delete users that can have access to your lumi merchant account"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Users</h4>
            <p>
              Invite your teammates or staffs and customize which feature you
              want them to have access to
            </p>
          </div>
          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isLoading}
          >
            <div className={styles.users_page}>
              <div className={styles.buttons_container}>
                <h5>{data?.length} users</h5>
                <primaryComponents.Button
                  onClick={() =>
                    navigate(
                      getPlatform(platform) + "/settings/users/manage-roles",
                    )
                  }
                  classNames={"btn btn--outline ml-auto"}
                >
                  <img src={Icon.manageRole} className="mr-2" alt="invite" />
                  Manage roles
                </primaryComponents.Button>
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Settings"}
                  permissionChildKey="can_create_update_and_invite_users"
                >
                  <primaryComponents.Button
                    onClick={() => setShowInviteModal(true)}
                    classNames={"btn btn--primary ml-2"}
                    data-testId="invite-button"
                  >
                    <img src={Icon.inviteUser} className="mr-2" alt="invite" />
                    Invite user
                  </primaryComponents.Button>
                </secondaryComponents.NavigateWithPermission>
              </div>
              {/* table section */}
              <div className={styles.users_content}>
                <secondaryComponents.UsersTable />
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          {/* Invite modal section */}
          {showInviteModal && (
            <secondaryComponents.InviteUser
              showInviteModal={showInviteModal}
              setShowInviteModal={setShowInviteModal}
            />
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Users;
