import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  sellingPrice: yup.string().required("Selling price is required"),
  costPrice: yup.string().required("Cost price is required"),
  stockQuantity: yup.string().required("Stock Quantity is required"),
  discountRate: yup.string().required("Discount Rate is required"),
});

const Validation = ({ batchDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      sellingPrice: batchDetails?.selling_price,
      costPrice: batchDetails?.cost_price,
      stockQuantity: batchDetails?.stock_quantity,
      discountRate: batchDetails?.discount_rate,
    },
  });
};

export default Validation;
