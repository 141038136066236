import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CitySchema = yup.object({
  cityName: yup.string().required("city name is require"),
});

const Validation = (city) => {
  return useForm({
    resolver: yupResolver(CitySchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      city,
    },
  });
};

export default Validation;
