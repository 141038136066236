import { useContext, useRef, useState, useEffect } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  cleanObject,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import {
  useAddCityToLocation,
  useCreateShippingMutation,
  useEditShippingMutation,
} from "../../../hooks/api/mutations/useInventory";
import { useGetMenus } from "../../../hooks/api/queries";
import {
  useGetCountries,
  useGetCountryDetails,
} from "../../../hooks/api/queries/useBusiness";
import { useQueryClient } from "react-query";

const useShippingForm = ({
  shippingInfo,
  refetch,
  closeWidget,
  isEditMode,
}) => {
  const queries = getUrlQuerysection();
  const { data: countries, isLoading: isLoadingCountries } = useGetCountries();
  const queryClient = useQueryClient();
  const reLabelDefault = (menus, nameKey = "name", idKey = "id") => {
    let newArray = [];
    menus?.forEach((menu) => {
      newArray?.push({
        value: menu?.[idKey],
        label: menu?.[nameKey],
      });
    });
    return newArray;
  };
  const [shippingDetails, setShippingDetails] = useState({
    name: shippingInfo?.name || "",
    countryId: shippingInfo?.country
      ? shippingInfo?.country?.emoji + " " + shippingInfo?.country?.name
      : "",
    delivery_window: shippingInfo?.delivery_window || "",
    storefront_ids: shippingInfo?.storefronts
      ? shippingInfo?.storefronts?.map((menu) => menu?.name)
      : [],
    stateIds: shippingInfo?.states ? reLabelDefault(shippingInfo?.states) : [],
    rate: shippingInfo?.rate || "",
    includes_holiday: shippingInfo?.includes_holiday || false,
    cityIds: shippingInfo?.cities ? reLabelDefault(shippingInfo?.cities) : [],
    free_delivery_min_order_amount:
      shippingInfo?.free_delivery_min_order_amount || "",
  });
  const { data: menus, isLoading: isLoadingMenus } = useGetMenus({ queries });
  const [addCity, setAddCity] = useState(null);
  const [addCityModal, setAddCityModal] = useState(null);
  const [currency, setCurrency] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState([]);
  const [cities, setCities] = useState({});
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const createShippingMutation = useCreateShippingMutation();
  const editShippingMutation = useEditShippingMutation();
  const [selectedFreeShipping, setSelectedFreeShipping] = useState(false);
  const {
    mutate: addCityToLocationMutation,
    data: cityLocationData,
    isLoading: isLoadingcityLocationData,
  } = useAddCityToLocation();
  const { data: countryDetails, isLoading: isLoadingCountryDetails } =
    useGetCountryDetails(selectedCountry);
  const selectInputRef = useRef();

  const [includesValue, setIncludesValue] = useState("");
  const [selectedIncludesHoliday, setSelectedIncludesHoliday] = useState({
    value: "Working days excl. Holidays",
    label: "Working days excl. Holidays",
  });

  useEffect(() => {
    if (isEditMode && shippingInfo?.country?.id) {
      setSelectedCountry(shippingInfo?.country?.id);
    }

    if (isEditMode && shippingInfo?.storefronts) {
      const getMenuIds = shippingInfo?.storefronts?.map((menu) => menu?.id);
      setShippingDetails((prevDetails) => ({
        ...prevDetails,
        storefront_ids: getMenuIds,
      }));
    }
  }, []);

  useEffect(() => {
    if (isEditMode && shippingInfo?.states) {
      const getStates = shippingInfo?.states?.map((data) => ({
        label: data?.name,
        value: data?.id,
      }));
      setSelectedState(getStates);
      const initialCities = {};

      shippingInfo?.states?.forEach((state) => {
        const cityIds = shippingInfo?.cities
          ?.filter((city) => city?.state?.id === state?.id)
          ?.map((city) => city?.id);

        if (cityIds) {
          initialCities[state?.id] = cityIds;
        }
      });
      setCities(initialCities);
    }
  }, []);

  useEffect(() => {
    if (shippingInfo?.free_delivery) {
      setSelectedFreeShipping(true);
    }
  }, [shippingInfo?.free_delivery]);

  const includesHolidayOptions = [
    {
      value: "Working days excl. Holidays",
      label: "Working days excl. Holidays",
    },
    {
      value: "Working days incl. Holidays",
      label: "Working days incl. Holidays",
    },
  ];

  const handleIncludesHolidayChange = (e) => {
    setIncludesValue(e.target.value);
  };

  const handleIncludesChange = (selectedOption) => {
    if (selectedOption.value === "Working days excl. Holidays") {
      setShippingDetails((prev) => ({
        ...prev,
        includes_holiday: true,
      }));
    }
    setSelectedIncludesHoliday(selectedOption);
  };

  const handleAddShipping = (check) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const {
      cityIds,
      stateIds,
      countryId,
      free_delivery_min_order_amount,
      ...otherShippingDetails
    } = shippingDetails;
    const country_id = selectedCountry;
    const city_ids = Object.values(cities).flat();
    const state_ids = selectedState?.map((data) => data?.value);
    const free_delivery = selectedFreeShipping;
    const data = {
      ...otherShippingDetails,
      country_id,
      city_ids,
      state_ids,
      free_delivery,
      free_delivery_min_order_amount: selectedFreeShipping
        ? shippingDetails?.free_delivery_min_order_amount || 0
        : 0,
    };
    createShippingMutation.mutate(data, {
      onSuccess: () => {
        refetch();
        triggerToast("shipping added successfully", "success");
        if (check !== "continue") {
          closeWidget();
        }
        setSelectedState([]);
        setCities({});
      },
    });
  };

  const handleEditShipping = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));

    const {
      cityIds,
      stateIds,
      countryId,
      storefront_ids,
      free_delivery_min_order_amount,
      ...otherShippingDetails
    } = shippingDetails;
    const country_id = selectedCountry;
    const city_ids = Object.values(cities).flat();
    const state_ids = selectedState?.map((data) => data?.value);
    const free_delivery = selectedFreeShipping;

    const data = cleanObject({
      ...otherShippingDetails,
      country_id,
      city_ids,
      state_ids,
      storefront_ids,
      free_delivery,
      free_delivery_min_order_amount: selectedFreeShipping
        ? shippingDetails?.free_delivery_min_order_amount || 0
        : 0,
    });

    editShippingMutation.mutate(
      { id: shippingInfo?.id, data },
      {
        onSuccess: () => {
          refetch();
          triggerToast("Shipping updated successfully", "success");
          closeWidget();
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const handleAddCityToLocation = (data) => {
    addCityToLocationMutation(data, {
      onSuccess: (data) => {
        setAddCity(data);
        queryClient.invalidateQueries(["getCountryDetails", selectedCountry]);
        triggerToast("city added successfully", "success");
      },
    });
  };

  return {
    menus: menus,
    isLoadingMenus,
    countryDetails: countryDetails,
    isLoadingCountryDetails,
    countries: countries,
    cityLocationData,
    addCity,
    isLoadingcityLocationData,
    isLoadingCountries,
    setSelectedState,
    selectInputRef,
    shippingDetails,
    includesHolidayOptions,
    includesValue,
    selectedIncludesHoliday,
    selectedCountry,
    selectedState,
    cities,
    isAddingShipping: createShippingMutation.isLoading,
    isEditingShipping: editShippingMutation.isLoading,
    handleIncludesHolidayChange,
    handleAddCityToLocation,
    handleIncludesChange,
    setSelectedCountry,
    setCities,
    setShippingDetails,
    handleAddShipping,
    handleEditShipping,
    setCurrency,
    currency,
    addCityModal,
    setAddCityModal,
    selectedFreeShipping,
    setSelectedFreeShipping,
  };
};

export default useShippingForm;
