import styles from "./ProfitAndLostListItem.module.scss";
import { formatCurrency } from "../../../helpers";

const ProfitAndLostListItem = ({ title, value, store_currency_code }) => {
  return (
    <>
      <div
        className={`${styles.headerLight} d-flex justify-content-between align-items-center flex-wrap mb-1`}
      >
        <h5 className="pl-5 m-2 default-font-size">{title}</h5>
        <h5 className="pl-5 m-2 default-font-size">
          {formatCurrency({
            value: value || 0,
            currencyCode: store_currency_code,
          })}
        </h5>
      </div>
    </>
  );
};

export default ProfitAndLostListItem;
