import { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  useApproveStockTransfer,
  useRejectStockTransfer,
} from "../../../../../hooks/api/mutations/useInventory";
import { getRandomIntInclusive } from "../../../../../helpers";
import { RequestLoader, ToastContext } from "../../../../../hooks/context";
import {
  useGetStockTransferDetails,
  useGetStockTransferItems,
} from "../../../../../hooks/api/queries";

const useTransferStockDetails = () => {
  const params = useParams();
  const approveStockTransfer = useApproveStockTransfer();
  const rejectStockTransfer = useRejectStockTransfer();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const {
    isLoading: isFetchingStockTransferDetails,
    data: stockTransferDetails,
    refetch: stockDetailsRefresh,
  } = useGetStockTransferDetails({ id: params.id });

  const {
    isLoading: isFetchingStockTransferItems,
    data: stockTransferItems,
    isFetchingNextPage,
    autoScrollRef,
  } = useGetStockTransferItems({ id: params.id });

  const handleApproveStockTransfer = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = params.id;
    approveStockTransfer.mutate(data, {
      onSuccess: () => {
        stockDetailsRefresh();
        triggerToast("Stock Transfer Approved successfully", "success");
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const handleRejectStockTransfer = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = params.id;
    rejectStockTransfer.mutate(data, {
      onSuccess: () => {
        stockDetailsRefresh();
        triggerToast("Stock Transfer Rejected", "warning");
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    params: params?.id,
    isFetchingStockTransferItems,
    isFetchingStockTransferDetails,
    stockTransferDetails,
    stockTransferItems,
    isFetchingNextPage,
    autoScrollRef,
    handleApproveStockTransfer,
    handleRejectStockTransfer,
  };
};
export default useTransferStockDetails;
