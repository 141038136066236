import React from "react";
import secondaryComponents from "../../../../../../../components/secondaryComponents";
import useMenuDetails from "./useMenuDetails";
import Images from "../../../../../../../assets/images";

const MenuDetails = ({
  selectedMenuTab,
  foodMenus,
  onCopy,
  setIsDeleteFoodMenu,
  setIsFoodMenuWidget,
}) => {
  const { instance } = useMenuDetails({
    foodMenus,
    onCopy,
    setIsDeleteFoodMenu,
    setIsFoodMenuWidget,
    selectedMenuTab,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <secondaryComponents.TableHelper
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
    >
      <div className="text-center mt-5">
        <img src={Images.foodMenuEmptyState} width="250px" alt="" />
        <h5>You have no Storefront</h5>
        <p>
          Use the “Create Storefront” button above to add your storefront. When
          you do, it will appear here.
        </p>
      </div>
    </secondaryComponents.TableHelper>
  );
};

export default MenuDetails;
