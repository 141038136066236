import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const businessSchema = yup
  .object({
    jobTitle: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Job title is required"),
    password: yup
      .string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Password should contain a minimumm of 8 characters, at least a lowercase, an uppercase and a number",
      )
      .required("Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords do not match!")
      .required("Confirm password is required"),
  })
  .required();

const BusinessFormValidation = (jobTitle, password, confirmPassword) => {
  return useForm({
    resolver: yupResolver(businessSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      jobTitle: jobTitle,
      password: password,
      confirmPassword: confirmPassword,
    },
  });
};

export default BusinessFormValidation;
