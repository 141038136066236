import primaryComponents from "../../../components/primaryComponents";
import { Outlet, useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { useState } from "react";

const Terminals = () => {
  const navigate = useNavigate();
  const [terminalHeader, setTerminalHeader] = useState({
    title: "",
    description: "",
  });

  return (
    <div className={globalStyles.pageColumnGaps}>
      <div>
        <h4 className="mb-0">{terminalHeader?.title}</h4>
        <p>{terminalHeader?.description}</p>
      </div>
      <div>
        <primaryComponents.Button
          onClick={() => navigate(pathConstants.PAYMENT_POS_ADD_TERMINALS)}
          classNames={"btn btn--primary ml-auto"}
        >
          Request a Terminal
        </primaryComponents.Button>
      </div>

      <Outlet context={{ setTerminalHeader }} />
    </div>
  );
};

export default Terminals;
