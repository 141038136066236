import ConfirmDialog from "../ConfirmDialog/ConfirmDialog";
import styles from "./DeleteOrderTable.module.scss";

const DeletOrderTableDialog = ({
  isOpen,
  setIsOpen,
  handleDeleteTable,
  tableDetails,
  isLoading,
}) => {
  return (
    <ConfirmDialog
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handler={handleDeleteTable}
      title="Are you sure you want to delete this table?
        All associated data would be deleted as well."
      isLoading={isLoading}
    >
      <div className={styles.deleteOrdeTable}>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className=" mb-2">Table No:</span>
          <span className="dark--text font-weight-semibold">
            {tableDetails?.name}
          </span>
        </p>
      </div>
    </ConfirmDialog>
  );
};

export default DeletOrderTableDialog;
