import { useTable } from "react-table";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import primaryComponents from "../../primaryComponents";
import { convertToTilteCase, formatCurrency } from "../../../helpers";
import { useGetCustomerDetails } from "../../../hooks/api/queries";

const useCustomersTable = ({ customers }) => {
  const [isCustomerProperties, setIsCustomerProperties] = useState(null);
  const [isCustomerDeleteProperties, setIsCustomerDeleteProperties] =
    useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "full_name",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={`${row.original["id"]}`}
            style={{ fontSize: "12px", textDecoration: "underline" }}
          >
            {value ? convertToTilteCase(value) : "N/A"}
          </Link>
        ),
      },
      // {
      //   Header: "Email Address",
      //   accessor: "email",
      //   Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      // },
      {
        Header: "Phone Number",
        Cell: ({ row }) =>
          row?.original?.phone
            ? "(+" + row?.original?.phone_code + ") " + row?.original?.phone
            : "N/A",
      },
      {
        Header: "Total Amount Spent",
        accessor: "total_purchases",
        Cell: ({ cell }) =>
          cell.value
            ? formatCurrency({
                value: cell.value || 0,
                currencyCode: cell?.row?.original?.store?.currency_code,
              })
            : "N/A",
      },
      {
        Header: "Debt/Deposit",
        accessor: "outstanding_balance",
        Cell: ({ cell }) => {
          let val = formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          });

          if (cell.value > 0) {
            return (
              <span
                style={{
                  color: "#FD561F",
                }}
              >
                -{val}
              </span>
            );
          } else {
            return (
              <span
                style={{
                  color: "#19AE57",
                }}
              >
                {formatCurrency({
                  value: Math.abs(cell.value),
                  currencyCode: cell?.row?.original?.store?.currency_code,
                })}
              </span>
            );
          }
        },
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <div className="d-flex flex-wrap">
            <primaryComponents.Button
              classNames={"btn btn--primary smallBtn mr-1"}
              onClick={() => {
                setIsCustomerProperties(row?.original?.id);
              }}
            >
              Edit
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames={"btn btn--outline-red smallBtn"}
              onClick={() => {
                setIsCustomerDeleteProperties(row?.original);
              }}
            >
              Delete
            </primaryComponents.Button>
          </div>
        ),
      },
    ],
    [],
  );

  const { data: customerDetails, isLoading: isFetchingCustomerDetails } =
    useGetCustomerDetails(isCustomerProperties);
  const instance = useTable({ columns, data: customers });
  return {
    instance,
    isCustomerProperties,
    setIsCustomerProperties,
    isCustomerDeleteProperties,
    isFetchingCustomerDetails,
    customerDetails: customerDetails,
    setIsCustomerDeleteProperties,
  };
};

export default useCustomersTable;
