import { useState } from "react";
import { useQuery } from "react-query";
import { getPrescriptionUnits } from "../../../services/inventory";

export const useMeasurementInput = () => {
  const [measurement, setMeasurement] = useState({
    value: "",
    unit: "",
  });

  const { data: prescription_units, isLoading: isFetchingUnits } = useQuery({
    queryKey: ["prescriptionUnits"],
    queryFn: () => getPrescriptionUnits().then((res) => res.data?.data),
    onSuccess: (data) => {
      const unit = data[0]?.id;
      setMeasurement((prev) => ({ ...prev, unit }));
    },
  });

  return { measurement, prescription_units, isFetchingUnits, setMeasurement };
};
