import styles from "./MeasurementInputField.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMeasurementInput } from "./useMeasurementInput";

const MeasurementInputField = ({
  label = null,
  isRequired = true,
  name = "",
  errorMessage = undefined,
  onChange = () => {},
}) => {
  const { measurement, prescription_units, isFetchingUnits, setMeasurement } =
    useMeasurementInput();

  return (
    <div className={styles.measurrementInputField}>
      {label !== null && (
        <label htmlFor={name}>
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div className={styles.measurrementInputField__content}>
        <input
          type="number"
          value={measurement.value}
          onChange={({ target: { value } }) => {
            setMeasurement((prev) => {
              onChange({ ...prev, value });
              return { ...prev, value };
            });
          }}
        />
        <select
          value={measurement.unit}
          onChange={({ target: { value } }) => {
            setMeasurement((prev) => {
              onChange({ ...prev, unit: value });
              return { ...prev, unit: value };
            });
          }}
        >
          {isFetchingUnits ? (
            <FontAwesomeIcon
              icon={["fas", "spinner"]}
              className="fa-spin purple--text"
              style={{ fontSize: "12px" }}
            />
          ) : (
            prescription_units.map((unit) => (
              <option key={unit.id} value={unit.id}>
                {unit.name}
              </option>
            ))
          )}
        </select>
      </div>
      {errorMessage !== undefined ? (
        <p
          className={`error-message text-danger ${
            errorMessage !== undefined ? "show" : ""
          }`}
          data-testid="error-message"
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage?.message}</span>}
        </p>
      ) : null}
    </div>
  );
};

export default MeasurementInputField;
