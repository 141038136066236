import { useState, useContext, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import {
  createService,
  getCategoryList,
  createCategory,
  uploadProductImage,
} from "../../../services/inventory";
import {
  platformHandler,
  RequestLoader,
  ToastContext,
} from "../../../hooks/context";
import { useSelector } from "react-redux";
import { getPlatform } from "../../../helpers";

export const useAddSingleServiceLogic = () => {
  const [serviceName, setServiceName] = useState("");
  const [serviceCategory, setServiceCategory] = useState("");
  const [store, setStore] = useState("");
  const [costPrice, setCostPrice] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [profit, setProfit] = useState("");
  const [serviceImage, setServiceImage] = useState("");

  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const fileUploadInputRef = useRef();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);

  // Mutations
  const categoryMutation = useMutation(["addCategory"], createCategory);
  const serviceMutation = useMutation(["addService"], createService);
  const serviceImageMutation = useMutation(
    "uploadServiceImage",
    uploadProductImage,
  );

  const { isLoading: isFetchingCategories } = useQuery(
    ["categoryList"],
    () => getCategoryList().then((res) => res.data?.data),
    {
      onSuccess: (response) => {
        setCategoryList(response);
      },
    },
  );

  const handleAddCategory = (value) => {
    setRequestLoaderProgress(20);
    const createOption = (label, value) => ({
      label,
      value,
    });
    categoryMutation.mutate(
      { name: value },
      {
        onSuccess: (response) => {
          const newOption = createOption(
            response?.data?.data?.name,
            response?.data?.data?.id,
          );
          const newCategories = [...categoryList];
          newCategories.push(response?.data?.data);
          setCategoryList(newCategories);
          setServiceCategory(newOption);
          triggerToast("Category Added Sucessfully", "success");
        },
        onSettled: () => {
          setRequestLoaderProgress(100);
        },
      },
    );
  };

  const handleAddService = () => {
    setRequestLoaderProgress(30);
    const payload = {
      name: serviceName,
      category: serviceCategory?.value,
      cost_price: costPrice,
      selling_price: sellingPrice,
      image_url: serviceImage,
      store: store,
    };
    serviceMutation.mutate(
      { ...payload },
      {
        onSuccess: () => {
          navigate(getPlatform(platform) + "/inventory/products", {
            state: { inventoryType: "Services" },
          });
        },
        onSettled: () => {
          setRequestLoaderProgress(100);
        },
      },
    );
  };

  const handleServiceImageUpload = (event) => {
    setRequestLoaderProgress(40);
    if (event.target.files[0].size > 10000000) {
      return new Error("File size should be less than 10mb");
    }
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    serviceImageMutation.mutate(formData, {
      onSuccess: (response) => {
        const url = response.data?.data?.url || "";
        setServiceImage(url);
        triggerToast("Image uploaded successfully", "success");
      },
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    serviceName,
    serviceCategory,
    costPrice,
    sellingPrice,
    profit,
    categoryList,
    isFetchingCategories,
    fileUploadInputRef,
    serviceImage,
    isUploadingImage: serviceImageMutation.isLoading,
    isAddingService: serviceMutation.isLoading,
    storeList,
    store,
    setStore,
    setServiceName,
    setServiceCategory,
    setCostPrice,
    setSellingPrice,
    setProfit,
    handleAddCategory,
    handleAddService,
    handleServiceImageUpload,
  };
};
