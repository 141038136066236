import styles from "./ProductComboBox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useProductComboBox } from "./useProductComboBox";
import Icon from "../../../assets/icons";

const ProductComboBox = ({ error = "" | null, label, onChange }) => {
  const { cb, selectedProduct, products, displayMenu, showSpinner } =
    useProductComboBox({ onChange });

  return (
    <div className={styles.productComboBox}>
      <input type="hidden" name="order_id" value={selectedProduct?.id || ""} />
      <div className={styles.productComboBoxLabel}>
        <label {...cb.getLabelProps()}>
          <span>{label}</span>
        </label>
        {error ? (
          <em id="order-errorr" className="text--danger">
            {error}
          </em>
        ) : null}
      </div>
      <div className={styles.productComboBoxControl}>
        <SeachIcon />
        <input
          type="text"
          {...cb.getInputProps({
            placeholder: "Search and select product",
            className: styles.productComboBoxInput,
            "aria-invalid": Boolean(error) || undefined,
            "aria-errormessage": error ? "customer-error" : undefined,
          })}
        />
        {/* Show spinner here... */}
        <Spinner showSpinner={showSpinner} />
      </div>
      <ul
        {...cb.getMenuProps({ className: styles.productComboBoxMenu })}
        style={{ visibility: !displayMenu ? "hidden" : "visible" }}
      >
        {displayMenu
          ? products.map((product, index) => (
              <li
                key={product?.id}
                {...cb.getItemProps({
                  className: styles.productComboBoxMenuItem,
                  key: product?.id,
                  item: product,
                  index,
                })}
              >
                <span>{product.name}</span>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

function Spinner({ showSpinner = true }) {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        opacity: showSpinner ? "100" : "0",
      }}
    >
      <FontAwesomeIcon
        icon={["fas", "spinner"]}
        className="fa-spin mr-2 purple--text"
      />
    </div>
  );
}

function SeachIcon() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
      }}
    >
      <img src={Icon.search} alt="" className="ml-2" />
    </div>
  );
}

export default ProductComboBox;
