import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useStoreCurrency } from "../../../../../hooks/useStoreCurrency";
import { useSelector } from "react-redux";
import { useGetProductsBatch } from "../../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../../helpers";

const useAllProductsBatch = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const [currentStore] = useState(
    (() => {
      if (search.get("store_id"))
        return storeList?.find((item) => item.id === search.get("store_id"))
          ?.name;
      else return storeList?.[0]?.name;
    })(),
  );

  const {
    isLoading: isFetchingProductsBatch,
    data: productsBatch,
    refetch: handleFetchProductsBatch,
  } = useGetProductsBatch(queries);

  const filterByStoreId = (storeId) => {
    search.set("store_id", storeId);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const searchProduct = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (getUrlQuerysection() === "" && storeList?.length > 0) {
      const storeId = storeList?.[0].id;
      search.set("store_id", queries || storeId);
      setSearch(search, { replace: true });
    }
  }, [storeList]);

  return {
    search,
    isFetchingProductsBatch,
    productsBatch: productsBatch?.data || [],
    metaDetails: productsBatch?.meta,
    filterByStoreId,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    handleFetchProductsBatch,
    gotoPage,
    searchProduct,
  };
};
export default useAllProductsBatch;
