import httpClient from "../axiosBase/axiosHandler";

export const createCampaign = (payload) => {
  const response = httpClient.post("/campaign/", payload);
  return response;
};

export const getCampaigns = (queries = "") => {
  const response = httpClient.get(`/campaign/${queries}`);
  return response;
};

export const getCampaignDetails = (id) => {
  const response = httpClient.get(`/campaign/${id}/`);
  return response;
};

export const updateCampaign = (payload) => {
  const response = httpClient.put(`/campaign/${payload?.id}/`, payload);
  return response;
};

export const deleteCampaign = (payload) => {
  const response = httpClient.delete(`/campaign/${payload?.id}/`);
  return response;
};

export const updateBrands = (id, payload) => {
  const response = httpClient.put(`/campaign/${id}/`, payload);
  return response;
};

export const updateOffers = (id, payload) => {
  const response = httpClient.put(`/campaign/${id}/offer-update/`, payload);
  return response;
};

export const toggleOfferStatus = (id) => {
  const response = httpClient.post(`/campaign/${id}/toggle-offer-status/`, {});
  return response;
};

export const toggleCampaignStatus = (id) => {
  const response = httpClient.post(
    `/campaign/${id}/toggle-campaign-status/`,
    {},
  );
  return response;
};
