import React, { useState } from "react";
import styles from "./RolePage.module.scss";
import primaryComponents from "../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../components/secondaryComponents";
import useRolePage from "./useRolePage";
import Icon from "../../../../../../assets/icons";
import { motion } from "framer-motion";
import SubscriptionFeatureCollapse from "../../../../../../components/secondaryComponents/SubscriptionFeatures/SubscriptionFeatureCollapse";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RolePage = () => {
  const navigate = useNavigate();

  const [isActiveCard, setIsActiveCard] = useState(null);
  const { data, isLoading, isLoadingUnauthorized, dataUnauthorized } =
    useRolePage();

  return (
    <secondaryComponents.LoaderHelper
      classNames="mt-5"
      isLoading={
        isLoading || !data || isLoadingUnauthorized || !dataUnauthorized
      }
    >
      <div className={styles.back}>
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="mr-3"
            style={{ fontSize: "18px" }}
          />
          <span>Back</span>
        </primaryComponents.Button>
      </div>
      <div className={styles.role_heading}>
        <h3 className="font-weight-semibold">
          {data?.label.charAt(0).toUpperCase() +
            data?.label.slice(1).toLowerCase()}
        </h3>
        {/* <primaryComponents.Button
          classNames={`btn btn--primary ${styles.edit_btn}`}
        >
          Edit
        </primaryComponents.Button> */}
      </div>

      <h5>Permissions</h5>
      {/* Desktop */}
      <div className={styles.table_container}>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p className={`${styles.title} dark--text font-weight-bold`}></p>
          </div>
          <div className={styles.table_column}>
            <p className={styles.authorized_title}>Authorized Permissions</p>
          </div>
          <div className={styles.table_column}>
            <p className={styles.unauthorized_title}>
              Unauthorized Permissions
            </p>
          </div>
        </div>

        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Inventory Management</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label ===
                  "Inventory Management",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label ===
                  "Inventory Management",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Expense</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label === "Expense",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label === "Expense",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Payments</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label === "Payments",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label === "Payments",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Loyalty</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label === "Loyalty",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label === "Loyalty",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Accounting</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label ===
                  "Accounting",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label === "Accounting",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Customers</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label ===
                  "Customers",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label === "Customers",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.table_row}>
          <div className={styles.table_column}>
            <p>Settings</p>
          </div>
          <div className={styles.table_column}>
            {data?.role_permissions
              .filter(
                (role_perm) =>
                  role_perm.permission.permission_category.label === "Settings",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.authorizedIcon} alt="" />
                  <p>{item.permission.label}</p>
                </div>
              ))}
          </div>
          <div className={styles.table_column}>
            {dataUnauthorized
              ?.filter(
                (role_perm) =>
                  role_perm.permission_category.label === "Settings",
              )
              .map((item) => (
                <div key={item.id} className={styles.column_item}>
                  <img src={Icon.unauthorizedIcon} alt="" />
                  <p>{item.label}</p>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className={styles.planMobileView__content}>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Inventory Management</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(
                  isActiveCard === "Inventory Management"
                    ? null
                    : "Inventory Management",
                )
              }
              isActive={isActiveCard === "Inventory Management"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Inventory Management" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Inventory Management",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label ===
                    "Inventory Management",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Expense</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(isActiveCard === "Expense" ? null : "Expense")
              }
              isActive={isActiveCard === "Expense"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Expense" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Expense",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label === "Expense",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Payments</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(isActiveCard === "Payments" ? null : "Payments")
              }
              isActive={isActiveCard === "Payments"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Payments" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Payments",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label === "Payments",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Loyalty</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(isActiveCard === "Loyalty" ? null : "Loyalty")
              }
              isActive={isActiveCard === "Loyalty"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Loyalty" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Loyalty",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label === "Loyalty",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Accounting</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(
                  isActiveCard === "Accounting" ? null : "Accounting",
                )
              }
              isActive={isActiveCard === "Accounting"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Accounting" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Accounting",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label === "Accounting",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Customers</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(
                  isActiveCard === "Customers" ? null : "Customers",
                )
              }
              isActive={isActiveCard === "Customers"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Customers" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Customers",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label === "Customers",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <h5>Settings</h5>
            <SubscriptionFeatureCollapse
              toggleView={() =>
                setIsActiveCard(isActiveCard === "Settings" ? null : "Settings")
              }
              isActive={isActiveCard === "Settings"}
              isMobile={true}
            />
          </div>

          {isActiveCard === "Settings" ? (
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              className={styles.card__content}
            >
              <p className={styles.authorized_title}>Authorized Permissions</p>
              {data?.role_permissions
                .filter(
                  (role_perm) =>
                    role_perm.permission.permission_category.label ===
                    "Settings",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.authorizedIcon} alt="" />
                    <p>{item.permission.label}</p>
                  </div>
                ))}

              <hr />

              <p className={styles.unauthorized_title}>
                Unauthorized Permissions
              </p>
              {dataUnauthorized
                ?.filter(
                  (role_perm) =>
                    role_perm.permission_category.label === "Settings",
                )
                .map((item) => (
                  <div key={item.id} className={styles.column_item}>
                    <img src={Icon.unauthorizedIcon} alt="" />
                    <p>{item.label}</p>
                  </div>
                ))}
            </motion.div>
          ) : null}
        </div>
      </div>
    </secondaryComponents.LoaderHelper>
  );
};

export default RolePage;
