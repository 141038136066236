import "./DateInput.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DateInput = ({
  placeholder = "YYYY-MM-DD",
  name = "",
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  register = () => {},
  onKeyUp = () => {},
  classNames = "",
  ...props
}) => {
  return (
    <div className="date-input">
      {/* label */}
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      {/* date input  */}
      <input
        data-testid="input"
        type="date"
        className={`${classNames} ${
          errorMessage !== undefined && "error-border"
        }`}
        name={name}
        placeholder={placeholder}
        id={name}
        {...register(name, { required: isRequired })}
        onChange={(e) => {
          onChange(e);
        }}
        disabled={isDisabled}
        onKeyUp={onKeyUp}
        {...props}
      />

      {/* error message */}
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
        data-testid="error-message"
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default DateInput;
