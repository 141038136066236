import "./ComingSoon.scss";

const ComingSoon = () => {
  return (
    <div className="coming-soon">
      <h1 className="font-weight-semibold dark--text">COMING SOON</h1>
      <p>
        We are actively working to make this feature available to you very soon!
      </p>
      <div className="coming-soon__progress-bar"></div>
    </div>
  );
};

export default ComingSoon;
