import { useState, useContext } from "react";
import { requestNewTerminal } from "../../../services/pos-terminals";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";

const TerminalRequestFormLogic = () => {
  const [terminal_type, setTerminalType] = useState("");
  const [payment_option, setPaymentOption] = useState("");
  const [number_of_terminals, setNumberOfTerminals] = useState("");
  const [lumi_account, setLumiAccount] = useState("");
  const [subAccount, setSubAccount] = useState("");
  const [delivery_address, setDeliveryAddress] = useState("");
  const [contact_phone_number, setContactPhoneNumber] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );

  const handleRequestTerminal = () => {
    setIsRequesting(true);
    setRequestLoaderProgress(getRandomIntInclusive(15, 25));
    requestNewTerminal({
      terminal_type,
      payment_type: payment_option,
      quantity: number_of_terminals,
      business_id: lumi_account,
      address: contact_phone_number,
      phone_number: contact_phone_number,
      sub_accounts_id: subAccount,
    })
      .then(() => {
        setIsRequesting(false);
        setRequestLoaderProgress(100);
        triggerToast("Your request has been sent successfully.", "success");
        navigate(pathConstants.PAYMENT_POS_REQUEST_TERMINAL);
      })
      .catch((error) => {
        setIsRequesting(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    terminal_type,
    payment_option,
    number_of_terminals,
    lumi_account,
    delivery_address,
    contact_phone_number,
    subAccount,
    setSubAccount,
    setTerminalType,
    setPaymentOption,
    setNumberOfTerminals,
    setLumiAccount,
    setDeliveryAddress,
    setContactPhoneNumber,
    handleRequestTerminal,
    isRequesting,
    business_list,
  };
};

export default TerminalRequestFormLogic;
