/* eslint-disable react/jsx-key */
import styles from "./StoresTable.module.scss";
import Images from "../../../assets/images";
import useStoresTable from "./useStoresTable";
import secondaryComponents from "..";

const StoresTable = ({ storeList }) => {
  const { instance } = useStoresTable({
    storeList,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.storesTable}>
        <secondaryComponents.TableHelper
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center">
            <img
              src={Images.storeProductEmpty}
              width="250px"
              alt="no-store-product"
            />

            <h4 className=" mt-4">You have no stores</h4>
            <p>
              Use the button above to add your stores. When you do, they would
              appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>
      </div>
    </>
  );
};

export default StoresTable;
