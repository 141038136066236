import { ToastContext } from "../../../hooks/context";
import { useContext } from "react";
import primaryComponents from "../../primaryComponents";
import { useDeleteSectionMutation } from "../../../hooks/api/mutations/useSection";

const SectionDeletion = ({
  sectionDetails,
  closeWidget,
  handleFetchSections,
}) => {
  const deleteSection = useDeleteSectionMutation();
  const triggerToast = useContext(ToastContext);

  const handleDeleteSectionById = () => {
    deleteSection.mutate(
      { id: sectionDetails?.id },
      {
        onSuccess: () => {
          handleFetchSections();
          triggerToast("Successfully deleted this section", "success");
          closeWidget();
        },
      },
    );
  };

  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        Are you sure you want to delete this section
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="dark--text font-weight-semibold mb-1">
            Section Name
          </span>
          <span>{sectionDetails?.name || "N/A"}</span>
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={closeWidget}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={handleDeleteSectionById}
          classNames={"btn btn--danger cover"}
          isLoading={deleteSection.isLoading}
          isDisabled={deleteSection.isLoading}
          loadingText="Deleting..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default SectionDeletion;
