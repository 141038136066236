import { useQuery } from "react-query";
import { getMetacareAnalytics } from "../../../../services/accounting";

const useAccounting = () => {
  const { isLoading, data } = useQuery(["metacare-analytics"], () =>
    getMetacareAnalytics().then((res) => res.data?.data.reverse()),
  );

  return {
    isLoading,
    data,
  };
};

export default useAccounting;
