import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import styles from "./Suppliers.module.scss";
import useSuppliers from "./useSuppliers";
import primaryComponents from "../../../../components/primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const Suppliers = () => {
  const {
    isFetchingSuppliers,
    suppliers,
    metaDetails,
    isCreateSupplier,
    setIsCreateSupplier,
    handleFetchSuppliers,
    gotoPage,
  } = useSuppliers();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Suppliers"
      description="View all suppliers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div className={`${styles.suppliers} ${globalStyles.pageColumnGaps}`}>
            <div>
              <h4 className="mb-0">Suppliers</h4>
              <p>
                Manage your suppliers and track your orders and payments here
              </p>
            </div>
            <div className={styles.suppliers__header}>
              <p>{metaDetails?.total} Suppliers</p>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_view_edit_and_create_suppliers"
              >
                <primaryComponents.Button
                  classNames="btn btn--primary ml-auto"
                  onClick={() => setIsCreateSupplier(true)}
                >
                  + Add new supplier
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>
            <div className={styles.suppliers__content}>
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingSuppliers}
                classNames="mt-5"
              >
                <secondaryComponents.SuppliersTable
                  metaDetails={metaDetails}
                  suppliers={suppliers}
                  handleFetchSuppliers={handleFetchSuppliers}
                  gotoPage={gotoPage}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          </div>

          <secondaryComponents.Modal
            isActive={isCreateSupplier}
            closeModal={() => setIsCreateSupplier(false)}
            width="600px"
          >
            <secondaryComponents.SupplierForm
              closeWidget={() => setIsCreateSupplier(false)}
              handleFetchSuppliers={handleFetchSuppliers}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default Suppliers;
