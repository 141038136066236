import { useState } from "react";

const useProductVariantCard = () => {
  const [variantOpened, setVariantOpened] = useState(false);

  return {
    variantOpened,
    setVariantOpened,
  };
};

export default useProductVariantCard;
