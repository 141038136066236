import httpClient from "../axiosBase/axiosHandler";

export const verifyBusiness = (payload) => {
  const response = httpClient.post("/business/verify/", payload);
  return response;
};

export const uploadVerificationDocument = (formData) => {
  const response = httpClient.post("/business/documents/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
};

export const getDocumentsStatus = () => {
  const response = httpClient.get("/business/documents/");
  return response;
};

export const verifyBvnOtp = (payload) => {
  const response = httpClient.post("/business/bvn-verify-otp/", payload);
  return response;
};

export const resendBvnOtp = () => {
  const response = httpClient.post("/business/bvn-resend-otp/");
  return response;
};

export const verifyBvn = (payload) => {
  const response = httpClient.post("/business/bvn-upload/", payload);
  return response;
};
