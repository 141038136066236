import { useEffect, useState } from "react";
import styles from "./CartInputField.module.scss";

const CartInputField = ({
  updateCart,
  isProductCard = false,
  defaultValue = "",
  classNames = "",
  disabled = false,
  ...rest
}) => {
  const [inputVal, setInputVal] = useState(defaultValue);

  useEffect(() => {
    setInputVal(defaultValue);
  }, [defaultValue]);

  return (
    <input
      className={`${styles.input} ${classNames} ${
        isProductCard ? styles.isProductCard : ""
      }`}
      onChange={(e) => {
        if (!disabled) setInputVal(e.target.value);
      }}
      onBlur={() => {
        if (!disabled) updateCart(Number(inputVal));
      }}
      onMouseLeave={() => {
        if (!disabled) updateCart(Number(inputVal));
      }}
      value={inputVal}
      type="number"
      step={0.01}
      {...rest}
    />
  );
};
export default CartInputField;
