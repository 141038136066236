import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { generatePrescription } from "../../../../services/inventory";
import { ToastContext } from "../../../../hooks/context";
import useOrderflows from "../Orderflows/useOrderflows";

export const usePrescription = ({ orderId }) => {
  const { orderDetails } = useOrderflows({
    orderId,
  });
  const queryClient = useQueryClient();
  const [prescriptionDetails] = useState(() => orderDetails);
  const [prescriptionNote, setPrescriptionNote] = useState("");
  const [prescriptionItems, setPrescriptionItems] = useState(() => {
    if (orderDetails) {
      return orderDetails?.order_items.map((item) => ({
        cart_item_name: item?.cart_item_name,
        item_id: item?.id,
        morning_dosage: 0,
        afternoon_dosage: 0,
        night_dosage: 0,
        frequency: "Daily",
        measurement: 0,
        unit_id: "",
      }));
    }

    return [];
  });

  const triggerToast = useContext(ToastContext);

  const generatePrescriptionMutation = useMutation({
    mutationKey: ["generatePrescription"],
    mutationFn: (data) => generatePrescription(orderDetails?.id, data),
    onSuccess: (response) => {
      const message = response.data?.detail;
      const prescription_url = response.data?.data?.prescription_url;
      triggerToast(message, "success");
      window.open(prescription_url, "_blank");
      queryClient.invalidateQueries(["orderDetails"]);
    },
    onError: (error) => {
      if (error?.response?.status === 400)
        triggerToast(error?.response?.data?.detail, "warning");
    },
  });

  const onSubmit = () => {
    const prescription_items = prescriptionItems
      .map((item) => ({
        ...item,
        frequency: item?.frequency.toUpperCase(),
      }))
      .filter((item) => item.measurement !== 0)
      .filter((item) => item.measurement !== "");

    if (prescription_items.length === 0) {
      return triggerToast("Measure(For Liquids) field is required", "warning");
    }

    const payload = {
      prescription_items: prescription_items,
      note: prescriptionNote,
    };
    generatePrescriptionMutation.mutate(payload);
  };

  return {
    prescriptionDetails,
    prescriptionItems,
    isGeneratingPrescription: generatePrescriptionMutation.isLoading,
    prescriptionNote,
    setPrescriptionNote,
    setPrescriptionItems,
    onSubmit,
  };
};
