export const DownloadStatementIcon = () => {
  return (
    <svg
      width="24"
      height="34"
      viewBox="0 0 24 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 32L16.6667 27.3333M12 20V32V20ZM12 32L7.33337 27.3333L12 32Z"
        stroke="#7647EE"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect width="24" height="24" fill="white" />
      <path
        d="M8 6H16H8ZM8 10H16H8ZM8 14H11H8ZM6 22H18C19.1046 22 20 21.1046 20 20V4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22Z"
        stroke="#7647EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
