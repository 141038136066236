import { useState } from "react";
import { useGetStoreList } from "../../../../../hooks/api/queries";

const useStores = () => {
  const [isCreateStoreModal, setIsCreateStoreModal] = useState(false);
  const { isLoading: isFetchingStores, data: storeList } = useGetStoreList();

  return {
    isCreateStoreModal,
    storeList: storeList || [],
    isFetchingStores: isFetchingStores,
    setIsCreateStoreModal,
  };
};

export default useStores;
