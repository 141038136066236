import { useOutletContext } from "react-router-dom";
import Icon from "../../../../../../../../../assets/icons";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../../../../../components/secondaryComponents";
import { cleanObject } from "../../../../../../../../../helpers";
import styles from "./styles.module.scss";
import { useBranding } from "./useBranding";
import { ColorPicker } from "react-color-palette";
import "react-color-palette/css";

const Branding = () => {
  const { menuProperties } = useOutletContext();
  const {
    isLoadingStorefrontColor,
    menuId,
    menu_id,
    fileRef,
    color,
    showColorPalette,
    primaryColor,
    secondaryColor,
    selectedLogo,
    logoUrl,
    triggerToast,
    setColor,
    setShowColorPalette,
    setPrimaryColor,
    setSecondaryColor,
    setSelectedLogo,
    mutateStorefrontColor,
    mutateUploadLogo,
  } = useBranding({ menuProperties });

  const handleUploadPic = (event) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      const reader = new FileReader();

      if (file) {
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            // Set the image dimensions in the state

            if (img.width > 120 || img.height > 80) {
              triggerToast(
                "Please ensure your image dimension is within the range of 120px by 80px",
                "warning",
              );
            } else {
              const formData = new FormData();
              formData.append("file", file);

              mutateUploadLogo(formData);

              setSelectedLogo({
                name: file.name,
                type: file.type,
                preview: reader.result,
              });
            }
          };
        };

        reader.readAsDataURL(file);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.brandHeading}>
        <div className={styles.brandItem}>
          <img src={Icon.brand} alt="" />
          <div>
            <h5>Brand Your Storefront</h5>
            <p>Edit your storefront to fit your brand identity.</p>
          </div>
        </div>
        <primaryComponents.Button
          classNames="btn btn--primary"
          onClick={() => {
            mutateStorefrontColor(
              cleanObject({
                storefront_id: menuId ? menuId : menu_id,
                primary_colour: primaryColor,
                secondary_colour: secondaryColor,
                logo_url: logoUrl,
              }),
            );
          }}
          loadingText={"Saving..."}
          isLoading={isLoadingStorefrontColor}
          isDisabled={isLoadingStorefrontColor}
        >
          <span>Save</span>
        </primaryComponents.Button>
      </div>
      <div className={styles.brandContainer}>
        <div className={styles.brandLogo}>
          <div className={styles.logoHeading}>
            <h6>Upload Brand Logo</h6>
          </div>
          <div
            onClick={() => fileRef.current?.click()}
            className={styles.logoContainer}
          >
            <p>Click to below upload</p>
            <input
              ref={fileRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleUploadPic}
              multiple={false}
            />
            <div className={styles.uploadItem}>
              {logoUrl ? (
                <img className={styles.logo} src={logoUrl} alt="logo" />
              ) : selectedLogo ? (
                <img
                  className={styles.logo}
                  src={selectedLogo?.preview}
                  alt="logo"
                />
              ) : (
                <img src={Icon.uploadLogo} alt="logo" />
              )}
            </div>
          </div>
        </div>
        <div className={styles.colorPallete}>
          <div className={styles.colorHeading}>
            <h6>Edit Storefront Color Palette</h6>
          </div>
          <div className={styles.colorContainer}>
            <div
              onClick={() => setShowColorPalette("Primary")}
              className={styles.colorItem}
            >
              <div className={styles.textContainer}>
                <p>Primary Color</p>
                <span>
                  Colors of Header, Buttons and Prices will be updated
                </span>
              </div>
              <div>
                <div
                  style={{ background: primaryColor }}
                  className={styles.colorCircle}
                />
              </div>
            </div>
            <div
              onClick={() => setShowColorPalette("Secondary")}
              className={styles.colorItem}
            >
              <div className={styles.textContainer}>
                <p>Secondary Color</p>
                <span>Color of categorized section will be updated</span>
              </div>
              <div>
                <div
                  style={{ background: secondaryColor }}
                  className={styles.colorCircle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <secondaryComponents.Modal
        width={"686px"}
        isActive={showColorPalette ? true : false}
        closeModal={() => setShowColorPalette(null)}
      >
        <div className={styles.modalContainer}>
          <div className={styles.colorPaletteContainer}>
            <div className={styles.heading}>
              <p>
                {showColorPalette === "Primary"
                  ? "Primary Color"
                  : "Secondary Color"}
              </p>
              <span>
                Select Your{" "}
                {showColorPalette === "Primary" ? "Primary" : "Secondary"} Color
              </span>
            </div>
            <div>
              <ColorPicker color={color} onChange={setColor} />
            </div>
          </div>
          <div className={styles.buttonsContainer}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => setShowColorPalette(null)}
            >
              <span>Back</span>
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames="btn btn--primary"
              onClick={() => {
                showColorPalette === "Primary"
                  ? setPrimaryColor(color.hex)
                  : setSecondaryColor(color.hex);

                setShowColorPalette(null);
              }}
            >
              <span>Save</span>
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.Modal>
    </div>
  );
};

export default Branding;
