import styles from "./FreePlanMobileView.module.scss";
import primaryComponents from "../../../primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { formatCurrency } from "../../../../helpers";
import { PERIOD } from "../../../../helpers/constants";
import React, { useState } from "react";
import SubscriptionFeatureCollapse from "../SubscriptionFeatureCollapse";
import { motion } from "framer-motion";
import CheckItem from "../CheckItem";
import { useSubscriptionFeatures } from "../../../../hooks/useSubscriptionFeatures";
import secondaryComponents from "../..";

const FreePlanMobileView = ({
  subscription,
  activeSubscription,
  subscriptionPeriod,
  setUpgradeDetails,
  setIsPayModal,
  accessKey = "",
}) => {
  const tierPriceProperties = subscription?.pricings?.find(
    (item) => item?.validity === subscriptionPeriod,
  );
  const { subscriptionFeatures } = useSubscriptionFeatures({
    tierCurrency: tierPriceProperties?.currency,
  });
  const [isActiveCard, setIsActiveCard] = useState(null);

  return (
    <div className={styles.planMobileView}>
      <div className={styles.planMobileView__header}>
        {activeSubscription?.pricing?.tier?.name === subscription?.name ? (
          <primaryComponents.Button
            classNames={`btn btn--outline mx-auto
            ${styles.btn}`}
            isDisabled={true}
          >
            Your Current Plan
          </primaryComponents.Button>
        ) : null}

        <p
          className={`${globalStyles.plan} ${
            subscription?.name === "Essential"
              ? globalStyles.essentialPlan
              : subscription?.name === "Growth"
                ? globalStyles.growthPlan
                : subscription?.name === "Scale"
                  ? globalStyles.scalePlan
                  : ""
          } mx-auto`}
        >
          {subscription?.name}
        </p>
        <h5 className="text-center">
          {" "}
          <span
            className="error--text"
            style={{ fontSize: "30px", lineHeight: 0 }}
          >
            .
          </span>
          <span className="dark--text font-weight-bold">
            {formatCurrency({
              value: tierPriceProperties?.price,
              currencyCode: tierPriceProperties?.currency,
              decimal: 0,
            })}
          </span>
          <span className="small-text-size gray--text font-weight-normal">
            / {PERIOD?.[subscriptionPeriod]}
          </span>
        </h5>
        {activeSubscription?.pricing?.tier?.name !== subscription?.name &&
        subscription?.name !== "Free" ? (
          <primaryComponents.Button
            classNames={`btn btn--primary
            ${styles.btn}`}
            onClick={() => {
              setUpgradeDetails({
                type: subscription?.name,
                currentSubcription: subscription,
              });
              setIsPayModal(true);
            }}
          >
            Select
          </primaryComponents.Button>
        ) : activeSubscription === null ? (
          <primaryComponents.Button
            classNames={`btn btn--primary
        ${styles.btn}`}
            onClick={() => {
              setUpgradeDetails({
                type: subscription?.name,
                currentSubcription: subscription,
              });
              setIsPayModal(true);
            }}
          >
            Select
          </primaryComponents.Button>
        ) : null}
      </div>
      <div className={styles.planMobileView__content}>
        {subscriptionFeatures?.map((objectItem) => {
          return (
            <React.Fragment key={Date.now() + Math.random()}>
              <secondaryComponents.CountryItemPermission
                dontCheckCountry={
                  objectItem?.dontCheckCountry !== undefined
                    ? objectItem?.dontCheckCountry
                    : true
                }
              >
                <div
                  className={`${styles.card} ${
                    isActiveCard === objectItem?.name ? styles.isActive : ""
                  }`}
                >
                  <div className={styles.card__header}>
                    <h5>{objectItem?.name}</h5>
                    <SubscriptionFeatureCollapse
                      toggleView={() =>
                        setIsActiveCard(
                          isActiveCard === objectItem?.name
                            ? null
                            : objectItem?.name,
                        )
                      }
                      isActive={isActiveCard === objectItem?.name}
                      isMobile={true}
                    />
                  </div>

                  {isActiveCard === objectItem?.name ? (
                    <motion.div
                      initial={{ y: 20 }}
                      animate={{ y: 0 }}
                      className={styles.card__content}
                    >
                      {objectItem[objectItem?.name]?.map((item, index) => {
                        return (
                          <div key={index}>
                            <h6>{item?.name}</h6>
                            {item?.tiers?.[accessKey]?.checked ? (
                              <CheckItem>
                                <p>{item?.tiers?.[accessKey]?.value}</p>
                              </CheckItem>
                            ) : (
                              <p>{item?.tiers?.[accessKey]?.value}</p>
                            )}
                          </div>
                        );
                      })}
                    </motion.div>
                  ) : null}
                </div>
              </secondaryComponents.CountryItemPermission>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default FreePlanMobileView;
