import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import DeleteInviteUserLogic from "./DeleteInviteUserLogic";

const DeleteInvitedUser = ({
  showDeleteUserModal,
  setShowDeleteUserModal,
  userEmail,
  fetchUsersList,
}) => {
  const { isRemovingUser, removeUser } = DeleteInviteUserLogic({
    userEmail,
    fetchUsersList,
    setShowDeleteUserModal,
  });

  return (
    <secondaryComponents.Modal
      width="400px"
      isActive={showDeleteUserModal}
      closeModal={() => {
        setShowDeleteUserModal(false);
      }}
    >
      <div className="py-4 px-2">
        <h5 className="mb-3">
          Are you show you want to remove user{" "}
          <span className="purple--text">{userEmail}</span>
        </h5>
        <i className="gray--text default-font-size">
          NB: This user will no longer have access to lumi merchant Owners
          account
        </i>

        <div className="mt-4 d-flex justify-content-between">
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => setShowDeleteUserModal(false)}
          >
            Cancel
          </primaryComponents.Button>

          <primaryComponents.Button
            classNames="btn btn--danger"
            onClick={removeUser}
            isLoading={isRemovingUser}
            isDisabled={isRemovingUser}
            loadingText="Removing..."
          >
            Continue
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};
export default DeleteInvitedUser;
