import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetExpenses } from "../../../../hooks/api/queries/useExpenses";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";
import { useSelector } from "react-redux";

const useExpenses = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const [isCreateExpense, setIsCreateExpense] = useState(
    search.get("isCreateExpense") === "true" ? true : false,
  );
  const queryParams = new URLSearchParams(window.location.search);

  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      search.set("store_id", storeList[0]?.id);
      setSearch(search, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  const {
    isLoading: isFetchingExpenses,
    data: expenses,
    refetch: handleFetchExpenses,
  } = useGetExpenses(queries);

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") search.delete("store_id");
    else search.set("store_id", storeId);
    setSearch(search, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchExpenses = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isFetchingExpenses,
    metaDetails: expenses?.meta || {},
    search,
    filterByStoreId,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    setCurrentStore,
    isCreateExpense,
    expenses: expenses?.data || [],
    setIsCreateExpense,
    handleFetchExpenses,
    gotoPage,
    searchExpenses,
  };
};

export default useExpenses;
