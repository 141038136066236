import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const settelementSchema = yup.object({
  settlment_option: yup.string().required("please select an option"),
});

const SettlementsValidation = (settlment_option) => {
  return useForm({
    resolver: yupResolver(settelementSchema),
    shouldFocusError: true,
    mode: "onChange",
    defaultValues: {
      settlment_option,
    },
  });
};

export default SettlementsValidation;
