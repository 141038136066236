import "./ImageInputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ImageInputField = ({
  setShowImageModal = () => {},
  errorMessage = undefined,
  imgValue,
  altValue,
}) => {
  return (
    <div className="image-upload-input">
      <div className="d-flex">
        <div
          className="image-upload-input__btn"
          onClick={() => setShowImageModal(true)}
        >
          <div className="upload-icon white--text">+</div>
        </div>

        {imgValue !== "" && (
          <div className="ml-2 image-show-room">
            <img src={imgValue} alt={altValue} />
          </div>
        )}
      </div>
      {/* errorMessage */}
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};
export default ImageInputField;
