import { useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { refreshTransactions } from "../../../../services/transaction";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { getRandomIntInclusive, getUrlQuerysection } from "../../../../helpers";
import { useGetDashboardDetails } from "../../../../hooks/api/queries/useDashboard";

const useOverview = () => {
  const queries = getUrlQuerysection();
  const [isRefreshingBalance, setIsRefreshingBalance] = useState(false);
  const [bvnConsentUrl, setBvnConsentUrl] = useState(null);
  const {
    businessDetails: { has_wallet },
    profileDetails: { business_list },
  } = useSelector((state) => state.profileDetailsReducer);
  const { refetch: refetchDashboardDeatils } = useGetDashboardDetails({
    isEnabled: true,
    queries,
  });

  const {
    total_revenue,
    available_balance,
    total_transaction_count,
    verification_status,
    total_revenue_trend,
    total_transaction_count_trend,
    five_most_recent_transactions,
    revenue_summary,
    business,
  } = useSelector((state) => state.dashboardReducer.dashboardDetails);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const revenue_summary_count =
    revenue_summary &&
    revenue_summary?.reduce((acc, curr) => {
      return acc + curr?.total_revenue;
    }, 0);

  const refreshBalance = () => {
    setIsRefreshingBalance(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    refreshTransactions()
      .then(() => {
        triggerToast("Account balance successfully updated", "success");
        refetchDashboardDeatils();
      })
      .finally(() => {
        setRequestLoaderProgress(100);
        setIsRefreshingBalance(false);
      });
  };

  useEffect(() => {
    const businessDetails = business_list?.find(
      (businessDetail) =>
        businessDetail?.post_no_debit === true &&
        businessDetail?.bvn_consent_url,
    );

    if (businessDetails?.post_no_debit && businessDetails?.bvn_consent_url) {
      setBvnConsentUrl(businessDetails?.bvn_consent_url);
    }
  }, []);

  return {
    total_revenue,
    available_balance,
    total_transaction_count,
    verification_status,
    total_revenue_trend,
    total_transaction_count_trend,
    isRefreshingBalance,
    five_most_recent_transactions,
    revenue_summary_count,
    revenue_summary,
    has_wallet,
    business,
    bvnConsentUrl,
    setBvnConsentUrl,
    refreshBalance,
  };
};

export default useOverview;
