import { useMutation, useQuery } from "react-query";
import {
  getCustomerWallet,
  validateGiftCard,
} from "../../../services/sales-point";
import { useContext, useState } from "react";
import { ToastContext } from "../../../hooks/context";

export const useLayalty = ({ loyaltyDetails }) => {
  const triggerToast = useContext(ToastContext);
  const [dataValidate, setDataValidate] = useState(null);

  const { data: dataCustomerWallet } = useQuery(
    ["getCustomerWallet", [loyaltyDetails]],
    () =>
      getCustomerWallet(loyaltyDetails.customer_id).then(
        (res) => res?.data?.data,
      ),
    { enabled: loyaltyDetails.customer_id.length > 0 ? true : false },
  );

  const { isLoading: isLoadingValidate, mutate: mutateValidate } = useMutation({
    mutationFn: (data) => validateGiftCard(data),
    onSuccess: (data) => {
      setDataValidate(data);
      triggerToast(data.data.detail, "success");
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  return {
    dataCustomerWallet,
    dataValidate,
    isLoadingValidate,
    mutateValidate,
  };
};
