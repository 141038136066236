import "./ChartWidget.scss";
import { LineChart, Line, XAxis, YAxis } from "recharts";

const ChartWidget = () => {
  const data = [
    { name: "Page A", uv: 400, pv: 2400, amt: 2400 },
    { name: "Page B", uv: 600, pv: 2400, amt: 2600 },
    { name: "Page C", uv: 800, pv: 2400, amt: 2800 },
    { name: "Page D", uv: 500, pv: 2400, amt: 2500 },
    { name: "Page E", uv: 900, pv: 2400, amt: 2200 },
  ];
  return (
    <div className="chart-widget">
      <LineChart data={data} height={250} width={543}>
        <Line type={"monotone"} dataKey="uv" stroke="#766df4" />
        <Line type={"monotone"} dataKey="amt" stroke="#766df6" />
        <YAxis />
        <XAxis dataKey="name" />
      </LineChart>
    </div>
  );
};

export default ChartWidget;
