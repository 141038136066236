import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { updateProductBatchDetails } from "../../../services/inventory";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";

const useProductBatchForm = ({ batchInfo }) => {
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [batchDetails, setBatchDetails] = useState({
    product_name: batchInfo[0]?.product_name || "",
    batch_id: batchInfo[0]?.batch_id || "",
    cost_price: batchInfo[0]?.cost_price || "",
    selling_price: batchInfo[0]?.selling_price || "",
    stock_quantity: batchInfo[0]?.stock_quantity || "",
    discount_rate: batchInfo[0]?.discount_rate || "",
    expiry_date: batchInfo[0]?.expiry_date || "",
  });

  const { mutate: updateProductBatch, isLoading: isEditingProductBatch } =
    useMutation((data) => updateProductBatchDetails(data));

  const handleEditProductBatch = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));

    const data = cleanObject(batchDetails);
    updateProductBatch(
      { data, id: batchInfo[0]?.product_id },
      {
        onSuccess: () => {
          triggerToast("Product Batch update successful", "success");
          queryClient.invalidateQueries(["getProductBatchDetails"]);
          queryClient.invalidateQueries(["getProductsBatch"]);
          navigate(pathConstants.INVENTORY_PRODUCTS_BATCH);
        },
      },
    );
  };

  return {
    batchDetails,
    setBatchDetails,
    isEditingProductBatch,
    handleEditProductBatch,
  };
};

export default useProductBatchForm;
