import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ProductBarcode.module.scss";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import Barcode from "react-barcode";
import { formatCurrency } from "../../../helpers";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import useProductBarcode from "./useProductBarcode";
import secondaryComponents from "..";
import { useSelector } from "react-redux";

const ProductBarcode = ({
  closeWidget,
  selectedProduct,
  handleFetchproducts,
}) => {
  const {
    isLoading,
    barCodeProperties,
    isRegenratePrompt,
    createBarcode,
    setIsRegenratePrompt,
  } = useProductBarcode({
    selectedProduct,
    handleFetchproducts,
  });
  const isVariantProduct = selectedProduct?.variants?.length > 0;
  const barCodesArray = isVariantProduct
    ? barCodeProperties
    : [barCodeProperties];
  const nonVariantComponent = useRef();
  const handlePrint = useReactToPrint({
    content: () => nonVariantComponent.current,
  });
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <>
      <div className={styles.barcodeDisplay}>
        <div className="mb-3">
          <FontAwesomeIcon
            onClick={closeWidget}
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        </div>
        <h4>Barcode</h4>
        <p>
          Print generated barcode(s). <br />
          Generated barcode(s) are automatically recorded in the system
        </p>

        <secondaryComponents.LoaderHelper
          isLoading={isLoading}
          classNames="mt-5"
        >
          <div className={styles.barcodeCover} ref={nonVariantComponent}>
            {barCodesArray?.map((item, index) => (
              <div key={index} className={styles.barcodeBox}>
                <div className={styles.textwithbackground}>
                  <p>
                    {selectedProduct?.name}{" "}
                    {isVariantProduct ? `(${item?.name})` : ""}
                  </p>
                </div>
                <div className={`${styles.textwithbackground} mt-2 mb-2`}>
                  <h5 className="mb-0">
                    {isVariantProduct
                      ? formatCurrency({
                          value:
                            selectedProduct?.variants?.[index]
                              ?.store_variant_properties?.[0]?.pricings?.[0]
                              ?.price,
                          currencyCode: currency_code,
                        })
                      : formatCurrency({
                          value:
                            selectedProduct?.store_product_properties?.[0]
                              ?.pricings?.[0]?.price || 0,
                          currencyCode: currency_code,
                        })}
                  </h5>
                </div>
                <Barcode value={item?.barcode} />
              </div>
            ))}
          </div>
        </secondaryComponents.LoaderHelper>

        <div className={styles.bottomSection}>
          {/* print button */}
          <primaryComponents.Button
            classNames="btn btn--primary mx-auto cover"
            onClick={handlePrint}
            isDisabled={isLoading}
          >
            <img src={Icon.printWhiteIcon} className="mr-2" alt="" />
            Print
          </primaryComponents.Button>
          {/* generate barcode */}
          <primaryComponents.Button
            classNames="btn btn--outline mx-auto cover"
            onClick={() => setIsRegenratePrompt(true)}
            isDisabled={isLoading}
            isLoading={isLoading}
            loadingText="Regenerating..."
          >
            Regenerate Barcode
          </primaryComponents.Button>
        </div>
      </div>

      {/* regenrate confir,ation */}
      <secondaryComponents.Modal
        isActive={isRegenratePrompt}
        closeModal={() => setIsRegenratePrompt(false)}
        width="500px"
      >
        <div className="py-3 px-3">
          <h4 className="mb-3 text-center">Confirm Barcode Regeneration</h4>
          <div
            style={{
              backgroundColor: "#F3F1FC",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              borderRadius: "8px",
            }}
          >
            <p>
              Kindly be aware that regenerating the barcode will require
              updating all products associated with the current barcode using
              the newly generated one. Are you sure you want to proceed with the
              barcode regeneration
            </p>
          </div>

          <div
            className="d-flex justify-content-between mt-4"
            style={{ gap: "20px" }}
          >
            <primaryComponents.Button
              classNames="btn btn--outline cover"
              onClick={() => setIsRegenratePrompt(false)}
            >
              Cancel
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames="btn btn--primary cover"
              onClick={() => {
                createBarcode();
                setIsRegenratePrompt(false);
              }}
            >
              Continue
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.Modal>
    </>
  );
};
export default ProductBarcode;
