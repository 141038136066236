/* eslint-disable react/jsx-key */
import useCustomerCreditLogTable from "./useCustomerCreditLogTable";
import secondaryComponents from "..";
import TransactionDetailsV2 from "../StoreCheckout/TransactionDetailsV2";
import styles from "./styles.module.scss";

const CustomerCreditLogTable = ({ credits, gotoPage, metaDetails }) => {
  const { instance, selectedOrder, setSelectedOrder } =
    useCustomerCreditLogTable({
      credits,
    });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.tableContainer}>
      <secondaryComponents.TableHelper
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <h4 className="pt-5 text-center">No Data Available</h4>
      </secondaryComponents.TableHelper>

      {credits?.length > 0 && (
        <div className="mt-auto">
          <secondaryComponents.Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        </div>
      )}

      {/* transaction */}
      <secondaryComponents.SideModal
        isActive={selectedOrder !== null}
        closeModal={() => setSelectedOrder(null)}
        width={1000}
      >
        <TransactionDetailsV2
          orderId={selectedOrder}
          isOrderFlow={false}
          closeModal={() => setSelectedOrder(null)}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default CustomerCreditLogTable;
