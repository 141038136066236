import httpClient from "../axiosBase/axiosHandler";

export const createGiftCard = (payload) => {
  return httpClient.post("/giftcard/", payload);
};

export const getGiftCardList = (queries = "") => {
  return httpClient.get(`/giftcard/${queries}`);
};

export const getGiftCardDetails = (id) => {
  return httpClient.get(`/giftcard/${id}/`);
};

export const updateGiftCard = (id, update) => {
  return httpClient.patch(`/giftcard/${id}/`, { status: update });
};

export const redeemGiftCard = (code) => {
  return httpClient.post("/giftcard/redeem/", { redemption_code: code });
};
