import { useState, useEffect, useContext } from "react";
import primaryComponents from "../../primaryComponents";
import Validation from "./Validation";
import { useSelector } from "react-redux";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { useCreateCustomerMutation } from "../../../hooks/api/mutations/useCustomer";
import { ToastContext } from "../../../hooks/context";

const AddCustomer = ({
  closeModal,
  addCustomer,
  allowApiAddCustomer = false,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const triggerToast = useContext(ToastContext);
  const [customerDetails, setCustomerDetails] = useState({
    customerName: "",
    customerEmail: "",
    customerPhoneNumber: "",
    customerPhoneCode: business_phone_code,
    is_loyalty_eligible: false,
  });
  const [isLoyaltyEligible, setIsLoyaltyEligible] = useState(
    customerDetails?.is_loyalty_eligible ?? false,
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = Validation({ customerDetails });

  useEffect(() => {
    setIsLoyaltyEligible(customerDetails.is_loyalty_eligible);
  }, [customerDetails.is_loyalty_eligible]);

  const createCustomerMutation = useCreateCustomerMutation();
  const handleAddCustomer = () => {
    const data = {
      name: customerDetails?.customerName,
      email: customerDetails?.customerEmail,
      phone_code: customerDetails?.customerPhoneCode,
      phone_number: customerDetails?.customerPhoneNumber,
    };
    createCustomerMutation.mutate(data, {
      onSuccess: (res) => {
        triggerToast("Customer added successfully", "success");
        addCustomer({ ...customerDetails, customerId: res?.data?.data?.id });
        closeModal();
      },
    });
  };

  return (
    <form className="px-2 py-2 d-flex flex-column" style={{ gap: "10px" }}>
      <h4 className="text-center">Add new customer</h4>

      <primaryComponents.InputFieldV2
        isRequired
        control={control}
        label="Customer Name"
        placeholder="Enter Customer Name"
        name="customerName"
        errorMessage={errors.customerName}
        value={customerDetails?.customerName}
        onKeyUp={() =>
          errors.customerName !== undefined && trigger("customerName")
        }
        onChange={({ target: { value } }) => {
          setCustomerDetails((prev) => ({ ...prev, customerName: value }));
        }}
        register={register}
      />

      <primaryComponents.PhoneNumberInputFieldV2
        control={control}
        label="Customer Phone Number"
        placeholder="Enter Customer's Phone Number"
        name="customerPhoneNumber"
        value={customerDetails?.customerPhoneNumber}
        errorMessage={errors?.customerPhoneNumber}
        onKeyUp={() =>
          errors.customerPhoneNumber !== undefined &&
          trigger("customerPhoneNumber")
        }
        onChange={({ target: { value } }) => {
          setCustomerDetails((prev) => ({
            ...prev,
            customerPhoneNumber: value,
          }));
        }}
        isRequired
        register={register}
        phoneCodeEditable={true}
        onChangePhoneCode={(val) =>
          setCustomerDetails((prev) => ({
            ...prev,
            customerPhoneCode: val,
          }))
        }
      />

      <primaryComponents.InputFieldV2
        control={control}
        label="Customer Email"
        placeholder="Enter Customer Email"
        name="customerEmail"
        errorMessage={errors.customerEmail}
        value={customerDetails?.customerEmail}
        onKeyUp={() =>
          errors.customerEmail !== undefined && trigger("customerEmail")
        }
        onChange={({ target: { value } }) => {
          setCustomerDetails((prev) => ({ ...prev, customerEmail: value }));
        }}
        register={register}
      />

      <div className={`${globalStyles.cardWithBorder} mt-2 mb-3`}>
        <div className="d-flex justify-content-between">
          <div>
            <p className="mb-0 small-text-size">
              Enable or Disable Loyalty for this customer
            </p>
          </div>
          <primaryComponents.Switch
            name={"loyalty"}
            id={customerDetails}
            isChecked={isLoyaltyEligible}
            onChange={(value) => {
              setCustomerDetails((prev) => ({
                ...prev,
                is_loyalty_eligible: value,
              }));
            }}
          />
        </div>
      </div>

      <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          type="submit"
          isDisabled={createCustomerMutation?.isLoading}
          isLoading={createCustomerMutation?.isLoading}
          loadingText={"Adding customer..."}
          onClick={handleSubmit(() => {
            if (allowApiAddCustomer) return handleAddCustomer();
            addCustomer(customerDetails);
            closeModal();
          })}
        >
          Add customer
        </primaryComponents.Button>
      </div>
    </form>
  );
};
export default AddCustomer;
