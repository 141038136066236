import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const editSubaccountSchema = yup
  .object({
    sub_account_tag: yup.string().required("Sub-account tag is required"),
    sub_account_description: yup.string("Description is required"),
  })
  .required("All fields are required");

const EditSubAccountFormValidation = (
  sub_account_tag,
  sub_account_description,
) => {
  return useForm({
    resolver: yupResolver(editSubaccountSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      sub_account_tag,
      sub_account_description,
    },
  });
};

export default EditSubAccountFormValidation;
