import "./ResetPassword.scss";
import { Link } from "react-router-dom";
import layouts from "../../../layouts";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import ResetPasswordLogic from "./ResetPasswordLogic";
import ResetPasswordFormValidation from "./ResetPasswordValidation";
import { Controller } from "react-hook-form";

const ResetPassword = () => {
  const {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    submitPassword,
    isResetting,
    errorMessage,
  } = ResetPasswordLogic();

  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
  } = ResetPasswordFormValidation(password, confirmPassword);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Reset Password"
      description="Reset your lumi merchant password"
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        exit={{ opacity: 0 }}
        data-testid="reset-password-page"
      >
        <layouts.LoginLayout
          linkedPage={
            <p>
              Remember password?
              <Link
                className="purple--text ml-1"
                to="/login"
                data-testid="login-btn"
              >
                Log In
              </Link>
            </p>
          }
        >
          <div className="reset-password-page">
            <div className="reset-password-page__container">
              <h3 className="purple--text text-center font-weight-normal">
                Create new password
              </h3>
              {/* <p className="text-center">
            Enter a new password 
          </p> */}
              <form
                onSubmit={handleSubmit(submitPassword)}
                className="reset-password-page__container__form"
              >
                {/* error message */}
                {errorMessage !== null && (
                  <p className="text-danger text-center mb-1">{errorMessage}</p>
                )}
                <div className="mb-2">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue={password}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.PasswordInputField
                        name="password"
                        placeholder="Enter your chosen password"
                        isRequired={true}
                        label="Create Password"
                        errorMessage={errors.password}
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setPassword(value);
                        }}
                        onKeyUp={() =>
                          errors.password !== undefined && trigger("password")
                        }
                        register={register}
                      />
                    )}
                  />
                </div>
                <div className="mb-2">
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue={confirmPassword}
                    render={({ field: { onChange } }) => (
                      <primaryComponents.PasswordInputField
                        name="confirmPassword"
                        placeholder="Confirm your password"
                        isRequired={true}
                        label="Confirm Password"
                        errorMessage={errors.confirmPassword}
                        onChange={({ target: { value } }) => {
                          onChange(value);
                          setConfirmPassword(value);
                        }}
                        onKeyUp={() =>
                          errors.confirmPassword !== undefined &&
                          trigger("confirmPassword")
                        }
                        register={register}
                      />
                    )}
                  />
                </div>
                <div className="mt-2">
                  <primaryComponents.Button
                    classNames={"btn btn--primary cover"}
                    isDisabled={isResetting}
                    isLoading={isResetting}
                    loadingText="Resetting..."
                    type="submit"
                  >
                    Reset Password
                  </primaryComponents.Button>
                </div>
              </form>
              <p className="route text-center mt-3">
                Remember password?
                <Link className="purple--text ml-1" to="/login">
                  Log In
                </Link>
              </p>
            </div>
          </div>
        </layouts.LoginLayout>
      </motion.div>
    </secondaryComponents.MetaSetter>
  );
};

export default ResetPassword;
