import styles from "./OrderCombobox.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOrderComboBox, formatOrderDate } from "./useOrderCombobox";
import Icon from "../../../assets/icons";

const OrderComboBox = ({ error = "" | null, label, onChange }) => {
  const { combobox, selectedOrder, orders, displayMenu, showSpinner } =
    useOrderComboBox({ onChange });

  return (
    <div className={styles.orderComboBox}>
      <input type="hidden" name="order_id" value={selectedOrder?.id || ""} />
      <div className={styles.orderComboBoxLabel}>
        <label {...combobox.getLabelProps()}>
          <span>{label}</span>
        </label>
        {error ? (
          <em
            id="order-errorr"
            style={{ color: "red" }}
            className="text--danger"
          >
            {error}
          </em>
        ) : null}
      </div>
      <div className={styles.orderComboBoxControl}>
        <SeachIcon />
        <input
          type="text"
          {...combobox.getInputProps({
            placeholder: "Search by Order ID",
            className: styles.orderComboBoxInput,
            "aria-invalid": Boolean(error) || undefined,
            "aria-errormessage": error ? "customer-error" : undefined,
          })}
        />
        {/* Show spinner here... */}
        <Spinner showSpinner={showSpinner} />
      </div>
      <ul
        {...combobox.getMenuProps({ className: styles.orderComboBoxMenu })}
        style={{ visibility: !displayMenu ? "hidden" : "visible" }}
      >
        {displayMenu
          ? orders?.map((order, index) => (
              <li
                key={order?.id}
                {...combobox.getItemProps({
                  className: styles.orderComboBoxMenuItem,
                  key: order?.id,
                  item: order,
                  index,
                })}
              >
                <span>{order.id}</span>
                <span>{formatOrderDate(order.created_at)}</span>
              </li>
            ))
          : null}
      </ul>
    </div>
  );
};

function Spinner({ showSpinner = true }) {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        opacity: showSpinner ? "100" : "0",
      }}
    >
      <FontAwesomeIcon
        icon={["fas", "spinner"]}
        className="fa-spin mr-2 purple--text"
      />
    </div>
  );
}

function SeachIcon() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
      }}
    >
      <img src={Icon.search} alt="" className="ml-2" />
    </div>
  );
}

export default OrderComboBox;
