import "./NavLink.scss";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const NavLink = ({ children, to, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link className={"nav-link " + match ? "active" : ""} to={to} {...props}>
      {children}
    </Link>
  );
};

export default NavLink;
