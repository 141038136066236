import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import styles from "./ExpenseCategories.module.scss";
import useExpenseCategories from "./useExpenseCategories";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const ExpenseCategories = () => {
  const {
    isFetchingCategories,
    expenseCategories,
    isNewExpenseCategory,
    setIsNewExpenseCategory,
    handleFetchCategories,
  } = useExpenseCategories();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Expense categories"
      description="View all expense categories"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <div
            className={`${styles.expenseCategories} ${globalStyles.pageColumnGaps}`}
          >
            <div>
              <h4 className="mb-0">Categories</h4>
              <p>
                Organise and monitor your spending by managing expenses with
                categories.
              </p>
            </div>
            <div className={styles.expenseCategories__header}>
              <p>{expenseCategories?.length} Expense Categories</p>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Expense"}
                permissionChildKey="can_view_edit_and_create_expense_category"
              >
                <primaryComponents.Button
                  classNames="btn btn--primary ml-auto"
                  onClick={() => setIsNewExpenseCategory(true)}
                >
                  + Add new category
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            </div>

            <div className={styles.expenseCategories__content}>
              <secondaryComponents.LoaderHelper
                isLoading={isFetchingCategories}
                classNames="mt-5"
              >
                <secondaryComponents.ExpenseCategoryTable
                  expenseCategories={expenseCategories}
                  handleFetchCategories={handleFetchCategories}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          </div>

          <secondaryComponents.Modal
            isActive={isNewExpenseCategory}
            width="500px"
            closeModal={() => setIsNewExpenseCategory(false)}
          >
            <secondaryComponents.CreateExpenseCategory
              closeWidget={() => setIsNewExpenseCategory(false)}
              handleFetchCategories={handleFetchCategories}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default ExpenseCategories;
