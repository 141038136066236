import React from "react";

export const QRScanHandPhoneIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.67025 4.49976C8.67025 5.16642 11.6705 8.49976 12.3371 9.49976C13.0038 10.4998 14.6705 13.1664 15.3371 13.4998C15.3371 14.0331 15.3371 15.2775 15.3371 15.8331C13.4705 15.8331 11.8927 15.8331 11.3371 15.8331C11.3371 15.1664 11.0038 14.8331 11.0038 14.4998C11.0038 14.1664 9.33713 14.8331 7.33713 11.4998C6.67046 11.4998 4.67046 11.4998 4.33713 11.8331C4.00379 12.1664 2.33713 11.4998 4.67046 10.4998M1.33682 8.16642C1.00349 8.16642 0.0036488 8.49976 1.33698 8.83309M7.67025 6.16642C8.33691 6.49976 8.67019 7.16642 8.00352 7.16642"
        stroke="black"
        strokeWidth="0.333333"
      />
      <rect
        x="1.50358"
        y="0.333171"
        width="6.33333"
        height="10.3333"
        rx="0.166667"
        fill="white"
        stroke="black"
        strokeWidth="0.333333"
      />
      <mask id="path-3-inside-1_1794_21566" fill="white">
        <rect
          x="2.00391"
          y="1.49976"
          width="5.33333"
          height="8.66667"
          rx="0.133333"
        />
      </mask>
      <rect
        x="2.00391"
        y="1.49976"
        width="5.33333"
        height="8.66667"
        rx="0.133333"
        stroke="black"
        strokeWidth="0.666667"
        mask="url(#path-3-inside-1_1794_21566)"
      />
      <path
        d="M8.02958 7.43888C7.65098 7.32403 6.77863 7.05363 6.31929 6.89038L5.83398 7.70701L6.16604 8.37062L8.23345 9.73613L8.27173 8.49824L8.02958 7.43888Z"
        fill="white"
        stroke="white"
        strokeWidth="0.133333"
      />
      <path
        d="M10.6701 8.83325C10.0034 8.49992 8.67008 8.16659 8.33675 7.49992C8.00341 7.49992 6.67008 7.16659 6.33675 6.83325C6.00341 7.16659 5.33675 7.83325 6.33675 8.49992C7.33675 9.16659 7.67008 9.16659 8.00341 9.49992C8.33675 9.83325 8.67008 12.8333 11.3367 13.1666"
        stroke="black"
        strokeWidth="0.333333"
      />
      <circle cx="3.88477" cy="0.992676" r="0.166992" fill="#696677" />
      <rect
        x="4.36719"
        y="0.893066"
        width="1.25488"
        height="0.177618"
        rx="0.0888091"
        fill="#696677"
      />
    </svg>
  );
};
