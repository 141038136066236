import styles from "./StockHistoryStatement.module.scss";
import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import useStockHistoryStatement from "./useStockHistoryStatement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Validation from "./Validation";

const StockHistoryStatement = ({ closeWidget }) => {
  const {
    store,
    stockType,
    startDate,
    endDate,
    storeList,
    isDownloading,
    dataStockTypes,
    setStartDate,
    setEndDate,
    setStore,
    setStockType,
    downloadStockStatement,
  } = useStockHistoryStatement({ closeWidget });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation(store, stockType, startDate, endDate);

  return (
    <div className={styles.stockHistoryStatement}>
      <FontAwesomeIcon
        onClick={() => closeWidget(false)}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px" }}
      />

      <div className="mt-4">
        <h4>Stock History</h4>
        <p>
          You can customize how you want to receive your stock history with the
          options below:
        </p>

        <form className={styles.formSection}>
          {/* store */}
          <Controller
            name="store"
            control={control}
            defaultValue={store}
            render={({ field: { onChange } }) => (
              <primaryComponents.MultipleSelectField
                options={storeList}
                idKey="id"
                nameKey="name"
                value={store || "Select Store"}
                errorMessage={errors.store}
                onChange={(values) => {
                  onChange(values);
                  let newValues = [];
                  values?.forEach((item) => {
                    newValues.push(item.value);
                  });
                  setStore(newValues);
                }}
              />
            )}
          />

          {/* stock type */}
          <Controller
            name="stockType"
            control={control}
            defaultValue={stockType}
            render={({ field: { onChange } }) => (
              <primaryComponents.MultipleSelectField
                options={[{ name: "Select All", id: "All" }, ...dataStockTypes]}
                idKey="id"
                nameKey="name"
                value={stockType || "Select Stock Type"}
                errorMessage={errors.stockType}
                onChange={(values) => {
                  const allValue = values?.find((item) => item.value === "All");

                  if (allValue) {
                    onChange(
                      dataStockTypes?.map((item) => ({
                        label: item.name,
                        value: item.id,
                      })),
                    );
                    let newValues = [];
                    dataStockTypes?.forEach((item) => {
                      newValues.push(item.id);
                    });
                    setStockType(newValues);
                  } else {
                    onChange(values);
                    let newValues = [];
                    values?.forEach((item) => {
                      newValues.push(item.value);
                    });
                    setStockType(newValues);
                  }
                }}
              />
            )}
          />
          {/* Date range */}
          <div>
            <label className="mb-1">
              <span className="error--text">*</span>Date Range
            </label>
            <div className={styles.daterangeSection}>
              <div style={{ width: "max-content" }}>
                <Controller
                  name="startDate"
                  control={control}
                  defaultValue={startDate}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.DateInput
                      name="startDate"
                      value={startDate}
                      classNames="white"
                      isRequired={true}
                      errorMessage={errors.startDate}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setStartDate(value);
                      }}
                      onKeyUp={() =>
                        errors.startDate !== undefined && trigger("startDate")
                      }
                      register={register}
                    />
                  )}
                />
              </div>
              <span>To</span>
              <div style={{ width: "max-content" }}>
                <Controller
                  name="endDate"
                  control={control}
                  defaultValue={endDate}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.DateInput
                      name="endDate"
                      value={endDate}
                      classNames="white"
                      isRequired={true}
                      errorMessage={errors.endDate}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setEndDate(value);
                      }}
                      onKeyUp={() =>
                        errors.endDate !== undefined && trigger("endDate")
                      }
                      register={register}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <primaryComponents.InputField
            label="File Format"
            placeholder="File Format"
            isRequired={true}
            isDisabled={true}
            value="Excel"
          />
        </form>
      </div>

      <primaryComponents.Button
        onClick={handleSubmit(downloadStockStatement)}
        classNames="btn btn--primary cover mt-auto"
        isDisabled={isDownloading}
        isLoading={isDownloading}
        loadingText="Downloading..."
      >
        Download
      </primaryComponents.Button>
    </div>
  );
};
export default StockHistoryStatement;
