import { useTable } from "react-table";
import { useContext, useMemo, useState } from "react";
import { formatCurrency, getPlatform, truncateString } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { useNavigate } from "react-router-dom";
import { platformHandler } from "../../../hooks/context";
import Icon from "../../../assets/icons";
import { useSelector } from "react-redux";

const useSuppliersTable = ({ suppliers }) => {
  const [isDeleteSupplier, setIsDeleteSupplier] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState({});
  const [isEditSupplier, setIsEditSupplier] = useState(false);
  const [isUpdateCredit, setIsUpdateCredit] = useState(false);
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const columns = useMemo(
    () => [
      {
        Header: "Supplier Name",
        accessor: "name",
        Cell: ({ row, cell: { value } }) => truncateString(value, 200),
      },
      {
        Header: "Credit Till Date",
        accessor: "total_credit",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value ?? 0, currencyCode: currency_code }),
      },
      {
        Header: "Repayment",
        accessor: "total_repayment",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value ?? 0, currencyCode: currency_code }),
      },
      {
        Header: "Outstanding Credit",
        accessor: "total_outstanding_balance",
        Cell: ({ cell: { value } }) => (
          <span className={value === 0 ? "text-success" : "text-danger"}>
            {formatCurrency({ value: value ?? 0, currencyCode: currency_code })}
          </span>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <span className="d-flex">
            <primaryComponents.Button
              onClick={() =>
                navigate(
                  `${getPlatform(platform)}/inventory/suppliers/${
                    row?.original?.id
                  }`,
                )
              }
              classNames={"btn btn--primary smallBtn mr-2"}
            >
              View Invoices
            </primaryComponents.Button>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_suppliers"
            >
              <primaryComponents.Button
                onClick={() => {
                  setIsEditSupplier(true);
                  setSelectedSupplier(row?.original);
                }}
                classNames="btn btn--default smallBtn mr-2 btn--outline"
                style={{
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Edit Supplier
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_suppliers"
            >
              <secondaryComponents.ToolTipV2 tipText="Delete Record">
                <primaryComponents.Button
                  onClick={() => {
                    setIsDeleteSupplier(true);
                    setSelectedSupplier(row?.original);
                  }}
                  classNames={"btn btn--outline-red smallBtn"}
                  style={{ width: "12px" }}
                >
                  <img src={Icon.deleteIcon} alt="" />
                </primaryComponents.Button>
              </secondaryComponents.ToolTipV2>
            </secondaryComponents.NavigateWithPermission>
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: suppliers });
  return {
    instance,
    isDeleteSupplier,
    selectedSupplier,
    isEditSupplier,
    isUpdateCredit,
    setIsDeleteSupplier,
    setIsEditSupplier,
    setIsUpdateCredit,
  };
};

export default useSuppliersTable;
