import { useState, useContext } from "react";
import { deleteInvitedUser } from "../../../services/settings";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";

const DeleteInviteUserLogic = ({
  userEmail,
  fetchUsersList,
  setShowDeleteUserModal,
}) => {
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const removeUser = () => {
    setIsRemovingUser(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    deleteInvitedUser({ email: userEmail })
      .then(() => {
        fetchUsersList();
        setIsRemovingUser(false);
        setRequestLoaderProgress(100);
        triggerToast("User removed successfully", "success");
        setShowDeleteUserModal(false);
      })
      .catch((error) => {
        setIsRemovingUser(false);
        setRequestLoaderProgress(100);
      });
  };

  return { isRemovingUser, removeUser };
};
export default DeleteInviteUserLogic;
