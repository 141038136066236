import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import primaryComponents from "../../primaryComponents";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";

const getTableProperties = (
  isAvailable,
  setSelectedUpdateItem,
  setSelectedDeleteItem,
  navigate,
) => {
  const header1 = [
    {
      Header: "Tracking Number",
      accessor: "item_number",
    },
    {
      Header: "Order Number",
      accessor: "order_number",
      Cell: ({ cell: { value } }) => (
        <span
          className="purple--text"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(
              `${pathConstants.INVENTORY_SALES_TRANSACTIONS}?orderId=${value}`,
            );
          }}
        >
          {value}
        </span>
      ),
    },
    {
      Header: `${isAvailable ? "Restock Date" : "Date Sold"}`,
      accessor: `${isAvailable ? "created_at" : "sold_at"}`,
      Cell: ({ cell: { value } }) => (
        <span>{new Date(value).toLocaleString("en-us")}</span>
      ),
    },
    {
      Header: "Added By",
      accessor: "created_by",
      Cell: ({ cell: { value } }) => <span>{value?.first_name || "N/A"}</span>,
    },
  ];

  const header3 = isAvailable
    ? [
        {
          Header: "Actions",
          accessor: "",
          Cell: ({ cell: { row } }) => (
            <div className="d-flex" style={{ gap: "5px" }}>
              <primaryComponents.Button
                classNames={"btn btn--outline smallBtn"}
                onClick={() => {
                  setSelectedUpdateItem(row?.original);
                }}
              >
                Update
              </primaryComponents.Button>

              <primaryComponents.Button
                classNames="btn btn--danger smallBtn"
                onClick={() => {
                  setSelectedDeleteItem(row?.original);
                }}
              >
                Delete
              </primaryComponents.Button>
            </div>
          ),
        },
      ]
    : [
        {
          Header: "Customer",
          accessor: "order_customer",
          Cell: ({ cell: { value } }) => (
            <span>{value?.first_name || "N/A"}</span>
          ),
        },
      ];

  if (isAvailable) header1.splice(1, 1);
  return [...header1, ...header3];
};
const useTrackItemTable = ({ trackedItems = [] }) => {
  const navigate = useNavigate();
  const [selectedDeleteItem, setSelectedDeleteItem] = useState(null);
  const [selectedUpdateItem, setSelectedUpdateItem] = useState(null);
  const [search] = useSearchParams();
  const [header, setHeader] = useState(
    getTableProperties(search.get("status") === "available"),
    setSelectedUpdateItem,
    setSelectedDeleteItem,
    navigate,
  );

  useEffect(() => {
    setHeader(
      getTableProperties(
        search.get("status") === "available",
        setSelectedUpdateItem,
        setSelectedDeleteItem,
        navigate,
      ),
    );
  }, [search]);

  const columns = useMemo(() => {
    return header;
  }, [header]);

  const instance = useTable({ columns, data: trackedItems });

  return {
    instance,
    selectedDeleteItem,
    selectedUpdateItem,
    setSelectedDeleteItem,
    setSelectedUpdateItem,
  };
};
export default useTrackItemTable;
