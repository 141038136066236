import { setToDateMonthYearInWords } from "../../../helpers";
import { useState } from "react";

const TransactionCountLogic = () => {
  const [showInfowLine, setShowInflowLine] = useState(true);
  const [showOutfowLine, setShowOutflowLine] = useState(true);

  const tickFormatter = (tick) => {
    return setToDateMonthYearInWords(tick);
  };

  const yAxisFormat = (tick) => {
    return tick;
  };

  return {
    tickFormatter,
    yAxisFormat,
    showInfowLine,
    showOutfowLine,
    setShowInflowLine,
    setShowOutflowLine,
  };
};

export default TransactionCountLogic;
