import secondaryComponents from "..";
import usePlatformNavigator from "./usePlatFormNavigator";
const PlatformNavigator = () => {
  const { isLoading } = usePlatformNavigator();
  return (
    <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-5">
      <h4 className="py-4 px-4 text-center">
        Invalid Platform Queries, please check that all query keys are correct
      </h4>
    </secondaryComponents.LoaderHelper>
  );
};
export default PlatformNavigator;
