import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { pathConstants } from "../../../../../routes/pathContants";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../../../../../components/secondaryComponents";
import useAllCampaigns from "./useAllCampaigns";

const AllCampaigns = () => {
  const navigate = useNavigate();
  const { isLoading, campaigns } = useAllCampaigns();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - All campaigns"
      description="View campaigns created"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div className={globalStyles.pageColumnGaps}>
          <div>
            <h4 className="mb-0">Setup Loyalty Program for your Business</h4>
            <p style={{ maxWidth: "600px" }}>
              Easily configure your loyalty program by setting cashback amounts,
              selecting participating stores, generating shareable links, and
              managing your campaigns with simple on/off controls.
            </p>
          </div>

          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Loyalty"}
            permissionChildKey={"can_view_and_create_loyalty_campaigns"}
          >
            <primaryComponents.Button
              onClick={() => navigate(pathConstants.LOYALTY_CREATE_CAMPAIGN)}
              classNames="btn btn--primary ml-auto"
              isDisabled={campaigns?.length > 0}
            >
              <span className="mr-2" style={{ fontSize: "24px" }}>
                +
              </span>{" "}
              Setup Loyalty
            </primaryComponents.Button>
          </secondaryComponents.NavigateWithPermission>

          <secondaryComponents.LoaderHelper
            classNames={"mt-4"}
            isLoading={isLoading}
          >
            <secondaryComponents.CampaignList campaigns={campaigns} />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllCampaigns;
