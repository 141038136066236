import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BvnConsent.module.scss";

const BvnConsent = ({ url, closeModal }) => {
  return (
    <div className={styles.bvnConsent}>
      <FontAwesomeIcon
        onClick={() => closeModal()}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px", cursor: "pointer" }}
      />

      <h4 className="text-center">BVN CONSENT</h4>

      <p className="dark--text">
        Nigerian Inter-Bank Settlement System Plc (NIBSS) has introduced a
        consent management process called “IGREE”. This innovative approach
        ensures a transparent and verifiable method for obtaining consent to
        utilize customer&apos;s BVN information, enabling financial institutions
        like us to securely access data while maintaining a high level of
        transparency
      </p>
      <p className="dark--text" style={{ fontStyle: "italic" }}>
        *Please note that your Account number will remain in no post debit until
        you initiate this process with our partner bank(VFD).
      </p>
      <p className="dark--text">
        <span>
          Regards,
          <br />
          Ayotunde.
        </span>
      </p>

      <a
        href={url}
        target="_blank"
        className="mt-3 purple--text"
        rel="noreferrer"
      >
        Please click here to proceed:
      </a>
    </div>
  );
};
export default BvnConsent;
