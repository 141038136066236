import { useQuery } from "react-query";
import { getMetacareAnalyticsDetails } from "../../../../services/accounting";
import { useParams } from "react-router-dom";

const useAccountingDetails = () => {
  const params = useParams();

  const { data, isLoading } = useQuery(
    ["metacare-analytics-details", params.id],
    () => getMetacareAnalyticsDetails(params.id).then((res) => res.data?.data),
  );

  return {
    isLoading,
    data,
  };
};

export default useAccountingDetails;
