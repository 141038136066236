import { useState, useContext } from "react";
import { ToastContext } from "../../../hooks/context";
import {
  useAddSectionMutation,
  useEditSectionMutation,
} from "../../../hooks/api/mutations/useSection";
import { useQueryClient } from "react-query";

const useSectionForm = ({ closeModal, sectionDetails }) => {
  const triggerToast = useContext(ToastContext);
  const [sectionName, setSectionName] = useState(sectionDetails?.name || "");
  const addSectionMutation = useAddSectionMutation();
  const editSectionMutation = useEditSectionMutation();
  const queryClient = useQueryClient();

  const updateSectionById = () => {
    editSectionMutation.mutate(
      { id: sectionDetails?.id, name: sectionName },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sections"]);
          triggerToast("Section Updated Successfully", "success");
          closeModal();
        },
      },
    );
  };

  const createSection = () => {
    addSectionMutation.mutate(
      { name: sectionName },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sections"]);
          triggerToast("Section Created Successfully", "success");
          closeModal();
        },
      },
    );
  };

  return {
    updateSectionById,
    sectionName,
    setSectionName,
    isUpdating: editSectionMutation.isLoading,
    isAdding: addSectionMutation.isLoading,
    createSection,
  };
};
export default useSectionForm;
