const PDF = () => {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10067_78316)">
        <path
          d="M13.5 18.6881H0.910645V0.637695H10.3746L13.5 3.72294V18.6881Z"
          fill="#EAEAEA"
        />
        <path
          d="M4.21383 12.1654C4.03989 12.1654 3.85194 12.1005 3.68316 11.9033C3.41046 11.5853 3.3994 11.222 3.65331 10.9058C3.88474 10.618 4.33913 10.3574 5.00357 10.1319C5.25858 10.0453 5.53829 9.96531 5.83679 9.89418C6.02105 9.55993 6.19904 9.19399 6.36525 8.80889C6.51929 8.45217 6.65269 8.10244 6.76251 7.7704C6.32876 7.04258 6.00815 6.30001 6.01626 5.76013C6.02437 5.30722 6.27422 5.01609 6.70207 4.96155C7.08165 4.91327 7.37057 5.09753 7.49365 5.46716C7.66354 5.97866 7.5154 6.83879 7.2449 7.7096C7.46456 8.06155 7.70246 8.40179 7.95762 8.72892C8.18242 9.01821 8.41163 9.28797 8.63754 9.53119C9.70624 9.50982 10.416 9.66017 10.7687 9.84959C11.1398 10.0482 11.289 10.3611 11.1792 10.7082C11.0458 11.1287 10.73 11.3307 10.3125 11.261C9.77294 11.1726 9.08639 10.632 8.45622 9.97968C8.00694 9.99734 7.55885 10.0379 7.11371 10.1013C6.76841 10.1496 6.43674 10.2096 6.12645 10.2785C5.68422 11.0558 5.1683 11.7497 4.67558 12.0279C4.53574 12.112 4.37689 12.1593 4.21383 12.1654ZM5.51839 10.4333C4.76845 10.6489 4.21199 10.9153 3.99825 11.1814C3.87627 11.3332 3.88179 11.4545 4.01851 11.6141C4.10217 11.7114 4.21309 11.7825 4.45779 11.6439C4.76256 11.4722 5.13955 11.0344 5.51839 10.4348V10.4333ZM9.07976 9.97673C9.59569 10.461 10.0633 10.7731 10.3839 10.8243C10.5516 10.852 10.6788 10.8195 10.7576 10.5726C10.7794 10.5041 10.8199 10.3766 10.5601 10.2373C10.2675 10.0836 9.7383 9.99479 9.07976 9.9782V9.97673ZM7.06396 8.24469C6.97183 8.49823 6.8727 8.74698 6.77135 8.98173C6.6608 9.23969 6.5355 9.50539 6.39989 9.77147C6.61142 9.7313 6.82958 9.69555 7.05217 9.66423C7.41528 9.61337 7.75346 9.57738 8.0667 9.55625C7.90529 9.37199 7.75125 9.18368 7.60826 8.99979C7.42584 8.76651 7.24048 8.51076 7.06396 8.24616V8.24469ZM6.75809 5.40009C6.5145 5.43104 6.46327 5.56924 6.45848 5.76861C6.45259 6.09769 6.62911 6.60146 6.93756 7.1804C7.12182 6.4839 7.17599 5.91565 7.07354 5.60756C7.01716 5.43731 6.92871 5.37908 6.75772 5.40119L6.75809 5.40009Z"
          fill="#D9251C"
        />
        <path
          d="M10.3748 3.76311H13.5002L10.3748 0.637695V3.76311Z"
          fill="#C9C9C9"
        />
        <path
          d="M0.848633 14.8069H13.4999V18.6882H0.848633V14.8069Z"
          fill="#D9251C"
        />
        <path
          d="M4.46484 15.65H5.30323C5.73366 15.65 6.02626 15.8925 6.02626 16.2765C6.02626 16.7187 5.69275 16.9188 5.29291 16.9188H4.90264V17.6758H4.46484V15.65ZM4.90338 16.5713H5.23763C5.44068 16.5713 5.58404 16.4936 5.58404 16.2865C5.58404 16.0794 5.43258 15.9994 5.24684 15.9994H4.90264L4.90338 16.5713Z"
          fill="white"
        />
        <path
          d="M6.41333 15.65H7.2167C7.80633 15.65 8.19291 16.0388 8.19291 16.638C8.19291 17.2277 7.81849 17.6769 7.19791 17.6769H6.41333V15.65ZM6.8515 17.3227H7.18022C7.56864 17.3227 7.74037 17.0338 7.74037 16.6391C7.74037 16.3075 7.57969 16.0042 7.1828 16.0042H6.85113L6.8515 17.3227Z"
          fill="white"
        />
        <path
          d="M8.63647 15.65H10.0512V16.0093H9.06433V16.5057H9.9882V16.865H9.06433V17.6758H8.63647V15.65Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10067_78316">
          <rect
            width="12.6513"
            height="18.0504"
            fill="white"
            transform="translate(0.848633 0.637695)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PDF;
