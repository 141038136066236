import "./EditSubAccountForm.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import { Controller } from "react-hook-form";
import EditSubAccountLogic from "./EditSubAccountFormLogic";
import EditSubAccountFormValidation from "./EditSubAccountFormValidation";

const EditSubAccountForm = ({ openEditWidget, setOpenEditWidget, account }) => {
  const {
    sub_account_tag,
    sub_account_description,
    setSubAccountTag,
    setSubAccountDescription,
    isEdited,
    isUpdating,
    setIsEdited,
    handleEditSubAccount,
  } = EditSubAccountLogic(account, setOpenEditWidget);

  const {
    register,
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = EditSubAccountFormValidation(sub_account_tag, sub_account_description);

  return (
    <secondaryComponents.Modal
      width="550px"
      isActive={openEditWidget}
      closeModal={() => setOpenEditWidget(false)}
    >
      <div className="edit-subaccount__modal">
        <h4 className="font-weight-semibold text-center">Edit Sub-account</h4>
        <form
          onSubmit={handleSubmit(handleEditSubAccount)}
          className="edit-subaccount__modal__form"
        >
          <div>
            <Controller
              name="sub_account_tag"
              control={control}
              defaultValue={sub_account_tag}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Subaccount Tag"
                  isRequired={true}
                  placeholder="Enter Subaccount title"
                  name="sub_account_tag"
                  value={sub_account_tag}
                  errorMessage={errors.sub_account_tag}
                  register={register}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setSubAccountTag(value);
                    setIsEdited(true);
                  }}
                  onKeyUp={() =>
                    errors.sub_account_tag !== undefined &&
                    trigger("sub_account_tag")
                  }
                />
              )}
            />
          </div>
          <div>
            <Controller
              name="sub_account_description"
              control={control}
              defaultValue={sub_account_description}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Description"
                  isRequired={true}
                  placeholder="Enter description"
                  name="sub_account_description"
                  value={sub_account_description}
                  errorMessage={errors.sub_account_description}
                  register={register}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setSubAccountDescription(value);
                    setIsEdited(true);
                  }}
                  onKeyUp={() =>
                    errors.sub_account_description !== undefined &&
                    trigger("sub_account_description")
                  }
                />
              )}
            />
          </div>
          <div className="edit-subaccount__modal__actions">
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => setOpenEditWidget(false)}
            >
              Cancel
            </primaryComponents.Button>
            <primaryComponents.Button
              isDisabled={!isEdited || isUpdating}
              isLoading={isUpdating}
              loadingText="Updating..."
              classNames="btn btn--primary"
            >
              Update
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};

export default EditSubAccountForm;
