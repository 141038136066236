/* eslint-disable no-useless-escape */
import "./HorizontalDivider.scss";

const HorizontalDivider = () => {
  return (
    <>
      <div className="divider-container"></div>
    </>
  );
};

export default HorizontalDivider;
