import { useQuery } from "react-query";
import { useContext } from "react";
import { ToastContext } from "../../../../hooks/context";
import { getGeneratedPrescriptions } from "../../../../services/inventory";

export const useGeneratedPrescriptions = ({ orderId }) => {
  const triggerToast = useContext(ToastContext);

  const { data: prescriptions, isLoading: isFetchingPrescriptions } = useQuery(
    ["waybills", orderId],
    () => getGeneratedPrescriptions(orderId).then((res) => res.data?.data),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
    },
  );

  return {
    prescriptions,
    isFetchingPrescriptions,
  };
};
