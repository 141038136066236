import { useQuery } from "react-query";
import {
  getActiveSubscription,
  getSubscriptionTiers,
} from "../../../services/settings";

export const useActiveSubscriptionQuery = (enabled = true) => {
  return useQuery(
    ["activeSubscription"],
    () => getActiveSubscription().then((res) => res.data.data),
    {
      enabled: enabled,
      staleTime: Infinity,
    },
  );
};

export const useSubscriptionTiersQuery = () => {
  return useQuery(
    ["subscriptionTiers"],
    () =>
      getSubscriptionTiers().then((res) => {
        const data = res.data.data;
        return data.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
      }),
    {
      staleTime: Infinity,
    },
  );
};
