const businessSizeOptions = [
  "1",
  "2 to 5",
  "6 to 10",
  "11 to 25",
  "26 to 50",
  "51 to 200",
  "201 to 1,000",
  "1,001 or more",
];
export default businessSizeOptions;
