import { useState, useContext } from "react";
import {
  useGetCustomerDetails,
  useGetCustomerLoyaltyActivites,
  useGetStoreWalletTransaction,
} from "../../../../hooks/api/queries";
import { useCustomerLoyaltyEligibilityToggleMutation } from "../../../../hooks/api/mutations/useCustomer";
import { useLocation } from "react-router-dom";
import { ToastContext } from "../../../../hooks/context";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { useGetOrderList } from "../../../../hooks/api/queries/useOrder";

const useCustomerDetails = (customerId) => {
  const tableTab = [
    "Sales Transactions",
    "Loyalty Activity",
    "Store Wallet Activity",
  ];
  const location = useLocation();
  const [showEditModal, setShowEditModal] = useState(false);
  const [sendMessage, setSendMessage] = useState(false);
  const [showUpdateCreditLimit, setShowUpdateCreditLimit] = useState(false);
  const [selectedTableTab, setSelectedTableTab] =
    useState("Sales Transactions");
  const [modals, setModals] = useState({
    isCreateWallet: false,
    isEditModal: false,
    isSendMessage: false,
    isUpdateCreditLimit: false,
  });
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    customer_id: customerId,
    customer: customerId,
  });

  const customerLoyaltyEligibilityToggleMutation =
    useCustomerLoyaltyEligibilityToggleMutation();

  const { isLoading: isFetchingCustomerDetails, data: customerDetails } =
    useGetCustomerDetails(customerId);

  const { data: walletsTnxData, isLoading: isLoadingStoreWallets } =
    useGetStoreWalletTransaction({
      queryParams,
      isEnabled: selectedTableTab === "Store Wallet Activity",
    });

  const { data: ordersData, isLoading: isLoadingOrderData } = useGetOrderList({
    queryParams,
    isEnabled: selectedTableTab === "Sales Transactions",
  });

  const {
    isLoading: isFetchingLoyaltyActivities,
    data: customerLoyaltyActivities,
    refetch: isRefetchingCustomerLoyaltyActivites,
  } = useGetCustomerLoyaltyActivites({
    queryParams,
    isEnabled: selectedTableTab === "Loyalty Activity",
  });

  const toggleCustomerLoyalty = (id) => {
    customerLoyaltyEligibilityToggleMutation.mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(["getCustomerOrderDetails"]);
        triggerToast("Customer Loyalty Status Updated Successfully", "success");
      },
    });
  };

  const resetModals = (key, value = true) => {
    setModals({
      isCreateWallet: false,
      isEditModal: false,
      isSendMessage: false,
      isUpdateCreditLimit: false,
      [key]: value,
    });
  };

  const gotoPage = (pageNumber) => {
    setQueryParams((prev) => ({ ...prev, page: pageNumber }));
  };

  useEffect(() => {
    if (location.state?.tableTab === "Sales Transactions") {
      setSelectedTableTab("Sales Transactions");
      setQueryParams({ page: 1, customer_id: customerId });
    }

    if (location.state?.tableTab === "Loyalty Activity") {
      setSelectedTableTab("Loyalty Activity");
      setQueryParams({ page: 1, customer_id: customerId });
    }

    if (location.state?.tableTab === "Store Wallet Activity") {
      setSelectedTableTab("Store Wallet Activity");
      setQueryParams({ page: 1, customer: customerId });
    }

    setQueryParams((prev) => ({ ...prev, page: 1 }));
  }, [location]);

  return {
    sendMessage,
    customerDetails,
    isFetchingCustomerDetails,
    showUpdateCreditLimit,
    isFetchingLoyaltyActivities,
    customerLoyaltyActivities: customerLoyaltyActivities?.data || [],
    customerLoyaltyActivitiesMetaDetails: customerLoyaltyActivities?.meta || {},
    showEditModal,
    modals,
    tableTab,
    selectedTableTab,
    isLoadingStoreWallets,
    storeWalletTransactions: walletsTnxData?.data || [],
    storeWalletTnxMetaDetails: walletsTnxData?.meta || {},
    orders: ordersData?.data || [],
    orderMetails: ordersData?.meta || [],
    isLoadingOrderData,
    setSelectedTableTab,
    setModals,
    resetModals,
    toggleCustomerLoyalty,
    gotoPage,
    setSendMessage,
    setShowUpdateCreditLimit,
    isRefetchingCustomerLoyaltyActivites,
    setShowEditModal,
  };
};
export default useCustomerDetails;
