import { useContext, useMemo, useState } from "react";
import { useTable } from "react-table";
import { formatCurrency, getRandomIntInclusive } from "../../../helpers";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { useSelector } from "react-redux";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { useMutation } from "react-query";
import { useSearchParams } from "react-router-dom";
import {
  cancelPendingSession,
  continuePendingSession,
} from "../../../services/sales-point";
import usePendingCheckoutSession from "../../../hooks/usePendingCheckoutSession";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const usePendingCheckout = ({ closeWidget, setIsRefreshCart }) => {
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [search, setSearch] = useSearchParams();
  const [showCancelPrompt, setShowCancelPrompt] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { data: cartItems } = useSelector(
    (state) => state.cartReducer.cartDetails,
  );
  const triggerToast = useContext(ToastContext);
  const {
    isFetchingSessions,
    pendingSessions,
    metaDetails,
    gotoPage,
    refetchPendingSessions,
  } = usePendingCheckoutSession();

  const { store_currency } = useStoreCurrency();

  const statusMap = {
    PENDING_CHECKOUT: "Pending Checkout",
    AWAITING_PAYMENT: "Awaiting Payment",
  };
  const statusColor = {
    PENDING_CHECKOUT: "chilled--text",
    AWAITING_PAYMENT: "pending--squared",
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined
              ? "N/A"
              : new Date(value).toLocaleString("en-us")}
          </span>
        ),
      },
      {
        Header: "Cashier",
        accessor: "user",
        Cell: ({ cell: { value } }) =>
          value === null ? "N/A" : value?.first_name,
      },
      {
        Header: "Items",
        accessor: "items",
        Cell: ({ cell: { value } }) => (
          <secondaryComponents.ConcatenateWithTooltip
            value={value}
            noOfCharacters={50}
          />
        ),
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => (
          <span>
            {formatCurrency({ value: value, currencyCode: store_currency })}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`${statusColor[value]}`}>
            {value === null ? "N/A" : statusMap[value]}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <primaryComponents.Button
                classNames="btn btn--primary smallBtn"
                onClick={() => {
                  if (
                    row?.original?.status !== "AWAITING_PAYMENT" &&
                    cartItems?.length > 0
                  )
                    return triggerToast(
                      "Please clear your cart to continue with pending orders",
                      "warning",
                    );
                  continueCheckout(row?.original?.id, row?.original?.status);
                }}
              >
                Checkout
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames="btn btn--outline smallBtn"
                onClick={() => {
                  setSelectedItem(row?.original);
                  setShowCancelPrompt(true);
                }}
              >
                Cancel
              </primaryComponents.Button>
            </span>
          );
        },
      },
    ],
    [],
  );
  const instance = useTable({ columns, data: pendingSessions || [] });

  const { mutate: continueSession } = useMutation((data) =>
    continuePendingSession(data),
  );
  const continueCheckout = (sessionId, status) => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    continueSession(
      { id: sessionId },
      {
        onSuccess: (res) => {
          if (status === "AWAITING_PAYMENT") {
            search.set("order_id", res?.data?.data?.order_id);
            setSearch(search, { replace: true });
          } else setIsRefreshCart(true);
          refetchPendingSessions();
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const { mutate: cancelSession } = useMutation((data) =>
    cancelPendingSession(data),
  );
  const cancelCheckout = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    cancelSession(
      { id: selectedItem?.id },
      {
        onSuccess: () => {
          refetchPendingSessions();
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    selectedItem,
    instance,
    isFetchingSessions,
    pendingSessions,
    metaDetails,
    gotoPage,
    refetchPendingSessions,
    showCancelPrompt,
    cancelCheckout,
    setShowCancelPrompt,
  };
};

export default usePendingCheckout;
