import { useState, useContext } from "react";
import { updateCategoryDetails } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useQueryClient } from "react-query";

const EditProductCategoryLogic = ({ category, setIsOpen }) => {
  const [isUpdatingCategory, setIsUpdatingCategory] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [categoryName, setCategoryName] = useState(category.name || "");

  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();

  const handleUpdateCategory = () => {
    setIsUpdatingCategory(true);
    setRequestLoaderProgress(40);
    updateCategoryDetails(category.id, {
      name: categoryName,
    })
      .then(() => {
        setIsUpdatingCategory(false);
        setRequestLoaderProgress(100);
        queryClient.invalidateQueries(["getProductCategoryList"]);
        setIsOpen(false);
        triggerToast("Product category updated successfully", "success");
      })
      .catch(() => {
        setIsUpdatingCategory(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    categoryName,
    isUpdatingCategory,
    isEdited,
    setIsEdited,
    setIsUpdatingCategory,
    setCategoryName,
    handleUpdateCategory,
  };
};

export default EditProductCategoryLogic;
