import httpClient from "../axiosBase/axiosHandler";

export const getProfile = () => {
  const response = httpClient.get("/profile/detail/");
  return response;
};

export const getBusiness = () => {
  const response = httpClient.get("/business/profile/");
  return response;
};

export const updatePersonalInfo = (payload) => {
  const response = httpClient.post("/profile/update/", payload);
  return response;
};

export const updateBusinessInfo = (payload) => {
  const response = httpClient.post(
    "/business/update-business-profile/",
    payload,
  );
  return response;
};

export const sendUserInvite = (payload) => {
  const response = httpClient.post("/business/invite-team-member/", payload);
  return response;
};

export const getTeamMembers = () => {
  const response = httpClient.get("/business/team-members/");
  return response;
};

export const updateUserInvite = (payload) => {
  const response = httpClient.post("/business/update-team-member/", payload);
  return response;
};

export const deleteInvitedUser = (payload) => {
  const response = httpClient.post("/business/delete-team-member/", payload);
  return response;
};

export const getApiKeys = () => {
  const response = httpClient.get("/settings/api-token/");
  return response;
};

export const generateNewApiKeys = () => {
  const response = httpClient.post("/settings/api-token/regenerate/");
  return response;
};

export const revealApiKeys = () => {
  const response = httpClient.post("/settings/api-token/reveal/");
  return response;
};

export const getSubscriptionHistory = () => {
  const response = httpClient.get("/subscriptions/subscription-history/");
  return response;
};

export const getActiveSubscription = () => {
  const response = httpClient.get("/subscriptions/active-subscription/");
  return response;
};

export const getSubscriptionTiers = () => {
  const response = httpClient.get("/subscriptions-tiers/");
  return response;
};

export const confirmSubscriptionPayment = (data) => {
  const response = httpClient.post("/subscriptions/pay/", data);
  return response;
};

export const createSubscriptionPaymentSession = (data) => {
  const response = httpClient.post("/subscriptions/", data);
  return response;
};

export const createFreePlan = (data) => {
  const response = httpClient.post("/subscriptions/create-free/", data);
  return response;
};

// Manage roles
export const inviteNewUser = (data) => {
  const response = httpClient.post("/business/invite-team-member/", data);
  return response;
};

export const createRole = (data) => {
  const response = httpClient.post("/roles/", data);
  return response;
};

export const editRole = (id, data) => {
  const response = httpClient.patch(`/roles/${id}/`, data);
  return response;
};

export const getPermissions = () => {
  const response = httpClient.get("/permissions/");
  return response;
};

export const getPermissionCategories = () => {
  const response = httpClient.get("/permission_categories/");
  return response;
};

export const getAllRoles = () => {
  const response = httpClient.get("/roles/");
  return response;
};

export const getAllCustomRoles = (pageNumber) => {
  const response = httpClient.get(`/roles/?page=${pageNumber}`);
  return response;
};

export const getSingleRole = (id) => {
  const response = httpClient.get(`/roles/${id}`);
  return response;
};

export const getUnauthorizedPermissions = (id) => {
  const response = httpClient.get(`/roles/${id}/unauthorized-permissions`);
  return response;
};

export const deleteRole = (id) => {
  const response = httpClient.delete(`/roles/${id}`);
  return response;
};

export const createConnection = (data) => {
  const response = httpClient.post("/client/create-connection/", data);
  return response;
};

export const validateClientInfo = (data) => {
  const response = httpClient.post("/client/validate-request-token/", data);
  return response;
};
