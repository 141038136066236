import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetCustomerAnalytics } from "../../../../hooks/api/queries/useDashboard";

const useCustomerAnalytics = () => {
  const queries = getUrlQuerysection();
  const [
    allCustomerAnalytics,
    newCustomerAnalytics,
    returningCustomerAnalytics,
    AVSUCustomerAnalytics,
    topCustomers,
  ] = useGetCustomerAnalytics({ queries });
  const [search] = useSearchParams();

  return {
    search,
    isLoadingAllCustomers: allCustomerAnalytics?.isLoading,
    isLoadingNewCustomers: newCustomerAnalytics?.isLoading,
    isLoadingReturnedCustomers: returningCustomerAnalytics?.isLoading,
    isLoadingASPU: AVSUCustomerAnalytics?.isLoading,
    isLoadingTopCustomers: topCustomers?.isLoading,
    allCustomerData: allCustomerAnalytics?.data || { trend: [] },
    newCustomerData: newCustomerAnalytics?.data || { trend: [] },
    returnedCustomerData: returningCustomerAnalytics?.data || { trend: [] },
    ASPUCustomerData: AVSUCustomerAnalytics?.data || { trend: [] },
    topCustomers: topCustomers?.data || [],
  };
};
export default useCustomerAnalytics;
