import "./CustomerSubLayout.scss";
import { Outlet, useSearchParams } from "react-router-dom";
import primaryComponents from "../../components/primaryComponents";
import { useState } from "react";
import secondaryComponents from "../../components/secondaryComponents";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";

const CustomerSubLayout = () => {
  const [search, setSearch] = useSearchParams();
  const [openAddCustomerWidget, setOpenAddCustomerWidget] = useState(false);
  const [terminalHeader, setTerminalHeader] = useState({
    title: "",
    description: "",
  });
  return (
    <div className={`customers-layout ${globalStyles.pageColumnGaps}`}>
      <div>
        <h4 className="mb-0">{terminalHeader?.title}</h4>
        <p style={{ maxWidth: "600px" }}>{terminalHeader?.description}</p>
      </div>

      <div className="customers-layout__buttons d-flex ml-auto">
        <secondaryComponents.NavigateWithPermission
          permissionParentKey={"Loyalty"}
          permissionChildKey="can_redeem_gift_card"
        >
          <primaryComponents.Button
            classNames={"btn btn--outline"}
            onClick={() => {
              search.set("redemption", "true");
              setSearch(search, { replace: true });
            }}
          >
            Gift card redemption
          </primaryComponents.Button>
        </secondaryComponents.NavigateWithPermission>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          onClick={() => setOpenAddCustomerWidget(true)}
        >
          <span className="mr-1 mb-1" style={{ fontSize: "22px" }}>
            +
          </span>
          <span>Add Customer</span>
        </primaryComponents.Button>
      </div>

      <Outlet context={{ setTerminalHeader }} />

      <secondaryComponents.SideModal
        isActive={openAddCustomerWidget}
        closeModal={() => setOpenAddCustomerWidget(false)}
      >
        <secondaryComponents.AddCustomerWidget
          closeWidget={() => setOpenAddCustomerWidget(false)}
        />
      </secondaryComponents.SideModal>

      <secondaryComponents.SideModal
        isActive={search.get("redemption") === "true"}
        closeModal={() => {
          search.delete("redemption");
          setSearch(search, { replace: true });
        }}
      >
        <secondaryComponents.GiftCardWidget
          closeWidget={() => {
            search.delete("redemption");
            setSearch(search, { replace: true });
          }}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default CustomerSubLayout;
