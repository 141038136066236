/* eslint-disable array-callback-return */
import "./InventoryLayout.scss";
import { Outlet } from "react-router-dom";
import useInventoryLayout from "./useInventoryLayout";
import Icon from "../../assets/icons";
import secondaryComponents from "../../components/secondaryComponents";
import React from "react";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";

const InventoryLayout = () => {
  const { openAddProductWidget, setOpenAddProductWidget } =
    useInventoryLayout();

  return (
    <div className={`${globalStyles?.pagePaddings} inventory-layout`}>
      {/* <h3 className="font-weight-semibold mb-0">Inventory</h3> */}

      <div className="inventory-layout__content">
        <Outlet />
      </div>

      {/* display only on mobile */}
      <button
        onClick={() => setOpenAddProductWidget(true)}
        className="floating-button"
      >
        <img src={Icon.add} alt="" />
      </button>

      <secondaryComponents.Modal
        isActive={openAddProductWidget}
        closeModal={() => setOpenAddProductWidget(false)}
        width={"500px"}
      >
        <secondaryComponents.AddProductWidget
          closeModal={() => setOpenAddProductWidget(false)}
        />
      </secondaryComponents.Modal>
    </div>
  );
};

export default InventoryLayout;
