import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { ToastContext } from "../../../../../hooks/context";
import { bulkTransferstock } from "../../../../../services/inventory";
import { cleanObject, deepCopyFunction } from "../../../../../helpers";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../../../routes/pathContants";

export const useCreateStockTransfer = () => {
  const triggerToast = useContext(ToastContext);
  const [transferData, setTransferData] = useState([]);
  const [isCreateRequest, setIsCreateRequest] = useState(false);
  const navigate = useNavigate();
  const [otherTransferInfo, setOtherTransferInfo] = useState({
    transfer_from_store_id: "",
    transfer_to_store_id: "",
  });

  const { mutate: tranferStockMutation, isLoading: isTransfering } =
    useMutation((data) => bulkTransferstock(data));
  const createTransferProducts = () => {
    if (transferData?.length === 0)
      return triggerToast(
        "You must transfer at least one item with some quantity",
        "warning",
      );

    const newTransferData = deepCopyFunction(transferData);
    const rearrangedData = newTransferData?.map((dataItem) => {
      return cleanObject({
        product_id: dataItem?.product_id,
        variant_id: dataItem?.variant_id,
        quantity: dataItem?.quantity,
      });
    });

    tranferStockMutation(
      {
        items: rearrangedData,
        transfer_from_store_id: otherTransferInfo.transfer_from_store_id,
        transfer_to_store_id: otherTransferInfo.transfer_to_store_id,
      },
      {
        onSuccess: () => {
          triggerToast("Stock Transfer Successfully", "success");
          navigate(pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY);
        },
      },
    );
  };

  return {
    isTransfering,
    transferData,
    isCreateRequest,
    createTransferProducts,
    setOtherTransferInfo,
    setIsCreateRequest,
    setTransferData,
  };
};
