import secondaryComponents from "../../../../../components/secondaryComponents";

import useCreateStore from "./useCreateStore";

const CreateStore = () => {
  const {
    isLoadingCountries,
    isLoadingStates,
    states,
    countries,
    setSelectedCountry,
  } = useCreateStore();

  return (
    <>
      <secondaryComponents.LoaderHelper
        classNames="mt-5"
        isLoading={isLoadingCountries}
      >
        <secondaryComponents.StoreForm
          isLoadingStates={isLoadingStates}
          states={states}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
        />
      </secondaryComponents.LoaderHelper>
    </>
  );
};

export default CreateStore;
