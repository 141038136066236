import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useAllStores from "./useAllStores";
import styles from "./AllStores.module.scss";
import primaryComponents from "../../../components/primaryComponents";
import Icon from "../../../assets/icons";
import { useState } from "react";
import Images from "../../../assets/images";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";

const AllStores = () => {
  const { storeList } = useAllStores();
  const [storeIcons] = useState([
    Icon.storeIcon1,
    Icon.storeIcon2,
    Icon.storeIcon3,
  ]);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Stores"
      description="View all stores"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0 }}
          className={`${styles.allStores} ${globalStyles.pageColumnGaps}`}
        >
          <h3 className="font-weight-semibold">Stores</h3>

          {storeList?.length > 0 ? (
            <div className={styles.storeList}>
              {storeList?.map((store, index) => (
                <div className={styles.storeList__item} key={store?.id}>
                  <img src={storeIcons[index % 3]} alt="store icon" />
                  <h4 className="font-weight-normal">{store.name}</h4>
                  <primaryComponents.Button
                    classNames="btn btn--primary"
                    onClick={() =>
                      window.open(`/sell-mode/${store?.id}`, "_blank")
                    }
                  >
                    View Store
                  </primaryComponents.Button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center mt-5">
              <img
                width="250px"
                height="262px"
                src={Images.storeProductEmpty}
                alt="empty state"
              />
              <h4>You haven&apos;t created a store yet </h4>
              <p>When you do, they would appear here.</p>
            </div>
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllStores;
