import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUrlQuerysection } from "../../../helpers";
import { useActiveSubscriptionQuery } from "../../../hooks/api/queries";
import { pathConstants } from "../../../routes/pathContants";

const useAppGuard = ({ checkSubscription = true }) => {
  const [grantAccess, setGrantAcces] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { data: activeSubscription } = useActiveSubscriptionQuery(
    role !== null && role !== undefined,
  );

  useEffect(() => {
    if (!localStorage.getItem("refreshToken")) {
      localStorage.setItem(
        "nextRoute",
        location.pathname + getUrlQuerysection(),
      );
      return navigate(pathConstants.LOGIN);
    }

    if (!activeSubscription && checkSubscription)
      navigate(pathConstants.APP_SUBSCRIPTION_PLAN);

    setGrantAcces(true);
  }, []);

  return { grantAccess };
};

export default useAppGuard;
