import styles from "./EditSupplier.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import useEditSupplier from "./useEditSupplier";
import primaryComponents from "../../../../components/primaryComponents";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditSupplier = () => {
  const { isFetchingSupplierInfo, data } = useEditSupplier();
  const navigate = useNavigate();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Edit Supplier"
      description="Edit supplier details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div className={styles.editSupplier}>
          <h3>Inventory</h3>
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => navigate(-1)}
            type="button"
            style={{ width: "max-content" }}
          >
            <FontAwesomeIcon
              icon="angle-left"
              className="mr-3"
              style={{ fontSize: "18px" }}
            />
            <span>Back</span>
          </primaryComponents.Button>
          <div className={styles.editSupplier__details}>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingSupplierInfo}
              classNames={"mt-3"}
            >
              <secondaryComponents.SupplierForm
                supplierInfo={data}
                isEditMode={true}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditSupplier;
