import styles from "./GenerateWaybillForm.module.scss";
import CustomersSearchField from "../CustomersSearchField";
import primaryComponents from "../../primaryComponents";

import { useGenerateWaybill } from "./useGenerateWaybill";
import { useValidateForm } from "./validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { truncateString } from "../../../helpers";

const GenerateWaybillForm = ({ closeWidget, orderDetails }) => {
  const setCustomerDetails = (phone, names) => {
    setValue("customerPhoneNumber", phone);
    setValue("customerName", names);
    setCustomerName(names);
    setCustomerPhoneNumber(phone);
  };

  const {
    customerEmail,
    customerName,
    customerPhoneNumber,
    driverName,
    driverPhoneNumber,
    vehicleNumber,
    logisticsCompany,
    currentStep,
    waybillItems,
    isGeneratingWaybill,
    hasSelectedAnItem,
    isValidatingWaybillItems,
    customerAddress,
    setCustomerAddress,
    setWaybillItems,
    setCurrentStep,
    setDriverName,
    setDriverPhoneNumber,
    setDriverPhoneCode,
    setLogisticsCompany,
    setVehicleNumber,
    setCustomerEmail,
    setCustomerName,
    setCustomerPhoneNumber,
    setCustomerPhoneCode,
    handleGenerateWaybill,
    handleValidateWaybillItems,
  } = useGenerateWaybill({ orderDetails, closeWidget });

  const {
    register,
    // handleSubmit,
    formState: { errors },
    control,
    trigger,
    setValue,
    handleSubmit,
  } = useValidateForm({
    customerEmail,
    customerName,
    customerPhoneNumber,
    driverName,
    driverPhoneNumber,
    vehicleNumber,
    logisticsCompany,
  });

  return (
    <div className={styles.generateWaybillForm}>
      <div className="d-flex align-items-center">
        {currentStep === "waybill_info" ? (
          <FontAwesomeIcon
            onClick={() => setCurrentStep("waybill_items")}
            className="d-block"
            icon="angle-left"
            style={{ fontSize: "25px", cursor: "pointer" }}
          />
        ) : null}
        <FontAwesomeIcon
          onClick={() => closeWidget()}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </div>
      {currentStep === "waybill_items" ? (
        <>
          <div className="mt-3">
            <h4>Waybill Items</h4>
            <p>Select and confirm the items for your waybill</p>
          </div>
          <div className={styles.generateWaybillForm__orderItems}>
            {waybillItems.map((order, index) => (
              <div
                key={order?.id}
                className={styles["generateWaybillForm__orderItems-item"]}
              >
                <label
                  className="d-flex align-items-center gap-5"
                  htmlFor={order?.id}
                >
                  <primaryComponents.Checkbox
                    name={order?.cart_item_name}
                    id={order?.id}
                    isChecked={order?.isDisabled === false}
                    onChange={(value) => {
                      const order_items = [...waybillItems];
                      order_items[index].isDisabled = !value;
                      setWaybillItems(order_items);
                    }}
                  />
                  <span className="ml-2">
                    {truncateString(waybillItems[index]?.cart_item_name, 45)}
                  </span>
                </label>
                <div>
                  <input
                    type="number"
                    defaultValue={
                      waybillItems[index]?.waybill_remaining_quantity
                    }
                    value={waybillItems[index]?.quantity}
                    disabled={waybillItems[index]?.isDisabled}
                    onChange={(event) => {
                      const value = event.target.value;
                      const order_items = [...waybillItems];
                      order_items[index].quantity = value;
                      setWaybillItems(order_items);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mt-3">
            <h4>Logistics</h4>
            <p>
              Enter the details needed for the delivery of the waybill items
            </p>
          </div>
          {/* Customer information */}
          <div className="mt-5">
            <h5 className="mb-2">Select Exiting Users Here</h5>
            <CustomersSearchField
              setCustomerDetails={setCustomerDetails}
              phone={""}
            />
            <p className="my-5 text-center">OR</p>
            <h5 className="mt-5">Customer Information</h5>
            <Controller
              name="customerName"
              control={control}
              defaultValue={customerName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  placeholder="Enter Customer Name"
                  isRequired={true}
                  name="customerName"
                  errorMessage={errors.customerName}
                  value={customerName}
                  onKeyUp={() =>
                    errors.customerName !== undefined && trigger("customerName")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCustomerName(value);
                  }}
                  register={register}
                />
              )}
            />
            <Controller
              name="customerEmail"
              control={control}
              defaultValue={customerName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  placeholder="Enter Customer Email"
                  isRequired={true}
                  name="customerEmail"
                  errorMessage={errors.customerEmail}
                  value={customerEmail}
                  onKeyUp={() =>
                    errors.customerEmail !== undefined &&
                    trigger("customerEmail")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCustomerEmail(value);
                  }}
                  register={register}
                />
              )}
            />

            <Controller
              name="customerPhoneNumber"
              control={control}
              defaultValue={customerPhoneNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.PhoneNumberInputField
                  placeholder="Enter Customer Phone Number"
                  isRequired={true}
                  name="customerPhoneNumber"
                  classNames="mb-1"
                  value={customerPhoneNumber}
                  errorMessage={errors.customerPhoneNumber}
                  onKeyUp={() =>
                    errors.customerPhoneNumber !== undefined &&
                    trigger("customerPhoneNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCustomerPhoneNumber(value);
                  }}
                  register={register}
                  phoneCodeEditable={true}
                  onChangePhoneCode={(val) => setCustomerPhoneCode(val)}
                />
              )}
            />

            <Controller
              name="customerAddress"
              control={control}
              defaultValue={customerAddress}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  placeholder="Enter Delivery address"
                  isRequired={true}
                  name="customerAddress"
                  errorMessage={errors.customerAddress}
                  value={customerAddress}
                  onKeyUp={() =>
                    errors.customerAddress !== undefined &&
                    trigger("customerAddress")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCustomerAddress(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>

          {/* Logistics information */}
          <div style={{ marginTop: "56px" }}>
            <h5 className="dark--text font-semibold mb-3">Logistics</h5>
            {/* <Controller
              name="waybillNumber"
              control={control}
              defaultValue={waybillNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Name"}
                  placeholder="Enter Waybill Number"
                  isRequired={true}
                  name="waybillNumber"
                  errorMessage={errors.waybillNumber}
                  value={waybillNumber}
                  onKeyUp={() =>
                    errors.waybillNumber !== undefined &&
                    trigger("waybillNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setWaybillNumber(value);
                  }}
                  register={register}
                />
              )}
            /> */}
            <Controller
              name="driverName"
              control={control}
              defaultValue={driverName}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Name"}
                  placeholder="Enter Driver Name"
                  isRequired={true}
                  name="driverName"
                  errorMessage={errors.driverName}
                  value={driverName}
                  onKeyUp={() =>
                    errors.driverName !== undefined && trigger("driverName")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setDriverName(value);
                  }}
                  register={register}
                />
              )}
            />
            <Controller
              name="vehicleNumber"
              control={control}
              defaultValue={vehicleNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Driver's Vehicle Number"}
                  placeholder="Enter Driver Vehicle Number"
                  isRequired={true}
                  name="vehicleNumber"
                  errorMessage={errors.vehicleNumber}
                  value={vehicleNumber}
                  onKeyUp={() =>
                    errors.vehicleNumber !== undefined &&
                    trigger("vehicleNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setVehicleNumber(value);
                  }}
                  register={register}
                />
              )}
            />
            <Controller
              name="driverPhoneNumber"
              control={control}
              defaultValue={driverPhoneNumber}
              render={({ field: { onChange } }) => (
                <primaryComponents.PhoneNumberInputField
                  // label={"Driver's Phone Number"}
                  placeholder="Enter Driver Phone Number"
                  isRequired={true}
                  name="driverPhoneNumber"
                  classNames="mb-1"
                  value={driverPhoneNumber}
                  errorMessage={errors.driverPhoneNumber}
                  onKeyUp={() =>
                    errors.driverPhoneNumber !== undefined &&
                    trigger("driverPhoneNumber")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setDriverPhoneNumber(value);
                  }}
                  register={register}
                  phoneCodeEditable={true}
                  onChangePhoneCode={(val) => setDriverPhoneCode(val)}
                />
              )}
            />
            <Controller
              name="logisticsCompany"
              control={control}
              defaultValue={logisticsCompany}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  // label={"Logisitcs Company"}
                  placeholder="Enter transport Company"
                  isRequired={false}
                  name="logisticsCompany"
                  errorMessage={errors.logisticsCompany}
                  value={logisticsCompany}
                  onKeyUp={() =>
                    errors.logisticsCompany !== undefined &&
                    trigger("logisticsCompany")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setLogisticsCompany(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>
        </>
      )}

      <div className={styles.generateWaybillForm__bottom}>
        {currentStep === "waybill_items" ? (
          <primaryComponents.Button
            onClick={() => handleValidateWaybillItems()}
            classNames={"btn btn--primary cover"}
            isDisabled={!hasSelectedAnItem || isValidatingWaybillItems}
            isLoading={isValidatingWaybillItems}
            loadingText={"Validating selected items..."}
          >
            Next
          </primaryComponents.Button>
        ) : (
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            isDisabled={isGeneratingWaybill}
            isLoading={isGeneratingWaybill}
            loadingText="Generating..."
            onClick={handleSubmit(handleGenerateWaybill)}
          >
            Submit
          </primaryComponents.Button>
        )}
      </div>
    </div>
  );
};

export default GenerateWaybillForm;
