import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import { motion } from "framer-motion";
import styles from "./ReceiptTerminals.module.scss";
import useReceiptTerminals from "./useReceiptTerminals";
import Images from "../../../../assets/images";

const ReceiptTerminalsV2 = ({
  orderId,
  isOrderFlow = true,
  sectionId = null,
  closeModal = () => {},
  setSectionId = () => {},
}) => {
  const {
    isFetchingTerminals,
    isPrinting,
    isPrintingSection,
    selectedTerminal,
    terminalList,
    sendOrderReceipt,
    sendSectionItemOReceipt,
  } = useReceiptTerminals({
    orderId,
    sectionId,
    setSectionId,
  });
  return (
    <div>
      {!isOrderFlow ? (
        <FontAwesomeIcon
          onClick={closeModal}
          className="d-block ml-auto mb-4"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      ) : null}

      <secondaryComponents.LoaderHelper
        isLoading={isFetchingTerminals}
        classNames="mt-5"
      >
        <motion.div
          className={`${styles.terminalList}`}
          initial={{ y: 100 }}
          animate={{ y: 0 }}
        >
          {terminalList?.length > 0 ? (
            <>
              {terminalList?.map((item) => (
                <div className={styles.terminalItem} key={item?.id}>
                  <div className="d-flex">
                    <h5>Terminal: {item?.terminal_id}</h5>
                    <div className={styles.terminalLocation}>
                      {item.business_tag}
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <p className="small-text-size">SN: {item?.terminal_sn}</p>
                    <primaryComponents.Button
                      classNames={`btn btn--primary ${styles.printBtn}`}
                      isDisabled={
                        item?.status === "DEAD" ||
                        ((isPrinting || isPrintingSection) &&
                          selectedTerminal === item?.id)
                      }
                      isLoading={
                        (isPrintingSection || isPrinting) &&
                        selectedTerminal === item?.id
                      }
                      loadingText="Sending..."
                      onClick={() => {
                        if (sectionId)
                          sendSectionItemOReceipt(item?.id, item?.terminal_sn);
                        else sendOrderReceipt(item?.id, item?.terminal_sn);
                      }}
                    >
                      Print Receipt
                    </primaryComponents.Button>
                  </div>
                </div>
              ))}
            </>
          ) : (
            // show if terminals is empty
            <div className="text-center mt-5 mb-5">
              <img width="200px" src={Images.tnxEmptyState} alt="empty state" />
              <h4>No terminal available</h4>
            </div>
          )}
        </motion.div>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};
export default ReceiptTerminalsV2;
