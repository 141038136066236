import TableHelper from "../TableHelper";
import { useBulkTransferTable } from "./useBulkTransferTable";
import styles from "./BulkTransferTable.module.scss";
import { TransferStockEmptyStateIcon } from "../../../assets/svgIcons/TransferStockEmptyStateIcon";

const BulkTransferTable = ({
  transferData,
  setTransferData = () => {},
  validation,
  isReviewMode = false,
  autoScrollRef,
}) => {
  const { instance } = useBulkTransferTable({
    transferData,
    setTransferData,
    validation,
    isReviewMode,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.bulkRestockTable}>
      <TableHelper
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        handleScroll={true}
        autoScrollRef={autoScrollRef}
      >
        <div
          className="px-4 text-center mt-5 mb-5 mx-auto"
          style={{ maxWidth: "650px" }}
        >
          <TransferStockEmptyStateIcon />

          <p>Selected items will appear here</p>
        </div>
      </TableHelper>
    </div>
  );
};

export default BulkTransferTable;
