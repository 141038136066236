import React from "react";

export const AppConnectionIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#75CE9A" />
      <path
        d="M13.25 5.75H10.75C10.4048 5.75 10.125 6.02982 10.125 6.375V8.875C10.125 9.22018 10.4048 9.5 10.75 9.5H13.25C13.5952 9.5 13.875 9.22018 13.875 8.875V6.375C13.875 6.02982 13.5952 5.75 13.25 5.75Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15.75V13.25C7 12.9048 7.27982 12.625 7.625 12.625H16.375C16.7202 12.625 17 12.9048 17 13.25V15.75"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 18.25C7.69036 18.25 8.25 17.6904 8.25 17C8.25 16.3096 7.69036 15.75 7 15.75C6.30964 15.75 5.75 16.3096 5.75 17C5.75 17.6904 6.30964 18.25 7 18.25Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 18.25C17.6904 18.25 18.25 17.6904 18.25 17C18.25 16.3096 17.6904 15.75 17 15.75C16.3096 15.75 15.75 16.3096 15.75 17C15.75 17.6904 16.3096 18.25 17 18.25Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18.25C12.6904 18.25 13.25 17.6904 13.25 17C13.25 16.3096 12.6904 15.75 12 15.75C11.3096 15.75 10.75 16.3096 10.75 17C10.75 17.6904 11.3096 18.25 12 18.25Z"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 9.5V15.75"
        stroke="white"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
