import { useState, useRef, useContext } from "react";
import {
  platformHandler,
  RequestLoader,
  ToastContext,
} from "../../../../hooks/context";
import { addMultipleProducts } from "../../../../services/inventory";
import { useNavigate } from "react-router-dom";
import { getPlatform } from "../../../../helpers";

const AddMultipleProductsLogic = () => {
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const inputRef = useRef();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);

  const handleProductUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return triggerToast("Please select a file", "warning");

    if (event.target.files[0].size > 10000000) {
      return triggerToast("File size should be less than 10mb", "warning");
    }

    setIsUploadingFile(true);
    setRequestLoaderProgress(40);

    const formData = new FormData();
    formData.append("file", file);

    addMultipleProducts(formData)
      .then(() => {
        setIsUploadingFile(false);
        setRequestLoaderProgress(100);
        navigate(getPlatform(platform) + "/inventory/products");
        inputRef.current.value = null;
        triggerToast("File uploaded successfully", "success");
      })
      .catch(() => {
        setIsUploadingFile(false);
        setRequestLoaderProgress(100);
        inputRef.current.value = null;
      });
  };

  return {
    isUploadingFile,
    inputRef,
    handleProductUpload,
  };
};

export default AddMultipleProductsLogic;
