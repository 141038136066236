import { useState } from "react";
import { useGetRiders } from "../../../../../hooks/api/queries/useRiders";
import { useUrlSearch } from "../../../../../hooks/useUrlSearch";

const useAllRiders = () => {
  const {
    data,
    isLoading: isLoadingRiders,
    refetch: handleFetchRiders,
  } = useGetRiders();
  const [isRiderForm, setIsRiderForm] = useState(false);
  const { gotoPage } = useUrlSearch();

  return {
    isLoadingRiders,
    riders: data?.data || [],
    metaDetails: data?.meta || {},
    isRiderForm,
    setIsRiderForm,
    gotoPage,
    handleFetchRiders,
  };
};
export default useAllRiders;
