import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import styles from "./ExpenseAnalytics.module.scss";
import useExpenseAnalytics from "./useExpenseAnalytics";
import { formatCurrency } from "../../../../helpers";
import Images from "../../../../assets/images";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

const ExpenseAnalytics = () => {
  const { store_currency } = useStoreCurrency();

  const {
    startDate,
    endDate,
    storeList,
    currentStore,
    expenseAnalytics,
    isFetchingExpenseAnalytics,
    storeHasMultipleCurrency,
    setCurrentStore,
    filterByStoreId,
    isCreateExpense,
    setIsCreateExpense,
    setDateFilter,
    handleFetchExpenses,
  } = useExpenseAnalytics();

  const expenseHighLevelNos = expenseAnalytics?.expense_high_level_nos?.[0];
  const expenseBreakdown = expenseAnalytics?.expense_breakdown;

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Expense Analytics"
      description="View all expense analysis"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.expenseAnalytics} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Expense Analytics</h4>
            <p>Gain insights into your expenses with detailed analytics</p>
          </div>
          <div className={styles.expenseAnalytics__header}>
            <div style={{ width: "200px" }}>
              <primaryComponents.SelectField
                options={
                  storeHasMultipleCurrency
                    ? [...storeList]
                    : [
                        {
                          name: "All stores",
                          id: "ALL_STORES",
                        },
                        ...storeList,
                      ]
                }
                idKey="id"
                nameKey="name"
                onChange={(value) => {
                  setCurrentStore(value);
                  filterByStoreId(value);
                }}
                value={
                  storeList?.find(
                    (storeItem) => `${storeItem?.id}` === `${currentStore}`,
                  )?.name || "All stores"
                }
                floatError={false}
                classNames="store-select"
              />
            </div>
            <primaryComponents.DateRangeInput
              startDate={startDate}
              endDate={endDate}
              onChange={setDateFilter}
              showPlaceholder={true}
            />
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Expense"}
              permissionChildKey="can_create_expense"
            >
              <primaryComponents.Button
                classNames="btn btn--primary ml-auto"
                onClick={() => setIsCreateExpense(true)}
              >
                + Create Expenses
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          {/* expenses */}
          <div className={styles.expenseAnalytics__cards}>
            <div className={styles.expenseAnalytics__card}>
              <p>Total Expense Value</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingExpenseAnalytics}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: expenseHighLevelNos?.total_expense_value || 0,
                    currencyCode: store_currency,
                  })}
                </h5>
                <p className="small-text-size orange--text">
                  {expenseHighLevelNos?.total_expense_count} Expenses
                </p>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles.expenseAnalytics__card}>
              <p>Value of Approved Expenses</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingExpenseAnalytics}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: expenseHighLevelNos?.approved_expense_value || 0,
                    currencyCode: store_currency,
                  })}
                </h5>
                <p className="small-text-size orange--text">
                  {expenseHighLevelNos?.approved_expense_count} Expenses
                </p>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles.expenseAnalytics__card}>
              <p>Value of Pending Expenses</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingExpenseAnalytics}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: expenseHighLevelNos?.pending_expense_value || 0,
                    currencyCode: store_currency,
                  })}
                </h5>
                <p className="small-text-size orange--text">
                  {expenseHighLevelNos?.pending_expense_count} Expenses
                </p>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles.expenseAnalytics__card}>
              <p>Expense disbursed</p>
              <secondaryComponents.LoaderHelper
                classNames="mt-2"
                isLoading={isFetchingExpenseAnalytics}
              >
                <h5 className="mb-0">
                  {formatCurrency({
                    value: expenseHighLevelNos?.disbursed_expense_value || 0,
                    currencyCode: store_currency,
                  })}
                </h5>
                <p className="small-text-size orange--text">
                  {expenseHighLevelNos?.disbursed_expense_count} Expenses
                </p>
              </secondaryComponents.LoaderHelper>
            </div>
          </div>

          {/* expenses chart */}
          <div className={styles.expenseAnalytics__chart}>
            <h5>Expense Chart</h5>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingExpenseAnalytics}
              classNames="mt-4 mb-4"
            >
              <>
                {expenseBreakdown?.length > 0 ? (
                  <secondaryComponents.ExpenseAnalyticsChart
                    data={expenseBreakdown}
                  />
                ) : (
                  <div className="text-center">
                    <img
                      src={Images.expenseAnalyticsEmptyState}
                      width="300px"
                      alt=""
                    />
                    <h5>You Data Available</h5>
                  </div>
                )}
              </>
            </secondaryComponents.LoaderHelper>
          </div>

          <secondaryComponents.SideModal
            isActive={isCreateExpense}
            closeModal={() => setIsCreateExpense(false)}
          >
            <secondaryComponents.CreateExpense
              closeWidget={() => setIsCreateExpense(false)}
              handleFetchExpenses={handleFetchExpenses}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default ExpenseAnalytics;
