export const VariantIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1H1V3.66667H13V1Z"
        fill="white"
        stroke="#916CF1"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.66634 10.3335H4.33301V13.0002H9.66634V10.3335Z"
        fill="white"
        stroke="#C8B5F8"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6663 5.6665H2.33301V8.33317H11.6663V5.6665Z"
        fill="white"
        stroke="#AD91F5"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
