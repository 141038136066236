import { useState } from "react";
import { useGetProductCategoryList } from "../../../../hooks/api/queries/useInventory";

const ProductCategoryLogic = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoading, data: categories } = useGetProductCategoryList();

  return {
    isModalOpen,
    isLoading,
    categories: categories || [],
    setIsModalOpen,
  };
};

export default ProductCategoryLogic;
