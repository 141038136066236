/* eslint-disable react/jsx-key */
import secondaryComponents from "../..";
import { StoreWalletTransactionView } from "../../../printViews";
import useStoreWalletTransactionTable from "./useStoreWalletTransactionTable";

const StoreWalletTransactionTable = ({
  metaDetails,
  gotoPage,
  storeWalletTransactions,
}) => {
  const { instance, tnxId, setTnxId } = useStoreWalletTransactionTable({
    storeWalletTransactions,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <secondaryComponents.TableHelper
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <h4 className="pt-5 text-center">No Data Available</h4>
      </secondaryComponents.TableHelper>

      {storeWalletTransactions?.length > 0 ? (
        <div className="mt-auto">
          <secondaryComponents.Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        </div>
      ) : null}

      {tnxId ? (
        <StoreWalletTransactionView
          tnx_id={tnxId}
          allowPrint={tnxId}
          setAllowPrint={setTnxId}
        />
      ) : null}
    </>
  );
};

export default StoreWalletTransactionTable;
