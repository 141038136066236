import "./UpdateCreditWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateCreditWidgetLogic from "./UpdateCreditWidgetLogic";
import { formatCurrency } from "../../../helpers";
// import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import { Validation } from "./Validation";

const UpdateCreditWidget = ({ closeWidget, customer, onSuccess }) => {
  const {
    note,
    store,
    storeList,
    amount,
    isSubmitting,
    paymentMethod,
    bankAccount,
    isLoadingBankAccountsList,
    repaymentDate,
    setRepaymentDate,
    setStore,
    setAmount,
    setPaymentMethod,
    makeRepayment,
    setNote,
    setAccount,
  } = UpdateCreditWidgetLogic({ closeWidget, customer, onSuccess });

  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
    register,
  } = Validation({ customer });

  const [paymentType] = useState("partial");
  const { allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className="update-credit">
      <FontAwesomeIcon
        onClick={closeWidget}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px", cursor: "pointer" }}
      />
      <div className="update-credit__title">Payment</div>
      <div className="update-credit__subtitle">
        Record payments made by your debtors
      </div>

      <dl className="update-credit__data mt-4">
        <dt className="outstanding">Balance</dt>
        <dd
          className="outstanding"
          style={{
            color: customer?.outstanding_balance <= 0 ? "#19AE57" : "#E02020",
          }}
        >
          {formatCurrency({
            value:
              customer?.outstanding_balance <= 0
                ? Math.abs(customer?.outstanding_balance)
                : -Math.abs(customer?.outstanding_balance),
            currencyCode: customer?.store?.currency_code,
          })}
        </dd>

        <dt>Customer Name</dt>
        <dd>{customer?.full_name}</dd>

        <dt>Total Credit</dt>
        <dd>
          {formatCurrency({
            value: customer?.total_credit,
            currencyCode: customer?.store?.currency_code,
          })}
        </dd>

        <dt>Total Payment</dt>
        <dd>
          {formatCurrency({
            value: customer?.total_repayment,
            currencyCode: customer?.store?.currency_code,
          })}
        </dd>

        <dt>Status</dt>
        <dd>
          <span style={{ fontSize: "10px" }} className="success--squared ml-3">
            Active
          </span>
        </dd>
      </dl>

      <div className="update-credit__payment-form">
        <primaryComponents.InputFieldV2
          name="amount"
          control={control}
          label={"Amount"}
          isRequired={true}
          placeholder="Amount"
          value={amount}
          type="number"
          onChange={({ target }) => setAmount(target.value)}
          errorMessage={
            errors.amount && {
              message: errors.amount.message,
            }
          }
          isDisabled={paymentType === "partial" ? false : true}
          onKeyUp={() =>
            errors.amount !== undefined && trigger("accountNumber")
          }
          register={register}
        />

        <div className="py-3 px-3 mt-2 border rounded">
          <primaryComponents.SelectFieldV2
            options={storeList}
            control={control}
            name="customerStoreId"
            label={"Which store is the customer making the payment to?"}
            isRequired={true}
            nameKey="name"
            value={customer?.store_id ? store : "Select stores"}
            onChange={(value) => {
              setStore(value);
            }}
            idKey={"id"}
            classNames="store-select"
            register={register}
            errorMessage={errors.customerStoreId}
          />

          <primaryComponents.SelectFieldV2
            control={control}
            options={["transfer", "card", "cash"]}
            label={"Payment Method"}
            isRequired={true}
            value={
              customer?.payment_method ? paymentMethod : "Select Payment Type"
            }
            onChange={(value) => setPaymentMethod(value)}
            register={register}
            name="paymentMethod"
            nameKey="name"
            idKey="name"
            errorMessage={errors.paymentMethod}
          />

          <primaryComponents.DateInputV2
            name="repaymentDate"
            control={control}
            label={"Repayment Date"}
            placeholder="repaymentDate"
            value={repaymentDate}
            onChange={({ target }) => setRepaymentDate(target.value)}
            onKeyUp={() =>
              errors.amount !== undefined && trigger("repaymentDate")
            }
            register={register}
          />

          {allow_ledger_account ? (
            <div className="">
              <LoaderHelper
                isLoading={isLoadingBankAccountsList}
                className="mt-1"
              >
                <primaryComponents.SelectFieldV2
                  control={control}
                  options={bankAccount?.data?.map((bank) => ({
                    id: bank?.id,
                    name: bank?.account_name,
                  }))}
                  label={"Receive Payment Into"}
                  idKey="id"
                  nameKey="name"
                  value={
                    bankAccount
                      ? bankAccount?.data[0]?.account_name
                      : "Select Bank"
                  }
                  onChange={(value) => {
                    setAccount(value);
                  }}
                  floatError={false}
                  classNames="bank-name"
                  isSearchable={false}
                />{" "}
              </LoaderHelper>
            </div>
          ) : null}

          <div className="mt-3">
            <primaryComponents.TextAreaV2
              control={control}
              type="text"
              label={"Note"}
              name="note"
              placeholder="Add note for this repayment transaction"
              value={note}
              onChange={({ target: { value } }) => setNote(value)}
              register={register}
            />
          </div>
        </div>
      </div>

      <div className="py-5">
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isLoading={isSubmitting}
          loadingText="Updating..."
          isDisabled={isSubmitting}
          onClick={handleSubmit(makeRepayment)}
        >
          Update
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default UpdateCreditWidget;
