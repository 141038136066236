import styles from "./InventoryRevenueChart.module.scss";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";
import { useSelector } from "react-redux";

const InventoryRevenueChartTooltip = (props) => {
  const { active, payload, label, isMoney = true } = props;
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  if (active) {
    return (
      <div className={styles["inventory-revenue-chart-tooltip"]}>
        <div className={styles["inventory-revenue-chart-tooltip__value"]}>
          <span>
            {isMoney
              ? formatCurrency({
                  value: payload[0]?.value,
                  currencyCode: currency_code,
                })
              : payload[0]?.value}
          </span>
        </div>
        <p className={styles["inventory-revenue-chart-tooltip__label"]}>
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

export default InventoryRevenueChartTooltip;
