import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  getReconciliationsProducts,
  submitReconciledData,
  saveReconciledDraft,
  getReconciliationDiscrepancyReasons,
} from "../../../../../services/inventory";
import { useInView } from "react-intersection-observer";
import { useContext, useEffect, useState } from "react";
import { RequestLoader, ToastContext } from "../../../../../hooks/context";
import { cleanObject, getRandomIntInclusive } from "../../../../../helpers";
import { useSelector } from "react-redux";
import { useGetProductCategoryList } from "../../../../../hooks/api/queries/useInventory";

const useReconcileStocks = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const { ref: autoScrollRef, inView } = useInView();
  const [reconciliedData, setReconciliedData] = useState({});
  const [isReconciliationPrompt, setIsReconciliationPrompt] = useState(false);
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const queryClient = useQueryClient();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const storeName = storeList.find(
    (item) => `${item.id}` === `${searchParams.get("store_id")}`,
  )?.name;
  const [filters, setFilters] = useState({
    categories: [],
    searchQuery: "",
    id: id,
    isReview: false,
  });

  const { data: categoryList } = useGetProductCategoryList();
  const { isLoading, data, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteQuery(
      ["getReconciliationsProducts", filters],
      ({ pageParam }) =>
        getReconciliationsProducts(
          cleanObject({
            id: filters?.id,
            page: pageParam,
            query: filters?.searchQuery,
            category_ids: filters?.categories?.join("|"),
            isReview: filters?.isReview,
            exclude_on_demand_composite_products: true,
          }),
        ).then((res) => res?.data),
      {
        staleTime: Infinity,
        getNextPageParam: ({ meta: { next_page } }) =>
          next_page === null ? undefined : next_page,
      },
    );

  const { data: discrepancyReasons, isLoading: isLoadingDiscrepancy } =
    useQuery(
      ["getReconciliationDiscrepancyReasons"],
      () =>
        getReconciliationDiscrepancyReasons().then((res) => res?.data?.data),
      {
        staleTime: Infinity,
      },
    );

  const products = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    products.set(`${page?.meta?.current_page}`, page?.data);
  });
  const productsList = [].concat(...products?.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (Object.keys(reconciliedData)?.length > 0) saveDraft();
    }, 50000);

    return () => clearInterval(interval);
  });

  const { mutate: submitReconciled, isLoading: isSubmitting } = useMutation(
    (data) => submitReconciledData(data),
  );
  const submitReconciliation = () => {
    const data = [];
    for (const key in reconciliedData) data.push(reconciliedData[key]);
    submitReconciled(
      { data: { data: data }, id: id },
      {
        onSuccess: () => {
          setReconciliedData([]);
          triggerToast(
            "Reconciliation has been submitted for review",
            "success",
          );
          queryClient.invalidateQueries(["getReconciliationsProducts"]);
          navigate(-1);
        },
      },
    );
  };

  const { mutate: saveReconciled, isLoading: isSaving } = useMutation((data) =>
    saveReconciledDraft(data),
  );
  const saveDraft = (auto = true) => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    const data = [];
    for (const key in reconciliedData) data.push(reconciliedData[key]);
    saveReconciled(
      { data: { data: data }, id: id },
      {
        onSuccess: () => {
          setReconciliedData([]);
          if (!auto) {
            triggerToast("Saved!", "success");
            queryClient.invalidateQueries(["getReconciliationsProducts"]);
          }
        },
        onError: (error) => {
          if (error?.response?.status === 400 && !auto)
            triggerToast(error?.response?.data?.detail, "warning");
        },
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  return {
    storeName,
    discrepancyReasons: discrepancyReasons || [],
    isLoadingDiscrepancy,
    isSaving,
    isSubmitting,
    products: productsList || [],
    isLoading,
    data,
    isFetchingNextPage,
    hasNextPage,
    inView,
    reconciliedData,
    isReconciliationPrompt,
    categoryList: categoryList || [],
    filters,
    searchParams,
    setFilters,
    autoScrollRef,
    fetchNextPage,
    submitReconciliation,
    setReconciliedData,
    saveDraft,
    setIsReconciliationPrompt,
  };
};
export default useReconcileStocks;
