import styles from "./CampaignForm.module.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import { motion } from "framer-motion";
import useCampaignForm from "./useCampaignForm";
import { Controller } from "react-hook-form";
import { useValidation } from "./useValidation";
import { useEffect } from "react";

const CampaignForm = ({ campaign, isEditMode = false }) => {
  const {
    showImageModal,
    campaignDetails,
    isLoading,
    setCampaignDetails,
    setShowImageModal,
    handleCreateOrUpdateCampaign,
  } = useCampaignForm({ campaign, isEditMode });

  const {
    control,
    formState: { errors },
    trigger,
    handleSubmit,
    setValue,
    clearErrors,
  } = useValidation({
    campaignDetails,
  });

  useEffect(() => {
    if (
      !campaignDetails?.brand_logo_url ||
      campaignDetails?.brand_logo_url === null
    )
      return;
    clearErrors("brand_logo_url");
    setValue("brand_logo_url", campaignDetails?.brand_logo_url);
  }, [campaignDetails?.brand_logo_url]);
  return (
    <>
      <motion.form
        onSubmit={handleSubmit(handleCreateOrUpdateCampaign)}
        className={styles.editCampaignForm}
        initial={{ marginTop: "50px" }}
        animate={{ marginTop: "0px", transition: { duration: 0.3 } }}
      >
        <div className={styles.editCampaignForm__group}>
          {/* cmapiagn name */}
          <primaryComponents.InputFieldV2
            name="campaign_name"
            label="Campaign Name"
            type="text"
            control={control}
            isRequired={true}
            placeholder="Enter your chosen campaign name"
            value={campaignDetails?.campaign_name}
            classNames="white"
            errorMessage={errors.campaign_name}
            onChange={({ target: { value } }) => {
              setCampaignDetails((prev) => ({ ...prev, campaign_name: value }));
            }}
            onKeyUp={() =>
              errors.campaign_name !== undefined && trigger("campaign_name")
            }
          />
          {/* brand name */}
          <primaryComponents.InputFieldV2
            name="brand_name"
            label="Brand Name"
            type="text"
            control={control}
            isRequired={true}
            placeholder="Enter your brand name"
            value={campaignDetails?.brand_name}
            classNames="white"
            errorMessage={errors.brand_name}
            onChange={({ target: { value } }) => {
              setCampaignDetails((prev) => ({ ...prev, brand_name: value }));
            }}
            onKeyUp={() =>
              errors.brand_name !== undefined && trigger("brand_name")
            }
          />
        </div>
        <div className={styles.editCampaignForm__group}>
          {/* brand descrpition */}
          <primaryComponents.TextAreaV2
            control={control}
            label="Brand Description"
            name="description"
            isRequired={false}
            type="text"
            placeholder="Enter your brand description"
            value={campaignDetails?.description}
            classNames="white"
            errorMessage={errors.description}
            onChange={({ target: { value } }) => {
              setCampaignDetails((prev) => ({
                ...prev,
                description: value,
              }));
            }}
            onKeyUp={() =>
              errors.description !== undefined && trigger("description")
            }
          />
          <div className="justify-content-end aligns-right">
            <label className="default-font-size mb-1" htmlFor="brandName">
              Brand Logo &nbsp;
              <span className="gray--text small-text-size">
                Click below to upload
              </span>
            </label>
            <Controller
              control={control}
              defaultValue={campaignDetails?.brand_logo_url}
              name="brand_logo_url"
              render={() => (
                <primaryComponents.ImageInputField
                  setShowImageModal={setShowImageModal}
                  errorMessage={errors.brand_logo_url}
                  imgValue={campaignDetails?.brand_logo_url}
                  altValue="brand logo"
                />
              )}
            />
          </div>
        </div>
        <div className={styles.editCampaignForm__group}>
          <div>
            <Controller
              name="business_slug"
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.SlugInputField
                  label={"Loyalty Portal URL"}
                  name="business_slug"
                  type="text"
                  placeholder="your-business-name"
                  isRequired={true}
                  isDisabled={isEditMode}
                  value={campaignDetails?.business_slug}
                  errorMessage={errors.business_slug}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setCampaignDetails((prev) => ({
                      ...prev,
                      business_slug: value,
                    }));
                  }}
                  onKeyUp={() =>
                    errors.business_slug !== undefined &&
                    trigger("business_slug")
                  }
                />
              )}
            />
            <p
              className="gray--text small-text-size"
              style={{ maxWidth: "90%" }}
            >
              <span className="error--text">Tip: </span>Set up your store’s
              unique URL where customers can check their points, redeem rewards,
              or purchase gift cards.
            </p>
          </div>

          <div></div>
        </div>
        <div className={"py-4 px-3 border rounded"}>
          <div>
            <div className="d-flex justify-content-between flex-wrap">
              <div className="w-50">
                <h4>Reward Percentage</h4>
                <p className="gray--text default-font-size">
                  Enter the % reward you want to offer on every purchase. For
                  example, if you set a 5% reward, a customer spending $100 in
                  store A will earn $5 in rewards in that store.
                </p>
              </div>
            </div>
            <div className={styles.editCampaignForm__store}>
              {campaignDetails?.offers?.map((offer, index) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "15px",
                    // width: "fit-content",
                  }}
                  key={offer.id}
                >
                  <label
                    className="d-flex align-items-center"
                    htmlFor={offer?.name}
                  >
                    <primaryComponents.Checkbox
                      name={"offer"}
                      id={offer?.name}
                      isChecked={offer?.isDisabled === false}
                      onChange={(value) => {
                        let _offers = [...campaignDetails?.offers];
                        _offers[index].isDisabled = !value;
                        setCampaignDetails((prev) => ({
                          ...prev,
                          offers: _offers,
                        }));
                      }}
                    />
                    <p className="ml-2 dark--text">{offer?.name}</p>
                  </label>
                  <div style={{ maxWidth: "277px" }}>
                    <Controller
                      name={`offers[${campaignDetails?.offers?.[index]}].reward_per_transaction`}
                      control={control}
                      render={({ field: { onChange } }) => {
                        return (
                          <primaryComponents.PercentageInputField
                            isDisabled={offer?.isDisabled}
                            placeholder="Enter Percentage"
                            name={`offers[${campaignDetails?.offers[index]}].reward_per_transaction`}
                            value={
                              campaignDetails?.offers?.[index]
                                ?.reward_per_transaction
                            }
                            onChange={({ target: { value } }) => {
                              onChange(value);
                              let _offers = [...campaignDetails?.offers];
                              _offers[index].reward_per_transaction = value;
                              setCampaignDetails((prev) => ({
                                ...prev,
                                offers: _offers,
                              }));
                            }}
                            type="number"
                            step={0.01}
                            floatError={false}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <primaryComponents.Button
            classNames="btn btn--primary ml-auto"
            type="submit"
            isLoading={isLoading}
            isDisabled={isLoading}
            loadingText={isEditMode ? "Updating..." : "Creating..."}
          >
            {isEditMode ? "Update" : "Submit"}
          </primaryComponents.Button>
        </div>
      </motion.form>
      {/* image modal */}

      <secondaryComponents.Modal
        width={1000}
        isActive={showImageModal}
        closeModal={() => setShowImageModal(false)}
      >
        <secondaryComponents.ImageUploadModal
          closeModal={() => setShowImageModal(false)}
          setImage={(val) =>
            setCampaignDetails((prev) => ({
              ...prev,
              brand_logo_url: val,
            }))
          }
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default CampaignForm;
