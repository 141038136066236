import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SlugInputField.scss";

const SlugInputField = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  value = "",
  register = () => {},
  onKeyUp = () => {},
  ...props
}) => {
  return (
    <div className="slug-input-field">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`slug-input-field__input-section ${
          errorMessage !== undefined && "error-border"
        } ${isDisabled && "disabled"}`}
      >
        <input
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={isDisabled}
          value={value}
          {...register(name, { required: isRequired })}
          onChange={onChange}
          onKeyUp={onKeyUp}
          {...props}
        />
        <span>
          {value}.
          {process.env.REACT_APP_CAMPAIGN_BASE_URL || "lumirewards.center"}
        </span>
      </div>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default SlugInputField;
