/* eslint-disable react/jsx-key */
import "./ExpensesTable.scss";
import secondaryComponents from "..";
import useExpensesTable from "./useExpensesTable";
import Images from "../../../assets/images";

const ExpensesTable = ({
  metaDetails,
  expenses,
  handleFetchExpenses,
  gotoPage,
}) => {
  const {
    instance,
    isReviewExpense,
    expenseDetails,
    isFetchingExpenseDetails,
    setIsReviewExpense,
  } = useExpensesTable({
    expenses,
    handleFetchExpenses,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="expenses-table">
      <div className="expenses-table__content">
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.expense2EmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no expense
            </h4>
            <p className="text-center">
              Use the button above to create an expense. When you do, they would
              appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>

        {expenses?.length > 0 && (
          <div className="mt-auto">
            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </div>
        )}
      </div>

      <secondaryComponents.SideModal
        isActive={isReviewExpense && !isFetchingExpenseDetails}
        closeModal={() => setIsReviewExpense(false)}
      >
        <secondaryComponents.ExpenseReview
          closeWidget={() => setIsReviewExpense(false)}
          expense={expenseDetails}
          handleFetchExpenses={handleFetchExpenses}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default ExpensesTable;
