import React from "react";
import primaryComponents from "../../primaryComponents";
import styles from "./Loyalty.module.scss";
import Icon from "../../../assets/icons";
import { pathConstants } from "../../../routes/pathContants";

const CampaignEmpty = () => {
  return (
    <div className={styles.empty_container}>
      <h4 className={styles.heading}>Apply Loyalty</h4>
      <div className={styles.icon_container}>
        <img src={Icon.campaign} alt="" />
        <h5 className={styles.campaign_text}>You have no existing campaign</h5>
      </div>
      <primaryComponents.Button
        classNames={"btn btn--primary"}
        onClick={() => {
          window.open(pathConstants.LOYALTY_CREATE_CAMPAIGN, "_blank");
        }}
      >
        Create Your First Campaign!
      </primaryComponents.Button>
    </div>
  );
};

export default CampaignEmpty;
