import Images from "../assets/images";
import { LumiPaystackIcon, VfdIcon } from "../assets/svgIcons";
const appsData = [
  {
    id: "optimus",
    imageSrc: Images.optimus,
    altText: "Optimus Bank MFB",
    appName: "Optimus Bank MFB",
    route:
      "https://app.lumibusiness.io/app/inventory/storefront/payment-method",
    short_description:
      "Open current account & accept card and transfer payments with Optimus Bank POS terminal.",
    status: "Coming Soon",
    videoUrl: "",
    url: "https://www.optimusbank.com",
    full_description:
      "Optimus Bank Microfinance is committed to supporting small and medium-sized enterprises by offering tailored banking solutions. With this upcoming integration, you’ll be able to open a current account and utilize their POS terminals to accept card and transfer payments seamlessly. Optimus Bank aims to simplify business banking, providing tools that help you manage your finances effectively.",
    key_features: [
      "Easy account opening process.",
      "Accept payments via POS terminals for cards and transfers.",
      "Secure payment processing with real-time tracking.",
      "Financial services designed for business growth.",
      "Reliable customer support.",
    ],
    getting_started: [
      {
        step: "Coming Soon",
        description:
          "This feature is coming soon. Stay tuned for updates on its availability and setup instructions.",
      },
    ],
    app_information: {
      developer: "Optimus Bank MFB",
      category: "Financial Services",
      pricing_information: "Available upon request",
    },
    support: {
      user_guide: "User Guide",
      email: "support@optimusbank.com",
    },
    legal: {
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy",
    },
  },
  {
    id: "paystackPOS",
    imageSrc: <LumiPaystackIcon />,
    altText: "Lumi Virtual Terminal powered by Paystack",
    appName: "Lumi Virtual Terminal powered by Paystack",
    videoUrl: "",
    route:
      "https://app.lumibusiness.io/app/inventory/storefront/payment-method",
    url: "https://www.paystack.com",
    short_description:
      "Process offline payments with Lumi- Paystack powered virtual terminals",
    status: "Coming Soon",
    full_description:
      "Lumi Paystack’s virtual wallet will enable you to accept payments in offline environments. Ideal for businesses that operate in areas with unstable internet, this feature will allow you to process card payments securely even when not connected to the internet. Once connected again, transactions will sync and be updated in real-time. This service will help you manage offline sales efficiently while ensuring all payments are securely processed.",
    key_features: [
      "Offline payment processing.",
      "Secure transactions, even offline.",
      "Automatic sync once connected to the internet.",
      "Simple setup with Lumi storefront.",
      "Real-time updates and transaction history.",
    ],
    getting_started: [
      {
        step: "Feature Coming Soon",
        description:
          "This feature is coming soon. Keep an eye on your Lumi dashboard for updates.",
      },
    ],
    app_information: {
      developer: "Paystack",
      category: "Payment Gateway",
      pricing_information: "Coming soon",
    },
    support: {
      user_guide: "User Guide",
      email: "support@paystack.com",
    },
    legal: {
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy",
    },
  },
  {
    id: "kuda",
    imageSrc: Images.kuda,
    altText: "KUDA Microfinance Bank",
    appName: "KUDA Microfinance Bank",
    route:
      "https://app.lumibusiness.io/app/inventory/storefront/payment-method",
    videoUrl: "",
    url: "https://kuda.com",
    short_description:
      "Open current account & accept card and transfer payments with KUDA POS terminal.",
    status: "Coming Soon",
    full_description:
      "KUDA Microfinance Bank is a digital-first bank offering simple and accessible banking solutions for businesses. Soon, you’ll be able to integrate KUDA with your Lumi storefront, allowing you to open a business current account and accept payments through KUDA’s POS terminal. This integration will streamline your in-store payment processes and offer real-time transaction monitoring.",
    key_features: [
      "Digital banking with easy account setup.",
      "Accept card and bank transfer payments via KUDA POS.",
      "Transparent banking with no hidden fees.",
      "Real-time transaction notifications.",
      "Mobile app for on-the-go banking.",
    ],
    getting_started: [
      {
        step: "Feature Coming Soon",
        description:
          "This feature is coming soon. Please check back later for detailed setup instructions.",
      },
    ],
    app_information: {
      developer: "KUDA Bank",
      category: "Digital Banking",
      pricing_information:
        "Free account setup, fees for POS transactions available upon request",
    },
    support: {
      user_guide: "User Guide",
      email: "help@kuda.com",
    },
    legal: {
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy",
    },
  },
  {
    id: "vfd",
    imageSrc: <VfdIcon />,
    altText: "Lumi Merchant Account powered by VFD",
    appName: "Lumi Merchant Account powered by VFD",
    route:
      "https://app.lumibusiness.io/app/inventory/storefront/payment-method",
    short_description:
      "Open a Lumi -VFD powered current account & accept card and transfer payments with our payment POS terminal.",
    status: "Connect",
    videoUrl: "",
    url: "https://vbank.ng",
    full_description:
      "VFD Microfinance Bank offers businesses an easy way to open a current account and accept payments through their POS terminal. The integration allows you to manage in-store transactions, accepting both card payments and bank transfers with ease. VFD Microfinance Bank provides a robust platform designed to support business growth, offering financial products tailored to small and medium enterprises.",
    key_features: [
      "Open a business current account quickly and easily.",
      "Accept card and bank transfer payments via VFD POS terminals.",
      "Access financial services tailored for businesses.",
      "Real-time transaction tracking and management.",
      "Dedicated customer support.",
    ],
    getting_started: [
      {
        step: "Verify Your Business:",
        description:
          "You will be required to submit KYB documents. Click here to verify your business on Lumi Business.",
      },
      {
        step: "Account Creation:",
        description:
          "A current account will be automatically created for you as soon as you submit your KYB documents. You can also create more sub-accounts using this link: Lumi",
      },
      {
        step: "Set Up POS Terminal:",
        description:
          "You can request for VFD MFM POS terminal from your Lumi business dashboard using this link: POS",
      },
      {
        step: "Start Accepting Payments:",
        description:
          "Once set up, your POS terminal will be delivered to your business address and you can begin accepting card and bank transfer payments.",
      },
    ],
    app_information: {
      developer: "VFD Microfinance Bank",
      category: "Financial Services",
      pricing_information: "No setup fee, 0.5% capped at N150 per transaction",
    },
    support: {
      user_guide: "User Guide",
      email: "contact@vfdbank.com",
    },
    legal: {
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy",
    },
  },
  {
    id: "paystack",
    imageSrc: Images.paystack,
    altText: "Paystack Payments Gateway",
    appName: "Paystack Payments Gateway",
    short_description:
      "Accept payments on Lumi Business storefront with Paystack.",
    full_description:
      "Integrate Paystack with your Lumi storefront to accept payments via cards, bank transfers, and mobile money. This secure and reliable payment gateway, trusted by businesses across Africa, helps you manage transactions efficiently and offers real-time updates on payments.",
    status: "Connect",
    videoUrl: "SwiclYYKHBQ",
    url: "https://www.paystack.com",
    route:
      "https://app.lumibusiness.io/app/inventory/storefront/payment-method",
    key_features: [
      "Accept multiple payment methods (cards, bank transfers, mobile money).",
      "Secure payment processing with PCI DSS Level 1 Compliance.",
      "Real-time transaction alerts and analytics.",
      "Easy setup with Lumi storefront.",
      "User-friendly dashboard for transaction management.",
    ],
    getting_started: [
      {
        step: "Create an Account",
        description:
          "Visit Paystack.com to create a new account or log in to your existing account.",
      },
      {
        step: "Access Your Dashboard",
        description: "After logging in, go to your Paystack dashboard.",
      },
      {
        step: "Locate Your Secret Key",
        description:
          "Navigate to the Settings section on your dashboard. Under API Keys & Webhooks, you'll find your secret key. Copy your secret key.",
      },
      {
        step: "Integrate with Lumi",
        description:
          "In your Lumi dashboard, go to the 'Integrations' tab. Select Paystack and click 'Connect'. Paste your secret key in the designated field on Lumi.",
      },
      {
        step: "Set Up Webhooks",
        description:
          "Generate your webhook URL from the Lumi platform. Copy and paste this URL into the appropriate section on your Paystack dashboard under API Keys & Webhooks.",
      },
      {
        step: "Don’t Have a Paystack Secret Key?",
        description:
          "Visit Paystack.com to get started. Alternatively, watch this short explainer video to guide you through the process.",
      },
    ],
    app_information: {
      developer: "Paystack",
      category: "Payment Gateway",
      pricing_information:
        "No setup fee, 1.5% + ₦100 per transaction (local), 3.9% + ₦100 (international)",
    },
    support: {
      user_guide: "User Guide",
      email: "support@paystack.com",
    },
    legal: {
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy",
    },
  },
  {
    id: "stripe",
    imageSrc: Images.stripe,
    altText: "Stripe Payments Gateway",
    appName: "Stripe Payments Gateway",
    route:
      "https://app.lumibusiness.io/app/inventory/storefront/payment-method",
    videoUrl: "4fogq7gsBmE",
    url: "https://www.stripe.com",
    short_description:
      "Accept international payments on Lumi Business storefront with Stripe.",
    status: "Connect",
    full_description:
      "Stripe allows you to accept payments from customers worldwide. It’s the go-to choice for businesses looking to expand globally, offering support for multiple currencies and payment methods, all secured by Stripe’s advanced security features and compliance standards.",
    key_features: [
      "Accept payments in over 135 currencies.",
      "Secure payment processing with PCI DSS Level 1 Compliance.",
      "Customizable checkout experience.",
      "Support for recurring billing and subscriptions.",
      "Comprehensive analytics and reporting tools.",
    ],
    getting_started: [
      {
        step: "Video URL",
        description: "Lumi Merchant - Stripe Setup",
      },
      {
        step: "Create an Account",
        description:
          "Visit Stripe.com to create a new account or log in to your existing account",
      },
      {
        step: "Access Your Dashboard",
        description: "After logging in, go to your Stripe dashboard.",
      },
      {
        step: "Locate Your Secret Key",
        description:
          "Navigate to the Developers section on your dashboard. Under the API Keys section, you will find your secret key. Copy your secret key.",
      },
      {
        step: "Integrate with Lumi",
        description:
          "In your Lumi dashboard, go to the 'Integrations' tab. Select Stripe and click 'Connect'. Paste your secret key in the designated field on Lumi.",
      },
      {
        step: "Set Up Webhooks",
        description:
          "Generate your webhook URL from the Lumi platform. Copy and paste this URL into the appropriate section on your Stripe dashboard under Webhooks. Select the necessary events to be sent to your webhook.",
      },
      {
        step: "Don’t Have a Stripe Secret Key?",
        description:
          "Visit Stripe.com to get started. You can also check Stripe’s documentation for detailed setup instructions.",
      },
    ],
    app_information: {
      developer: "Stripe",
      category: "Payment Gateway",
      pricing_information: "2.9% + 30¢ per successful card charge",
    },

    support: {
      user_guide: "User Guide",
      email: "support@stripe.com",
    },
    legal: {
      terms_of_service: "Terms of Service",
      privacy_policy: "Privacy Policy",
    },
  },
];

const keywordMap = {
  "Paystack.com": "https://www.paystack.com",
  "Kuda.com": "https://help.kuda.com",
  "support@paystack.com": "mailto:support@paystack.com",
  "https://www.paystack.com/privacy": "https://www.paystack.com/privacy",
  "https://www.paystack.com/terms": "https://www.paystack.com/terms",
  "Stripe.com": "https://www.stripe.com",
  "Vfdbank.com": "https://vbank.ng",
  "Optimusbank.com": "https://www.optimusbank.com/",
  Lumi: "https://app.lumibusiness.io/app/payments/sub-accounts",
  POS: "https://app.lumibusiness.io/app/payments/pos/add",
};

function replaceKeywordsWithLinks(text) {
  const pattern = new RegExp(Object.keys(keywordMap).join("|"), "g");
  return text.replace(pattern, function (matched) {
    return `<a href="${keywordMap[matched]}" target="_blank" rel="noopener noreferrer">${matched}</a>`;
  });
}

appsData.forEach((app) => {
  if (typeof app.description === "string") {
    app.description = replaceKeywordsWithLinks(app.description);
  }

  if (Array.isArray(app.getting_started)) {
    app.getting_started = app.getting_started.map((step) => ({
      ...step,
      description: replaceKeywordsWithLinks(step.description),
    }));
  }
});

export default appsData;
