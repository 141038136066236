import { useState } from "react";
import {
  useGetCountries,
  useGetCountryDetails,
} from "../../../../../hooks/api/queries/useBusiness";

const useCreateStore = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { data: countries, isLoading: isLoadingCountries } = useGetCountries();
  const { data: states, isLoading: isLoadingStates } =
    useGetCountryDetails(selectedCountry);

  return {
    isLoadingCountries,
    isLoadingStates,
    states: states?.states,
    countries,
    setSelectedCountry,
  };
};

export default useCreateStore;
