import useSupplierDeletion from "./useSupplierDeletion";
import primaryComponents from "../../primaryComponents";

const SupplierDeletion = ({
  supplierDetails,
  closeWidget,
  handleFetchSuppliers,
}) => {
  const { isDeletingSupplier, handleDeleteSupplier } = useSupplierDeletion({
    supplierDetails,
    closeWidget,
    handleFetchSuppliers,
  });

  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        Are you sure you want to delete this supplier
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="dark--text font-weight-semibold mb-1">
            Supplier Name
          </span>
          <span>{supplierDetails?.name || "N/A"}</span>
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={closeWidget}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={handleDeleteSupplier}
          classNames={"btn btn--danger cover"}
          isLoading={isDeletingSupplier}
          isDisabled={isDeletingSupplier}
          loadingText="Deleting..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default SupplierDeletion;
