import { usePDF } from "@react-pdf/renderer";
import { useEffect } from "react";

export const MobilePrint = ({ itemDetails, clearAfterPrint, mobileDoc }) => {
  const [instance] = usePDF({
    document: mobileDoc,
  });

  useEffect(() => {
    if (itemDetails && !instance?.loading)
      handlePrintOnMobile({ url: instance?.url });
  }, [itemDetails, instance?.loading]);

  const handlePrintOnMobile = ({ url }) => {
    window.open(url);
    clearAfterPrint();
  };

  return <></>;
};
