import styles from "./styles.module.scss";
import primaryComponents from "../../../../../../../../../components/primaryComponents";
import { useCheckout } from "./useCheckout";
import { useOutletContext } from "react-router-dom";

const Checkout = () => {
  const { menuProperties } = useOutletContext();
  const {
    isLoadingActivate,
    isLoadingActivateInstoreCheckout,
    isLoadingActivateDeliveryCheckout,
    mutateActivate,
    mutateActivateInstoreCheckout,
    mutateActivateDeliveryCheckout,
  } = useCheckout();

  return (
    <div className={styles.container}>
      <div className={styles.itemContainer}>
        <div>
          <h4>Online Checkout</h4>
          <p>
            Give your customers a fast order experience by allowing them add
            your storefront items to cart and checkout.
          </p>
        </div>
        <primaryComponents.Button
          classNames={`btn ${
            menuProperties?.allow_payment ? "btn--outline-red" : "btn--success"
          }`}
          onClick={() => mutateActivate()}
          loadingText={
            menuProperties?.allow_payment ? "De-activating..." : "Activating..."
          }
          isLoading={isLoadingActivate}
          isDisabled={isLoadingActivate}
          showSpinner={false}
        >
          <span>
            {menuProperties?.allow_payment ? "De-activate" : "Activate"}
          </span>
        </primaryComponents.Button>
      </div>
      <div className={styles.divider} />
      <div
        style={{ opacity: menuProperties?.allow_payment ? 1 : 0.4 }}
        className={styles.itemContainer}
      >
        <div>
          <h4>In-store Checkout</h4>
          <p>
            Give your customers the option to pick up orders at your store or
            dine-in.
          </p>
        </div>
        <primaryComponents.Button
          classNames={`btn ${
            menuProperties?.allow_in_store_checkout
              ? "btn--outline-red"
              : "btn--success"
          }`}
          onClick={() => mutateActivateInstoreCheckout()}
          loadingText={
            menuProperties?.allow_in_store_checkout
              ? "De-activating..."
              : "Activating..."
          }
          isLoading={isLoadingActivateInstoreCheckout}
          isDisabled={
            isLoadingActivateInstoreCheckout || !menuProperties?.allow_payment
          }
          showSpinner={false}
        >
          <span>
            {menuProperties?.allow_in_store_checkout
              ? "De-activate"
              : "Activate"}
          </span>
        </primaryComponents.Button>
      </div>
      <div className={styles.divider} />
      <div
        style={{ opacity: menuProperties?.allow_payment ? 1 : 0.4 }}
        className={styles.itemContainer}
      >
        <div>
          <h4>Delivery Checkout</h4>
          <p>
            Give your customers the option to have their orders at their
            doorsteps.
          </p>
        </div>
        <primaryComponents.Button
          classNames={`btn ${
            menuProperties?.allow_delivery ? "btn--outline-red" : "btn--success"
          }`}
          onClick={() => mutateActivateDeliveryCheckout()}
          loadingText={
            menuProperties?.allow_delivery
              ? "De-activating..."
              : "Activating..."
          }
          isLoading={isLoadingActivateDeliveryCheckout}
          isDisabled={
            isLoadingActivateDeliveryCheckout || !menuProperties?.allow_payment
          }
          showSpinner={false}
        >
          <span>
            {menuProperties?.allow_delivery ? "De-activate" : "Activate"}
          </span>
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default Checkout;
