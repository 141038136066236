import { useInfiniteQuery, useQuery } from "react-query";
import {
  getOrderDetails,
  getOrderList,
  getRiderOrderDetails,
  getRiderOrders,
} from "../../../services/inventory";
import { useInView } from "react-intersection-observer";
import { cleanObject } from "../../../helpers";
import { useEffect } from "react";

export const useGetOrderDetails = ({ id, isEnabled = true }) => {
  return useQuery(
    ["orderDetails", id],
    () => getOrderDetails(id).then((res) => res?.data?.data),
    { enabled: isEnabled && id ? true : false, staleTime: Infinity },
  );
};

export const useGetRiderOrders = ({ queries = "" }) => {
  const { ref: autoScrollRef, inView } = useInView();
  const riderOrdersQueries = useInfiniteQuery(
    ["riderOrders", queries],
    ({ pageParam }) =>
      getRiderOrders(
        cleanObject({
          page: pageParam,
          queries,
        }),
      ).then((res) => res?.data),
    {
      enabled: queries !== "" ? true : false,
      staleTime: Infinity,
      getNextPageParam: ({ meta: { next_page } }) =>
        next_page === null ? undefined : next_page,
    },
  );
  const { data, hasNextPage, fetchNextPage } = riderOrdersQueries;
  const riderOrders = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    riderOrders.set(`${page.meta.current_page}`, page?.data);
  });
  const riderOrdersList = [].concat(...riderOrders.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  return {
    ...riderOrdersQueries,
    autoScrollRef,
    data: riderOrdersList,
  };
};

export const useGetStorefrontOrderDetails = ({ id }) => {
  return useQuery(
    ["storefrontOrderDetails", id],
    () => getRiderOrderDetails(id).then((res) => res?.data?.data),
    { staleTime: Infinity },
  );
};

export const useGetOrderList = ({
  queries = "",
  queryParams = {},
  isEnabled = true,
}) => {
  return useQuery(
    ["getOrderList", queries, queryParams],
    () => getOrderList(queries, queryParams).then((res) => res?.data),
    { staleTime: Infinity, enabled: isEnabled },
  );
};
