import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const biodataSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .max(40, "Maximum of 30 characters is allowed"),

    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Phone number is required"),

    firstName: yup
      .string()
      .required("First name is required")
      .min(3, "Minimum of 2 characters is allowed")
      .max(30, "Maximum of 30 characters is allowed"),

    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Minimum of 2 characters is allowed")
      .max(30, "Maximum of 30 characters is allowed"),
  })
  .required();

const BiodataFormValidation = (email, firstName, lastName, phoneNumber) => {
  return useForm({
    resolver: yupResolver(biodataSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
    },
  });
};

export default BiodataFormValidation;
