import { useState, useContext } from "react";
import { ToastContext } from "../../../../hooks/context";
import { useMutation, useQueryClient } from "react-query";
import {
  generateWaybill,
  validateWaybillItems,
} from "../../../../services/sales-point";
import { useSelector } from "react-redux";
import useOrderflows from "../Orderflows/useOrderflows";

export const useGenerateWaybill = ({ orderId }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { orderDetails } = useOrderflows({
    orderId,
  });

  const [waybillItems, setWaybillItems] = useState(
    () =>
      orderDetails?.order_items.map((order) => ({
        ...order,
        isDisabled: true,
      })) || [],
  );
  const [customerDetails, setCustomerDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [customerAddress, setCustomerAddress] = useState("");
  const [driverName, setDriverName] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverPhoneNumber, setDriverPhoneNumber] = useState("");
  const [driverPhoneCode, setDriverPhoneCode] = useState("");
  const [logisticsCompany, setLogisticsCompany] = useState("");
  // const [waybillNumber, setWaybillNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("waybill_items");
  const queryClient = useQueryClient();

  const order_id = orderId;
  const hasSelectedAnItem =
    waybillItems.filter((item) => item?.isDisabled === false).length > 0;

  const triggerToast = useContext(ToastContext);
  const generateWaybillMutation = useMutation({
    mutationFn: (data) => generateWaybill(order_id, data),
    onSuccess: (response) => {
      const data = response.data?.data;
      const waybill_url = data?.waybill_url;
      triggerToast("Waybill Generated Successfully", "success");
      window.open(waybill_url, "_blank");
      queryClient.invalidateQueries(["orderDetails"]);
    },
  });

  const validateWaybillItemsMutation = useMutation({
    mutationFn: (data) => validateWaybillItems(order_id, data),
    onSuccess: (response) => {
      const message = response.data?.detail;
      triggerToast(message, "success");
      setCurrentStep("waybill_info");
    },
  });

  const handleGenerateWaybill = () => {
    const payload = {
      waybill_items:
        waybillItems
          .filter((item) => item?.isDisabled === false)
          .map((item) => ({ item_id: item?.id, quantity: item?.quantity })) ||
        [],
      customer: {
        customer_name: customerDetails?.customerName,
        customer_phone_number: customerDetails?.customerPhoneNumber,
        customer_phone_code:
          customerDetails?.customerPhoneCode || business_phone_code,
        customer_email: customerDetails?.customerEmail,
        customer_address: customerAddress,
      },
      logistics: {
        driver_name: driverName,
        driver_vehicle_number: vehicleNumber,
        driver_phone_number: driverPhoneNumber,
        driver_phone_code: driverPhoneCode || business_phone_code,
        company: logisticsCompany,
      },
    };

    generateWaybillMutation.mutate(payload);
  };

  const handleValidateWaybillItems = () => {
    const payload = {
      waybill_items:
        waybillItems
          .filter((item) => item?.isDisabled === false)
          .map((item) => ({ item_id: item?.id, quantity: item?.quantity })) ||
        [],
    };

    return validateWaybillItemsMutation.mutate(payload);
  };

  return {
    customerAddress,
    driverName,
    vehicleNumber,
    driverPhoneNumber,
    logisticsCompany,
    currentStep,
    waybillItems,
    isGeneratingWaybill: generateWaybillMutation.isLoading,
    isValidatingWaybillItems: validateWaybillItemsMutation.isLoading,
    hasSelectedAnItem,
    customerDetails,
    setCustomerDetails,
    setWaybillItems,
    setCurrentStep,
    setDriverName,
    setVehicleNumber,
    setDriverPhoneNumber,
    setDriverPhoneCode,
    setLogisticsCompany,
    setCustomerAddress,
    handleGenerateWaybill,
    handleValidateWaybillItems,
  };
};
