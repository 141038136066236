export const RemoveIcon = ({ width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 10.5L17.5 17.5L10.5 10.5ZM17.5 10.5L10.5 17.5L17.5 10.5ZM9.33337 2.33334H18.6667L25.6667 9.33334V18.6667L18.6667 25.6667H9.33337L2.33337 18.6667V9.33334L9.33337 2.33334Z"
        stroke="#E02020"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
