import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const AddDebtValidationSchema = yup.object({
  amount: yup.string().required("Amount is required"),
  repaymentDate: yup.string().required("Repayment date is required"),
  StoreId: yup.string().required("store is required"),
});

export const Validation = ({ amount, repaymentDate }) => {
  return useForm({
    resolver: yupResolver(AddDebtValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      amount,
      repaymentDate,
    },
  });
};
