import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { motion } from "framer-motion";
import AddMultipleCustomersLogic from "./AddMultipleCustomersLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { One, Two } from "../../../assets/svgIcons";

const AddMultipleCustomers = ({ closeWidget }) => {
  const {
    isUploadingFile,
    inputRef,
    handleProductUpload,
    storeList,
    setCurrentStore,
    currentStore,
  } = AddMultipleCustomersLogic({ closeWidget });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className="m-4"
    >
      <primaryComponents.Button
        classNames="btn btn--outline"
        onClick={() => closeWidget()}
      >
        <FontAwesomeIcon
          icon="angle-left"
          className="mr-3"
          style={{ fontSize: "18px" }}
        />
        <span>Back</span>
      </primaryComponents.Button>
      <div className={globalStyles.pageColumnGaps}>
        <div>
          <h5 className="mt-3">Migrate Your Customers</h5>
          <p style={{ display: "none" }}>Upload your bulk product file here:</p>
          <input
            style={{ display: "none" }}
            type="file"
            name="products"
            id="products"
            onChange={handleProductUpload}
            ref={inputRef}
            accept=".csv"
          />
        </div>
        <div>
          <hr />
          <p className="small-text-size">
            Step-by-Step Guide for Migrating Your Customers to Your Lumi
            Business Store.
          </p>
          <hr />
          <div
            className={globalStyles.pageColumnGaps}
            style={{
              maxWidth: "100%",
              paddingLeft: "70px",
              backgroundColor: "#FAFAFA",
              minHeight: "100%",
              marginTop: "-8px",
              marginBottom: "-8px",
            }}
          >
            <div className="d-flex mt-3" style={{ gap: "4px" }}>
              <One />
              <p className="smallest-text-size dark--text mt-1 p-1">
                <b>Download the sample spreadsheet: </b> Start by downloading
                the sample spreadsheet provided here. This spreadsheet will
                serve as a template for organizing your customer data. <br />
                <a
                  href="https://enlumi-research-app.s3.amazonaws.com/production/business/4531/media/Customer%20Upload%20%2BTemplate%2B-%2BCustomer%2BTemplate%20-%20Product%2BTemplate%2B-%2BProduct%2BTemplate-3a1169ab.csv"
                  className="purple--text smallest-text-size"
                  style={{ textDecoration: "underline" }}
                >
                  Click here to download template
                </a>
              </p>
            </div>

            <div className="d-flex" style={{ gap: "4px" }}>
              <Two />
              <p className="smallest-text-size dark--text p-1 mt-3">
                <b>Watch the full video tutorial: </b> For a detailed
                walkthrough of the bulk upload process, we recommend watching
                our comprehensive video tutorial. This video will guide you
                through each step of the process, from organizing your data in
                the spreadsheet to successfully uploading your customers to your
                store. <br />
                <a
                  href="https://www.youtube.com/embed/EGhsR87T86s"
                  className="purple--text smallest-text-size"
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to find out how to bulk upload customers
                </a>
              </p>
            </div>
          </div>
          <hr />
        </div>
        <div style={{ maxWidth: "100%" }} className="mb-5">
          <primaryComponents.SelectField
            label={"Select a store to upload customers"}
            options={storeList}
            idKey="id"
            nameKey="name"
            onChange={(value) => {
              setCurrentStore(value);
            }}
            value={
              storeList?.find(
                (storeItem) => `${storeItem?.id}` === `${currentStore}`,
              )?.name || "Select store"
            }
            floatError={false}
            classNames="store-select cover"
          />
        </div>

        <primaryComponents.Button
          isLoading={isUploadingFile}
          classNames={"btn btn--primary container"}
          loadingText="Uploading..."
          isDisabled={currentStore === ""}
          onClick={() => inputRef.current.click()}
        >
          <img className="mr-2" src={Icon.upload} alt="" />
          Upload .CSV File
        </primaryComponents.Button>
      </div>
    </motion.div>
  );
};

export default AddMultipleCustomers;
