import { useState, useEffect } from "react";
import { COLORS } from "../../../helpers/constants";

const RevenueAnalyticsLogic = ({ tagsData }) => {
  const [sumOfTags, setSumOfTags] = useState(0);
  const data = tagsData.revenue_tags;

  useEffect(() => {
    calculateSumOfTags();
  }, [tagsData]);

  const calculateSumOfTags = () => {
    let sum = tagsData.revenue_tags.reduce((accumulator, item) => {
      return accumulator + item.total;
    }, 0);
    setSumOfTags(sum);
  };

  return {
    data,
    COLORS,
    sumOfTags,
  };
};

export default RevenueAnalyticsLogic;
