import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import useStores from "./useStores";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { pathConstants } from "../../../../../routes/pathContants";
import { useNavigate } from "react-router-dom";

const Stores = () => {
  const { storeList, isFetchingStores } = useStores();

  const navigate = useNavigate();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Stores"
      description="View all stores"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Stores</h4>
            <p style={{ maxWidth: "650px" }}>
              Transform your physical stores into virtual ones and manage your
              virtual stores with customizable features and settings.
            </p>
          </div>
          <div className={globalStyles.pageHeaders}>
            <p>{storeList?.length} Stores</p>

            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() => navigate(pathConstants.INVENTORY_STORES_CREATE)}
            >
              + Add new store
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper
            classNames="mt-4"
            isLoading={isFetchingStores}
          >
            <secondaryComponents.StoresTable storeList={storeList} />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Stores;
