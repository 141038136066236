import { useParams } from "react-router-dom";
import secondaryComponents from "../../../components/secondaryComponents";
import { useEffect, useState } from "react";
import { thirdPartyLogin } from "../../../services/auth";
import useGrantAccessToApp from "../../../hooks/useGrantAccessToApp";
import Logo from "../../../assets/logo";
import { useMutation } from "react-query";

export const ClientLogin = () => {
  const { token } = useParams();
  const { setAuthTokensAndGrantAccessToApp } = useGrantAccessToApp({
    message: "Client Access Successful",
  });
  const [errorResponse, setErrorResponse] = useState("");
  const clientConnectMutate = useMutation({
    mutationFn: (data) => thirdPartyLogin(data),
    onSuccess: (res) => {
      const { access, refresh } = res?.data?.data;
      setAuthTokensAndGrantAccessToApp({
        refreshToken: refresh,
        accessToken: access,
        profileDetails: {},
      });
    },
    onError: (error) => {
      setErrorResponse(error?.response?.data?.detail);
    },
  });

  useEffect(() => {
    clientConnectMutate.mutate({ token });
  }, []);

  return (
    <secondaryComponents.LoaderHelper
      classNames={"mt-5"}
      isLoading={clientConnectMutate?.isLoading}
    >
      <div className="p-4">
        <a href={"/"}>
          <img width={48} height={16} src={Logo.lumiWhiteLogo} alt="Lumi" />
        </a>

        <h4 className="text-center mt-3">{errorResponse}</h4>
      </div>
    </secondaryComponents.LoaderHelper>
  );
};
