import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useAllTransactions from "./useAllTransactions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoutePermissionRedirector from "../../../../../components/secondaryComponents/RoutePermissionRedirector";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const AllTransactions = () => {
  const {
    isRefreshingTransactions,
    search,
    isFetchingTnx,
    transactions,
    metaDetails,
    setSearch,
    RefreshTransactions,
    gotoPage,
    searchTnx,
    filterByDate,
  } = useAllTransactions();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transactions"
      description="View all transactions that have taken place"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Transactions</h4>
            <p>
              Your POS payment history: see the details and status of your
              transactions
            </p>
          </div>

          <div className="d-flex ml-auto">
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() => RefreshTransactions()}
              isDisabled={isRefreshingTransactions}
              isLoading={isRefreshingTransactions}
              loadingText="Refreshing..."
              data-testid="refresh-tnx"
            >
              Refresh Transactions
            </primaryComponents.Button>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Payments"}
              permissionChildKey="can_download_statement_of_account"
            >
              <primaryComponents.Button
                classNames="ml-2 btn btn--primary"
                onClick={() => {
                  search.set("statement", "true");
                  setSearch(search, { replace: true });
                }}
                data-testid="download-statement"
              >
                Statement
                <FontAwesomeIcon icon="download" className="ml-3" />
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <secondaryComponents.SubAccountsList />

          <div className={globalStyles.tableWrapper}>
            <div className={globalStyles.tableHeader}>
              {/* header section */}
              <primaryComponents.SearchField
                trigger={(value) => searchTnx(value)}
                value={search.get("query") || ""}
                placeholder="Search transaction by ( ID, phone number or email )"
                classNames={`${globalStyles.searchField}`}
                triggerOnInput={true}
                showsearchBtn={true}
              />
              {/* transaction filter */}
              <secondaryComponents.TransactionsFilter />
              {/* date filter */}
              <primaryComponents.DateRangeInput
                onChange={filterByDate}
                startDate={search.get("start_date")}
                endDate={search.get("end_date")}
                showPlaceholder={true}
              />
            </div>

            {/* transaction table */}
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingTnx}
              classNames="mt-5"
            >
              <secondaryComponents.TransactionsTable
                transactions={transactions}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          {/* statement of account widget */}
          <secondaryComponents.SideModal
            isActive={search.get("statement") === "true"}
            closeModal={() => {
              search.delete("statement");
              setSearch(search, { replace: true });
            }}
          >
            <RoutePermissionRedirector
              permissionParentKey={"Payments"}
              permissionChildKey="can_download_statement_of_account"
            >
              <secondaryComponents.StatementOfAccount
                closeWidget={() => {
                  search.delete("statement");
                  setSearch(search, { replace: true });
                }}
              />
            </RoutePermissionRedirector>
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AllTransactions;
