import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import useCompositeProducts from "../CreateCompositeProduct/useCompositeProducts";

const EditCompositeProducts = () => {
  const {
    compositeDetails,
    isFetchingCompositeDetails,
    isUpdatingComposite,
    isErrors,
    productToReplicate,
    isReplicatingProduct,
    editCompositeProduct,
    setIsErrors,
    replicateProductIntoStores,
  } = useCompositeProducts({ isEnabled: true });

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Composite Products"
      description="Edit composite products"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${globalStyles.pagePaddings} ${globalStyles.pageColumnGaps}`}
        >
          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingCompositeDetails}
          >
            <secondaryComponents.CompositeForm
              createCompositeProduct={editCompositeProduct}
              compositeDetails={compositeDetails}
              isEditMode={true}
              isSubmiting={isUpdatingComposite}
              isErrors={isErrors}
              setIsErrors={setIsErrors}
              replicateProductIntoStores={replicateProductIntoStores}
              isReplicatingProduct={isReplicatingProduct}
              productToReplicate={productToReplicate}
            />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default EditCompositeProducts;
