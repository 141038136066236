import { useQuery } from "react-query";
import { getServiceOption } from "../../../../services/auth";

const useBusinessForm = () => {
  const { data: serviceOfInterestOption, isLoading } = useQuery(
    "getServiceOption",
    () => getServiceOption().then((response) => response.data.data),
  );
  return {
    serviceOfInterestOption: serviceOfInterestOption || [],
    isLoading,
  };
};
export default useBusinessForm;
