import "./Login.scss";
import layouts from "../../../layouts";
import primaryComponents from "../../../components/primaryComponents";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import LoginLogic from "./LoginLogic";
import LoginFormValidation from "./LoginValidation";
import secondaryComponents from "../../../components/secondaryComponents";
import { Controller } from "react-hook-form";

const Login = () => {
  const {
    email,
    password,
    login,
    setPassword,
    setEmail,
    isLogging,
    errorMessage,
  } = LoginLogic();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = LoginFormValidation(email, password);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Log In"
      description="Log in to your lumi merchant account"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          data-testid="login-page"
        >
          <layouts.LoginLayout
            linkedPage={
              <p>
                Don&apos;t have an account?
                <Link
                  className="purple--text ml-1"
                  to="/signup"
                  data-testid="create-account-btn"
                >
                  Create Account
                </Link>
              </p>
            }
          >
            <div className="login-page">
              <div className="login-page__container">
                <h3 className="purple--text font-weight-normal text-center">
                  Welcome Back
                </h3>
                <p className="text-center">
                  Enter your email address and your password
                </p>
                {/* login form section */}
                <form onSubmit={handleSubmit(login)} className="login__form">
                  {/* error message */}
                  {errorMessage !== null && (
                    <p className="text-danger text-center mb-1">
                      {errorMessage}
                    </p>
                  )}
                  <div className="form-group">
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={email}
                      render={({ field: { onChange } }) => (
                        <primaryComponents.InputField
                          label="Email Address or User Name"
                          placeholder="Enter your email address or username"
                          isRequired={true}
                          name="email"
                          errorMessage={errors.email}
                          onKeyUp={() =>
                            errors.email !== undefined && trigger("email")
                          }
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            setEmail(value);
                          }}
                          register={register}
                        />
                      )}
                    />
                  </div>
                  <div className="mb-1">
                    <Controller
                      name="password"
                      control={control}
                      defaultValue={password}
                      render={({ field: { onChange } }) => (
                        <primaryComponents.PasswordInputField
                          label="Password"
                          name="password"
                          placeholder="Enter your password"
                          errorMessage={errors.password}
                          isRequired={true}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            setPassword(value);
                          }}
                          onKeyUp={() =>
                            errors.password !== undefined && trigger("password")
                          }
                          register={register}
                        />
                      )}
                    />
                  </div>
                  <div className="text-right mb-4">
                    <Link
                      className="purple--text  default-font-size"
                      to="/forgot-password"
                      data-testid="forgot-password-btn"
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <primaryComponents.Button
                    classNames="btn btn--primary cover"
                    type="submit"
                    isDisabled={isLogging}
                    isLoading={isLogging}
                    loadingText="Logging..."
                  >
                    Log In
                  </primaryComponents.Button>
                </form>
                <p className="route mt-3 text-center">
                  Don&apos;t have an account?
                  <Link className="purple--text ml-1" to="/signup">
                    Create Account
                  </Link>
                </p>
              </div>
            </div>
          </layouts.LoginLayout>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Login;
