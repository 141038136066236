import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUrlQuerysection, getStartAndDate } from "../../../helpers";

const TransfersFilterLogic = ({ applyFilter, filterDetails }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { paymentStatus, setPaymentStatus, setTimeDuration, timeDuration } =
    filterDetails;
  const navigate = useNavigate();

  const setFilter = () => {
    let paymentStatusValues = paymentStatus.length === 0 ? "" : "status=";

    paymentStatus?.forEach((item, index) => {
      let comma = index + 1 === paymentStatus.length ? "" : "|";
      paymentStatusValues = paymentStatusValues + item + comma;
    });

    const queryParams = new URLSearchParams(window.location.search);
    let queries = getUrlQuerysection();
    let newQueries = "";
    let prevPaymentStatus = queryParams.get("status");
    let prevStartDate = queryParams.get("start_date");
    let prevEndDate = queryParams.get("end_date");

    // set payment method filters
    if (prevPaymentStatus === null) {
      let symbol =
        paymentStatusValues.length === 0
          ? ""
          : queries.includes("?")
            ? "&"
            : "?";
      newQueries = `${queries}${symbol}${paymentStatusValues}`;
    } else {
      let splitedQuery = queries.split(`status=${prevPaymentStatus}`);
      splitedQuery = splitedQuery[0] + paymentStatusValues + splitedQuery[1];
      newQueries = `${splitedQuery}`;
    }

    // set date filters
    const { startDate, endDate } = getStartAndDate(timeDuration);
    let startDateQuery = startDate === "" ? "" : `start_date=${startDate}`;
    let endDateQuery = endDate === "" ? "" : `end_date=${endDate}`;

    if (prevStartDate === null) {
      let symbol = startDate === "" ? "" : newQueries.includes("?") ? "&" : "?";
      newQueries = `${newQueries}${symbol}${startDateQuery}`;
    } else {
      let splitedQuery = newQueries.split(`start_date=${prevStartDate}`);
      splitedQuery = splitedQuery[0] + `${startDateQuery}` + splitedQuery[1];
      newQueries = `${splitedQuery}`;
    }

    if (prevEndDate === null) {
      let symbol = endDate === "" ? "" : newQueries.includes("?") ? "&" : "?";
      newQueries = `${newQueries}${symbol}${endDateQuery}`;
    } else {
      let splitedQuery = newQueries.split(`end_date=${prevEndDate}`);
      splitedQuery = splitedQuery[0] + `${endDateQuery}` + splitedQuery[1];
      newQueries = `${splitedQuery}`;
    }

    navigate(newQueries);
    setShowDropdown(false);
    applyFilter();
  };

  const addValueToPaymentStatus = (value) => {
    let newPaymentStatus = [...paymentStatus];
    newPaymentStatus.push(value);
    setPaymentStatus(newPaymentStatus);
  };

  const removeValueFromPaymentStatus = (value) => {
    let newPaymentStatus = [...paymentStatus];
    setPaymentStatus(newPaymentStatus.filter((item) => item !== value));
  };

  return {
    showDropdown,
    setShowDropdown,
    paymentStatus,
    setTimeDuration,
    timeDuration,
    setFilter,
    removeValueFromPaymentStatus,
    addValueToPaymentStatus,
  };
};
export default TransfersFilterLogic;
