import { useTable } from "react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  addTransactionTag,
  removeTransactionTag,
} from "../../../services/transaction";
import { addTag } from "../../../services/tags";
import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { formatCurrency, convertToTilteCase } from "../../../helpers";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";
import { useGetTagList } from "../../../hooks/api/queries/useInventory";
import primaryComponents from "../../primaryComponents";
import { useQueryClient } from "react-query";

const useTransactionsTable = ({ transactions }) => {
  const { role: business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [openTagWidget] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { data: tags } = useGetTagList();
  const queryClient = useQueryClient();

  const handleAddTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    addTransactionTag(id, tags)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Tag added successfully", "success");
      })
      .catch(() => {
        setRequestLoaderProgress(100);
      });
  };

  const handleRemoveTag = (data) => {
    const { id, tags } = data;
    setRequestLoaderProgress(40);
    removeTransactionTag(id, tags)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Tag removed successfully", "success");
      })
      .catch(() => {
        setRequestLoaderProgress(100);
      });
  };

  const handleCreateTag = ({ value, transaction_id }) => {
    setRequestLoaderProgress(40);
    addTag({ name: value })
      .then((response) => {
        setRequestLoaderProgress(100);
        const tag = response.data?.data;
        queryClient.invalidateQueries(["getTagList"]);
        handleAddTag({ id: transaction_id, tags: [tag.id] });
      })
      .catch(() => {
        setRequestLoaderProgress(100);
      });
  };

  const handleTagChange = (data) => {
    const { transfer_id, datas } = data;
    if (datas.action === "select-option") {
      handleAddTag({ id: transfer_id, tags: [datas.option.value] });
    } else if (datas.action === "remove-value") {
      const { removedValue } = datas;
      handleRemoveTag({ id: transfer_id, tags: [removedValue.value] });
    } else if (datas.action === "clear") {
      const { removedValues } = datas;
      const tags = removedValues.map((removedValue) => removedValue.value);
      handleRemoveTag({ id: transfer_id, tags: tags });
    }
  };

  const columns = useMemo(() => {
    let headers = [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "-",
      },
      {
        Header: "Customer Name",
        accessor: "payer",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={pathConstants.PAYMENT_TRANSACTIONS_DETAILS({
              id: row.original["id"],
            })}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {value ? convertToTilteCase(value) : "N/A"}
          </Link>
        ),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) =>
          value
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : "-",
      },
      {
        Header: "Account",
        accessor: "business_tag",
      },
    ];

    if (business_role !== "CUSTOMER_SUPPORT") {
      headers.push({
        Header: "Transaction Fee",
        accessor: "settlement_fee",
        Cell: ({ cell: { value } }) =>
          value
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : "-",
      });
      headers.push({
        Header: "Amount Settled",
        accessor: "amount_settled",
        Cell: ({ cell: { value } }) =>
          value
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : "-",
      });
    }
    headers.push({
      Header: "Status",
      accessor: "status",
      Cell: ({ cell: { value } }) => (
        <span
          className={`${
            value === "settled"
              ? "success--squared"
              : value === "unpaid"
                ? "pending--squared"
                : "failed--text"
          }`}
        >
          {value}
        </span>
      ),
    });

    headers.push({
      Header: "Tags",
      accessor: "",
      Cell: ({ cell: { row } }) => (
        <span style={{ maxWidth: "200px" }}>
          <primaryComponents.TagsMultiSelect
            options={tags}
            idKey="id"
            nameKey="name"
            value={row.original?.tag.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={(value, datas) => {
              const transfer_id = row.original?.id;
              handleTagChange({
                inputValue: value,
                transfer_id,
                datas,
              });
            }}
            onCreateOption={(value) => {
              handleCreateTag({
                value,
                transfer_id: row.original.id,
              });
            }}
          />
        </span>
      ),
    });

    return headers;
  }, []);

  const instance = useTable({
    columns,
    data: transactions,
  });

  return {
    instance,
    openTagWidget,
    tags,
    handleTagChange,
    handleCreateTag,
  };
};

export default useTransactionsTable;
