import { useTable } from "react-table";
import { useMemo } from "react";
import { formatNumberWithCommas, truncateString } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import { useNavigate } from "react-router-dom";
import secondaryComponents from "..";

import { pathConstants } from "../../../routes/pathContants";

const useAllProductsBatchTable = ({ productsBatch }) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () => [
      {
        Header: "Batch ID",
        accessor: "batch_id",
        Cell: ({ cell: { value }, row }) => {
          if (value) {
            return (
              <span
                style={{
                  color: "#7647EE",
                  height: "28px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(
                    pathConstants.INVENTORY_PRODUCTS_BATCH_EDIT +
                      `?product_id=${row?.original?.product_id}`,
                  );
                }}
              >
                {truncateString(value, 20)}
              </span>
            );
          } else return "-";
        },
      },
      {
        Header: "Product Name",
        accessor: "product_name",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value, 50) : "N/A",
      },
      {
        Header: "Stock Quantity",
        accessor: "stock_quantity",
        Cell: ({ cell: { value } }) =>
          value ? formatNumberWithCommas(value) : "N/A",
      },
      {
        Header: "Procurement Date",
        accessor: "procurement_date",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Expiring Date",
        accessor: "expiry_date",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <span className="d-flex">
            <secondaryComponents.NavigateWithPermission>
              <primaryComponents.Button
                onClick={() => {
                  navigate(
                    pathConstants.INVENTORY_PRODUCTS_BATCH_EDIT +
                      `?product_id=${row?.original?.product_id}`,
                  );
                }}
                classNames={"btn btn--primary smallBtn mr-2"}
              >
                Edit details
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: productsBatch });
  return {
    instance,
  };
};

export default useAllProductsBatchTable;
