/* eslint-disable jsx-a11y/anchor-is-valid */
import styles from "./SellModeLayout.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import primaryComponents from "../../components/primaryComponents";
import secondaryComponents from "../../components/secondaryComponents";
import useSellModeLayout from "./useSellModeLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import Icon from "../../assets/icons";
import TableView from "../../components/secondaryComponents/TableView";
import { pathConstants } from "../../routes/pathContants";
import { truncateString } from "../../helpers";
import { PendIcon } from "../../assets/svgIcons";

const SellModeLayout = () => {
  const {
    tableCardId,
    isLoading,
    categoryId,
    categories,
    searchValue,
    productContainer,
    inventoryType,
    storeName,
    isSubscriptionModal,
    pendingMetaDetails,
    menuItems,
    setMenuItems,
    resetView,
    setInventoryType,
    handleSearchProducts,
    selectCategory,
    filterByInventory,
    setSearchValue,
    setIsSubscriptionModal,
    setTableCardId,
  } = useSellModeLayout();
  const navigate = useNavigate();
  const categoryContainer = document.getElementById("categorySlider");
  const [scrollX, setscrollX] = useState(0); // For detecting start scroll postion
  const [scrolEnd, setscrolEnd] = useState(false); // For detecting end of scrolling
  const slideCategory = (shift) => {
    categoryContainer.scrollLeft += shift;
    setscrollX(scrollX + shift); // Updates the latest scrolled postion

    //For checking if the scroll has ended
    if (
      Math.floor(
        categoryContainer.scrollWidth - categoryContainer.scrollLeft,
      ) <= categoryContainer.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //This will check scroll event and checks for scroll end
  const scrollCheck = () => {
    setscrollX(categoryContainer.scrollLeft);
    if (
      Math.floor(
        categoryContainer.scrollWidth - categoryContainer.scrollLeft,
      ) <= categoryContainer.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    //Check width of the scollings
    if (
      categoryContainer &&
      categoryContainer?.scrollWidth === categoryContainer?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [categoryContainer?.scrollWidth, categoryContainer?.offsetWidth]);

  return (
    <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-5">
      <div className={styles.storeLayout} ref={productContainer}>
        <div className={styles.storeLayout__header}>
          <div className={styles.storeLayout__header__top}>
            <div className={styles.navSection}>
              <primaryComponents.Button
                className={`btn btn--outline ${styles.backButton}`}
                style={{ padding: "15px" }}
                onClick={() => navigate(pathConstants.STORES)}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="purple--text"
                  style={{ fontSize: "20px" }}
                />
              </primaryComponents.Button>
              <div className={styles.midSection}>
                {/* <div className={styles.selectContainer}>
                    <primaryComponents.SelectField
                      options={["All", "Products", "Services"]}
                      value={inventoryType}
                      onChange={(value) => {
                        setInventoryType(value);
                        filterByInventory(value);
                      }}
                      floatError={false}
                    />
                  </div> */}
                <div className={styles.navSearchSection}>
                  <div className={styles.storeName}>
                    <FontAwesomeIcon
                      className="mr-2"
                      style={{ fontSize: "20px" }}
                      icon="map-marker-alt"
                    />{" "}
                    <span>{truncateString(storeName, 7)}</span>
                  </div>
                  <primaryComponents.SearchField
                    trigger={(value) => handleSearchProducts(value)}
                    value={searchValue}
                    placeholder="Search by Barcode Scaning or Product Name"
                    classNames={styles.searchSection}
                    triggerOnInput={true}
                    useControlledValue={true}
                    setValue={(val) => setSearchValue(val)}
                    isScan={true}
                    showClearBtn={true}
                  />
                </div>
              </div>

              <div className={styles.storeMenu}>
                <div>
                  <primaryComponents.Button
                    classNames={"btn btn--outline-yellow mx-auto"}
                    style={{
                      padding: "0 7px",
                      height: "35px",
                      position: "relative",
                      overflow: "visible",
                    }}
                    onClick={() => resetView("showPendingSessions")}
                  >
                    <PendIcon />
                    <span className={styles.noOfPends}>
                      {pendingMetaDetails?.total}
                    </span>
                  </primaryComponents.Button>
                  <span className="white--text d-block text-center mt-1">
                    Pending Checkouts
                  </span>
                </div>
                <div className={styles.divider}></div>
                <div onClick={() => resetView("isOpenSellModeMenu")}>
                  <primaryComponents.Button
                    classNames={"btn btn--outline mx-auto"}
                    style={{ padding: "0 7px", height: "35px" }}
                  >
                    <img width={20} src={Icon.storeMenuIcon} alt="" />
                  </primaryComponents.Button>
                  <span className="white--text d-block text-center mt-1">
                    Menu
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.storeLayout__bottom}>
            <div className={styles.storeLayout__categories}>
              {/* scroll left */}
              <div
                className={`${styles.categoryScroller} ${
                  scrollX !== 0 && styles.active
                }`}
                onClick={() => slideCategory(-100)}
              >
                <FontAwesomeIcon icon="angle-left" />
              </div>

              <div
                className={styles.categoryContainer}
                id="categorySlider"
                onScroll={scrollCheck}
              >
                <a
                  className={`${styles.category} ${
                    categoryId === null ? styles.isActive : ""
                  }`}
                  onClick={() => selectCategory(null)}
                >
                  All categories
                </a>
                {categories.map((category) => (
                  <a
                    className={`${styles.category} ${
                      categoryId === category?.id ? styles.isActive : ""
                    }`}
                    key={category?.id}
                    onClick={() => selectCategory(category?.id)}
                  >
                    {category?.name}
                  </a>
                ))}
              </div>
              {/* scroll right */}
              <div
                className={`${styles.categoryScroller} ${
                  !scrolEnd && styles.active
                } ml-auto`}
                onClick={() => slideCategory(100)}
              >
                <FontAwesomeIcon icon="angle-right" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.storeLayout__content}>
          <Outlet
            context={{
              tableCardId,
              productContainer,
              inventoryType,
              menuItems,
              setMenuItems,
              resetView,
              setInventoryType,
              filterByInventory,
              setTableCardId,
            }}
          />
        </div>
      </div>

      {/* sellmode menu widget */}
      <secondaryComponents.SellModeSideModal
        closeWidget={() => resetView("isOpenSellModeMenu", false)}
        isActive={menuItems?.isOpenSellModeMenu}
        width={440}
      >
        <secondaryComponents.SellModeMenu
          resetView={resetView}
          setTableCardId={setTableCardId}
        />
      </secondaryComponents.SellModeSideModal>

      {/* subscription modal */}
      <secondaryComponents.Modal
        closeModal={() => setIsSubscriptionModal(false)}
        isActive={isSubscriptionModal}
        width={750}
        closeViaOverlay={false}
      >
        <secondaryComponents.SubscribtionNotification
          setIsSubscriptionModal={setIsSubscriptionModal}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        closeModal={() => resetView("isTable", false)}
        isActive={menuItems?.isTable}
        width={1360}
      >
        <TableView resetView={resetView} setTableCardId={setTableCardId} />
      </secondaryComponents.Modal>
    </secondaryComponents.LoaderHelper>
  );
};

export default SellModeLayout;
