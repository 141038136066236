import "./SignupLayout.scss";

import Logo from "../../assets/logo";
import Images from "../../assets/images";

const SignupLayout = ({ children, sidebar }) => {
  return (
    <div className="signup-layout">
      <div className="signup-layout__sidebar">
        <img
          className="signup-layout__sidebar__logo"
          src={Logo.primary}
          alt="lumi merchant"
        />
        {sidebar}
        <img
          className="signup-layout__sidebar__image"
          src={Images.enlumi_arrows}
          alt=""
        />
      </div>
      <div className="signup-layout__content">{children}</div>
    </div>
  );
};

export default SignupLayout;
