import "../UpdateCreditWidget/UpdateCreditWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendCreditReminderWidgetLogic from "./SendCreditReminderWidgetLogic";
import primaryComponents from "../../primaryComponents";
import Validation from "./useValidation";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./SendCreditReminder.module.scss";

const SendCreditReminderWidget = ({
  closeWidget,
  customer,
  type = "reminder",
}) => {
  const {
    messageType,
    isSending,
    setMessageType,
    reminderDetails,
    setReminderDetails,
    sendCreditLimitReminder,
    handleSendThroughChange,
    sendThroughOptions,
    generateDefaultSubject,
    generateDefaultBody,
    handleSubjectChange,
    handleBodyChange,
  } = SendCreditReminderWidgetLogic({
    closeWidget,
    customer,
    type,
  });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = Validation({ customer });

  return (
    <div className="update-credit">
      <FontAwesomeIcon
        onClick={closeWidget}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "25px" }}
      />
      <div className="update-credit__title">
        {type === "reminder" ? "Remind Customer" : "Message Customer"}
      </div>
      {/* {type === "reminder" ? (
        <div className="update-credit__subtitle">
          Send email or SMS reminders to your customers for credit repayment
        </div>
      ) : null} */}
      <div className="d-flex mt-3" style={{ gap: "15px" }}>
        <primaryComponents.BoxedRadioInput
          name="send_through"
          value={sendThroughOptions.EMAIL}
          id="email"
          isChecked={reminderDetails.send_through === sendThroughOptions.EMAIL}
          onChange={() => handleSendThroughChange(sendThroughOptions.EMAIL)}
          setRadioValue={setMessageType}
          classNames="whites"
        >
          Email
        </primaryComponents.BoxedRadioInput>

        <primaryComponents.BoxedRadioInput
          name="send_through"
          value={sendThroughOptions.SMS}
          id="sms"
          isChecked={reminderDetails.send_through === sendThroughOptions.SMS}
          onChange={() => handleSendThroughChange(sendThroughOptions.SMS)}
          setRadioValue={setMessageType}
          classNames="whites"
        >
          SMS
        </primaryComponents.BoxedRadioInput>
      </div>

      <div className="update-credit__payment-form mt-3">
        {messageType === "email" ? (
          <primaryComponents.InputFieldV2
            control={control}
            classNames="white"
            name="email"
            label={"Email"}
            isRequired={true}
            value={
              messageType === "email"
                ? reminderDetails?.email
                : reminderDetails?.phone
            }
            onChange={({ target: { value } }) => {
              setReminderDetails((prev) => ({
                ...prev,
                email: value,
              }));
            }}
            isDisabled={false}
            errorMessage={errors.email}
            register={register}
          />
        ) : (
          <primaryComponents.PhoneNumberInputFieldV2
            control={control}
            classNames="white"
            name="phoneNumber"
            label="Phone Number"
            value={reminderDetails?.phone}
            onChange={({ target: { value } }) => {
              setReminderDetails((prev) => ({
                ...prev,
                phone: value,
              }));
            }}
            errorMessage={errors.phoneNumber}
            onKeyUp={() =>
              errors.phoneNumber !== undefined && trigger("phoneNumber")
            }
            register={register}
            phoneCodeEditable={true}
            onChangePhoneCode={(val) => {
              setReminderDetails((prev) => ({
                ...prev,
                phone_code: val,
              }));
            }}
            countryCode={reminderDetails?.phone_code}
          />
        )}
      </div>
      <div className="py-3 px-3  mt-3 border rounded">
        {messageType === "email" ? (
          <primaryComponents.InputFieldV2
            control={control}
            classNames="white"
            name="subject"
            label="Subject"
            value={reminderDetails?.subject || generateDefaultSubject()}
            onChange={handleSubjectChange}
            register={register}
          />
        ) : null}
        <ReactQuill
          theme="snow"
          value={reminderDetails.body || generateDefaultBody()}
          onChange={handleBodyChange}
          placeholder="Enter body text here..."
          readOnly={isSending}
          className={"quill-editor"}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }],
              [{ list: "ordered" }, { list: "bullet" }],
              ["bold", "italic", "underline"],
              [{ align: [] }],
              ["link"],
              ["clean"],
              [{ font: [] }],
              [{ size: [] }],
              [{ color: [] }],
              [{ background: [] }],
              ["blockquote"],
              [{ script: "sub" }, { script: "super" }],
              [{ direction: "rtl" }],
              [{ indent: "-1" }, { indent: "+1" }], // Add indent buttons
              [{ direction: "rtl" }],
              ["code-block"],
            ],
          }}
          formats={[
            "header",
            "list",
            "bold",
            "italic",
            "underline",
            "align",
            "link",
            "image",
            "clean",
            "font",
            "size",
            "color",
            "background",
            "blockquote",
            "script",
            "direction",
            "indent",
            "code-block",
          ]}
        />
      </div>
      <div className={`${styles.bottom} pt-4`}>
        <primaryComponents.Button
          onClick={handleSubmit(sendCreditLimitReminder)}
          isLoading={isSending}
          isDisabled={isSending || reminderDetails.body === ""}
          classNames="btn btn--primary cover"
          loadingText={"Sending..."}
          type="submit"
        >
          Send
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default SendCreditReminderWidget;
