import React from "react";
import secondaryComponents from "..";
import styles from "./CustomerDetailsCard.module.scss";
import { COLORS } from "../../../helpers/constants";

const CustomerDetailsCard = ({
  title,
  value,
  color,
  isFetchingAnalytics,
  percentageText,
}) => {
  return (
    <div className={styles.analyticsCard}>
      <div
        className={styles.analyticsCard__left}
        style={{ borderLeft: `4px solid ${COLORS[color % COLORS.length]}` }}
      >
        <p className="mb-0 small-text-size ">{title}</p>
      </div>
      <secondaryComponents.LoaderHelper isLoading={isFetchingAnalytics}>
        <h3 className="mb-0">{value}</h3>
      </secondaryComponents.LoaderHelper>

      <p className="success--text">{percentageText}</p>
    </div>
  );
};

export default CustomerDetailsCard;
