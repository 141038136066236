import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { ToastContext } from "../../../hooks/context";
import {
  createExpenseCategory,
  editExpenseCategory,
} from "../../../services/expenses";

const useCreateExpenseCategory = ({
  handleFetchCategories,
  closeWidget,
  categoryDetails,
  isEditMode,
}) => {
  const [categoryName, setCategoryName] = useState(categoryDetails?.name || "");
  const triggerToast = useContext(ToastContext);

  const { mutate: createCategory, isLoading: isCreatingCategory } = useMutation(
    (data) => createExpenseCategory(data),
  );
  const { mutate: editCategory, isLoading: isUpdatingCategory } = useMutation(
    (data) => editExpenseCategory(data),
  );
  const handleAddExpenseCategory = () => {
    if (!isEditMode)
      createCategory(
        {
          name: categoryName,
        },
        {
          onSuccess: () => {
            handleFetchCategories();
            triggerToast("Category created successfully", "success");
            closeWidget();
          },
        },
      );
    else
      editCategory(
        {
          data: { name: categoryName },
          id: categoryDetails?.id,
        },
        {
          onSuccess: () => {
            handleFetchCategories();
            triggerToast("Category Edited successfully", "success");
            closeWidget();
          },
        },
      );
  };

  return {
    isCreatingCategory,
    categoryName,
    isUpdatingCategory,
    setCategoryName,
    handleAddExpenseCategory,
  };
};
export default useCreateExpenseCategory;
