import { useQuery } from "react-query";
import { getExpenses, getExpenseAnalytics } from "../../../services/expenses";

export const useGetExpenses = (queries) => {
  return useQuery(
    ["getExpenses", queries],
    () => getExpenses(queries).then((res) => res?.data),
    {
      enabled: queries !== null,
    },
  );
};

export const useGetExpensesAnalytics = (queries = "") => {
  return useQuery(
    ["getExpenseAnalytics", queries],
    () => getExpenseAnalytics(queries).then((res) => res?.data?.data),
    {
      enabled: queries !== null,
    },
  );
};
