import { useState } from "react";
import { useQuery } from "react-query";
import { getOrderTables } from "../../../../services/inventory";
import { getUrlQuerysection } from "../../../../helpers";
import { useSearchParams } from "react-router-dom";

export const useInventoryTableLogic = () => {
  const [isAddNewTable, setIsAddNewTable] = useState(false);
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();

  const {
    isLoading: isFetchingOrderTables,
    data: orderTables,
    refetch: handleFetchOrderTables,
  } = useQuery(
    ["getOrderTables", queries],
    () => getOrderTables(queries).then((res) => res.data),
    { staleTime: Infinity },
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    isAddNewTable,
    isFetchingOrderTables,
    orderTables: orderTables?.data || [],
    metaDetails: orderTables?.meta || [],
    setIsAddNewTable,
    handleFetchOrderTables,
    gotoPage,
  };
};
