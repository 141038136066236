import { useTable } from "react-table";
import { useMemo } from "react";
import Icon from "../../../../assets/icons";
import { Link } from "react-router-dom";
import { pathConstants } from "../../../../routes/pathContants";
import primaryComponents from "../../../primaryComponents";
import { defaultDateDisplay, formatCurrency } from "../../../../helpers";
import { useState } from "react";

const useStoreWalletTransactionTable = ({ storeWalletTransactions }) => {
  const [tnxId, setTnxId] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: "Transaction Reference",
        accessor: "id",
        Cell: ({ cell: { value }, row }) => (
          <span>
            <Link
              className="purple--text small-text-size"
              to={pathConstants.INVENTORY_STORE_WALLET_TRANSACTION_DETAILS({
                id: row?.original?.id,
              })}
            >
              {value}
            </Link>
            <span className="d-block">
              {defaultDateDisplay(row?.original?.created_at)}
            </span>
          </span>
        ),
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ cell: { value } }) => (
          <span className={value === "debit" ? "error--text" : "success--text"}>
            {value}
          </span>
        ),
      },
      {
        Header: "Payment Method",
        accessor: "payment_method",
        Cell: ({ cell: { value } }) => <div>{value ? value : "____"}</div>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value }, row }) => (
          <span>
            {" "}
            {formatCurrency({
              value,
              currencyCode: row?.original?.store?.currency_code,
            })}
          </span>
        ),
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({ cell: { value }, row }) => (
          <span>
            {formatCurrency({
              value,
              currencyCode: row?.original?.store?.currency_code,
            })}
          </span>
        ),
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value?.name}</span>,
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => (
          <primaryComponents.Button
            classNames={"btn btn--primary smallBtn"}
            onClick={() => setTnxId(row?.original?.id)}
          >
            <img className="mr-2" src={Icon.receipt} alt="" />
            <span>Print Receipt</span>
          </primaryComponents.Button>
        ),
      },
    ],
    [],
  );

  const instance = useTable({
    columns,
    data: storeWalletTransactions,
  });

  return {
    tnxId,
    setTnxId,
    instance,
  };
};

export default useStoreWalletTransactionTable;
