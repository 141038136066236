import { useQuery } from "react-query";
import { getBeneficiaries, getTransfers } from "../../../services/transfers";

export const useGetTransfers = ({ queries = "" }) => {
  return useQuery(
    ["getTransfers", queries],
    () => getTransfers(queries).then((res) => res?.data),
    // {
    //   staleTime: Infinity,
    // },
  );
};

export const useGetBeneficiaries = ({ queries = "" }) => {
  return useQuery(
    ["getBeneficiaries", queries],
    () => getBeneficiaries(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};
