import useGetPermission from "../../../hooks/useGetPermission";

const NavigateWithPermission = ({
  children,
  permissionParentKey,
  permissionChildKey,
}) => {
  const { isPermitted } = useGetPermission({
    permissionParentKey,
    permissionChildKey,
  });

  return isPermitted ? <>{children}</> : null;
};
export default NavigateWithPermission;
