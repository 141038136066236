import { useContext, useState } from "react";
import { ToastContext } from "../../../../hooks/context";
import { useGetTerminals } from "../../../../hooks/api/queries/useTerminals";
import { useSendOrderReceiptToPrint } from "../../../../hooks/api/mutations/useOrder";
import { useSendSectionItemReceiptToPrint } from "../../../../hooks/api/mutations/useSection";

const useReceiptTerminals = ({ orderId, sectionId, setSectionId }) => {
  const triggerToast = useContext(ToastContext);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const { data: terminalList, isLoading: isFetchingTerminals } =
    useGetTerminals({ queries: "?all_business=true" });

  const { isLoading: isPrinting, mutate: printReceipt } =
    useSendOrderReceiptToPrint();

  const { isLoading: isPrintingSection, mutate: printSectionReceipt } =
    useSendSectionItemReceiptToPrint();

  const sendOrderReceipt = (terminalId, terminalSn) => {
    setSelectedTerminal(terminalId);
    printReceipt(
      { order_id: orderId, terminal_id: terminalId },
      {
        onSuccess: () => {
          triggerToast(
            `The receipt has been sent to terminal with SN: ${terminalSn}`,
            "success",
          );
        },
      },
    );
  };

  const sendSectionItemOReceipt = (terminalId, terminalSn) => {
    setSelectedTerminal(terminalId);
    printSectionReceipt(
      { section_id: sectionId, terminal_id: terminalId },
      {
        onSuccess: () => {
          triggerToast(
            `The receipt has been sent to terminal with SN: ${terminalSn}`,
            "success",
          );
          setSectionId(null);
        },
      },
    );
  };

  return {
    isFetchingTerminals,
    isPrinting,
    isPrintingSection,
    selectedTerminal,
    terminalList: terminalList || [],
    sendOrderReceipt,
    sendSectionItemOReceipt,
  };
};
export default useReceiptTerminals;
