import { useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getSuppliers } from "../../../../services/suppliers";

const useSuppliers = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const [isCreateSupplier, setIsCreateSupplier] = useState(false);

  const {
    isLoading: isFetchingSuppliers,
    data: suppliers,
    refetch: handleFetchSuppliers,
  } = useQuery(
    ["getSuppliersList", queries],
    () => getSuppliers(queries).then((res) => res?.data),
    { staleTime: Infinity },
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    isFetchingSuppliers,
    metaDetails: suppliers?.meta || {},
    isCreateSupplier,
    suppliers: suppliers?.data || [],
    setIsCreateSupplier,
    handleFetchSuppliers,
    gotoPage,
  };
};
export default useSuppliers;
