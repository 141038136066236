import { useState, useContext } from "react";
import { ToastContext } from "../../../hooks/context";
import { useMutation } from "react-query";
import {
  generateWaybill,
  validateWaybillItems,
} from "../../../services/sales-point";
import { useSelector } from "react-redux";

export const useGenerateWaybill = ({ orderDetails, closeWidget }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [waybillItems, setWaybillItems] = useState(
    () =>
      orderDetails?.order_items.map((order) => ({
        ...order,
        isDisabled: true,
      })) || [],
  );
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerPhoneCode, setCustomerPhoneCode] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [driverName, setDriverName] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverPhoneNumber, setDriverPhoneNumber] = useState("");
  const [driverPhoneCode, setDriverPhoneCode] = useState("");
  const [logisticsCompany, setLogisticsCompany] = useState("");
  // const [waybillNumber, setWaybillNumber] = useState("");
  const [currentStep, setCurrentStep] = useState("waybill_items");

  const order_id = orderDetails?.id || "";
  const hasSelectedAnItem =
    waybillItems.filter((item) => item?.isDisabled === false).length > 0;

  const triggerToast = useContext(ToastContext);
  const generateWaybillMutation = useMutation({
    mutationFn: (data) => generateWaybill(order_id, data),
    onSuccess: (response) => {
      const data = response.data?.data;
      const waybill_url = data?.waybill_url;
      closeWidget();
      window.open(waybill_url, "_blank");
    },
    onError: (error) => {
      if (error?.response?.status === 400)
        triggerToast(error?.response?.data?.detail, "warning");

      if (error?.response?.status === 422) {
        for (const [index, [key]] of Object.entries(
          Object.entries(error?.response?.data?.errors),
        )) {
          if (index > 0) break;
          triggerToast(
            typeof error?.response?.data?.errors?.[key]?.[0] === "string"
              ? error?.response?.data?.errors?.[key]?.[0]
              : error?.response?.data?.detail,
            "warning",
          );
        }
      }
    },
  });

  const validateWaybillItemsMutation = useMutation({
    mutationFn: (data) => validateWaybillItems(order_id, data),
    onSuccess: (response) => {
      const message = response.data?.detail;
      triggerToast(message, "success");
      setCurrentStep("waybill_info");
    },
    onError: (error) => {
      if (error?.response?.status === 400)
        triggerToast(error?.response?.data?.detail, "warning");
    },
  });

  const handleGenerateWaybill = () => {
    const payload = {
      waybill_items:
        waybillItems
          .filter((item) => item?.isDisabled === false)
          .map((item) => ({ item_id: item?.id, quantity: item?.quantity })) ||
        [],
      customer: {
        customer_name: customerName,
        customer_email: customerEmail,
        customer_phone_number: customerPhoneNumber,
        customer_phone_code: customerPhoneCode || business_phone_code,
        customer_address: customerAddress,
      },
      logistics: {
        driver_name: driverName,
        driver_vehicle_number: vehicleNumber,
        driver_phone_number: driverPhoneNumber,
        driver_phone_code: driverPhoneCode || business_phone_code,
        company: logisticsCompany,
        // waybill_number: waybillNumber,
      },
    };

    generateWaybillMutation.mutate(payload);
  };

  const handleValidateWaybillItems = () => {
    const payload = {
      waybill_items:
        waybillItems
          .filter((item) => item?.isDisabled === false)
          .map((item) => ({ item_id: item?.id, quantity: item?.quantity })) ||
        [],
    };

    return validateWaybillItemsMutation.mutate(payload);
  };

  return {
    customerEmail,
    customerName,
    customerPhoneNumber,
    customerAddress,
    driverName,
    vehicleNumber,
    driverPhoneNumber,
    logisticsCompany,
    currentStep,
    waybillItems,
    isGeneratingWaybill: generateWaybillMutation.isLoading,
    isValidatingWaybillItems: validateWaybillItemsMutation.isLoading,
    hasSelectedAnItem,
    setWaybillItems,
    setCurrentStep,
    setDriverName,
    setVehicleNumber,
    setDriverPhoneNumber,
    setDriverPhoneCode,
    setLogisticsCompany,
    setCustomerEmail,
    setCustomerName,
    setCustomerAddress,
    setCustomerPhoneNumber,
    setCustomerPhoneCode,
    handleGenerateWaybill,
    handleValidateWaybillItems,
  };
};
