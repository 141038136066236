import httpClient from "../axiosBase/axiosHandler";

export const fetchDashboard = (queries) => {
  const response = httpClient.get(`/dashboard/${queries}`);
  return response;
};

export const fetchAllCustomerAnalytics = (queries) => {
  const response = httpClient.get(`/customer-analytics/customers/${queries}`);
  return response;
};

export const fetchNewCustomerAnalytics = (queries) => {
  const response = httpClient.get(
    `/customer-analytics/new-customers/${queries}`,
  );
  return response;
};

export const fetchReturningCustomerAnalytics = (queries) => {
  const response = httpClient.get(
    `/customer-analytics/returning-customers/${queries}`,
  );
  return response;
};

export const fetchAVSUCustomerAnalytics = (queries) => {
  const response = httpClient.get(
    `/customer-analytics/average-spend/${queries}`,
  );
  return response;
};

export const fetchTopCustomers = (queries) => {
  const response = httpClient.get(
    `/customer-analytics/top-customers/${queries}`,
  );
  return response;
};

export const fetchCashFlowAnalytics = (queries) => {
  const response = httpClient.get(`/metrics/cashflow/${queries}`);
  return response;
};

export const fetchTagsFlowAnalytics = (queries) => {
  const response = httpClient.get(`/metrics/tags-metrics/${queries}`);
  return response;
};

export const getDashboardTutorials = () => {
  const response = httpClient.get("/tutorials/");
  return response;
};

export const getDashboardSetUpGuideCategories = () => {
  const response = httpClient.get("/setup-guide-categories/");
  return response;
};

export const setUpGuideMarkAsCompleted = (queries) => {
  const response = httpClient.post(
    `/setup-guide-categories/${queries}/mark-as-completed/`,
  );
  return response;
};
