import { nFormatter, setToDateMonthYearInWords } from "../../../helpers";

export const InventtoryProfitChartLogic = () => {
  const tickFormatter = (tick) => {
    return setToDateMonthYearInWords(tick);
  };

  const yAxisFormat = (tick) => {
    return nFormatter(tick);
  };

  const calcTotalProfit = (data) => {
    if (!data) return 0;
    return data.reduce((acc, cv) => {
      return (acc += cv?.count);
    }, 0);
  };

  return {
    tickFormatter,
    yAxisFormat,
    calcTotalProfit,
  };
};
