import { useTable } from "react-table";
import { useMemo } from "react";
import { convertToTilteCase, formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";

const useAllBankTransactionLogic = ({ banks, handleFetchbanks, type }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const columns = useMemo(
    () =>
      [
        {
          Header: "Date",
          accessor: "created_at",
          Cell: ({ cell: { value } }) =>
            value ? new Date(value).toLocaleString("en-us") : "N/A",
        },
        {
          Header: "Description",
          accessor: "description",
          Cell: ({ cell: { value } }) =>
            value ? convertToTilteCase(value) : "N/A",
        },
        {
          Header: "Amount",
          accessor: "amount",
          Cell: ({ cell: { value } }) =>
            formatCurrency({ value: value, currencyCode: currency_code }),
        },
        {
          Header: "Credit/Debit",
          accessor: "type",
          Cell: ({ cell: { value } }) =>
            value ? (
              <span
                className={value === "credit" ? "success--text" : "error--text"}
              >
                {" "}
                {convertToTilteCase(value)}
              </span>
            ) : (
              "N/A"
            ),
        },
        type === "cash"
          ? null
          : {
              Header: "Account Details",
              accessor: "ledger_account",
              Cell: ({ cell: { value } }) =>
                value ? value?.account_name : "N/A",
            },
        {
          Header: "Reference",
          accessor: "id",
          Cell: ({ cell: { value } }) => (value ? value : "N/A"),
        },
        type === "cash"
          ? {
              Header: "Payment Method",
              accessor: "payment_method",
              Cell: ({ cell: { value } }) => {
                if (value === "cash") {
                  return "Cash";
                }
                return "N/A";
              },
            }
          : {
              Header: "Payment Method",
              accessor: "payment_method",
              Cell: ({ cell: { value } }) =>
                value ? convertToTilteCase(value) : "N/A",
            },
        {
          Header: "Store",
          accessor: "store",
          Cell: ({ cell: { value } }) =>
            value ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    background: "#F2EDFE",
                    padding: "6px",
                    borderRadius: "8px",
                    fontSize: "10px",
                    alignItems: "center",
                  }}
                  className="gray--text font-bold"
                >
                  {value?.name}
                </span>
              </div>
            ) : (
              "N/A"
            ),
        },
      ].filter(Boolean),
    [handleFetchbanks],
  );

  const instance = useTable({ columns, data: banks });
  return {
    instance,
    banks,
    handleFetchbanks,
  };
};

export default useAllBankTransactionLogic;
