/* eslint-disable react/jsx-key */
import styles from "./ManageRoleTable.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import useManageRolesTable from "./useManageRolesTable";
import { useNavigate } from "react-router-dom";
import Icon from "../../../../../assets/icons";
import { useState } from "react";
import EditRoleModal from "../ManageRoles/EditRole";
import DeleteRoleWarning from "../ManageRoles/DeleteRoleWarning";
import { pathConstants } from "../../../../../routes/pathContants";

const ManageRolesTable = ({ roleType, rolesData, refetch }) => {
  const navigate = useNavigate();
  const [showEditRoleModal, setShowEditRoleModal] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(null);

  const { instance, isLoadingDelete, mutateDelete } = useManageRolesTable({
    rolesData,
    refetch,
    setShowDeleteWarning,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.roles_table}>
        {rolesData.length > 0 ? (
          <>
            {/* shows on desktop */}
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <th className="font-weight-semibold">S/N</th>
                    {headerGroup.headers.map((column) => (
                      <th
                        className="font-weight-semibold"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                    <th className="font-weight-semibold">
                      <span className="mr-5">Actions</span>
                    </th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps({ key: i })}>
                      <td>{i + 1}</td>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                      <td>
                        <div className={styles.actions_container}>
                          <primaryComponents.Button
                            classNames={`btn btn--primary smallBtn ${styles.viewBtn}`}
                            onClick={() =>
                              navigate(
                                pathConstants.SETTINGS_USERS_MANAGEROLES_DETAILS(
                                  { id: row.original.id },
                                ),
                              )
                            }
                          >
                            View
                          </primaryComponents.Button>
                          {roleType === "custom" && (
                            <>
                              <primaryComponents.Button
                                className={styles.editBtn}
                                onClick={() =>
                                  setShowEditRoleModal(row.original.id)
                                }
                              >
                                <img
                                  src={Icon.editWrite}
                                  alt=""
                                  width="16px"
                                  height="16px"
                                />
                              </primaryComponents.Button>
                              <primaryComponents.Button
                                classNames={"btn btn--outline-red smallBtn"}
                                onClick={() => setShowDeleteWarning(row)}
                              >
                                <img src={Icon.deleteIcon} alt="" />
                              </primaryComponents.Button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {/* display on mobile */}
            <div className={styles.role_mobile_table}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div
                    className={styles.role_mobile_table_row}
                    {...row.getRowProps({ key: i })}
                  >
                    {row.cells.map((cell, j) => (
                      <div
                        className={styles.role_mobile_table_item}
                        {...cell.getCellProps({ key: j })}
                      >
                        <h6 className={styles.title}>{cell.column.Header}</h6>
                        <p className={styles.value}>{cell.render("Cell")}</p>
                      </div>
                    ))}
                    {/*  action */}
                    <div className={styles.role_mobile_table_item}>
                      <primaryComponents.Button
                        classNames={`btn btn--primary smallBtn ${
                          roleType === "custom" ? "" : styles.viewMobileBtn
                        }`}
                        onClick={() =>
                          navigate(
                            pathConstants.SETTINGS_USERS_MANAGEROLES_DETAILS({
                              id: row.original.id,
                            }),
                          )
                        }
                      >
                        View
                      </primaryComponents.Button>
                      {/* <h6 className={styles.title}>Actions</h6> */}
                      {roleType === "custom" && (
                        <>
                          <div className={styles.actions_container}>
                            {/* edit icon */}
                            <primaryComponents.Button
                              className={styles.editBtn}
                              onClick={() =>
                                setShowEditRoleModal(row.original.id)
                              }
                            >
                              <img
                                src={Icon.editWrite}
                                alt=""
                                width="16px"
                                height="16px"
                              />
                            </primaryComponents.Button>
                            <primaryComponents.Button
                              classNames={"btn btn--outline-red smallBtn"}
                              onClick={() => setShowDeleteWarning(row)}
                            >
                              <img src={Icon.deleteIcon} alt="" />
                            </primaryComponents.Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          // shows if user is empty
          <div className="text-center mt-5">
            <h4>
              You have no {roleType === "default" ? "default" : "custom"} role
              yet
            </h4>
          </div>
        )}
      </div>

      {showEditRoleModal && (
        <EditRoleModal
          showEditRoleModal={showEditRoleModal}
          setShowEditRoleModal={setShowEditRoleModal}
        />
      )}

      {showDeleteWarning && (
        <DeleteRoleWarning
          showDeleteWarning={showDeleteWarning}
          setShowDeleteWarning={setShowDeleteWarning}
          mutateDelete={mutateDelete}
          isLoadingDelete={isLoadingDelete}
        />
      )}
    </>
  );
};

export default ManageRolesTable;
