import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const InviteSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    role: yup.string().required("Role is required"),
    businessId: yup
      .array()
      .required("Account is required")
      .min(1, "Account is required")
      .nullable(),
    storeId: yup.array().nullable(),
  })
  .required("All fields are required");

const InviteValidation = (email, role, businessId, storeId) => {
  return useForm({
    resolver: yupResolver(InviteSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      email,
      role,
      businessId,
      storeId,
    },
  });
};

export default InviteValidation;
