import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboardDetails: {
    five_most_recent_transactions: [],
    revenue_summary: [],
    business: {},
    total_revenue: 0,
    available_balance: 0,
    total_transaction_count: 0,
    verification_status: "unverified",
    total_revenue_trend: "-",
    total_transaction_count_trend: "-",
    pending_transfer_request: 0,
  },
};

export const dashboardSlice = createSlice({
  name: "dasboard",
  initialState,
  reducers: {
    setDashboardDetails: (state, action) => {
      state.dashboardDetails = action.payload;
    },
  },
});

export const { setDashboardDetails } = dashboardSlice.actions;
