export const InventoryIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 28 29"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9256 7.5H23.0738L25.6663 11.5833V15.6667H2.33301V11.5833L4.9256 7.5Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.667 15.6667H23.3337V23.8334C23.3337 24.4777 22.8113 25 22.167 25H12.8337C12.1893 25 11.667 24.4777 11.667 23.8334V15.6667Z"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66699 15.6667V25"
        stroke={strokeColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.1663 2.83331H5.83301"
        stroke={strokeColor}
        strokeWidth="2.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
