import React from "react";

export const TransferArrowIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8641 1.63574V11.5352M1.13611 14.3637L13.8641 1.63574L1.13611 14.3637ZM13.8641 1.63574L3.96452 1.63575L13.8641 1.63574Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
