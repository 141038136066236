import "./TransferSummary.scss";
import primaryComponents from "../../../primaryComponents";
import { formatCurrency } from "../../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FundCheckoutValidation from "./validation";
import { Controller } from "react-hook-form";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";

const TransferSummary = ({ fundTransferLogic, externalRequest }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const {
    transferDetails,
    handlePreviousStep,
    handleFundTransfer,
    isTranferringFunds,
    setTransferDetails,
  } = fundTransferLogic;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = FundCheckoutValidation();

  return (
    <motion.div
      initial={{ opacity: 0, marginTop: "100px" }}
      animate={{ opacity: 1, marginTop: "25px", transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
      className="transfer-summary"
      data-testid="transfer-summary"
    >
      <div className="d-flex align-items-center mb-4">
        <FontAwesomeIcon
          onClick={() => handlePreviousStep()}
          className="mb-2 mr-3"
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
        <h4>Confirmation</h4>
      </div>
      <p>Confirm the following details for your withdrawal</p>

      <div className="transfer-summary__details">
        <div className="withdrawal__amount">
          <p className="font-weight-semibold dark--text">
            You are withdrawing:
          </p>
          <h5 className="purple--text">
            {formatCurrency({
              value: transferDetails?.amount,
              currencyCode: currency_code,
            })}
          </h5>
        </div>
        <div className="withdrawal__amount">
          <p className="font-weight-semibold dark--text">Charges</p>
          <h5 className="purple--text">
            {formatCurrency({
              value: transferDetails?.transfer_charge,
              currencyCode: currency_code,
            })}
          </h5>
        </div>
        <div className="withdrawal__amount">
          <p className="font-weight-semibold dark--text">Total</p>
          <h5 className="purple--text">
            {formatCurrency({
              value:
                parseFloat(transferDetails?.amount) +
                parseFloat(transferDetails?.transfer_charge),
              currencyCode: currency_code,
            })}
          </h5>
        </div>
        <div className="beneficiary__details mt-4">
          <p className="font-weight-semibold dark--text mb-3">To:</p>
          <div className="beneficiary__details__items">
            <p className="beneficiary__details__item">
              <span>Bank:</span>
              <span>{transferDetails?.bank_name}</span>
            </p>
            <p className="beneficiary__details__item">
              <span>Account Number:</span>
              <span>{transferDetails?.account_number}</span>
            </p>
            <p className="beneficiary__details__item">
              <span>Beneficiary:</span>
              <span>{transferDetails?.account_name}</span>
            </p>
            <p className="beneficiary__details__item">
              <span>Description:</span>
              <span>{transferDetails?.narration}</span>
            </p>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(handleFundTransfer)}>
        <div className="mt-5">
          <Controller
            name="password"
            render={({ field: { value, onChange } }) => (
              <primaryComponents.PasswordInputField
                label="Password"
                isRequired={true}
                placeholder="Password"
                name="amount"
                onChange={({ target: { value } }) => {
                  onChange(value);
                  setTransferDetails((prev) => ({ ...prev, password: value }));
                }}
                errorMessage={errors.password}
              />
            )}
            control={control}
          />
        </div>
        <primaryComponents.Button
          isDisabled={isTranferringFunds || externalRequest?.isLoading}
          isLoading={isTranferringFunds || externalRequest?.isLoading}
          classNames={"btn btn--primary cover mt-3"}
          loadingText="Transfering..."
          type="submit"
        >
          Transfer
        </primaryComponents.Button>
      </form>
    </motion.div>
  );
};

export default TransferSummary;
