import { useState, useEffect } from "react";
import {
  useGetAccountingBankDetails,
  useGetAccountingBanksListWithSpreadFilter,
  useGetBankTransactionList,
} from "../../../../hooks/api/queries";
import { cleanObject, getUrlQuerysection } from "../../../../helpers";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const useAllTransaction = () => {
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [showAddDebit, setShowAddDebit] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [search, setSearch] = useSearchParams();

  const queries = getUrlQuerysection();
  const {
    isLoading: isLoadingBankTransaction,
    data: bankTransaction,
    refetch: handleFetchBankTransaction,
  } = useGetBankTransactionList({ queries });

  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: handleFetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter(
    cleanObject({ account_type: search.get("account_type") }),
  );

  const { isLoading: isFetchingBankInfo, data: bankInfo } =
    useGetAccountingBankDetails(selectedBank);

  useEffect(() => {
    if (bankAccount?.data?.length > 0 && !selectedBank) {
      setSelectedBank(bankAccount?.data[0]?.id);
    }
  });

  const filterByAccountType = (value) => {
    search.set("ledger_account_id", value);
    setSearch(search, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    storeList,
    bankTransaction: bankTransaction?.data,
    metaDetails: bankTransaction?.meta,
    showAddCredit,
    setShowAddCredit,
    isLoadingBankTransaction,
    handleFetchBankTransaction,
    showAddDebit,
    setShowAddDebit,
    bankInfo,
    isFetchingBankInfo,
    bankAccount,
    isLoadingBankAccountsList,
    setSelectedBank,
    handleFetchBankAccountsList,
    filterByAccountType,
    selectedBank,
    gotoPage,
  };
};

export default useAllTransaction;
