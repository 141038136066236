export const WarningIcon = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="28" height="28" rx="8" fill="#E02020" />
      <path
        d="M6.37905 19.5318L12.5722 8.28171C13.1461 7.23943 14.8539 7.23943 15.4278 8.28171L21.621 19.5318C22.1233 20.4444 21.3576 21.5 20.1932 21.5H7.80679C6.64239 21.5 5.87666 20.4444 6.37905 19.5318Z"
        fill="white"
        stroke="#FFDDD2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 12.1667V15.2778"
        stroke="#E02020"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 18.4041V18.3889"
        stroke="#E02020"
        strokeWidth="1.55556"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
