import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ToastContext } from "../../../hooks/context";
import { createPricingTypes } from "../../../services/inventory";

const useCreatePricingType = ({ closeWidget, pricingDetails, isEditMode }) => {
  const [pricingName, setPricingName] = useState(pricingDetails?.name || "");
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const pricingHandler = useMutation((data) => createPricingTypes(data));
  const handleAddPricing = () => {
    if (!isEditMode)
      pricingHandler.mutate(
        {
          label: pricingName,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["getPricingTypes"]);
            triggerToast("Pricing created successfully", "success");
            closeWidget();
          },
        },
      );
  };

  return {
    isCreatingPricing: pricingHandler?.isLoading,
    pricingName,
    setPricingName,
    handleAddPricing,
  };
};
export default useCreatePricingType;
