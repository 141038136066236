import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../hooks/context";
import { exportProducts } from "../../../services/inventory";

const useProductsExport = () => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [store, setStore] = useState([]);
  const [fileFormat, setFileFormat] = useState("excel");
  const triggerToast = useContext(ToastContext);

  const { isLoading: isExporting, mutate: exportProds } = useMutation((data) =>
    exportProducts(data),
  );
  const exportProductList = () => {
    exportProds(
      {
        store_id: store,
        download_format: fileFormat,
      },
      {
        onSuccess: (res) => {
          if (res?.data?.data?.url !== null)
            window.open(res?.data?.data?.url, "_blank");
          triggerToast(res?.data?.detail, "success");
        },
      },
    );
  };

  return {
    isExporting,
    store,
    storeList,
    fileFormat,
    setStore,
    exportProductList,
    setFileFormat,
  };
};
export default useProductsExport;
