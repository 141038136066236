import styles from "./ExistingClientConnect.module.scss";
import Logo from "../../../assets/logo";
import primaryComponents from "../../../components/primaryComponents";
import {
  CancelIcon,
  ConnectIcon,
  LumiColorIcon,
  NextArrowIcon,
  WarningIcon,
} from "../../../assets/svgIcons";
import Images from "../../../assets/images";
import secondaryComponents from "../../../components/secondaryComponents";
import { useNavigate, useParams } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";
import {
  useGetBusinessDetails,
  useGetProfileDetails,
} from "../../../hooks/api/queries/useSettings";
import { useMutation } from "react-query";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../hooks/context";
import {
  createConnection,
  validateClientInfo,
} from "../../../services/settings";

export const ExistingClientConnect = () => {
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);
  const { token } = useParams();
  const { isLoading: isLoadingProfile, data: profileInfo } =
    useGetProfileDetails({ isProfileEnabled: true });
  const { isLoading: isLoadingBusiness, data: businessInfo } =
    useGetBusinessDetails();
  const [clientInfo, setClientInfo] = useState({});

  const validateClientInfoMutation = useMutation({
    mutationFn: (data) => validateClientInfo(data),
    onSuccess: (res) => {
      setClientInfo(res?.data?.data?.client);
    },
  });

  const connectMutation = useMutation((data) => createConnection(data));

  useEffect(() => {
    validateClientInfoMutation.mutate({ token });
  }, []);

  const handleConnection = () => {
    connectMutation.mutate(
      { token },
      {
        onSuccess: () => {
          triggerToast(
            `You have succefully connected your Kuda account to ${businessInfo?.business_name}`,
            "success",
          );
          navigate(pathConstants.DASHBOARD_MAIN);
        },
      },
    );
  };

  return (
    <div className={styles.existingClientConnect}>
      <img
        className={styles.existingClientConnect__design}
        src={Images.enlumi_arrows}
        alt=""
      />

      <div className={styles.existingClientConnect__content}>
        <div className={styles.existingClientConnect__content__header}>
          <img
            className="mx-auto"
            width={48}
            height={16}
            src={Logo.lumiWhiteLogo}
            alt="Lumi"
          />
        </div>
        <secondaryComponents.LoaderHelper
          classNames={"mt-4"}
          isLoading={
            isLoadingProfile ||
            isLoadingBusiness ||
            validateClientInfoMutation?.isLoading
          }
        >
          <div>
            <div className="d-flex align-items-center" style={{ gap: "7px" }}>
              {clientInfo?.client_logo ? (
                <img
                  src={clientInfo?.client_logo}
                  alt="client logo"
                  width={56}
                />
              ) : (
                <h5 className="mb-0">{clientInfo?.client_name}</h5>
              )}
              <ConnectIcon />
              <LumiColorIcon />
            </div>
            <h5 className="mt-3">
              Connect {clientInfo?.client_name} with{" "}
              {businessInfo?.business_name}
            </h5>
          </div>

          <div className={styles.businessDetails}>
            <div className={styles.businessDetails__item}>
              <p>Business Name:</p>
              <h5 className="mb-0">{businessInfo?.business_name}</h5>
            </div>
            <div className={styles.businessDetails__item}>
              <p>Email:</p>
              <h5 className="mb-0">{profileInfo?.email}</h5>
            </div>
          </div>

          <div className="text-center mx-auto" style={{ maxWidth: "400px" }}>
            <WarningIcon />
            <p>
              You are about to connect your{" "}
              <span className="font-weight-bold">
                {clientInfo?.client_name} Account
              </span>{" "}
              with your{" "}
              <span className="font-weight-bold">
                {businessInfo?.business_name} Account
              </span>
            </p>
          </div>

          <div className={styles.existingClientConnect__content__bottom}>
            <primaryComponents.Button
              classNames={"btn btn--outline w-100"}
              onClick={() => navigate(pathConstants.DASHBOARD_MAIN)}
            >
              <CancelIcon />
              <span className="ml-2">Cancel</span>
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames={"btn btn--primary w-100"}
              onClick={() => handleConnection()}
              isDisabled={connectMutation?.isLoading}
              isLoading={connectMutation?.isLoading}
              //   loadingText={"Connecting..."}
            >
              <NextArrowIcon />
              <span className="ml-2">Connect</span>
            </primaryComponents.Button>
          </div>
        </secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};
