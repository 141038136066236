import { Navigate } from "react-router-dom";
import useGetPermission from "../../../hooks/useGetPermission";

const RoutePermissionRedirector = ({
  children,
  permissionParentKey,
  permissionChildKey,
  redirectPath = null,
}) => {
  const { isPermitted } = useGetPermission({
    permissionParentKey,
    permissionChildKey,
  });

  return isPermitted ? (
    children
  ) : !isPermitted && redirectPath ? (
    <Navigate to={redirectPath} replace />
  ) : (
    <>
      <h4 className="mt-5 text-center">
        You are not permitted to view this resources
      </h4>
    </>
  );
};
export default RoutePermissionRedirector;
