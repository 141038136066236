import { useState, useContext } from "react";
import { changeSettlementType } from "../../../../services/settlements";
import { ToastContext, RequestLoader } from "../../../../hooks/context";
import { useSelector } from "react-redux";

const SettlementsLogic = () => {
  const { settlement_type } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { verification_status } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails,
  );
  const [isOpenSettlementForm, setIsOpenSettlementForm] = useState(false);
  const [settlementOption, setSettlementOption] = useState(
    settlement_type ? settlement_type : "",
  );
  const [hasAccount, setHasAccount] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  const handleSettlementType = (settlement_type) => {
    if (!settlement_type) return;
    setRequestLoaderProgress(50);

    changeSettlementType(settlement_type)
      .then(() => {
        setRequestLoaderProgress(100);
        triggerToast("Settlement type updated successfully", "success");
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
      });
  };

  return {
    settlementOption,
    isOpenSettlementForm,
    hasAccount,
    verification_status,
    setSettlementOption,
    setIsOpenSettlementForm,
    handleSettlementType,
    setHasAccount,
  };
};

export default SettlementsLogic;
