import styles from "./Transaction.module.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { AddIcon } from "../../../../assets/svgIcons";
import useAllTransaction from "./useTransaction";
import AddCreditToBankAccount from "../../../../components/secondaryComponents/AddCreditToBankAccount";
import AddDebitToBankAccount from "../../../../components/secondaryComponents/AddDebitToBankAccount";
import { formatCurrency } from "../../../../helpers";
import { useSelector } from "react-redux";
const AccountingTransactions = () => {
  const {
    bankTransaction,
    showAddCredit,
    setShowAddCredit,
    isLoadingBankTransaction,
    handleFetchBankTransaction,
    showAddDebit,
    setShowAddDebit,
    bankAccount,
    isLoadingBankAccountsList,
    bankInfo,
    setSelectedBank,
    isFetchingBankInfo,
    filterByAccountType,
    metaDetails,
    gotoPage,
  } = useAllTransaction();
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Bank Accountings Transaction"
      description="View all bank accountings Transaction"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={` ${globalStyles.pageColumnGaps}`}
        >
          <div className="mt-2">
            <h4 className="font-weight-semibold">Transactions</h4>
            <p className="default-text-size">
              Initiate debit & credit transactions
            </p>
          </div>

          <div className={`${styles.card} mt-3`}>
            <div className="d-flex flex-wrap justify-content-between w-100">
              <div className="w-50">
                <p className={`${styles.title} smallest-text-size p-1 mb-2`}>
                  {"Available Balance"}
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={isFetchingBankInfo}
                  classNames="mt-5"
                >
                  <h5>
                    {formatCurrency({
                      value: bankInfo?.balance || "0",
                      currencyCode: currency_code,
                    })}
                  </h5>{" "}
                </secondaryComponents.LoaderHelper>
              </div>
              <secondaryComponents.LoaderHelper
                isLoading={isLoadingBankAccountsList}
                classNames="mt-1"
              >
                <div style={{ width: "200px" }}>
                  <primaryComponents.SelectField
                    options={bankAccount?.data.map((bank) => ({
                      id: bank?.id,
                      name: bank?.account_name,
                    }))}
                    idKey="id"
                    nameKey="name"
                    value={
                      bankAccount
                        ? bankAccount?.data[0]?.account_name
                        : "Select Bank"
                    }
                    onChange={(value) => {
                      setSelectedBank(value);
                      filterByAccountType(value);
                    }}
                    floatError={false}
                    classNames="bank-name"
                    isSearchable={false}
                  />
                </div>
              </secondaryComponents.LoaderHelper>
            </div>
            <div className={styles.cards_container}>
              <div>
                <p className={`${styles.title} smallest-text-size p-1`}>
                  Bank Name
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={isFetchingBankInfo}
                >
                  <h6 className="default-text-size">
                    {bankInfo?.bank_name || "N/A"}
                  </h6>
                </secondaryComponents.LoaderHelper>
              </div>
              <div>
                <p className={`${styles.title} smallest-text-size p-1`}>
                  Bank Account Name
                </p>
                <secondaryComponents.LoaderHelper
                  isLoading={isFetchingBankInfo}
                >
                  <h6>{bankInfo?.account_name || "N/A"}</h6>
                </secondaryComponents.LoaderHelper>
              </div>
              <div>
                <p className={`${styles.title} smallest-text-size p-1`}>
                  Account Number
                </p>
                <h6>{bankInfo?.account_number || "N/A"}</h6>
              </div>
            </div>
          </div>
          {/* table session */}
          <div className={styles.tableBody}>
            <div
              className={`m-1 d-flex justify-content-end ${styles.tableBody__header}`}
            >
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => setShowAddCredit(true)}
              >
                <div className="d-flex align-items-center">
                  <AddIcon color="#7647ee" />
                  <span className="default-text-size pl-2">Add Credit</span>
                </div>
              </primaryComponents.Button>

              <primaryComponents.Button
                classNames="btn btn--primary"
                onClick={() => setShowAddDebit(true)}
              >
                <div className="d-flex align-items-center">
                  <AddIcon />
                  <span className="default-text-size pl-2">Add Debit</span>
                </div>
              </primaryComponents.Button>
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isLoadingBankTransaction}
              classNames={"mt-5"}
            >
              <secondaryComponents.AllBankTransactionTable
                banks={bankTransaction}
                handleFetchbanks={handleFetchBankTransaction}
                gotoPage={gotoPage}
                metaDetails={metaDetails}
              />
            </secondaryComponents.LoaderHelper>

            <secondaryComponents.SideModal
              isActive={showAddCredit}
              closeModal={() => setShowAddCredit(false)}
              width={500}
            >
              <AddCreditToBankAccount
                closeWidget={() => setShowAddCredit(false)}
                isLoading={isLoadingBankTransaction}
                handleFetchBanks={handleFetchBankTransaction}
              />
            </secondaryComponents.SideModal>

            {/* debit */}
            <secondaryComponents.SideModal
              isActive={showAddDebit}
              closeModal={() => setShowAddDebit(false)}
              width={500}
            >
              <AddDebitToBankAccount
                closeWidget={() => setShowAddDebit(false)}
                isLoading={isLoadingBankTransaction}
                handleFetchBanks={handleFetchBankTransaction}
              />
            </secondaryComponents.SideModal>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default AccountingTransactions;
