import primaryComponents from "../../primaryComponents";
import Validation from "./Validation";
import useRiderForm from "./useRiderForm";

const RidersForm = ({
  isEditMode = false,
  closeModal,
  riderInfo,
  handleFetchRiders = () => {},
}) => {
  const { riderDetails, isLoading, setRiderDetails, handleRiderSubmit } =
    useRiderForm({
      isEditMode,
      riderInfo,
      handleFetchRiders,
      closeModal,
    });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ riderDetails });

  return (
    <form className="p-3" onSubmit={handleSubmit(handleRiderSubmit)}>
      <h5 className="text-center mb-4">Add new Dispatch Riders</h5>

      <primaryComponents.InputFieldV2
        control={control}
        classNames="white"
        name="name"
        label="Rider Name"
        placeholder="Enter your rider name"
        isRequired={true}
        value={riderDetails.name}
        onChange={({ target: { value } }) => {
          setRiderDetails((prev) => ({
            ...prev,
            name: value,
          }));
        }}
        errorMessage={errors.name}
        onKeyUp={() => errors.name !== undefined && trigger("name")}
        register={register}
      />
      <primaryComponents.PhoneNumberInputFieldV2
        control={control}
        classNames="white"
        name="phone_number"
        label="Rider Phone Number"
        placeholder="Enter your rider phone"
        isRequired={true}
        value={riderDetails.phone_number}
        onChange={({ target: { value } }) => {
          setRiderDetails((prev) => ({
            ...prev,
            phone_number: value,
          }));
        }}
        onChangePhoneCode={(val) => {
          setRiderDetails((prev) => ({
            ...prev,
            phone_code: val,
          }));
        }}
        countryCode={riderDetails?.phone_code}
        phoneCodeEditable={true}
        errorMessage={errors.phone_number}
        onKeyUp={() =>
          errors.phone_number !== undefined && trigger("phone_number")
        }
        register={register}
      />
      <primaryComponents.InputFieldV2
        control={control}
        classNames="white"
        name="email"
        label="Rider Email"
        placeholder="Enter your rider email"
        isRequired={true}
        value={riderDetails.email}
        onChange={({ target: { value } }) => {
          setRiderDetails((prev) => ({
            ...prev,
            email: value,
          }));
        }}
        errorMessage={errors.email}
        onKeyUp={() => errors.email !== undefined && trigger("email")}
        register={register}
      />

      <div
        className="d-flex justify-content-between mt-3"
        style={{ gap: "10px" }}
      >
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          type="submit"
          isDisabled={isLoading}
          isLoading={isLoading}
          loadingText={isEditMode ? "Updating..." : "Adding..."}
        >
          {isEditMode ? "Update" : "Add"} Dispatch Rider
        </primaryComponents.Button>
      </div>
    </form>
  );
};
export default RidersForm;
