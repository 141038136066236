import { useMutation, useQueryClient } from "react-query";
import {
  activateDeliveryCheckout,
  activateInstoreCheckout,
  activateStorefrontPayment,
} from "../../../../../../../../../services/inventory";
import { useContext } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams, useSearchParams } from "react-router-dom";

export const useCheckout = () => {
  const { menuId } = useParams();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const menu_id = searchParams.get("menu_id");

  // useEffect(() => {
  //   queryClient.invalidateQueries(["getMenusDetails"]);
  // }, [queryClient]);

  const { isLoading: isLoadingActivate, mutate: mutateActivate } = useMutation({
    mutationFn: () => activateStorefrontPayment(menuId ? menuId : menu_id),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      queryClient.invalidateQueries(["getMenusDetails"]);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const {
    isLoading: isLoadingActivateInstoreCheckout,
    mutate: mutateActivateInstoreCheckout,
  } = useMutation({
    mutationFn: () => activateInstoreCheckout(menuId ? menuId : menu_id),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      queryClient.invalidateQueries(["getMenusDetails"]);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const {
    isLoading: isLoadingActivateDeliveryCheckout,
    mutate: mutateActivateDeliveryCheckout,
  } = useMutation({
    mutationFn: () => activateDeliveryCheckout(menuId ? menuId : menu_id),
    onSuccess: (data) => {
      triggerToast(data.data.detail, "success");
      queryClient.invalidateQueries(["getMenusDetails"]);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  return {
    isLoadingActivate,
    isLoadingActivateInstoreCheckout,
    isLoadingActivateDeliveryCheckout,
    mutateActivate,
    mutateActivateInstoreCheckout,
    mutateActivateDeliveryCheckout,
  };
};
