import styles from "./AddOrderTableForm.module.scss";
import primaryComponents from "../../primaryComponents";
import { useAddOrderTable } from "./useAddOrderTable";
import { useAddOrderTableValidation } from "./useAddOrderTableValidation";
import { Controller } from "react-hook-form";

const AddOrderTableForm = ({ closeModal, handleFetchOrderTables }) => {
  const {
    formData,
    setFormData,
    isAddingOrderTable,
    handleAddOrderTable,
    storeList,
  } = useAddOrderTable({ closeModal, handleFetchOrderTables });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    trigger,
  } = useAddOrderTableValidation(formData);

  return (
    <div className={styles.addTableForm}>
      <h4 className="text-center">Add new Table</h4>
      <form
        onSubmit={handleSubmit(handleAddOrderTable)}
        className={styles.addTableForm__form}
      >
        <Controller
          name="name"
          control={control}
          defaultValue={formData.name}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              classNames="white"
              label="Table Label"
              name="name"
              isRequired
              placeholder={"Enter table number or Name"}
              errorMessage={errors.name}
              onKeyUp={() => errors.name !== undefined && trigger("name")}
              onChange={({ target: { value } }) => {
                onChange(value);
                setFormData((prev) => ({ ...prev, name: value }));
              }}
              register={register}
            />
          )}
        />
        <Controller
          control={control}
          name="store"
          defaultValue={formData.store}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Select Store"
              isRequired
              name="store"
              placeholder="Select store to make this table available at"
              errorMessage={errors.store}
              options={storeList}
              idKey={"id"}
              nameKey={"name"}
              onChange={(value) => {
                onChange(value);
                setFormData((prev) => ({ ...prev, store: value }));
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="seat_capacity"
          defaultValue={formData.seat_capacity}
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Table Capacity"
              isRequired
              name="seat_capacity"
              placeholder="Select table capacity"
              errorMessage={errors.seat_capacity}
              options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
              idKey={"id"}
              nameKey={"seat_capacity"}
              onChange={(value) => {
                onChange(value);
                setFormData((prev) => ({ ...prev, seat_capacity: value }));
              }}
            />
          )}
        />
        <div
          style={{
            marginTop: "64px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <primaryComponents.Button
            type="button"
            classNames={"btn btn--outline"}
            onClick={closeModal}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            isLoading={isAddingOrderTable}
            loadingText={"Adding Table..."}
            isDisabled={isAddingOrderTable}
            classNames={"btn btn--primary"}
          >
            Add new table
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};

export default AddOrderTableForm;
