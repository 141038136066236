import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useRootProperties from "../../hooks/useRootProperties";
import useScanDetection from "use-scan-detection";
import { getPlatform } from "../../helpers";
import { platformHandler } from "../../hooks/context";
import usePendingCheckoutSession from "../../hooks/usePendingCheckoutSession";
import { useGetCategoriesLinkedToProduct } from "../../hooks/api/queries/useInventory";

const useSellModeLayout = () => {
  const { isLoading } = useRootProperties();
  const queryParams = new URLSearchParams(window.location.search);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { tableDetails } = useSelector((state) => state.tableReducer);
  const [tableCardId, setTableCardId] = useState(null);
  const [menuItems, setMenuItems] = useState({
    isOpenSellModeMenu: false,
    isOpenBillsWidget: false,
    isOpenSalesWidget: false,
    isOpenKitchensWidget: false,
    isOpenReturnWidget: false,
    isOpenEndOfDayWidget: false,
    isTable: false,
    showPendingSessions: false,
  });
  const [inventoryType, setInventoryType] = useState(
    queryParams.get("type") === "goods"
      ? "Products"
      : queryParams.get("type") === "services"
        ? "Services"
        : "All",
  );
  const [search, setSearch] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    queryParams.get("query") !== null ? [queryParams.get("query")] : "",
  );
  const [categoryId, setCategoryId] = useState(
    queryParams.get("category_id") !== null
      ? [queryParams.get("category_id")]
      : null,
  );
  const { businessDetails } = useSelector(
    (state) => state.profileDetailsReducer,
  );
  const { store_id } = useParams();
  const [storeName, setStoreName] = useState("");
  const productContainer = useRef();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(
    businessDetails?.is_subscribed,
  );
  const { metaDetails: pendingMetaDetails } = usePendingCheckoutSession();
  const { data: categories } = useGetCategoriesLinkedToProduct({
    queries: `?store_id=${store_id}`,
  });

  useEffect(() => {
    if (!isLoading) {
      if (storeList?.length === 0)
        return navigate(getPlatform(platform) + "/inventory/stores");
      else {
        const name = storeList?.find((item) => `${item.id}` === store_id)?.name;
        setStoreName(name);
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (search.get("query")) setSearchValue(search.get("query"));
    else setSearchValue("");
  }, [search.get("query")]);

  useEffect(() => {
    setIsSubscriptionModal(!businessDetails?.is_subscribed);
  }, [businessDetails?.is_subscribed]);

  const handleScanBar = (value) => {
    handleSearchProducts(value, true);
  };

  const handleSearchProducts = (value, isScanBar = false) => {
    search?.forEach((value, key) => {
      search.delete(key);
    });
    if (value?.length !== 0) {
      search.set("query", value);
      search.set("is_scan", isScanBar);
    } else {
      search.delete("query");
      search.delete("is_scan");
    }
    setCategoryId(null);
    setSearch(search, { replace: true });
  };

  const selectCategory = (id) => {
    if (id === null) search.delete("category_id");
    else search.set("category_id", id);
    search.delete("page");
    setSearch(search, { replace: true });
    setCategoryId(id);
  };

  const filterByInventory = (value) => {
    if (value === "Services") search.set("type", "services");
    else if (value === "Products") search.set("type", "goods");
    else search.set("type", "all");
    search.delete("page");
    setSearch(search, { replace: true });
  };

  useScanDetection({
    onComplete: handleScanBar,
    minLength: 8,
  });

  const resetView = (key, value = true) => {
    setMenuItems({
      isOpenSellModeMenu: false,
      isOpenBillsWidget: false,
      isOpenSalesWidget: false,
      isOpenKitchensWidget: false,
      isOpenReturnWidget: false,
      isOpenEndOfDayWidget: false,
      isOpenStorefrontWidget: false,
      isTable: false,
      showPendingSessions: false,
      [key]: value,
    });
  };

  return {
    tableCardId,
    tableDetails,
    isLoading,
    categoryId,
    categories: categories || [],
    business_name: businessDetails?.business_name,
    searchValue,
    productContainer,
    inventoryType,
    storeName,
    isSubscriptionModal,
    pendingMetaDetails,
    menuItems,
    setMenuItems,
    resetView,
    setInventoryType,
    handleSearchProducts,
    selectCategory,
    filterByInventory,
    setSearchValue,
    setIsSubscriptionModal,
    setTableCardId,
  };
};

export default useSellModeLayout;
