export const LumiColorIcon = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="56" height="56" rx="11.5" fill="white" />
      <rect x="0.5" y="0.5" width="56" height="56" rx="11.5" stroke="#7647EE" />
      <path
        d="M29.6698 29.6742L22.4493 30.1769L22.1512 26.1905L18.3152 30.4651L13.2089 30.8207L12.8401 25.8975L16.6761 21.6228L12.542 21.9103L12.0206 14.9463L28.4822 13.8005L29.6698 29.6742ZM24.475 27.9182L27.3288 27.7194L26.4558 16.0592L14.3615 16.9011L14.5677 19.6516L21.7917 19.1488L15.0891 26.6169L15.2346 28.5613L17.2517 28.421L23.9536 20.9522L24.475 27.9182Z"
        fill="#FF5500"
      />
      <path
        d="M44.9797 42.4452L37.7593 42.948L37.4611 38.9615L33.6252 43.2362L28.5189 43.5918L28.1501 38.6685L31.9861 34.3939L27.852 34.6814L27.3306 27.7181L43.7921 26.5722L44.9797 42.4452ZM39.7843 40.6893L42.6381 40.4905L41.765 28.8303L29.6708 29.6721L29.8769 32.4226L37.1009 31.9199L30.3983 39.388L30.5438 41.3324L32.561 41.1921L39.2629 33.7233L39.7843 40.6893Z"
        fill="#FF5500"
      />
      <path
        d="M26.6499 47.957L19.4295 48.4597L19.1313 44.4733L15.2954 48.7479L10.1891 49.1035L9.82029 44.1803L13.6563 39.9056L9.52214 40.1931L9.00003 33.2299L25.4616 32.084L26.6499 47.957ZM21.4545 46.201L24.3083 46.0022L23.4352 34.342L11.341 35.1839L11.5471 37.9344L18.7711 37.4316L12.0685 44.9004L12.214 46.8448L14.2312 46.7045L20.9331 39.2357L21.4545 46.201Z"
        fill="#F7AD3C"
      />
      <path
        d="M48 24.1625L40.7796 24.6653L40.4814 20.6788L36.6455 24.9535L31.5392 25.3084L31.1704 20.3851L35.0064 16.1104L30.8723 16.3979L30.3509 9.43469L46.8117 8.28882L48 24.1625ZM42.8053 22.4065L45.6591 22.2078L44.786 10.5475L32.6918 11.3894L32.8979 14.1399L40.122 13.6371L33.4201 21.1059L33.5656 23.0503L35.5827 22.91L42.2846 15.4412L42.8053 22.4065Z"
        fill="#F7AD3C"
      />
    </svg>
  );
};
