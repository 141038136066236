import { useState, useContext } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { getAllRoles, updateUserInvite } from "../../../services/settings";
import { getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

const EditInviteUserLogic = ({
  setShowInviteModal,
  userEmail,
  userRole,
  fetchUsersList,
  subAccountList,
  storeAccessList,
}) => {
  const [email, setEmail] = useState(userEmail);
  const [role, setRole] = useState("");
  const [isInviting, setIsInviting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { profileDetails, storeList } = useSelector(
    (state) => state.profileDetailsReducer,
  );
  const [businessId, setBusinessId] = useState([]);
  const [storeId, setStoreId] = useState([]);
  const [defaultBusinesses] = useState(() => {
    let newArray = [];
    subAccountList?.forEach((item) => {
      let businessName = profileDetails?.business_list.find(
        (business) => Number(business?.id) === item,
      );
      newArray.push({ value: businessName?.id, label: businessName?.tag });
    });
    return newArray;
  });

  const [defaultStores] = useState(() => {
    let newArray = [];
    storeAccessList?.forEach((item) => {
      let store = storeList.find((store) => Number(store?.id) === item);
      newArray.push({ value: store?.id, label: store?.name });
    });
    return newArray;
  });

  const { data: dataRoles } = useQuery(["roles"], () =>
    getAllRoles().then((res) => res.data?.data),
  );

  const resetForm = () => {
    setEmail("");
    window.resetInviteForm({ email: "" });
    setRole("");
    window.resetInviteForm({ role: "" });
    window.clearInviteError();
  };

  const getDefaultBusinesses = () => {
    let newArray = [];
    defaultBusinesses?.forEach((item) => {
      newArray.push(item.value);
    });
    return newArray;
  };

  const getDefaultStores = () => {
    let newArray = [];
    defaultStores?.forEach((item) => {
      newArray.push(item.value);
    });
    return newArray;
  };

  const updateUser = () => {
    setIsInviting(true);
    setErrorMessage(null);
    setRequestLoaderProgress(getRandomIntInclusive(12, 40));
    updateUserInvite({
      email: email,
      role_id: role
        ? role
        : dataRoles?.find((item) => item.label === userRole)?.id,
      businesses_id:
        businessId.length !== 0 ? businessId : getDefaultBusinesses(),
      store_id:
        storeId?.length > 0
          ? storeId
          : getDefaultStores()?.length > 0
            ? getDefaultStores()
            : undefined,
    })
      .then(() => {
        setIsInviting(false);
        triggerToast("User permission updated successfully", "success");
        setRequestLoaderProgress(100);
        resetForm();
        fetchUsersList();
        setShowInviteModal(false);
      })
      .catch((error) => {
        setIsInviting(false);
        setRequestLoaderProgress(100);
        if (error?.response?.status === 400)
          setErrorMessage(error?.response?.data?.detail);
      });
  };

  return {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    updateUser,
    resetForm,
    userRoles: dataRoles
      ?.filter((item) => item.label !== "BUSINESS MANAGER")
      ?.map((item) => ({
        ...item,
        label:
          item.label.charAt(0).toUpperCase() +
          item.label.slice(1).toLowerCase(),
      })),
    subAccounts: profileDetails?.business_list,
    businessId,
    setBusinessId,
    defaultBusinesses,
    storeList,
    storeId,
    setStoreId,
    defaultStores,
  };
};
export default EditInviteUserLogic;
