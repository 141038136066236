import styles from "./RiderOrders.module.scss";
import primaryComponents from "../../../components/primaryComponents";
import Logo from "../../../assets/logo";
import { NoteIcon } from "../../../assets/svgIcons";
import { useGetRiderOrders } from "../../../hooks/api/queries/useOrder";
import secondaryComponents from "../../../components/secondaryComponents";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";
import { getUrlQuerysection } from "../../../helpers";
import { useEffect } from "react";

const orderStatus = [
  {
    name: "All",
    id: "ALL",
  },
  { name: "NEW", id: "PLACED|SHIPPED|IN_PROGRESS|PENDING" },
  { name: "Delivered", id: "DELIVERED" },
  { name: "Completed", id: "COMPLETED" },
  { name: "Returned", id: "RETURNED" },
  { name: "Canceled", id: "CANCELED" },
];
const AllRiderOrders = () => {
  const navigate = useNavigate();
  const parmas = useParams();
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const {
    isLoading: isFetchingRiderOrders,
    isFetchingNextPage,
    autoScrollRef,
    data: riderOrdersList,
  } = useGetRiderOrders({ queries });

  const filterByStatus = (value) => {
    if (value !== "ALL") search.set("status", value);
    else search.delete("status");
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (!search.get("dispatch_rider_id"))
      search.set("dispatch_rider_id", parmas?.id);

    if (!search.get("status"))
      search.set("status", "PLACED|SHIPPED|IN_PROGRESS|PENDING");

    setSearch(search, { replace: true });
  }, []);

  return (
    <div className={styles.riderOrders} ref={autoScrollRef}>
      <div className={styles.riderListHeader}>
        <img
          className="ml-auto"
          src={Logo.lumiLogo}
          alt="lumi"
          width={100}
          height={40}
        />
        <div className="d-flex justify-content-between" style={{ gap: "10px" }}>
          <div>
            <h6 className="mb-0">
              {riderOrdersList?.[0]?.dispatch_rider?.name}
            </h6>
            <p className="error--text">Please Drop-off Assigned Orders</p>
          </div>

          <div style={{ width: "150px" }}>
            <primaryComponents.SelectField
              options={orderStatus}
              idKey={"id"}
              nameKey={"name"}
              value={
                orderStatus?.find((item) => item?.id === search.get("status"))
                  ?.name || "NEW"
              }
              onChange={(value) => filterByStatus(value)}
              floatError={false}
            />
          </div>
        </div>
      </div>
      <div className={styles.riderList}>
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingRiderOrders}
          classNames={"mt-4"}
        >
          {riderOrdersList?.length > 0 ? (
            <>
              {" "}
              {riderOrdersList?.map((order) => (
                <div className={styles.riderList__item} key={order?.id}>
                  <div
                    className="d-flex justify-content-between"
                    style={{ gap: "5px" }}
                  >
                    <h4 className="mb-0">{order?.id}</h4>
                    <p
                      className={
                        order?.status === "COMPLETED"
                          ? "success--text"
                          : order?.status === "RETURNED"
                            ? "pending--text"
                            : order?.status === "CANCELED"
                              ? "error--text"
                              : "purple--text"
                      }
                    >
                      {order?.status === "COMPLETED"
                        ? "Delivered"
                        : order?.status === "RETURNED"
                          ? "Returned"
                          : order?.status === "CANCELED"
                            ? "Canceled"
                            : "New"}
                    </p>
                  </div>
                  <p className="small-text-size">Drop-off address:</p>
                  <p className="dark--text">
                    {order?.delivery_address?.address}
                    {order?.delivery_address?.city
                      ? `, ${order?.delivery_address?.city}`
                      : ""}
                    {order?.delivery_address?.state
                      ? `, ${order?.delivery_address?.state}`
                      : ""}
                    {order?.delivery_address?.country
                      ? `, ${order?.delivery_address?.country}`
                      : ""}
                  </p>
                  <primaryComponents.Button
                    classNames={"btn btn--primary cover"}
                    style={{ height: "40px" }}
                    onClick={() =>
                      navigate(
                        pathConstants.INVENTORY_STOREFRONT_ORDER_DETAILS({
                          id: order?.id,
                        }),
                      )
                    }
                  >
                    <NoteIcon stroke="#fff" />
                    <span className="ml-2">View Details</span>
                  </primaryComponents.Button>
                </div>
              ))}
            </>
          ) : (
            <h5 className="text-center px-2">
              No available orders assigned to this rider
            </h5>
          )}
        </secondaryComponents.LoaderHelper>
        <secondaryComponents.LoaderHelper
          isLoading={!isFetchingRiderOrders && isFetchingNextPage}
          classNames={"mt-4"}
        ></secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};
export default AllRiderOrders;
