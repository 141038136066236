import secondaryComponents from "..";
import useCustomersTable from "./CustomersTableLogic";
import Images from "../../../assets/images";

const CustomersTable = ({ customers, metaDetails, gotoPage, refetch }) => {
  const {
    instance,
    isCustomerProperties,
    setIsCustomerProperties,
    customerDetails,
    isCustomerDeleteProperties,
    isFetchingCustomerDetails,
    setIsCustomerDeleteProperties,
  } = useCustomersTable({ customers });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;
  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <img
            src={Images.customersEmptyState}
            alt="No Customers Found"
            width="250px"
            height="262px"
          />
          <h4>No data available</h4>
        </div>
      </secondaryComponents.TableHelper>

      {/* edit customer modal section */}
      <secondaryComponents.Modal
        isActive={isCustomerProperties !== null && !isFetchingCustomerDetails}
        closeModal={() => {
          setIsCustomerProperties(null);
        }}
        width={650}
      >
        <secondaryComponents.CustomerForm
          customerInfo={customerDetails}
          closeWidget={() => {
            setIsCustomerProperties(null);
          }}
          isLoading={isFetchingCustomerDetails}
          isEditMode={true}
          handleFetchCustomers={refetch}
        />
      </secondaryComponents.Modal>

      {/* delete modal */}
      <secondaryComponents.Modal
        isActive={isCustomerDeleteProperties !== null}
        closeModal={() => setIsCustomerDeleteProperties(null)}
        width={500}
      >
        <secondaryComponents.CustomerDeletion
          closeWidget={() => setIsCustomerDeleteProperties(null)}
          customerDetails={isCustomerDeleteProperties}
          handleFetchCustomers={refetch}
        />
      </secondaryComponents.Modal>

      {/* show pagination component only when data is available */}
      {customers?.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={metaDetails.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails.current_page}
        />
      )}
    </div>
  );
};

export default CustomersTable;
