import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CloseIcon = ({ onClick, classNames }) => {
  return (
    <FontAwesomeIcon
      icon={["fas", "times"]}
      className={classNames}
      style={{ cursor: "pointer", fontSize: "20px" }}
      onClick={onClick}
    />
  );
};
