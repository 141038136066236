import secondaryComponents from "..";
import BeneficiariesTableLogic from "./BeneficiariesTableLogic";
import Images from "../../../assets/images";

const BeneficiariesTable = ({ beneficiaries }) => {
  const {
    instance,
    showDeleteBeneficiaryModal,
    setShowDeleteBeneficiaryModal,
    fetchBeneficiaries,
    currentBeneficiary,
  } = BeneficiariesTableLogic({ beneficiaries });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <img
            src={Images.customersEmptyState}
            alt="No Customers Found"
            width="250px"
            height="262px"
          />
          <h4>No data available</h4>
          <p>When you have beneficiaries, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>

      {showDeleteBeneficiaryModal && (
        <secondaryComponents.DeleteBeneficiary
          showDeleteBeneficiaryModal={showDeleteBeneficiaryModal}
          setShowDeleteBeneficiaryModal={setShowDeleteBeneficiaryModal}
          fetchBeneficiaries={fetchBeneficiaries}
          currentBeneficiary={currentBeneficiary}
        />
      )}
    </div>
  );
};

export default BeneficiariesTable;
