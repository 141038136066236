import { Controller } from "react-hook-form";
import AmountField from "../AmountField";

const AmountFieldV2 = ({
  onChange = () => {},
  name = "",
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onControllerChange } }) => (
        <AmountField
          name={name}
          onChange={(e) => {
            onControllerChange(Number(e?.target?.value));
            onChange(e);
          }}
          {...props}
        />
      )}
    />
  );
};

export default AmountFieldV2;
