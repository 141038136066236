import { useTable } from "react-table";
import { useMemo, useState } from "react";
// import primaryComponents from "../../primaryComponents";
// import Icon from "../../../assets/icons";

const useExpenseCategoryTable = ({ expenseCategories }) => {
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [isDeleteCategory, setIsDeleteCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const columns = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "name",
      },
      {
        Header: "Created On",
        accessor: "created_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Last Update On",
        accessor: "update_at",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      // {
      //   Header: "Action",
      //   Cell: ({ cell: { value }, row }) => (
      //     <span className="d-flex align-items-center" style={{ gap: "10px" }}>
      //       <primaryComponents.Button
      //         classNames={"btn btn--primary smallBtn"}
      //         onClick={() => {
      //           setSelectedCategory(row?.original);
      //           setIsEditCategory(true);
      //         }}
      //       >
      //         Edit
      //       </primaryComponents.Button>
      //       <img
      //         width={20}
      //         src={Icon.deleteIcon}
      //         alt=""
      //         style={{ cursor: "pointer" }}
      //         onClick={() => {
      //           setSelectedCategory(row?.original);
      //           setIsDeleteCategory(true);
      //         }}
      //       />
      //     </span>
      //   ),
      // },
    ],
    [],
  );

  const instance = useTable({ columns, data: expenseCategories });
  return {
    instance,
    selectedCategory,
    isDeleteCategory,
    isEditCategory,
    setIsDeleteCategory,
    setIsEditCategory,
    setSelectedCategory,
  };
};

export default useExpenseCategoryTable;
