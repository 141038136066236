import styles from "./ProfitAndLostNetEarning.module.scss";

const ProfitAndLostNetEarning = ({ value }) => {
  return (
    <div className={styles.item}>
      <h5 className="purple--text">
        Net Earnings <span className="defaut-text-size">(Before Tax)</span>
      </h5>
      <h5 className="purple--text">{value}</h5>
    </div>
  );
};

export default ProfitAndLostNetEarning;
