import React from "react";

export const ProductEmpty = ({ color, height, width }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 362 311"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.895 93.3745V67.5504C124.895 41.7263 145.492 20.5137 171.624 20.5137C197.14 20.5137 218.353 41.4189 218.353 67.5504V93.3745"
        stroke="#C7D2DD"
        strokeWidth="1.8941"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M284.758 285.211C285.988 284.596 286.295 283.981 287.525 283.674L284.758 285.211Z"
        fill="#E4DAFC"
      />
      <path
        d="M284.758 285.211C285.988 284.596 286.295 283.981 287.525 283.674"
        stroke="#FF78B7"
        strokeWidth="0.6123"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M31.1279 309.805H361H31.1279Z" fill="#E4DAFC" />
      <path
        d="M31.1279 309.805H361"
        stroke="#B1B9CC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1 309.805H14.5269H1Z" fill="#E4DAFC" />
      <path
        d="M1 309.805H14.5269"
        stroke="#B1B9CC"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M308.122 44.4941H46.1924V307.039H308.122V44.4941Z"
        fill="#FEFEFE"
      />
      <path
        d="M308.737 44.4941H119.053V307.039H308.429V44.4941H308.737Z"
        fill="#F3F1FC"
      />
      <path
        d="M82.4691 44.4941H46.1924V307.039H82.4691V44.4941Z"
        fill="url(#paint0_linear_3241_30748)"
      />
      <path
        d="M119.053 307.039H46.1924V306.424L83.0839 269.84L119.053 307.039Z"
        fill="#15112D"
      />
      <path
        d="M164.86 102.904C170.087 102.904 174.391 98.6004 174.391 93.3741C174.391 88.1478 170.087 83.8438 164.86 83.8438C159.634 83.8438 155.33 88.1478 155.33 93.3741C155.33 98.6004 159.634 102.904 164.86 102.904Z"
        fill="#C7D2DD"
      />
      <path
        d="M258.318 102.904C263.545 102.904 267.849 98.6004 267.849 93.3741C267.849 88.1478 263.545 83.8438 258.318 83.8438C253.092 83.8438 248.788 88.1478 248.788 93.3741C249.096 98.6004 253.092 102.904 258.318 102.904Z"
        fill="#C7D2DD"
      />
      <path
        d="M164.86 93.3745V67.5504C164.86 41.7263 185.458 20.5137 211.59 20.5137C237.106 20.5137 258.319 41.4189 258.319 67.5504V93.3745"
        stroke="#C7D2DD"
        strokeWidth="1.8941"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M270.066 44.4925H270.066L270.066 44.4807C269.73 30.2008 277.119 17.76 288.393 10.3553C294.952 6.1516 302.688 3.79688 310.932 3.79688C333.469 3.79688 351.797 21.9605 351.797 44.4925C351.797 67.0244 333.469 85.1881 310.932 85.1881C288.394 85.1881 270.066 67.0244 270.066 44.4925Z"
        fill={color}
        stroke={color}
      />
      <path
        d="M310.925 62.9384C302.616 62.9384 295.554 55.4772 295.554 44.2854C295.554 33.0936 302.616 26.0469 310.925 26.0469C319.234 26.0469 326.297 33.0936 326.297 44.2854C326.297 55.4772 319.234 62.9384 310.925 62.9384ZM310.925 54.2337C313.833 54.2337 316.741 51.3321 316.741 44.2854C317.157 37.6532 313.833 34.3371 310.925 34.3371C308.017 34.3371 305.109 37.6532 305.109 44.2854C305.109 51.3321 308.017 54.2337 310.925 54.2337Z"
        fill="white"
      />
      <path
        d="M182.002 177.608C171.442 177.608 162.571 170.851 159.191 161.139C158.347 159.027 159.614 156.916 161.726 156.071C163.838 155.227 165.95 156.493 166.795 158.605C168.907 164.939 175.243 169.585 182.002 169.585C188.761 169.585 195.097 165.362 197.209 158.605C198.054 156.493 200.166 155.227 202.278 156.071C204.391 156.916 205.658 159.027 204.813 161.139C201.434 171.274 192.563 177.608 182.002 177.608Z"
        fill="#15112D"
      />
      <path
        d="M245.788 177.608C235.228 177.608 226.357 170.851 222.978 161.139C222.133 159.027 223.4 156.916 225.512 156.071C227.624 155.227 229.736 156.493 230.581 158.605C232.693 164.939 239.03 169.585 245.788 169.585C252.547 169.585 258.883 165.362 260.996 158.605C261.84 156.493 263.952 155.227 266.065 156.071C268.177 156.916 269.444 159.027 268.599 161.139C265.22 171.274 256.349 177.608 245.788 177.608Z"
        fill="#15112D"
      />
      <path
        d="M214.106 229.314C219.472 229.314 223.822 224.966 223.822 219.601C223.822 214.237 219.472 209.889 214.106 209.889C208.74 209.889 204.391 214.237 204.391 219.601C204.391 224.966 208.74 229.314 214.106 229.314Z"
        fill="#15112D"
      />
      <path
        d="M37.1598 14.8804V0.585938H92.4052V14.8804L54.7084 60.3628H93.0552V74.6572H35.21V60.3628L72.9068 14.8804H37.1598Z"
        fill="url(#paint1_linear_3241_30748)"
      />
      <path
        d="M82.6563 122.089V112.992H118.403V122.089L94.3554 151.327H119.053V160.424H81.3564V151.327L105.404 122.089H82.6563Z"
        fill="url(#paint2_linear_3241_30748)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3241_30748"
          x1="64.2505"
          y1="51.4814"
          x2="64.2505"
          y2="303.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#E6ECF5" />
          <stop offset="1" stopColor="#CED7E2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3241_30748"
          x1="64.1371"
          y1="3.15909"
          x2="64.1371"
          y2="44.7346"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3241_30748"
          x1="100.208"
          y1="114.64"
          x2="100.208"
          y2="141.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
