import Icon from "../../../assets/icons";
import primaryComponents from "../../primaryComponents";
import "./CheckoutByTransfer.scss";

const CheckoutByTransfer = ({ closeWidget }) => {
  return (
    <div className="checkout-by-transfer">
      <div
        className="checkout-by-transfer-close"
        onClick={() => closeWidget(false)}
      >
        <img src={Icon.close} alt="" />
      </div>
      <div className="checkout-by-transfer-details-wrapper">
        <div className="checkout-by-transfer-details">
          <h4 className="font-weight-semibold checkout-by-transfer-details__title">
            Please confirm Payment of{" "}
            <span className="purple--text">N45,670:00</span> by selecting the
            right transfer
          </h4>
        </div>
        <div className="checkout-by-transfer-details">
          {/* Fetch transfers here */}
        </div>
      </div>
      <div className="d-flex justify-content-between checkout-by-transfer-submission">
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={() => closeWidget(false)}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button classNames={"btn btn--primary"}>
          Okay
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default CheckoutByTransfer;
