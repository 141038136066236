import { useState, useContext, useEffect } from "react";
import { verifyUserEmail, sendOTP } from "../../../services/auth";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { useGetProfileDetails } from "../../../hooks/api/queries/useSettings";
import { pathConstants } from "../../../routes/pathContants";
import useGrantAccessToApp from "../../../hooks/useGrantAccessToApp";

const EmailVerificationLogic = () => {
  const params = new URLSearchParams(window.location.search);
  const email = params.get("email");
  const token = params.get("token");
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [countDown, setCountDown] = useState(60);
  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const [tokens, setTokens] = useState({
    refreshToken: null,
    accessToken: null,
  });
  const { isLoading, data: profileDetails } = useGetProfileDetails({
    isProfileEnabled: tokens?.accessToken ? true : false,
    accessToken: tokens?.accessToken,
  });
  const { setAuthTokensAndGrantAccessToApp } = useGrantAccessToApp({
    message: "Email verification successful",
  });

  useEffect(() => {
    let interval = setInterval(() => {
      if (countDown <= 0) return clearInterval(interval);
      setCountDown((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (!isLoading && tokens?.refreshToken) getUserProfileDetailsAndLogin();
  }, [isLoading, tokens]);

  const handleOTPInputs = (value) => {
    setOtp(value);
    if (value?.length !== 6) return;
    setIsVerifing(true);
    setRequestLoaderProgress(40);
    verifyUserEmail(token, { code: `${value}` })
      .then((response) => {
        const { access, refresh } = response?.data?.data;
        setTokens({
          refreshToken: refresh,
          accessToken: access,
        });
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 400
        ) {
          triggerToast(
            error?.response?.data?.detail || "Token expired, please login",
            "warning",
          );
        }

        if (error?.response?.status === 500) {
          triggerToast("Something went wrong, Please try again", "warning");
        }
      })
      .finally(() => {
        setIsVerifing(false);
        setRequestLoaderProgress(100);
      });
  };

  const resendOTP = () => {
    setIsResendingOTP(true);
    sendOTP(token)
      .then(() => {
        triggerToast("OTP resent successful", "success");
        setCountDown(60);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          triggerToast("Token expired, please login", "warning");
          navigate(pathConstants.LOGIN);
        }
      })
      .finally(() => setIsResendingOTP(false));
  };

  const getUserProfileDetailsAndLogin = () => {
    setAuthTokensAndGrantAccessToApp({
      refreshToken: tokens?.refreshToken,
      accessToken: tokens?.accessToken,
      profileDetails: profileDetails,
    });
  };

  return {
    email,
    handleOTPInputs,
    isVerified,
    otp,
    isVerifing,
    countDown,
    setIsVerified,
    resendOTP,
    isResendingOTP,
  };
};
export default EmailVerificationLogic;
