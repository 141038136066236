/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import primaryComponents from "../../primaryComponents";
import styles from "./CustomersSearchField.module.scss";
import useCustomerSearchField from "./useCustomerSearchField";
import secondaryComponents from "..";

const CustomersSearchField = ({
  setCustomerDetails,
  customerValue = "",
  title = "",
}) => {
  const {
    serachRef,
    isLoading,
    searchValue,
    customersNames,
    isDropDown,
    setIsDropDown,
    setIsEnableSearch,
    setSearchValue,
  } = useCustomerSearchField({ customerValue });
  return (
    <div className={styles.customersSearchField} ref={serachRef}>
      <primaryComponents.SearchField
        triggerOnInput={true}
        value={searchValue}
        placeholder="Search customer by name or phone number"
        useControlledValue={true}
        trigger={(value) => {
          setSearchValue(value);
          setIsEnableSearch(true);
          setIsDropDown(true);
        }}
      />
      {/* dropdown section */}
      {isDropDown ? (
        <div className={styles.resultSection}>
          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames="mt-4"
          >
            {customersNames.length > 0 ? (
              <div className={styles.nameContainer}>
                {customersNames.map((customer) => (
                  <a
                    className={styles.nameItem}
                    key={customer.id}
                    onClick={() => {
                      setIsEnableSearch(false);
                      setIsDropDown(false);
                      setSearchValue(customer?.full_name || "");
                      setCustomerDetails({
                        customer_phone_number: `${customer.phone}`,
                        customer_full_name: customer?.full_name || "",
                        customer_email: customer?.email || "",
                        customer_phone_code: customer?.phone_code || "",
                        customer_id: customer?.id || "",
                        storeWalletDetails: customer?.store_wallet,
                      });
                    }}
                  >
                    {customer?.full_name || ""} -- {customer.phone}
                  </a>
                ))}
              </div>
            ) : (
              <h5 className="mt-3 text-center">
                Customer cannot be found. Enter customer&#39;s record{" "}
                {title !== "" ? "with the link above" : "in the field below"}
              </h5>
            )}
          </secondaryComponents.LoaderHelper>
        </div>
      ) : null}
    </div>
  );
};
export default CustomersSearchField;
