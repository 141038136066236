import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const businessSchema = yup
  .object({
    businessName: yup
      .string()
      .min(3, "Minimum of 3 characters is allowed")
      .max(30, "Maximum of 30 characters is allowed")
      .required("Business name is required"),
    jobTitle: yup
      .string()
      .max(30, "Maximum of 30 characters is allowed")
      .required("Job title is required"),
    businessSize: yup.string().required("Business size is required"),
    serviceType: yup
      .array()
      .min(1, "This field is required")
      .required("This field is required"),
    referral: yup.string().required("This field is required"),
  })
  .required();

const BusinessFormValidation = (
  businessName,
  jobTitle,
  businessSize,
  serviceType,
  referral,
) => {
  return useForm({
    resolver: yupResolver(businessSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      businessName,
      jobTitle,
      businessSize,
      serviceType,
      referral,
    },
  });
};

export default BusinessFormValidation;
