import { useState } from "react";
import Validation from "./Validation";
import primaryComponents from "../../primaryComponents";

const RejectOrderPop = ({ closeModal, onSubmit, isProcessingOrder }) => {
  const [rejectionReason, setRejectionReason] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = Validation(rejectionReason);

  return (
    <div className="p-3">
      <h4 className="mb-4 orange--text">Reason for Rejection</h4>
      <primaryComponents.TextAreaV2
        control={control}
        name="rejectionReason"
        type="text"
        placeholder="Reason for rejection"
        value={rejectionReason}
        isRequired={true}
        errorMessage={errors.rejectionReason}
        onChange={({ target: { value } }) => {
          setRejectionReason(value);
        }}
        onKeyUp={() =>
          errors.rejectionReason !== undefined && trigger("rejectionReason")
        }
        register={register}
      />
      <div
        className={"mt-4 d-flex justify-content-between"}
        style={{ gap: "10px" }}
      >
        <primaryComponents.Button
          classNames={"btn btn--outline cover"}
          onClick={() => closeModal()}
        >
          Back
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          type="submit"
          onClick={handleSubmit(() =>
            onSubmit({ isAccept: false, rejectionReason }),
          )}
          isLoading={isProcessingOrder}
          isDisabled={isProcessingOrder}
          loadingText={"Submiting..."}
        >
          Submit
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default RejectOrderPop;
