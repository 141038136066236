import styles from "./InventoryProfitChart.module.scss";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";

const InventoryProfitChartTooltip = (props) => {
  const { store_currency_code, active, payload, label, isMoney = true } = props;

  if (active) {
    return (
      <div className={styles["inventory-profit-chart-tooltip"]}>
        <div className={styles["inventory-profit-chart-tooltip__value"]}>
          <span>
            {isMoney
              ? formatCurrency({
                  value: payload[0]?.value,
                  currencyCode: store_currency_code,
                })
              : payload[0]?.value}
          </span>
        </div>
        <p className={styles["inventory-profit-chart-tooltip__label"]}>
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

export default InventoryProfitChartTooltip;
