import styles from "./ProductStockHistory.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import useProductStockHistory from "./useProductStockHistory";

const ProductStockHistory = () => {
  const navigate = useNavigate();

  const {
    isFetchingStockHistory,
    metaDetails,
    stockHistory,
    handleFetchStockHistory,
    gotoPage,
  } = useProductStockHistory();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className={styles.productStockHistory}
    >
      <div>
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon="angle-left"
            className="mr-3"
            style={{ fontSize: "18px" }}
          />
          <span>Back</span>
        </primaryComponents.Button>
      </div>

      <div className={styles.productStockHistory__content}>
        <h4 className="font-weight-semibold">History</h4>
        <secondaryComponents.LoaderHelper
          classNames={"mt-3"}
          isLoading={isFetchingStockHistory}
        >
          <div className={styles.productDetails}>
            <div>
              <p className="dark--text">Product Name</p>
              <p>{stockHistory?.[0]?.product?.name || "N/A"}</p>
            </div>
          </div>

          <secondaryComponents.StockHistoryTable
            metaDetails={metaDetails}
            stockHistory={stockHistory}
            handleFetchStockHistory={handleFetchStockHistory}
            gotoPage={gotoPage}
          />
        </secondaryComponents.LoaderHelper>
      </div>
    </motion.div>
  );
};

export default ProductStockHistory;
