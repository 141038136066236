import React from "react";

export const RestaurantIcon = ({ color }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.01475 6.38184H7.20949C6.75252 6.38184 6.38208 6.75228 6.38208 7.20924L6.38208 10.0018V13.6217L6.38208 17.2416V18.7178C6.38208 19.1747 6.75252 19.5452 7.20949 19.5452H9.01475H12.9637H16.9128H18.718C19.175 19.5452 19.5454 19.1747 19.5454 18.7178V17.2416V13.6217V10.0018V7.20924C19.5454 6.75228 19.175 6.38184 18.718 6.38184H16.9128L12.9637 6.38184L9.01475 6.38184Z"
        fill="white"
        stroke={color}
        strokeWidth="1.45455"
      />
      <path
        d="M13 1C11.1925 1 9.72729 2.30244 9.72729 3.90909L16.2727 3.90909C16.2727 2.30244 14.8075 1 13 1Z"
        fill="#F3F1FC"
        stroke={color}
        strokeWidth="0.727273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 25C11.1925 25 9.72729 23.6976 9.72729 22.0909H16.2727C16.2727 23.6976 14.8075 25 13 25Z"
        fill="#F3F1FC"
        stroke={color}
        strokeWidth="0.727273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 12.9997C25 14.8072 23.6976 16.2725 22.0909 16.2725L22.0909 9.72701C23.6976 9.72701 25 11.1923 25 12.9997Z"
        fill="#F3F1FC"
        stroke={color}
        strokeWidth="0.727273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 12.9997C1 14.8072 2.30244 16.2725 3.90909 16.2725L3.90909 9.72701C2.30244 9.72701 1 11.1923 1 12.9997Z"
        fill="#F3F1FC"
        stroke={color}
        strokeWidth="0.727273"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_2231_142034)">
        <path
          d="M10.0006 9.51914C10.0006 9.46977 9.98094 9.42241 9.94603 9.3875C9.87333 9.3148 9.75545 9.3148 9.68275 9.3875C9.64783 9.42241 9.62822 9.46977 9.62822 9.51914V11.3037H9.25588V9.50576C9.25588 9.46495 9.23967 9.42582 9.21082 9.39696C9.13289 9.31903 9.00654 9.31903 8.92861 9.39696C8.89976 9.42582 8.88354 9.46495 8.88354 9.50576V11.7588C8.88354 12.1244 9.1799 12.4207 9.54548 12.4207C9.59117 12.4207 9.62822 12.4578 9.62822 12.5035V16.447C9.62822 16.4915 9.64588 16.5341 9.67732 16.5655C9.75302 16.6412 9.87575 16.6412 9.95145 16.5655C9.98289 16.5341 10.0006 16.4915 10.0006 16.447V12.5035C10.0006 12.4578 10.0376 12.4207 10.0833 12.4207C10.4489 12.4207 10.7452 12.1244 10.7452 11.7588V9.48296C10.7452 9.45675 10.7348 9.43162 10.7163 9.41308C10.6295 9.32625 10.4887 9.32625 10.4018 9.41308C10.3833 9.43162 10.3729 9.45675 10.3729 9.48296V11.3037H10.0006V9.51914Z"
          fill="#FD784C"
        />
        <path
          d="M17.0601 9.44238C16.4513 9.44238 15.9578 9.93591 15.9578 10.5447V13.0927C15.9578 13.1331 15.9905 13.1658 16.0308 13.1658H16.5806C16.6479 13.1658 16.7024 13.2203 16.7024 13.2876V16.4668C16.7024 16.4988 16.7152 16.5295 16.7378 16.5522C16.8211 16.6354 16.9561 16.6354 17.0394 16.5522C17.062 16.5295 17.0748 16.4988 17.0748 16.4668V9.45708C17.0748 9.44896 17.0682 9.44238 17.0601 9.44238Z"
          fill="#FD784C"
        />
        <path
          d="M16.0006 14.2004C16.0411 14.1189 16.0238 14.0232 15.9643 13.9543C15.8362 13.8059 15.5617 13.8378 15.4699 14.0111C15.0326 14.8367 14.1646 15.3992 13.1652 15.3992C12.2037 15.3992 11.3638 14.8785 10.912 14.1037C10.8088 13.9267 10.5036 13.9169 10.3945 14.0903C10.3521 14.1577 10.348 14.2385 10.3862 14.3084C10.9232 15.2911 11.9664 15.9577 13.1652 15.9577C14.4074 15.9577 15.4825 15.242 16.0006 14.2004Z"
          fill="#FD784C"
        />
        <path
          d="M14.9163 10.8623C15.0634 10.9958 15.3369 10.9296 15.4034 10.7424C15.4332 10.6584 15.4156 10.5657 15.3511 10.5041C14.783 9.9613 14.013 9.62793 13.1652 9.62793C12.3988 9.62793 11.696 9.90035 11.1484 10.3536C11.0904 10.4017 11.0633 10.4745 11.0723 10.5492C11.0978 10.7597 11.3957 10.8675 11.5628 10.7371C12.0048 10.392 12.561 10.1864 13.1652 10.1864C13.8393 10.1864 14.4536 10.4423 14.9163 10.8623Z"
          fill="#FD784C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.427 12.7928C14.427 13.4897 13.8621 14.0546 13.1652 14.0546C12.4683 14.0546 11.9034 13.4897 11.9034 12.7928C11.9034 12.0959 12.4683 11.531 13.1652 11.531C13.8621 11.531 14.427 12.0959 14.427 12.7928ZM13.8685 12.7928C13.8685 13.1812 13.5536 13.4961 13.1652 13.4961C12.7768 13.4961 12.4619 13.1812 12.4619 12.7928C12.4619 12.4044 12.7768 12.0895 13.1652 12.0895C13.5536 12.0895 13.8685 12.4044 13.8685 12.7928Z"
          fill="#FD784C"
        />
      </g>
      <defs>
        <clipPath id="clip0_2231_142034">
          <rect
            width="8.27408"
            height="8.27408"
            fill="white"
            transform="translate(8.84204 8.8418)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
