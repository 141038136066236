import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { useNavigate } from "react-router-dom";
import useLoyaltyCustomerDetails from "./useLoyaltyCustomerDetails";
import styles from "./LoyaltyCustomerDetails.module.scss";

const LoyaltyCustomerDetails = () => {
  const navigate = useNavigate();
  const { isLoading, customerDetails } = useLoyaltyCustomerDetails();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty Customer Details"
      description="View the details of a customer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div className={styles.loyaltyCustomer}>
          <div className="d-flex justify-content-between">
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            {/* <div>
              <span className="dark--text font-weight-semibold mr-3">
                Add to Loyalty
              </span>
              <primaryComponents.Switch
                onChange={toggleLoyalty}
                isOn={!customerDetails?.is_blacklisted}
                name="loyaltyswitch"
              />
            </div> */}
          </div>

          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames="mt-5"
          >
            <div className={styles.loyaltyCustomer__content}>
              <div className={styles.loyaltyCustomer__content__rows}>
                <div>
                  <p className="font-weight-semibold gray--text">
                    Customer&apos;s Name
                  </p>
                  <span className="font-weight-semibold dark--text">
                    {customerDetails?.full_name
                      ? customerDetails?.full_name
                      : "N/A"}
                  </span>
                </div>

                <div>
                  <p className="font-weight-semibold gray--text">
                    Phone Number
                  </p>
                  <span className="font-weight-semibold dark--text">
                    {customerDetails?.phone ? customerDetails?.phone : "N/A"}
                  </span>
                </div>
                <div>
                  <p className="font-weight-semibold gray--text">
                    Email Address
                  </p>
                  <span className="font-weight-semibold dark--text">
                    {customerDetails?.email ? customerDetails?.email : "N/A"}
                  </span>
                </div>
                <div>
                  <p className="font-weight-semibold gray--text">
                    Date of Birth
                  </p>
                  <span className="font-weight-semibold dark--text">N/A</span>
                </div>
              </div>

              {/* account number section */}
              {/* <div>
                <h5 className="font-weight-semibold">Account Numbers</h5>
                <div className={styles.loyaltyCustomer__content__accounts}>
                  <div className={styles.accountRow}>
                    <div>
                      <p className="gray--text"> Account Number</p>
                      <span className="font-weight-semibold dark--text">
                        0123456789
                      </span>
                    </div>

                    <div>
                      <p className="gray--text">Beneficiary</p>
                      <span className="font-weight-semibold dark--text">
                        Tobiloba Ilori
                      </span>
                    </div>
                    <div>
                      <p className="gray--text">Bank Name</p>
                      <span className="font-weight-semibold dark--text">
                        Zenith Bank
                      </span>
                    </div>
                    <div>
                      <p className="gray--text">Card Details</p>
                      <span className="font-weight-semibold dark--text">
                        23456****123
                      </span>
                      <span className="font-weight-semibold dark--text">
                        VERVE CARD
                      </span>
                    </div>
                  </div>
                </div>
              </div> */}

              <div>
                <h5 className="font-weight-semibold">Transaction History</h5>
                <div className={styles.loyaltyCustomer__content__history}>
                  <secondaryComponents.LoyaltyCustomerTransactionTable
                    data={customerDetails?.loyalty_transactions}
                  />
                </div>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>
        </div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default LoyaltyCustomerDetails;
