import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { ToastContext } from "../../../hooks/context";
import { transferStock } from "../../../services/inventory";

export const useTransferStock = ({ closeWidget }) => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const [stores, setStores] = useState([]);
  const [selectedProductVariants, setSelectedProductVariants] = useState([]);
  const [formData, setFormData] = useState({
    product: "",
    variant: "",
    transferFrom: "",
    transferTo: "",
    quantity: 0,
  });

  const stockTransferMutation = useMutation({
    mutationFn: (data) => transferStock(data),
    onSuccess: () => {
      triggerToast("Stock trasnfer successfull", "success");
      queryClient.invalidateQueries(["getProductList"]);
      closeWidget();
    },
  });

  const handleTransferStock = () => {
    stockTransferMutation.mutate({
      product_id: formData.product,
      transfer_from_store_id: formData.transferFrom,
      transfer_to_store_id: formData.transferTo,
      variant_id: formData.variant ? formData.variant : undefined,
      quantity: Number(formData.quantity),
    });
  };

  return {
    selectedProductVariants,
    isTransferingStock: stockTransferMutation.isLoading,
    stores,
    formData,
    setSelectedProductVariants,
    setFormData,
    setStores,
    handleTransferStock,
  };
};
