import primaryComponents from "../../../primaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import styles from "./BaseProductError.module.scss";
import externalStyles from "../CompositeDetailsForm/CompositeDetailsForm.module.scss";
import Icon from "../../../../assets/icons";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

const BaseProductError = ({
  productToReplicate,
  isReplicatingProduct,
  closeModal,
  replicateProductIntoStores,
}) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  return (
    <div
      className="text-center py-4 px-4 d-flex flex-column"
      style={{ gap: "40px" }}
    >
      <div className={`${globalStyles.cardWithBorder} ${styles.card}`}>
        <img
          className="mx-auto"
          width={80}
          src={Icon.noticeIcon}
          alt="notice"
        />
        <p className="mb-4" style={{ maxWidth: "450px", margin: "auto" }}>
          Apologies! The following base products are not available in the stores
          you selected for your composite product
        </p>

        <div className={styles.cardItem}>
          <p>Base Product</p>
          <p>Location</p>
        </div>
        {productToReplicate?.map((item) => (
          <div className={styles.cardItem} key={uuidv4()}>
            <p className="dark--text">{item?.name}</p>
            <p className="dark--text">
              {item?.stores?.map((storeItem, index) => (
                <span key={uuidv4()}>
                  {
                    storeList?.find(
                      (store) => `${store?.id}` === `${storeItem}`,
                    )?.name
                  }
                  {item?.stores?.length - 1 !== index ? ", " : ""}
                </span>
              ))}
            </p>
          </div>
        ))}
      </div>
      <h5>
        You&apos;ve made a composite product, but some base products aren&apos;t
        available in your selected stores. To continue:
      </h5>
      <div className={externalStyles.buttonSection}>
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--primary"
          isDisabled={isReplicatingProduct}
          isLoading={isReplicatingProduct}
          loadingText={"Adding..."}
          onClick={replicateProductIntoStores}
        >
          Add Base Products for me
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default BaseProductError;
