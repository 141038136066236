import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape(
  {
    storeName: yup.string().required("Store Name is required"),
    storeEmail: yup
      .string()
      .email("Invalid email address")
      .max(50, "Maximum of 50 characters is allowed"),
    storeAddress: yup.string().required("Store address is required"),
    storeCategory: yup.string().required("Please select your store category"),
    orderReversalWindow: yup
      .number()
      .min(0.01, "Minimum value is 0.01")
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? undefined : value,
      )
      .test("max-decimal", "Decimal value cannot exceed .59", (value) => {
        if (!value) return true;
        const decimalPart = value - Math.floor(value);
        return decimalPart <= 0.59;
      }),
    storePhone: yup
      .string()
      .notRequired()
      .when("storePhone", {
        is: (val) => val?.length,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Phone number must contain only digits")
          .test(
            "num",
            "Invalid phone number format",
            (val) => !val.startsWith("0"),
          )
          .min(10, "Invalid phone number format")
          .max(10, "Invalid phone number format"),
      }),
  },
  ["storePhone", "storePhone"],
);

const Validation = ({ storeDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      storeName: storeDetails?.name,
      storeAddress: storeDetails?.address,
      storeCategory: storeDetails?.store_category,
      storePhone: storeDetails?.phone_number,
      storeEmail: storeDetails?.email,
      orderReversalWindow: storeDetails?.order_reversal_window,
    },
  });
};

export default Validation;
