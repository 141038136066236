import { getOrderTableDetails } from "../../../../services/inventory";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

export const useTableDetails = () => {
  const { id } = useParams();

  const { isLoading: isFetchingOrderTable, data: tableDetails } = useQuery(
    ["getOrderTableDetails", id],
    () => getOrderTableDetails(id).then((res) => res.data?.data),
  );

  return {
    tableDetails,
    isFetchingOrderTable,
    tableId: id,
  };
};
