import { useEffect } from "react";
import { useState } from "react";

const useCountDown = ({ defaultStartNum = 60 }) => {
  const [countDown, setCountDown] = useState(defaultStartNum);

  useEffect(() => {
    let interval = setInterval(() => {
      if (countDown <= 0) return clearInterval(interval);
      setCountDown((prevState) => prevState - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  return { countDown, setCountDown };
};
export default useCountDown;
