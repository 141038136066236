import { useContext, useState } from "react";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { updateSubAccount } from "../../../services/business";
import { getRandomIntInclusive } from "../../../helpers";
import { useQueryClient } from "react-query";

const EditSubAccountLogic = (account, setOpenEditWidget) => {
  const { business_tag, business_id } = account;
  const [sub_account_description, setSubAccountDescription] = useState("");
  const [sub_account_tag, setSubAccountTag] = useState(business_tag || "");
  const [isEdited, setIsEdited] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();

  const handleEditSubAccount = async () => {
    setIsUpdating(true);
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));

    updateSubAccount({
      sub_account_id: business_id,
      sub_account_tag,
      sub_account_description,
    })
      .then(() => {
        triggerToast("Account updated successfully", "success");
        setOpenEditWidget(false);
        queryClient.invalidateQueries(["getSubAccounts"]);
      })
      .catch(() => {})
      .finally(() => {
        setIsUpdating(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    sub_account_tag,
    isEdited,
    isUpdating,
    sub_account_description,
    setSubAccountTag,
    setIsEdited,
    setIsUpdating,
    handleEditSubAccount,
    setSubAccountDescription,
  };
};

export default EditSubAccountLogic;
