import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import DeleteBeneficiaryLogic from "./DeleteBeneficiaryLogic";

const DeleteBeneficiary = ({
  showDeleteBeneficiaryModal,
  setShowDeleteBeneficiaryModal,
  fetchBeneficiaries,
  currentBeneficiary,
}) => {
  const { isRemovingBeneficiary, removeBenficiary } = DeleteBeneficiaryLogic({
    currentBeneficiary,
    setShowDeleteBeneficiaryModal,
    fetchBeneficiaries,
  });

  return (
    <secondaryComponents.Modal
      width="500px"
      isActive={showDeleteBeneficiaryModal}
      closeModal={() => {
        setShowDeleteBeneficiaryModal(false);
      }}
    >
      <div className="py-4 px-2">
        <h4 className="text-center mb-4">Delete Beneficiary</h4>
        <p className="mb-4 text-center">
          Are you sure you want to delete this beneficiary?
        </p>

        <div className="py-3 px-3 mb-4" style={{ background: "#F3F1FC" }}>
          <p className="small-font-size mb-3">Beneficiary Details</p>

          <h6>{currentBeneficiary.account_name}</h6>
          <h6>{currentBeneficiary.bank_name}</h6>
          <h6>{currentBeneficiary.account_number}</h6>
        </div>

        <div className="mt-4 d-flex justify-content-between">
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={() => setShowDeleteBeneficiaryModal(false)}
          >
            Cancel
          </primaryComponents.Button>

          <primaryComponents.Button
            classNames="btn btn--danger"
            onClick={removeBenficiary}
            isLoading={isRemovingBeneficiary}
            isDisabled={isRemovingBeneficiary}
            loadingText="Deleting..."
          >
            Continue
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};
export default DeleteBeneficiary;
