import httpClient from "../axiosBase/axiosHandler";

export const requestNewTerminal = (payload) => {
  const response = httpClient.post("/terminals/", payload);
  return response;
};

export const fetchAllTerminals = (queries) => {
  const response = httpClient.get(`/terminals/${queries}`);
  return response;
};

export const fetchPendingRequest = () => {
  const response = httpClient.get("/terminals/request-list/");
  return response;
};

export const fetchTerminalDetails = (id) => {
  const response = httpClient.get(`/terminals/${id}/`);
  return response;
};

export const resetTerminalCredentials = (id) => {
  return httpClient.post(`/terminals/${id}/reset-cashier/`, { id });
};
