import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  productName: yup.string().required("Product name is required"),
  productSku: yup.string().nullable(true),
  productCategory: yup.string().required("Category is required"),
  stores: yup
    .array()
    .min(1, "Minimum of one store is required")
    .required("Minimum of one store is required"),
  storeProperties: yup.array().of(
    yup.object().shape({
      cost_price: yup.string().required("Cost price is required"),
      pricing: yup.array().of(
        yup.object().shape({
          price: yup.string().required("Selling price is required"),
          pricing_type_id: yup
            .string()
            .nullable()
            .required("Pricing type is required"),
        }),
      ),
    }),
  ),
});

export const Validation = ({ rootFormDetails, storeProperties }) => {
  return useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      productName: rootFormDetails.product_name,
      productCategory:
        rootFormDetails.category?.label || rootFormDetails.category,
      stores: rootFormDetails.stores,
      storeProperties,
    },
  });
};
