import Validation from "./Validation";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AddDebitToBankAccount.module.scss";
import useBankAccountLogic from "./useAddDebitToBankAccount";
import secondaryComponents from "..";

const AddDebitToBankAccount = ({
  bankInfo,
  handleFetchBankTransaction = () => {},
  handleFetchBanks = () => {},
  closeWidget = () => {},
  type = "bank",
}) => {
  const {
    bankDetails,
    bankAccount,
    isLoadingBankAccountsList,
    setBankDetails,
    storeList,
    handleAddBankTransaction,
    createBankDebitMutation,
  } = useBankAccountLogic({
    closeWidget,
    bankInfo,
    handleFetchBanks,
    type,
    handleFetchBankTransaction,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ bankDetails, type });
  return (
    <div className={styles.allAccounting}>
      <FontAwesomeIcon
        onClick={() => closeWidget(false)}
        className="d-block ml-auto"
        icon="times"
        style={{ fontSize: "30px" }}
      />

      <div className="mt-4">
        <h5>Add Debit Transaction</h5>
        <p className="small-text-size">Record debit transactions.</p>

        <div className={styles.formSection}>
          <div className="w-100 cover">
            <primaryComponents.AmountFieldV2
              control={control}
              classNames="white"
              name="amount"
              label="Amount"
              placeholder="Enter amount"
              isRequired={true}
              value={bankDetails?.amount}
              onChange={({ target: { value } }) => {
                setBankDetails((prev) => ({
                  ...prev,
                  amount: value,
                }));
              }}
              errorMessage={errors.amount}
              onKeyUp={() => errors.amount !== undefined && trigger("amount")}
              register={register}
            />
          </div>
          <div className="w-100 cover">
            <primaryComponents.InputFieldV2
              control={control}
              classNames="white"
              name="description"
              label="Description"
              placeholder="Enter description"
              value={bankDetails?.description}
              onChange={({ target: { value } }) => {
                setBankDetails((prev) => ({
                  ...prev,
                  description: value,
                }));
              }}
              errorMessage={errors.description}
              onKeyUp={() =>
                errors.description !== undefined && trigger("description")
              }
              register={register}
            />
          </div>
          {type === "cash" ? null : (
            <div className="w-100 cover">
              <secondaryComponents.LoaderHelper
                isLoading={isLoadingBankAccountsList}
                classNames="mt-5"
              >
                <primaryComponents.SelectFieldV2
                  options={bankAccount?.data?.map((bank) => ({
                    id: bank?.id,
                    name: bank?.account_name,
                  }))}
                  control={control}
                  name="account"
                  label={"Account"}
                  isRequired={type !== "cash"}
                  nameKey="name"
                  value={bankDetails?.ledger_account_id || "Select account"}
                  onChange={(value) => {
                    setBankDetails((prev) => ({
                      ...prev,
                      ledger_account_id: value,
                    }));
                  }}
                  idKey={"id"}
                  classNames="account-select"
                  register={register}
                  errorMessage={errors.account}
                />
              </secondaryComponents.LoaderHelper>
            </div>
          )}

          <div className="w-100 cover">
            <primaryComponents.SelectFieldV2
              options={[
                { name: "Transfer", value: "transfer" },
                { name: "Card", value: "card" },
              ]}
              defaultValue={type === "cash" ? "cash" : ""}
              isDisabled={type === "cash"}
              control={control}
              name="paymentMethod"
              label="Payment Method"
              isRequired={true}
              nameKey="name"
              value={
                type === "cash"
                  ? "Cash"
                  : bankDetails?.payment_method
                    ? bankDetails.payment_method
                    : "Select method of payment"
              }
              onChange={(value) => {
                setBankDetails((prev) => ({
                  ...prev,
                  payment_method: value,
                }));
              }}
              idKey="value"
              classNames="bank-type-select"
              register={register}
              errorMessage={errors.paymentMethod}
            />
          </div>

          <div className="w-100 cover">
            <primaryComponents.SelectFieldV2
              options={storeList}
              control={control}
              name="storeIds"
              label={"Select store(s)"}
              isRequired={type === "cash" ? true : false}
              nameKey="name"
              value={
                bankDetails?.store_id ? bankInfo?.store?.name : "Select store"
              }
              onChange={(value) => {
                setBankDetails((prev) => ({
                  ...prev,
                  store_id: value,
                }));
              }}
              idKey={"id"}
              classNames="store-select"
              register={register}
              errorMessage={type === "cash" ? errors.storeIds : undefined}
            />
          </div>
        </div>
      </div>

      <primaryComponents.Button
        onClick={handleSubmit(handleAddBankTransaction)}
        classNames="btn btn--primary cover mt-auto"
        isDisabled={createBankDebitMutation.isLoading}
        isLoading={createBankDebitMutation.isLoading}
        loadingText={"Adding..."}
      >
        Add
      </primaryComponents.Button>
    </div>
  );
};

export default AddDebitToBankAccount;
