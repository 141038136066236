import styles from "../CreateCampaign/CreateCampaign.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { useGetCampaignDetails } from "../../../../../hooks/api/queries/useLoyalty";

const EditCampaign = () => {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { data: campaign, isLoading } = useGetCampaignDetails(campaignId);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - Edit campaign"
      description="Create campaign on lumi merchant and see an improve customer loyalty"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps} ${styles.createCampaign}`}
        >
          {/* back btn */}
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <div className={styles.createCampaign__content}>
            <h3>Edit Loyalty SetUp</h3>

            <secondaryComponents.LoaderHelper
              isLoading={isLoading}
              classNames={"mt-4"}
            >
              <secondaryComponents.CampaignForm
                campaign={campaign}
                isEditMode={true}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default EditCampaign;
