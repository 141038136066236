import styles from "./SubscribtionNotification.module.scss";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";

const SubscribtionNotification = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.subscribtionNotification}>
      <img className="mx-auto" width={120} src={Icon.padlockIcon} alt="" />
      <h4>Apologies! You no longer have access to this feature</h4>

      <div className={styles.content}>
        <p>
          <span className="font-weight-semibold">Dear Abdulazeez</span>
          <br />
          This is to inform you that your Lumi Business subscription is now due.
          Please find package options below.{" "}
          <span className="font-weight-semibold">
            The recommended package for you based on the activities on your
            account is{" "}
          </span>
          <span className="purple--text">Essentials plan at ₦30,000/year</span>
        </p>
        <p>
          To avoid an interruption in service, please make payment to the
          account below at your earliest convenience.
        </p>
        <div className={styles.account}>
          <hr />
          <h4 className="purple--text d-flex align-items-center">
            <img width={20} className="mr-2" src={Icon.bankFinancial} alt="" />
            <span>2053218104 -</span>
            <span className="small-text-size ml-1">Kuda Microfinance Bank</span>
          </h4>
          <h6 className="d-flex align-items-center font-weight-normal">
            {" "}
            <img width={20} className="mr-2" src={Icon.userProfile} alt="" />
            <span>ENLUMI INSIGHTS - 4</span>
          </h6>
          <hr />
          <p>
            <span className="error--text">*</span>include your business name in
            the transfer description
          </p>
        </div>
        <p>
          Kind Regards,
          <br />
          The Lumi Team.
        </p>
      </div>

      <div className="d-flex justify-content-between mt-4">
        <primaryComponents.Button
          classNames="btn btn--outline"
          onClick={() =>
            navigate(
              `${pathConstants.PAYMENT_TRANSFER}?status=accepted&all_business=true`,
            )
          }
        >
          Continue with free features
        </primaryComponents.Button>
        <primaryComponents.Button classNames="btn btn--primary">
          View package option
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default SubscribtionNotification;
