import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { useSendOrderReceiptMutation } from "../../../../hooks/api/mutations/useOrder";
import useOrderflows from "../Orderflows/useOrderflows";
import { ToastContext } from "../../../../hooks/context";

const useSendReceiptV2 = ({ orderId }) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [customerPhoneCode, setCustomerPhoneCode] = useState("");
  const [sendReceiptOption, setSendReceiptOption] = useState("email");
  const triggerToast = useContext(ToastContext);
  const sendOrderReceiptMutation = useSendOrderReceiptMutation();
  const { orderDetails } = useOrderflows({
    orderId,
  });

  const handleSendReceipt = (data) => {
    const { email, phone_number } = data;
    const recipient = sendReceiptOption === "email" ? email : phone_number;
    if (sendReceiptOption === "email")
      sendOrderReceiptMutation.mutate(
        {
          medium: sendReceiptOption,
          send_to: recipient,
          order_id: orderId,
        },
        {
          onSuccess: () => {
            triggerToast("Receipt sent successfully", "success");
          },
        },
      );
    else {
      const receipt_url = orderDetails?.receipt_url;
      const store_name = orderDetails?.store?.name;
      const encodedText = encodeURIComponent(`Dear customer,
            Here is your receipt for the recent purchase from (${store_name}). Kindly click the link below to download 
            your receipt. ${receipt_url}`);
      const url = `https://wa.me/${`+${
        customerPhoneCode || business_phone_code
      }`}${phone_number}?text=${encodedText}`;
      window.open(url, "_blank");
    }
  };

  return {
    isSendingReceipt: sendOrderReceiptMutation?.isLoading,
    sendReceiptOption,
    handleSendReceipt,
    setSendReceiptOption,
    setCustomerPhoneCode,
  };
};

export default useSendReceiptV2;
