import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  cleanObject,
  getRandomIntInclusive,
  getUrlQuerysection,
} from "../../../helpers";
import { useCreateBankDebitMutation } from "../../../hooks/api/mutations/useAccounting";
import { useGetAccountingBanksList } from "../../../hooks/api/queries";

const useAddDebitToBankAccount = ({
  closeWidget,
  bankInfo,
  handleFetchBanks,
  type,
  handleFetchBankTransaction,
}) => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const createBankDebitMutation = useCreateBankDebitMutation();
  const queries = getUrlQuerysection();

  const [bankDetails, setBankDetails] = useState({
    amount: bankInfo?.amount || "",
    description: bankInfo?.description || "",
    store_id: bankInfo?.store?.id || "",
    payment_method: type === "cash" ? "cash" : bankInfo?.account_type || "",
    source: "user_entry",
  });

  const handleAddBankTransaction = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...bankDetails };
    createBankDebitMutation.mutate(cleanObject(data), {
      onSuccess: () => {
        handleFetchBanks();
        handleFetchBankTransaction();
        triggerToast("Debit added successfully", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: handleFetchBankAccountsList,
  } = useGetAccountingBanksList({ queries });

  return {
    bankDetails,
    bankAccount,
    isLoadingBankAccountsList,
    handleFetchBankAccountsList,
    setBankDetails,
    storeList,
    handleAddBankTransaction,
    createBankDebitMutation,
  };
};

export default useAddDebitToBankAccount;
