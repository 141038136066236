import { ToastContext } from "../../../hooks/context";
import { useContext } from "react";
import primaryComponents from "../../primaryComponents";
import { useDeleteRiderMutation } from "../../../hooks/api/mutations/useRiders";

const RidersDelete = ({ riderDetails, closeModal, handleFetchRiders }) => {
  const deleteRider = useDeleteRiderMutation();
  const triggerToast = useContext(ToastContext);

  const handleDeleteRiderById = () => {
    deleteRider.mutate(riderDetails?.id, {
      onSuccess: () => {
        handleFetchRiders();
        triggerToast("Successfully deleted this rider", "success");
        closeModal();
      },
    });
  };

  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        Are you sure you want to delete this rider
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="dark--text font-weight-semibold mb-1">
            Rider Name
          </span>
          <span>{riderDetails?.name || "N/A"}</span>
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={closeModal}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={handleDeleteRiderById}
          classNames={"btn btn--danger cover"}
          isLoading={deleteRider.isLoading}
          isDisabled={deleteRider.isLoading}
          loadingText="Deleting..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default RidersDelete;
