import "./ProductCategoryTable.scss";
import secondaryComponents from "..";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductCategoryTableLogic from "./ProductCategoryTableLogic";
import Images from "../../../assets/images";

const ProductCategoryTable = ({ categories }) => {
  const {
    instance,
    isOpenDeleteDialog,
    isOpenEditDialog,
    selectedCategory,
    isDeletingCategory,
    setIsOpenDeleteDialog,
    setIsOpenEditDialog,
    handleDeleteProductCategory,
  } = ProductCategoryTableLogic({
    categories,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="product-category-table">
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center pt-5 pb-5">
          <img width="250px" src={Images.productCategoryEmptyState} alt="" />
          <h4 className="font-weight-semibold mt-4 text-center">
            You have no product categories
          </h4>
          <p className="mt-2 text-center">
            Use the button above to add your categories. <br />
            When you do, they would appear here.
          </p>
        </div>
      </secondaryComponents.TableHelper>

      {isOpenEditDialog && (
        <secondaryComponents.EditProductCategory
          isOpen={isOpenEditDialog}
          setIsOpen={setIsOpenEditDialog}
          category={selectedCategory}
        />
      )}

      {isOpenDeleteDialog && (
        <secondaryComponents.ConfirmDialog
          title="Are you sure you want to delete this
                                category?"
          isOpen={isOpenDeleteDialog}
          setIsOpen={setIsOpenDeleteDialog}
          handler={handleDeleteProductCategory}
          isLoading={isDeletingCategory}
        >
          <div
            style={{
              backgroundColor: "#F3F1FC",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              borderRadius: "8px",
            }}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="dark--text font-weight-semibold mb-1">
                Category Name
              </span>
              <span>{selectedCategory.name || "N/A"}</span>
            </p>
            <p
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="dark--text font-weight-semibold mb-1">
                Description
              </span>
              <span>{selectedCategory.description || "N/A"}</span>
            </p>
          </div>
        </secondaryComponents.ConfirmDialog>
      )}
    </div>
  );
};

export default ProductCategoryTable;
