import styles from "./SubscriptionPlanHeader.module.scss";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { formatCurrency } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import { PERIOD } from "../../../helpers/constants";

const SubscriptionPlanHeader = ({
  subscriptionTiers,
  activeSubscription,
  subscriptionPeriod,
  setUpgradeDetails,
  setIsPayModal,
}) => {
  return (
    <>
      <div className={styles.subscriptionPlanHeader}>
        <div
          className={`${styles.card} ${styles.select}`}
          style={{ position: "relative", height: "200px" }}
        >
          <h5 className="mt-4">Select a Plan</h5>
          <div
            className="orange--subscription p-3"
            style={{
              position: "absolute",
              bottom: "0",
              left: "0",
              right: "0",
              borderBottomLeftRadius: "15px",
            }}
          >
            <span className="error--text">Please Note:</span>
            <p className="small-text-size">
              Please note all prices are VAT inclusive
            </p>
          </div>
        </div>
        {subscriptionTiers?.map((subscription) => (
          <div
            className={`${styles.card} ${
              activeSubscription?.pricing?.tier?.name === subscription?.name
                ? styles.active
                : ""
            }`}
            key={subscription?.id}
          >
            {activeSubscription?.pricing?.tier?.name === subscription?.name ? (
              <primaryComponents.Button
                classNames={`btn btn--outline mx-auto
            ${styles.btn}`}
                isDisabled={true}
              >
                Your Current Plan
              </primaryComponents.Button>
            ) : null}

            <p
              className={`${globalStyles.plan} ${
                subscription?.name === "Essential"
                  ? globalStyles.essentialPlan
                  : subscription?.name === "Growth"
                    ? globalStyles.growthPlan
                    : subscription?.name === "Scale"
                      ? globalStyles.scalePlan
                      : ""
              } ${
                activeSubscription?.pricing?.tier?.name === subscription?.name
                  ? "mx-auto"
                  : ""
              }`}
            >
              {subscription?.name}
            </p>
            <h5>
              {" "}
              <span
                className="error--text"
                style={{ fontSize: "30px", lineHeight: 0 }}
              >
                .
              </span>
              <span className="dark--text font-weight-bold">
                {formatCurrency({
                  value: subscription?.pricings?.find(
                    (item) => item?.validity === subscriptionPeriod,
                  )?.price,
                  currencyCode: subscription?.pricings?.find(
                    (item) => item?.validity === subscriptionPeriod,
                  )?.currency,
                  decimal: 0,
                })}
              </span>
              <span className="small-text-size gray--text font-weight-normal">
                / {PERIOD?.[subscriptionPeriod]}
              </span>
            </h5>
            {activeSubscription?.pricing?.tier?.name !== subscription?.name &&
            subscription?.name !== "Free" ? (
              <primaryComponents.Button
                classNames={`btn btn--primary
            ${styles.btn}`}
                onClick={() => {
                  setUpgradeDetails({
                    type: subscription?.name,
                    currentSubcription: subscription,
                  });
                  setIsPayModal(true);
                }}
              >
                Select
              </primaryComponents.Button>
            ) : activeSubscription === null ||
              activeSubscription === undefined ? (
              <primaryComponents.Button
                classNames={`btn btn--primary
        ${styles.btn}`}
                onClick={() => {
                  setUpgradeDetails({
                    type: subscription?.name,
                    currentSubcription: subscription,
                  });
                  setIsPayModal(true);
                }}
              >
                Select
              </primaryComponents.Button>
            ) : null}
          </div>
        ))}
      </div>
    </>
  );
};
export default SubscriptionPlanHeader;
