/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import primaryComponents from "../../primaryComponents";
import styles from "./ProductSearch.module.scss";
import useProductSearch from "./useProductSearch";
import secondaryComponents from "..";
import Icon from "../../../assets/icons";
import { motion } from "framer-motion";
import { formatCurrency } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const ProductSearch = ({
  addProduct,
  orderDetails = null,
  buttonName = "Add Item",
  filterByStore = true,
  filterByComposite = true,
  excludeManageModeOnlyProducts = false,
  isBillUpdate = false,
}) => {
  const { store_currency } = useStoreCurrency();
  const {
    serachRef,
    isLoading,
    searchValue,
    productList,
    isDropDown,
    isVariantOpenId,
    setIsDropDown,
    searchProduct,
    setIsVariantOpenId,
  } = useProductSearch({
    orderDetails,
    filterByStore,
    filterByComposite,
    excludeManageModeOnlyProducts,
  });

  return (
    <div className={styles.productSearch} ref={serachRef}>
      <primaryComponents.Button
        classNames={`btn btn--outline cover ${styles.addBtn}`}
        onClick={() => setIsDropDown(true)}
      >
        <img src={Icon.addCriclePlus} className="mr-2" alt="" />
        {buttonName}
      </primaryComponents.Button>

      {/* dropdown section */}
      {isDropDown ? (
        <motion.div
          initial={{ y: 50 }}
          animate={{
            y: 0,
          }}
          className={styles.resultSection}
        >
          <primaryComponents.SearchField
            triggerOnInput={true}
            value={searchValue}
            placeholder="Search products or services"
            useControlledValue={true}
            trigger={(value) => searchProduct(value)}
            classNames={styles.searchSection}
          />
          <secondaryComponents.LoaderHelper
            isLoading={isLoading}
            classNames="mt-5 mt-5"
          >
            {productList?.length > 0 ? (
              <div className={styles.nameContainer}>
                {productList?.map((item) => {
                  const productTypeName = item?.is_service
                    ? "store_service_properties"
                    : "store_product_properties";
                  return (
                    <div key={item?.id} style={{ borderBottom: "#ddd" }}>
                      <div
                        className={`${styles.listItemParent} ${
                          item?.variants?.length > 0 ? styles.isVariant : ""
                        } ${item?.id === isVariantOpenId ? styles.active : ""}`}
                        onClick={() => {
                          if (item?.variants?.length > 0) {
                            if (isVariantOpenId === item?.id)
                              setIsVariantOpenId(null);
                            else setIsVariantOpenId(item?.id);
                          } else {
                            const data = {
                              is_product: !item?.is_service,
                              quantity: 1,
                              store_id: orderDetails?.store?.id,
                              name: `${item?.name}`,
                              pricing_type_id:
                                item?.[productTypeName]?.[0]?.pricings?.[0]
                                  ?.pricing_type?.id,
                              unit: item?.unit,
                            };
                            if (item?.is_service) data.service_id = item?.id;
                            else data.product_id = item?.id;

                            if (isBillUpdate) {
                              data.unit = item?.unit;
                              data.store_product_properties = item?.is_service
                                ? item?.store_service_properties
                                : item?.store_product_properties;
                              data.id = `${item?.id}`;
                            }
                            addProduct(data);
                          }
                        }}
                      >
                        <p className="mb-0">{item?.name}</p>
                        <p className="mb-0">
                          {item?.variants?.length > 0 ? (
                            <span
                              className="d-flex align-items-center"
                              style={{ width: "fit-content" }}
                            >
                              <span>
                                {item?.variants?.length}{" "}
                                {item?.variants?.length > 1
                                  ? "variants"
                                  : "variant"}
                              </span>
                              <FontAwesomeIcon
                                className="ml-2"
                                icon={`${
                                  isVariantOpenId === item?.id
                                    ? "angle-up"
                                    : "angle-down"
                                }`}
                              />
                            </span>
                          ) : (
                            <>
                              {filterByStore ? (
                                <>
                                  <span>
                                    {formatCurrency({
                                      value:
                                        item?.[productTypeName]?.[0]
                                          ?.pricings?.[0]?.price,
                                      currencyCode: store_currency,
                                    })}
                                  </span>
                                  <span className="ml-2">
                                    (
                                    {
                                      item?.[productTypeName]?.[0]
                                        ?.pricings?.[0]?.pricing_type?.label
                                    }
                                    )
                                  </span>
                                </>
                              ) : null}
                            </>
                          )}
                        </p>
                      </div>

                      {isVariantOpenId === item?.id &&
                        item?.variants?.map((variantItem) => (
                          <motion.div
                            initial={{ y: 20 }}
                            animate={{
                              y: 0,
                            }}
                            key={variantItem?.id}
                            className={`${styles.listItemParent} ${styles.isSubVariant}`}
                            onClick={() => {
                              const data = {
                                name: `${item?.name} - (${variantItem?.option}: ${variantItem?.value})`,
                                product_id: item?.id,
                                is_product: !item?.is_service,
                                quantity: 1,
                                store_id: orderDetails?.store?.id,
                                variant_id: variantItem?.id,
                                pricing_type_id:
                                  variantItem?.store_variant_properties?.[0]
                                    ?.pricings?.[0]?.pricing_type?.id,
                                unit: item?.unit,
                              };
                              if (isBillUpdate) {
                                data.store_product_properties =
                                  variantItem?.store_variant_properties;
                                data.id = `${item?.id}${variantItem?.id}`;
                              }
                              addProduct(data);
                            }}
                          >
                            <p className="mb-0">
                              {variantItem?.option} - {variantItem?.value}
                            </p>
                            {filterByStore ? (
                              <p className="mb-0">
                                {formatCurrency({
                                  value:
                                    variantItem?.store_variant_properties?.[0]
                                      ?.pricings?.[0]?.price,
                                  currencyCode: store_currency,
                                })}

                                <span className="ml-2">
                                  (
                                  {
                                    variantItem?.store_variant_properties?.[0]
                                      ?.pricings?.[0]?.pricing_type?.label
                                  }
                                  )
                                </span>
                              </p>
                            ) : null}
                          </motion.div>
                        ))}
                    </div>
                  );
                })}
              </div>
            ) : (
              <h5 className="mt-5 mb-5 text-center">No Data Available</h5>
            )}
          </secondaryComponents.LoaderHelper>
        </motion.div>
      ) : null}
    </div>
  );
};
export default ProductSearch;
