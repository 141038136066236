/* eslint-disable react/jsx-key */
import styles from "./ReconciliationsTable.module.scss";
import secondaryComponents from "..";
import useReconciliationsTable from "./useReconciliationsTable";
import Images from "../../../assets/images";

const ReconciliationsTable = ({
  metaDetails,
  reconciliations,
  handleFetchReconciliations,
  gotoPage,
}) => {
  const { instance } = useReconciliationsTable({
    reconciliations,
    handleFetchReconciliations,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.reconciliationsTable}>
      <div className={styles.reconciliationsTable__content}>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.reconciliationEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have made no reconciliations yet
            </h4>
            <p className="text-center">
              Use the button above to start reconciliation. When you do, they
              would appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>

        {reconciliations?.length > 0 && (
          <div className="mt-auto">
            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReconciliationsTable;
