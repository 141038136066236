import { useTable } from "react-table";
import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { formatCurrency, getPlatform, truncateString } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
import { useQuery } from "react-query";
import { getExpenseDetails } from "../../../services/expenses";
import { platformHandler } from "../../../hooks/context";
import useGetPermission from "../../../hooks/useGetPermission";

const useExpensesTable = ({ expenses }) => {
  const [isReviewExpense, setIsReviewExpense] = useState(false);
  const [expenseId, setExpenseId] = useState(null);
  const { platform } = useContext(platformHandler);
  const { isPermitted } = useGetPermission({
    permissionParentKey: "Expense",
    permissionChildKey: "can_approve_expense",
  });
  const statusMap = {
    APPROVED: "success--squared",
    DISBURSED: "success--squared",
    PENDING: "pending--squared",
    DECLINED: "failed--text",
  };

  const columns = useMemo(
    () => [
      {
        Header: "Date Created",
        accessor: "expense_date",
        Cell: ({ cell: { value } }) =>
          value ? new Date(value).toLocaleString("en-us") : "N/A",
      },
      {
        Header: "Expense Name",
        accessor: "name",
        Cell: ({ row, cell: { value } }) => (
          <Link
            className="purple--text"
            to={`${getPlatform(platform)}/expenses/${row?.original?.id}`}
            style={{ fontSize: "12px", textDecoration: "underline !important" }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Category",
        accessor: "category",
        Cell: ({ cell: { value } }) => (value ? value?.name : "N/A"),
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell }) =>
          formatCurrency({
            value: cell.value,
            currencyCode: cell?.row?.original?.store?.currency_code,
          }),
      },
      {
        Header: "Initiated By",
        accessor: "initiated_by",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value?.first_name, 15) : "N/A",
      },
      {
        Header: "Approved By",
        accessor: "reviewed_by",
        Cell: ({ cell: { value } }) =>
          value ? truncateString(value?.first_name, 15) : "N/A",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span className={`${statusMap[value]} text-capitalize`}>{value}</span>
        ),
      },
      {
        Header: "Action",
        Cell: ({ row }) => (
          <primaryComponents.Button
            onClick={() => {
              setIsReviewExpense(true);
              setExpenseId(row?.original?.id);
            }}
            classNames={"btn btn--primary smallBtn"}
            isDisabled={row?.original?.status !== "PENDING" || !isPermitted}
          >
            Review
          </primaryComponents.Button>
        ),
      },
    ],
    [],
  );

  const { data: expenseDetails, isLoading: isFetchingExpenseDetails } =
    useQuery(
      ["getExpenseDetails", expenseId],
      () =>
        getExpenseDetails(expenseId).then((res) => {
          return res.data?.data;
        }),
      {
        enabled: expenseId !== null && isReviewExpense,
      },
    );

  const instance = useTable({ columns, data: expenses });
  return {
    instance,
    isReviewExpense,
    isFetchingExpenseDetails,
    expenseDetails: expenseDetails || {},
    setIsReviewExpense,
  };
};

export default useExpensesTable;
