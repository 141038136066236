import "./NewCustomersChart.scss";
import NewCustomersChartLogic from "./NewCustomersChartLogic";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const NewCustomersChart = ({ newCustomerData }) => {
  const { xAxisFormatter } = NewCustomersChartLogic();
  return (
    <div className="new-customers-chart">
      <div className="new-customers-chart__header">
        <h4 style={{ fontSize: "20px" }} className="font-weight-semibold">
          New Customers
        </h4>
        <div className="text-right">
          <h4 className="font-weight-semibold">{newCustomerData.count}</h4>
          {/* <span style={{ fontSize: "12px", color: "#19AE57" }}>
                        +14% from last week
                    </span> */}
        </div>
      </div>
      {newCustomerData.trend.length > 0 ? (
        <ResponsiveContainer
          width="100%"
          aspect={2}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          className="new-customer-chart__main"
        >
          <BarChart width="auto" height={250} data={newCustomerData.trend}>
            <CartesianGrid
              stroke="#E8E9EB"
              strokeDasharray="5 0"
              horizontal={true}
              vertical={false}
            />
            <XAxis
              tickLine={false}
              tick={{ fontSize: "10px" }}
              axisLine={false}
              dataKey="date"
              tickFormatter={xAxisFormatter}
            />
            <YAxis
              tick={{ fontSize: "10px" }}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip
              wrapperStyle={{ border: "none", outline: "none" }}
              content={<secondaryComponents.CustomerChartTooltip />}
            />
            <Bar dataKey="count" fill="#19AE57" barSize={35} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div className="mt-5 text-center">
          <img
            width={"150px"}
            src={Images.customersEmptyState}
            alt="No transactions"
          />
          <p className="font-weight-semibold mt-3 dark--text">
            No data available
          </p>
        </div>
      )}
    </div>
  );
};

export default NewCustomersChart;
