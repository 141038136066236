import { useContext } from "react";
import Icon from "../../../assets/icons";
import primaryComponents from "../../../components/primaryComponents";
import styles from "./TableCard.module.scss";
import useTableCard from "./useTableCard";
import { ToastContext } from "../../../hooks/context";
import { setTableDetails } from "../../../store/modules/table";
import { useDispatch } from "react-redux";

const TableCard = ({
  table,
  setTableCardId,
  resetView,
  refetchOrderTables,
}) => {
  const dispatch = useDispatch();
  const triggerToast = useContext(ToastContext);
  const { showMenu, setShowMenu, popMenuRef, isLoading, mutate } = useTableCard(
    {
      refetchOrderTables,
    },
  );

  return (
    <div
      className={`${styles.card} ${
        table.status === "FREE" ? styles.card_green : styles.card_red
      }`}
    >
      <div className={styles.title_diners_conatiner}>
        <div className={styles.title_container}>
          <>
            <p className={styles.table_name_desktop}>{table.name}</p>
            <p className={styles.table_name_mobile}>
              {table.name.length > 5
                ? `${table.name.slice(0, 5)}...`
                : table.name}
            </p>
          </>
          <div className={styles.seats_container}>
            <p>{table.seat_capacity} seats</p>
            <img
              ref={popMenuRef}
              src={Icon.tableMenuIcon}
              className={styles.menu}
              alt="menu"
              onClick={() => setShowMenu(true)}
            />

            <div>
              {/* Menu Pop up */}
              {showMenu && (
                <div className={styles.menu_container}>
                  <p
                    onClick={() => mutate(table.id)}
                    className={styles.clear_table}
                  >
                    {isLoading ? "Clearing table..." : "Clear table"}
                  </p>
                  <p
                    onClick={() => {
                      setTableCardId(table.id);
                      resetView("isTable", false);
                      resetView("isOpenBillsWidget", true);
                    }}
                    className={styles.view_bills}
                  >
                    View Bills
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.diners_container}>
          <div className={styles.diner_icon}>
            <img
              src={
                table.status === "FREE" ? Icon.dinersIconGreen : Icon.dinersIcon
              }
              alt="diners"
            />
          </div>
          <p>{table.diners} diners</p>
        </div>
      </div>
      <primaryComponents.Button
        type="button"
        classNames={`btn smallBtn ${
          table.status === "FREE" ? "btn--outline-green" : "btn--outline-red"
        }`}
        onClick={() => {
          dispatch(setTableDetails(table));
          triggerToast(
            "Table selected. Start adding orders to this table",
            "success",
          );
          resetView("isTable", false);
        }}
      >
        Add new order
      </primaryComponents.Button>
    </div>
  );
};

export default TableCard;
