import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const RejectionReasonSchema = yup.object().shape({
  rejectionReason: yup.string().required("Rejection reason is required"),
});

const Validation = (rejectionReason) => {
  return useForm({
    resolver: yupResolver(RejectionReasonSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      rejectionReason,
    },
  });
};

export default Validation;
