import { useQuery } from "react-query";
import { getAllCustomRoles } from "../../../../../../services/settings";
import { useState } from "react";

const useCustomRoles = () => {
  const [pageNumber] = useState(2);
  const { isLoading, data, refetch } = useQuery(["roles-custom"], () =>
    getAllCustomRoles(pageNumber).then((res) => res.data?.data),
  );

  return {
    isLoading,
    data,
    refetch,
  };
};

export default useCustomRoles;
