import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  menuConfirmCustomer,
  menuOrder,
  ordersTables,
} from "../../../../../services/inventory";
import { ToastContext } from "../../../../../hooks/context";
import { cleanObject } from "../../../../../helpers";
import { useSelector } from "react-redux";
import countriesList from "../../../../../localdata/worldCountries.json";
import { useGetStoreFrontShippingGroup } from "../../../../../hooks/api/queries";

const useCheckoutForm = ({
  setShowCheckoutModal,
  setShowOrderSummaryModal,
  setShowOrderSummary,
  selectedOrderType,
  setSelectedOrderType,
  menuObjectDetail,
}) => {
  const triggerToast = useContext(ToastContext);
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);
  const countryCode = countriesList
    .filter((item) => item.countryCode !== "undefined")
    .find(
      (item) => item.currencyCode === menuCartItems[0].store?.currency_code,
    )?.countryCode;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [numberOfDiners, setNumberOfDiners] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  // const [orderType, setOrderType] = useState("DINE_IN");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [shippingId, setShippingId] = useState("");

  useEffect(() => {
    if (
      menuObjectDetail?.allow_in_store_checkout &&
      menuObjectDetail?.allow_delivery
    ) {
      setSelectedOrderType("DINE_IN");
    } else if (menuObjectDetail?.allow_in_store_checkout) {
      setSelectedOrderType("DINE_IN");
    } else {
      setSelectedOrderType("DELIVERY");
    }
  }, [menuObjectDetail]);

  const { isLoading: isLoadingConfirmCustomer, mutate: mutateConfirmCustomer } =
    useMutation({
      mutationFn: (data) => menuConfirmCustomer({ data }),
      onSuccess: (data) => {
        setShowCheckoutModal(false);
        setShowOrderSummary(data);
        setShowOrderSummaryModal(true);
      },
    });
  const { data: shippingLocation, isLoading: isLoadingShippingLocation } =
    useGetStoreFrontShippingGroup(menuObjectDetail?.id);

  const { isLoading: isLoadingOrder, mutate: mutateOrder } = useMutation({
    mutationFn: (data) => menuOrder({ data }),
    onSuccess: (data) => {
      const guest_token = localStorage.getItem("guest_token");
      triggerToast(data.data.detail, "success");
      localStorage.setItem("order_id", data?.data?.data?.id);

      if (menuObjectDetail) {
        mutateConfirmCustomer(
          cleanObject({
            storefront_id: menuObjectDetail?.id,
            guest_token,
            order_id: data?.data?.data?.id,
            customer_name: name,
            customer_phone_code: phoneCode || countryCode,
            customer_phone_number: phoneNumber,
            customer_email: email,
            order_table_id: tableNumber,
            diners: numberOfDiners,
            order_notes: orderNotes,
            order_type: selectedOrderType,
            zipcode: postalCode,
            customer_address:
              streetAddress || apartment
                ? streetAddress + `, ${apartment}`
                : "",
            state: state,
            country: country,
            shipping_group_id: shippingId,
          }),
        );
      }
    },
  });

  const { data: dataTables } = useQuery(
    ["orders-tables", menuObjectDetail],
    () => ordersTables(menuObjectDetail?.id).then((res) => res?.data?.data),
    { enabled: menuObjectDetail ? true : false },
  );

  const submitDetails = () => {
    const guest_token = localStorage.getItem("guest_token");
    if (menuObjectDetail) {
      mutateOrder({
        storefront_id: menuObjectDetail.id,
        guest_token,
      });
    }
  };

  const mergeCountries = (shippingLocation) => {
    const mergedData = shippingLocation?.reduce((acc, current) => {
      const existingCountry = acc.find(
        (item) => item.country.id === current?.country.id,
      );

      if (existingCountry) {
        existingCountry.states = [
          ...existingCountry.states,
          ...current.states.filter(
            (state) => !existingCountry.states.some((s) => s.id === state.id),
          ),
        ];

        existingCountry.cities = [
          ...existingCountry.cities,
          ...current.cities.filter(
            (city) => !existingCountry.cities.some((c) => c.id === city.id),
          ),
        ];
      } else {
        acc.push({
          ...current,
          states: current.states || [],
          cities: current.cities || [],
        });
      }

      return acc;
    }, []);

    return mergedData;
  };

  return {
    mergeCountries,
    countriesList,
    isLoadingShippingLocation,
    shippingLocation,
    setShippingId,
    cities,
    states,
    setCities,
    setStates,
    name,
    email,
    phoneNumber,
    phoneCode,
    numberOfDiners,
    tableNumber,
    orderNotes,
    // orderType,
    isLoadingOrder,
    isLoadingConfirmCustomer,
    countryCode,
    dataTables,
    menuObjectDetail,
    country,
    state,
    postalCode,
    streetAddress,
    apartment,
    setName,
    setEmail,
    setPhoneNumber,
    setPhoneCode,
    setNumberOfDiners,
    setTableNumber,
    setOrderNotes,
    // setOrderType,
    setCountry,
    setState,
    setPostalCode,
    setStreetAddress,
    setApartment,
    submitDetails,
  };
};

export default useCheckoutForm;
