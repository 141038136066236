import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const fundCheckoutSchema = yup
  .object({
    password: yup.string().required("Password is required"),
  })
  .required();

const FundCheckoutValidation = (password) => {
  return useForm({
    resolver: yupResolver(fundCheckoutSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      password: password,
    },
  });
};
export default FundCheckoutValidation;
