import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { createStore, getStoreCategoryList } from "../../../services/inventory";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
// import { useNavigate } from "react-router-dom";

const StoresTableModalLogic = ({ setIsActive, handleFetchStores }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [storeName, setStoreName] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [settlementAccount, setSettlementAccount] = useState("");
  const [vat, setVat] = useState(0);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const [isWarehouse, setIsWarehouse] = useState(false);
  const [storeCategory, setStoreCategory] = useState("");
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { data: category_list } = useQuery(["storeCategoryList"], () =>
    getStoreCategoryList().then((res) => {
      return res?.data?.data;
    }),
  );
  // const navigate = useNavigate();

  const handleAddStore = () => {
    setIsLoading(true);
    setRequestLoaderProgress(40);

    createStore({
      name: storeName,
      address: storeAddress,
      subaccount: parseInt(settlementAccount),
      store_category: parseInt(storeCategory),
      vat: vat === "" ? 0 : vat,
      is_warehouse: isWarehouse,
    })
      .then(() => {
        setIsLoading(false);
        setRequestLoaderProgress(100);
        handleFetchStores();
        // navigate(getPlatform(platform) + "/products/categories");
        setIsActive(false);
        triggerToast("Store added successfully", "success");
      })
      .catch((error) => {
        setIsLoading(false);
        setRequestLoaderProgress(100);

        if (error.response.status === 422) {
          if (error.response.data.errors.settlement_account) {
            window.setNewStoreError(
              "settlementAccount",
              {
                type: "validate",
                message: error.response.data.errors.settlement_account[0],
              },
              { shouldFocus: true },
            );
            triggerToast(
              error.response.data.errors.settlement_account[0],
              "warning",
            );
          }
        }
      });
  };

  return {
    isLoading,
    storeName,
    storeAddress,
    settlementAccount,
    storeCategory,
    business_list,
    vat,
    isWarehouse,
    category_list: category_list || [],
    setVat,
    handleAddStore,
    setStoreName,
    setStoreAddress,
    setSettlementAccount,
    setIsWarehouse,
    setStoreCategory,
  };
};

export default StoresTableModalLogic;
