import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import styles from "./InvoiceDetails.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  formatCurrency,
  formatDateToString,
  formatDateYYYYMMDD,
} from "../../../../helpers";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useInvoiceDetails } from "./useInvoiceDetails";
import { useSelector } from "react-redux";

const InvoiceDetails = () => {
  const navigate = useNavigate();
  const {
    isFetchingInvoiceDetails,
    isFetchingSupplier,
    suppliers,
    invoiceDetails,
  } = useInvoiceDetails();
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Invoice Details"
      description="View invoice details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={styles.invoiceDetails}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper
            classNames={"mt-5"}
            isLoading={isFetchingInvoiceDetails || isFetchingSupplier}
          >
            <div className={styles.invoiceDetails__content}>
              <div className={styles.invoiceDetails__content__header}>
                <h4>Invoice Details</h4>
                <div className={styles.supplierSection}>
                  <div>
                    <primaryComponents.SelectField
                      name="supplierName"
                      label={"Supplier Name"}
                      options={suppliers}
                      giveControl={false}
                      value={{
                        label:
                          invoiceDetails?.supplier !== null
                            ? suppliers?.find(
                                (item) =>
                                  item?.id === invoiceDetails?.supplier?.id,
                              )?.name
                            : "Select supplier",
                        value: invoiceDetails?.supplier?.id,
                      }}
                      isDisabled
                    />
                  </div>
                  <div className="mt-2">
                    <primaryComponents.InputField
                      name="invoiceNumber"
                      label={"Invoice/Receipt Number"}
                      classNames="white"
                      value={invoiceDetails?.invoice_number}
                      isDisabled
                    />
                  </div>
                  <div className="mt-2">
                    <primaryComponents.InputField
                      name="orderNumber"
                      label={"Order Number"}
                      classNames="white"
                      value={invoiceDetails?.order_number}
                      isDisabled
                    />
                  </div>

                  <primaryComponents.DateInput
                    name="invoiceDate"
                    label={"Invoice/Receipt Date"}
                    value={formatDateYYYYMMDD(invoiceDetails?.invoice_date)}
                    isDisabled
                  />
                </div>
              </div>

              {/* table section */}
              <secondaryComponents.InvoiceHistoryTable
                historyData={invoiceDetails?.histories}
              />

              {/* price summary */}
              <div
                className={`${styles.summarySection} ${styles.individualBody}`}
              >
                <h4 className="mb-3">Price Summary</h4>
                <div className={styles.summaryRow}>
                  <p>Subtotal:</p>
                  <p className="font-weight-bold">
                    {formatCurrency({
                      value: invoiceDetails?.sub_total,
                      currencyCode: currency_code,
                      decimal: 2,
                    })}
                  </p>
                </div>
                <div className={styles.summaryRow}>
                  <p>Discount:</p>
                  <p className="font-weight-bold">
                    {formatCurrency({
                      value: invoiceDetails?.discount,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
                <div className={styles.summaryRow}>
                  <p>VAT (%):</p>
                  <p className="font-weight-bold">{invoiceDetails?.vat}</p>
                </div>
                <div className={styles.summaryRow}>
                  <p>Total:</p>
                  <p className="purple--text font-weight-bold">
                    {formatCurrency({
                      value: invoiceDetails?.total,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
              </div>

              {/* payment section */}
              <div
                className={`${styles.summarySection} ${styles.individualBody}`}
              >
                <h4 className="mb-3">Payment Summary</h4>

                <div className={styles.summaryRow}>
                  <p>Amount paid to supplier:</p>
                  <p className="purple--text font-weight-bold">
                    {formatCurrency({
                      value: invoiceDetails?.amount_paid,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
                <div className={styles.summaryRow}>
                  <p>Payment Method:</p>
                  <p className="font-weight-bold">
                    {invoiceDetails?.payment_method
                      ? invoiceDetails?.payment_method
                      : "N/A"}
                  </p>
                </div>
                <div className={styles.summaryRow}>
                  <p>Outstanding Balance:</p>
                  <p className="purple--text font-weight-bold">
                    {formatCurrency({
                      value: invoiceDetails?.outstanding_balance,
                      currencyCode: currency_code,
                    })}
                  </p>
                </div>
                <div className={styles.summaryRow}>
                  <p>Repayment/Invoice Due Date:</p>
                  <p className="font-weight-bold">
                    {formatDateToString(
                      invoiceDetails?.invoice_due_date,
                      currency_code,
                    )}
                  </p>
                </div>
              </div>

              {/* Notes section */}
              {(invoiceDetails?.notes || invoiceDetails?.invoice_url) && (
                <div className={styles.individualBody}>
                  <h4 className="mb-3">Notes</h4>
                  <div className={styles.notesSection}>
                    {invoiceDetails?.notes && (
                      <div className={styles.noteField}>
                        <primaryComponents.InputField
                          value={invoiceDetails?.notes}
                          classNames="white"
                          isDisabled
                        />
                      </div>
                    )}
                    {invoiceDetails?.invoice_url && (
                      <div className={styles.invoiceField}>
                        <h5>Invoice Image</h5>
                        <a
                          style={{ textDecoration: "underline" }}
                          className="purple--text"
                          href={invoiceDetails?.invoice_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Click to view invoice image
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default InvoiceDetails;
