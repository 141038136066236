import "./Checkbox.scss";

const Checkbox = ({ isChecked = false, name, id, ...props }) => {
  const onChange = () => {
    props.onChange(!isChecked);
  };
  return (
    <span className="checkbox mr-4">
      <input
        {...props}
        name={name}
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={onChange}
      />
      <label htmlFor={id}></label>
    </span>
  );
};

export default Checkbox;
