import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import { useGetTrackItems } from "../../../../hooks/api/queries";
import { useState } from "react";
import { cleanObject } from "../../../../helpers";

const ItemTrackingSelection = ({
  closeModal,
  productProperties,
  cartDetails,
  handleAddToCart = () => {},
  handleReduceCartItem = () => {},
}) => {
  const product = productProperties?.product;
  const variant = productProperties?.variant;
  const store_properties = productProperties?.store_properties;
  const [params, setParams] = useState(
    cleanObject({
      store_id: store_properties?.store_id,
      product_id: product?.id,
      variant_id: productProperties?.variant?.id,
    }),
  );
  const { isLoading, data } = useGetTrackItems({
    queries: "",
    otherParams: params,
  });
  const trackItems = data?.data;

  return (
    <div className="d-flex flex-column px-2" style={{ gap: "15px" }}>
      <primaryComponents.Button
        classNames={"ml-auto btn btn--success smallBtn"}
        onClick={closeModal}
      >
        Continue shopping
      </primaryComponents.Button>

      <h5 className="mb-0">
        Select the Tracking Code for the Item You Wish to Sell
        <span
          className="ml-3 smallest-text-size dark--text ash px-2 py-1"
          style={{ borderRadius: "7px" }}
        >
          {product?.name}
        </span>
      </h5>

      <primaryComponents.SearchField
        placeholder="Search tracking code"
        trigger={(value) => setParams((prev) => ({ ...prev, query: value }))}
        value={params?.query || ""}
        triggerOnInput={true}
        showsearchBtn={true}
      />

      <secondaryComponents.LoaderHelper
        isLoading={isLoading}
        classNames={"mt-4"}
      >
        <div className="d-flex flex-column" style={{ gap: "10px" }}>
          {trackItems?.map((item) => {
            const isInCart = cartDetails?.item_numbers?.includes(
              item?.item_number,
            );
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ gap: "10px" }}
                key={item?.item_number}
              >
                <p className="dark--text">{item?.item_number}</p>
                <primaryComponents.Button
                  classNames={`btn ${
                    isInCart ? "btn--outline" : "btn--primary"
                  }`}
                  style={{ width: "125px" }}
                  onClick={() => {
                    const item_numbers =
                      cartDetails?.item_numbers?.length > 0
                        ? [...cartDetails?.item_numbers]
                        : [];
                    if (isInCart) {
                      const indexOfItem = item_numbers.indexOf(
                        item?.item_number,
                      );
                      item_numbers?.splice(indexOfItem, 1);
                      handleReduceCartItem(
                        cleanObject({
                          quantity: Number(cartDetails?.quantity) - 1,
                          product,
                          item_numbers,
                          variant,
                        }),
                      );
                    } else {
                      handleAddToCart(
                        cleanObject({
                          quantity:
                            store_properties?.stock_quantity > 0 &&
                            store_properties?.stock_quantity < 1
                              ? store_properties?.stock_quantity
                              : cartDetails?.quantity + 1 || 1,
                          product,
                          item_numbers: [...item_numbers, item?.item_number],
                          variant,
                        }),
                      );
                    }
                  }}
                >
                  {isInCart ? "Remove" : "Add to cart"}
                </primaryComponents.Button>
              </div>
            );
          })}
        </div>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default ItemTrackingSelection;
