import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    supplierName: yup.string().required("Supplier Name is required"),
    supplierPhone: yup.string().required("Supplier contact is required"),
    supplierContactName: yup
      .string()
      .required("Supplier contact name is required"),
    supplierAddress: yup.string().required("Address is required"),
  })
  .required("All fields are required");

const Validation = ({ supplierDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      supplierName: supplierDetails?.name,
      supplierPhone: supplierDetails?.phone_number,
      supplierContactName: supplierDetails?.contact_name,
      supplierAddress: supplierDetails?.address,
    },
  });
};

export default Validation;
