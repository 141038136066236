export const DeleteIcon = ({ type = "default" }) => {
  return type === "default" ? (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.6"
        y="0.6"
        width="22.8"
        height="22.8"
        rx="4.2"
        stroke="#E02020"
        strokeWidth="1.2"
      />
      <path
        d="M9.19995 7.79902V6.39902C9.19995 5.62582 9.82675 4.99902 10.6 4.99902H13.4C14.1732 4.99902 14.8 5.62582 14.8 6.39902V7.79902M5.69995 7.79902H18.3H5.69995ZM7.09995 7.79902V17.599C7.09995 18.3722 7.72675 18.999 8.49995 18.999H15.5C16.2732 18.999 16.9 18.3722 16.9 17.599V7.79902H7.09995Z"
        stroke="#E02020"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4001 11.2988V15.4988"
        stroke="#E02020"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5999 11.2988V15.4988"
        stroke="#E02020"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83333 3.99967V2.66634C5.83333 1.92996 6.43029 1.33301 7.16667 1.33301H9.83333C10.5697 1.33301 11.1667 1.92996 11.1667 2.66634V3.99967M2.5 3.99967H14.5H2.5ZM3.83333 3.99967V13.333C3.83333 14.0694 4.43029 14.6663 5.16667 14.6663H11.8333C12.5697 14.6663 13.1667 14.0694 13.1667 13.333V3.99967H3.83333Z"
        stroke="#E02020"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83325 7.33301V11.333"
        stroke="#E02020"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16675 7.33301V11.333"
        stroke="#E02020"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
