import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import styles from "./FormStepper.module.scss";

const FormStepper = ({ currentStep, naviagetToStep, steps = [] }) => {
  return (
    <div className={styles.formStepper}>
      {steps?.map((step, index) => (
        <div
          key={`${step.label} + ${step?.id}`}
          className={`${styles.formStepper__step} ${
            index === 0 ? styles.firstStep : ""
          } ${index === steps?.length - 1 ? styles.lastStep : ""}`}
          onClick={() => {
            if (currentStep <= step?.id) return;
            naviagetToStep(step?.id);
          }}
        >
          <div
            className={`${styles.formStepper__stepNumber} ${
              currentStep >= step?.id ? "purple white--text" : ""
            }`}
          >
            {/* checked icon */}
            {currentStep > step?.id && (
              <motion.span
                initial={{ opacity: 0, marginTop: "38px" }}
                animate={{
                  opacity: 1,
                  marginTop: "0px",
                  transition: { duration: 0.3 },
                }}
                exit={{ opacity: 0 }}
              >
                <FontAwesomeIcon icon="check" style={{ fontSize: "18px" }} />
              </motion.span>
            )}

            {/* step number */}
            {currentStep <= step?.id && (
              <motion.span
                initial={{ opacity: 0, marginTop: "38px" }}
                animate={{
                  opacity: 1,
                  marginTop: "0px",
                  transition: { duration: 0.3 },
                }}
                exit={{ opacity: 0 }}
              >
                {step?.id + 1}
              </motion.span>
            )}
          </div>
          {/* label text */}
          <p
            className={`${styles.formStepper__stepTitle} ${
              step?.id === currentStep ? "purple--text" : ""
            }`}
          >
            {step.title}
          </p>
          <p
            className={`${styles.formStepper__stepDesc}`}
            style={{ fontSize: "10px" }}
          >
            {step.description}
          </p>
        </div>
      ))}
    </div>
  );
};
export default FormStepper;
