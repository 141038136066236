import { useContext, useEffect } from "react";
import { updateServiceDetails } from "../../../../../services/inventory";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { RequestLoader, ToastContext } from "../../../../../hooks/context";
import { useGetServiceDetails } from "../../../../../hooks/api/queries/useInventory";
import { getRandomIntInclusive } from "../../../../../helpers";
import { useMultipleImageUpload } from "../useMultipeImageUpload";

export const useEditService = () => {
  const params = useParams();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isLoading: isFetchingService, data: serviceDetails } =
    useGetServiceDetails(params.id);
  const { handleUploadMultipleMediaFiles } = useMultipleImageUpload();

  useEffect(() => {
    queryClient.invalidateQueries(["getServiceDetails", params?.id]);
  }, [params, queryClient]);

  // update service function
  const { mutate: updateService, isLoading: isUpdatingService } = useMutation(
    (data) => updateServiceDetails(data),
  );
  const handleServiceUpdate = (submissionData) => {
    const { media_files } = submissionData;
    setRequestLoaderProgress(getRandomIntInclusive(10, 40));
    updateService(
      { submissionData, id: params.id },
      {
        onSuccess: () => {
          handleUploadMultipleMediaFiles({
            media_files: media_files,
            productId: params?.id,
            item_type: "service",
          });
          triggerToast("Service Updated Sucessfully", "success");
          queryClient.invalidateQueries(["getServiceDetails", params?.id]);
          queryClient.invalidateQueries(["getProductList"]);
          navigate(-1);
        },
        onSettled: setRequestLoaderProgress(100),
      },
    );
  };

  return {
    isFetchingService,
    isUpdatingService,
    serviceDetails,
    handleServiceUpdate,
  };
};
