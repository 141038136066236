import "./CampaignSearchField.scss";
import Icon from "../../../assets/icons";

const CampaignSearchField = ({ placeholder, name, onChange }) => {
  return (
    <div className="campaign-search-field">
      <img className="mr-2 ml-2" src={Icon.search} alt="search campaign" />
      <input
        type="text"
        placeholder={placeholder}
        name={name}
        onChange={onChange}
      />
      <img className="mr-2" src={Icon.filter} alt="filter campaign" />
    </div>
  );
};

export default CampaignSearchField;
