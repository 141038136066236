import { Outlet } from "react-router-dom";
import styles from "./StoresLayout.module.scss";

const StoresLayout = () => {
  return (
    <div className={styles.storesLayout}>
      <Outlet />
    </div>
  );
};

export default StoresLayout;
