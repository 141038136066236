import {
  RequestLoader,
  MetaHandler,
  platformHandler,
} from "../../hooks/context";
import { useEffect, useState } from "react";

let interval;
const MainLayoutLogic = () => {
  const [requestLoaderProgress, setRequestLoaderProgress] = useState(0);
  const [metaDetails, setMetaDetails] = useState({
    title: "Lumi Merchant",
    description: "Lumi Merchant",
  });
  const [platform, setPlatform] = useState({ type: "web" });

  useEffect(() => {
    if (requestLoaderProgress >= 100) {
      clearInterval(interval);
      setTimeout(() => {
        setRequestLoaderProgress(0);
      }, 200);
    }
  }, [requestLoaderProgress]);

  return {
    RequestLoader,
    requestLoaderProgress,
    setRequestLoaderProgress,
    MetaHandler,
    setMetaDetails,
    metaDetails,
    platform,
    setPlatform,
    platformHandler,
  };
};
export default MainLayoutLogic;
