import "./InviteSignup.scss";
import { Link } from "react-router-dom";
import layouts from "../../../layouts";
import BiodataForm from "./BiodataForm/BiodataForm";
import BusinessForm from "./BusinessForm/BusinessForm";
import { motion } from "framer-motion";
import InviteSignupLogic from "./InviteSignupLogic";
import secondaryComponents from "../../../components/secondaryComponents";

const Sidebar = () => {
  return (
    <div className="signup-page__sidebar">
      <div className="signup-page__sidebar__content">
        <h2 className="signup-page__sidebar__content__title white--text">
          A suite of tools built just for
          <em style={{ fontWeight: "bold" }}> you</em>
        </h2>
        <p className="big-text-size white--text mt-5">
          Accept offline and online payments, offer loyalty and track your
          business health
        </p>
      </div>
    </div>
  );
};

const InviteSignup = () => {
  const signupLogic = InviteSignupLogic();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Invite Sign up"
      description="Sign up via invite on lumi merchant"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          {signupLogic.showRegistrationFlow && (
            <layouts.SignupLayout sidebar={<Sidebar />}>
              <div className="signup-page" data-testid="signup-page">
                <p className="text-right">
                  Already an account?{" "}
                  <Link className="purple--text" to="/login">
                    Log In
                  </Link>
                </p>
                <div className="signup-page__content">
                  {signupLogic.index === 0 && (
                    <BiodataForm SignupLogic={signupLogic} />
                  )}
                  {signupLogic.index === 1 && (
                    <BusinessForm SignupLogic={signupLogic} />
                  )}
                </div>
              </div>
            </layouts.SignupLayout>
          )}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default InviteSignup;
