import Pagination from "../Pagination/Pagination";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import styles from "./PendingCheckoutSessions.module.scss";
import usePendingCheckout from "./usePendingCheckout";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";

const PendingCheckoutSessions = ({ closeWidget, setIsRefreshCart }) => {
  const {
    selectedItem,
    instance,
    showCancelPrompt,
    isFetchingSessions,
    pendingSessions,
    metaDetails,
    gotoPage,
    cancelCheckout,
    setShowCancelPrompt,
  } = usePendingCheckout({
    closeWidget,
    setIsRefreshCart,
  });

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.pendingCheckout}>
      <div className={styles.pendingCheckout__content}>
        <LoaderHelper isLoading={isFetchingSessions} classNames={"mt-5"}>
          <secondaryComponents.TableHelper
            getTableBodyProps={getTableBodyProps}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            handleScroll={true}
          >
            <h4 className="mt-5 text-center">No Data Available</h4>
          </secondaryComponents.TableHelper>
        </LoaderHelper>
      </div>
      {pendingSessions?.length > 0 && (
        <Pagination
          totalPageNumber={metaDetails?.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails?.current_page}
        />
      )}

      <secondaryComponents.Modal
        isActive={showCancelPrompt}
        closeModal={() => setShowCancelPrompt(false)}
        width="500px"
      >
        <div className="px-3 py-3">
          <h4 className="mb-3 text-center">Cancel Pending Checkout</h4>
          <div
            style={{
              backgroundColor: "#F3F1FC",
              padding: "16px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              borderRadius: "8px",
            }}
          >
            <p className="dark--text">
              Are you sure you want to remove this pending checkout session with
              the following items:{" "}
              <span className="purple--text">{selectedItem?.items}</span>
            </p>
          </div>
          <div
            className="d-flex justify-content-between mt-4"
            style={{ gap: "20px" }}
          >
            <primaryComponents.Button
              classNames="btn btn--outline cover"
              onClick={() => setShowCancelPrompt(false)}
            >
              No, Cancel
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames="btn btn--primary cover"
              onClick={() => {
                cancelCheckout();
                setShowCancelPrompt(false);
              }}
            >
              Yes, Proceed
            </primaryComponents.Button>
          </div>
        </div>
      </secondaryComponents.Modal>
    </div>
  );
};

export default PendingCheckoutSessions;
