import "./LoyaltyLayout.scss";
import { Outlet } from "react-router-dom";
// import secondaryComponets from "../../components/secondaryComponents";
// import LoyaltyLayoutLogic from "./LoyaltyLayoutLogic";

const LoyaltyLayout = () => {
  // const { has_wallet } = LoyaltyLayoutLogic();
  return (
    <div className="loyalty-page">
      {/* {has_wallet ? ( */}
      <Outlet />
      {/* ) : (
        <secondaryComponets.UnverifiedAccountWidget message="It appears your account has not been verified, please verify your account to continue" />
      )} */}
    </div>
  );
};

export default LoyaltyLayout;
