import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { platformHandler } from "../../../hooks/context";
import { setAccessToken } from "../../../store/modules/profile";

const usePlatformNavigator = () => {
  const [searchParams] = useSearchParams();
  const { setPlatform } = useContext(platformHandler);
  const [isLoading, setIsloading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      searchParams.get("platform") === "mobile" &&
      searchParams.get("routePath") &&
      searchParams.get("refreshToken")
    ) {
      setPlatform({ type: "mobile" });
      localStorage.setItem("refreshToken", searchParams.get("refreshToken"));
      dispatch(setAccessToken(searchParams.get("accessToken")));
      const url = searchParams.get("routePath")?.includes("sell-mode")
        ? `/${searchParams.get("routePath")}`
        : `/${searchParams.get("platform")}/${searchParams.get("routePath")}`;
      window.location.replace(url);
    } else setIsloading(false);
  }, []);

  return { isLoading };
};
export default usePlatformNavigator;
