import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import useAllTransfers from "../useAllTransfers";
import { useEffect } from "react";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";

const PendingTransfers = () => {
  const { setTerminalHeader } = useOutletContext();
  useEffect(() => {
    setTerminalHeader({
      title: "Pending Transfers",
      description: "Review and approve transfers before it's sent",
    });
  }, []);

  const {
    search,
    transfers,
    metaDetails,
    isFetchingTransfers,
    searchTransfer,
    filterByDate,
    gotoPage,
  } = useAllTransfers();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transfers - Pending"
      description="View all your transfers to other banks"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={globalStyles.tableWrapper}
        >
          <div className={globalStyles.tableHeader}>
            {/* header section */}
            <primaryComponents.SearchField
              trigger={(value) => searchTransfer(value)}
              value={search.get("query") || ""}
              placeholder="Search transfers by ( beneficiary name, account number or bank name)"
              classNames={`${globalStyles.searchField}`}
              triggerOnInput={true}
              showsearchBtn={true}
            />

            {/* date filter */}
            <primaryComponents.DateRangeInput
              onChange={filterByDate}
              startDate={search.get("start_date")}
              endDate={search.get("end_date")}
              showPlaceholder={true}
            />
          </div>

          {/* transfer table */}
          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingTransfers}
          >
            <secondaryComponents.PendingTransfersTable
              metaDetails={metaDetails}
              gotoPage={gotoPage}
              transfers={transfers}
            />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default PendingTransfers;
