import { useState, useContext, useEffect } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { addInvoiceCredit } from "../../../services/invoices";
import { useMutation } from "react-query";
import { getRandomIntInclusive } from "../../../helpers";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";

const useCreditForm = ({ invoiceDetails, handleFetchInvoice, closeWidget }) => {
  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: fetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter({
    account_type: "bank_account",
  });

  const [creditDetails, setCreditDetails] = useState({
    amount_paid: "",
    payment_method: "",
    ledger_account_id: invoiceDetails?.ledger_account_id,
  });
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  const { mutate: addCredit, isLoading: isAddingCredit } = useMutation((data) =>
    addInvoiceCredit(data, invoiceDetails?.id),
  );
  const handleAddCredit = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...creditDetails };
    addCredit(data, {
      onSuccess: () => {
        handleFetchInvoice();
        triggerToast("Payment added successfully", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  const submitCreditInfo = () => {
    handleAddCredit();
  };
  useEffect(() => {
    if (bankAccount?.data?.length > 0 && !creditDetails?.ledger_account_id) {
      setCreditDetails((prev) => ({
        ...prev,
        ledger_account_id: bankAccount?.data[0]?.id,
      }));
    }
  });

  return {
    isAddingCredit,
    creditDetails,
    setCreditDetails,
    submitCreditInfo,
    bankAccount,
    isLoadingBankAccountsList,
    fetchBankAccountsList,
  };
};

export default useCreditForm;
