import primaryComponents from "../../primaryComponents";
import "./SubAccountModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Fragment, useEffect, useState } from "react";

const SubAccountModal = () => {
  const navigate = useNavigate();
  const [copiedAccount, setCopiedAccount] = useState(false);

  useEffect(() => {
    if (copiedAccount === false) return;
    setTimeout(() => {
      setCopiedAccount(false);
    }, 5000);
  }, [copiedAccount]);

  return (
    <div className="created-sub-account-modal">
      <div className="created-sub-account-modal__card">
        <div className="created-sub-account-modal__top">
          <h3 className="purple--text">Sub-account created</h3>
          <p>
            Your unique bank account for your Lumi for business sub-account has
            been created.
          </p>
        </div>
        <div className="created-sub-account-modal__bank">
          <div className="created-sub-account-modal__bank-wrapper">
            <p className="created-sub-account-modal__bank-title smallest-text-size">
              Bank Name
            </p>
            <p className="created-sub-account-modal__bank-info font-weight-bold">
              Kuda Bank
            </p>
          </div>
          <div className="created-sub-account-modal__bank-wrapper">
            <p className="created-sub-account-modal__bank-title smallest-text-size">
              Bank Account Number
            </p>
            <p className="created-sub-account-modal__bank-info font-weight-bold">
              0123456789
            </p>
          </div>
        </div>
        <div className="created-sub-account-modal__buttons">
          <CopyToClipboard
            onCopy={() => setCopiedAccount(true)}
            text="0123456789"
          >
            <primaryComponents.Button classNames="btn purple white--text copy">
              {copiedAccount ? (
                "Copied"
              ) : (
                <Fragment>
                  <FontAwesomeIcon icon="copy" style={{ marginRight: "8px" }} />
                  Copy account number
                </Fragment>
              )}
            </primaryComponents.Button>
          </CopyToClipboard>

          <primaryComponents.Button
            classNames="btn white purple--text return"
            onClick={() => navigate(-1)}
          >
            Return to Dashboard
          </primaryComponents.Button>
        </div>
      </div>
      <p className="created-sub-account-modal__powered">Powered by Kuda Bank</p>
    </div>
  );
};

export default SubAccountModal;
