import { useState, useRef, useEffect, useContext } from "react";
import { ToastContext } from "../../../hooks/context";
import { useGetUploadedImages } from "../../../hooks/api/queries/useMedia";
import { useUploadImageMutation } from "../../../hooks/api/mutations/useMedia";

const useImageUpload = ({ setImage }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const hiddenFileInput = useRef(null);
  const triggerToast = useContext(ToastContext);
  const { data, isLoading, isFetchingNextPage, autoScrollRef } =
    useGetUploadedImages();
  const uploadImageMutation = useUploadImageMutation();

  useEffect(() => {
    if (uploadProgress >= 100) setUploadProgress(0);
  }, [uploadProgress]);

  const setImageSelected = (event) => {
    const files = event.target.files;
    let imageNames = files;
    if (imageNames === null) return;
    const formData = new FormData();
    formData.set("file", imageNames[0]);
    uploadimage(formData);
  };

  const uploadimage = (formData) => {
    uploadImageMutation.mutate(
      { formData, setUploadProgress },
      {
        onSuccess: (res) => {
          triggerToast("Image upload successful", "success");
          setImage(res?.data?.data?.url);
        },
        onSettled: () => {
          setUploadProgress(100);
        },
      },
    );
  };

  return {
    uploadProgress,
    hiddenFileInput,
    isLoading,
    imageList: data || [],
    isFetchingNextPage,
    autoScrollRef,
    setUploadProgress,
    setImageSelected,
  };
};

export default useImageUpload;
