import React, { useContext, useState } from "react";
import styles from "./mains.module.scss";
import { GRID_PRODUCT_DEFAULT_ICONS } from "../../../../../helpers/constants";
import AddToCartModal from "../AddToCartModal";
import {
  getPriceWithDiscout,
  getPriceWithoutDiscout,
  getVariantPriceWithDiscout,
} from "../../../../../helpers";
import { ToastContext } from "../../../../../hooks/context";
import { StorefrontAddIcon } from "../../../../../assets/svgIcons";

const ProductPriceRange = ({ store, variants, primaryColor }) => {
  const productVariants = variants?.map((item) => ({
    name: `${item.option} -- ${item.value}`,
    price: getVariantPriceWithDiscout(item),
  }));

  const { minPrice, maxPrice } = productVariants?.reduce(
    (acc, variant) => {
      if (variant.price < acc.minPrice) {
        acc.minPrice = variant.price;
      }
      if (variant.price > acc.maxPrice) {
        acc.maxPrice = variant.price;
      }
      return acc;
    },
    {
      minPrice: productVariants[0].price,
      maxPrice: productVariants[0].price,
    },
  );

  if (minPrice === maxPrice) {
    return (
      <p style={{ color: primaryColor }} className={styles.priceText}>
        {store?.currency_symbol}
        {minPrice?.toLocaleString()}
      </p>
    );
  }

  return (
    <p style={{ color: primaryColor }} className={styles.priceText}>
      {store?.currency_symbol}
      {minPrice?.toLocaleString()}
      {" - "}
      {store?.currency_symbol}
      {maxPrice?.toLocaleString()}
    </p>
  );
};

const MainsCard = ({ productIndex, productItem, store, menuObjectDetail }) => {
  const triggerToast = useContext(ToastContext);
  const [showAddToCartModal, setShowAddToCartModal] = useState(null);

  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";

  return (
    <>
      <div
        className={styles.cardContainer}
        onClick={() => {
          if (
            menuObjectDetail &&
            menuObjectDetail.allow_payment &&
            (menuObjectDetail?.allow_in_store_checkout ||
              menuObjectDetail?.allow_delivery)
          ) {
            if (
              (productItem?.is_service
                ? productItem?.store_service_properties[0]?.stock_quantity > 0
                : productItem?.store_product_properties[0]?.stock_quantity >
                  0) ||
              productItem?.variants?.length > 0 ||
              productItem?.is_service ||
              productItem?.composite_product_type === "ON_DEMAND"
            ) {
              setShowAddToCartModal({
                ...productItem,
                store,
              });
            }
          } else {
            triggerToast(
              "Checkout is not allowed for this storefront",
              "warning",
            );
          }
        }}
      >
        <div className={styles.mainContainer}>
          {(productItem.is_service
            ? productItem?.store_service_properties[0]?.stock_quantity === 0
            : productItem?.store_product_properties[0]?.stock_quantity === 0) &&
            productItem?.composite_product_type !== "ON_DEMAND" && (
              <p className={styles.soldOut}>Sold out</p>
            )}
          <div className={styles.imageContainer}>
            <img
              src={
                productItem?.media_files.length > 0
                  ? productItem?.media_files[0].url
                  : productItem?.image_url
                    ? productItem?.image_url
                    : GRID_PRODUCT_DEFAULT_ICONS[productIndex % 5]
              }
              style={{
                objectFit:
                  productItem?.media_files.length > 0
                    ? "contain"
                    : productItem?.image_url
                      ? "contain"
                      : "cover",
              }}
              className={styles.productImg}
              alt=""
            />
            {(productItem.is_service
              ? productItem?.store_service_properties[0]?.stock_quantity === 0
              : productItem?.store_product_properties[0]?.stock_quantity ===
                0) && productItem?.composite_product_type !== "ON_DEMAND" ? (
              <></>
            ) : (
              <div className={styles.addIcon}>
                <StorefrontAddIcon color={primaryColor} />
              </div>
            )}
          </div>
          <div className={styles.descriptionContainer}>
            <div className={styles.description}>{productItem?.name}</div>
            {(getPriceWithDiscout(productItem) > 0 ||
              (productItem?.variants?.length > 0 &&
                getVariantPriceWithDiscout(productItem.variants[0]) > 0)) && (
              <div className={styles.priceContainer}>
                <div className={styles.price}>
                  <span className={styles.dot}></span>
                  {productItem?.variants?.length > 0 ? (
                    <ProductPriceRange
                      store={store}
                      variants={productItem.variants}
                      primaryColor={primaryColor}
                    />
                  ) : (
                    <p
                      style={{ color: primaryColor }}
                      className={styles.priceText}
                    >
                      {store?.currency_symbol}
                      {getPriceWithDiscout(productItem).toLocaleString()}
                    </p>
                  )}
                </div>
                {productItem?.variants?.length === 0 &&
                  (productItem.is_service
                    ? productItem.store_service_properties[0]?.pricings?.find(
                        (item) => item.pricing_type.id === 1,
                      )?.discount_rate > 0
                    : productItem.store_product_properties[0]?.pricings?.find(
                        (item) => item.pricing_type.id === 1,
                      )?.discount_rate > 0) && (
                    <div className={styles.offContainer}>
                      <span className={styles.offPrice}>
                        {store?.currency_symbol}
                        {getPriceWithoutDiscout(productItem).toLocaleString()}
                      </span>
                      <span className={styles.offPerc}>
                        {productItem.is_service
                          ? productItem.store_service_properties[0].pricings?.find(
                              (item) => item.pricing_type.id === 1,
                            )?.discount_rate
                          : productItem.store_product_properties[0].pricings?.find(
                              (item) => item.pricing_type.id === 1,
                            )?.discount_rate}
                        % OFF
                      </span>
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showAddToCartModal && (
        <AddToCartModal
          productIndex={productIndex}
          showAddToCartModal={showAddToCartModal}
          setShowAddToCartModal={setShowAddToCartModal}
          menuObjectDetail={menuObjectDetail}
        />
      )}
    </>
  );
};

export default MainsCard;
