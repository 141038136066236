import "./UserRoles.scss";

import Icon from "../../../assets/icons";

const UserRoles = ({ closeModal }) => {
  return (
    <div className="user-roles__modal">
      <div className="user-roles__modal__header">
        <div>
          <h4 className="font-weight-semibold">Permissions</h4>
          <p>This role has access to:</p>
        </div>
        <div
          className="user-roles__modal-close"
          role={"button"}
          onClick={() => closeModal()}
        >
          <img width={"16px"} src={Icon.close} alt="close" />
        </div>
      </div>
      <div className="user-roles__modal__roles-list">
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Update Business</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Perform Exports</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Edit settlement account</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Withdraw Fund</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Access 2FA</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Regenerate keys</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Edit settlement account</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Delete customer</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Invite member</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Delete member</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
        <div className="user-roles__modal__roles-list__item">
          <p className="dark--text">Edit settlement account</p>
          <div className="user-roles__modal__roles-list__item__icon">
            <img src={Icon.check} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRoles;
