import styles from "./ProductsExport.module.scss";
import primaryComponents from "../../primaryComponents";
import useProductsExport from "./useProductsExport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Validation from "./Validation";
import { Controller } from "react-hook-form";

const ProductsExport = ({ closeWidget }) => {
  const {
    isExporting,
    store,
    storeList,
    fileFormat,
    setStore,
    exportProductList,
    setFileFormat,
  } = useProductsExport({ closeWidget });

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = Validation(store);

  return (
    <div className={styles.productsExport}>
      <div>
        <FontAwesomeIcon
          onClick={closeWidget}
          className="d-block"
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </div>

      <div className="mt-4">
        <h4>Inventory Export</h4>
        <p>Export your inventory from multiple stores in PDF or Excel</p>

        <form
          className={styles.formSection}
          id="exportInventory"
          onSubmit={handleSubmit(exportProductList)}
        >
          {/* store */}
          <primaryComponents.MultipleSelectFieldV2
            name="store"
            control={control}
            options={storeList}
            idKey="id"
            nameKey="name"
            value={store || "Select Store"}
            errorMessage={errors.store}
            onChange={(values) => {
              let newValues = [];
              values?.forEach((item) => {
                newValues.push(item.value);
              });
              setStore(newValues);
            }}
          />

          <div className="d-flex flex-column" style={{ gap: "5px" }}>
            <label>Select preferred file type </label>
            <Controller
              name="fileFormat"
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.BoxedRadioInput
                  name="fileFormat"
                  value={fileFormat}
                  id="excel"
                  isChecked={fileFormat === "excel"}
                  setRadioValue={() => {
                    setFileFormat("excel");
                    onChange("excel");
                  }}
                >
                  <span className="font-weight-semibold">Excel</span>
                </primaryComponents.BoxedRadioInput>
              )}
            />
            <Controller
              name="fileFormat"
              control={control}
              render={({ field: { onChange } }) => (
                <primaryComponents.BoxedRadioInput
                  name="fileFormat"
                  value={fileFormat}
                  id="pdf"
                  isChecked={fileFormat === "pdf"}
                  setRadioValue={() => {
                    setFileFormat("pdf");
                    onChange("pdf");
                  }}
                >
                  <span className="font-weight-semibold">PDF</span>
                </primaryComponents.BoxedRadioInput>
              )}
            />
          </div>
        </form>
      </div>

      <primaryComponents.Button
        classNames="btn btn--primary cover mt-auto"
        isDisabled={isExporting}
        isLoading={isExporting}
        loadingText="Exporting..."
        form="exportInventory"
        type="submit"
      >
        Export
      </primaryComponents.Button>
    </div>
  );
};
export default ProductsExport;
