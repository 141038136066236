import { useState, useContext } from "react";
import { createCategory } from "../../../services/inventory";
import { ToastContext, RequestLoader } from "../../../hooks/context";
import { useQueryClient } from "react-query";

const ProductCategoryModalLogic = ({ setIsActive }) => {
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  // const [categoryDescription, setCategoryDescripion] = useState("");
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const queryClient = useQueryClient();

  const handleAddCategory = () => {
    setIsAddingCategory(true);
    setRequestLoaderProgress(40);
    createCategory({ name: categoryName })
      .then(() => {
        setIsAddingCategory(false);
        setRequestLoaderProgress(100);
        setIsActive(false);
        queryClient.invalidateQueries(["getProductCategoryList"]);
        triggerToast("Product category created successfully", "success");
      })
      .catch((error) => {
        setIsAddingCategory(false);
        setRequestLoaderProgress(100);
      });
  };

  return {
    categoryName,
    isAddingCategory,
    setCategoryName,
    handleAddCategory,
  };
};

export default ProductCategoryModalLogic;
