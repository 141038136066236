import "./SendReceiptWidget.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import SendReceiptLogic from "./useSendReceiptLogic";
import SendReceiptValidation from "./SendReceiptValidation";

const SendReceiptWidget = ({
  closeWidget,
  transactionId,
  receiptType,
  goBack = () => {},
  orderDetails,
}) => {
  const {
    sendReceiptOption,
    setSendReceiptOption,
    handleSendReceipt,
    isSendingReceipt,
    setCustomerPhoneCode,
  } = SendReceiptLogic({
    closeWidget,
    transactionId,
    receiptType,
    orderDetails,
  });
  const {
    control,
    register,
    formState: { errors },
    trigger,
    handleSubmit,
  } = SendReceiptValidation();

  return (
    <div className="send-receipt-widget__content">
      <div className="d-flex align-items-center">
        {receiptType === "storeOrder" && (
          <primaryComponents.Button
            classNames="btn btn--outline"
            onClick={goBack}
          >
            <FontAwesomeIcon className="mr-2" icon="angle-left" />
            Back
          </primaryComponents.Button>
        )}

        <FontAwesomeIcon
          onClick={() => closeWidget(false)}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      </div>

      <div className="send-receipt-widget__content__title">
        <h4 className="font-weight-semibold">Send receipt</h4>
        <p>
          You can send your receipt to your beneficiary&apos;s email or phone
          number
        </p>
      </div>
      <form
        onSubmit={handleSubmit(handleSendReceipt)}
        className="send-receipt-widget__content__form"
      >
        <Controller
          name="option"
          control={control}
          defaultValue={sendReceiptOption}
          render={({ field: { onChange } }) => (
            <primaryComponents.BoxedRadioInput
              name="option"
              value={sendReceiptOption}
              id="email"
              isChecked={sendReceiptOption === "email"}
              setRadioValue={() => {
                setSendReceiptOption("email");
                onChange("email");
              }}
            >
              <span className="font-weight-semibold">Email</span>
            </primaryComponents.BoxedRadioInput>
          )}
        />
        <Controller
          name="option"
          control={control}
          defaultValue={sendReceiptOption}
          render={({ field: { onChange } }) => (
            <primaryComponents.BoxedRadioInput
              name="option"
              value={sendReceiptOption}
              id="sms"
              isChecked={sendReceiptOption === "sms"}
              setRadioValue={() => {
                setSendReceiptOption("whatsapp");
                onChange("whatsapp");
              }}
            >
              <span className="font-weight-semibold">WhatsApp</span>
            </primaryComponents.BoxedRadioInput>
          )}
        />
        <div className="mt-4">
          {sendReceiptOption === "email" && (
            <Controller
              name="email"
              control={control}
              defaultValue={
                orderDetails?.customer?.email !== null
                  ? orderDetails?.customer?.email
                  : ""
              }
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Email"
                  placeholder="Enter customer email address"
                  isRequired={true}
                  type="email"
                  name="email"
                  errorMessage={errors.email}
                  onKeyUp={() => errors.email !== undefined && trigger("email")}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                  }}
                  register={register}
                />
              )}
            />
          )}
          {sendReceiptOption === "whatsapp" && (
            <Controller
              name="phone_number"
              control={control}
              defaultValue={
                orderDetails?.customer?.phone !== null
                  ? orderDetails?.customer?.phone
                  : ""
              }
              render={({ field: { onChange } }) => (
                <primaryComponents.PhoneNumberInputField
                  label="Customers Phone Number"
                  placeholder="Enter customer phone number"
                  isRequired={true}
                  name="phone_number"
                  errorMessage={errors.phone_number}
                  onKeyUp={() =>
                    errors.phone_number !== undefined && trigger("phone_number")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                  }}
                  register={register}
                  phoneCodeEditable={true}
                  onChangePhoneCode={(val) => setCustomerPhoneCode(val)}
                />
              )}
            />
          )}
        </div>
        <div className="send-receipt--button__container">
          <primaryComponents.Button
            classNames={"btn btn--primary cover "}
            isDisabled={isSendingReceipt}
            isLoading={isSendingReceipt}
            loadingText="Sending Receipt..."
          >
            Send Receipt
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};

export default SendReceiptWidget;
