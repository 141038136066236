import "./AccountVerificationWidget.scss";
import primaryComponents from "../../primaryComponents";
import { useNavigate } from "react-router-dom";
import Images from "../../../assets/images";
import Icon from "../../../assets/icons";
import { useContext } from "react";
import { platformHandler } from "../../../hooks/context";
import { getPlatform } from "../../../helpers";

const AccountVerificationWidget = ({ username }) => {
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);

  return (
    <div className="account-verification-widget">
      <p className="purple--text">Welcome to Lumi for Business</p>
      <h3 className="mt-3 font-weight-semibold">Hi {username},</h3>
      <p className="mb-4">
        Thank you for joining the Lumi family, to fully access the service,{" "}
        <br /> you will need to verify your account, so we can get you setup
      </p>
      <primaryComponents.Button
        onClick={() =>
          navigate(getPlatform(platform) + "/inventory/kyb-verification")
        }
        classNames={"btn btn--primary btn__dashboard"}
      >
        <span>Verify my account</span>
        <img width="15px" className="ml-2" src={Icon.arrow_right} alt="" />
      </primaryComponents.Button>
      <img
        className="account-verification-widget__image"
        src={Images.enlumi_arrows_2}
        alt=""
      />
    </div>
  );
};

export default AccountVerificationWidget;
