export const BulkRestockIcon = ({ width = "24", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.20999L17 9.39999V13.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 16V7.99999C21.4996 7.64927 21.4071 7.3048 21.2315 7.00116C21.056 6.69751 20.8037 6.44536 20.5 6.26999L13.5 2.26999C13.196 2.09446 12.8511 2.00204 12.5 2.00204C12.1489 2.00204 11.804 2.09446 11.5 2.26999L4.5 6.26999C4.19626 6.44536 3.94398 6.69751 3.76846 7.00116C3.59294 7.3048 3.50036 7.64927 3.5 7.99999V16C3.50036 16.3507 3.59294 16.6952 3.76846 16.9988C3.94398 17.3025 4.19626 17.5546 4.5 17.73L11.5 21.73C11.804 21.9055 12.1489 21.9979 12.5 21.9979C12.8511 21.9979 13.196 21.9055 13.5 21.73L20.5 17.73C20.8037 17.5546 21.056 17.3025 21.2315 16.9988C21.4071 16.6952 21.4996 16.3507 21.5 16Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.77002 6.95999L12.5 12.01L21.23 6.95999"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 22.08V12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
