import { useDispatch } from "react-redux";
import { setAccessToken, setProfileDetails } from "../store/modules/profile";
import { pathConstants } from "../routes/pathContants";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ToastContext } from "./context";

const useGrantAccessToApp = ({ message }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);

  const setAuthTokensAndGrantAccessToApp = ({
    refreshToken,
    accessToken,
    profileDetails,
  }) => {
    localStorage.setItem("refreshToken", refreshToken);
    dispatch(setAccessToken(accessToken));
    dispatch(setProfileDetails(profileDetails));
    triggerToast(message, "success");
    navigate(getDefaultRoute());
  };

  const getDefaultRoute = () => {
    if (localStorage.getItem("nextRoute")) {
      let pathname = localStorage.getItem("nextRoute");
      localStorage.removeItem("nextRoute");
      return navigate(pathname);
    }

    return pathConstants.DASHBOARD_MAIN;
  };

  return { setAuthTokensAndGrantAccessToApp };
};
export default useGrantAccessToApp;
