import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getOrderTables } from "../../../services/inventory";
import { useState } from "react";

const useTableView = () => {
  const { store_id } = useParams();
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);

  const {
    isLoading: isLoadingOrderTable,
    data: orderTables,
    refetch: refetchOrderTables,
  } = useQuery(["getOrderTables", `?store_id=${store_id}`], () =>
    getOrderTables(`?store_id=${store_id}`).then((res) => res.data),
  );

  const handleRefresh = () => {
    setIsLoadingRefresh(true);
    refetchOrderTables();
    setTimeout(() => {
      setIsLoadingRefresh(false);
    }, 1000);
  };

  return {
    isLoadingOrderTable,
    orderTables: orderTables?.data || [],
    isLoadingRefresh,
    handleRefresh,
    refetchOrderTables,
  };
};

export default useTableView;
