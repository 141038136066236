import { Outlet } from "react-router-dom";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";

const AllSectionsLayout = () => {
  return (
    <div className={globalStyles.pagePaddings}>
      <Outlet />
    </div>
  );
};

export default AllSectionsLayout;
