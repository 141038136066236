import React from "react";

export const ShareIcon = ({ width = "25", height = "24" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 2L12.0833 12.4167M22.5 2L2.5 8.66667L12.0833 12.4167L22.5 2ZM22.5 2L15.8333 22L12.0833 12.4167L22.5 2Z"
        stroke="#7647EE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
