import primaryComponents from "../../primaryComponents";
import styles from "./ReturnStockWidget.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { useReturnStock } from "./ReturnStockLogic";
import { useReturnStockValidation } from "./validation";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import AddCustomerSection from "../StoreCheckout/AddCustomerSection";
import { useSelector } from "react-redux";
import secondaryComponents from "..";

const ReturnStockWidget = ({ closeWidget }) => {
  const {
    formData,
    stores,
    isReturningStock,
    isOrderItemsSelected,
    setFormData,
    handleReturnStock,
    isLoadingBankAccountsList,
    bankAccount,
  } = useReturnStock({ closeWidget });
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useReturnStockValidation(formData);
  const { allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className={globalStyles.pageColumnGaps} style={{ minHeight: "100%" }}>
      <div className={"d-flex align-items-center"}>
        <primaryComponents.Button
          classNames={"btn btn--outline smallBtn"}
          onClick={closeWidget}
        >
          <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
        </primaryComponents.Button>
        <h3 className="mx-auto">Returns</h3>
      </div>

      <form
        onSubmit={handleSubmit(handleReturnStock)}
        id="returnForm"
        className={globalStyles.pageColumnGaps}
      >
        <primaryComponents.SelectFieldV2
          control={control}
          name="store_id"
          options={stores}
          label="Select The Store Your Customer Purchased The Product(s) From"
          nameKey={"name"}
          idKey={"id"}
          onChange={(value) => {
            setFormData((prev) => ({
              ...prev,
              store_id: value,
            }));
          }}
          errorMessage={errors.store_id}
        />

        {allow_ledger_account ? (
          <div className="">
            <secondaryComponents.LoaderHelper
              isLoading={isLoadingBankAccountsList}
              className="mt-1"
            >
              <primaryComponents.SelectField
                options={bankAccount?.data?.map((bank) => ({
                  id: bank?.id,
                  name: bank?.account_name,
                }))}
                label={"Fund Return From"}
                idKey="id"
                nameKey="name"
                value={
                  bankAccount
                    ? bankAccount?.data[0]?.account_name
                    : "Select Bank"
                }
                onChange={(value) => {
                  setFormData((prev) => ({
                    ...prev,
                    ledger_account_id: value,
                  }));
                }}
                floatError={false}
                classNames="bank-name"
                isSearchable={false}
              />{" "}
            </secondaryComponents.LoaderHelper>
          </div>
        ) : null}

        <Controller
          control={control}
          name="order_id"
          defaultValue={formData.order_id}
          render={({ field: { onChange } }) => (
            <primaryComponents.OrderComboBox
              label="Enter and Search by Order ID"
              onChange={(selectedOrder) => {
                const { id, order_items } = selectedOrder;

                if (selectedOrder) {
                  const orderItems = order_items
                    ?.filter((order) => order?.product !== null)
                    ?.map((order) => ({
                      item_id: order?.id,
                      store_id: formData.store_id,
                      quantity: order?.quantity,
                      selling_price: order?.product?.selling_price,
                      cost_price: order?.product?.cost_price,
                      isReturning: false,
                      cart_item_name: order?.cart_item_name,
                    }));

                  onChange(id);
                  setFormData((prev) => ({
                    ...prev,
                    items: orderItems,
                    order_id: id,
                  }));
                }
              }}
              error={errors?.order_id?.message}
            />
          )}
        />

        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p className="dark--text" style={{ fontSize: "14px" }}>
              Select Items Your Customer is Returning
            </p>
            <p className="dark--text" style={{ fontSize: "14px" }}>
              Quantity
            </p>
          </div>
          <div className={styles.returnStockWidgetItems}>
            {formData?.items?.map((item, index) => (
              <div
                key={item?.item_id + index + item?.cart_item_name}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <primaryComponents.Checkbox
                    name="item"
                    id={item.item_id}
                    isChecked={item?.isReturning === true}
                    onChange={(value) => {
                      let orderItems = [...formData.items];
                      orderItems[index].isReturning = value;
                      setFormData((prev) => ({ ...prev, items: orderItems }));
                    }}
                  />
                  <p className="ml-1">{item?.cart_item_name}</p>
                </div>
                <div style={{ width: "72px" }}>
                  <Controller
                    name={`items[${formData?.items?.[index]}].quantity`}
                    control={control}
                    defaultValue={formData?.items?.[index]?.quantity}
                    render={({ field: { onChange } }) => {
                      return (
                        <primaryComponents.InputField
                          classNames="white"
                          value={item?.quantity}
                          isDisabled={!item?.isReturning}
                          type="number"
                          step="0.1"
                          name={`items[${formData?.items?.[index]}].quantity`}
                          onChange={({ target: { value } }) => {
                            onChange(value);
                            let _orderItems = [...formData.items];
                            _orderItems[index].quantity = Number(value);
                            setFormData((prev) => ({
                              ...prev,
                              items: _orderItems,
                            }));
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <primaryComponents.TextAreaV2
          control={control}
          label="Remarks"
          placeholder="Customer returned this because good was damaged internally"
          value={formData.remarks}
          onChange={({ target: { value } }) => {
            setFormData((prev) => ({ ...prev, remarks: value }));
          }}
          errorMessage={errors.remarks}
        />

        <div className={globalStyles.cardWithBorder} style={{ gap: "15px" }}>
          <div>
            <label>Do you want to issue a refund to customer wallet? </label>
            <div className="d-flex" style={{ gap: "35px" }}>
              <primaryComponents.BoxedRadioInput
                style={{ height: "30px" }}
                name="statementFormat"
                value={true}
                id="YES"
                isChecked={formData?.return_to_store_wallet}
                setRadioValue={() => {
                  setFormData((prev) => ({
                    ...prev,
                    return_to_store_wallet: true,
                  }));
                }}
              >
                <span className="font-weight-semibold">YES</span>
              </primaryComponents.BoxedRadioInput>
              <primaryComponents.BoxedRadioInput
                style={{ height: "30px" }}
                name="statementFormat"
                value={false}
                id="NO"
                isChecked={!formData?.return_to_store_wallet}
                setRadioValue={() => {
                  setFormData((prev) => ({
                    ...prev,
                    return_to_store_wallet: false,
                  }));
                }}
              >
                <span className="font-weight-semibold">NO</span>
              </primaryComponents.BoxedRadioInput>
            </div>
          </div>

          {formData?.return_to_store_wallet ? (
            <div>
              <AddCustomerSection
                setCustomerDetails={(info) => {
                  setFormData((prev) => ({ ...prev, customer: info }));
                }}
                customerDetails={formData?.customer}
                showLoyalty={false}
              />

              <p
                className={`smallest-text-size error-message text-danger ${errors.customer?.message ? "show" : "invisible"}`}
              >
                <FontAwesomeIcon
                  icon={["fas", "exclamation-circle"]}
                  className="mr-2"
                  style={{ fontSize: "13px" }}
                />
                <span>{errors.customer?.message}</span>
              </p>
            </div>
          ) : null}
        </div>
      </form>

      <primaryComponents.Button
        classNames={"btn btn--primary mt-auto w-100"}
        isDisabled={isReturningStock || !isOrderItemsSelected}
        isLoading={isReturningStock}
        loadingText={"Processing..."}
        type="submit"
        form="returnForm"
      >
        Confirm
      </primaryComponents.Button>
    </div>
  );
};

export default ReturnStockWidget;
