import { getBusiness } from "../../../services/settings";
import { setBusinessDetails } from "../../../store/modules/profile";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPlatform } from "../../../helpers";
import { platformHandler } from "../../../hooks/context";
import { useContext } from "react";

const KYBVerificationResponseLogic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);

  const handleClick = () => {
    getBusiness().then((response) => {
      dispatch(setBusinessDetails(response.data.data));
      navigate(getPlatform(platform) + "/dashboard");
    });
  };

  return { handleClick };
};

export default KYBVerificationResponseLogic;
