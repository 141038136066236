import { useMutation } from "react-query";
import {
  generateGuestUserToken,
  menuAddBulkToCart,
} from "../../../../../services/inventory";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../../../../hooks/context";
import { useSelector } from "react-redux";

const useMenuCart = ({ setShowCheckoutModal, menuObjectDetail }) => {
  const triggerToast = useContext(ToastContext);
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);
  const [bulkCartItems, setBulkCartItems] = useState(null);

  useEffect(() => {
    const bulkCartItems = menuCartItems?.map((item) => {
      if (!item.is_service) {
        if (item?.variant) {
          return {
            product_id: item?.productId,
            quantity: item.quantity,
            variant_id: item?.variant?.id,
            is_product: true,
            pricing_type_id: item.pricing_type.id,
          };
        }
        return {
          product_id: item.productId,
          quantity: item.quantity,
          is_product: true,
          pricing_type_id: item.pricing_type.id,
        };
      } else {
        if (item.is_service) {
          return {
            service_id: item.productId,
            quantity: item.quantity,
            is_product: false,
            pricing_type_id: item.pricing_type.id,
          };
        }
      }
      return {};
    });

    setBulkCartItems(bulkCartItems);
  }, [menuCartItems]);

  const { isLoading: isLoadingAddBulkToCart, mutate: mutateAddBulkToCart } =
    useMutation({
      mutationFn: (data) => menuAddBulkToCart({ data }),
      onSuccess: (data) => {
        setShowCheckoutModal(true);
        triggerToast(data.data.detail, "success");
      },
    });

  const { isLoading: isLoadingGenerateToken, mutate: mutateGenerateToken } =
    useMutation({
      mutationFn: () => generateGuestUserToken(),
      onSuccess: (data) => {
        localStorage.setItem("guest_token", data.data.data.token);

        if (bulkCartItems && menuObjectDetail) {
          mutateAddBulkToCart({
            storefront_id: menuObjectDetail.id,
            items: bulkCartItems,
            guest_token: data.data.data.token,
          });
        }
      },
    });

  const submitDetails = () => {
    const guest_token = localStorage.getItem("guest_token");

    if (guest_token && menuObjectDetail) {
      mutateAddBulkToCart({
        storefront_id: menuObjectDetail.id,
        items: bulkCartItems,
        guest_token,
      });
    } else {
      mutateGenerateToken();
    }
  };

  return {
    isLoadingGenerateToken,
    isLoadingAddBulkToCart,
    submitDetails,
    store: menuObjectDetail?.store,
  };
};

export default useMenuCart;
