import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    percentage_change: yup
      .string()
      .required("input a decrease or increase value"),
  })
  .required();

const Validation = ({ productsDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: productsDetails,
  });
};

export default Validation;
