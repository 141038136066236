import "./EditSettlementAccount.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import EditSettlementAccountLogic from "./EditSettlementAccountLogic";
import EditSettlementAccountValidation from "./EditSettlementValidation";
import { Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const EditSettlementAccount = ({
  isOpen = false,
  setIsOpen = () => {},
  settlementAccount,
  handleFetchSettlementAccounts,
}) => {
  const {
    isFetchingBankList,
    bank,
    bankList,
    account_currency,
    account_number,
    accountDetails,
    isResolvingAccount,
    isUpdatingAccount,
    is_primary,
    isEdited,
    business_id,
    business_list,
    setBusinessList,
    setBusinessId,
    setIsEdited,
    setIsPrimary,
    setAccountNumber,
    setBank,
    setAccountCurrency,
    handleResolveAccountNumber,
    handleUpdateSettlementAccount,
  } = EditSettlementAccountLogic(
    setIsOpen,
    settlementAccount,
    handleFetchSettlementAccounts,
  );

  const {
    control,
    register,
    trigger,
    formState: { errors },
    handleSubmit,
  } = EditSettlementAccountValidation({
    account_currency,
    account_number,
    bank,
  });

  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.Modal
      isActive={isOpen}
      width="500px"
      closeModal={() => setIsOpen(false)}
    >
      <div className="edit-settlement-account">
        <h4 className="font-weight-semibold text-center mb-3">
          Edit bank account
        </h4>
        <secondaryComponents.LoaderHelper
          classNames={"mt-3"}
          isLoading={isFetchingBankList}
        >
          <form
            onSubmit={handleSubmit(handleUpdateSettlementAccount)}
            className="add-settlement-account__form"
          >
            <div>
              <Controller
                name="account_currency"
                control={control}
                defaultValue={account_currency}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    label="Account Currency"
                    isRequired={true}
                    placeholder="Select currency"
                    name="account_currency"
                    options={[`${currency_code}`]}
                    value={
                      account_currency === ""
                        ? "Select currency"
                        : account_currency
                    }
                    errorMessage={errors.account_currency}
                    onChange={(value) => {
                      setIsEdited(true);
                      onChange(value);
                      setAccountCurrency(value);
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="bank"
                control={control}
                defaultValue={bank}
                render={({ field: { onChange } }) => (
                  <primaryComponents.SelectField
                    label="Bank"
                    isRequired={true}
                    placeholder="Select bank"
                    name="bank"
                    nameKey={"bank_name"}
                    idKey={"bank_code"}
                    options={bankList}
                    value={bank === "" ? "Select bank" : bank}
                    errorMessage={errors.bank}
                    onChange={(value) => {
                      setIsEdited(true);
                      onChange(value);
                      setBank(value);
                    }}
                  />
                )}
              />
            </div>
            <div style={{ position: "relative" }}>
              <Controller
                name="account_number"
                control={control}
                defaultValue={account_number}
                render={({ field: { onChange } }) => (
                  <primaryComponents.InputField
                    label="Account Number"
                    isRequired={true}
                    placeholder="Enter your account number"
                    name="account_number"
                    errorMessage={errors.account_number}
                    register={register}
                    onKeyUp={() =>
                      errors.account_number !== undefined &&
                      trigger("account_number")
                    }
                    onChange={({ target: { value } }) => {
                      setIsEdited(true);
                      onChange(value);
                      setAccountNumber(value);
                      if (value.length === 10) {
                        handleResolveAccountNumber(value, bank);
                      }
                    }}
                  />
                )}
              />
              {accountDetails !== null && (
                <p className="purple--text mb-3">
                  {accountDetails.account_name}
                </p>
              )}
              {isResolvingAccount && (
                <FontAwesomeIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    // bottom: "50%",
                    top: "45%",
                    transform: "translateY(-50%)",
                  }}
                  icon={["fas", "spinner"]}
                  spin
                />
              )}
            </div>
            <div>
              <Controller
                control={control}
                defaultValue={business_id}
                name="business_id"
                render={({ field: { onChange } }) => (
                  <primaryComponents.MultipleSelectField
                    label={"Select Sub Account"}
                    isRequired={true}
                    placeholder="Select account"
                    options={business_list}
                    idKey="id"
                    nameKey="tag"
                    name="business_id"
                    value={business_id?.map(({ tag, id }) => ({
                      label: tag,
                      value: id,
                    }))}
                    errorMessage={errors.business_id}
                    onChange={(values, { action, removedValue }) => {
                      onChange(values);
                      setIsEdited(true);
                      let newValues = [];
                      values?.forEach((item) => {
                        newValues.push(item.value);
                      });
                      setBusinessId(newValues);
                      if (action === "remove-value") {
                        let business = business_list.find(
                          (item) => item.id === removedValue.value,
                        );
                        if (business === undefined) {
                          let newBusinessList = business_list;
                          newBusinessList.push({
                            tag: removedValue.label,
                            id: removedValue.value,
                          });
                          setBusinessList(newBusinessList);
                        }
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="d-flex">
              <Controller
                name="is_primary"
                control={control}
                defaultValue={is_primary}
                render={({ field: { onChange } }) => (
                  <primaryComponents.Checkbox
                    name="is_primary"
                    id="is_primary"
                    isChecked={is_primary}
                    onChange={(value) => {
                      onChange(value);
                      setIsPrimary(value);
                    }}
                  />
                )}
              />
              <p className="ml-2">Make this my primary bank account</p>
            </div>
            <div className="add-settlement-account__form__actions">
              <primaryComponents.Button
                classNames={"btn btn--outline"}
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                isDisabled={!isEdited || isResolvingAccount}
                isLoading={isUpdatingAccount}
                loadingText="Adding account..."
              >
                Add new bank
              </primaryComponents.Button>
            </div>
          </form>
        </secondaryComponents.LoaderHelper>
      </div>
    </secondaryComponents.Modal>
  );
};

export default EditSettlementAccount;
