/* eslint-disable react/jsx-key */
import styles from "./SuppliersTable.module.scss";
import secondaryComponents from "..";
import useSuppliersTable from "./useSuppliersTable";
import Images from "../../../assets/images";

const SuppliersTable = ({
  metaDetails,
  suppliers,
  handleFetchSuppliers,
  gotoPage,
}) => {
  const {
    instance,
    isDeleteSupplier,
    selectedSupplier,
    isEditSupplier,
    setIsDeleteSupplier,
    setIsEditSupplier,
  } = useSuppliersTable({
    suppliers,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.suppliersTable}>
      <div className={styles.suppliersTable__content}>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.supplierEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no supplier
            </h4>
            <p className="text-center">
              Use the button above to add your suppliers When you do, they would
              appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>

        {suppliers?.length > 0 && (
          <div className="mt-auto">
            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </div>
        )}
      </div>

      <secondaryComponents.Modal
        isActive={isDeleteSupplier}
        closeModal={() => setIsDeleteSupplier(false)}
        width="400px"
      >
        <secondaryComponents.SupplierDeletion
          closeWidget={() => setIsDeleteSupplier(false)}
          supplierDetails={selectedSupplier}
          handleFetchSuppliers={handleFetchSuppliers}
        />
      </secondaryComponents.Modal>
      <secondaryComponents.SideModal
        isActive={isEditSupplier}
        closeModal={() => setIsEditSupplier(false)}
      >
        <secondaryComponents.SupplierForm
          closeWidget={() => setIsEditSupplier(false)}
          handleFetchSuppliers={handleFetchSuppliers}
          supplierInfo={selectedSupplier}
          isEditMode={true}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default SuppliersTable;
