import httpClient from "../axiosBase/axiosHandler";

export const createSupplier = (data) => {
  return httpClient.post("/suppliers/", data);
};

export const updateSupplier = ({ data, id }) => {
  return httpClient.patch(`/suppliers/${id}/`, data);
};

export const getSuppliers = (queries = "") => {
  return httpClient.get(`/suppliers/${queries}`);
};

export const getSuppliersDetails = (id) => {
  return httpClient.get(`/suppliers/${id}/`);
};

export const deleteSupplier = ({ id }) => {
  return httpClient.delete(`/suppliers/${id}/`);
};
