import "./LoyaltyCustomer.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import useLoyaltyCustomer from "./useLoyaltyCustomer.js";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const LoyaltyCustomer = () => {
  const { isRefresh, setIsRefresh } = useLoyaltyCustomer();
  const { setTerminalHeader } = useOutletContext();
  useEffect(() => {
    setTerminalHeader({
      title: "All Customer",
      description:
        "See who are your most loyal customers and how many points they have earned.",
    });
  }, []);
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Customers"
      description="View all customers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <secondaryComponents.LoyaltyCustomersTable
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
          />
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default LoyaltyCustomer;
