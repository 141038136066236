export const LoyaltyIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.26445 11.9397H3.70559C2.39525 11.9397 1.33301 13.0163 1.33301 14.3446V22.7615C1.33301 24.0896 2.39525 25.1663 3.70559 25.1663H7.26445M7.26445 11.9397L11.0622 3.27859C11.588 2.07942 12.9996 1.45982 14.1443 2.07288C15.9258 3.02698 15.5684 3.92128 15.5684 9.53485H22.2932C23.7474 9.53485 24.8593 10.8486 24.6382 12.3054L22.9957 23.1271C22.8176 24.3003 21.8218 25.1663 20.6507 25.1663H7.26445V11.9397ZM7.26445 11.9397V25.1663V11.9397Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
