import "./AddSingleProduct.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useAddSingleProduct } from "./useAddSingleProduct";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";

const AddSingleProduct = () => {
  const navigate = useNavigate();
  const {
    option,
    isCreatingProduct,
    isCreatingService,
    setOption,
    handleProductCreation,
    handleServiceCreation,
  } = useAddSingleProduct();

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      className={`add-single-product-page ${globalStyles.pagePaddings}`}
    >
      <primaryComponents.Button
        classNames="btn btn--outline back__btn"
        onClick={() => navigate(-1)}
      >
        <FontAwesomeIcon
          icon="angle-left"
          className="mr-3"
          style={{ fontSize: "18px" }}
        />
        <span>Back</span>
      </primaryComponents.Button>
      <div className="add-single-product-page__content">
        <div className="add-single-product-page__content-options">
          <secondaryComponents.WalletOption
            title="Product"
            onChange={(option) => {
              setOption(option);
            }}
            name="inventory_option"
            checked={option === "products"}
            value={"products"}
          />
          <secondaryComponents.WalletOption
            title="Services"
            onChange={(option) => {
              setOption(option);
            }}
            name="inventory_option"
            checked={option === "services"}
            value={"services"}
          />
        </div>
        <div className="add-single-product-page__content-header">
          {option !== "products" ? (
            <>
              <h3 className="font-weight-semibold">Create single service</h3>
              <p>Please provide the following information about your service</p>
            </>
          ) : (
            <>
              <h3 className="font-weight-semibold">Create single product</h3>
              <p>Please provide the following information about your product</p>
            </>
          )}
        </div>
        {option !== "products" ? (
          <secondaryComponents.SingleServiceForm
            handleProductUpdate={handleServiceCreation}
            isUpdatingProduct={isCreatingService}
          />
        ) : (
          <secondaryComponents.SingleProductForm
            handleProductUpdate={handleProductCreation}
            isUpdatingProduct={isCreatingProduct}
          />
        )}
      </div>
    </motion.div>
  );
};

export default AddSingleProduct;
