import { COLORS } from "../../../helpers/constants";
import { useState, useEffect } from "react";

const useExpenseAnalyticsChart = ({ data }) => {
  const [sumOfTags, setSumOfTags] = useState(0);

  useEffect(() => {
    calculateSumOfTags();
  }, [data]);

  const calculateSumOfTags = () => {
    let sum = data?.reduce((accumulator, item) => {
      return accumulator + item.expense_value;
    }, 0);
    setSumOfTags(sum);
  };

  return {
    COLORS,
    sumOfTags,
  };
};

export default useExpenseAnalyticsChart;
