import React from "react";

export const GuardIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1656 4.83418L20.1661 4.83474C21.0224 5.69013 21.5005 6.84295 21.5005 8.05V15.95C21.5005 18.4625 19.4557 20.5 16.9395 20.5H7.06049C4.54441 20.5 2.50049 18.4626 2.50049 15.95V8.05C2.50049 5.53672 4.53505 3.5 7.06049 3.5H16.9395C18.1489 3.5 19.3112 3.978 20.1656 4.83418ZM18.1205 7.70251L18.1086 7.70131L18.0966 7.70068C17.7626 7.68319 17.436 7.79416 17.1849 8.01934L12.6885 11.6093L12.6885 11.6092L12.6813 11.6151C12.2878 11.9415 11.7231 11.9446 11.3167 11.6127L11.3168 11.6127L11.3128 11.6096L6.81284 8.00957L6.80542 8.00364L6.79779 7.99799C6.29666 7.62738 5.5857 7.66272 5.14326 8.12013C4.70195 8.56506 4.65011 9.27303 5.03205 9.77338L5.0529 9.8007L5.07729 9.8249L5.20829 9.95491L5.22943 9.97589L5.25292 9.99421L9.80158 13.5432C9.80178 13.5433 9.80198 13.5435 9.80219 13.5436C10.4481 14.051 11.2352 14.33 12.0605 14.33C12.8822 14.33 13.682 14.052 14.3287 13.5429L14.3288 13.5429L14.3319 13.5404L18.8429 9.93038L18.8645 9.91311L18.884 9.89355L18.964 9.81355L18.981 9.79655L18.9963 9.77799C19.3883 9.30234 19.3891 8.61016 18.9782 8.13353L18.9718 8.1261L18.9651 8.11893C18.7402 7.87785 18.4355 7.73417 18.1205 7.70251Z"
        fill="#FACE8A"
        stroke="black"
      />
    </svg>
  );
};
