import React from "react";
import { useNavigate } from "react-router-dom";
import secondaryComponents from "../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import primaryComponents from "../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./AccountingDetails.module.scss";
import useAccountingDetails from "./useAccountingDetails";
import { pathConstants } from "../../../../routes/pathContants";

const AccountingDetails = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useAccountingDetails();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Accounting"
      description="Quickly gauge your business’ performance at a glance"
    >
      <secondaryComponents.LoaderHelper
        isLoading={isLoading || !data}
        classNames="mt-5"
      >
        <secondaryComponents.RequestLoaderTrigger>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0 }}
            className={styles.accounting_details}
          >
            <div className={styles.title_container}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => navigate(pathConstants.ACCOUNTING_OVERVIEW)}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  style={{ fontSize: "18px" }}
                />
              </primaryComponents.Button>
              <div className={styles.title_content}>
                <h4 className={styles.title}>{data?.title}</h4>
                <p>{data?.description}</p>
              </div>
            </div>

            <iframe
              src={data?.iframe_url}
              title={data?.title}
              allowFullScreen
              className={styles.iframe}
            />
          </motion.div>
        </secondaryComponents.RequestLoaderTrigger>
      </secondaryComponents.LoaderHelper>
    </secondaryComponents.MetaSetter>
  );
};

export default AccountingDetails;
