import "./TagsLayout.scss";
import { Outlet } from "react-router-dom";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";

const TagsLayout = () => {
  return (
    <div className={`tags-layout ${globalStyles.pagePaddings} `}>
      <Outlet />

      {/* {isAddingTag && (
        <secondaryComponents.AddTransactionsTag
          isOpen={isAddingTag}
          setIsOpen={setIsAddingTag}
          isEditMode={isEditingTag}
          setIsEditMode={setIsEditingTag}
          setIsRefresh={setIsRefresh}
          tagDetails={tagDetails}
        />
      )}
      {isDeletingTag && (
        <secondaryComponents.DeleteTagDialog
          isOpen={isDeletingTag}
          setIsOpen={setIsDeletingTag}
          tagDetails={tagDetails}
          setIsRefresh={setIsRefresh}
        />
      )} */}
    </div>
  );
};

export default TagsLayout;
