import { useQuery } from "react-query";
import {
  getCities,
  getCountries,
  getCountriesForBusiness,
  getCountry,
  getStates,
  getStoreLocations,
  getSubAccounts,
  getTimezones,
} from "../../../services/business";

export const useGetSubAccounts = () => {
  return useQuery(
    ["getSubAccounts"],
    () => getSubAccounts().then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCountries = () => {
  return useQuery(
    ["getCountries"],
    () => getCountries().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCountryDetails = (id) => {
  return useQuery(
    ["getCountryDetails", id],
    () => getCountry(id).then((res) => res?.data?.data),
    {
      enabled: id ? true : false,
      staleTime: Infinity,
    },
  );
};

export const useGetStateDetails = ({ stateId }) => {
  return useQuery(
    ["getStateDetails", stateId],
    () => getStates(stateId).then((res) => res?.data?.data),
    {
      enabled: !!stateId,
      staleTime: Infinity,
    },
  );
};

export const useGetCityDetails = ({ cityId }) => {
  return useQuery(
    ["getCityDetails", cityId],
    () => getCities(cityId).then((res) => res?.data?.data),
    {
      enabled: !!cityId,
      staleTime: Infinity,
    },
  );
};

export const useGetStoreLocations = (queryParams = {}) => {
  return useQuery(
    ["getStoreLocations", queryParams],
    () => getStoreLocations(queryParams).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCountriesForBusiness = (id) => {
  return useQuery(
    ["getCountriesForBusiness", id],
    () => getCountriesForBusiness(id).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetTimezones = () => {
  return useQuery(
    ["getTimezones"],
    () => getTimezones().then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};
