import httpClient from "../axiosBase/axiosHandler";

export const uploadImage = ({ formData, setUploadProgress = () => {} }) => {
  return httpClient.post("/media/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: (progressEvent) => {
      const percentage = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      setUploadProgress(percentage); // Update progress
    },
  });
};

export const getUploadedImage = ({ ...params }) => {
  return httpClient.get("/media/", {
    params: { ...params, per_page: 50 },
  });
};

export const deleteUploadedImage = () => {
  return httpClient.delete("/media/");
};
