import { useQuery } from "react-query";
import {
  getViewMenuDetail,
  getViewMenuList,
} from "../../../services/inventory";

export const useGetStorefrontMenus = (id) => {
  return useQuery(
    ["getStorefrontMenus", id],
    () => getViewMenuList({ id }).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: id ? true : false,
    },
  );
};

export const useGetViewStorefrontDetail = (id) => {
  return useQuery(
    ["getStorefrontDetails", id],
    () => getViewMenuDetail({ id }).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      enabled: id ? true : false,
    },
  );
};
