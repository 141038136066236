import { getUrlQuerysection } from "../../../../../../../helpers";
import { useGetShippingsList } from "../../../../../../../hooks/api/queries";
import CreateShippingForm from "./ShippingForm";
import styles from "./styles.module.scss";

const StorefrontShipping = () => {
  const queries = getUrlQuerysection();
  const { data: shippings, refetch: handleFetchShippings } =
    useGetShippingsList({ queries });

  return (
    <div className={styles.container}>
      <h3>Set up shipping for your storefront</h3>
      <div className={styles.createContainer}>
        <CreateShippingForm
          refetch={handleFetchShippings}
          shippingInfo={shippings?.data}
        />
      </div>
    </div>
  );
};

export default StorefrontShipping;
