import primaryComponents from "../../primaryComponents";
import styles from "./CreatePricingType.module.scss";
import Validation from "./Validation";
import useCreatePricingType from "./useCreatePricingType";

const CreatePricingType = ({
  closeWidget,
  pricingDetails = null,
  isEditMode = false,
}) => {
  const { isCreatingPricing, pricingName, setPricingName, handleAddPricing } =
    useCreatePricingType({
      closeWidget,
      pricingDetails,
      isEditMode,
    });
  const {
    handleSubmit,
    formState: { errors },
    control,
    trigger,
  } = Validation({ pricingName });

  return (
    <div className={styles.createPricing}>
      <h4 className="mb-4 text-center">
        {!isEditMode ? "Create New Pricing" : "Edit Pricing"}
      </h4>
      <form
        className={styles.createPricing__form}
        onSubmit={handleSubmit(handleAddPricing)}
      >
        <primaryComponents.InputFieldV2
          name="pricingName"
          control={control}
          label="Pricing Name"
          isRequired
          placeholder="Enter your pricing name"
          type="text"
          errorMessage={errors.pricingName}
          value={pricingName}
          onKeyUp={() =>
            errors.pricingName !== undefined && trigger("pricingName")
          }
          onChange={({ target: { value } }) => {
            setPricingName(value);
          }}
        />

        <div
          className={"d-flex justify-content-between mt-2"}
          style={{ gap: "15px" }}
        >
          <primaryComponents.Button
            classNames="btn btn--outline cover"
            type="button"
            onClick={closeWidget}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames="btn btn--primary cover"
            type="submit"
            isLoading={isCreatingPricing}
            isDisabled={isCreatingPricing}
            loadingText={!isEditMode ? "Creating..." : "Updating..."}
          >
            {!isEditMode ? "Create Pricing" : "Update Pricing"}
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};
export default CreatePricingType;
