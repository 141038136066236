/* eslint-disable jsx-a11y/anchor-is-valid */
import "./AppLayout.scss";
import { NavLink, Outlet } from "react-router-dom";
import Logo from "../../assets/logo";
import Icon from "../../assets/icons";
import useAppLayout from "./useAppLayout";
import { getPlatform, logoutHandler } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import secondaryComponents from "../../components/secondaryComponents";
import styles from "./AppLayout.module.scss";
import { motion } from "framer-motion";
import { platformHandler } from "../../hooks/context";
import React, { useContext, useState } from "react";
import { pathConstants } from "../../routes/pathContants";
import useAppSidebarItems from "../../hooks/useAppSidebarItems";
import {
  AccountingsIcon,
  ChefIcon,
  CollapseIcon,
  MarketsIcon,
} from "../../assets/svgIcons";
import primaryComponents from "../../components/primaryComponents";
import Modal from "../../components/secondaryComponents/Modal/Modal";
import MoreOption from "../../components/secondaryComponents/MoreModal";

const AppLayout = () => {
  const {
    isLoading,
    // pending_transfer_request,
    isSubMobileMenu,
    menuData,
    currentMobileMenu,
    openLoyalty,
    activeSubMenu,
    isCollapseSidebar,
    setIsCollapseSidebar,
    setActiveSubMenu,
    setCurrentMobileMenu,
    setIsSubMobileMenu,
    closeSubMenu,
    setOpenLoyalty,
  } = useAppLayout();
  const { platform } = useContext(platformHandler);
  const [isMoreModalOpen, setIsMoreModalOpen] = useState(false);
  const { appSidebarItems } = useAppSidebarItems({
    setIsCollapseSidebar,
    setActiveSubMenu,
    isCollapseSidebar,
  });
  const subMenuItems = appSidebarItems?.find(
    (menuItem) => menuItem?.active,
  )?.children;
  return (
    <>
      <secondaryComponents.LoaderHelper
        isLoading={isLoading}
        classNames={"mt-5"}
      >
        <div className="dashboard-layout">
          {/* sidebar section */}
          <div
            className={`${styles.deskTopSidebar} ${
              subMenuItems ? styles.allowHover : ""
            }`}
          >
            <div
              className={`${styles.deskTopSidebar__item} ${styles.deskTopSidebar__left}`}
            >
              <img width={48} height={16} src={Logo.lumiWhiteLogo} alt="Lumi" />

              <div
                className={`${styles.menuCover} text-center align-items-center mt-4`}
              >
                {appSidebarItems?.map((menuItem, menuIndex) => {
                  if (menuItem?.key !== 9) {
                    return (
                      <React.Fragment key={menuItem?.key}>
                        <secondaryComponents.CountryItemPermission
                          dontCheckCountry={
                            menuItem?.dontCheckCountry !== undefined
                              ? menuItem?.dontCheckCountry
                              : true
                          }
                        >
                          <secondaryComponents.NavigateWithPermission
                            permissionParentKey={menuItem?.permissionParentKey}
                            permissionChildKey={menuItem?.permissionChildKey}
                          >
                            <div
                              className={`${styles.menuCover__item} ${
                                menuItem?.active ? styles.isActive : ""
                              }`}
                              key={menuItem?.key}
                              onClick={() => menuItem?.onClick()}
                            >
                              {menuItem?.icon({
                                active: menuItem?.active ? true : false,
                              })}
                              <p>{menuItem.name}</p>
                            </div>
                          </secondaryComponents.NavigateWithPermission>
                        </secondaryComponents.CountryItemPermission>
                      </React.Fragment>
                    );
                  } else if (menuItem?.name === "More") {
                    return (
                      <div
                        key={menuItem?.key}
                        className={`${styles.menuCover__item} ${
                          menuItem?.active ? styles.isActive : ""
                        }`}
                        onClick={() => setIsMoreModalOpen(true)}
                      >
                        {menuItem?.icon({
                          active: menuItem?.active ? true : false,
                        })}
                        <p>{menuItem.name}</p>
                      </div>
                    );
                  }
                  return null;
                })}
                <Modal
                  isActive={isMoreModalOpen}
                  closeModal={() => setIsMoreModalOpen(false)}
                  width="350px"
                  top="35%"
                  left="94px"
                  position="left"
                >
                  <MoreOption closeModal={() => setIsMoreModalOpen(false)} />
                </Modal>
              </div>
            </div>

            <div
              className={`${styles.deskTopSidebar__item} ${
                styles.deskTopSidebar__right
              } ${subMenuItems ? "" : styles.closeMode} ${
                isCollapseSidebar ? styles.collapseMode : ""
              }`}
            >
              <span className={styles.collapseSection}>
                <span
                  className={styles.collapseIcon}
                  onClick={() => setIsCollapseSidebar((prev) => !prev)}
                >
                  <CollapseIcon />
                </span>
                <p className="smallest-text-size dark--text">Toggle sidebar</p>
              </span>

              <div className={`${styles.menuCover} ${styles.modified} mt-5`}>
                {subMenuItems?.map((subMenuItem, subMenuItemIndex) => (
                  <React.Fragment key={subMenuItem?.key}>
                    {subMenuItem?.children ? (
                      <secondaryComponents.CountryItemPermission
                        dontCheckCountry={
                          subMenuItem?.dontCheckCountry !== undefined
                            ? subMenuItem?.dontCheckCountry
                            : true
                        }
                      >
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={subMenuItem?.permissionParentKey}
                          permissionChildKey={subMenuItem?.permissionChildKey}
                        >
                          <div
                            className={`${styles.parentNavlink} ${
                              subMenuItem?.active
                                ? styles.parentNavlink__active
                                : ""
                            }`}
                          >
                            <div
                              className={styles.parentNavlink__top}
                              onClick={() => {
                                if (activeSubMenu === subMenuItem?.key)
                                  setActiveSubMenu(null);
                                else setActiveSubMenu(subMenuItem?.key);
                              }}
                            >
                              <span
                                className={`${
                                  subMenuItem?.active
                                    ? "purple--text"
                                    : "dark--text"
                                }`}
                              >
                                {subMenuItem?.name}
                              </span>
                              <primaryComponents.Button
                                classNames={`btn btn--outline ${styles.parentNavlink__icon}`}
                              >
                                <FontAwesomeIcon
                                  className={`${
                                    subMenuItem?.active
                                      ? "purple--text"
                                      : "dark--text"
                                  }`}
                                  icon={`${
                                    activeSubMenu === subMenuItem?.key
                                      ? "angle-up"
                                      : "angle-down"
                                  }`}
                                  fontSize={"12px"}
                                />
                              </primaryComponents.Button>
                            </div>

                            {activeSubMenu === subMenuItem?.key ? (
                              <motion.div
                                initial={{ y: -20 }}
                                animate={{
                                  y: 0,
                                }}
                                className={`${styles.menuCover} ${styles.modified2}`}
                              >
                                {subMenuItem?.children?.map(
                                  (subSubMenuItem) => (
                                    <React.Fragment key={subSubMenuItem?.key}>
                                      <secondaryComponents.CountryItemPermission
                                        dontCheckCountry={
                                          subMenuItem?.dontCheckCountry !==
                                          undefined
                                            ? subSubMenuItem?.dontCheckCountry
                                            : true
                                        }
                                      >
                                        <secondaryComponents.NavigateWithPermission
                                          permissionParentKey={
                                            subSubMenuItem?.permissionParentKey
                                          }
                                          permissionChildKey={
                                            subSubMenuItem?.permissionChildKey
                                          }
                                        >
                                          <NavLink
                                            to={subSubMenuItem?.routePath}
                                            className={({ isActive }) =>
                                              `${styles.navlink} ${
                                                isActive ? styles.active : ""
                                              }`
                                            }
                                            end={
                                              subMenuItemIndex === 0
                                                ? true
                                                : false
                                            }
                                          >
                                            {subSubMenuItem?.name}
                                          </NavLink>
                                        </secondaryComponents.NavigateWithPermission>
                                      </secondaryComponents.CountryItemPermission>
                                    </React.Fragment>
                                  ),
                                )}
                              </motion.div>
                            ) : null}
                          </div>
                        </secondaryComponents.NavigateWithPermission>
                      </secondaryComponents.CountryItemPermission>
                    ) : (
                      <secondaryComponents.CountryItemPermission
                        dontCheckCountry={
                          subMenuItem?.dontCheckCountry !== undefined
                            ? subMenuItem?.dontCheckCountry
                            : true
                        }
                      >
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={subMenuItem?.permissionParentKey}
                          permissionChildKey={subMenuItem?.permissionChildKey}
                        >
                          <div className={styles.navlinkCover}>
                            <NavLink
                              to={subMenuItem?.routePath}
                              className={({ isActive }) =>
                                `${styles.navlink} ${
                                  isActive ? styles.active : ""
                                }`
                              }
                              end={subMenuItemIndex === 0 ? true : false}
                            >
                              {subMenuItem?.name}
                            </NavLink>
                          </div>
                        </secondaryComponents.NavigateWithPermission>
                      </secondaryComponents.CountryItemPermission>
                    )}
                  </React.Fragment>
                ))}

                {subMenuItems === undefined ? (
                  <h5 className="text-center">No Sub Menu</h5>
                ) : null}
              </div>
            </div>
          </div>

          {/* Dashboard layout content */}
          <div className="dashboard-layout__content">
            <Outlet />
          </div>

          {/* mobile menu */}
          <div className={styles.mobileMenuSection}>
            <div
              className={`${styles.mobileMenuCover} ${
                isSubMobileMenu ? styles.active : ""
              }`}
            >
              {/* sub menu items */}
              {isSubMobileMenu ? (
                <motion.div initial={{ y: 50 }} animate={{ y: 0 }}>
                  {currentMobileMenu === "Payments" ? (
                    <>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Payments"}
                        permissionChildKey={
                          "can_view_payment_dashboard_overview_cash_and_customer_analytics"
                        }
                      >
                        <div className={`${styles.subMenuRow} d-block`}>
                          <div
                            className="d-flex justify-content-between w-100 align-items-center"
                            onClick={() => {
                              console.log(openLoyalty);
                              if (openLoyalty === "paymentsOverview")
                                setOpenLoyalty(null);
                              else setOpenLoyalty("paymentsOverview");
                            }}
                          >
                            <div
                              className={`${styles.subMenuRow} ${styles.splited}`}
                              style={{ padding: 0 }}
                            >
                              <img src={Icon.mobileHomeIcon} alt="overiew" />
                              <p>Overview</p>
                            </div>
                            <FontAwesomeIcon
                              icon={
                                openLoyalty === "paymentsOverview"
                                  ? "angle-up"
                                  : "angle-down"
                              }
                            />
                          </div>

                          {openLoyalty === "paymentsOverview" ? (
                            <motion.div
                              initial={{ y: -40 }}
                              animate={{ y: 0 }}
                              className="pl-4"
                            >
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.DASHBOARD_OVERVIEW +
                                  "?all_business=true"
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                  marginTop: "10px",
                                }}
                              >
                                <p>Summary</p>
                              </NavLink>
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.DASHBOARD_CASHFLOW_ANALYTICS +
                                  "?all_business=true"
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>Cashflow Analytics</p>
                              </NavLink>
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.DASHBOARD_CUSTOMER_ANALYTICS +
                                  "?all_business=true"
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>Customer Analytics</p>
                              </NavLink>
                            </motion.div>
                          ) : null}
                        </div>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Payments"}
                        permissionChildKey={"can_view_transfer"}
                      >
                        <div className={`${styles.subMenuRow} d-block`}>
                          <div
                            className="d-flex justify-content-between w-100 align-items-center"
                            onClick={() => {
                              if (openLoyalty === "transfers")
                                setOpenLoyalty(null);
                              else setOpenLoyalty("transfers");
                            }}
                          >
                            <div
                              className={`${styles.subMenuRow} ${styles.splited}`}
                              style={{ padding: 0 }}
                            >
                              <img
                                src={Icon.mobileTransferIcon}
                                alt="Transfers"
                              />
                              <p>Transfers</p>
                            </div>
                            <FontAwesomeIcon
                              icon={
                                openLoyalty === "transfers"
                                  ? "angle-up"
                                  : "angle-down"
                              }
                            />
                          </div>

                          {openLoyalty === "transfers" ? (
                            <motion.div
                              initial={{ y: -40 }}
                              animate={{ y: 0 }}
                              className="pl-4"
                            >
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.PAYMENT_TRANSFER_APPROVED +
                                  "?status=accepted&all_business=true"
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                  marginTop: "10px",
                                }}
                              >
                                <p>Approved Transfers</p>
                              </NavLink>
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.PAYMENT_TRANSFER_PENDING +
                                  "?status=pending_approval&all_business=true"
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>Pending Transfers</p>
                              </NavLink>
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.PAYMENT_TRANSFER_REJECTED +
                                  "?status=rejected&all_business=true"
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>Rejected Transfers</p>
                              </NavLink>
                              <NavLink
                                className={styles.subMenuRow}
                                to={
                                  pathConstants.PAYMENT_TRANSFER_SAVEDBENEFICIARY
                                }
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                  paddingBottom: 0,
                                }}
                              >
                                <p>Saved Beneficiary</p>
                              </NavLink>
                            </motion.div>
                          ) : null}
                        </div>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Payments"}
                        permissionChildKey={"can_view_transactions_history"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={
                            pathConstants.PAYMENT_TRANSACTIONS_ALL +
                            "?all_business=true"
                          }
                          onClick={closeSubMenu}
                        >
                          <img
                            src={Icon.mobileTransactionIcon}
                            alt="Transactions"
                          />
                          <p>Transactions</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Payments"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants.PAYMENT_SUBACCOUNTS}
                          onClick={closeSubMenu}
                        >
                          <img
                            src={Icon.mobileSubaccountIcon}
                            alt="Subaccount"
                          />
                          <p>Sub Accounts</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Payments"}
                        permissionChildKey={"can_view_and_request_pos"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants.PAYMENT_POS_ALL_TERMINALS}
                          onClick={closeSubMenu}
                        >
                          <img src={Icon.mobilePosIcon} alt="Pos" />
                          <p>POS</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Payments"}
                        permissionChildKey={"can_view_tags"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants.PAYMENT_ALL_TAGS}
                          onClick={closeSubMenu}
                        >
                          <img src={Icon.mobileTagIcon} alt="Tags" />
                          <p>Tags</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                    </>
                  ) : currentMobileMenu === "Inventory" ? (
                    <>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Inventory Management"}
                        permissionChildKey={"can_view_stores"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants.STORES}
                          onClick={closeSubMenu}
                        >
                          <img src={Icon.mobileSellIcon} alt="Sell" />
                          <p>Sell</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Inventory Management"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants.SECTIONS}
                          onClick={closeSubMenu}
                        >
                          <ChefIcon width={20} height={20} />
                          <p>Section Display</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Inventory Management"}
                      >
                        <div className={`${styles.subMenuRow} d-block`}>
                          <div
                            className="d-flex justify-content-between w-100 align-items-center"
                            onClick={() => {
                              if (openLoyalty === "inventoryManage")
                                setOpenLoyalty(null);
                              else setOpenLoyalty("inventoryManage");
                            }}
                          >
                            <div
                              className={`${styles.subMenuRow} ${styles.splited}`}
                              style={{ padding: 0 }}
                            >
                              <img src={Icon.mobileManageIcon} alt="Manage" />
                              <p>Manage</p>
                            </div>
                            <FontAwesomeIcon
                              icon={
                                openLoyalty === "inventoryManage"
                                  ? "angle-up"
                                  : "angle-down"
                              }
                            />
                          </div>

                          {openLoyalty === "inventoryManage" ? (
                            <motion.div
                              initial={{ y: -40 }}
                              animate={{ y: 0 }}
                              className="pl-4"
                            >
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={"can_view_products"}
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_PRODUCTS}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                    marginTop: "10px",
                                  }}
                                >
                                  <p>Products</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={"can_view_analytics"}
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_ANALYTICS}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Analytics</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={"can_view_product_category"}
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_CATEGORIES}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Product Categories</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={"can_view_stores"}
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_STORES}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Stores</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_create_kitchen_sections"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.MANAGE_SECTIONS}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Manage Section</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_view_edit_and_create_storefront"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_MENU}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Storefront</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_view_edit_and_create_suppliers"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_SUPPLIERS}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Suppliers</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_view_sales_transaction"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={
                                    pathConstants.INVENTORY_SALES_TRANSACTIONS
                                  }
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Sales Transaction</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={"can_view_stock_history"}
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_STOCK_HISTORY}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Stock History</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={"can_view_stock_transfer"}
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={
                                    pathConstants.INVENTORY_PRODUCT_BULK_TRANSFER_HISTORY
                                  }
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Stock Transfer</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_view_and_update_credits"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_CREDITLOG}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Debtors</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_create_and_submit_reconciliation"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_RECONCILIATIONS}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Reconciliation</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                              <secondaryComponents.NavigateWithPermission
                                permissionParentKey={"Inventory Management"}
                                permissionChildKey={
                                  "can_view_edit_and_create_tables"
                                }
                              >
                                <NavLink
                                  className={styles.subMenuRow}
                                  to={pathConstants.INVENTORY_TABLES}
                                  onClick={closeSubMenu}
                                  style={{
                                    borderBottom: 0,
                                    borderTop: "1px solid #ddd",
                                  }}
                                >
                                  <p>Tables</p>
                                </NavLink>
                              </secondaryComponents.NavigateWithPermission>
                            </motion.div>
                          ) : null}
                        </div>
                      </secondaryComponents.NavigateWithPermission>
                    </>
                  ) : currentMobileMenu === "More" ? (
                    <>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Customers"}
                        permissionChildKey={"can_view_customer"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={getPlatform(platform) + "/customers"}
                          onClick={closeSubMenu}
                        >
                          <img src={Icon.mobileCustomerIcon} alt="Customers" />
                          <p>Customers</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Customers"}
                        permissionChildKey={"can_view_customer"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={getPlatform(platform) + "/markets"}
                          onClick={closeSubMenu}
                        >
                          <MarketsIcon width="20" height="20" />
                          <p>Apps</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Accounting"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants.ACCOUNTING_OVERVIEW}
                          onClick={closeSubMenu}
                        >
                          <img
                            src={Icon.mobileDocumentationIcon}
                            alt="Accounting"
                          />
                          <p>Reports</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.CountryItemPermission>
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Accounting"}
                        >
                          <div className={`${styles.subMenuRow} d-block`}>
                            <div
                              className="d-flex justify-content-between w-100 align-items-center"
                              onClick={() => {
                                if (openLoyalty === "accounting")
                                  setOpenLoyalty(null);
                                else setOpenLoyalty("accounting");
                              }}
                            >
                              <div
                                className={`${styles.subMenuRow} ${styles.splited}`}
                                style={{ padding: 0 }}
                              >
                                <AccountingsIcon />
                                <p>Accounting Beta</p>
                              </div>
                              <FontAwesomeIcon
                                icon={
                                  openLoyalty === "accounting"
                                    ? "angle-up"
                                    : "angle-down"
                                }
                              />
                            </div>

                            {openLoyalty === "accounting" ? (
                              <motion.div
                                initial={{ y: -40 }}
                                animate={{ y: 0 }}
                                className="pl-4"
                              >
                                <secondaryComponents.NavigateWithPermission
                                  permissionParentKey={"Accounting"}
                                >
                                  <NavLink
                                    className={styles.subMenuRow}
                                    to={
                                      pathConstants.CASH_ACCOUNTINGS +
                                      "?account_type=cash_on_hand"
                                    }
                                    onClick={closeSubMenu}
                                    style={{
                                      borderBottom: 0,
                                      borderTop: "1px solid #ddd",
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <p>Cash On Hand</p>
                                  </NavLink>
                                </secondaryComponents.NavigateWithPermission>
                                <secondaryComponents.NavigateWithPermission
                                  permissionParentKey={"Accounting"}
                                >
                                  <NavLink
                                    className={styles.subMenuRow}
                                    to={
                                      pathConstants.BANK_ACCOUNTINGS +
                                      "?account_type=bank_account"
                                    }
                                    onClick={closeSubMenu}
                                    style={{
                                      borderBottom: 0,
                                      borderTop: "1px solid #ddd",
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <p>Bank Accounts</p>
                                  </NavLink>
                                </secondaryComponents.NavigateWithPermission>
                                <secondaryComponents.NavigateWithPermission
                                  permissionParentKey={"Accounting"}
                                >
                                  <NavLink
                                    className={styles.subMenuRow}
                                    to={
                                      pathConstants.ACCOUNTINGS_TRANSACTION +
                                      "?account_type=bank_account"
                                    }
                                    onClick={closeSubMenu}
                                    style={{
                                      borderBottom: 0,
                                      borderTop: "1px solid #ddd",
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <p>Bank Transactions</p>
                                  </NavLink>
                                </secondaryComponents.NavigateWithPermission>
                              </motion.div>
                            ) : null}
                          </div>
                        </secondaryComponents.NavigateWithPermission>
                      </secondaryComponents.CountryItemPermission>
                      <secondaryComponents.CountryItemPermission>
                        <secondaryComponents.NavigateWithPermission
                          permissionParentKey={"Loyalty"}
                        >
                          <div className={`${styles.subMenuRow} d-block`}>
                            <div
                              className="d-flex justify-content-between w-100 align-items-center"
                              onClick={() => {
                                if (openLoyalty === "loyalty")
                                  setOpenLoyalty(null);
                                else setOpenLoyalty("loyalty");
                              }}
                            >
                              <div
                                className={`${styles.subMenuRow} ${styles.splited}`}
                                style={{ padding: 0 }}
                              >
                                <img
                                  src={Icon.mobileLoyaltyIcon}
                                  alt="Loyalty"
                                />
                                <p>Loyalty</p>
                              </div>
                              <FontAwesomeIcon
                                icon={
                                  openLoyalty === "loyalty"
                                    ? "angle-up"
                                    : "angle-down"
                                }
                              />
                            </div>

                            {openLoyalty === "loyalty" ? (
                              <motion.div
                                initial={{ y: -40 }}
                                animate={{ y: 0 }}
                                className="pl-4"
                              >
                                <secondaryComponents.NavigateWithPermission
                                  permissionParentKey={"Loyalty"}
                                >
                                  <NavLink
                                    className={styles.subMenuRow}
                                    to={pathConstants.LOYALTY_MANAGE_CAMPAIGN}
                                    onClick={closeSubMenu}
                                    style={{
                                      borderBottom: 0,
                                      borderTop: "1px solid #ddd",
                                      paddingBottom: 0,
                                    }}
                                  >
                                    <img
                                      src={Icon.mobileManagePinkIcon}
                                      alt="Manage"
                                    />
                                    <p>Manage</p>
                                  </NavLink>
                                </secondaryComponents.NavigateWithPermission>
                              </motion.div>
                            ) : null}
                          </div>
                        </secondaryComponents.NavigateWithPermission>
                      </secondaryComponents.CountryItemPermission>
                      <a
                        href="https://enlumidatainc.zohodesk.com/portal/en/kb/enlumi-data-inc/lumi-business"
                        target="_blank"
                        className={styles.subMenuRow}
                        onClick={closeSubMenu}
                        rel="noreferrer"
                      >
                        <img
                          src={Icon.mobileDocumentationIcon}
                          alt="Documentation"
                        />
                        <p>Help</p>
                      </a>

                      <div className={`${styles.subMenuRow} d-block`}>
                        <div
                          className="d-flex justify-content-between w-100 align-items-center"
                          onClick={() => {
                            if (openLoyalty === "settings")
                              setOpenLoyalty(null);
                            else setOpenLoyalty("settings");
                          }}
                        >
                          <div
                            className={`${styles.subMenuRow} ${styles.splited}`}
                            style={{ padding: 0 }}
                          >
                            <img src={Icon.mobileSettingsIcon} alt="Settings" />
                            <p>Settings</p>
                          </div>
                          <FontAwesomeIcon
                            icon={
                              openLoyalty === "settings"
                                ? "angle-up"
                                : "angle-down"
                            }
                          />
                        </div>

                        {openLoyalty === "settings" ? (
                          <motion.div
                            initial={{ y: -40 }}
                            animate={{ y: 0 }}
                            className="pl-4"
                          >
                            <NavLink
                              className={styles.subMenuRow}
                              to={pathConstants.SETTINGS_PROFILE}
                              onClick={closeSubMenu}
                              style={{
                                borderBottom: 0,
                                borderTop: "1px solid #ddd",
                                marginTop: "10px",
                              }}
                            >
                              <p>Profile</p>
                            </NavLink>

                            <secondaryComponents.NavigateWithPermission
                              permissionParentKey={"Settings"}
                              permissionChildKey={
                                "can_create_update_and_invite_users"
                              }
                            >
                              <NavLink
                                className={styles.subMenuRow}
                                to={pathConstants.SETTINGS_USERS}
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>Users</p>
                              </NavLink>
                            </secondaryComponents.NavigateWithPermission>
                            <secondaryComponents.NavigateWithPermission
                              permissionParentKey={"Settings"}
                              permissionChildKey={
                                "can_edit_and_update_settlement_accounts"
                              }
                            >
                              <NavLink
                                className={styles.subMenuRow}
                                to={pathConstants.SETTINGS_SETTLEMENTS}
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>Settlements</p>
                              </NavLink>
                            </secondaryComponents.NavigateWithPermission>
                            <secondaryComponents.NavigateWithPermission
                              permissionParentKey={"Settings"}
                              permissionChildKey={"can_view_update_api_key"}
                            >
                              <NavLink
                                className={styles.subMenuRow}
                                to={pathConstants.SETTINGS_APIKEYS}
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                }}
                              >
                                <p>API</p>
                              </NavLink>
                            </secondaryComponents.NavigateWithPermission>
                            <secondaryComponents.NavigateWithPermission
                              permissionParentKey={"Settings"}
                              permissionChildKey={"can_update_subscriptions"}
                            >
                              <NavLink
                                className={styles.subMenuRow}
                                to={pathConstants.SETTINGS_SUBSCRIPTIONS}
                                onClick={closeSubMenu}
                                style={{
                                  borderBottom: 0,
                                  borderTop: "1px solid #ddd",
                                  paddingBottom: 0,
                                }}
                              >
                                <p>Subscriptions</p>
                              </NavLink>
                            </secondaryComponents.NavigateWithPermission>
                          </motion.div>
                        ) : null}
                      </div>

                      <a
                        className={` ${styles.subMenuRow} `}
                        onClick={logoutHandler}
                        style={{ borderLeft: "1px solid #ddd" }}
                      >
                        <img src={Icon.mobileLogoutIcon} alt="logout" />
                        <p className="error--text">Logout</p>
                      </a>
                    </>
                  ) : currentMobileMenu === "Expenses" ? (
                    <>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Expense"}
                        permissionChildKey={"can_create_expense"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants?.EXPENSES_LIST}
                          onClick={closeSubMenu}
                        >
                          <p>Expenses</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Expense"}
                        permissionChildKey={"can_view_expense_analytics"}
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants?.EXPENSES_ANALYTICS}
                          onClick={closeSubMenu}
                        >
                          <p>Analytics</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={"Expense"}
                        permissionChildKey={
                          "can_view_edit_and_create_expense_category"
                        }
                      >
                        <NavLink
                          className={styles.subMenuRow}
                          to={pathConstants?.EXPENSES_CATEGORIES}
                          onClick={closeSubMenu}
                        >
                          <p>Categories</p>
                        </NavLink>
                      </secondaryComponents.NavigateWithPermission>
                    </>
                  ) : null}
                </motion.div>
              ) : null}

              <div className={styles.mobileMenu}>
                {menuData?.map((item) => (
                  <React.Fragment key={item?.key}>
                    <secondaryComponents.CountryItemPermission
                      dontCheckCountry={item.key === 1 ? false : true}
                    >
                      <secondaryComponents.NavigateWithPermission
                        permissionParentKey={item?.permissionParentKey}
                      >
                        <div
                          className={`${styles.mobileMenu__item} ${
                            item?.active ? styles.active : ""
                          }`}
                          onClick={item?.clickEventHandler}
                        >
                          {item?.key === 2 ? (
                            <div className={styles.inventoryCover}>
                              <img src={item?.icon} alt={item?.name} />
                            </div>
                          ) : (
                            <img src={item?.icon} alt={item?.name} />
                          )}

                          <p>{item?.name}</p>
                          <span className={styles.pointer}></span>
                        </div>
                      </secondaryComponents.NavigateWithPermission>
                    </secondaryComponents.CountryItemPermission>
                  </React.Fragment>
                ))}
              </div>
            </div>

            {/* mobile menu overlay */}
            <div
              className={styles.mobileMenuOverlay}
              onClick={() => {
                setCurrentMobileMenu(null);
                setIsSubMobileMenu(false);
              }}
            ></div>
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
    </>
  );
};

export default AppLayout;
