import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    store: yup.string().required("Store is required"),
  })
  .required("All fields are required");

const Validation = ({ reconsileDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { store: reconsileDetails?.store },
  });
};

export default Validation;
