import "./Documentation.scss";
import secondaryComponents from "../../../components/secondaryComponents";

const Documentation = () => {
  return (
    <div>
      <secondaryComponents.ComingSoon />
    </div>
  );
};

export default Documentation;
