import { useTable } from "react-table";
import useOrderWidget from "../../../hooks/useOrderWidget";
import "../../../assets/styles/base/globalPage.scss";
import useStoreFrontDisplay from "../../../hooks/useStoreFrontDisplay";

const useSellModeStoreFrontOrders = () => {
  const {
    isFetchingOrders,
    orders,
    metaDetails,
    selectedOrder,
    currency_code,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
    handleRefetchOrders,
  } = useOrderWidget({ orderSource: "STOREFRONT" });
  const { columns } = useStoreFrontDisplay({
    setSelectedOrder,
    currency_code,
    handleRefetchOrders,
  });

  const instance = useTable({ columns, data: orders || [] });

  return {
    instance,
    isFetchingOrders,
    orders,
    metaDetails,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
    handleRefetchOrders,
  };
};

export default useSellModeStoreFrontOrders;
