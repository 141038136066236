import { formatCurrency } from "../../../helpers";
import styles from "./ServiceCard.module.scss";
import { PRODUCT_DEFAULT_ICONS } from "../../../helpers/constants";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

const ServiceCard = ({
  updatingCart,
  service,
  serviceIndex,
  cartDetails,
  handleAddToCart,
  handleReduceCartItem,
  selectedItemId,
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className={styles.serviceCard}>
      <div className={styles.serviceCardDetails}>
        <div className={styles.serviceImage}>
          <img
            src={
              service?.image_url === "" || service?.image_url === null
                ? PRODUCT_DEFAULT_ICONS[serviceIndex % 5]
                : service?.image_url
            }
            alt=""
          />
        </div>
        <div>
          <p className="dark--text mb-1">{service?.name}</p>
        </div>
      </div>
      <div className={styles.servicePricing}>
        <p className="d-flex align-items-center">
          <span
            className="error mr-1"
            style={{
              width: "5px",
              height: "5px",
              borderRadius: "5px",
            }}
          ></span>
          <span className="font-weight-semibold dark--text">
            {formatCurrency({
              value: service?.selling_price,
              currencyCode: currency_code,
            })}
          </span>
        </p>
      </div>

      <div className={styles.serviceActions}>
        {cartDetails !== undefined ? (
          <div
            className={styles.product__actions}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <button
              onClick={(event) => {
                event.stopPropagation();
                handleReduceCartItem({
                  serviceId: service?.id,
                  quantity: cartDetails?.quantity - 1,
                  cartId: cartDetails?.id,
                });
              }}
              disabled={updatingCart}
              className={styles.decrement}
            >
              -
            </button>
            {updatingCart && selectedItemId === service?.id ? (
              <FontAwesomeIcon
                icon={["fas", "spinner"]}
                className="fa-spin purple--text"
                style={{ fontSize: "15px", width: "25px" }}
              />
            ) : (
              <primaryComponents.CartInputField
                value={cartDetails?.quantity || 0}
                updateCart={(val) =>
                  handleAddToCart({ serviceId: service?.id, quantity: val })
                }
              />
            )}
            <button
              onClick={(event) => {
                event.stopPropagation();
                handleAddToCart({
                  serviceId: service?.id,
                  quantity: cartDetails?.quantity + 1,
                });
              }}
              disabled={updatingCart}
              className={styles.increment}
            >
              +
            </button>
          </div>
        ) : (
          <primaryComponents.Button
            classNames={`btn btn--primary ${styles.addToCartBtn}`}
            isLoading={updatingCart && selectedItemId === service?.id}
            isDisabled={updatingCart && selectedItemId === service?.id}
            loadingText="Adding..."
            showSpinner={false}
            onClick={(event) => {
              event.stopPropagation();
              handleAddToCart({
                serviceId: service?.id,
                quantity: 1,
              });
            }}
          >
            Add to cart
          </primaryComponents.Button>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
