import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    bank: yup.string().required("Select a bank"),
    useExternalDetails: yup.boolean(),
    subAccount: yup.string().required("Select sub account"),
    accountNumber: yup
      .string()
      .min(10, "Minimum of 10 digits is allowed")
      .max(10, "Maximum of 10 digits is allowed"),
    amount: yup.string().required("Amount is required"),
    transferDescription: yup
      .string()
      .required("Reason for transfer is required"),
  })
  .required();

const Validation = ({
  bank,
  accountNumber,
  amount,
  transferDescription,
  subAccount,
}) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      bank,
      accountNumber,
      amount,
      transferDescription,
      subAccount,
    },
  });
};

export default Validation;
