import { Outlet } from "react-router-dom";
import "./SubAccountLayout.scss";

const SubAccountLayout = () => {
  return (
    <div className="sub-account-layout">
      <Outlet />
    </div>
  );
};

export default SubAccountLayout;
