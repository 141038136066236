import { useTable } from "react-table";
import { useMemo } from "react";
import { useState } from "react";
// import { mapUserRole } from "../../../helpers";
import { useGetUserList } from "../../../hooks/api/queries/useSettings";
import primaryComponents from "../../../components/primaryComponents";

const useUsersTableLogic = () => {
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [subAccountList, setsubAccountList] = useState([]);
  const [storeAccessList, setStoreAccessList] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

  const {
    isLoading: isFetchingUsers,
    data,
    refetch: fetchUsersList,
  } = useGetUserList();

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "first_name",
        Cell: ({ cell: { value, row } }) => (
          <span>
            {value} {row.original["last_name"]}
          </span>
        ),
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ cell: { value } }) => (
          <primaryComponents.Button classNames={"btn btn--green smallBtn"}>
            {value?.charAt(0)?.toUpperCase() + value?.slice(1)?.toLowerCase()}
          </primaryComponents.Button>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: data || [] });

  return {
    instance,
    isFetchingUsers,
    usersList: data || [],
    showInviteModal,
    setShowInviteModal,
    userRole,
    userEmail,
    subAccountList,
    setsubAccountList,
    setUserRole,
    setUserEmail,
    showDeleteUserModal,
    setShowDeleteUserModal,
    columns,
    storeAccessList,
    setStoreAccessList,
    fetchUsersList,
  };
};

export default useUsersTableLogic;
