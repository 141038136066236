import { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { removeStock } from "../../../services/inventory";

import { ToastContext } from "../../../hooks/context";

export const useRemoveStock = ({ closeWidget }) => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [stores, setStores] = useState([]);
  const [selectedProductVariants, setSelectedProductVariants] = useState([]);

  const [formData, setFormData] = useState({
    product_id: "",
    variant_id: "",
    store_id: "",
    quantity: 0,
    reason: "",
  });

  const removeStockMutation = useMutation({
    mutationFn: (data) => removeStock(data),
    onSuccess: () => {
      triggerToast("Product removed successfully", "success");
      queryClient.invalidateQueries(["getProductList"]);
      closeWidget();
    },
  });

  const handleRemoveStock = () => {
    removeStockMutation.mutate({
      product_id: formData.product_id,
      variant_id: formData.variant_id ? formData.variant_id : undefined,
      store_id: formData.store_id,
      quantity: formData.quantity,
      reason: formData.reason,
    });
  };

  return {
    formData,
    isDeletingStock: removeStockMutation?.isLoading,
    stores,
    selectedProductVariants,
    setStores,
    setFormData,
    setSelectedProductVariants,
    handleRemoveStock,
  };
};
