import { useTable } from "react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";

const PendingTransfersTableLogic = ({ transfers }) => {
  const [showTransferApprovalModal, setShowTransferApprovalModal] =
    useState(false);
  const [transferOnReview, setTransferOnReview] = useState({});
  const { role: business_role } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => new Date(value).toLocaleString("en-us"),
      },
      {
        Header: "Beneficiary",
        accessor: "account_name",
        Cell: ({ cell: { value, row } }) => (
          <Link
            className="purple--text"
            to={pathConstants.PAYMENT_TRANSFER_DETAILS({
              transferId: row.original["id"],
            })}
            style={{
              fontSize: "12px",
              textDecoration: "underline",
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: "Account No.",
        accessor: "account_number",
      },
      {
        Header: "Bank",
        accessor: "bank_name",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }),
      },
      {
        Header: "Transfer Fee",
        accessor: "fee",
        Cell: ({ cell: { value } }) =>
          formatCurrency({ value: value, currencyCode: currency_code }),
      },
      {
        Header: "Account",
        accessor: "business_tag",
      },
      {
        Header: "Initiated By",
        accessor: "initiator_name",
        Cell: ({ cell: { value } }) => (value ? value.split(" ")[0] : "N/A"),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <secondaryComponents.NavigateWithPermission
            permissionParentKey={"Payments"}
            permissionChildKey={"can_authorize_a_transfer"}
          >
            <primaryComponents.Button
              classNames="btn btn--outline"
              style={{
                height: "30px",
                fontSize: "12px",
                padding: "0 15px",
              }}
              onClick={() => {
                setTransferOnReview(instance?.data[row?.index]);
                setShowTransferApprovalModal(true);
              }}
            >
              Review
            </primaryComponents.Button>
          </secondaryComponents.NavigateWithPermission>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: transfers });
  return {
    instance,
    transfers,
    setShowTransferApprovalModal,
    showTransferApprovalModal,
    transferOnReview,
    setTransferOnReview,
    business_role,
  };
};

export default PendingTransfersTableLogic;
