/* eslint-disable react/jsx-key */
import "./ExpenseCategoryTable.module.scss";
import secondaryComponents from "..";
import useExpenseCategoryTable from "./useExpenseCategoryTable";
import Images from "../../../assets/images";

const ExpenseCategoryTable = ({ expenseCategories, handleFetchCategories }) => {
  const {
    selectedCategory,
    isDeleteCategory,
    isEditCategory,
    instance,
    setIsDeleteCategory,
    setIsEditCategory,
  } = useExpenseCategoryTable({
    expenseCategories,
    handleFetchCategories,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="expenses-table">
      <div className="expenses-table__content">
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.expenseEmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no expense categories
            </h4>
            <p className="text-center">
              Use the button above to add your categories. When you do, they
              would appear here.
            </p>
          </div>
        </secondaryComponents.TableHelper>

        {/* {expenseCategories?.length > 0 && (
          <div className="mt-auto">
            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </div>
        )} */}
      </div>

      {/* edit modal */}
      <secondaryComponents.Modal
        isActive={isEditCategory}
        closeModal={() => setIsEditCategory(false)}
        width="500px"
      >
        <secondaryComponents.CreateExpenseCategory
          categoryDetails={selectedCategory}
          handleFetchCategories={handleFetchCategories}
          closeWidget={() => setIsEditCategory(false)}
          isEditMode={true}
        />
      </secondaryComponents.Modal>

      {/* delete modal */}
      <secondaryComponents.Modal
        isActive={isDeleteCategory}
        closeModal={() => setIsDeleteCategory(false)}
        width="400px"
      >
        <secondaryComponents.ExpenseCategoryDeletion
          categoryDetails={selectedCategory}
          handleFetchCategories={handleFetchCategories}
          closeWidget={() => setIsDeleteCategory(false)}
        />
      </secondaryComponents.Modal>
    </div>
  );
};

export default ExpenseCategoryTable;
