import secondaryComponents from "../../../../../components/secondaryComponents";
import useEditStore from "./useEditStore";

const EditStore = () => {
  const {
    isLoadingStates,
    countries,
    states,
    storeInfo,
    isLoading,
    setSelectedCountry,
  } = useEditStore();

  return (
    <>
      <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-4">
        <secondaryComponents.StoreForm
          storeInfo={storeInfo}
          isEditMode={true}
          isLoadingStates={isLoadingStates}
          states={states}
          countries={countries}
          setSelectedCountry={setSelectedCountry}
        />
      </secondaryComponents.LoaderHelper>
    </>
  );
};

export default EditStore;
