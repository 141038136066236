import styles from "./PrescriptionWidget.module.scss";
import primaryComponents from "../../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { truncateString } from "../../../../helpers";
import { useState } from "react";

export default function PrescriptionItem({
  index,
  prescription,
  prescriptionItems,
  setPrescriptionItems,
}) {
  const [isCollapsed, setCollapsed] = useState(true);
  return (
    <div className={styles.prescriptionWidget__item}>
      <div
        onClick={() => setCollapsed((prev) => !prev)}
        className={styles.prescriptionWidget__item__header}
      >
        <p className="dark--text">
          {truncateString(prescription?.cart_item_name, 100)}
        </p>
        <FontAwesomeIcon
          // onClick={() => setCollapsed((prev) => !prev)}
          className="d-block"
          icon="angle-down"
          style={{ fontSize: "20px", cursor: "pointer" }}
        />
      </div>
      {isCollapsed ? null : (
        <div className={styles.prescriptionWidget__item__content}>
          <p className="dark--text">Dosage</p>
          <div className={styles.dosage}>
            <primaryComponents.InputField
              classNames="white"
              label={"Morning"}
              value={prescriptionItems[index].morning_dosage}
              onChange={({ target: { value } }) => {
                const prescriptions = [...prescriptionItems];
                prescriptions[index].morning_dosage = value;
                setPrescriptionItems(prescriptions);
              }}
            />
            <primaryComponents.InputField
              classNames="white"
              value={prescriptionItems[index].afternoon_dosage}
              label={"Afternoon"}
              onChange={({ target: { value } }) => {
                const prescriptions = [...prescriptionItems];
                prescriptions[index].afternoon_dosage = value;
                setPrescriptionItems(prescriptions);
              }}
            />
            <primaryComponents.InputField
              value={prescriptionItems[index].night_dosage}
              classNames="white"
              label={"Night"}
              onChange={({ target: { value } }) => {
                const prescriptions = [...prescriptionItems];
                prescriptions[index].night_dosage = value;
                setPrescriptionItems(prescriptions);
              }}
            />
          </div>
          <div className={styles.frequency}>
            <primaryComponents.SelectField
              value={prescriptionItems[index].frequency}
              label={"Frequency"}
              isRequired
              options={["Daily", "Weekly", "Monthly"]}
              onChange={(value) => {
                const prescriptions = [...prescriptionItems];
                prescriptions[index].frequency = value;
                setPrescriptionItems(prescriptions);
              }}
              placeholder={"Select frequency"}
            />
            <div style={{ width: "200px", marginTop: 4 }}>
              <primaryComponents.MeasurementInputField
                label={"Measurement (For Liquids)"}
                isRequired={true}
                onChange={({ value, unit }) => {
                  const prescriptions = [...prescriptionItems];
                  prescriptions[index].measurement = value;
                  prescriptions[index].unit_id = unit;
                  setPrescriptionItems(prescriptions);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
