import "./SavedBeneficiaries.scss";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import useSavedBeneficiaries from "./useSavedBeneficiaries";

const SavedBeneficiaries = () => {
  const { setTerminalHeader } = useOutletContext();
  useEffect(() => {
    setTerminalHeader({
      title: "Saved Beneficiaries",
      description:
        "Manage your existing recipients and add new ones to send money to seamlessly",
    });
  }, []);

  const { search, beneficiaries, isFetchingBeneficiaries, searchBeneficiary } =
    useSavedBeneficiaries();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Transfers - Beneficiaries"
      description="View all beneficiaries you choose to save on transfers"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={globalStyles.tableWrapper}
        >
          <div className={globalStyles.tableHeader}>
            {/* header section */}
            <primaryComponents.SearchField
              trigger={(value) => searchBeneficiary(value)}
              value={search.get("query") || ""}
              placeholder="Search customers by name or email"
              classNames={`${globalStyles.searchField}`}
              triggerOnInput={true}
              showsearchBtn={true}
            />
          </div>

          {/* transfer table */}
          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isFetchingBeneficiaries}
          >
            <secondaryComponents.BeneficiariesTable
              beneficiaries={beneficiaries}
            />
          </secondaryComponents.LoaderHelper>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default SavedBeneficiaries;
