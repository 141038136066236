import "./LinkCustomerWidget.scss";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { useLinkCustomerLogic } from "./LinkCustomerWidgetLogic";
import { useLinkCustomerValidation } from "./LinkCustomerValidation";
import { useEffect } from "react";

const LinkCustomerWidget = ({
  closeWidget,
  transactionId,
  setRefreshDetails,
}) => {
  const {
    customerPhoneNumber,
    isSubmitting,
    setCustomerPhoneNumber,
    handleLinkCustomer,
  } = useLinkCustomerLogic({ closeWidget, transactionId, setRefreshDetails });

  const {
    control,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    handleSubmit,
  } = useLinkCustomerValidation({ customerPhoneNumber });

  useEffect(() => {
    window.setCustomerTransactionError = setError;
    window.clearCustomerTransactionError = clearErrors;
  }, []);

  return (
    <div className="link-customer-widget__content">
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          onClick={() => closeWidget()}
          className="close__icon ml-auto"
          icon="times"
        />
      </div>
      <div className="link-customer-widget__content__title">
        <h4 className="font-weight-semibold">Update customer details</h4>
      </div>
      <form
        onSubmit={handleSubmit(handleLinkCustomer)}
        className="link-customer-widget__content__form"
        id="link-customer-transaction"
      >
        <Controller
          name="customerPhoneNumber"
          control={control}
          defaultValue={customerPhoneNumber}
          render={({ field: { onChange } }) => (
            <primaryComponents.PhoneNumberInputField
              label="Phone Number"
              placeholder="Enter customer phone number"
              name="customerPhoneNumber"
              isRequired={true}
              errorMessage={errors.customerPhoneNumber}
              onChange={({ target: { value } }) => {
                onChange(value);
                setCustomerPhoneNumber(value);
              }}
              onKeyUp={() =>
                errors.customerPhoneNumber !== undefined &&
                trigger("customerPhoneNumber")
              }
            />
          )}
        />
      </form>
      <primaryComponents.Button
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        loadingText={"Updating..."}
        classNames={"btn btn--primary w-100 mt-auto"}
        type="submit"
        form="link-customer-transaction"
      >
        Update
      </primaryComponents.Button>
    </div>
  );
};

export default LinkCustomerWidget;
