import "./ToastMessage.scss";
import ToastMessageLogic from "./ToastMessageLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const ToastMessage = ({ children }) => {
  const {
    toastMessageDetails,
    setToastMessageDetails,
    triggerToast,
    ToastContext,
  } = ToastMessageLogic();
  return (
    <ToastContext.Provider value={triggerToast}>
      {children}

      {toastMessageDetails !== null && (
        <motion.div
          initial={{ opacity: 0, marginTop: "-15px" }}
          animate={{
            opacity: 1,
            marginTop: "0px",
            transition: { duration: 0.1 },
          }}
          exit={{ opacity: 0 }}
          className={`toast-message ${
            toastMessageDetails.type === "success"
              ? "success"
              : toastMessageDetails.type === "hint"
                ? "hint"
                : "warning"
          }`}
        >
          <div className="d-flex">
            <FontAwesomeIcon
              icon={`${
                toastMessageDetails.type === "success"
                  ? "check-circle"
                  : "exclamation-circle"
              }`}
              className="circle white--text mr-2"
              style={{
                fontSize: "15px",
                background: "transparent",
                marginTop: "2px",
              }}
            />
            <span className="mt-0 white--text mb-0">
              {toastMessageDetails.message}
            </span>
          </div>
          <span
            className="close-toast"
            onClick={() => {
              setToastMessageDetails(null);
            }}
          >
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="white--text mt-1 ml-5"
              style={{ fontSize: "20px" }}
            />
          </span>
        </motion.div>
      )}
    </ToastContext.Provider>
  );
};
export default ToastMessage;
