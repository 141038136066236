import { useMemo, useState } from "react";
import { useTable } from "react-table";
import primaryComponents from "../../primaryComponents";
import { defaultDateDisplay } from "../../../helpers";

const useRidersTable = ({ riders }) => {
  const [riderDetails, setRiderDetails] = useState(null);
  const [riderDeleteDetails, setRiderDeleteDetails] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Added Date",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => defaultDateDisplay(value),
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ row }) => (
          <span className="d-flex" style={{ gap: "5px" }}>
            <primaryComponents.Button
              onClick={() => {
                setRiderDetails(row?.original);
              }}
              classNames={"btn btn--primary smallBtn"}
            >
              Update
            </primaryComponents.Button>

            <primaryComponents.Button
              onClick={() => {
                setRiderDeleteDetails(row?.original);
              }}
              classNames={"btn btn--outline-red smallBtn"}
            >
              Delete
            </primaryComponents.Button>
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: riders || [] });
  return {
    instance,
    riderDetails,
    riderDeleteDetails,
    setRiderDeleteDetails,
    setRiderDetails,
  };
};
export default useRidersTable;
