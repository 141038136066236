import primaryComponents from "../../primaryComponents";

const OrderCancel = ({ orderDetails, closeWidget, cancelCurrentOrder }) => {
  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        Are you sure you want to cancel this bill
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="dark--text font-weight-semibold mb-1">
            Order Number
          </span>
          <span>{orderDetails?.id}</span>
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={closeWidget}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={() => {
            cancelCurrentOrder(orderDetails?.id);
            closeWidget();
          }}
          classNames={"btn btn--danger cover"}
          loadingText="Cancelling..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default OrderCancel;
