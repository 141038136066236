import { useQuery } from "react-query";
import {
  getSingleRole,
  getUnauthorizedPermissions,
} from "../../../../../../services/settings";
import { useParams } from "react-router-dom";

const useRolePage = () => {
  const { id } = useParams();

  const { isLoading, data } = useQuery(["single-role", id], () =>
    getSingleRole(id).then((res) => res.data?.data),
  );

  const { isLoading: isLoadingUnauthorized, data: dataUnauthorized } = useQuery(
    ["single-unauthorized-role", id],
    () => getUnauthorizedPermissions(id).then((res) => res.data?.data),
  );

  return {
    isLoading,
    isLoadingUnauthorized,
    data,
    dataUnauthorized,
  };
};

export default useRolePage;
