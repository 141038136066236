export const PhoneIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#7647EE" />
      <path
        d="M7.04834 6.1665L9.17117 6.24332C9.6183 6.2595 10.0151 6.54186 10.1865 6.96579L10.8142 8.51858C10.9602 8.87993 10.9209 9.29222 10.7094 9.6177L9.90517 10.855C10.3813 11.5388 11.6764 13.1407 13.0477 14.0784L14.0702 13.4491C14.3301 13.2892 14.6412 13.2417 14.9353 13.3171L16.968 13.8383C17.5087 13.977 17.873 14.4955 17.8303 15.0654L17.6998 16.8056C17.6539 17.4163 17.1541 17.8955 16.5608 17.8266C8.72925 16.9159 4.13666 6.16649 7.04834 6.1665Z"
        stroke="white"
        strokeWidth="1.16667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
