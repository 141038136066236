import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getOrders } from "../services/inventory";
import { cleanObject } from "../helpers";

const useOrderWidget = ({ orderSource = "INTERNAL" }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [queryParams, setQueryParams] = useState(
    cleanObject({ order_source: orderSource }),
  );

  const {
    isLoading: isFetchingOrders,
    data: orders,
    refetch: handleRefetchOrders,
  } = useQuery(["getOrders", queryParams], () =>
    getOrders(queryParams).then((res) => {
      return res.data;
    }),
  );

  const handleRenderStatusIndicator = (value) => {
    if (value === "COMPLETED") {
      return { color: "#19AE57", backgroundColor: "#E5FFF0" };
    } else if (value === "IN_PROGRESS") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else if (value === "PLACED") {
      return { color: "#F7AD3C", backgroundColor: "#FEF7EB" };
    } else {
      return { color: "#E02020", backgroundColor: "#FFEEE9" };
    }
  };

  const handleFilterOrderByStatus = (value) => {
    const newQueryParams = { ...queryParams };
    if (value === "") delete newQueryParams.status;
    else newQueryParams.status = value;
    delete newQueryParams.page;
    setQueryParams(newQueryParams);
  };

  const gotoPage = (pageNumber) => {
    const newQueryParams = { ...queryParams };
    newQueryParams.page = pageNumber;
    setQueryParams(newQueryParams);
  };

  const handleSearchOrders = (value) => {
    const newQueryParams = { ...queryParams };
    if (value.length === 0) delete newQueryParams.query;
    else newQueryParams.query = value;
    delete newQueryParams.page;
    setQueryParams(newQueryParams);
  };

  return {
    isFetchingOrders,
    orders: orders?.data || [],
    metaDetails: orders?.meta || {},
    selectedOrder,
    currency_code,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
    handleRenderStatusIndicator,
    handleRefetchOrders,
  };
};

export default useOrderWidget;
