import { getBusiness, getActiveSubscription } from "../services/settings";
import { setBusinessDetails } from "../store/modules/profile";
import { useQueries } from "react-query";
import { useGetProfileDetails } from "./api/queries/useSettings";
import { useDispatch } from "react-redux";
import useGetPermission from "./useGetPermission";
import { useGetDashboardDetails } from "./api/queries/useDashboard";
import { useGetStoreList, useGetTagList } from "./api/queries/useInventory";

const useRootProperties = () => {
  const dispatch = useDispatch();
  const profileDetails = useGetProfileDetails();
  const hasFetchedProfile = profileDetails?.data ? true : false;
  const { isPermitted: isPermittedToViewDashboard } = useGetPermission({
    permissionChildKey:
      "can_view_payment_dashboard_overview_cash_and_customer_analytics",
    permissionParentKey: "Payments",
  });
  const { isPermitted: isPermittedToViewStore } = useGetPermission({
    permissionChildKey: "can_view_stores",
    permissionParentKey: "Inventory Management",
  });
  const { isPermitted: isPermittedToViewTags } = useGetPermission({
    permissionChildKey: "can_view_tags",
    permissionParentKey: "Payments",
  });

  const dashboardDetails = useGetDashboardDetails({
    isEnabled: isPermittedToViewDashboard && hasFetchedProfile,
    queries: "?all_business=true",
    staleTime: Infinity,
    queryName: "getRootDashboardDetails",
  });
  const storeList = useGetStoreList(
    isPermittedToViewStore && hasFetchedProfile,
  );
  const tagDetails = useGetTagList(isPermittedToViewTags && hasFetchedProfile);

  const [businessDetails, activeSubscription] = useQueries([
    {
      queryKey: ["activeSubscription"],
      staleTime: Infinity,
      queryFn: () => getActiveSubscription().then((res) => res?.data?.data),
    },
    {
      queryKey: ["businessDetails"],
      staleTime: Infinity,
      queryFn: () =>
        getBusiness().then((res) => {
          if (res?.data?.data) dispatch(setBusinessDetails(res?.data?.data));
        }),
    },
  ]);

  return {
    isLoading:
      profileDetails.isLoading ||
      businessDetails.isLoading ||
      activeSubscription.isLoading ||
      dashboardDetails?.isLoading ||
      storeList?.isLoading ||
      tagDetails?.isLoading,
  };
};
export default useRootProperties;
