import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import React from "react";
import useSubscriptionPlans from "../../appPages/Settings/Subscriptions/SubscriptionPlans/useSubscriptionPlans";
import styles from "../../appPages/Settings/Subscriptions/SubscriptionSelection/SubscriptionSelection.module.scss";
import { PERIOD } from "../../../helpers/constants";
import Logo from "../../../assets/logo";

const SubscriptionView = () => {
  const {
    isLoading,
    subscriptionTiers,
    subscriptionPeriod,
    isPayModal,
    upgradeDetails,
    activeSubscription,
    setUpgradeDetails,
    setIsPayModal,
    setSubscriptionPeriod,
  } = useSubscriptionPlans(false);

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Subscription"
      description="View subscription plans"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.subscriptionSelection} px-4 py-3`}
        >
          <div className="mb-2">
            <img width={"101px"} src={Logo.secondary} alt="Lumi" />
          </div>
          <div className={styles.subscriptionSelection__header}>
            <div className={styles.instruction}>
              <h3 className="text-center mb-2">Select a Subscription Plan</h3>
              <p className="text-center dark--text">
                Experience tailored solutions for your needs—inventory, expense,
                loyalty, accounting, and payments—all on our platform, perfectly
                designed for you.
              </p>
            </div>

            <div>
              {/* period toggler */}
              <secondaryComponents.SubscriptionPeriod
                subscriptionPeriod={subscriptionPeriod}
                setSubscriptionPeriod={setSubscriptionPeriod}
              />

              <secondaryComponents.SubscriptionPlanHeader
                subscriptionTiers={subscriptionTiers}
                activeSubscription={activeSubscription}
                subscriptionPeriod={subscriptionPeriod}
                setUpgradeDetails={setUpgradeDetails}
                setIsPayModal={setIsPayModal}
              />
            </div>
          </div>

          {/* Inventory */}
          <secondaryComponents.LoaderHelper
            classNames="mt-5"
            isLoading={isLoading}
          >
            <div className={styles.subscriptionSelection__desktop}>
              <secondaryComponents.PlanInventory
                subscriptionPeriod={subscriptionPeriod}
                subscription={subscriptionTiers?.[0]}
              />
            </div>
            <div className={styles.subscriptionSelection__mobile}>
              {subscriptionTiers?.map((item, index) => (
                <React.Fragment key={item?.name}>
                  <secondaryComponents.FreePlanMobileView
                    subscription={subscriptionTiers?.[index]}
                    activeSubscription={activeSubscription}
                    subscriptionPeriod={subscriptionPeriod}
                    setUpgradeDetails={setUpgradeDetails}
                    setIsPayModal={setIsPayModal}
                    accessKey={
                      index === 0
                        ? "free"
                        : index === 1
                          ? "growth"
                          : index === 2
                            ? "essential"
                            : index === 3
                              ? "scale"
                              : ""
                    }
                  />
                </React.Fragment>
              ))}
            </div>
          </secondaryComponents.LoaderHelper>

          {/* subscription payment section */}
          <secondaryComponents.SideModal
            isActive={isPayModal}
            closeModal={() => setIsPayModal(false)}
          >
            <secondaryComponents.SubscriptionPay
              upgradeDetails={upgradeDetails}
              subscriptionPeriod={subscriptionPeriod}
              setSubscriptionPeriod={setSubscriptionPeriod}
              period={PERIOD}
              subscriptionTiers={subscriptionTiers}
              setIsPayModal={setIsPayModal}
            />
          </secondaryComponents.SideModal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default SubscriptionView;
