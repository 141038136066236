import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SplitPaymentValidationSchema = yup.object({
  cashAmount: yup
    .number()
    .min(0, "The minimum amount allowed is 0")
    .nullable(true)
    .transform((_, val) => (val === "" ? null : Number(val)))
    .typeError("Amount must be a number"),
  cardAmount: yup
    .number()
    .min(0, "The minimum amount allowed is 0")
    .nullable(true)
    .transform((_, val) => (val === "" ? null : Number(val)))
    .typeError("Amount must be a number"),
  creditAmount: yup
    .number()
    .min(0, "The minimum amount allowed is 0")
    .nullable(true)
    .transform((_, val) => (val === "" ? null : Number(val)))
    .typeError("Amount must be a number"),
  transferAmount: yup
    .number()
    .min(0, "The minimum amount allowed is 0")
    .nullable(true)
    .transform((_, val) => (val === "" ? null : Number(val)))
    .typeError("Amount must be a number"),
});

const SplitPaymentValidation = (
  cashAmount,
  cardAmount,
  creditAmount,
  transferAmount,
) => {
  return useForm({
    resolver: yupResolver(SplitPaymentValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      cashAmount,
      cardAmount,
      creditAmount,
      transferAmount,
    },
  });
};

export default SplitPaymentValidation;
