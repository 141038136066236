import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  createProductBarcode,
  getProductBarcode,
} from "../../../services/inventory";

const useProductBarcode = ({ selectedProduct }) => {
  const [isRegenratePrompt, setIsRegenratePrompt] = useState(false);

  const {
    isLoading: isFetchingBarcode,
    data: barCodeProperties,
    refetch: refetchBarcode,
  } = useQuery(
    ["getProductBarcode", selectedProduct?.id],
    () => getProductBarcode(selectedProduct?.id).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
      onSuccess: (res) => {
        const itemArray = Array.isArray(res) ? res : [res];
        if (itemArray?.[0]?.barcode === null) createBarcode();
      },
    },
  );

  const {
    mutate: addBarcodeForExistingProducts,
    isLoading: isCreatingBarcode,
  } = useMutation((data) => createProductBarcode(data));
  const createBarcode = () => {
    addBarcodeForExistingProducts(
      {
        id: selectedProduct?.id,
      },
      {
        onSuccess: () => {
          refetchBarcode();
        },
      },
    );
  };

  return {
    isLoading: isFetchingBarcode || isCreatingBarcode,
    barCodeProperties,
    isRegenratePrompt,
    createBarcode,
    setIsRegenratePrompt,
  };
};
export default useProductBarcode;
