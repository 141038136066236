import { useEffect, useState } from "react";
import {
  getProfitTrend,
  getRevenueTrend,
  getTopProductsList,
  getAnalyticsData,
} from "../../../../services/inventory";
import { useSelector } from "react-redux";
import { useQueries } from "react-query";
import { getUrlQuerysection } from "../../../../helpers";
import { useSearchParams } from "react-router-dom";
import { useStoreCurrency } from "../../../../hooks/useStoreCurrency";

export const useInventoryAnalytics = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queries = getUrlQuerysection();
  const queryParams = new URLSearchParams(window.location.search);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { storeHasMultipleCurrency } = useStoreCurrency();

  const [startDate, setStartDate] = useState(searchParams.get("start_date"));
  const [endDate, setEndDate] = useState(searchParams.get("end_date"));
  const [currentStore, setCurrentStore] = useState(
    storeHasMultipleCurrency
      ? queryParams.get("store_id") || storeList[0]?.id
      : "",
  );

  useEffect(() => {
    if (storeList.length > 0 && storeHasMultipleCurrency) {
      searchParams.set("store_id", storeList[0]?.id);
      setSearchParams(searchParams, { replace: true });
    }
  }, [storeList, storeHasMultipleCurrency]);

  const [profitTrend, revenueTrend, topProductsList, inventoryAnalyticsData] =
    useQueries([
      {
        staleTime: Infinity,
        queryKey: ["profitTrend", queries],
        queryFn: () => getProfitTrend(queries).then((res) => res?.data?.data),
      },
      {
        staleTime: Infinity,
        queryKey: ["revenueTrend", queries],
        queryFn: () => getRevenueTrend(queries).then((res) => res?.data?.data),
      },
      {
        staleTime: Infinity,
        queryKey: ["topProductsList", queries],
        queryFn: () =>
          getTopProductsList(queries).then((res) => res?.data?.data),
      },
      {
        staleTime: Infinity,
        queryKey: ["inventoryAnalyticsData", queries],
        queryFn: () => getAnalyticsData(queries).then((res) => res?.data?.data),
      },
    ]);

  const setDateFilter = (data) => {
    const { startDate, endDate } = data;
    searchParams.set("start_date", startDate);
    searchParams.set("end_date", endDate);
    setSearchParams(searchParams, { replace: true });
  };

  const filterByStoreId = (storeId) => {
    if (storeId === "ALL_STORES") searchParams.delete("store_id");
    else searchParams.set("store_id", storeId);
    setSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    if (!inventoryAnalyticsData?.isLoading) {
      setStartDate(inventoryAnalyticsData?.data?.[0]?.start_date);
      setEndDate(inventoryAnalyticsData?.data?.[0]?.end_date);
    }
  }, [inventoryAnalyticsData?.data]);

  return {
    startDate,
    endDate,
    analytics: {
      profit_trend: { ...profitTrend?.data },
      revenue_trend: { ...revenueTrend?.data },
      top_products: topProductsList?.data || [],
      ...inventoryAnalyticsData?.data?.[0],
    },
    isFetchingData:
      profitTrend?.isLoading ||
      revenueTrend?.isLoading ||
      topProductsList?.isLoading ||
      inventoryAnalyticsData?.isLoading,
    storeList,
    storeHasMultipleCurrency,
    currentStore,
    setCurrentStore,
    setDateFilter,
    filterByStoreId,
  };
};
