import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useGetBeneficiaries } from "../../../../../hooks/api/queries/useTransfers";

const useSavedBeneficiaries = () => {
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();

  const { isLoading: isFetchingBeneficiaries, data } = useGetBeneficiaries({
    queries,
  });

  const searchBeneficiary = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    search,
    isFetchingBeneficiaries,
    beneficiaries: data?.data || [],
    searchBeneficiary,
  };
};
export default useSavedBeneficiaries;
