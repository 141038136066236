import { useSelector } from "react-redux";
import { formatCurrency } from "../../../helpers";
import styles from "./ChartCustomToolTip.module.scss";

const ChartCustomToolTip = ({ active, payload, sumOfTags }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  if (active) {
    return (
      <div className={styles.chartTooltip}>
        <p className="dark--text" style={{ fontSize: "13px" }}>
          {payload[0].name}
        </p>
        <p className="purple--text" style={{ fontSize: "12px" }}>
          {formatCurrency({
            value: payload[0].value,
            currencyCode: currency_code,
          })}
        </p>
        <p style={{ fontSize: "12px", color: "#FD561F" }}>
          {((payload[0].value / sumOfTags) * 100).toFixed(2)}%
        </p>
      </div>
    );
  }
  return null;
};
export default ChartCustomToolTip;
