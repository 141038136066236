import "./BankVerificationOtpWidget.scss";
import primaryComponents from "../../primaryComponents";
import BankVerificationOtpWidgetLogic from "./BankVerificationOtpWidgetLogic";
import { motion } from "framer-motion";

const BankVerificationOtpWidget = ({
  setShowBankVerificationOtpWidget,
  fetchDocuments,
}) => {
  const {
    otp,
    setOtp,
    handleVerifyOtp,
    isVerifyingOtp,
    isResendingOtp,
    handleResendOtp,
  } = BankVerificationOtpWidgetLogic({
    setShowBankVerificationOtpWidget,
    fetchDocuments,
  });
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.8 } }}
      exit={{ opacity: 0, transition: { duration: 0.8 } }}
      className="bank-verification-otp-widget"
    >
      <h4 className="font-weight-semibold">Verify you own this BVN</h4>
      <p>Please enter the OTP sent to the phone number attached to this BVN</p>
      <div className="bank-verification-otp-widget__field">
        <primaryComponents.OtpInputField
          onChange={setOtp}
          numOfInputs={6}
          value={otp}
        />
      </div>
      <div className="bank-verification-otp-widget__actions">
        <p
          onClick={() => handleResendOtp()}
          className="purple--text font-weight-semibold resend-otp__btn"
        >
          {isResendingOtp ? "Sending Otp.." : "Resend Otp"}
        </p>
        <primaryComponents.Button
          onClick={handleVerifyOtp}
          classNames={"btn btn--primary"}
          type="button"
          isLoading={isVerifyingOtp}
          isDisabled={isVerifyingOtp}
          loadingText="Verifying Otp..."
        >
          Submit
        </primaryComponents.Button>
      </div>
    </motion.div>
  );
};

export default BankVerificationOtpWidget;
