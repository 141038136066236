import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useActiveSubscriptionQuery } from "../../../../../hooks/api/queries";
import { getSubscriptionHistory } from "../../../../../services/settings";

const useSubscritionption = () => {
  const queries = getUrlQuerysection();
  const [search, setSearch] = useSearchParams();
  const { isLoading: isFetchingActiveSUbscription, data: activeSubscription } =
    useActiveSubscriptionQuery();

  const {
    isLoading: isFetchingSubscriptions,
    data: subscriptions,
    refetch: handleFetchSubscriptions,
  } = useQuery(
    ["getSubscriptionHistory", queries],
    () => getSubscriptionHistory(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    isFetchingActiveSUbscription,
    activeSubscription: activeSubscription || {},
    subscriptions: subscriptions?.data || [],
    isFetchingSubscriptions,
    metaDetails: subscriptions?.meta || {},
    gotoPage,
    handleFetchSubscriptions,
  };
};
export default useSubscritionption;
