import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import "./ImageUploadModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useImageUpload from "./useImageUpload";

const ImageUploadModal = ({ setImage, closeModal }) => {
  const {
    uploadProgress,
    hiddenFileInput,
    isLoading,
    imageList,
    isFetchingNextPage,
    autoScrollRef,
    setImageSelected,
  } = useImageUpload({ setImage, closeModal });

  return (
    <div className="image-upload">
      {/* progress loader */}
      <div className={`upload-progress ${uploadProgress !== 0 && "active"}`}>
        <div
          className="upload-progress__loader"
          style={{ width: `${uploadProgress}%` }}
        ></div>
      </div>

      {/* upload section */}
      <div className="image-upload__container">
        <div className="mt-3 d-flex align-items-center justify-content-between">
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/x-png,image/jpeg"
            multiple="multiple"
            ref={hiddenFileInput}
            onChange={setImageSelected}
          />
          <div>
            <h4>Upload Image</h4>
            <p className="dark--text">
              Select image from your gallery{" "}
              <span className="small-text-size gray--text">
                (Accepted format: png, jpeg)
              </span>
            </p>
          </div>
          <primaryComponents.Button
            classNames="btn btn--primary smallBtn"
            onClick={() => {
              hiddenFileInput.current.click();
            }}
          >
            Click to upload
            <FontAwesomeIcon
              className="ml-2"
              icon="upload"
              style={{ fontSize: "15px" }}
            />
          </primaryComponents.Button>
        </div>

        <secondaryComponents.LoaderHelper
          classNames={"mt-5"}
          isLoading={isLoading}
        >
          <div className="image-upload__container__items" ref={autoScrollRef}>
            {imageList?.map((imageItem) => (
              <div
                key={imageItem.id}
                className="image-item"
                onClick={() => {
                  setImage(imageItem?.url);
                  closeModal();
                }}
              >
                <img src={imageItem.url} alt="library" />
              </div>
            ))}
          </div>
        </secondaryComponents.LoaderHelper>
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingNextPage && !isLoading}
          classNames={"mt-4"}
        ></secondaryComponents.LoaderHelper>
      </div>
    </div>
  );
};

export default ImageUploadModal;
