import siblingStyles from "../SellModeProductItems/ProductGridCard/ProductGridCard.module.scss";
import styles from "./MenuGridCard.module.scss";
import { formatCurrency } from "../../../helpers";
import {
  DEFAULT_PRICING_ID,
  GRID_PRODUCT_DEFAULT_ICONS,
} from "../../../helpers/constants";
import secondaryComponents from "..";

const MenuGridCard = ({ menuItem, menuObject, productIndex }) => {
  const pricingDetails = menuItem?.store_properties?.pricings?.find(
    (pricingItem) =>
      `${pricingItem?.pricing_type_id}` === `${DEFAULT_PRICING_ID}`,
  );
  return (
    <div className={`${siblingStyles.productGridCard} ${styles.menuGridCard}`}>
      <div className={siblingStyles.productImage}>
        <img
          src={
            menuItem?.product?.image_url === "" ||
            menuItem?.product?.image_url === null ||
            menuItem?.product?.image_url === undefined
              ? GRID_PRODUCT_DEFAULT_ICONS[productIndex % 5]
              : menuItem?.product?.image_url
          }
          alt=""
        />
      </div>

      <div className={siblingStyles.rowItem}>
        <p className="d-flex align-items-center">
          <span
            className="error mr-1"
            style={{
              width: "4px",
              height: "4px",
              borderRadius: "4px",
            }}
          ></span>
          <span className="font-weight-semibold purple--text">
            {formatCurrency({
              value:
                pricingDetails?.price -
                (pricingDetails?.price * pricingDetails?.discount_rate) / 100,
              currencyCode: menuObject?.store?.currency_code,
              decimal: 0,
            })}
          </span>
        </p>

        <span className={"error--text"} style={{ fontSize: "10px" }}>
          {pricingDetails?.discount_rate > 0 ? (
            <>
              <span style={{ textDecoration: "line-through" }}>
                {formatCurrency({
                  value: pricingDetails?.price,
                  currencyCode: menuObject?.store?.currency_code,
                  decimal: 0,
                })}
              </span>{" "}
              {pricingDetails?.discount_rate}% OFF
            </>
          ) : (
            <span style={{ visibility: "hidden" }}>null</span>
          )}
        </span>
      </div>

      <secondaryComponents.ConcatenateWithTooltip
        value={menuItem?.product_name || ""}
        noOfCharacters={80}
        style={{ fontSize: "12px", fontWeight: "700" }}
      />
    </div>
  );
};

export default MenuGridCard;
