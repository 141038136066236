import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const LoyaltyType = {
  GIFT_CARD: "GIFT_CARD",
  LOYALTY_POINT: "LOYALTY_POINT",
};

const loyaltySchema = yup
  .object({
    loyaltyType: yup.mixed().oneOf(Object.values(LoyaltyType)),
    redemption_code: yup.string().when("loyaltyType", {
      is: LoyaltyType.GIFT_CARD,
      then: yup.string().required("Gift card code is required"),
      otherwise: yup.string().notRequired(),
    }),
    customer_id: yup.string().when("loyaltyType", {
      is: LoyaltyType.LOYALTY_POINT,
      then: yup.string().required("Select existing customer"),
      otherwise: yup.string().notRequired(),
    }),
    giftcard_amount: yup.string().when("loyaltyType", {
      is: LoyaltyType.GIFT_CARD,
      then: yup.string().required("Amount is required"),
      otherwise: yup.string().notRequired(),
    }),
    loyalty_point_amount: yup.string().when("loyaltyType", {
      is: LoyaltyType.LOYALTY_POINT,
      then: yup.string().required("Amount is required"),
      otherwise: yup.string().notRequired(),
    }),
  })
  .required();

export const useValidation = ({ loyaltyDetails }) => {
  return useForm({
    resolver: yupResolver(loyaltySchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      ...loyaltyDetails,
    },
  });
};
