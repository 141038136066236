export const EditIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" fill="white" />
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke="#7647EE" />
      <rect width="16" height="16" transform="translate(2 2)" fill="white" />
      <path
        d="M4 16H16"
        stroke="#7647EE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00016 11L11.6668 4.33333C12.2191 3.78104 13.1146 3.78104 13.6668 4.33333C14.2191 4.88561 14.2191 5.78104 13.6668 6.33333L7.00016 13L4.3335 13.6667L5.00016 11Z"
        stroke="#7647EE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
