import styles from "./StockHistoryTable.module.scss";
import secondaryComponents from "..";
import { useStockHistoryTable } from "./useStockHistoryTable";

const StockHistoryTable = ({
  metaDetails,
  stockHistory,
  handleFetchStockHistory,
  gotoPage,
}) => {
  const {
    instance,
    isTnxRecipt,
    isSendRecipt,
    isTerminal,
    openSalesTransactionWidget,
    selectedOrder,
    orderDetails,
    setOrderDetails,
    gotoTransactionDetails,
    closeWidget,
    openSendReceipt,
    gotoReceiptTerminals,
  } = useStockHistoryTable({
    stockHistory,
    handleFetchStockHistory,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.stockHistoryTable}>
        <div className={styles.stockHistoryTable__content}>
          <secondaryComponents.TableHelper
            getTableBodyProps={getTableBodyProps}
            getTableProps={getTableProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
          >
            <h4 className="pt-5 text-center">No Data Available</h4>
          </secondaryComponents.TableHelper>
          {/* pagination */}
          {stockHistory.length > 0 && (
            <div className="mt-auto">
              <secondaryComponents.Pagination
                totalPageNumber={metaDetails?.last_page}
                handlePageClick={(value) => gotoPage(value?.selected + 1)}
                currentPage={metaDetails?.current_page}
              />
            </div>
          )}
        </div>
      </div>

      <secondaryComponents.SideModal
        isActive={openSalesTransactionWidget}
        closeModal={closeWidget}
        width={1000}
      >
        {isTnxRecipt ? (
          <secondaryComponents.SalesTransactionWidget
            closeWidget={closeWidget}
            selectedOrder={selectedOrder}
            openSendReceipt={openSendReceipt}
            gotoReceiptTerminals={gotoReceiptTerminals}
            setOrderDetailsAcross={setOrderDetails}
          />
        ) : null}

        {isTerminal ? (
          <secondaryComponents.ReceiptTerminals
            orderDetails={orderDetails}
            closeStoreCheckout={closeWidget}
            gotoTransactionDetails={gotoTransactionDetails}
          />
        ) : null}

        {isSendRecipt ? (
          <secondaryComponents.SendReceiptWidget
            closeWidget={closeWidget}
            transactionId={orderDetails?.id}
            receiptType="storeOrder"
            goBack={gotoTransactionDetails}
          />
        ) : null}
      </secondaryComponents.SideModal>
    </>
  );
};

export default StockHistoryTable;
