import React from "react";
import primaryComponents from "../../../components/primaryComponents";
import styles from "./MainDashboardSetUpCard.module.scss";

const MainDashboardSetUpCard = ({ image, subtitle, actionText, link }) => {
  return (
    <div className={styles.card}>
      <div className={styles.card__img}>
        <img src={image} alt="card-icon" className={styles.card__image} />
      </div>
      <p className="small-text-size">{subtitle}</p>
      <primaryComponents.Button
        onClick={() => window.open(link)}
        classNames="mt-auto btn btn--primary ml-auto smallBtn"
      >
        {actionText}
      </primaryComponents.Button>
    </div>
  );
};

export default MainDashboardSetUpCard;
