import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransactionDetailsV2 from "../TransactionDetailsV2";
import ReceiptTerminalsV2 from "../ReceiptTerminalsV2";
import SendReceiptV2 from "../SendReceiptV2";
import primaryComponents from "../../../primaryComponents";
import PrintOnDevice from "../PrintOnDevice";
import StoreCustomerForm from "../StoreCustomerForm";
import GenerateWaybillForm from "../GenerateWaybillForm";
import PrescriptionWidget from "../PrescriptionWidget";
import BillUpdate from "../BillUpdate";
import WaybillsDetails from "../WaybillsDetails";
import PrescriptionsDetails from "../PrescriptionsDetails";

const Orderflows = ({
  closeModal,
  orderId,
  defaultLanding = "storeCustomerForm",
  viaSellMode = false,
  viaSaleRecord = false,
  isViaBill = false,
  setModalWidth,
}) => {
  const [views, setViews] = useState({
    isTnxReceipt: defaultLanding === "tnxReceipt" || isViaBill ? true : false,
    isTerminal: false,
    isSendReceipt: false,
    isPrintOnDevice: false,
    isStoreCustomerForm: defaultLanding === "storeCustomerForm" ? true : false,
    isGeneratePescription: false,
    isGenerateWaybill: false,
    isPrescriptionDetails: false,
    isWaybillDetails: false,
    isOrderUpdate: false,
  });
  const [sectionId, setSectionId] = useState(null);
  const [viaPos, setViaPos] = useState(false);
  const [billWasUpdated, setBillWasUpdated] = useState(false);

  const openView = (key) => {
    setViews((prev) => ({ ...prev, [key]: true }));
  };
  const closeView = (key) => {
    setViews((prev) => ({ ...prev, [key]: false }));
  };

  const resetView = (key) => {
    setViews({
      isTnxReceipt: false,
      isTerminal: false,
      isSendReceipt: false,
      isPrintOnDevice: false,
      isGeneratePescription: false,
      isGenerateWaybill: false,
      isStoreCustomerForm: false,
      [key]: true,
    });
  };

  useEffect(() => {
    if (setModalWidth) {
      if (views && views.isGenerateWaybill) {
        setModalWidth(600);
      } else {
        setModalWidth(1000);
      }
    }
  }, [views, setModalWidth]);

  return (
    <div className="h-100">
      <div className="d-flex mb-4">
        {!views?.isTnxReceipt && !views?.isStoreCustomerForm ? (
          <primaryComponents.Button
            classNames={"btn btn--outline smallBtn"}
            onClick={() => {
              if (views?.isTerminal) setSectionId(null);
              resetView("isTnxReceipt");
            }}
          >
            <FontAwesomeIcon icon="angle-left" style={{ fontSize: "15px" }} />
          </primaryComponents.Button>
        ) : null}
        <FontAwesomeIcon
          onClick={closeModal}
          className="d-block ml-auto"
          icon="times"
          style={{ fontSize: "25px" }}
        />
      </div>

      <div style={{ height: "calc(100% - 60px)" }}>
        {views?.isTnxReceipt ? (
          <TransactionDetailsV2
            orderId={orderId}
            resetView={resetView}
            sectionId={sectionId}
            setViaPos={setViaPos}
            viaSellMode={viaSellMode}
            billWasUpdated={billWasUpdated}
            viaSaleRecord={viaSaleRecord}
          />
        ) : views?.isTerminal ? (
          <ReceiptTerminalsV2
            orderId={orderId}
            sectionId={sectionId}
            setSectionId={setSectionId}
          />
        ) : views?.isSendReceipt ? (
          <SendReceiptV2
            orderId={orderId}
            openView={openView}
            closeView={closeView}
          />
        ) : views?.isPrintOnDevice ? (
          <PrintOnDevice
            orderId={orderId}
            setSectionIdExternal={setSectionId}
            viaPos={viaPos}
            resetView={resetView}
            billWasUpdated={billWasUpdated}
          />
        ) : views?.isStoreCustomerForm ? (
          <StoreCustomerForm orderId={orderId} resetView={resetView} />
        ) : views?.isGenerateWaybill ? (
          <GenerateWaybillForm orderId={orderId} />
        ) : views?.isGeneratePescription ? (
          <PrescriptionWidget orderId={orderId} />
        ) : views?.isOrderUpdate ? (
          <BillUpdate
            orderId={orderId}
            resetView={resetView}
            setBillWasUpdated={setBillWasUpdated}
          />
        ) : views?.isWaybillDetails ? (
          <WaybillsDetails orderId={orderId} />
        ) : views?.isPrescriptionDetails ? (
          <PrescriptionsDetails orderId={orderId} />
        ) : null}
      </div>
    </div>
  );
};
export default Orderflows;
