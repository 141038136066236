import "./CreditLogTable.scss";
import secondaryComponents from "..";
import { useCreditLogTable } from "./useCreditLogTable";
import AddDebtWidgt from "../../../pages/appPages/Inventory/CreditLogs/AddDebt";

const CreditLogTable = ({
  metaDetails,
  creditLog,
  handleFetchCredits,
  gotoPage,
}) => {
  const {
    selectedCredit,
    instance,
    addDebt,
    isUpdateCreditWidget,
    setIsUpdateCreditWidget,
    setAddDebt,
  } = useCreditLogTable({
    creditLog,
    handleFetchCredits,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="credit-log-table">
      <div className="credit-log-table__content">
        <secondaryComponents.TableHelper
          getTableBodyProps={getTableBodyProps}
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
        >
          <h4 className="pt-5 text-center">No Data Available</h4>
        </secondaryComponents.TableHelper>

        {creditLog.length > 0 && (
          <div className="mt-auto">
            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </div>
        )}
      </div>
      {/* credit side modal */}
      <secondaryComponents.SideModal
        isActive={isUpdateCreditWidget}
        closeModal={() => setIsUpdateCreditWidget(false)}
      >
        <secondaryComponents.UpdateCreditWidget
          closeWidget={() => setIsUpdateCreditWidget(false)}
          onSuccess={() => handleFetchCredits()}
          customer={selectedCredit}
        />
      </secondaryComponents.SideModal>
      <secondaryComponents.SideModal
        isActive={addDebt}
        closeModal={() => setAddDebt(false)}
      >
        <AddDebtWidgt
          closeWidget={() => setAddDebt(false)}
          onSuccess={() => handleFetchCredits()}
          customer={selectedCredit}
        />
      </secondaryComponents.SideModal>
    </div>
  );
};

export default CreditLogTable;
