import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";
import styles from "../StoreWalletDetails/styles.module.scss";
import Icon from "../../../../../assets/icons";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStoreWalletTransactionDetails from "./useStoreWalletTransactionDetails";
import { defaultDateDisplay, formatCurrency } from "../../../../../helpers";
import { StoreWalletTransactionView } from "../../../../../components/printViews";

const StoreWalletTransactionDetails = () => {
  const navigate = useNavigate();
  const {
    storeWalletTnxDetails,
    isLoadingStoreWalletTnxDetails,
    tnxId,
    setTnxId,
  } = useStoreWalletTransactionDetails();

  const orderDetails = storeWalletTnxDetails?.order;

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Store Wallet Transaction Details"
      description="View the details of a store wallet transaction"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={` ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper
            isLoading={isLoadingStoreWalletTnxDetails}
            classNames="my-5"
          >
            <div className={globalStyles.pageHeaders}>
              <div>
                <p>Transaction Reference</p>
                <h4 className="font-weight-medium">
                  {storeWalletTnxDetails?.id}
                </h4>
              </div>
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                onClick={() => setTnxId(storeWalletTnxDetails?.id)}
              >
                <img className="mr-2" src={Icon.receipt} alt="" />
                <span>Print Receipt</span>
              </primaryComponents.Button>
            </div>
            <div className={styles.walletSection}>
              <div>
                <h6>Date/Time</h6>
                <span className="gray--text">
                  {defaultDateDisplay(storeWalletTnxDetails?.created_at)}
                </span>
              </div>
              <div>
                <h6>Type</h6>
                <span
                  className={`${storeWalletTnxDetails?.type === "debit" ? "error--text" : "success--text"}`}
                >
                  {storeWalletTnxDetails?.type}
                </span>
              </div>
              <div>
                <h6>Amount</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: storeWalletTnxDetails?.amount,
                    currencyCode: storeWalletTnxDetails?.store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Store</h6>
                <span className="gray--text">
                  {storeWalletTnxDetails?.store?.name}
                </span>
              </div>
              <div>
                <h6>Current Balance</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: storeWalletTnxDetails?.balance,
                    currencyCode: storeWalletTnxDetails?.store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Initiated By</h6>
                <span className="gray--text">
                  {storeWalletTnxDetails?.initiated_by?.first_name}
                </span>
              </div>
              <div>
                <h6>Source</h6>
                <span className="gray--text">
                  {storeWalletTnxDetails?.source?.replace("_", " ")}
                </span>
              </div>
              <div>
                <h6>Order Number</h6>
                <span className="gray--text">
                  {storeWalletTnxDetails?.order?.id}
                </span>
              </div>
              <div>
                <h6>Description</h6>
                <span className="gray--text">
                  {storeWalletTnxDetails?.description || "N/A"}
                </span>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          {/* <div className="mt-4">
            <h4 className="font-weight-semibold">Order Items</h4>
          </div> */}

          {orderDetails && (
            <div>
              <h5 className="mb-3">
                {orderDetails && storeWalletTnxDetails?.type === "credit"
                  ? "Returned Items"
                  : "Order Items"}
              </h5>
              <div className={styles.orderContainer}>
                <div className={styles.horizontalScrollContainer}>
                  <div className={styles.horizontalScroll}>
                    <div className={styles.orderDetails}>
                      <p>ITEM</p>
                      <p>QUANTITY</p>
                      <p>METRIC UNIT</p>
                      <p>UNIT AMOUNT</p>
                      <p className="ml-auto">AMOUNT</p>
                    </div>
                    {orderDetails?.order_items?.map((item) => (
                      <div className={styles.orderDetails} key={item?.id}>
                        <p>{item?.cart_item_name}</p>
                        <p>{item?.quantity}</p>
                        <p>
                          {item?.product?.unit?.unit ||
                            item?.variant?.unit?.unit ||
                            "N/A"}
                        </p>
                        <p>
                          {formatCurrency({
                            value: Number(item?.unit_price),
                            currencyCode: orderDetails?.store?.currency_code,
                          })}
                        </p>
                        <p className="ml-auto">
                          {formatCurrency({
                            value: item?.total_price,
                            currencyCode: orderDetails?.store?.currency_code,
                          })}
                        </p>
                      </div>
                    ))}
                    <div className="mt-2 d-flex justify-content-between">
                      <h6>Subtotal:</h6>
                      <h6>
                        {" "}
                        {formatCurrency({
                          value: orderDetails?.total_item_amount,
                          currencyCode: orderDetails?.store?.currency_code,
                        })}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className={styles.priceSumaryContainer}>
                  <h4 className="gray--text">Price Summary</h4>
                  <div className="d-flex justify-content-between">
                    <p>Subtotal:</p>
                    <h6>
                      {" "}
                      {formatCurrency({
                        value: orderDetails?.total_item_amount,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Gift Card:</p>
                    <h6>
                      {formatCurrency({
                        value: orderDetails?.giftcard_debit_amount || 0,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Loyalty Point:</p>
                    <h6>
                      {formatCurrency({
                        value: orderDetails?.loyalty_debit_amount || 0,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Delivery Fee:</p>
                    <h6>
                      {formatCurrency({
                        value: orderDetails?.delivery_fee_amount || 0,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <p>Discount:</p>
                    <h6>
                      {formatCurrency({
                        value: orderDetails?.discount_amount || 0,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <p>VAT:</p>
                    <h6>
                      {formatCurrency({
                        value: orderDetails?.vat || 0,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                  <div className="mt-1 d-flex justify-content-between">
                    <p>Total:</p>
                    <h6 className="purple--text">
                      {formatCurrency({
                        value: orderDetails?.total_amount,
                        currencyCode: orderDetails?.store?.currency_code,
                      })}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )}

          {tnxId ? (
            <StoreWalletTransactionView
              tnx_id={tnxId}
              allowPrint={tnxId}
              setAllowPrint={setTnxId}
            />
          ) : null}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default StoreWalletTransactionDetails;
