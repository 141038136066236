import { useState } from "react";

const useInventoryLayout = () => {
  const [openAddProductWidget, setOpenAddProductWidget] = useState(false);

  return {
    openAddProductWidget,
    setOpenAddProductWidget,
  };
};

export default useInventoryLayout;
