import { useTable } from "react-table";
import { formatDateYYYYMMDD, formatCurrency } from "../../../helpers";

import secondaryComponents from "..";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useInvoiceHistoryTable = ({ historyData }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        accessor: "product_name",
        Cell: ({ cell: { value } }) => (
          <secondaryComponents.ConcatenateWithTooltip
            value={value}
            noOfCharacters={200}
          />
        ),
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        Cell: ({ cell: { value } }) => (value !== null ? value : "N/A"),
      },
      {
        Header: "Stores",
        accessor: "store",
        Cell: ({ cell: { value } }) => (value !== null ? value?.name : "N/A"),
      },
      {
        Header: "Expiry Date",
        accessor: "expiry_date",
        Cell: ({ cell: { value } }) =>
          value !== null ? formatDateYYYYMMDD(value) : "N/A",
      },
      {
        Header: "Cost Price",
        accessor: "cost_price",
        Cell: ({ cell: { value } }) =>
          value !== null
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : "N/A",
      },
      {
        Header: "Selling Price",
        accessor: "selling_price",
        Cell: ({ cell: { value } }) =>
          value !== null
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : "N/A",
      },
      {
        Header: "Profit Per Unit",
        accessor: "profit",
        Cell: ({ cell: { value } }) =>
          value !== null
            ? formatCurrency({ value: value, currencyCode: currency_code })
            : "N/A",
      },
    ],
    [],
  );

  const instance = useTable({
    columns,
    data: historyData || [],
    autoResetPage: false,
  });

  return { instance };
};
