import "./AddTransactionsTag.scss";
import primaryComponents from "../../primaryComponents";
import AddTransactionsTagLogic from "./AddTransactionsTagLogic";
import { Fragment } from "react";
import { Controller } from "react-hook-form";
import AddTransactionsTagValidation from "./AddTransactionsTagValidation";

const AddTransactionsTag = ({ tagDetails, isEditMode = false, closeModal }) => {
  const { tag_name, setTagName, handleAddTagName, isAddingTag } =
    AddTransactionsTagLogic({
      tagDetails,
      closeModal,
      isEditMode,
    });
  const {
    register,
    control,
    formState: { errors },
    trigger,
    handleSubmit,
  } = AddTransactionsTagValidation(tag_name);

  return (
    <div className="transaction-tag">
      <Fragment>
        <h3 style={{ fontSize: "20px" }} className="font-weight-semibold">
          {isEditMode ? "Edit tag" : "Add tag"}
        </h3>
        <form
          onSubmit={handleSubmit(handleAddTagName)}
          className="transaction-tag__form"
        >
          <div className="transaction-tag__form__field">
            <Controller
              name="tag_name"
              control={control}
              defaultValue={tag_name}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Tag Name"
                  placeholder="Enter your chosen tag name"
                  isRequired={true}
                  type="text"
                  name="tag_name"
                  errorMessage={errors.tag_name}
                  onKeyUp={() =>
                    errors.tag_name !== undefined && trigger("tag_name")
                  }
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setTagName(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>
          <div className="transaction-tag__form__actions">
            <primaryComponents.Button
              type="button"
              classNames={"btn btn--outline"}
              onClick={() => closeModal()}
            >
              Cancel
            </primaryComponents.Button>
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              isLoading={isAddingTag}
              loadingText="Saving..."
            >
              Save and return
            </primaryComponents.Button>
          </div>
        </form>
      </Fragment>
    </div>
  );
};

export default AddTransactionsTag;
