import { useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const TransactionsFilterLogic = () => {
  const [search, setSearch] = useSearchParams();
  const [showDropdown, setShowDropdown] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(
    search.get("status")?.split("|") || [],
  );
  const [paymentMethod, setPaymentMethod] = useState(
    search.get("payment_method")?.split("|") || [],
  );
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target))
        setShowDropdown(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [inputRef]);

  const setFilter = () => {
    if (paymentMethod?.length === 0) search.delete("payment_method");
    else search.set("payment_method", paymentMethod?.join("|"));

    if (paymentStatus?.length === 0) search.delete("status");
    else search.set("status", paymentStatus?.join("|"));
    setSearch(search, { replace: true });
  };

  const addValueToPaymentStatus = (value) => {
    let newPaymentStatus = [...paymentStatus];
    newPaymentStatus.push(value);
    setPaymentStatus(newPaymentStatus);
  };

  const removeValueFromPaymentStatus = (value) => {
    let newPaymentStatus = [...paymentStatus];
    setPaymentStatus(newPaymentStatus.filter((item) => item !== value));
  };

  const addValueToPaymentMethod = (value) => {
    let newPaymentMethod = [...paymentMethod];
    newPaymentMethod.push(value);
    setPaymentMethod(newPaymentMethod);
  };

  const removeValueFromPaymentMethod = (value) => {
    let newPaymentMethod = [...paymentMethod];
    setPaymentMethod(newPaymentMethod.filter((item) => item !== value));
  };

  return {
    showDropdown,
    paymentStatus,
    paymentMethod,
    inputRef,
    setShowDropdown,
    setFilter,
    removeValueFromPaymentStatus,
    addValueToPaymentStatus,
    addValueToPaymentMethod,
    removeValueFromPaymentMethod,
  };
};
export default TransactionsFilterLogic;
