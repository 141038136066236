/* eslint-disable eqeqeq */
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../../helpers";
import { useGetStockTransfersList } from "../../../../../hooks/api/queries/useInventory";

const useStockTransfer = () => {
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const { isLoading: isFetchingStocksTransfer, data: stocksTransfer } =
    useGetStockTransfersList({ queries });

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchStockTransfer = (value) => {
    if (value?.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  return {
    isFetchingStocksTransfer,
    stocksTransfer: stocksTransfer?.data || [],
    metaDetails: stocksTransfer?.meta || {},
    search,
    searchStockTransfer,
    gotoPage,
  };
};
export default useStockTransfer;
