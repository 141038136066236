import "./GiftCardSuccessModal.scss";
import Images from "../../../assets/images";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";

const GiftCardSuccessModal = ({ isActive, setIsActive }) => {
  return (
    <secondaryComponents.Modal
      isActive={isActive}
      closeModal={() => setIsActive(false)}
      width="550px"
    >
      <div className="gift-card-success-modal-wrapper">
        <img className="mr-2 ml-2" src={Images.success_image} alt="success" />
        <h3>Gift card purchase successful</h3>
        <primaryComponents.Button className="btn btn--primary add-customer-widget__content__button">
          Print receipt
        </primaryComponents.Button>
      </div>
    </secondaryComponents.Modal>
  );
};
export default GiftCardSuccessModal;
