/* eslint-disable react/jsx-key */
import styles from "./SubscriptionHistory.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { formatCurrency, getPlatform } from "../../../../../helpers";
import useSubscriptionHistory from "./useSubscritionption";
import { motion } from "framer-motion";
import globaStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { useContext } from "react";
import { platformHandler } from "../../../../../hooks/context";
import { useNavigate } from "react-router-dom";
import { PERIOD } from "../../../../../helpers/constants";
import { useSelector } from "react-redux";

const SubscriptionHistory = () => {
  const {
    activeSubscription,
    subscriptions,
    isFetchingSubscriptions,
    metaDetails,
    gotoPage,
    handleFetchSubscriptions,
  } = useSubscriptionHistory();
  const { platform } = useContext(platformHandler);
  const navigate = useNavigate();
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Subscription"
      description="View subscription history"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={`${styles.subscriptionHistory} ${globaStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Subscriptions</h4>
            <p>
              Renew or upgrade your subscription plan and view your subscription
              history
            </p>
          </div>
          {/* header */}
          <div className={styles.header}>
            <div className={`${styles.header__left} `}>
              <div>
                <p className="small-text-size mb-1">Plan</p>
                <p
                  className={`${globaStyles.plan} ${
                    activeSubscription?.pricing?.tier?.name === "Essential"
                      ? globaStyles.essentialPlan
                      : activeSubscription?.pricing?.tier?.name === "Growth"
                        ? globaStyles.growthPlan
                        : activeSubscription?.pricing?.tier?.name === "Scale"
                          ? globaStyles.scalePlan
                          : ""
                  }`}
                >
                  {activeSubscription?.pricing?.tier?.name || "N/A"}
                </p>
              </div>
              <div>
                <p className="small-text-size mb-1">Price</p>
                <h5>
                  {" "}
                  <span
                    className="error--text"
                    style={{ fontSize: "30px", lineHeight: 0 }}
                  >
                    .
                  </span>
                  <span className="dark--text font-weight-bold">
                    {formatCurrency({
                      value: activeSubscription?.pricing?.price,
                      currencyCode:
                        activeSubscription?.currency || currency_code,
                    })}
                  </span>
                  <span className="small-text-size font-weight-normal">
                    /{PERIOD[activeSubscription?.pricing?.validity]}
                  </span>
                </h5>
              </div>
              <div>
                <p className="small-text-size mb-1">Next Billing Date</p>
                <p className="dark--text">
                  {activeSubscription?.next_subscription_date
                    ? new Date(
                        activeSubscription?.next_subscription_date,
                      ).toLocaleString("en-us")
                    : "N/A"}
                </p>
              </div>
            </div>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Settings"}
              permissionChildKey="can_update_subscriptions"
            >
              <primaryComponents.Button
                classNames={`btn btn--primary ${styles.upgradeBtn} ml-auto`}
                onClick={() =>
                  navigate(
                    `${getPlatform(platform)}/settings/subscriptions/plans`,
                  )
                }
              >
                Upgrade
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          {/* table section */}
          <div className={styles.tableSection}>
            <secondaryComponents.LoaderHelper
              classNames="mt-5"
              isLoading={isFetchingSubscriptions}
            >
              <secondaryComponents.SubscriptionTable
                subscriptions={subscriptions}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                handleFetchSubscriptions={handleFetchSubscriptions}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default SubscriptionHistory;
