import "./AccountSettings.scss";

import secondaryComponents from "../../../../components/secondaryComponents";

const AccountSettings = () => {
  return (
    <div className="account-settings-page">
      <secondaryComponents.ComingSoon />
      {/* <div className="account-settings-page__nav">
                <h4>Preferences</h4>
                <primaryComponents.Button
                    classNames={"btn btn--disabled btn__save-preferences"}
                >
                    Save
                </primaryComponents.Button>
            </div>
            <div className="account-settings-page__content">
                <div className="account-settings-page__content__section">
                    <div className="payment__methods">
                        <h5>Which payment method should be on your widget?</h5>
                        <div className="payment__options">
                            <div className="payment__option mr-4">
                                <primaryComponents.Checkbox checked={true} />
                                <p className="ml-2">Pay with Lumi</p>
                            </div>
                            <div className="payment__option">
                                <primaryComponents.Checkbox />
                                <p className="ml-2">Bank Transfer</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="account-settings-page__content__section">
                    <div className="payment__methods">
                        <h5>Which payment method should be on your widget?</h5>
                        <div className="payment__options">
                            <div className="payment__option mr-4">
                                <primaryComponents.Checkbox />
                                <p className="ml-2">Charge me</p>
                            </div>
                            <div className="payment__option">
                                <primaryComponents.Checkbox checked={true} />
                                <p className="ml-2">Charge my customers</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="account-settings-page__content__section">
                    <div className="payment__methods">
                        <h5>Transaction Emails</h5>
                        <div className="payment__options">
                            <primaryComponents.Checkbox checked={true} />
                            <p className="ml-2">Email me for every transaction</p>
                        </div>
                    </div>
                </div>
                <div className="account-settings-page__content__section">
                    <div className="payment__methods">
                        <h5>Transaction Emails (Customers)</h5>
                        <div className="payment__options">
                            <primaryComponents.Checkbox checked={true} />
                            <p className="ml-2">Email customers for every transaction</p>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default AccountSettings;
