import { useMutation } from "react-query";
import { updateOrderTable } from "../../../services/inventory";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { platformHandler, ToastContext } from "../../../hooks/context";
import { getPlatform } from "../../../helpers";

export const useEditOrderTableForm = ({ tableDetails, storeList }) => {
  const [formData, setFormData] = useState(tableDetails);
  const [isEditStore, setIsEditStore] = useState(false);
  const { platform } = useContext(platformHandler);
  const navigate = useNavigate();
  const triggerToast = useContext(ToastContext);

  const serializeStore = (store) => {
    const _store = storeList.find(({ id }) => id === store);
    return _store?.id;
  };

  const { mutate: updateTable, isLoading: isUpdatingOrderTable } = useMutation(
    (data) => updateOrderTable(data),
  );

  const submitTableDetails = () => {
    updateTable(
      {
        name: formData?.name,
        table_id: formData?.id,
        store: isEditStore ? formData.store : serializeStore(formData?.store),
        seat_capacity: formData?.seat_capacity,
      },
      {
        onSuccess: () => {
          navigate(getPlatform(platform) + "/inventory/tables");
          triggerToast("Table successfully updated", "success");
        },
      },
    );
  };

  return {
    formData,
    isUpdatingOrderTable,
    isEditStore,
    setFormData,
    submitTableDetails,
    setIsEditStore,
  };
};
