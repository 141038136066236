import React from "react";

export const ScanCameraIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66634 14.3333H13.333C14.0694 14.3333 14.6663 13.7363 14.6663 12.9999V6.06659C14.6663 5.33021 14.0694 4.73325 13.333 4.73325H11.333L9.66634 2.33325H6.33301L4.66634 4.73325H2.66634C1.92996 4.73325 1.33301 5.33021 1.33301 6.06659V12.9999C1.33301 13.7363 1.92996 14.3333 2.66634 14.3333Z"
        stroke="#0B0C0E"
        strokeWidth="0.533333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 11.6666C9.47243 11.6666 10.6663 10.4727 10.6663 8.99992C10.6663 7.52716 9.47243 6.33325 7.99967 6.33325C6.52692 6.33325 5.33301 7.52716 5.33301 8.99992C5.33301 10.4727 6.52692 11.6666 7.99967 11.6666Z"
        stroke="#0B0C0E"
        strokeWidth="0.533333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
