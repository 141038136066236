import secondaryComponents from "..";
import AllStockTransferTableLogic from "./AllStockTransferTableLogic";
import { TransferHistoryEmptyStateIcon } from "../../../assets/svgIcons/TransferHistoryEmptyStateIcon";

const AllStockTransferTable = ({ stockHistory, metaDetails, gotoPage }) => {
  const { instance } = AllStockTransferTableLogic({ stockHistory });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <TransferHistoryEmptyStateIcon />
          <h5>You have no transfers</h5>
          <p>
            Use the the button above to transfer products between stores When
            you do, they would appear here..
          </p>
        </div>
      </secondaryComponents.TableHelper>
      <secondaryComponents.Pagination
        totalPageNumber={metaDetails?.last_page}
        handlePageClick={(value) => gotoPage(value?.selected + 1)}
        currentPage={metaDetails?.current_page}
      />
    </div>
  );
};

export default AllStockTransferTable;
