import { useMutation, useQueryClient } from "react-query";
import {
  createCampaign,
  deleteCampaign,
  updateCampaign,
} from "../../../services/campaign";
import { RequestLoader, ToastContext } from "../../context";
import { useContext } from "react";

export const useCreateCampaign = () => {
  return useMutation((data) => createCampaign(data));
};

export const useEditCampaign = () => {
  return useMutation((data) => updateCampaign(data));
};

export const useDeleteCampaign = () => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { setRequestLoaderProgress } = useContext(RequestLoader);

  return useMutation({
    mutationFn: (data) => deleteCampaign(data),
    onSuccess: (data) => {
      setRequestLoaderProgress(100);
      queryClient.invalidateQueries(["getCampaigns"]);
      triggerToast(data.data.detail, "success");
    },
  });
};
