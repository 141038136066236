import { useQuery } from "react-query";
import { getRiders } from "../../../services/riders";

export const useGetRiders = () => {
  return useQuery(
    ["dispatchRiders"],
    () => getRiders().then((res) => res?.data),
    { staleTime: Infinity },
  );
};
