/* eslint-disable no-restricted-globals */
import { useState, useRef, useEffect } from "react";
import { last7Days } from "../../../helpers";

const DateRangeInputLogic = ({ startDate, endDate }) => {
  const getDateFormat = () => {
    return [
      {
        startDate:
          startDate !== null
            ? new Date(startDate?.replaceAll("-", "/"))
            : new Date(last7Days()?.[1]?.replaceAll("-", "/")),
        endDate:
          endDate !== null
            ? new Date(endDate?.replaceAll("-", "/"))
            : new Date(last7Days()?.[0]?.replaceAll("-", "/")),
        key: "selection",
      },
    ];
  };
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  const [dateRange, setDateRange] = useState(getDateFormat());
  const isMobile = screen?.width < 600 ? true : false;

  useEffect(() => {
    setDateRange(getDateFormat());
  }, [startDate, endDate]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) &&
        !isMobile
      )
        setIsActive(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [inputRef]);

  return {
    isActive,
    isMobile,
    inputRef,
    dateRange,
    setIsActive,
    setDateRange,
  };
};
export default DateRangeInputLogic;
