import styles from "./RiderOrders.module.scss";
import primaryComponents from "../../../components/primaryComponents";
import { useGetStorefrontOrderDetails } from "../../../hooks/api/queries/useOrder";
import secondaryComponents from "../../../components/secondaryComponents";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { useQueryClient } from "react-query";
import { CircleIcon, LocationIcon, PhoneIcon } from "../../../assets/svgIcons";
import { useContext, useState } from "react";
import { useUpdateOrderStatusConfirmation } from "../../../hooks/api/mutations/useOrder";
import { pathConstants } from "../../../routes/pathContants";
import { ToastContext } from "../../../hooks/context";

const RiderOrderDetails = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const parmas = useParams();
  const triggerToast = useContext(ToastContext);
  const [isOrderConfirmation, setIsOrderConfirmation] = useState({
    status: null,
    title: "",
  });
  const { isLoading: isFetchingOrderDetails, data: orderDetails } =
    useGetStorefrontOrderDetails({ id: parmas.id });
  const { isLoading, mutate } = useUpdateOrderStatusConfirmation();
  const update_order_status = () => {
    mutate(
      { data: { status: isOrderConfirmation.status }, orderId: parmas?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["storefrontOrderDetails"]);
          queryClient.invalidateQueries(["riderOrders"]);
          navigate(
            pathConstants.INVENTORY_STOREFRONT_RIDER_ORDERS({
              id: orderDetails?.dispatch_rider?.id,
            }),
          );
          triggerToast("Order status updated successfully", "success");
        },
      },
    );
  };

  return (
    <div className={`${styles.riderOrders} ${styles.details}`}>
      <div
        className={"d-flex align-items-center"}
        style={{ padding: "10px 0" }}
      >
        <FontAwesomeIcon
          icon={["fas", "angle-left"]}
          style={{ fontSize: "25px" }}
          onClick={() => navigate(-1)}
        />
        <p className="mx-auto dark--text">{orderDetails?.id}</p>
      </div>
      <secondaryComponents.LoaderHelper
        isLoading={isFetchingOrderDetails}
        classNames={"mt-4"}
      >
        <div className={`${styles.orderDetails} mt-3`}>
          <div className={globalStyles.cardWithBorder} style={{ gap: "35px" }}>
            <div>
              <p className="small-text-size d-flex align-items-center">
                <span className="mr-1">
                  <CircleIcon />
                </span>
                <span>Pick-up Address:</span>
              </p>
              <p className="small-text-size dark--text">
                {" "}
                {orderDetails?.store?.address}
                {orderDetails?.store?.country
                  ? `, ${orderDetails?.store?.country}`
                  : ""}
              </p>
            </div>
            <div>
              <p className="small-text-size d-flex align-items-center">
                <span className="mr-1">
                  <LocationIcon />
                </span>
                <span>Drop-off Address:</span>
              </p>
              <p className="small-text-size dark--text">
                {" "}
                {orderDetails?.delivery_address?.address}
                {orderDetails?.delivery_address?.city
                  ? `, ${orderDetails?.delivery_address?.city}`
                  : ""}
                {orderDetails?.delivery_address?.state
                  ? `, ${orderDetails?.delivery_address?.state}`
                  : ""}
                {orderDetails?.delivery_address?.country
                  ? `, ${orderDetails?.delivery_address?.country}`
                  : ""}
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Customer Details:</p>
              <h6 className="mb-0">{orderDetails?.customer?.full_name}</h6>
              <p>
                {orderDetails?.customer?.phone_code}
                {orderDetails?.customer?.phone}
              </p>
            </div>
            <div className="d-flex" style={{ gap: "15px" }}>
              <a
                href={`tel:${orderDetails?.customer?.phone_code}${orderDetails?.customer?.phone}`}
              >
                <PhoneIcon />
              </a>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <p>Store Details:</p>
              <h6 className="mb-0">{orderDetails?.store?.name}</h6>
              <p>{orderDetails?.store?.phone_number}</p>
            </div>
            <div className="d-flex" style={{ gap: "15px" }}>
              <a href={`tel:${orderDetails?.store?.phone_number}`}>
                <PhoneIcon />
              </a>
            </div>
          </div>
          <div>
            <p>
              Payment Point:{" "}
              <span className="dark--text">
                {orderDetails?.payment_point?.replace("_", " ")}
              </span>
            </p>
          </div>
          <div>
            <p>
              Is Paid:{" "}
              <span
                className={`${
                  !orderDetails?.is_paid ? "error--squared" : "success--squared"
                }`}
              >
                {" "}
                {orderDetails?.is_paid ? "YES" : "NO"}
              </span>
            </p>
          </div>
        </div>

        <div className="mt-auto w-100">
          <primaryComponents.Button
            classNames={"btn btn--success cover"}
            onClick={() =>
              setIsOrderConfirmation({
                status: "DELIVERED",
                title: "Mark Order as delivered",
              })
            }
          >
            Mark as Delivered
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--outline-red mt-3 cover"}
            onClick={() =>
              setIsOrderConfirmation({
                status: "RETURNED",
                title: "Mark Order as Returned",
              })
            }
          >
            Mark as Returned
          </primaryComponents.Button>
        </div>
      </secondaryComponents.LoaderHelper>
      <secondaryComponents.ConfirmDialog
        isOpen={isOrderConfirmation.status !== null}
        setIsOpen={() => setIsOrderConfirmation({ status: null, title: "" })}
        handler={update_order_status}
        isLoading={isLoading}
        title={isOrderConfirmation.title}
      >
        <p className="small-text-size">
          {isOrderConfirmation.status === "RETURNED"
            ? "Please click on continue if the customer was not around or something"
            : isOrderConfirmation.status === "DELIVERED"
              ? "Ensure that payment is collected before marking the order as delivered."
              : null}
        </p>
      </secondaryComponents.ConfirmDialog>
    </div>
  );
};
export default RiderOrderDetails;
