import { useTable } from "react-table";
import { useMemo, useState } from "react";
import { convertToTilteCase } from "../../../helpers";
import primaryComponents from "../../primaryComponents";

const BeneficiariesTableLogic = ({ beneficiaries }) => {
  const [showDeleteBeneficiaryModal, setShowDeleteBeneficiaryModal] =
    useState(false);
  const [currentBeneficiary, setCurrentBeneficiary] = useState({});

  const columns = useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "account_name",
        Cell: ({ cell: { value } }) => (
          <span className="purple--text">
            {value ? convertToTilteCase(value) : "N/A"}
          </span>
        ),
      },
      {
        Header: "Account Number",
        accessor: "account_number",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Bank Name",
        accessor: "bank_name",
        Cell: ({ cell: { value } }) => (value ? value : "N/A"),
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ cell: { row } }) => (
          <primaryComponents.Button
            classNames="btn btn--outline-red"
            style={{
              height: "30px",
              fontSize: "12px",
              padding: "0 15px",
            }}
            onClick={() => {
              setCurrentBeneficiary(beneficiaries[row?.index]);
              setShowDeleteBeneficiaryModal(true);
            }}
          >
            Detele
          </primaryComponents.Button>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: beneficiaries });
  return {
    instance,
    showDeleteBeneficiaryModal,
    currentBeneficiary,
  };
};

export default BeneficiariesTableLogic;
