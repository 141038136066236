import { useQuery } from "react-query";
import { getAllRoles } from "../../../../../../services/settings";

const useDefaultRoles = () => {
  const { isLoading, data, refetch } = useQuery(["roles"], () =>
    getAllRoles().then((res) => res.data?.data),
  );

  return {
    isLoading,
    data,
    refetch,
  };
};

export default useDefaultRoles;
