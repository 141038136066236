import { useTable } from "react-table";
import { useMemo, useState } from "react";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";

const TagsTableLogic = ({ tags }) => {
  const [isEditTag, setIsEditTag] = useState(false);
  const [isDeleteTag, setIsDeleteTag] = useState(false);
  const [tagDetails, setTagDetails] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Tag Name",
        accessor: "name",
        Cell: ({ cell: { value } }) => (
          <h5 className="font-weight-normal">{value}</h5>
        ),
      },
      {
        // Header: "Actions",
        accessor: "edit",
        Cell: ({ cell: { row } }) => (
          <span className="d-flex align-items-center justify-content-end">
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Payments"}
              permissionChildKey="can_edit_tags"
            >
              <primaryComponents.Button
                classNames={"btn btn--outline smallBtn"}
                onClick={() => {
                  setTagDetails(row.original);
                  setIsEditTag(true);
                }}
              >
                Edit tag
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Payments"}
              permissionChildKey="can_delete_tags"
            >
              <primaryComponents.Button
                classNames={"btn btn--danger ml-3 smallBtn"}
                onClick={() => {
                  setTagDetails(row.original);
                  setIsDeleteTag(true);
                }}
              >
                Delete tag
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: tags || [] });

  return {
    instance,
    isDeleteTag,
    isEditTag,
    tagDetails,
    setIsEditTag,
    setIsDeleteTag,
  };
};

export default TagsTableLogic;
