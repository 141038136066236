import { useSelector } from "react-redux";
import { formatCurrency } from "../../../helpers";
import primaryComponents from "../../primaryComponents";
const ReconciliationReviewPrompt = ({
  reconciliationType,
  closeModal,
  proceed,
  discrepancy = "",
}) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  return (
    <div className="py-3 px-3">
      <h4 className="mb-3 text-center">
        {reconciliationType === "approve"
          ? "Confirm Reconciliation Approval"
          : reconciliationType === "reject"
            ? "Confirm Reconciliation Rejection"
            : "Confirm Submission for Review"}
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
        }}
      >
        {reconciliationType === "approve" ? (
          <p>
            You are on the verge of authorizing a reconciliation process with
            the potential to affect your inventory by{" "}
            {formatCurrency({
              value: discrepancy || 0,
              currencyCode: currency_code,
            })}
            . This action will directly influence your current stock position.
            Are you certain you wish to proceed with approval?
          </p>
        ) : reconciliationType === "reject" ? (
          <p>
            You have opted to decline the reconciliation process. This choice
            will keep your current inventory status unchanged. Are you certain
            you want to reject this reconciliation?
          </p>
        ) : (
          <p>
            Please be aware that once you submit this reconciliation for review,
            any further edits will not be possible. Are you certain you want to
            proceed with the submission?
          </p>
        )}
      </div>

      <div
        className="d-flex justify-content-between mt-4"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          classNames="btn btn--outline cover"
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          onClick={() => {
            proceed();
            closeModal();
          }}
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default ReconciliationReviewPrompt;
