import MultipleSelectField from "../MultipleSelectField/MultipleSelectField";
import { Controller } from "react-hook-form";

const MultipleSelectFieldV2 = ({
  onChange = () => {},
  name = "",
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onControllerChange } }) => (
        <MultipleSelectField
          name={name}
          onChange={(answer, others) => {
            onControllerChange(answer);
            onChange(answer, others);
          }}
          {...props}
        />
      )}
    />
  );
};

export default MultipleSelectFieldV2;
