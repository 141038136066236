import { useParams } from "react-router-dom";
import { getStoreDetails } from "../../../../services/inventory";
import { useQuery } from "react-query";

const useEditStoreDetails = () => {
  const { id: storeId } = useParams();

  const { data, isLoading: isFetchingStoreInfo } = useQuery(
    ["getStoreDetails", storeId],
    () => getStoreDetails(storeId).then((res) => res.data?.data),
    {
      cacheTime: 0,
    },
  );

  return {
    data,
    isFetchingStoreInfo,
  };
};

export default useEditStoreDetails;
