import { useState } from "react";
import { useGetTagList } from "../../../../hooks/api/queries/useInventory";

const useTags = () => {
  const [isAddingTag, setIsAddingTag] = useState(false);
  const { isLoading: isFetchingTags, data: tags } = useGetTagList();

  return { isAddingTag, isFetchingTags, tags: tags || [], setIsAddingTag };
};
export default useTags;
