export const ToolTipIcon = ({ fill = "None", width = "22", height = "22" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="22" height="22" rx="11" fill="" />
      <g clipPath="url(#clip0_469_7505)">
        <path
          d="M11.0001 3.5C6.85798 3.5 3.50012 6.85786 3.50012 11C3.50012 15.1421 6.85798 18.5 11.0001 18.5C15.1423 18.5 18.5001 15.1421 18.5001 11C18.5001 6.85786 15.1423 3.5 11.0001 3.5Z"
          fill="white"
          stroke="#7647EE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 14L11 11"
          stroke="#7647EE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 7.98537L11 8"
          stroke="#7647EE"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_469_7505">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(20 20) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
