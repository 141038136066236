import { setApiErrorMessage } from "../../../store/modules/apiErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const ApiErrorHandlerLogic = () => {
  const dispatch = useDispatch();
  const { message, isSubscriptionNotification } = useSelector(
    (state) => state.apiErrorReducer,
  );

  useEffect(() => {
    if (message === null) return;

    setTimeout(() => {
      if (!isSubscriptionNotification) closeApiErrorHandler();
    }, 10000);
  }, [message]);

  const closeApiErrorHandler = () => {
    dispatch(
      setApiErrorMessage({
        message: null,
        isSubscriptionNotification: undefined,
      }),
    );
  };

  return { message, isSubscriptionNotification, closeApiErrorHandler };
};
export default ApiErrorHandlerLogic;
