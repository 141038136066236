import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";
import useAllStoreWallets from "./useAllStoreWallets";

const AllStoreWalllet = () => {
  const {
    search,
    isLoadingStoreWallets,
    storeWallets,
    metaDetails,
    modals,
    resetModals,
    handleSearchWallets,
    gotoPage,
  } = useAllStoreWallets();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Store Wallets"
      description="View all store wallet"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={` ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Store Wallet</h4>
            <p>
              Manage wallets of customers that keep funds with your business.
            </p>
          </div>

          <div className={globalStyles.pageHeaders}>
            <p>{metaDetails?.total} Store Wallets</p>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_create_store_wallet"
            >
              <primaryComponents.Button
                classNames={"btn btn--primary"}
                onClick={() => resetModals("isCreateWallet")}
              >
                Create Wallet
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <div className={`${globalStyles.tableWrapper}`}>
            <div className={`${globalStyles.tableHeader}`}>
              <primaryComponents.SearchField
                value={search.get("query") ? search.get("query") : ""}
                placeholder={
                  "Search by customer name, phone number or wallet ID"
                }
                triggerOnInput={true}
                showsearchBtn={true}
                trigger={(value) => handleSearchWallets(value)}
                classNames={`${globalStyles.searchField}`}
              />
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isLoadingStoreWallets}
              classNames={"mt-5"}
            >
              <secondaryComponents.StoreWalletsTable
                storeWallets={storeWallets}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>

        {/* create store wallet */}
        <secondaryComponents.Modal
          isActive={modals?.isCreateWallet}
          closeModal={() => resetModals("isCreateWallet", false)}
          width={600}
        >
          <secondaryComponents.StoreWalletCreationForm
            closeModal={() => resetModals("isCreateWallet", false)}
            customerDetails={null}
          />
        </secondaryComponents.Modal>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllStoreWalllet;
