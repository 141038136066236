import { useQuery } from "react-query";
import { getCampaignDetails, getCampaigns } from "../../../services/campaign";
import { getGiftCardList } from "../../../services/giftcards";

export const useGetCampaigns = ({ queries = "" }) => {
  return useQuery(
    ["getCampaigns", queries],
    () => getCampaigns(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetCampaignDetails = (id = "") => {
  return useQuery(
    ["getCampaignDetails", id],
    () => getCampaignDetails(id).then((res) => res?.data?.data),
    {
      staleTime: Infinity,
    },
  );
};

export const useGetGiftCards = ({ queries = "" }) => {
  return useQuery(
    ["getGiftCardList", queries],
    () => getGiftCardList(queries).then((res) => res?.data),
    {
      staleTime: Infinity,
    },
  );
};
