import React from "react";
import Icon from "../../../assets/icons";
import styles from "./styles.module.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { useNotifications } from "./useNotifications";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { pathConstants } from "../../../routes/pathContants";

dayjs.extend(relativeTime);

const Notifications = ({ setShowNotificationModal }) => {
  const navigate = useNavigate();
  const { isLoading, dataNotifications } = useNotifications();

  return (
    <div className={styles.mainContainer}>
      <div className={styles.closeContainer}>
        <img
          onClick={() => setShowNotificationModal(false)}
          src={Icon.close}
          alt=""
        />
      </div>
      <h4>Notifications</h4>
      <secondaryComponents.LoaderHelper
        isLoading={isLoading || !dataNotifications}
        classNames="mt-4"
      >
        <div className={styles.notificationsContainer}>
          <span className={styles.subTitle}>Today</span>
          <div className={styles.notificationItems}>
            {dataNotifications?.map((item) => (
              <div key={item.id} className={styles.notificationItem}>
                <div className={styles.notificationTextContainer}>
                  {item?.is_read ? (
                    <img src={Icon.readNotification} alt="" />
                  ) : (
                    <img src={Icon.unReadNotification} alt="" />
                  )}
                  <div className={styles.notificationText}>
                    <p
                      className={
                        item?.is_read ? styles.readMainText : styles.mainText
                      }
                    >
                      {item.subject}
                    </p>
                    <p
                      className={`small-text-size ${
                        item?.is_read ? styles.readSubText : styles.subText
                      }`}
                    >
                      {item.body}
                    </p>
                    <p className={"smallest-text-size"}>
                      {dayjs(item.created_at).format("DD MMMM YYYY")}
                      {" | "}
                      {dayjs(item.created_at).fromNow()}
                    </p>
                  </div>
                </div>
                <primaryComponents.Button
                  classNames={`btn ${
                    item?.is_read ? "btn--outline" : "btn--primary"
                  } smallBtn`}
                  onClick={() =>
                    navigate(
                      `${pathConstants.INVENTORY_SALES_TRANSACTIONS}?orderId=${item?.object_id}`,
                    )
                  }
                >
                  View order
                </primaryComponents.Button>
              </div>
            ))}
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default Notifications;
