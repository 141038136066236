/* eslint-disable react/jsx-key */
import "./RecentTransactionTable.scss";
import RecentTransactionTableLogic from "./RecentTransactionTableLogic";

const RecentTransactionTable = ({ mostRecentTransaction }) => {
  const { instance } = RecentTransactionTableLogic({ mostRecentTransaction });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <table className="recent-transactions-table" {...getTableProps()}>
      <thead className="recent-transactions-table__head">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th className="font-weight-semibold" {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        className="recent-transactions-table__body"
        {...getTableBodyProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              className="recent-transactions-table__body__row"
              {...row.getRowProps({ key: i })}
            >
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default RecentTransactionTable;
