import Icon from "../../../assets/icons";
import styles from "./ToolTip.module.scss";

const ToolTip = ({
  children,
  placeholderContent,
  showInfoIcon = false,
  contentStyles = {},
  useContentStyling = false,
}) => {
  return (
    <span className={styles.toolTip}>
      <span
        className={`${styles.toolTip__placeholder} d-flex align-items-center`}
        style={{ cursor: "pointer" }}
      >
        <span>{placeholderContent}</span>
        {showInfoIcon ? (
          <img
            className="ml-2"
            src={Icon.exclamationCirlceIcon}
            alt="exclamation circle"
            style={{ transform: "rotate(180deg)" }}
          />
        ) : null}
      </span>

      <span
        className={`${styles.toolTip__content} ${
          useContentStyling ? styles.toolTip__contentStyles : ""
        }`}
        style={contentStyles}
      >
        {children}
      </span>
    </span>
  );
};
export default ToolTip;
