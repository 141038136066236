import Icon from "../../../../../assets/icons";
import styles from "./styles.module.scss";
import { useState, useEffect, useRef, useContext } from "react";
import primaryComponents from "../../../../primaryComponents";
import secondaryComponents from "../../..";
import { useQueryClient } from "react-query";
import { RequestLoader, ToastContext } from "../../../../../hooks/context";
import {
  useToggleStoreWallet2fAuthMutation,
  useToggleStoreWalletStatusMutation,
} from "../../../../../hooks/api/mutations/useInventory";
import { cleanObject, getRandomIntInclusive } from "../../../../../helpers";

const MoreActions = ({ walletDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const toggleStoreWalletStatusMutation = useToggleStoreWalletStatusMutation();
  const toggleStoreWallet2fAuthMutation = useToggleStoreWallet2fAuthMutation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleWalletStatus = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    toggleStoreWalletStatusMutation.mutate(
      cleanObject({ id: walletDetails?.id }),
      {
        onSuccess: (res) => onSuccess(res),
        onSettled: setRequestLoaderProgress(100),
      },
    );
  };

  const toggleWallet2fAuth = () => {
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    toggleStoreWallet2fAuthMutation.mutate(
      cleanObject({ id: walletDetails?.id }),
      {
        onSuccess: (res) => onSuccess(res),
        onSettled: setRequestLoaderProgress(100),
      },
    );
  };

  const onSuccess = (res) => {
    triggerToast(res?.data?.detail, "success");
    queryClient.invalidateQueries(["getStoreWallets"]);
    queryClient.invalidateQueries(["getStoreWalletDetails"]);
  };

  return (
    <span className={styles.container} ref={menuRef}>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <img src={Icon.option_alt} alt="" />
      </span>

      {isOpen ? (
        <div className={styles.dropdrown}>
          <div className={styles.dropdrownItem}>
            <p
              onClick={() => {
                setIsOpen(false);
              }}
              className="d-flex align-items-center"
            >
              Store Wallet
              <secondaryComponents.ToolTipV2
                tipText="Control your customer's store wallet by activating or deactivating them."
                position="left"
              >
                <img
                  className="ml-2"
                  src={Icon.exclamationCirlceIcon}
                  alt="exclamation circle"
                  style={{ transform: "rotate(180deg)" }}
                />
              </secondaryComponents.ToolTipV2>
            </p>
            <primaryComponents.Button
              type="button"
              classNames={`btn ${walletDetails?.is_active ? "btn--outline-red" : "btn--success"} smallBtn`}
              onClick={() => toggleWalletStatus()}
            >
              {walletDetails?.is_active ? "De-activate" : "Activate"}
            </primaryComponents.Button>
          </div>
          <div className={styles.dropdrownItem}>
            <p
              onClick={() => {
                setIsOpen(false);
              }}
              className="d-flex align-items-center"
            >
              2-Factor Authentication
              <secondaryComponents.ToolTipV2
                tipText="Add an extra layer of security to confirm customers when using their store wallet for transactions."
                position="left"
              >
                <img
                  className="ml-2"
                  src={Icon.exclamationCirlceIcon}
                  alt="exclamation circle"
                  style={{ transform: "rotate(180deg)" }}
                />
              </secondaryComponents.ToolTipV2>
            </p>
            <primaryComponents.Button
              type="button"
              classNames={`btn ${walletDetails?.is_2fa_enabled ? "btn--outline-red" : "btn--success"} smallBtn`}
              onClick={() => toggleWallet2fAuth()}
            >
              {walletDetails?.is_2fa_enabled ? "De-activate" : "Activate"}
            </primaryComponents.Button>
          </div>
        </div>
      ) : null}
    </span>
  );
};

export default MoreActions;
