import "./Beneficiaries.scss";
import primaryComponents from "../../../primaryComponents";
import secondaryComponents from "../..";
import BeneficiariesLogic from "./BeneficiariesLogic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

const Beneficiaries = ({ fundTransferLogic }) => {
  const { isLoading, searchBeneficary, searchValue, currentBeneficiaries } =
    BeneficiariesLogic();
  return (
    <motion.div
      initial={{ opacity: 0, marginTop: "100px" }}
      animate={{ opacity: 1, marginTop: "25px", transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
      className="beneficiaries"
      data-testid="beneficiaries"
    >
      <div className="d-flex align-items-center mb-4">
        <FontAwesomeIcon
          onClick={() => fundTransferLogic.navigation.go(0)}
          className="mb-2 mr-3"
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
        <h4>Find Beneficiary</h4>
      </div>

      <primaryComponents.SearchField
        placeholder="Search for a beneficiary"
        trigger={(value) => searchBeneficary(value)}
        value={searchValue}
        triggerOnInput={true}
      />

      <secondaryComponents.LoaderHelper classNames="mt-5" isLoading={isLoading}>
        {currentBeneficiaries.length > 0 ? (
          <div className="mt-4">
            {currentBeneficiaries.map((item, index) => (
              <div
                className="beneficiaries__item"
                key={index}
                onClick={() => fundTransferLogic.fillFormWithBeneficiary(item)}
              >
                <div className="profile">
                  {item.account_name.split("")[0].toUpperCase()}
                  {item.account_name.split("")[1].toUpperCase()}
                </div>

                <div className="ml-3">
                  <h6 className="mb-0">{item.account_name}</h6>
                  <p className="mb-0">
                    {item.bank_name} - {item.account_number}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="text-center mt-5">
            <h4>No data available</h4>
          </div>
        )}
      </secondaryComponents.LoaderHelper>
    </motion.div>
  );
};
export default Beneficiaries;
