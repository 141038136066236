import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../hooks/context";
import { downloadStockHistory } from "../../../services/inventory";
import { useGetStockTypes } from "../../../hooks/api/queries";

const useStockHistoryStatement = () => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [store, setStore] = useState([]);
  const [stockType, setStockType] = useState([]);
  const [fileFormat] = useState("csv");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const triggerToast = useContext(ToastContext);

  const { data: dataStockTypes } = useGetStockTypes();

  const { isLoading: isDownloading, mutate: downloadStockStatements } =
    useMutation((data) => downloadStockHistory(data));
  const downloadStockStatement = () => {
    downloadStockStatements(
      {
        store_id: store,
        stock_type: stockType,
        file_format: fileFormat,
        start_date: startDate,
        end_date: endDate,
      },
      {
        onSuccess: (res) => {
          triggerToast(res?.data?.detail, "success");
        },
      },
    );
  };

  return {
    isDownloading,
    store,
    fileFormat,
    stockType,
    startDate,
    endDate,
    storeList,
    dataStockTypes:
      dataStockTypes?.map((item) => ({
        name: item.display_name,
        id: item.value,
      })) || [],
    setStore,
    setStockType,
    setStartDate,
    setEndDate,
    downloadStockStatement,
  };
};
export default useStockHistoryStatement;
