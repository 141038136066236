import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menuCartItems: [],
  businessId: null,
  menuId: null,
};

export const menuCartSlice = createSlice({
  name: "menuCart",
  initialState,
  reducers: {
    addToMenuCart: (state, action) => {
      const productIndex = state.menuCartItems.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (productIndex !== -1) {
        state.menuCartItems.splice(productIndex, 1);
      }

      state.menuCartItems.unshift(action.payload);

      localStorage.setItem(
        `menuCartItems${state.menuId}`,
        JSON.stringify(state.menuCartItems),
      );
    },
    removeFromMenuCart: (state, action) => {
      state.menuCartItems = state.menuCartItems.filter(
        (item) => item.id !== action.payload.id,
      );

      localStorage.setItem(
        `menuCartItems${state.menuId}`,
        JSON.stringify(state.menuCartItems),
      );
    },
    setMenuItemQty: (state, action) => {
      const indexOfProduct = state.menuCartItems.findIndex(
        (item) => item.id === action.payload.id,
      );

      if (indexOfProduct !== -1) {
        state.menuCartItems[indexOfProduct] = action.payload;

        localStorage.setItem(
          `menuCartItems${state.menuId}`,
          JSON.stringify(state.menuCartItems),
        );
      }
    },
    clearAllMenuCartItems: (state) => {
      state.menuCartItems = [];

      localStorage.setItem(
        `menuCartItems${state.menuId}`,
        JSON.stringify(state.menuCartItems),
      );
    },
    resetMenuCartItems: (state, action) => {
      state.menuCartItems = action.payload;

      if (state.menuId)
        localStorage.setItem(
          `menuCartItems${state.menuId}`,
          JSON.stringify(action.payload),
        );
    },
    setBusinessId: (state, action) => {
      state.businessId = action.payload;
    },
    setMenuId: (state, action) => {
      state.menuId = action.payload;
    },
  },
});

export const {
  addToMenuCart,
  removeFromMenuCart,
  setMenuItemQty,
  clearAllMenuCartItems,
  setBusinessId,
  setMenuId,
  resetMenuCartItems,
} = menuCartSlice.actions;
