import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";
import { getSuppliersDetails } from "../../../../services/suppliers";
import { getSupplierInvoices } from "../../../../services/invoices";

const useSupplierDetails = () => {
  const params = useParams();
  const [search, setSearch] = useSearchParams();

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const { isLoading: isFetchingSupplierDetails, data: supplierDetails } =
    useQuery(["getSuppliersDetails", params.id], () =>
      getSuppliersDetails(params.id).then((res) => res?.data?.data),
    );

  const {
    isLoading: isFetchingInvoice,
    data: invoices,
    refetch: refetchSupplierInvoices,
  } = useQuery(["getSupplierInvoices", params.id, search.get("page")], () =>
    getSupplierInvoices(params.id, search).then((res) => res?.data),
  );

  return {
    isFetchingSupplierDetails,
    isFetchingInvoice,
    supplierDetails,
    invoices,
    refetchSupplierInvoices,
    gotoPage,
  };
};
export default useSupplierDetails;
