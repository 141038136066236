import "./FundTransfer.scss";
import secondaryComponents from "../../secondaryComponents";
import useFundTransfer from "./useFundTransfer";
import TransferSummary from "./TransferSummary/TransferSummary";
import TransferForm from "./TransferForm/TransferForm";
import Beneficiaries from "./Beneficiaries/Beneficiaries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FundTransfer = ({ closeWidget, externalRequest = null }) => {
  const fundTransferLogic = useFundTransfer({
    closeWidget,
    externalRequest,
  });

  return (
    <div>
      {fundTransferLogic.canTransferFunds ? (
        <div>
          {fundTransferLogic.index === 0 && (
            <TransferForm
              fundTransferLogic={fundTransferLogic}
              externalRequest={externalRequest}
              closeWidget={closeWidget}
            />
          )}
          {fundTransferLogic.index === 1 && (
            <TransferSummary
              fundTransferLogic={fundTransferLogic}
              externalRequest={externalRequest}
            />
          )}
          {fundTransferLogic.index === 2 && (
            <Beneficiaries fundTransferLogic={fundTransferLogic} />
          )}
        </div>
      ) : (
        <div className="mt-2">
          <div className="close-icon">
            <FontAwesomeIcon
              onClick={closeWidget}
              style={{ fontSize: "25px" }}
              icon="times"
            />
          </div>
          <secondaryComponents.UnverifiedAccountWidget
            message="It appears your account has not been verified, please verify your account to continue."
            verification_status={fundTransferLogic.verification_status}
          />
        </div>
      )}
    </div>
  );
};

export default FundTransfer;
