import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
const initialState = { message: null, isSubscriptionNotification: false };

export const apiErrorSlice = createSlice({
  name: "apiError",
  initialState,
  reducers: {
    setApiErrorMessage: (state, action) => {
      state.message = action?.payload?.message;
      state.isSubscriptionNotification =
        action?.payload?.isSubscriptionNotification || false;
    },
  },
});

export const { setApiErrorMessage } = apiErrorSlice.actions;
