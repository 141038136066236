import { useState, useEffect } from "react";
import {
  useGetAccountingBanksList,
  useGetBankTransactionList,
} from "../../../../hooks/api/queries";
import { getUrlQuerysection } from "../../../../helpers";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
const useAllTransaction = () => {
  const [showAddCredit, setShowAddCredit] = useState(false);
  const [showAddDebit, setShowAddDebit] = useState(false);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);

  const queries = getUrlQuerysection();
  const {
    isLoading: isLoadingBankTransaction,
    data: bankTransaction,
    refetch: handleFetchBankTransaction,
  } = useGetAccountingBanksList({ queries });

  const {
    isLoading: isLoadingTransactions,
    data: Transactions,
    refetch: handleFetchTransactions,
  } = useGetBankTransactionList({ queries });

  const [search, setSearch] = useSearchParams(storeList[0]?.id);

  const [currentStore] = useState(search.get("store_id") || storeList[0]?.id);

  const filterByStore = (value) => {
    search.set("store_id", value);
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (!search.get("store_id")) {
      filterByStore(storeList[0]?.id);
    } else {
      handleFetchBankTransaction();
      handleFetchTransactions();
    }
  }, [storeList, search, setSearch]);

  return {
    filterByStore,
    isLoadingBankTransaction,
    isLoadingTransactions,
    handleFetchTransactions,
    bankTransaction: bankTransaction?.data || [],
    Transactions: Transactions?.data || [],
    currentStore,
    storeList,
    showAddCredit,
    setShowAddCredit,
    handleFetchBankTransaction,
    showAddDebit,
    setShowAddDebit,
  };
};

export default useAllTransaction;
