import { useEffect, useContext, useState } from "react";
import { RequestLoader, ToastContext } from "../../../../hooks/context";
import {
  fetchTerminalDetails,
  resetTerminalCredentials,
} from "../../../../services/pos-terminals";
import { getRandomIntInclusive } from "../../../../helpers";
import { useParams, useNavigate } from "react-router-dom";

const TerminalCredentialsLogic = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [terminalDetails, setTerminalDetails] = useState({});
  const [isResetingTerminal, setIsResetingTerminal] = useState(false);
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const { terminalId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getTerminalDetails();
  }, []);

  const getTerminalDetails = (showSpinner = true) => {
    if (showSpinner === true) setIsLoading(true);
    setRequestLoaderProgress(getRandomIntInclusive(20, 50));
    fetchTerminalDetails(terminalId)
      .then((response) => {
        setTerminalDetails(response.data.data);
        setRequestLoaderProgress(100);
        setIsLoading(false);
      })
      .catch((error) => {
        setRequestLoaderProgress(100);
        setIsLoading(false);
      });
  };

  const handleResetTerminal = () => {
    setIsResetingTerminal(true);
    resetTerminalCredentials(terminalId)
      .then(() => {
        setIsResetingTerminal(false);
        getTerminalDetails(false);
        triggerToast("Credentials successfully reset.", "success");
      })
      .catch((error) => {
        setIsResetingTerminal(false);
      });
  };

  return {
    isLoading,
    terminalDetails,
    navigate,
    handleResetTerminal,
    isResetingTerminal,
  };
};

export default TerminalCredentialsLogic;
