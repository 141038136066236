import React from "react";
import secondaryComponents from "../../../../../../components/secondaryComponents";
import ManageRolesTable from "../../ManageRolesTable";
import useCustomRoles from "./useCustomRoles";

const CustomRoles = () => {
  const { isLoading, data, refetch } = useCustomRoles();

  return (
    <secondaryComponents.LoaderHelper
      classNames="mt-5"
      isLoading={isLoading || !data}
    >
      <ManageRolesTable
        roleType="custom"
        rolesData={data?.filter((item) => !item.is_default)}
        refetch={refetch}
      />
    </secondaryComponents.LoaderHelper>
  );
};

export default CustomRoles;
