const Excel = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10067_78337)">
        <path
          d="M2.74219 0.84375H11.4609C13.6161 0.84375 15.3633 2.59089 15.3633 4.74609V17.2969H2.74219V0.84375Z"
          fill="#EAEAEA"
        />
        <path d="M15.5671 3.85308H12.1921V0.467529" fill="#C5C5C5" />
        <path
          d="M15.8203 17.7891H2.17969V0.210938H12.5754L15.8238 3.61055V17.7891H15.8203ZM2.68945 17.2758H15.3105V3.81797L12.3539 0.724219H2.68945V17.2758Z"
          fill="#EAEAEA"
        />
        <path
          d="M14.3649 16.3266H3.63525V6.75354H14.3685V16.3266H14.3649ZM4.14502 15.8168H13.8517V7.26682H4.14502V15.8168Z"
          fill="#19AE57"
        />
        <path
          d="M15.3535 7.37091L15.7369 7.03447L17.1476 8.64187L16.7643 8.97831L15.3535 7.37091Z"
          fill="#19AE57"
        />
        <path
          d="M0.86377 8.64575L2.27452 7.03835L2.65786 7.3748L1.24711 8.9822L0.86377 8.64575Z"
          fill="#19AE57"
        />
        <path
          d="M0.861328 8.64136V14.4386H17.1387V8.64136H0.861328ZM7.54453 12.7019L7.0418 11.83L6.53906 12.7019H6.07852L6.80977 11.5312L6.09609 10.3781H6.55312L7.03828 11.2359L7.52695 10.3781H7.98047L7.2668 11.5312L8.00859 12.7019H7.54453ZM9.88594 12.7019H8.40937V10.3781H8.79609V12.3925H9.88242V12.7019H9.88594ZM11.6965 12.5613C11.5453 12.6773 11.3484 12.7335 11.1023 12.7335C10.8668 12.7335 10.6594 12.6703 10.4836 12.5437C10.3078 12.4171 10.2234 12.2414 10.227 12.0128L10.2305 12.0023H10.6031C10.6031 12.1429 10.6523 12.2449 10.7473 12.3187C10.8422 12.389 10.9617 12.4242 11.0988 12.4242C11.2359 12.4242 11.3414 12.396 11.4187 12.3363C11.4961 12.28 11.5312 12.1992 11.5312 12.1007C11.5312 12.0023 11.4961 11.925 11.4293 11.8617C11.359 11.7984 11.2395 11.7457 11.0637 11.6964C10.8141 11.6261 10.6207 11.5382 10.4871 11.4257C10.35 11.3132 10.2832 11.1656 10.2832 10.9863C10.2832 10.8 10.357 10.6453 10.5082 10.5222C10.6559 10.4027 10.8492 10.3394 11.0813 10.3394C11.3309 10.3394 11.5312 10.4062 11.6824 10.5398C11.8336 10.6734 11.9039 10.8421 11.9004 11.039L11.8969 11.0496H11.5242C11.5242 10.9265 11.482 10.8316 11.4012 10.7578C11.3203 10.6839 11.2113 10.6488 11.0777 10.6488C10.9477 10.6488 10.8457 10.6804 10.7754 10.7402C10.7051 10.8035 10.6699 10.8808 10.6699 10.9792C10.6699 11.0671 10.7086 11.141 10.7859 11.2007C10.8633 11.257 10.9934 11.3132 11.1727 11.366C11.4152 11.4328 11.6016 11.5277 11.7281 11.6437C11.8547 11.7597 11.918 11.9109 11.918 12.0937C11.925 12.2941 11.8477 12.4488 11.6965 12.5613Z"
          fill="#19AE57"
        />
      </g>
      <defs>
        <clipPath id="clip0_10067_78337">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Excel;
