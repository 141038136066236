import secondaryComponents from "..";
import Images from "../../../assets/images";
import RemoveTrackItem from "./RemoveTrackItem";
import UpdateTrackItem from "./UpdateTrackItem";
import useTrackItemTable from "./useTrackItemTable";

const TrackItemTable = ({ metaDetails, trackedItems = [], gotoPage }) => {
  const {
    instance,
    selectedDeleteItem,
    selectedUpdateItem,
    setSelectedDeleteItem,
    setSelectedUpdateItem,
  } = useTrackItemTable({ trackedItems });

  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <secondaryComponents.TableHelper
        getTableBodyProps={getTableBodyProps}
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center pt-5">
          <img src={Images.productsEmptyState} width="250px" alt="" />
          <h4 className="font-weight-semibold text-center mt-4">
            No Data Available
          </h4>
        </div>
      </secondaryComponents.TableHelper>
      {/* pagination */}
      {trackedItems?.length > 0 && (
        <div className="mt-auto">
          <secondaryComponents.Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        </div>
      )}

      <secondaryComponents.Modal
        width={400}
        isActive={selectedDeleteItem !== null}
        closeModal={() => setSelectedDeleteItem(null)}
      >
        <RemoveTrackItem
          selectedItem={selectedDeleteItem}
          closeModal={() => setSelectedDeleteItem(null)}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        width={400}
        isActive={selectedUpdateItem !== null}
        closeModal={() => setSelectedUpdateItem(null)}
      >
        <UpdateTrackItem
          selectedItem={selectedUpdateItem}
          closeModal={() => setSelectedUpdateItem(null)}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default TrackItemTable;
