import { useContext, useEffect, useRef, useState } from "react";
import { findPricingViaIdHelper, getUrlQuerysection } from "../../../helpers";
import { useSearchParams } from "react-router-dom";
import useScanDetection from "use-scan-detection";
import { DEFAULT_PRICING_ID } from "../../../helpers/constants";
import { ToastContext } from "../../../hooks/context";
import { useGetAllProductsList } from "../../../hooks/api/queries";

export const useBulkRestockComboBox = ({
  handleAppendRestockData,
  setStoreError,
}) => {
  const [search, setSearch] = useSearchParams();
  // const queries = (() => {
  //   let params = `?exclude_on_demand_composite_products=${true}`;
  //   for (const entry of search.entries())
  //     if (entry[0] !== "is_scan") params = `${params}&${entry[0]}=${entry[1]}`;

  //   return params;
  // })();
  const queries = getUrlQuerysection();
  const searchRef = useRef(null);
  const [isDropDown, setIsDropDown] = useState(false);
  const [isVariantOpenId, setIsVariantOpenId] = useState(null);
  const [searchValue, setSearchValue] = useState(
    search.get("query") !== null ? search.get("query") : "",
  );
  const triggerToast = useContext(ToastContext);

  const { isLoading, data } = useGetAllProductsList({
    queries,
    enabled: search.get("store_id") && search.get("query") ? true : false,
    onSuccess: (res) => {
      if (search.get("is_scan") === "true") {
        const variant =
          res?.[0]?.variants?.length > 0 ? res?.data?.[0]?.variants?.[0] : [];
        addProductForRestock(res?.data?.[0], variant);
      }
    },
  });

  const searchProduct = (value, isScanBar = false) => {
    if (!search.get("store_id")) {
      const message = "Please select a store";
      setStoreError(message);
      triggerToast(message, "warning");
      setTimeout(() => {
        setStoreError("");
      }, 5000);
      return;
    }

    if (value?.length === 0) search.delete("query");
    else {
      setIsDropDown(true);
      search.set("query", value);
      search.set("is_scan", isScanBar);
    }
    setSearch(search, { replace: true });
  };

  const addProductForRestock = (product, variant = []) => {
    const isVariant = product?.variants?.length > 0;

    handleAppendRestockData({
      stock_quantity: isVariant
        ? variant?.store_variant_properties?.[0]?.stock_quantity
        : product?.store_product_properties?.[0]?.stock_quantity,
      quantity: "",
      name: isVariant
        ? `${product?.name} (${variant?.option} - ${variant?.value})`
        : product?.name,
      product_id: product?.id,
      variant_id: isVariant ? variant?.id : undefined,
      variant: isVariant ? variant?.value : undefined,
      store_id: isVariant
        ? variant?.store_variant_properties?.[0]?.store_id
        : product?.store_product_properties?.[0]?.store_id,
      store_name: isVariant
        ? variant?.store_variant_properties?.[0]?.store_name
        : product?.store_product_properties?.[0]?.store_name,
      barcode: isVariant
        ? `${variant?.barcode ? variant?.barcode : ""}`
        : `${product?.barcode ? product?.barcode : ""}`,
      expiry_date: isVariant
        ? `${
            variant?.store_variant_properties?.[0]?.expiry_date
              ? variant?.store_variant_properties?.[0]?.expiry_date
              : ""
          }`
        : `${
            product?.store_product_properties?.[0]?.expiry_date
              ? product?.store_product_properties?.[0]?.expiry_date
              : ""
          }`,
      cost_price: isVariant
        ? variant?.store_variant_properties?.[0]?.cost_price
        : product?.store_product_properties?.[0]?.cost_price,
      product_type: isVariant
        ? "Variant"
        : product?.is_composite
          ? "Composite"
          : "Single Product",
      pricings: [
        findPricingViaIdHelper(
          DEFAULT_PRICING_ID,
          isVariant
            ? variant?.store_variant_properties?.[0]?.pricings
            : product?.store_product_properties?.[0]?.pricings,
        ),
      ],
      unit: product?.unit?.unit,
    });
    setIsDropDown(false);
    setSearchValue("");
    search.delete("is_scan");
    search.delete("query");
    setSearch(search, { replace: true });
  };

  const handleScanBar = (value) => {
    searchProduct(value, true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target))
        setIsDropDown(false);
    };
    document.addEventListener("click", handleClickOutside);
  }, [searchRef]);

  useScanDetection({
    onComplete: handleScanBar,
    minLength: 8,
  });

  return {
    searchRef,
    isLoading,
    products: data?.data || [],
    isDropDown,
    searchValue,
    isVariantOpenId,
    setSearchValue,
    searchProduct,
    setIsDropDown,
    addProductForRestock,
    setIsVariantOpenId,
  };
};
