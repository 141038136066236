import styles from "./CreditForm.module.scss";
import primaryComponents from "../../primaryComponents";
import useCreditForm from "./useCreditForm";
import Validation from "./Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";
import LoaderHelper from "../LoaderHelper/LoaderHelper";

const CreditForm = ({
  invoiceDetails,
  handleFetchInvoice = () => {},
  closeWidget = () => {},
}) => {
  const { currency_code, allow_ledger_account } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const {
    isAddingCredit,
    creditDetails,
    setCreditDetails,
    submitCreditInfo,
    bankAccount,
    isLoadingBankAccountsList,
  } = useCreditForm({
    invoiceDetails,
    handleFetchInvoice,
    closeWidget,
  });
  const {
    handleSubmit,
    control,
    register,
    trigger,
    formState: { errors },
  } = Validation({ creditDetails });

  return (
    <div className={styles.creditForm}>
      <div className="mb-3">
        <FontAwesomeIcon
          onClick={() => {
            closeWidget();
          }}
          icon="angle-left"
          style={{ fontSize: "25px", cursor: "pointer" }}
        />
      </div>
      <h4 className="font-weight-semibold">
        Update&nbsp;
        <span className="purple--text"> Invoice {invoiceDetails?.id}</span>
      </h4>
      <div className={styles.user_info}>
        <p className={styles.acct_text}>Outstanding Balance</p>
        <div className={styles.acct_no_wrapper}>
          <p className="purple--text">
            {formatCurrency({
              value: invoiceDetails?.outstanding_balance,
              currencyCode: currency_code,
            })}
          </p>
        </div>
      </div>
      <div className={styles.user_info}>
        <p className={styles.acct_text}>Total Credit:</p>
        <div className={styles.acct_no_wrapper}>
          <p>
            {formatCurrency({
              value: invoiceDetails?.credit_amount,
              currencyCode: currency_code,
            })}
          </p>
        </div>
      </div>
      <div className={styles.user_info}>
        <p className={styles.acct_text}>Total Repayment</p>
        <div className={styles.acct_no_wrapper}>
          <p>
            {formatCurrency({
              value: invoiceDetails?.total_repayment,
              currencyCode: currency_code,
            })}
          </p>
        </div>
      </div>
      {/* form section */}
      <form
        onSubmit={handleSubmit(submitCreditInfo)}
        className={`${styles.formSection} border rounded py-3 px-3`}
      >
        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          name="outstandingBalance"
          label="Outstanding Balance"
          isDisabled={true}
          value={invoiceDetails?.outstanding_balance}
          register={register}
        />
        <primaryComponents.InputFieldV2
          control={control}
          classNames="white"
          name="amountPaid"
          label="Amount Paid"
          placeholder="Enter amount paid"
          isRequired={true}
          value={creditDetails?.amount_paid}
          onChange={({ target: { value } }) => {
            setCreditDetails((prev) => ({
              ...prev,
              amount_paid: value,
            }));
          }}
          errorMessage={errors.amountPaid}
          onKeyUp={() =>
            errors.amountPaid !== undefined && trigger("amountPaid")
          }
          register={register}
        />

        <primaryComponents.SelectFieldV2
          label="Payment Method"
          isRequired
          options={[
            { name: "Transfer", id: "transfer" },
            { name: "Card", id: "card" },
            { name: "Cash", id: "cash" },
            { name: "Cheque", id: "cheque" },
          ]}
          nameKey={"name"}
          idKey={"id"}
          name="paymentMethod"
          control={control}
          value={
            creditDetails?.payment_method
              ? creditDetails?.payment_method
              : "Select payment method"
          }
          errorMessage={errors.paymentMethod}
          onChange={(value) => {
            setCreditDetails((prevState) => ({
              ...prevState,
              payment_method: value,
            }));
          }}
        />

        {allow_ledger_account ? (
          <div className="mb-2">
            <LoaderHelper
              isLoading={isLoadingBankAccountsList}
              className="mt-1"
            >
              <primaryComponents.SelectField
                options={bankAccount?.data?.map((bank) => ({
                  id: bank?.id,
                  name: bank?.account_name,
                }))}
                label={"Fund Supplier From"}
                idKey="id"
                nameKey="name"
                value={
                  bankAccount
                    ? bankAccount?.data[0]?.account_name
                    : "Select Bank"
                }
                onChange={(value) => {
                  setCreditDetails((prev) => ({
                    ...prev,
                    ledger_account_id: value,
                  }));
                }}
                floatError={false}
                classNames="bank-name"
                isSearchable={false}
              />{" "}
            </LoaderHelper>
          </div>
        ) : null}

        <div className="d-flex justify-content-between mt-3">
          <primaryComponents.Button
            classNames={"btn btn--primary cover"}
            isLoading={isAddingCredit}
            isDisabled={isAddingCredit}
            loadingText={"Updating..."}
          >
            {"Update"}
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};
export default CreditForm;
