import styles from "./ExpensesLayout.module.scss";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";
import { Outlet } from "react-router-dom";

const ExpensesLayout = () => {
  return (
    <div className={`${globalStyles?.pagePaddings}  ${styles.expensesLayout}`}>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default ExpensesLayout;
