import "./TagsMultiSelect.scss";
import CreatableSelect from "react-select/creatable";
import TagsMultiSelectLogic from "./TagsMultiSelectLogic";

const styles = (bodyStyles) => {
  return {
    control: (base) => ({
      ...base,
      border: "1px solid #7647EE",
      color: "#7647EE",
      borderRadius: "5px",
      textAlign: "left",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #7647ee !important",
        boxShadow: "none",
      },
      alignItems: "center",
      flexWrap: "nowrap",
      paddingRight: "8px",
      ...bodyStyles,
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#7647ee" : null,
        color: isFocused ? "#fff" : null,
      };
    },
    valueContainer: (provided) => ({
      ...provided,
      flexWrap: "nowrap",
    }),
    multiValue: (styles) => ({
      ...styles,
      maxWidth: "70%",
      minWidth: "fit-content",
    }),
  };
};

const TagsMultiSelect = ({
  value = [],
  onChange = () => {},
  options = [],
  idKey,
  nameKey,
  isDisabled = false,
  isMulti = true,
  placeholder = "Add new tag",
  height = "30px",
  fontSize = "12px",
  isSearchable = false,
  onCreateOption = () => {},
  giveControl = true,
  name = "",
}) => {
  const { items, selectedItem } = TagsMultiSelectLogic({
    options,
    valueKey: idKey,
    labelKey: nameKey,
    value,
  });

  return (
    <div className="multi-select-dropdown">
      {value.length > 0 && (
        <div className="tool-tip">
          {value.map(({ label }) => (
            <span key={label}>{label}</span>
          ))}
        </div>
      )}
      <CreatableSelect
        isMulti={isMulti}
        styles={styles({ height: height, fontSize: fontSize })}
        placeholder={placeholder}
        components={{ DropdownIndicator: null, ClearIndicator: null }}
        options={items}
        onChange={(value, datas) => {
          onChange(value, datas);
        }}
        defaultValue={giveControl ? selectedItem : undefined}
        value={!giveControl ? value : undefined}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        onCreateOption={onCreateOption}
        name={name}
      />
    </div>
  );
};

export default TagsMultiSelect;
