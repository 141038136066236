import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    amountPaid: yup.string().required("Amount Paid is required"),
    paymentMethod: yup.string().required("Payment method is required"),
  })
  .required("All fields are required");

const Validation = ({ creditDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      amountPaid: creditDetails?.amount_paid,
      paymentMethod: creditDetails?.payment_method,
    },
  });
};

export default Validation;
