import secondaryComponents from "..";
import useAllProductsBatchTable from "./useAllProductsBatchTableLogic";
import Images from "../../../assets/images";

const AllProductsBatchTable = ({
  metaDetails,
  productsBatch,
  handleFetchProductsBatch,
  gotoPage,
}) => {
  const { instance } = useAllProductsBatchTable({
    productsBatch,
    handleFetchProductsBatch,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5 mb-5">
          <img src={Images.reconciliationEmptyState} width="250px" alt="" />
          <h4 className="font-weight-semibold text-center mt-4">
            You have no Products Batch
          </h4>
          <p className="text-center">Products Batch not found for this store</p>
        </div>
      </secondaryComponents.TableHelper>

      {productsBatch?.length > 0 && (
        <div className="mt-auto">
          <secondaryComponents.Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        </div>
      )}
    </div>
  );
};

export default AllProductsBatchTable;
