import styles from "./styles.module.scss";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { clearAllMenuCartItems } from "../../../../../store/modules/menuCart";
import ProductCart from "../ProductCart";
import useMenuCart from "./useMenuCart";
import { useEffect } from "react";
import { ProductEmpty } from "../../../../../assets/svgIcons";

const MenuCart = ({
  isActiveCart,
  setIsActiveCart,
  setShowCartItems,
  setShowCheckoutModal,
  menuObjectDetail,
}) => {
  const dispatch = useDispatch();
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);
  const { isLoadingAddBulkToCart, isLoadingGenerateToken, submitDetails } =
    useMenuCart({ setShowCheckoutModal, menuObjectDetail });

  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";

  useEffect(() => {
    menuCartItems?.forEach((item) => {
      if (!item.productId || !item.pricing_type)
        dispatch(clearAllMenuCartItems());
    });
  }, [menuCartItems]);

  // Lock background scroll when modal is active
  useEffect(() => {
    if (isActiveCart) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    // Cleanup function to re-enable scroll on modal close
    return () => {
      document.body.style.overflow = "";
    };
  }, [isActiveCart]);

  if (menuObjectDetail && menuObjectDetail.allow_payment) {
    return (
      <div className={styles.storeCart}>
        {/* cart items section */}
        <secondaryComponents.LoaderHelper isLoading={false} classNames="mt-3">
          <div
            onClick={() => setShowCartItems(false)}
            className={styles.closeContainer}
          >
            <img src={Icon.close} alt="" />
          </div>
          {/* cart header */}
          <div
            className={styles.cartHeader}
            style={{ paddingLeft: 20, paddingRight: 20 }}
            onClick={() => setIsActiveCart(!isActiveCart)}
          >
            <div className="d-flex align-items-end">
              <div style={{ position: "relative" }}>
                <img src={Icon.shoppingCart} alt="cart" />
                <span
                  style={{ background: primaryColor + "E6" }}
                  className={styles.cartCount}
                >
                  {menuCartItems?.length}
                </span>
              </div>
              <h5 className="font-weight-semibold ml-3">
                {menuCartItems?.length}{" "}
                {menuCartItems?.length > 1 ? "items" : "item"} in cart
              </h5>
            </div>
            <FontAwesomeIcon
              icon={`${isActiveCart ? "angle-down" : "angle-up"}`}
              style={{ fontSize: "20px" }}
            />
          </div>
          {menuCartItems?.length > 0 ? (
            <div
              className="ml-auto mb-1 small-text-size"
              style={{ cursor: "pointer", color: primaryColor }}
              onClick={() => dispatch(clearAllMenuCartItems())}
            >
              Clear All
            </div>
          ) : null}
          {/* cart body */}
          {menuCartItems?.find((item) => item?.productId) ? (
            <div className={styles.storeCart__items}>
              {menuCartItems?.map((product, index) => (
                <ProductCart
                  key={index}
                  product={product}
                  cartIndex={index}
                  menuObjectDetail={menuObjectDetail}
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "80%",
              }}
              className="text-center"
            >
              <ProductEmpty
                color={primaryColor}
                height={"150px"}
                width={"150px"}
              />
              <h5 className="mt-3 text-center">
                You have no products in your cart
              </h5>
              <p>When you select products, they would appear here.</p>
            </div>
          )}
        </secondaryComponents.LoaderHelper>

        <div
          className={`${styles.storeCart__bottom} ${
            !isActiveCart ? styles.active : ""
          }`}
        >
          <primaryComponents.Button
            isLoading={isLoadingGenerateToken || isLoadingAddBulkToCart}
            isDisabled={
              menuCartItems?.length === 0 ||
              isLoadingGenerateToken ||
              isLoadingAddBulkToCart
            }
            loadingText="Adding bulk to cart..."
            classNames="btn btn--primary cover"
            onClick={() => submitDetails()}
            style={{ background: primaryColor }}
          >
            Checkout {menuCartItems?.length}{" "}
            {menuCartItems?.length > 1 ? "items" : "item"}
          </primaryComponents.Button>
        </div>
      </div>
    );
  }

  return <></>;
};

export default MenuCart;
