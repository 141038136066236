import "./ServiceLevelAgreementModal.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";
import { Link } from "react-router-dom";

const ServiceLevelAgreementModal = ({
  isActive,
  setIsActive,
  setAgreeTerms,
}) => {
  return (
    <secondaryComponents.Modal
      isActive={isActive}
      width="800px"
      closeModal={() => {
        setAgreeTerms(false);
        setIsActive(false);
      }}
    >
      <div className="service-level-agreement-modal">
        <h4 className="text-center font-weight-semibold">
          Lumi POS Service Level Agreement
        </h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
          className="my-4"
        >
          <p className="dark--text">
            This Agreement is made today between ENLUMI INSIGHTS LTD and YOU(THE
            BUSINESS OWNER), The service level agreement reads as follows,
          </p>
          <div>
            <h6>1. Terms And Conditions</h6>
            <p className="dark--text">
              Please note that your use of and access to the services (as
              defined below) are subject to the following terms and conditions.
              If you do not agree to all of these terms, you may not use or
              access the services in any manner.
            </p>
          </div>
          <div>
            <h6>2. Acceptance Of Terms</h6>
            <p className="dark--text">
              These Terms and Conditions (these &quot;Terms&quot;) represent an
              agreement between you and Enlumi Insights Limited
              (&quot;Enlumi&quot;), a private limited liability company
              incorporated under the laws of the Federal Republic of Nigeria.
              These Terms contain the terms and conditions governing your use of
              and access to Enlumi’s platform (“Lumi Business”), a business
              platform that gives businesses access to a dedicated bank account
              number with which they can collect payments,manage loyalty for
              their customers, and gain interest on balances.
            </p>
            <p className="dark--text mt-3">
              These Terms are applicable to our website at{" "}
              <Link
                to="https://www.lumibusiness.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.lumibusiness.io/
              </Link>
              , mobile application, and all affiliated websites, owned and
              operated by us (collectively, the &quot;Website and App&quot;) and
              our products, services, and applications (together with the
              Website, the &quot;Services&quot;). &quot;You&quot; and
              &quot;your&quot; mean the person who uses or accesses the
              Services. &quot;We,&quot; &quot;us,&quot; and &quot;our&quot; mean
              Enlumi and its successors, affiliates, and assignees. As used in
              these Terms, &quot;Enlumi Account&quot; means the account you have
              with us for the Services.
            </p>
            <p className="dark--text mt-3">
              Your use of and access to the Services are subject at all times to
              these Terms and our Privacy Policy. Please read these Terms and
              our Privacy Policy carefully. By using or accessing the Services,
              you represent that you have read and understood these Terms and
              our Privacy Policy, and you agree to be bound by these Terms and
              our Privacy Policy. If you do not agree to all the terms and
              conditions of these Terms and our Privacy Policy, do not use or
              access the Services. Lumi Business reserves the right to update,
              revise, modify, or otherwise, reissue these Terms without prior
              notice. The continued use of your Lumi Business account after any
              amendment to the Terms constitutes your acceptance of the Terms as
              modified by such amendment.
            </p>
          </div>
          <div>
            <h6>3. Scope Of The Terms Of Use</h6>
            <p className="dark--text">
              These Terms specify the opening and use of your relevant Lumi
              Business Account. These terms together with our Privacy and Data
              Protection Policy and Anti Money Laundering Policy, constitute the
              legal relationship between you and Enlumi.
            </p>
          </div>
          <div>
            <h6>4. Nature Of Service</h6>
            <p className="dark--text">
              Enlumi is not a financial/business adviser, and the Services are
              not intended to provide financial/business advice. Your
              financial/business needs are unique. We do not make any
              representations, warranties, or guarantees of any kind that the
              Services are appropriate for you. Before using the Services, you
              should consider obtaining additional information and advice from a
              financial/business adviser.
            </p>
          </div>
          <div>
            <h6>5. Precondition For Access</h6>
            <p className="dark--text">
              As pre-conditions to benefit from the Services, you shall enter
              into an agreement with us, pass all relevant compliance procedures
              that Enlumi is subject to based on applicable laws on prevention
              of money laundering and terrorist financing, including and not
              limited to filling out Enlumi application form and submitting it
              along with the required documents, and accept our Privacy and Data
              Protection Policy. You shall read and accept the Terms of Use.
            </p>
            <p className="dark--text mt-3">
              You will be required to accept the Privacy and Data Protection
              Policy , Anti Money Laundering Policy, and the Terms and Condition
              in your first login on the Lumi Business Online Platform.
            </p>
            <p className="dark--text mt-3">
              By accepting these Terms, you hereby reiterate the representations
              that all information you have provided to us is accurate and
              truthful. You must ensure that the information provided to Enlumi
              is always accurate and up to date and inform us about any changes
              in the information and/or documents provided. We may ask you at
              any time to confirm the accuracy of your information or to provide
              documents or other evidence.
            </p>
            <p className="dark--text mt-3">
              In order to access certain features of our Services, you must
              register to create an account. (&quot;User Account&quot;). When
              you register, you will be asked to choose a password, which you
              will be required to use to access your User Account. We have
              physical, electronic, and procedural safeguards that comply with
              regulatory standards to guard Users&apos; nonpublic personal
              information. You are responsible for safeguarding your password
              and other User Account information. You agree not to disclose your
              password to any third party, and you will notify Us immediately if
              your password is lost or stolen or if you suspect any unauthorized
              use of your User Account. As a User you agree that you shall be
              solely responsible for any activities or actions under your User
              Account, whether or not you have authorized such activities or
              actions. You agree that the information you provide to us on
              account registration through our website or mobile applications
              will be true, accurate, current, and complete.
            </p>
          </div>
          <div>
            <h6>6. Applicability</h6>
            <p className="dark--text">
              These Terms are applicable when you choose to sign up on the Lumi
              Business platform and your application is successfully processed.
            </p>
          </div>
          <div>
            <h6>7. Eligibility</h6>
            <p className="dark--text">
              In order to use the Services, you must:
            </p>
            <p className="dark--text mt-3">
              (a) Accept and agree to these Terms, Anti Money Laundering Policy,
              and our Privacy Policy;
            </p>
            <p className="dark--text mt-3">
              (b) Register with us on the Website/App;
            </p>
            <p className="dark--text mt-3">
              (c) Be a Nigerian business, citizen (or a legal Nigerian resident)
              of at least 18 years of age (or older if you reside in a state
              where the majority age is older);
            </p>
            <p className="dark--text mt-3">
              (d) Submit your certificate of incorporation, details of
              directors, Bank Verification Number (“BVN”) in order to verify
              users and generate accounts
            </p>
            <p className="dark--text mt-3">
              (e) Provide all information requested by us, such as your name,
              email address, mobile device number, and such other information as
              we may request from time to time (collectively, &quot;User
              Information&quot;).
            </p>
          </div>
          <div>
            <h6>8. Compliance</h6>
            <p className="dark--text">
              For our compliance purposes and in order to provide the Services
              to you, you hereby authorize Us to, directly or through a third
              party, obtain, verify, record and store information and
              documentation that helps us verify your identity and the
              information you supply to us. When you register for the Services
              and from time to time thereafter, we may require you to provide
              and/or confirm information and documentation that will allow us to
              identify you.
            </p>
            <p className="dark--text mt-3">
              For purposes of such authorization, you hereby grant Enlumi and
              our third-party service providers a limited power of attorney, and
              you hereby appoint Enlumi and our third-party service providers as
              your true and lawful attorneys for you and in your name, place,
              and stead, in any and all capacities, to access third-party
              websites, servers, and documents; retrieve and store information;
              and use your User Information, all as described above.
            </p>
          </div>
          <div>
            <h6>9. Consent To Use Electronic Signatures And Communication</h6>
            <h6 className="mt-3">9.1 Consent</h6>
            <p className="dark--text">
              To the extent permitted by applicable law, you consent to use
              electronic signatures and to electronically receive all records,
              notices, statements, communications, and other items for all
              services provided to you under these Terms and in connection with
              your relationship with us (collectively,
              &quot;Communications&quot;) that we may otherwise be required to
              send or provide you in paper form (e.g., by mail). By accepting
              and agreeing to these Terms electronically, you represent that:
            </p>
            <p className="dark--text mt-3">
              (1) you have read and understand this consent to use electronic
              signatures and to receive Communications electronically; and
            </p>
            <p className="dark--text mt-3">
              (2) your consent will remain in effect until you withdraw your
              consent as specified below.
            </p>
          </div>
          <div>
            <h6>10. Your Right To Withdraw Your Consent</h6>
            <div className="dark--text mt-3">
              Your consent to receive Communications electronically will remain
              in effect until you withdraw it. You may withdraw your consent to
              receive further Communications electronically at any time by
              contacting us at{" "}
              <a href="mailto:help@lumibusiness.io">help@lumibusiness.io</a>. If
              you withdraw your consent to receive Communications
              electronically, we will close your Enlumi Account and you will no
              longer be able to use your Enlumi Account or the Services, except
              as expressly provided in these Terms. Any withdrawal of your
              consent to receive Communications electronically will be effective
              only after we have a reasonable period of time to process your
              withdrawal. Please note that withdrawal of your consent to receive
              Communications electronically will not apply to Communications
              electronically provided by us to you before the withdrawal of your
              consent becomes effective.
            </div>
          </div>
          <div>
            <h6>11. You Must Keep Your Contact Information Current With Us</h6>
            <p className="dark--text">
              In order to ensure that we are able to provide Communications to
              you electronically, you must notify us of any change in your email
              address and your mobile device number or other text message
              address by updating your profile on your Lumi Business account.
            </p>
          </div>
          <div>
            <h6>12. Changes</h6>
            <p className="dark--text">
              We reserve the right, in our sole discretion, to discontinue the
              provision of electronic Communications or to terminate or change
              the terms and conditions on which we provide electronic
              Communications. Except as otherwise required by applicable law, we
              will notify you of any such termination or change by updating
              these Terms on the Website/App or delivering notice of such
              termination or change electronically.
            </p>
          </div>
          <div>
            <h6>13. Confidentiality</h6>
            <p className="dark--text">
              All information and documents obtained by Enlumi or its employees,
              consultants or agents which are not published or otherwise
              publicly available shall be kept confidential and not disclosed to
              third parties or the public. This restriction shall not apply to
              any information disclosed to legal advisers, or to a third party
              to the extent that this is required, by any court of competent
              jurisdiction, or by a governmental or regulatory authority in
              Nigeria. This provision shall survive the termination of these
              Terms or the Services.
            </p>
          </div>
          <div>
            <h6>14. Anti-Money Laundering</h6>
            <p className="dark--text">
              You represent and warrant the following and shall promptly notify
              Us if any of the following ceases to be true and accurate:
            </p>
            <p className="dark--text mt-3">
              1. To the best of your knowledge based on appropriate diligence
              and investigation, none of the cash or assets that will be the
              subject of any transaction has been or shall be derived from or
              related to any activity that is deemed to be in contravention of
              The Money Laundering (Prohibition) Act 2022 or other applicable
              Money Laundering/Terrorist Financing Laws. (“AML Laws”).
            </p>
            <p className="dark--text mt-3">
              2. You represent that as an individual/business owner, you are in
              compliance with all relevant laws and regulations in Nigeria and
              you are not restricted from using any financial service products.
            </p>
            <p className="dark--text mt-3">
              Where we discover that the representations above are untrue or
              inaccurate or that our platform is used to launder money or for
              any suspicious transactions, we have an obligation to report your
              activities to the relevant authorities without recourse to you.
            </p>
          </div>
          <div>
            <h6>15. Warranty Disclaimer</h6>
            <p className="dark--text">
              To the fullest extent permitted by applicable law and except as
              otherwise expressly provided in these Terms, you expressly
              understand and agree that your use of the Services and all
              information, products, and other content (including that of third
              parties) included in or accessible from the Services is at your
              sole risk. The services are provided on an &quot;as is&quot; and
              &quot;as available&quot; basis without any warranty of any kind.
              To the maximum extent permitted by applicable law, we and our
              third-party service providers expressly disclaim any and all
              conditions, representations, and warranties of any kind as to the
              services and all information, products, and other content
              (including that of third parties) included in or accessible from
              the services, whether express, implied, statutory, or otherwise,
              including, but not limited to, any implied warranty of
              merchantability, fitness for a particular purpose, and
              non-infringement. Without limiting the foregoing, we and our
              third-party service providers make no warranty that:
            </p>
            <p className="dark--text mt-3">
              (a) the Services will meet your requirements;
            </p>
            <p className="dark--text mt-3">
              (b) the Services will be uninterrupted, timely, secure, or
              error-free;
            </p>
            <p className="dark--text mt-3">
              (c) the results that may be obtained from the use of the Services
              will be accurate or reliable;
            </p>
            <p className="dark--text mt-3">
              (d) the quality of any products, services, information, or other
              material purchased or obtained by you through the Services will
              meet your expectations; or
            </p>
            <p className="dark--text mt-3">
              (e) any errors in the Services will be corrected. No oral or
              written information or advice obtainedby you from us or our
              third-party service providers through or from the Services will
              create any warranty not expressly stated in these terms.
            </p>
            <p className="dark--text mt-3">
              Any material downloaded or otherwise obtained through the Services
              is done at your own discretion and risk, and you are solely
              responsible for any damage to your computer system or loss of data
              that results from the download of any such material.
            </p>
          </div>
          <div>
            <h6>16. Limitation Of Liability</h6>
            <p className="dark--text">
              To the fullest extent permitted by applicable law, you agree that
              Enlumi, its affiliates, and its and their respective employees,
              officers, directors, agents, and third-party service providers
              will not be liable to you or any third party for:
            </p>
            <p className="dark--text mt-3">
              (a) the performance of the Services or the inability to use the
              Services; (b) any indirect, incidental, special, consequential,
              punitive, or exemplary damages, whether based in contract, tort,
              or otherwise, including, but not limited to, damages for loss of
              profits, goodwill, use, data, or other intangible losses, even if
              such persons have been advised of the possibility of such damages,
              which arise out of or are in any way connected with these terms or
              the Services; (c) any event beyond Our reasonable control.
            </p>
          </div>
          <div>
            <h6>17. Indemnity</h6>
            <p className="dark--text">
              You hereby undertake to defend, indemnify, and hold harmless
              Enlumi, their directors, officers, employees, agents, successors
              or assigns from and against any and all claims, losses, damages,
              liabilities, costs, and expenses (including but not limited to
              reasonable legal fees) while acting in accordance with these terms
              and that are not a direct result of a breach of the terms herein
              state, bad faith, negligence, and material violation of applicable
              laws.
            </p>
          </div>
          <div>
            <h6>18. Intellectual Property</h6>
            <p className="dark--text">
              Unless otherwise stated, Enlumi and/or its licensors own the
              intellectual property rights and materials on the Lumi Business
              Platform. We do not grant you any right, licence, title, or
              interest to any of our intellectual property rights which you may
              or may not have access to.
            </p>
          </div>
          <div>
            <h6>19. Warranties And Representations</h6>
            <p className="dark--text">
              You warrant and represent that you have full power and authority
              to enter these terms;
            </p>
            <p className="dark--text mt-3">
              1. That the terms hereof do not violate any obligation by which
              you are bound, whether arising by contract or operation of law, or
              otherwise;
            </p>
            <p className="dark--text mt-3">
              2. That the assets in the User Accounts are free from all liens
              and charges; and
            </p>
            <p className="dark--text mt-3">
              3. That you shall promptly notify Enlumi if any of your
              representations or warranties in these Terms are no longer true or
              completely accurate.
            </p>
          </div>
          <div>
            <h6>20. User Obligations</h6>
            <p className="dark--text">
              Comply with all applicable laws, including, without limitation,
              privacy laws, intellectual property laws, anti-spam laws, export
              control laws, tax laws, and regulatory requirements.
            </p>
            <p className="dark--text mt-3">
              Provide accurate information to Us, whether reported directly or
              through a third party who you authorize, and keep it updated;
            </p>
            <p className="dark--text mt-3">
              Use your real name on your profile and keep your password
              confidential.
            </p>
            <p className="dark--text mt-3">
              Use the Services in a professional manner.
            </p>
          </div>
          <div>
            <h6>21. User Restrictions</h6>
            <p className="dark--text">As a User, you undertake not to;</p>
            <p className="dark--text mt-3">
              Circumvent, disable, or otherwise interfere with security-related
              features of Enlumi or features that prevent or restrict use or
              copying of any content or User information Upload, email,
              transmit, provide, or otherwise make available:
            </p>
            <p className="dark--text mt-3">
              any User information which you do not have the lawful right to
              use, copy, transmit, display, or make available (including any
              User information that would violate any confidentiality or
              fiduciary obligations that you might have with respect to the User
              information); or
            </p>
            <p className="dark--text mt-3">
              any User information that infringes the intellectual property
              rights of, or violates the privacy rights of, any third-party
              (including without limitation copyright, trademark, patent, trade
              secret, or other intellectual property right, moral right, or
              right of publicity); or
            </p>
            <p className="dark--text mt-3">
              unsolicited or unauthorized advertising, promotional materials,
              junk mail, spam, chain letters, pyramid schemes, or any other form
              of solicitation; or
            </p>
            <p className="dark--text mt-3">
              any personal information that is unlawful, obscene, harmful,
              threatening, harassing, defamatory, or hateful, or that contain
              objects or symbols of hate, invade the privacy of any third-party,
              contain nudity, are deceptive, threatening, abusive, inciting of
              unlawful action, or are otherwise objectionable in the sole
              discretion of Enlumi; or
            </p>
            <p className="dark--text mt-3">
              any personal information that contains software viruses or any
              other computer code, files, or programs designed to
            </p>
            <p className="dark--text">
              (i) interrupt, destroy, or limit the functionality of any computer
              software; or
            </p>
            <p className="dark--text">
              (ii) interfere with the access of any user, host or network,
              including without limitation overloading, flooding, spamming,
              mail-bombing, or sending a virus to Enlumi; or
            </p>
            <p className="dark--text mt-3">
              any personal information that includes code that is hidden or
              otherwise surreptitiously contained within the User information;
            </p>
            <p className="dark--text mt-3">
              Use any tags, hidden text or metadata utilizing an Enlumi name,
              trademark, URL or product name;
            </p>
            <p className="dark--text mt-3">
              In any way use Enlumi’s logo to send altered, deceptive, or false
              source- identifying information;
            </p>
            <p className="dark--text mt-3">
              Interfere with or disrupt (or attempt to interfere with or
              disrupt) any Lumi Business/Enlumi web page, server, or network, or
              the technical delivery systems of Enlumi&apos;s providers, or
              disobey any requirements, procedures, policies, or regulations of
              networks connected to Enlumi.
            </p>
            <p className="dark--text mt-3">
              Attempt to probe, scan, or test the vulnerability of any Enlumi
              system or network or breach or impair or circumvent any security
              or authentication measures protecting Enlumi;
            </p>
            <p className="dark--text mt-3">
              Attempt to decipher, decompile, disassemble, or reverse-engineer
              any of the software used by Enlumi;
            </p>
            <p className="dark--text mt-3">
              Attempt to access, search Enlumi or content thereon with any
              engine, software, tool, agent, device, or mechanism other than
              software and/or search agents provided by Enlumi or other
              generally available third-party web browsers, including without
              limitation any software that sends queries to Enlumi to determine
              how a website or web page ranks;
            </p>
            <p className="dark--text mt-3">
              Violate the terms of use or any other rule or agreement applicable
              to you or Enlumi&apos;s inclusion in, reference to, or
              relationship with any third party or third-party site or service,
              or your use of any such third-party site or service; Collect or
              store personal information about other users without their express
              permission;
            </p>
            <p className="dark--text mt-3">
              Impersonate or misrepresent your affiliation with any person or
              entity, through pretexting or some other form of social
              engineering, or commit fraud;
            </p>
            <p className="dark--text mt-3">
              Solicit any User for any investment or other commercial or
              promotional transaction;
            </p>
            <p className="dark--text mt-3">
              Violate any applicable law or regulation;
            </p>
            <p className="dark--text mt-3">
              Scrape or copy information through any means (including crawlers,
              browser plugins and add-ons, and any other technology or manual
              work);
            </p>
            <p className="dark--text mt-3">
              Use, launch, or permit to be used any automated system, including
              without limitation &quot;robots,&quot; &quot;crawlers,&quot; or
              &quot;spiders&quot;; or
            </p>
            <p className="dark--text mt-3">
              Copy or use the information, ontent or data on Enlumi in
              connection with a competitive service (as determined by Enlumi);
            </p>
            <p className="dark--text mt-3">
              Monitor Enlumi&apos;s availability, performance or functionality
              for any competitive purposes
            </p>
            <p className="dark--text mt-3">
              Use Enlumi’s website/app or content thereon in any manner not
              permitted by these Terms;
            </p>
            <p className="dark--text mt-3">
              Copyrighted Materials: If you become aware of misuse of any of
              Enlumi’s content by any person, please contact Enlumi via{" "}
              <a href="mailto:help@lumibusiness.io">help@lumibusiness.io</a> to
              report any abuse. Enlumi has adopted and implemented a policy that
              provides for the termination of the accounts of Users who infringe
              the rights of copyright holders.
            </p>
          </div>
          <div>
            <h6>22. Severability</h6>
            <p className="dark--text">
              If any provision of these Terms is found to be illegal,
              unenforceable, or invalid, that provision will be limited or
              eliminated to the minimum extent necessary so that these Terms
              will otherwise remain in full force and effect.
            </p>
          </div>
          <div>
            <h6>23. Waiver</h6>
            <p className="dark--text">
              The failure of a party to enforce any right or provision of these
              Terms will not be deemed a waiver of such right or provision.
            </p>
          </div>
          <div>
            <h6>24. Assignment</h6>
            <p className="dark--text">
              You may not assign any part of these Terms of Use (by operation of
              law or otherwise) without the prior written consent of Enlumi, and
              any prohibited assignment will be null and void. Enlumi may
              however assign these Terms or any rights hereunder without your
              consent.
            </p>
          </div>
          <div>
            <h6>25. Breaches Of These Terms</h6>
            <p className="dark--text">
              If you breach these Terms in any way. Enlumi may take such actions
              as it deems appropriate to deal with the breach, including
              suspending and prohibiting you access to your Lumi Business
              Account, and/or instituting legal proceedings against you.
            </p>
          </div>
          <div>
            <h6>26. Entire Agreement</h6>
            <p className="dark--text">
              These Terms, together with Enlumi&apos;s Privacy Policy, form the
              complete and exclusive agreement between you and Enlumi with
              respect to your use of the Lumi Business platform, and other
              subject matters contemplated by this Agreement, superseding and
              replacing any and all prior or contemporaneous agreements,
              communications, and understandings, both oral and written,
              regarding that subject matter.
            </p>
          </div>
          <div>
            <h6>27. Governing Law</h6>
            <p className="dark--text">
              These Terms shall be governed, construed, and enforced pursuant to
              the laws of the Federal Republic of Nigeria.
            </p>
          </div>
          <div>
            <h6>28. Dispute Resolution</h6>
            <p className="dark--text">
              Any controversies, claims, causes of action, disagreement,
              dispute, demands or differences howsoever arising, from these
              Terms, between you and Enlumi, including its interpretation and
              validity or as to the rights, duties and liabilities of the
              Parties hereto or as to any other matter in any way connected with
              or arising out of or in relation to the subject matter of this
              Terms of Use, which cannot be amicably resolved by the Parties
              within 30 (thirty) days shall be referred exclusively to binding
              and confidential Mediation in accordance with the Lagos Multi Door
              Courthouse Mediation Rules (“LMDC Rules”)
            </p>
            <p className="dark--text mt-3">
              Notwithstanding this agreement to mediate, either party may seek
              equitable relief in court in order to maintain status quo pending
              mediation, and each party hereby agrees to submit to the
              jurisdiction of the court for such purpose. A request for interim
              measures will not be deemed a waiver of the obligation to mediate.
            </p>
          </div>
          <div>
            <h6>29. Communication</h6>
            <p className="dark--text">
              Any notice or communication will be in writing by email
            </p>
            <p className="dark--text mt-3">
              You hereby authorize Enlumi to deliver notices required under
              these Terms by
            </p>
            <p className="dark--text mt-3">
              (a) Enlumi via email (in each case to the address that you
              provide), or
            </p>
            <p className="dark--text mt-3">
              (b) you via email to{" "}
              <a href="mailto:help@lumibusiness.io">help@lumibusiness.io</a> or
              to such other addresses as Enlumi may specify in writing. The date
              of receipt shall be deemed the date on which such notice is
              transmitted.
            </p>
            <p className="dark--text mt-3">
              You agree to notify Enlumi if you have any reason to believe your
              email address or account may have been compromised by any third
              party.
            </p>
          </div>
          <div>
            <h6>30. Termination</h6>
            <p className="dark--text">
              Either of us can end this agreement at any time, subject to all
              relevant requirements being fulfilled. We may terminate or suspend
              your access to Enlumi at any time with prior notice to you if your
              account is found to be fraudulent or in violation of any
              applicable law or regulation.
            </p>
            <p className="dark--text mt-3">
              We will fully cooperate with any law enforcement authorities or
              court order requesting or directing us to disclose the identity of
              anyone posting, publishing, or otherwise making available any User
              information, emails, or other materials that are believed to
              violate these Terms of Use. Any suspension, termination, or
              cancellation shall not affect your obligations to Enlumi under
              these Terms (including but not limited to ownership,
              indemnification, and limitation of liability), which by their
              sense and context are intended to survive such suspension,
              termination, or cancellation
            </p>
          </div>
          <div>
            <h6>31. Change Of Control</h6>
            <p className="dark--text">
              In the event that Enlumi is acquired by or merged with a
              third-party entity, we reserve the right, in any of these
              circumstances, to transfer or assign the information we have
              collected from you as part of such merger, acquisition, sale, or
              other change of control.
            </p>
          </div>
          <div>
            <h6>32. Updates, Modifications & Amendments</h6>
            <p className="dark--text">
              We may add to or terminate any of the Services or amend these
              Terms at any time, in our sole discretion, without providing
              notice to you, subject to applicable law(s). We reserve the right,
              subject to applicable law(s), to deliver to you any notice of
              changes to existing terms or the addition of new terms by posting
              an updated version of these Terms on the Website or delivering
              notice thereof to you electronically. You are free to decide
              whether or not to accept a revised version of these Terms, but
              accepting these Terms, as revised, is required for you to continue
              accessing or using the Services. If you do not agree to these
              Terms or any revised version of these Terms, your sole recourse is
              to terminate your access or use of the Services. Except as
              otherwise expressly stated by us, your access and use of the
              Services are subject to and constitute your acceptance of, the
              version of these Terms in effect at the time of your access or
              use.
            </p>
            <p className="dark--text mt-3">
              Without prejudice to the generality of the foregoing, We retain
              the right to adjust prices at any time with a 2 week prior notice
              communicated to you.
            </p>
            <p className="dark--text mt-3">
              By accepting these terms, you further agree and acknowledge that
              these Terms of Use and the rules, restrictions, and policies
              contained herein, and Enlumi&apos;s enforcement thereof, are not
              intended to confer and do not confer any rights or remedies on any
              person other than you and Enlumi.
            </p>
          </div>
          <div>
            <h6>33. Complaints And Disputes</h6>
            <p className="dark--text">
              If you have any feedback, questions or complaints, contact us via
              email at{" "}
              <a href="mailto:help@lumibusiness.io">help@lumibusiness.io</a> or
              +23408097907374. Kindly avail us the necessary information needed
              to verify your account whenever you are contacting us.
            </p>
          </div>
        </div>

        <div className="service-level-agreement-modal__actions">
          <primaryComponents.Button
            onClick={() => {
              setAgreeTerms(false);
              setIsActive(false);
            }}
            classNames={"btn btn--outline"}
            type="button"
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            type="button"
            onClick={() => {
              setAgreeTerms(true);
              setIsActive(false);
            }}
          >
            Agree
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};

export default ServiceLevelAgreementModal;
