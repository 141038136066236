import { Controller } from "react-hook-form";
import primaryComponents from "../../primaryComponents";
import styles from "./EditOrderTableForm.module.scss";
import { useFormValidation } from "./useFormValidation";
import { useEditOrderTableForm } from "./useEditOrderTableForm";

const EditOrderTableForm = ({ tableDetails, storeList, tableId }) => {
  const {
    formData,
    setFormData,
    isEditStore,
    setIsEditStore,
    isUpdatingOrderTable,
    submitTableDetails,
  } = useEditOrderTableForm({
    tableDetails,
    storeList,
    tableId,
  });

  const {
    control,
    formState: { errors },
    trigger,
    register,
    handleSubmit,
  } = useFormValidation({
    name: formData?.name,
    store: formData?.store,
    seat_capacity: formData?.seat_capacity,
  });

  return (
    <form
      onSubmit={handleSubmit(submitTableDetails)}
      className={styles.tableDetails__form}
    >
      <Controller
        name="name"
        control={control}
        defaultValue={formData.name}
        render={({ field: { onChange } }) => (
          <primaryComponents.InputField
            classNames="white"
            label="Table Label"
            name="name"
            isRequired
            value={formData.name}
            placeholder={"Enter table number or Name"}
            errorMessage={errors.name}
            onKeyUp={() => errors.name !== undefined && trigger("name")}
            onChange={({ target: { value } }) => {
              onChange(value);
              setIsEditStore(true);
              setFormData((prev) => ({ ...prev, name: value }));
            }}
            register={register}
          />
        )}
      />
      <Controller
        control={control}
        name="store"
        defaultValue={formData.store}
        render={({ field: { onChange } }) => (
          <primaryComponents.SelectField
            label="Select Stores"
            isRequired
            name="store"
            placeholder="Select store to make this table available at"
            errorMessage={errors.store}
            value={storeList.find((store) => store?.id === formData.store).name}
            options={storeList}
            idKey={"id"}
            nameKey={"name"}
            onChange={(value) => {
              onChange(value);
              setIsEditStore(true);
              setFormData((prev) => ({ ...prev, store: value }));
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="seat_capacity"
        defaultValue={formData.seat_capacity}
        render={({ field: { onChange } }) => (
          <primaryComponents.SelectField
            label="Table Capcity"
            isRequired
            name="seat_capacity"
            placeholder="Select table capcity"
            errorMessage={errors.seat_capacity}
            value={formData.seat_capacity}
            options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
            idKey={"id"}
            nameKey={"seat_capacity"}
            onChange={(value) => {
              onChange(value);
              setIsEditStore(true);
              setFormData((prev) => ({ ...prev, seat_capacity: value }));
            }}
          />
        )}
      />
      <div style={{ marginTop: "64px", alignSelf: "flex-end" }}>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          isDisabled={!isEditStore}
          isLoading={isUpdatingOrderTable}
          loadingText={"Updating..."}
        >
          Save Changes
        </primaryComponents.Button>
      </div>
    </form>
  );
};

export default EditOrderTableForm;
