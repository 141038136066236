import "./RadioInput.scss";

const RadioInput = ({
  name,
  value,
  checked,
  onChange,
  placeholder,
  rounded,
}) => {
  return (
    <label
      htmlFor={value}
      className={
        "radio__input " +
        (rounded ? "rounded " : "") +
        (checked ? "checked" : "")
      }
    >
      <input
        type="radio"
        name={name}
        id={value}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <div className="radio__input-indicator"></div>
      <p className="radio__input-label dark--text" data-testid="description">
        {placeholder}
      </p>
    </label>
  );
};

export default RadioInput;
