import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../assets/icons";
import {
  cleanObject,
  findPricingViaIdHelper,
  formatCurrency,
  truncateString,
} from "../../../helpers";
import styles from "./ProductCarting.module.scss";
import primaryComponents from "../../primaryComponents";
import { motion } from "framer-motion";
import useProductCarting from "./useProductCarting";
import { ProductCardEmptyIcon } from "../../../assets/svgIcons";
import useItemTracking from "../SellModeProductItems/ItemTrackingSelection/useItemTracking";
import ItemTrackingSelection from "../SellModeProductItems/ItemTrackingSelection";
import secondaryComponents from "..";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const ProductCarting = ({
  cartDetails,
  handleAddToCart,
  handleReduceCartItem,
  cartIndex,
  addCartItemToLocalStorage,
}) => {
  const { store_currency } = useStoreCurrency();

  const {
    quantity,
    cart_item_name,
    total_price,
    product,
    service,
    discount_rate,
    total_price_without_discount,
    custom_price,
    pricing_type_id,
  } = cartDetails;

  const {
    tagIsActive,
    customValue,
    pricingTypeId,
    setPricingTypeId,
    setCustomValue,
    setTagIsActive,
    addCustomPricing,
    pricings,
    changePricingType,
  } = useProductCarting({
    custom_price,
    addCartItemToLocalStorage,
    product,
    pricing_type_id,
  });
  const { productProperties, setProductProperties, closeModal } =
    useItemTracking();
  const isService = product?.is_service;
  const productTypeName = isService
    ? "store_service_properties"
    : "store_product_properties";
  const variant = product?.variant;
  const hasItemTracking =
    product?.[productTypeName]?.[0]?.has_inventory_items ||
    variant?.store_variant_properties?.[0]?.has_inventory_items;
  const productImage =
    product?.media_files?.[0]?.url ||
    product?.image_url ||
    service?.media_files?.[0]?.url ||
    service?.image_url;

  return (
    <>
      <motion.div
        initial={{ y: 20 }}
        animate={{ y: 0 }}
        className={styles.cartContainer}
      >
        <span className={styles.tagStatus}>
          {pricingTypeId === "custom"
            ? "Custom"
            : truncateString(
                findPricingViaIdHelper(pricingTypeId, pricings())?.pricing_type
                  ?.label,
                15,
              )}
        </span>
        <div className={styles["product"]}>
          <div className={styles["product-image"]}>
            {variant?.image_url || productImage ? (
              <img src={variant?.image_url || productImage} alt="" />
            ) : (
              <ProductCardEmptyIcon height="25" width="28" />
            )}
          </div>

          <div className={styles["product-details"]}>
            <p className={`${styles["product-name"]} dark--text`}>
              {truncateString(cart_item_name, 50)}
            </p>
            <div className={styles["product-order"]}>
              <p className={"font-weight-semibold dark--text"}>
                {formatCurrency({
                  value: total_price,
                  currencyCode: store_currency,
                  decimal: 0,
                })}{" "}
                {custom_price ? (
                  <span className="ml-2 pending--rounded">C</span>
                ) : null}
                {discount_rate > 0 &&
                (custom_price === "" ||
                  custom_price === undefined ||
                  custom_price === null) ? (
                  <span className={"error--text smallest-text-size"}>
                    <span style={{ textDecoration: "line-through" }}>
                      {formatCurrency({
                        value: total_price_without_discount,
                        currencyCode: store_currency,
                        decimal: 0,
                      })}
                    </span>{" "}
                    {discount_rate}% OFF
                  </span>
                ) : null}
              </p>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-between align-item-center mt-3"
          style={{ gap: "15px" }}
        >
          <p
            className={styles.tagBtn}
            onClick={() => setTagIsActive(!tagIsActive)}
          >
            <span>Change Pricing</span>{" "}
            <FontAwesomeIcon
              icon={`${tagIsActive ? "angle-up" : "angle-down"}`}
            />
          </p>
          <div className={styles["product-actions"]}>
            <primaryComponents.Button
              classNames={"btn btn--outline"}
              style={{ height: "40px", width: "50px" }}
              onClick={() => {
                if (!hasItemTracking)
                  return handleReduceCartItem({
                    product,
                    quantity: Number(quantity) - 1,
                    variant: cartDetails?.product?.variant,
                  });
                setProductProperties(
                  cleanObject({
                    product,
                    store_properties:
                      product?.[productTypeName]?.[0] ||
                      variant?.store_variant_properties?.[0],
                    variant,
                  }),
                );
              }}
            >
              -
            </primaryComponents.Button>

            <primaryComponents.CartInputField
              defaultValue={quantity}
              updateCart={(val) => {
                handleAddToCart({
                  product,
                  quantity: val,
                  variant: cartDetails?.product?.variant,
                });
              }}
              disabled={hasItemTracking}
              style={{ height: "40px", width: "50px" }}
            />

            <button
              className={"btn btn--primary"}
              onClick={() => {
                if (!hasItemTracking)
                  return handleAddToCart({
                    product,
                    quantity: Number(quantity) + 1,
                    variant: cartDetails?.product?.variant,
                  });
                setProductProperties(
                  cleanObject({
                    product,
                    store_properties:
                      product?.[productTypeName]?.[0] ||
                      variant?.store_variant_properties?.[0],
                    variant,
                  }),
                );
              }}
              style={{ height: "40px", width: "50px" }}
            >
              +
            </button>
            <primaryComponents.Button
              className={"btn btn--outline-red"}
              onClick={() =>
                handleReduceCartItem({
                  product,
                  quantity: 0,
                  variant: cartDetails?.product?.variant,
                })
              }
              style={{ height: "40px", width: "50px" }}
            >
              <img src={Icon.deleteIcon} alt="" />
            </primaryComponents.Button>
          </div>
        </div>

        {tagIsActive && (
          <div className={styles.tagSection}>
            <h6>Select pricing for this item</h6>
            <div className="d-flex flex-column mt-2" style={{ gap: "10px" }}>
              {pricings()?.map((pricingItem, pricingIndex) => (
                <label
                  key={pricingIndex}
                  htmlFor={`pricingType${cartIndex}${pricingIndex}`}
                  className="d-flex align-items-center"
                  style={{ gap: "7px" }}
                >
                  <primaryComponents.RadioButton
                    name={`pricingType${cartIndex}`}
                    id={`pricingType${cartIndex}${pricingIndex}`}
                    value={pricingItem?.pricing_type?.id}
                    checked={
                      `${pricingTypeId}` === `${pricingItem?.pricing_type?.id}`
                    }
                    setRadioValue={(val) => {
                      setPricingTypeId(val);
                      changePricingType(cartIndex, val);
                    }}
                  />
                  <span>{pricingItem?.pricing_type?.label}</span>
                </label>
              ))}

              <label
                key={cartIndex}
                htmlFor={`pricingTypeCustom${cartIndex}`}
                className="d-flex align-items-center"
                style={{ gap: "7px" }}
              >
                <primaryComponents.RadioButton
                  name={`pricingType${cartIndex}`}
                  id={`pricingTypeCustom${cartIndex}`}
                  value={"custom"}
                  checked={pricingTypeId === "custom"}
                  setRadioValue={(val) => {
                    setPricingTypeId(val);
                  }}
                />
                <span>Custom</span>
              </label>
            </div>

            {pricingTypeId === "custom" ? (
              <div className={`${styles.tagSection}`}>
                <p className="font-weight-normal mb-2">
                  Enter custom price per unit
                </p>
                <primaryComponents.InputField
                  placeholder="NGN 0.00"
                  value={customValue}
                  type="number"
                  step={0.01}
                  onChange={({ target: { value } }) => {
                    setCustomValue(value);
                  }}
                />
                <div className={styles.btnContainer}>
                  <primaryComponents.Button
                    classNames="btn btn--primary smallBtn ml-auto"
                    onClick={() => addCustomPricing(cartIndex)}
                  >
                    Confirm
                  </primaryComponents.Button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </motion.div>
      <secondaryComponents.Modal
        isActive={productProperties !== null}
        width={550}
        closeModal={closeModal}
      >
        <ItemTrackingSelection
          closeModal={closeModal}
          productProperties={productProperties}
          cartDetails={cartDetails}
          handleAddToCart={handleAddToCart}
          handleReduceCartItem={handleReduceCartItem}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default ProductCarting;
