import { useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { clearOrderTable } from "../../../services/inventory";
import { ToastContext } from "../../../hooks/context";

const useTableCard = ({ refetchOrderTables }) => {
  const triggerToast = useContext(ToastContext);

  const [showMenu, setShowMenu] = useState(false);
  const popMenuRef = useRef(null);

  const { isLoading, mutate } = useMutation({
    mutationFn: (id) => clearOrderTable(id),
    onSuccess: (data) => {
      refetchOrderTables();
      triggerToast(data.data.detail, "success");
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popMenuRef.current && !popMenuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
  }, [popMenuRef]);

  return {
    showMenu,
    setShowMenu,
    popMenuRef,
    isLoading,
    mutate,
  };
};

export default useTableCard;
