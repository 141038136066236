export const ProductCardEmptyIcon = () => {
  return (
    <svg
      width="106"
      height="94"
      viewBox="0 0 106 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4950_28771)">
        <path
          d="M81.8307 76.4956L24.4766 76.4325C21.1661 76.4199 18.4888 73.7325 18.4717 70.4479L18.4814 15.6486C18.4903 12.342 21.1778 9.6709 24.4663 9.6575L81.8205 9.72067C85.1309 9.73326 87.8082 12.4206 87.8253 15.7052L87.8156 70.5045C87.8067 73.8111 85.1192 76.4822 81.8307 76.4956Z"
          fill="white"
        />
        <path
          d="M24.4781 76.0119L81.8289 76.075C81.8294 76.075 81.8298 76.075 81.8302 76.075C84.8871 76.0619 87.3862 73.5778 87.3944 70.5033L87.4042 15.7073C87.4042 15.7069 87.4042 15.7065 87.4042 15.7061C87.3876 12.6527 84.8979 10.1536 81.8201 10.1413L24.4781 76.0119ZM24.4781 76.0119C24.4777 76.0119 24.4774 76.0119 24.477 76.0119C21.3992 75.9996 18.9094 73.5005 18.8929 70.447C18.8929 70.4466 18.8929 70.4462 18.8929 70.4458L18.9026 15.6498C18.9026 15.6497 18.9026 15.6496 18.9026 15.6495C18.911 12.575 21.4101 10.0911 24.4671 10.0781C24.4674 10.0781 24.4678 10.0781 24.4681 10.0781L81.819 10.1412L24.4781 76.0119Z"
          stroke="#C5C5C5"
          strokeWidth="0.84173"
        />
      </g>
      <path
        d="M78.6702 68.2889L28.251 68.2532C26.8248 68.2541 25.6863 67.1203 25.6838 65.6958L25.6767 20.4067C25.6742 18.9821 26.808 17.8462 28.2342 17.8453L78.6534 17.881C80.0796 17.8802 81.2181 19.0139 81.2206 20.4384L81.2277 65.7276C81.2302 67.1521 80.0964 68.288 78.6702 68.2889Z"
        fill="#E4DAFC"
      />
      <path
        d="M45.2607 36.0498C46.7251 34.8129 46.9071 32.6233 45.6671 31.1591C44.4271 29.695 42.2347 29.5108 40.7703 30.7477C39.3058 31.9846 39.1239 34.1742 40.3639 35.6383C41.6038 37.1025 43.7962 37.2867 45.2607 36.0498Z"
        fill="#7647EE"
      />
      <path
        d="M54.3645 51.0784L36.1706 51.0572L40.7184 45.9312L42.9923 43.3683L45.2662 40.8053L47.5408 43.3735L49.8153 45.9418L54.3645 51.0784Z"
        fill="#FACE8A"
      />
      <path
        d="M46.2446 41.9021L45.7672 41.6233L45.494 41.871L44.8601 41.6762L44.2947 41.8998L44.7821 41.3504L45.0258 41.0757L45.2695 40.8011L45.5133 41.0763L45.7571 41.3516L46.2446 41.9021Z"
        fill="white"
      />
      <path
        d="M69.6855 51.1076L43.1717 51.0768L49.7992 43.6067L53.1129 39.8717L56.4266 36.1367L59.7414 39.8794L63.0561 43.6221L69.6855 51.1076Z"
        fill="#A3DFBC"
      />
      <path
        d="M57.8518 37.7427L57.1561 37.3365L56.7579 37.6974L55.8342 37.4135L55.0102 37.7394L55.7205 36.9387L56.0756 36.5384L56.4308 36.1381L56.786 36.5393L57.1413 36.9404L57.8518 37.7427Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4950_28771"
          x="0.681679"
          y="0.757714"
          width="104.934"
          height="102.418"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="8.89" />
          <feGaussianBlur stdDeviation="8.895" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4950_28771"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4950_28771"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
