import { useState } from "react";
import { useSetUpGuideMarkAsCompleted } from "../../../hooks/api/mutations/useSetupGuide";
const GetStartedLogic = () => {
  const [showDropdown, setShowDropdown] = useState(null);
  const { mutate } = useSetUpGuideMarkAsCompleted();

  const RefreshSetUpGuide = (queries) => {
    mutate(queries);
  };

  return {
    showDropdown,
    setShowDropdown,
    RefreshSetUpGuide,
  };
};

export default GetStartedLogic;
