import "./TerminalsLayout.scss";
import { Outlet } from "react-router-dom";
import globalStyles from "../../assets/styles/base/globalPage.module.scss";

const TerminalsLayout = () => {
  return (
    <div className={`${globalStyles?.pagePaddings} terminals-layout`}>
      <Outlet />
    </div>
  );
};

export default TerminalsLayout;
