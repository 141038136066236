import { useState } from "react";
import {
  useAddRiderMutation,
  useUpdateRiderMutation,
} from "../../../hooks/api/mutations/useRiders";
import { useSelector } from "react-redux";

const useRiderForm = ({
  isEditMode = false,
  riderInfo,
  handleFetchRiders = () => {},
  closeModal,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [riderDetails, setRiderDetails] = useState({
    name: riderInfo?.name || "",
    email: riderInfo?.email || "",
    phone_number: riderInfo?.phone_number || "",
    phone_code: riderInfo?.phone_code || business_phone_code || "",
  });
  const updateRider = useUpdateRiderMutation();
  const addRider = useAddRiderMutation();

  const handleRiderSubmit = () => {
    if (isEditMode)
      updateRider.mutate(
        {
          id: riderInfo?.id,
          data: riderDetails,
        },
        { onSuccess: () => onSuccess() },
      );
    else
      addRider.mutate(
        {
          ...riderDetails,
        },
        { onSuccess: () => onSuccess() },
      );
  };

  const onSuccess = () => {
    handleFetchRiders();
    closeModal();
  };

  return {
    riderDetails,
    isLoading: addRider?.isLoading || updateRider?.isLoading,
    setRiderDetails,
    handleRiderSubmit,
  };
};
export default useRiderForm;
