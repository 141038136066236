import { useParams } from "react-router-dom";
import { useGetStoreDetails } from "../../../../../hooks/api/queries";
import {
  useGetCountries,
  useGetCountryDetails,
} from "../../../../../hooks/api/queries/useBusiness";
import { useEffect, useState } from "react";

const useEditStore = () => {
  const params = useParams();
  const { isLoading, data: storeInfo } = useGetStoreDetails(params?.id);
  const { data: countries, isLoading: isLoadingCountries } = useGetCountries();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { data: states, isLoading: isLoadingStates } =
    useGetCountryDetails(selectedCountry);
  const [loadingStateCount, setLoadingStateCount] = useState(0);

  useEffect(() => {
    if (storeInfo?.countries?.[0]?.id)
      setSelectedCountry(storeInfo?.countries?.[0]?.id);
    else setLoadingStateCount((prev) => prev + 1);
  }, [storeInfo]);

  useEffect(() => {
    if (loadingStateCount === 0 && states)
      setLoadingStateCount((prev) => prev + 1);
  }, [states]);

  return {
    isLoadingStates,
    countries,
    states: states?.states,
    storeInfo,
    isLoading: isLoading || isLoadingCountries || loadingStateCount === 0,
    setSelectedCountry,
  };
};

export default useEditStore;
