import { getBeneficiaries } from "../../../../services/transfers";
import { useEffect, useState } from "react";

const BeneficiariesLogic = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [currentBeneficiaries, setCurrentBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue] = useState("");

  useEffect(() => {
    fetchBeneficiaries();
  }, []);

  const fetchBeneficiaries = () => {
    setIsLoading(true);
    getBeneficiaries()
      .then((response) => {
        setBeneficiaries(response.data.data);
        setCurrentBeneficiaries(response.data.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const searchBeneficary = (value) => {
    let newBeneficiaries = beneficiaries.filter((item) =>
      item.account_name.toLowerCase().includes(value.toLowerCase()),
    );
    setCurrentBeneficiaries(newBeneficiaries);
  };

  return {
    fetchBeneficiaries,
    isLoading,
    searchBeneficary,
    searchValue,
    currentBeneficiaries,
  };
};
export default BeneficiariesLogic;
