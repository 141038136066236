import { useState } from "react";

export const SectionCardLogic = () => {
  const [sectionDetails, setSectionDetails] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [isEnabledPrint, setIsEnabledPrint] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [isTerminal, setIsTerminal] = useState(false);

  return {
    sectionDetails,
    orderId,
    isEnabledPrint,
    isPrintModal,
    isTerminal,
    setIsTerminal,
    setIsPrintModal,
    setIsEnabledPrint,
    setOrderId,
    setSectionDetails,
  };
};
