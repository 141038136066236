import { useGetPricingTypes } from "../../../hooks/api/queries/useInventory";
import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { getRandomIntInclusive } from "../../../helpers";
import { useBulkUpdateProductPrice } from "../../../hooks/api/mutations/useInventory";
import { useParams, useSearchParams } from "react-router-dom";

const BulkEditProductLogic = ({
  selectedProducts,
  closeModal,
  handleFetchproducts,
  setSelectedProducts,
}) => {
  const { data: pricingTypes, isLoading: isLoadingPricingTypes } =
    useGetPricingTypes();
  const [controlPrice, setControlPrice] = useState();
  const triggerToast = useContext(ToastContext);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const createBulkUpdateProductPriceMutation = useBulkUpdateProductPrice();
  const { store_id } = useParams();
  const [search] = useSearchParams();
  const [confirm, setConfirm] = useState(false);

  const [productsDetails, setProductsDetails] = useState({
    store_id: search.get("store_id") || store_id,
    pricing_type_id: "",
    percentage_change: "",
    items: selectedProducts,
  });

  const handleBulkEditProduct = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...productsDetails };
    createBulkUpdateProductPriceMutation.mutate(data, {
      onSuccess: () => {
        handleFetchproducts();
        setSelectedProducts([]);
        triggerToast("Product price updated successfully", "success");
        closeModal();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  return {
    pricingTypes,
    isLoadingPricingTypes,
    createBulkUpdateProductPriceMutation,
    productsDetails,
    controlPrice,
    confirm,
    setConfirm,
    handleBulkEditProduct,
    setControlPrice,
    setProductsDetails,
  };
};

export default BulkEditProductLogic;
