/* eslint-disable jsx-a11y/anchor-is-valid */
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { motion } from "framer-motion";
// import styles from "./TrackItem.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import useTrackItem from "./useTrackItem";
import "./TrackItem.scss";

const TrackItem = () => {
  const navigate = useNavigate();
  const {
    search,
    storeList,
    isFetchingTrackItems,
    trackedItems,
    metaDetails,
    productDetails,
    filterByVariant,
    filterByStatus,
    filterByStore,
    gotoPage,
    searchTrackItem,
  } = useTrackItem();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Loyalty - All campaigns"
      description="View campaigns created"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps} ${globalStyles.pagePaddings} trackItem`}
        >
          {/* back btn */}
          <div className="d-flex align-items-center">
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>

            <h4 className="ml-3 mb-0">Track Item</h4>
          </div>

          <div className={globalStyles.rowNavigations}>
            <a
              className={`${globalStyles.rowNavigations__item} ${
                search.get("status") === "available" ? globalStyles.active : ""
              }`}
              onClick={() => filterByStatus("available")}
            >
              Available Stock
            </a>
            <a
              className={`${globalStyles.rowNavigations__item} ${
                search.get("status") === "sold" ? globalStyles.active : ""
              }`}
              onClick={() => filterByStatus("sold")}
            >
              Sold Stock
            </a>
          </div>

          <div>
            <div className="d-flex align-items-center">
              <h4 className="mr-3 mb-0">{productDetails?.name}</h4>
              {productDetails?.variants?.length > 0 &&
              search.get("variant_id") ? (
                <div style={{ width: "160px" }}>
                  <primaryComponents.SelectField
                    options={productDetails?.variants}
                    idKey="id"
                    nameKey="value"
                    onChange={(value) => {
                      filterByVariant(value);
                    }}
                    value={
                      productDetails?.variants?.find(
                        (item) =>
                          `${item.id}` === `${search.get("variant_id")}`,
                      )?.value || "Select variant"
                    }
                    floatError={false}
                  />
                </div>
              ) : null}
            </div>
            <p>{metaDetails?.total} tracking number</p>
          </div>

          {/* table section */}
          <div className={globalStyles.tableWrapper}>
            <div className={globalStyles.tableHeader}>
              <primaryComponents.SearchField
                classNames={globalStyles.searchField}
                placeholder="Search tracking code"
                trigger={(value) => searchTrackItem(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
                triggerOnInput={true}
                showsearchBtn={true}
              />

              {!isFetchingTrackItems ? (
                <div className="ml-auto" style={{ width: "160px" }}>
                  <primaryComponents.SelectField
                    options={storeList}
                    idKey="id"
                    nameKey="name"
                    onChange={(value) => {
                      filterByStore(value);
                    }}
                    value={
                      storeList?.find(
                        (item) => `${item.id}` === search.get("store_id"),
                      )?.name || "Select store"
                    }
                    floatError={false}
                  />
                </div>
              ) : null}
            </div>

            <secondaryComponents.LoaderHelper
              isLoading={isFetchingTrackItems}
              classNames="mt-5"
            >
              <secondaryComponents.TrackItemTable
                metaDetails={metaDetails}
                trackedItems={trackedItems}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default TrackItem;
