import "./KYBVerificationResponseWidget.scss";
import primaryComponents from "../../primaryComponents";
import KYBVerificationResponseLogic from "./KYBVerificationResponseLogic";

const KYBVerificationResponseWidget = () => {
  const { handleClick } = KYBVerificationResponseLogic();

  return (
    <div className="kyb-response-widget">
      <h3 className="font-weight-semibold purple--text">Thank You</h3>
      <p className="text-center mt-3">
        We are currently reviewing your application. However we have provided
        you with a unique account number to receive funds, pending approval of
        your verification
      </p>
      <primaryComponents.Button
        classNames={"btn btn--primary mt-4"}
        onClick={() => handleClick()}
      >
        View Account Number
      </primaryComponents.Button>
    </div>
  );
};

export default KYBVerificationResponseWidget;
