import "./Profile.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import { NavLink, Outlet } from "react-router-dom";
import { pathConstants } from "../../../../routes/pathContants";

const Profile = () => {
  return (
    <div className={`${globalStyles.pageColumnGaps}`}>
      <div className={`${globalStyles.pageHeaders}`}>
        <div>
          <h4 className="mb-0">Profile</h4>
          <p>View and update your personal and business information</p>
        </div>
      </div>

      <div className={globalStyles.rowNavigations}>
        <NavLink
          to={pathConstants.SETTINGS_PROFILE}
          className={({ isActive }) =>
            `${globalStyles.rowNavigations__item} ${
              isActive ? globalStyles.active : ""
            } dark--text`
          }
        >
          Personal Information
        </NavLink>

        <NavLink
          className={({ isActive }) =>
            `${globalStyles.rowNavigations__item} ${
              isActive ? globalStyles.active : ""
            } dark--text`
          }
          to={pathConstants.SETTINGS_BUSINESS_PROFILE}
        >
          Business Information
        </NavLink>
      </div>

      <Outlet />
    </div>
  );
};

export default Profile;
