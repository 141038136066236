import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const OrderType = {
  DINE_IN: "DINE_IN",
  DELIVERY: "DELIVERY",
};

const MenuValidationSchema = yup.object().shape(
  {
    name: yup.string().required(),
    phoneNumber: yup
      .string()
      .required()
      .when("phoneNumber", {
        is: (val) => val?.length,
        then: yup
          .string()
          .matches(/^[0-9]+$/, "Phone number must contain only digits")
          .test(
            "num",
            "Invalid phone number format",
            (val) => !val.startsWith("0"),
          )
          .min(10, "Invalid phone number format")
          .max(10, "Invalid phone number format"),
      }),
    email: yup
      .string()
      .required()
      .nullable("Email is required")
      .email("Invalid email address")
      .max(40, "Maximum of 30 characters is allowed"),
    orderType: yup.mixed().oneOf(Object.values(OrderType)),
    country: yup.string().when("orderType", {
      is: OrderType.DELIVERY,
      then: yup.string().required("Country is a required field"),
      otherwise: yup.string().notRequired(),
    }),
    state: yup.string().when("orderType", {
      is: OrderType.DELIVERY,
      then: yup.string().required("This field is required"),
      otherwise: yup.string().notRequired(),
    }),
    streetAddress: yup.string().when("orderType", {
      is: OrderType.DELIVERY,
      then: yup.string().required("This field is required"),
      otherwise: yup.string().notRequired(),
    }),
  },
  ["phoneNumber", "phoneNumber"],
);

const Validation = (
  name,
  email,
  phoneNumber,
  country,
  state,
  streetAddress,
  orderType,
) => {
  return useForm({
    resolver: yupResolver(MenuValidationSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      name,
      email,
      phoneNumber,
      country,
      state,
      streetAddress,
      orderType,
    },
  });
};

export default Validation;
