import primaryComponents from "..";
import "./BoxedRadioInput.scss";

const BoxedRadioInput = ({
  children,
  name,
  value,
  id,
  isChecked,
  setRadioValue,
  isDisabled = false,
  classNames = "",
  ...rest
}) => {
  const handleChange = () => {
    if (!isDisabled && setRadioValue) {
      setRadioValue(value);
    }
  };

  return (
    <label
      className={`boxed-radio ${isChecked ? "active" : ""} ${
        isDisabled ? "disabled" : ""
      } ${classNames}`}
      onClick={handleChange}
      {...rest}
    >
      <primaryComponents.RadioButton
        name={name}
        value={value}
        id={id}
        checked={isChecked}
        isDisabled={isDisabled}
        onChange={handleChange}
      />
      <span>{children}</span>
    </label>
  );
};

export default BoxedRadioInput;
