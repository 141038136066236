/* eslint-disable react/jsx-key */
import useStoreWalletsTable from "./useStoreWalletsTable";
import secondaryComponents from "../..";
import Images from "../../../../assets/images";

const StoreWalletsTable = ({ storeWallets, metaDetails, gotoPage }) => {
  const {
    isFundWallet,
    isDebitWallet,
    setIsFundWallet,
    setIsDebitWallet,
    instance,
  } = useStoreWalletsTable({ storeWallets });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5 mb-5">
          <img src={Images.storeWalletEmptyState} width="250px" alt="" />
          <h4 className="font-weight-semibold text-center">
            No Data Available
          </h4>
        </div>
      </secondaryComponents.TableHelper>

      {/* pagination */}
      {storeWallets?.length > 0 && (
        <div className="mt-auto">
          <secondaryComponents.Pagination
            totalPageNumber={metaDetails?.last_page}
            handlePageClick={(value) => gotoPage(value?.selected + 1)}
            currentPage={metaDetails?.current_page}
          />
        </div>
      )}

      <secondaryComponents.SideModal
        isActive={isFundWallet !== null}
        closeModal={() => setIsFundWallet(null)}
        width={600}
      >
        <secondaryComponents.StoreWalletFund
          setIsFundWallet={setIsFundWallet}
          customerDetails={isFundWallet}
        />
      </secondaryComponents.SideModal>

      <secondaryComponents.SideModal
        isActive={isDebitWallet !== null}
        closeModal={() => setIsDebitWallet(null)}
        width={600}
      >
        <secondaryComponents.StoreWalletDebit
          setIsDebitWallet={setIsDebitWallet}
          customerDetails={isDebitWallet}
        />
      </secondaryComponents.SideModal>
    </>
  );
};

export default StoreWalletsTable;
