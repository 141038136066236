import { useState, useId } from "react";
import { useCombobox } from "downshift";
import { useSpinDelay } from "spin-delay";

import { getProductList } from "../../../services/inventory";

export const useProductComboBox = ({ onChange }) => {
  const id = useId();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const cb = useCombobox({
    id,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem);
      setSelectedProduct(selectedItem);
    },
    items: products,
    itemToString: (item) => (item ? item?.name : ""),
    onInputValueChange: (changes) => {
      if (changes.inputValue) {
        handleFetchProducts(changes.inputValue);
      }
    },
  });

  const displayMenu = cb.isOpen && products.length > 0;
  const showSpinner = useSpinDelay(isLoading, {
    delay: 150,
    minDuration: 500,
  });

  const handleFetchProducts = (query) => {
    setIsLoading(true);
    getProductList(`?query=${query}`)
      .then((response) => {
        setProducts(response.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return { cb, products, selectedProduct, isLoading, displayMenu, showSpinner };
};
