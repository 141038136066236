import "./UnverifiedAccountWidget.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import Images from "../../../assets/images";
import { useNavigate } from "react-router-dom";
import { getPlatform } from "../../../helpers";
import { platformHandler } from "../../../hooks/context";
import { useContext } from "react";

const UnverifiedAccountWidget = ({
  message,
  verification_status = "unverified",
}) => {
  const navigate = useNavigate();
  const { platform } = useContext(platformHandler);

  return (
    <div className="unverified-account-widget">
      <img src={Images.error_img} alt="" />
      <h4>
        {verification_status === "unverified" && "UNVERIFIED ACCOUNT"}
        {verification_status === "pending_review" && "VERIFICATION PENDING"}
      </h4>
      <p style={{ textAlign: "center" }}>{message}</p>
      <secondaryComponents.NavigateWithPermission
        permissionParentKey={"Settings"}
        permissionChildKey="can_submit_bvn_and_kyb"
      >
        <primaryComponents.Button
          onClick={() => navigate(getPlatform(platform) + "/kyb-verification")}
          classNames={"btn btn--primary"}
        >
          Verify Account
        </primaryComponents.Button>
      </secondaryComponents.NavigateWithPermission>
    </div>
  );
};

export default UnverifiedAccountWidget;
