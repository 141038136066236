/* eslint-disable react/jsx-key */
import "./SettlementsTable.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import useSettlementsTableLogic from "./SettlementsTableLogic";
import { motion } from "framer-motion";
import { Fragment } from "react";

const SettlementsTable = ({ setHasAccount }) => {
  const {
    instance,
    isFetchingAccounts,
    accountList,
    isOpenDeleteDialog,
    isDeletingAccount,
    selectedAccount,
    isOpenEditDialog,
    isOpenSettlementForm,
    setSelectedAccount,
    setIsOpenSettlementForm,
    setIsOpenEditDialog,
    setIsOpenDeleteDialog,
    handleDeleteSettlementAccount,
    handleFetchSettlementAccounts,
  } = useSettlementsTableLogic(setHasAccount);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3 } }}
      exit={{ opacity: 0 }}
    >
      <div
        style={{
          background: accountList.length <= 0 ? "none" : "white",
        }}
        className="settlements__table"
      >
        <secondaryComponents.LoaderHelper
          classNames={"mt-3"}
          isLoading={isFetchingAccounts}
        >
          {accountList.length > 0 && (
            <Fragment>
              <div className="settlements__table__header">
                <primaryComponents.Button
                  onClick={() => setIsOpenSettlementForm(true)}
                  classNames={"btn btn--primary"}
                >
                  Add New Bank
                </primaryComponents.Button>
              </div>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          className="font-weight-semibold"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps({ key: i })}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="mobile__cards">
                {accountList.length > 0 &&
                  accountList.map((account) => (
                    <div key={account.id} className="mobile__card">
                      <p>
                        <span>Bank Name</span>
                        <span>{account?.bank_name}</span>
                      </p>
                      <p>
                        <span>Account Number</span>
                        <span>{account?.decrypted_nuban}</span>
                      </p>
                      <p>
                        <span>Account Name</span>
                        <span>{account?.account_name}</span>
                      </p>
                      <p>
                        <span>Currency</span>
                        <span>{account?.currency}</span>
                      </p>
                      <div className="mobile__card__actions">
                        <primaryComponents.Button
                          onClick={() => {
                            setSelectedAccount(account);
                            setIsOpenEditDialog(true);
                          }}
                          classNames={"btn btn--primary"}
                        >
                          Edit
                        </primaryComponents.Button>
                        <primaryComponents.Button
                          onClick={() => {
                            setSelectedAccount(account);
                            setIsOpenDeleteDialog(true);
                          }}
                          classNames={"btn btn--danger"}
                        >
                          Delete
                        </primaryComponents.Button>
                      </div>
                    </div>
                  ))}
              </div>
            </Fragment>
          )}

          {isOpenDeleteDialog && (
            <secondaryComponents.ConfirmDialog
              title="Are you sure you want to delete this
                                account?"
              isOpen={isOpenDeleteDialog}
              setIsOpen={setIsOpenDeleteDialog}
              handler={handleDeleteSettlementAccount}
              isLoading={isDeletingAccount}
            >
              <div
                style={{
                  backgroundColor: "#F3F1FC",
                  padding: "16px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  borderRadius: "8px",
                }}
              >
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span className="dark--text font-weight-semibold mb-1">
                    Account Name
                  </span>
                  <span>{selectedAccount.account_name}</span>
                </p>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span className="dark--text font-weight-semibold mb-1">
                    Account Number
                  </span>
                  <span>{selectedAccount.decrypted_nuban}</span>
                </p>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span className="dark--text font-weight-semibold mb-1">
                    Bank Name
                  </span>
                  <span>{selectedAccount.bank_name}</span>
                </p>
              </div>
            </secondaryComponents.ConfirmDialog>
          )}

          {isOpenEditDialog && (
            <secondaryComponents.EditSettlementAccount
              isOpen={isOpenEditDialog}
              setIsOpen={setIsOpenEditDialog}
              settlementAccount={selectedAccount}
              handleFetchSettlementAccounts={handleFetchSettlementAccounts}
            />
          )}
        </secondaryComponents.LoaderHelper>
        {isOpenSettlementForm && (
          <secondaryComponents.AddSettlementAccount
            isOpen={isOpenSettlementForm}
            setIsOpen={setIsOpenSettlementForm}
            handleFetchSettlementAccounts={handleFetchSettlementAccounts}
          />
        )}
      </div>
    </motion.div>
  );
};

export default SettlementsTable;
