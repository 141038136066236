import "./RevenueChart.scss";
import {
  AreaChart,
  XAxis,
  Area,
  CartesianGrid,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Images from "../../../assets/images";
import RevenueChartLogic from "./RevenueChartLogic";
import RevenueChartTooltip from "../RevenueChartTooltip/RevenueChartTooltip";

const RevenueChart = ({ revenue_summary }) => {
  const { tickFormatter, yAxisFormat } = RevenueChartLogic();
  return (
    <>
      {revenue_summary.length > 0 ? (
        <ResponsiveContainer
          width={"100%"}
          aspect={2}
          className="revenue-chart"
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <AreaChart data={revenue_summary} height={250} width="auto">
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#7647EE" stopOpacity={0.8} />
                <stop offset="90%" stopColor="#7647EE" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              dataKey="total_revenue"
              stroke="#7647EE"
              fillOpacity={0.2}
              fill="url(#colorUv)"
              // dot={{ stroke: "#7647EE", strokeWidth: 1, r: 2, strokeDasharray:""}}
            />
            <CartesianGrid
              stroke="#E8E9EB"
              strokeDasharray="5 0"
              horizontal={true}
              vertical={false}
            />
            <YAxis
              tick={{ fontSize: "10px" }}
              dataKey="total_revenue"
              tickLine={false}
              axisLine={false}
              tickFormatter={yAxisFormat}
              // label={{ value: "Revenue (₦)", angle: -90, position: "insideLeft", textAnchor: "middle", fontSize: "12px" }}
            />
            <XAxis
              dataKey="date"
              tickFormatter={tickFormatter}
              tickLine={false}
              tick={{ fontSize: "10px" }}
              axisLine={false}
              // label={{ value: "Day", offset: 0, position: "insideBottom", fontSize: "12px" }}
            />
            <Tooltip
              wrapperStyle={{ border: "none", outline: "none" }}
              content={<RevenueChartTooltip />}
            />
          </AreaChart>
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginTop: "20px",
          }}
        >
          <img
            width={"150px"}
            src={Images.transaction_empty_state}
            alt="No transactions"
          />
          <p className="font-weight-semibold mt-3 dark--text">
            No data available
          </p>
        </div>
      )}
    </>
  );
};

export default RevenueChart;
