import { useMemo } from "react";
import { useTable } from "react-table";
import { formatCurrency } from "../../../helpers";
import useOrderWidget from "../../../hooks/useOrderWidget";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const useSalesWidget = () => {
  const {
    isFetchingOrders,
    orders,
    metaDetails,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
    handleRenderStatusIndicator,
  } = useOrderWidget({ orderSource: "INTERNAL" });
  const { store_currency } = useStoreCurrency();

  const columns = useMemo(
    () => [
      {
        Header: "Order Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined
              ? "N/A"
              : new Date(value).toLocaleDateString("en-GB")}
          </span>
        ),
      },
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <span
            onClick={() => {
              setSelectedOrder(value);
            }}
            style={{
              color: "#7647EE",
              height: "28px",
              cursor: "pointer",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Customer Name",
        accessor: "customer",
        Cell: ({ cell: { value } }) =>
          value === null ? "N/A" : value?.full_name,
      },
      {
        Header: "Cashier",
        accessor: "cashier",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined ? "N/A" : value?.first_name}
          </span>
        ),
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => (
          <span>
            {formatCurrency({ value: value, currencyCode: store_currency })}
          </span>
        ),
      },
      {
        Header: "Payment Type",
        accessor: "payment_method",
        Cell: ({ cell: { value } }) => (
          <span>{value === null ? "N/A" : value}</span>
        ),
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell: { value } }) => <span>{value?.name || "N/A"}</span>,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              display: "block",
              ...handleRenderStatusIndicator(value),
              borderRadius: "8px",
              padding: "10px 16px",
              height: "28px",
              width: "100px",
              maxWidth: "100px",
              fontSize: "12px",
              textAlign: "center",
            }}
          >
            {value}
          </span>
        ),
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: orders || [] });

  return {
    instance,
    isFetchingOrders,
    orders,
    metaDetails,
    selectedOrder,
    setSelectedOrder,
    gotoPage,
    handleSearchOrders,
    handleFilterOrderByStatus,
  };
};

export default useSalesWidget;
