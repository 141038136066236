import { Controller } from "react-hook-form";
import InputField from "../InputField/InputField";

const InputFieldV2 = ({
  onChange = () => {},
  name = "",
  control,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onControllerChange } }) => (
        <InputField
          name={name}
          onChange={(e) => {
            onControllerChange(e?.target?.value);
            onChange(e);
          }}
          {...props}
        />
      )}
    />
  );
};

export default InputFieldV2;
