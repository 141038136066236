export const ReportIcon = ({
  fill = "None",
  width = "23",
  height = "23",
  strokeColor = "#15112D",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.125 26.1668H21.875C23.3248 26.1668 24.5 25.1222 24.5 23.8335V5.16683C24.5 3.87816 23.3248 2.8335 21.875 2.8335H6.125C4.67525 2.8335 3.5 3.87816 3.5 5.16683V23.8335C3.5 25.1222 4.67525 26.1668 6.125 26.1668Z"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8335 11L19.8335 22.6667"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 15.6665L14 22.6665"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.1665 19.1665L8.1665 22.6665"
        stroke={strokeColor}
        strokeWidth="2.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7778 8.00017L11.6667 12.2224L11.2223 9.00017L7.00006 12.3335"
        stroke={strokeColor}
        strokeWidth="0.888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
