import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import useRootProperties from "../../hooks/useRootProperties";
import Icon from "../../assets/icons";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { getPlatform } from "../../helpers";
import { platformHandler } from "../../hooks/context";

const useAppLayout = () => {
  const { isLoading } = useRootProperties();
  const {
    business_id,
    business_list,
    role: business_role,
  } = useSelector((state) => state.profileDetailsReducer.profileDetails);

  const { business_name } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { total_revenue, pending_transfer_request } = useSelector(
    (state) => state.dashboardReducer.dashboardDetails,
  );
  const [showSidebar, setShowSidebar] = useState(false);
  const [subTab, setSubTab] = useState(null);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [isSubMobileMenu, setIsSubMobileMenu] = useState(false);
  const [currentMobileMenu, setCurrentMobileMenu] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [openLoyalty, setOpenLoyalty] = useState(false);
  const { platform } = useContext(platformHandler);
  const [isCollapseSidebar, setIsCollapseSidebar] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const navigateToPage = (route) => {
    closeSubMenu();
    navigate(route);
  };

  const showMobileSubMenu = (type) => {
    setCurrentMobileMenu(type);
    setIsSubMobileMenu(true);
  };

  const closeSubMenu = () => {
    setCurrentMobileMenu(null);
    setIsSubMobileMenu(false);
  };

  const menuData = [
    {
      name: "Dashboard",
      icon: matchPath(getPlatform(platform) + "/dashboard/*", pathname)
        ? Icon.mobileHomePurpleIcon
        : Icon.mobileHomeIcon,
      active: matchPath(getPlatform(platform) + "/dashboard/*", pathname),
      key: 0,
      clickEventHandler: () =>
        navigateToPage(getPlatform(platform) + "/dashboard"),
    },
    {
      name: "Payments",
      icon: matchPath(getPlatform(platform) + "/payments/*", pathname)
        ? Icon.mobilePaymentPurpleIcon
        : Icon.mobilePaymentIcon,
      active: matchPath(getPlatform(platform) + "/payments/*", pathname),
      key: 1,
      permissionParentKey: "Payments",
      clickEventHandler: () => showMobileSubMenu("Payments"),
    },
    {
      name: "Inventory",
      icon:
        matchPath(getPlatform(platform) + "/stores/*", pathname) ||
        matchPath(getPlatform(platform) + "/inventory/*", pathname)
          ? Icon.mobileInventoryPurpleIcon
          : Icon.mobileInventoryIcon,
      active:
        matchPath(getPlatform(platform) + "/stores/*", pathname) ||
        matchPath(getPlatform(platform) + "/inventory/*", pathname),
      key: 2,
      permissionParentKey: "Inventory Management",
      clickEventHandler: () => showMobileSubMenu("Inventory"),
    },
    {
      name: "Expenses",
      icon: matchPath(getPlatform(platform) + "/expenses/*", pathname)
        ? Icon.mobileExpensePurpleIcon
        : Icon.mobileExpenseIcon,
      active: matchPath(getPlatform(platform) + "/expenses/*", pathname),
      key: 3,
      permissionParentKey: "Expense",
      clickEventHandler: () => showMobileSubMenu("Expenses"),
    },
    {
      name: "More",
      icon:
        pathname.includes(getPlatform(platform) + "/customers") ||
        pathname.includes(getPlatform(platform) + "/apps") ||
        pathname.includes(getPlatform(platform) + "/accounting") ||
        pathname.includes(getPlatform(platform) + "/loyalty") ||
        pathname.includes(getPlatform(platform) + "/documentation") ||
        pathname.includes(getPlatform(platform) + "/settings")
          ? Icon.mobileMorePurpleIcon
          : Icon.mobileMoreIcon,
      active:
        pathname.includes(getPlatform(platform) + "/customers") ||
        pathname.includes(getPlatform(platform) + "/apps") ||
        pathname.includes(getPlatform(platform) + "/accounting") ||
        pathname.includes(getPlatform(platform) + "/loyalty") ||
        pathname.includes(getPlatform(platform) + "/documentation") ||
        pathname.includes(getPlatform(platform) + "/settings"),
      key: 4,
      clickEventHandler: () => showMobileSubMenu("More"),
    },
  ];

  return {
    isLoading,
    business_name,
    business_id,
    business_list,
    business_role,
    showSidebar,
    total_revenue,
    showAccountDropdown,
    pending_transfer_request,
    subTab,
    isSubMobileMenu,
    menuData,
    currentMobileMenu,
    openLoyalty,
    isCollapseSidebar,
    activeSubMenu,
    setActiveSubMenu,
    setIsCollapseSidebar,
    setIsSubMobileMenu,
    setSubTab,
    setShowSidebar,
    setShowAccountDropdown,
    setCurrentMobileMenu,
    closeSubMenu,
    setOpenLoyalty,
  };
};
export default useAppLayout;
