import { useState } from "react";
import primaryComponents from "../../primaryComponents";
import { useUpdateProductTrackItem } from "../../../hooks/api/mutations/useInventory";
import { useQueryClient } from "react-query";

const UpdateTrackItem = ({ selectedItem, closeModal }) => {
  const queryClient = useQueryClient();
  const [itemVal, setItemVal] = useState(selectedItem?.item_number);
  const { isLoading, mutate } = useUpdateProductTrackItem();
  const updateItemTrackNumber = () => {
    mutate(
      { data: { item_number: itemVal }, id: selectedItem?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getTrackItems"]);
          closeModal();
        },
      },
    );
  };

  return (
    <div className="d-flex flex-column p-3" style={{ gap: "15px" }}>
      <h5 className="text-center">Update tracking number</h5>

      <primaryComponents.InputField
        classNames="white"
        value={itemVal}
        onChange={({ target: { value } }) => {
          setItemVal(value);
        }}
      />

      <div className="d-flex justify-content-between mt-2">
        <primaryComponents.Button
          classNames={"btn btn--outline"}
          onClick={closeModal}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--primary"}
          isDisabled={isLoading}
          isLoading={isLoading}
          loadingText={"Updating..."}
          onClick={() => {
            updateItemTrackNumber();
          }}
        >
          Update
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default UpdateTrackItem;
