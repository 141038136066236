import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const categorySchema = yup
  .object({
    categoryName: yup.string().required("Category name is required"),
  })
  .required("All fields are required");

const ProductCategoryModalValidation = ({ categoryName }) => {
  return useForm({
    resolver: yupResolver(categorySchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { categoryName },
  });
};

export default ProductCategoryModalValidation;
