import { useState } from "react";
import styles from "./ManageSections.module.scss";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import Icon from "../../../../assets/icons";
import { motion } from "framer-motion";
import { useGetSections } from "../../../../hooks/api/queries/useSection";

const ManageSections = () => {
  const [showSectionModal, setShowSectionModal] = useState(false);
  const { data, isLoading, refetch } = useGetSections();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Manage - sections"
      description="Add, edit and delete sections that can have access to your lumi merchant account"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Manage Section</h4>
            <p>
              Create Specialized Sections for Your Kitchen (Grill, Bakery etc)
            </p>
          </div>

          <div
            className="d-flex justify-content-between align-items-center"
            style={{ gap: "20px" }}
          >
            <p>{data?.length || 0} Sections</p>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey={"can_create_kitchen_sections"}
            >
              <primaryComponents.Button
                onClick={() => setShowSectionModal(true)}
                classNames={"btn btn--primary ml-2"}
                data-testId="section-button"
              >
                <img src={Icon.plusIcon} className="mr-1" alt="section" />
                Add new section
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>

          <div className={styles.users_page}>
            {/* table section */}
            <secondaryComponents.LoaderHelper
              classNames="mt-5"
              isLoading={isLoading}
            >
              <secondaryComponents.SectionsTable
                sections={data}
                refetch={refetch}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          {/* section modal section */}
          <secondaryComponents.Modal
            isActive={showSectionModal}
            closeModal={() => setShowSectionModal(false)}
            width={500}
          >
            <secondaryComponents.SectionForm
              closeModal={() => setShowSectionModal(false)}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ManageSections;
