import { useContext } from "react";
import { ToastContext } from "../../../../hooks/context";

const useCompositeItems = ({
  selectedProducts,
  setSelectedProducts,
  setCurrentStep,
}) => {
  const triggerToast = useContext(ToastContext);

  const addSelectedProduct = (data) => {
    const itemId = `${data?.product_id || data?.service_id}${
      data?.variant_id || ""
    }`;
    const itemIndex = findProductIndex([...selectedProducts], itemId);
    if (itemIndex !== -1) return;
    const newData = {
      ...data,
      stock_quantity: data?.quantity,
      unit: data?.unit?.unit,
      itemId,
    };
    delete newData?.quantity;
    setSelectedProducts((prev) => [newData, ...prev]);
  };

  const editSelectedProductQuantity = (data, index) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts[index].stock_quantity = data?.stock_quantity;
    setSelectedProducts(newSelectedProducts);
  };

  const removeSelectedProduct = (index) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts.splice(index, 1);
    setSelectedProducts(newSelectedProducts);
  };

  const findProductIndex = (productList, id) => {
    const itemIndex = productList?.findIndex((item) => item?.itemId === id);
    return itemIndex;
  };

  const gotoNextStep = () => {
    if (selectedProducts?.length === 0)
      return triggerToast(
        "At least one base item is needed to proceed with composite creation",
        "warning",
      );
    setCurrentStep(1);
  };

  return {
    addSelectedProduct,
    editSelectedProductQuantity,
    removeSelectedProduct,
    gotoNextStep,
  };
};
export default useCompositeItems;
