import httpClient from "../axiosBase/axiosHandler";

export const addTag = (payload) => {
  const response = httpClient.post("/tags/", { ...payload });
  return response;
};

export const getTags = (systemTag = true) => {
  const response = httpClient.get(`/tags/?system_tag=${systemTag} `);
  return response;
};

export const getTag = (tag_id) => {
  const response = httpClient.get(`/ tags / ${tag_id} /`);
  return response;
};

export const updateTag = (tag_id, payload) => {
  const response = httpClient.patch(`/tags/${tag_id}/`, { ...payload });
  return response;
};

export const deletTag = (tag_id) => {
  const response = httpClient.delete(`/tags/${tag_id}/`);
  return response;
};
