/* eslint-disable react/jsx-key */
import secondaryComponents from "..";
import useAllFoodMenusList from "./useAllFoodMenusList";
import MenuDetails from "../../../pages/appPages/Inventory/FoodMenus/AllFoodMenus/components/MenuDetails";

const AllFoodMenusList = ({
  foodMenus,
  handleFetchFoodMenu,
  selectedMenuTab,
}) => {
  const {
    isDeletingMenu,
    isFoodMenuWidget,
    isDeleteFoodMenu,
    setIsFoodMenuWidget,
    setIsDeleteFoodMenu,
    onCopy,
    handleDeleteMenu,
  } = useAllFoodMenusList({
    foodMenus,
    handleFetchFoodMenu,
  });
  return (
    <>
      <MenuDetails
        selectedMenuTab={selectedMenuTab}
        foodMenus={foodMenus}
        onCopy={onCopy}
        setIsDeleteFoodMenu={setIsDeleteFoodMenu}
        setIsFoodMenuWidget={setIsFoodMenuWidget}
      />

      {/* storefront details */}
      <secondaryComponents.SideModal
        isActive={isFoodMenuWidget !== null}
        closeModal={() => setIsFoodMenuWidget(null)}
        width={1000}
      >
        <secondaryComponents.FoodMenuWidget
          closeWidget={() => setIsFoodMenuWidget(null)}
          menuId={isFoodMenuWidget}
        />
      </secondaryComponents.SideModal>
      {/* delete menu modal */}
      <secondaryComponents.ConfirmDialog
        title="Delete Storefront"
        isOpen={isDeleteFoodMenu !== null && isDeleteFoodMenu !== false}
        setIsOpen={setIsDeleteFoodMenu}
        isLoading={isDeletingMenu}
        handler={() => handleDeleteMenu()}
      >
        <p className="text-center">
          Are you sure you want to delete this storefront?
        </p>
        <p className="text-center">This action can not be undone</p>
      </secondaryComponents.ConfirmDialog>
    </>
  );
};

export default AllFoodMenusList;
