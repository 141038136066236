import { useContext, useState } from "react";
import { ToastContext } from "../../../hooks/context";
import {
  useCompleteSectionOrderMutation,
  useStartSectionOrderMutation,
} from "../../../hooks/api/mutations/useSection";
import { useQueryClient } from "react-query";

const useSectionDetailsWidget = ({ sectionDetails, closeModal }) => {
  const triggerToast = useContext(ToastContext);
  const startSectionOrder = useStartSectionOrderMutation();
  const completeSectionOrder = useCompleteSectionOrderMutation();
  const [isEnabled, setIsEnabled] = useState(false);
  const [isPrintModal, setIsPrintModal] = useState(false);
  const [isTerminal, setIsTerminal] = useState(false);
  const queryClient = useQueryClient();

  const handleStartKitchenOrder = () => {
    startSectionOrder.mutate(
      { id: sectionDetails?.id },
      {
        onSuccess: (res) => onSuccess(res?.data?.detail),
      },
    );
  };

  const handleCompleteKitchenOrder = () => {
    completeSectionOrder.mutate(
      { id: sectionDetails?.id },
      {
        onSuccess: (res) => onSuccess(res?.data?.detail),
      },
    );
  };

  const onSuccess = (message) => {
    queryClient.invalidateQueries(["sectionOrders"]);
    triggerToast(message, "success");
    closeModal();
  };

  return {
    isStarting: startSectionOrder?.isLoading,
    isCompleting: completeSectionOrder?.isLoading,
    isEnabled,
    isPrintModal,
    isTerminal,
    setIsTerminal,
    setIsPrintModal,
    handleCompleteKitchenOrder,
    handleStartKitchenOrder,
    setIsEnabled,
  };
};
export default useSectionDetailsWidget;
