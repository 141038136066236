import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const transferApprovalSchema = yup
  .object({
    password: yup.string().required("Password is required"),
  })
  .required();

const TransferApprovalValidation = (password) => {
  return useForm({
    resolver: yupResolver(transferApprovalSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      password: password,
    },
  });
};

export default TransferApprovalValidation;
