export const CollapseIcon = ({
  fill = "none",
  width = "24",
  height = "22",
  strokeColor = "#fff",
  ...rest
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 22"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.7777 1C22.4527 1 22.9999 1.74619 22.9999 2.66667V19.3333C22.9999 20.2538 22.4527 21 21.7777 21H11.9999H8.21099H2.2221C1.54709 21 0.999878 20.2538 0.999878 19.3333V2.66667C0.999878 1.74619 1.54709 1 2.2221 1H8.21099H11.9999H21.7777Z"
        fill="#7647EE"
      />
      <path
        d="M8.21099 1H2.2221C1.54709 1 0.999878 1.74619 0.999878 2.66667V19.3333C0.999878 20.2538 1.54709 21 2.2221 21H8.21099M8.21099 1H11.9999H21.7777C22.4527 1 22.9999 1.74619 22.9999 2.66667V19.3333C22.9999 20.2538 22.4527 21 21.7777 21H11.9999H8.21099M8.21099 1V11V21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9999 7L13.9996 11.0003L17.9999 15.0007"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
