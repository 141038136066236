import styles from "./SectionDetailsWidget.module.scss";
import React from "react";
import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlateIcon } from "../../../assets/svgIcons";
import useSectionDetailsWidget from "./useSectionDetailsWidget";
import { SectionView } from "../../printViews";
import secondaryComponents from "..";
import ReceiptTerminalsV2 from "../StoreCheckout/ReceiptTerminalsV2";
import PrintTypeOption from "../StoreCheckout/PrintTypeOption";

const SectionDetailsWidget = ({
  isPrint = false,
  sectionDetails,
  closeModal,
}) => {
  const {
    isStarting,
    isCompleting,
    isEnabled,
    isPrintModal,
    isTerminal,
    setIsTerminal,
    setIsPrintModal,
    setIsEnabled,
    handleCompleteKitchenOrder,
    handleStartKitchenOrder,
  } = useSectionDetailsWidget({ sectionDetails, closeModal });

  return (
    <>
      <div className={styles.sectionDetails}>
        <FontAwesomeIcon
          icon={"angle-left"}
          className="mr-auto"
          style={{ fontSize: "25px", cursor: "pointer" }}
          onClick={closeModal}
        />
        <div className="d-flex align-items-center">
          <h1 className="mb-0 mr-2"> {sectionDetails?.order?.queue_number}</h1>
          <div>
            <p className="small-text-size mb-0">Order Number: </p>
            <p className="small-text-size">{sectionDetails?.order?.id}</p>
          </div>
        </div>

        <div className={styles.sectionDetails__content}>
          <div className={styles.sectionDetails__content__header}>
            <PlateIcon />
            <p className="purple--text">
              {sectionDetails?.order?.order_type?.replace("_", " ")}
            </p>
          </div>

          {/* Table */}
          <div className="d-flex justify-content-between">
            <p>Table : </p>

            <p className="dark--text">
              {sectionDetails?.order?.table?.name || "N/A"}
            </p>
          </div>

          {/* Order */}
          <div>
            <p>Orders : </p>

            {sectionDetails?.docket_items?.map((item, index) => (
              <p key={item?.id}>
                {" "}
                {item?.quantity} x{" "}
                <span className="dark--text">
                  {item?.cart_item?.cart_item_name}
                </span>
                {index === sectionDetails?.docket_items?.length - 1 ? "" : ","}
              </p>
            ))}
          </div>

          {/* Notes */}
          <div>
            <p>Notes:</p>
            <p className="small-text-size">
              {sectionDetails?.order?.notes || "N/A"}
            </p>
          </div>
        </div>

        {(sectionDetails?.status === "RECEIVED" ||
          sectionDetails?.status === "IN_PROGRESS") &&
        !isPrint ? (
          <primaryComponents.Button
            classNames={"btn btn--primary w-100 mt-auto"}
            onClick={() => {
              if (sectionDetails?.status === "RECEIVED")
                handleStartKitchenOrder();
              else handleCompleteKitchenOrder();
            }}
            isDisabled={isStarting || isCompleting}
            isLoading={isStarting || isCompleting}
            loadingText={"Proccessing..."}
          >
            {sectionDetails?.status === "RECEIVED" ? "Start" : "Mark as Ready"}
          </primaryComponents.Button>
        ) : null}
        {sectionDetails?.status === "COMPLETED" ||
        sectionDetails?.status === "PARTIALLY_COMPLETED" ||
        isPrint ? (
          <primaryComponents.Button
            classNames={"btn btn--primary w-100 mt-auto"}
            onClick={() => setIsPrintModal(true)}
            loadingText={"Proccessing..."}
          >
            Print
          </primaryComponents.Button>
        ) : null}
      </div>

      <SectionView
        section_id={sectionDetails?.id}
        allowPrint={isEnabled}
        setAllowPrint={setIsEnabled}
      />

      <secondaryComponents.SideModal
        width={500}
        isActive={isTerminal}
        closeModal={() => setIsTerminal(false)}
      >
        <ReceiptTerminalsV2
          isOrderFlow={false}
          sectionId={sectionDetails?.id}
          closeModal={() => setIsTerminal(false)}
        />
      </secondaryComponents.SideModal>

      <secondaryComponents.Modal
        width={400}
        isActive={isPrintModal}
        closeModal={() => setIsPrintModal(false)}
      >
        <PrintTypeOption
          printOnDeviceAction={() => setIsEnabled(true)}
          printOnDevicePOS={() => setIsTerminal(true)}
          closeModal={() => setIsPrintModal(false)}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default SectionDetailsWidget;
