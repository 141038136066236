import { useQuery } from "react-query";
import { useContext, useState } from "react";
import { getGeneratedWaybills } from "../../../../services/sales-point";
import { ToastContext } from "../../../../hooks/context";
import useOrderflows from "../Orderflows/useOrderflows";

export const useGeneratedWaybills = ({ orderId }) => {
  const [currentStep, setCurrentStep] = useState("waybill_list");
  const [selectedWaybill, setSelectedWaybill] = useState(null);
  const triggerToast = useContext(ToastContext);
  const { orderDetails } = useOrderflows({
    orderId,
  });

  const { data: waybills, isLoading: loadingWaybills } = useQuery(
    ["waybills", orderId],
    () => getGeneratedWaybills(orderId).then((res) => res.data?.data),
    {
      onError: (error) => {
        if (error?.response?.status === 400)
          triggerToast(error?.response?.data?.detail, "warning");
      },
      enabled: orderDetails ? true : false,
    },
  );

  return {
    currentStep,
    waybills,
    loadingWaybills,
    selectedWaybill,
    setSelectedWaybill,
    setCurrentStep,
  };
};
