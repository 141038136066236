import "./LoginOtpVerification.scss";
import layouts from "../../../layouts";
import primaryComponents from "../../../components/primaryComponents";
import secondaryComponents from "../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import LoginVerificationLogic from "./LoginOtpVerificationLogic";

const LoginOtpVerification = () => {
  const { handleOTPInputs, otp, isVerifing, sent_to, email } =
    LoginVerificationLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Login - Verification"
      description="Log in to your lumi merchant account"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
        >
          <layouts.LoginLayout>
            <div className="login-verification-page">
              <div className="login-verification-page__container">
                <h3 className="purple--text text-center">Verification</h3>
                <p className="text-center">
                  Enter the 4-digit code sent to your{" "}
                  {sent_to !== email ? "supervisor email" : "email"}{" "}
                  <span className="purple--text mt-2 font-weight-semibold">
                    {sent_to !== email ? sent_to : email}
                  </span>
                </p>
                <form className="verification__form">
                  {!isVerifing ? (
                    <primaryComponents.OtpInputField
                      onChange={handleOTPInputs}
                      numOfInputs={4}
                      value={otp}
                    />
                  ) : (
                    <h4 className="purple--text font-weight-normal">
                      Verifying...
                    </h4>
                  )}
                </form>
                <p className="text-center">
                  <Link
                    className="font-weight-semibold purple--text"
                    to="/login"
                  >
                    Return to Login
                  </Link>
                </p>
              </div>
            </div>
          </layouts.LoginLayout>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default LoginOtpVerification;
