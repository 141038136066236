import "./ApiErrorHandler.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import ApiErrorHandlerLogic from "./ApiErrorHandlerLogic";

const ApiErrorHandler = ({ children }) => {
  const { message, isSubscriptionNotification, closeApiErrorHandler } =
    ApiErrorHandlerLogic();

  return (
    <>
      {children}
      {message && !isSubscriptionNotification ? (
        <motion.div
          initial={{ opacity: 0, top: "10px" }}
          animate={{
            opacity: 1,
            top: "50px",
            transition: { duration: 0.1 },
          }}
          exit={{ opacity: 0 }}
          className="api-error-handler"
        >
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon="exclamation-circle"
              className="white--text circle mr-3"
              style={{
                fontSize: "25px",
                background: "transparent",
              }}
            />

            <span className="white--text">{message}</span>
          </div>

          <span
            className="api-error-handler__close"
            onClick={closeApiErrorHandler}
          >
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="white--text mt-1 ml-5"
              style={{ fontSize: "20px" }}
            />
          </span>
        </motion.div>
      ) : null}
    </>
  );
};
export default ApiErrorHandler;
