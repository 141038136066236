import React from "react";

export const BankEmptyIcon = () => {
  return (
    <svg
      width="340"
      height="352"
      viewBox="0 0 360 372"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7932 79.3454C21.081 77.9162 23.5822 73.271 22.1529 68.9832C20.7237 64.6954 16.0785 62.1942 11.4334 63.6235C7.14562 65.0527 4.64441 69.6979 6.07367 73.9857C7.50294 78.2735 12.1481 80.7747 16.7932 79.3454Z"
        fill="#E4DAFC"
      />
      <path
        d="M35.0174 52.1895C37.876 51.1175 39.6625 47.9017 38.5906 45.0431C37.5186 42.1846 34.3028 40.398 31.4442 41.47C28.5857 42.5419 26.7991 45.7578 27.8711 48.6163C28.5857 51.4748 31.8016 53.2614 35.0174 52.1895Z"
        fill="#E4DAFC"
      />
      <path
        d="M337.512 117.125C342.872 112.479 343.229 104.261 338.227 99.2588C333.582 94.2563 325.363 93.899 320.361 98.5441C315.001 103.189 314.644 111.408 319.646 116.41C324.291 121.412 332.51 121.77 337.512 117.125Z"
        fill="#E4DAFC"
      />
      <path
        d="M356.805 142.162C358.949 140.019 359.307 136.803 357.163 134.659C355.019 132.515 351.803 132.158 349.659 134.301C347.515 136.445 347.158 139.661 349.302 141.805C351.088 144.306 354.661 144.306 356.805 142.162Z"
        fill="#E4DAFC"
      />
      <path
        d="M31.444 354.122C33.5879 351.978 33.9453 348.762 31.8014 346.618C29.6575 344.475 26.4416 344.117 24.2977 346.261C22.1538 348.405 21.7965 351.621 23.9404 353.765C26.0843 355.909 29.3001 356.266 31.444 354.122Z"
        fill="#EAEEF9"
      />
      <path
        d="M166.51 371.631C258.34 371.631 333.019 297.309 333.019 205.836C332.662 114.005 258.34 39.6833 166.51 39.6833C74.3219 39.6833 0 114.005 0 205.478C0 297.309 74.3219 371.631 166.51 371.631Z"
        fill="#F3F1FC"
      />
      <path
        d="M277.013 120.02V132.861H276.025H263.513L71.5615 132.202C67.9397 132.202 64.318 131.544 60.6963 130.556C60.367 130.556 60.0378 130.227 59.7085 130.227C55.099 128.581 51.1481 126.605 47.8556 123.313C45.2216 120.679 43.2461 116.728 43.2461 113.106C43.2461 106.192 48.8433 100.924 56.7453 97.6313C59.3793 96.3143 62.6718 95.6558 65.9643 95.3266C67.2812 95.3266 68.5982 94.9973 69.9152 94.9973H251.99C253.965 94.9973 255.611 95.3266 257.258 95.6558C258.575 95.9851 259.892 96.3143 261.209 96.9728C270.757 100.924 277.013 109.484 277.013 120.02Z"
        fill="url(#paint0_linear_628_8548)"
      />
      <path
        opacity="0.1"
        d="M276.025 132.861H263.513L71.5614 132.202C67.9396 132.202 64.3179 131.544 60.6962 130.556C60.3669 130.556 60.0377 130.227 59.7084 130.227C55.0989 128.581 51.148 126.605 47.8555 123.313C50.1602 121.008 53.1234 119.033 56.7452 117.716C59.3792 116.399 62.6717 115.74 65.9642 115.411C67.2811 115.082 68.5981 115.082 69.9151 115.082H251.99C253.965 115.082 255.611 115.411 257.258 115.74C258.575 116.069 259.892 116.399 261.209 117.057C268.452 120.02 273.72 125.947 276.025 132.861Z"
        fill="#15112D"
      />
      <g filter="url(#filter0_d_628_8548)">
        <path
          d="M300.388 152.945V314.606C300.388 325.801 291.499 334.691 280.304 334.691H86.3768C70.2437 334.691 57.7322 327.118 49.8302 320.862C46.2085 317.899 43.9038 313.289 43.9038 308.68C43.9038 306.375 43.9038 304.07 43.9038 301.766C43.9038 298.473 43.9038 294.522 43.9038 290.571C43.9038 272.133 43.9038 247.44 43.5745 222.417C43.5745 218.795 43.5745 215.173 43.5745 211.222C43.5745 198.052 43.5745 184.882 43.2453 172.371C43.2453 168.749 43.2453 165.127 43.2453 161.176C43.2453 136.483 42.916 118.045 42.916 113.765C42.916 121.008 49.8302 127.593 59.0492 130.227C59.3785 130.227 59.7077 130.556 60.0369 130.556C63.3294 131.544 67.2804 132.203 70.9022 132.203L262.854 133.19H276.683H279.975C291.499 132.861 300.388 141.751 300.388 152.945Z"
          fill="url(#paint1_linear_628_8548)"
        />
      </g>
      <path
        opacity="0.1"
        d="M261.538 207.93C254.624 207.93 248.698 210.564 244.417 214.844C240.137 219.124 237.174 225.38 237.174 231.965C237.174 232.294 237.174 232.953 237.174 233.282C237.832 246.123 248.698 256.329 261.538 256.329H300.39V207.93H261.538Z"
        fill="url(#paint2_linear_628_8548)"
      />
      <g filter="url(#filter1_d_628_8548)">
        <path
          d="M300.389 198.711V204.637C300.059 204.637 299.73 204.637 299.072 204.637H259.562C252.647 204.637 246.721 207.271 242.441 211.551C238.49 215.502 235.527 221.429 235.197 227.685C235.197 228.014 235.197 228.672 235.197 229.002C235.197 242.501 246.062 253.366 259.562 253.366H299.072C301.047 253.366 302.693 252.707 303.352 251.72C303.681 251.39 304.01 250.732 304.01 250.403V201.674C304.01 200.028 302.364 199.04 300.389 198.711Z"
          fill="url(#paint3_linear_628_8548)"
        />
      </g>
      <path
        d="M257.587 243.489C265.489 243.489 272.074 236.904 272.074 229.002C272.074 221.1 265.489 214.515 257.587 214.515C249.685 214.515 243.1 221.1 243.1 229.002C243.1 236.904 249.685 243.489 257.587 243.489Z"
        fill="#7647EE"
      />
      <path
        d="M131.217 242.14C136.657 242.14 141.26 237.537 141.26 232.097C141.26 226.657 136.657 222.053 131.217 222.053C125.777 222.053 121.174 226.657 121.174 232.097C121.174 237.537 125.777 242.14 131.217 242.14Z"
        fill="#15112D"
      />
      <path
        d="M208.217 242.14C213.657 242.14 218.26 237.537 218.26 232.097C218.26 226.657 213.657 222.053 208.217 222.053C202.777 222.053 198.174 226.657 198.174 232.097C198.174 237.955 202.777 242.14 208.217 242.14Z"
        fill="#15112D"
      />
      <path
        d="M130.825 198.87L108.732 210.798L111.715 216.322L133.807 204.393L130.825 198.87Z"
        fill="#15112D"
      />
      <path
        d="M206.851 198.711L203.869 204.234L225.964 216.161L228.946 210.637L206.851 198.711Z"
        fill="#15112D"
      />
      <path
        d="M169.717 265.574C176.188 265.574 181.434 261.64 181.434 256.786C181.434 251.933 176.188 247.999 169.717 247.999C163.246 247.999 158 251.933 158 256.786C158 261.64 163.246 265.574 169.717 265.574Z"
        fill="#15112D"
      />
      <path
        d="M342.184 13.159C309.506 5.99271 274.534 7.13932 242.429 16.5989C235.836 18.6054 228.67 21.1853 223.51 25.7717C215.484 32.3648 212.044 44.1175 214.624 54.1504C217.204 64.1832 225.803 72.4961 236.409 74.5027C243.002 76.2226 251.029 73.9294 255.042 68.483C258.768 62.7499 257.908 54.1504 253.322 48.9906C248.735 43.2576 241.282 40.9643 234.403 41.251C227.523 41.5376 220.643 44.1175 214.624 47.2707C195.131 57.8768 179.365 76.5093 172.772 98.2949C169.43 108.878 168.253 120.003 169.109 130.946"
        stroke="#7647EE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4 4"
      />
      <path
        d="M359.383 5.7057C359.096 10.0055 358.81 14.0186 355.657 14.8786C352.503 15.7385 351.07 12.872 349.064 8.85888C347.057 5.13239 348.204 1.11926 351.643 0.259298C354.797 -0.60066 359.956 0.545951 359.383 5.7057Z"
        fill="#FCDEB1"
      />
      <path
        d="M355.942 26.3448C356.802 21.1851 357.662 18.3185 354.795 16.8853C351.642 15.452 349.636 18.0319 346.196 21.4717C343.329 24.6249 345.049 29.2113 347.916 30.6446C351.356 32.3645 355.082 30.6446 355.942 26.3448Z"
        fill="#FCDEB1"
      />
      <path
        d="M359.956 16.8854C359.669 18.8919 358.236 20.3252 356.229 20.6118C355.369 20.6118 354.509 20.6118 353.363 20.6118C349.349 20.0385 346.196 17.4587 346.483 14.8788C346.77 12.2989 350.496 10.8657 355.083 11.439C355.942 11.439 356.802 11.7256 357.376 12.0123C359.096 12.5856 360.242 14.5921 359.956 16.8854C359.956 16.8854 359.956 16.5987 359.956 16.8854Z"
        fill="#989FB0"
      />
      <path
        d="M44.3926 60.0239C44.3926 55.1508 44.3926 50.2777 47.8324 48.8444C51.5589 47.4111 53.5655 50.851 56.432 55.7241C59.0119 60.0239 57.8652 64.6103 54.1388 66.0436C50.9856 67.4768 44.3926 66.6169 44.3926 60.0239Z"
        fill="#A3DFBC"
      />
      <path
        d="M46.3987 35.945C45.8254 41.9647 44.9654 45.4046 48.6919 46.8378C52.4184 48.2711 54.425 45.1179 58.1514 40.5315C61.018 36.5183 59.0114 31.3586 55.2849 29.9253C51.5584 28.4921 46.972 31.0719 46.3987 35.945Z"
        fill="#A3DFBC"
      />
      <path
        d="M43.2461 47.4113C43.2461 45.118 44.966 43.3981 46.9726 43.1115C47.8325 42.8248 48.9791 42.8248 50.1258 43.1115C54.7122 43.3981 58.7253 45.978 58.4387 48.8445C58.152 51.7111 54.4255 53.7176 49.5525 53.1443C48.6925 53.1443 47.8325 52.8577 46.9726 52.571C44.6794 52.2844 43.2461 49.9911 43.2461 47.4113Z"
        fill="#989FB0"
      />
      <path
        d="M62.7383 48.271C92.8368 48.271 148.447 65.7568 149.307 129.967"
        stroke="#7647EE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeDasharray="4 4"
      />
      <defs>
        <filter
          id="filter0_d_628_8548"
          x="20.916"
          y="102.765"
          width="301.473"
          height="264.926"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_628_8548"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_628_8548"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_628_8548"
          x="213.197"
          y="187.711"
          width="112.812"
          height="98.6553"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11" />
          <feGaussianBlur stdDeviation="11" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_628_8548"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_628_8548"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_628_8548"
          x1="160.148"
          y1="96.3127"
          x2="160.148"
          y2="117.565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_628_8548"
          x1="171.568"
          y1="108.654"
          x2="171.568"
          y2="337.072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_628_8548"
          x1="268.787"
          y1="209.611"
          x2="268.787"
          y2="236.777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_628_8548"
          x1="269.581"
          y1="197.446"
          x2="269.581"
          y2="253.955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDFEFF" />
          <stop offset="0.9964" stopColor="#ECF0F5" />
        </linearGradient>
      </defs>
    </svg>
  );
};
