import { useMemo } from "react";
import { useTable } from "react-table";
import Icon from "../../../assets/icons";

const StoreDevicesTableLogic = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Device ID",
        accessor: "device_id",
      },
      {
        Header: "Merchant",
        accessor: "merchant",
      },
      {
        Header: "Store",
        accessor: "store",
      },
      {
        Header: "Type of Device",
        accessor: "type_of_device",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }) => (
          <span
            style={{
              color: "#19AE57",
              height: "28px",
              cursor: "pointer",
              backgroundColor: "#E5FFF0",
              padding: "8px 16px",
              borderRadius: "8px",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "",
        accessor: "action",
        Cell: () => (
          <span style={{ cursor: "pointer" }}>
            <img src={Icon.option_alt} alt="" />
          </span>
        ),
      },
    ],
    [],
  );

  const rows = useMemo(
    () => [
      {
        device_id: "FRV0039KHG",
        merchant: "Behemoth Group",
        store: "Lekki",
        type_of_device: "Mobile Phone",
        status: "Active",
        action: "",
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: rows });

  return { instance };
};

export default StoreDevicesTableLogic;
