/* eslint-disable react/jsx-key */
import styles from "./SubscriptionTable.module.scss";
import secondaryComponents from "..";
import useSubscriptionTable from "./useSubscriptionTable";
import Images from "../../../assets/images";

const SubscriptionTable = ({
  subscriptions,
  metaDetails,
  gotoPage,
  handleFetchSubscriptions,
}) => {
  const { instance } = useSubscriptionTable({
    subscriptions,
    handleFetchSubscriptions,
  });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <>
      <div className={styles.subscriptionTable}>
        <secondaryComponents.TableHelper
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          rows={rows}
          prepareRow={prepareRow}
          hasHeader={false}
        >
          <div className="text-center mt-5 mb-5">
            <img src={Images.expense2EmptyState} width="250px" alt="" />
            <h4 className="font-weight-semibold text-center mt-4">
              You have no subscription history
            </h4>
          </div>
        </secondaryComponents.TableHelper>

        {subscriptions?.length > 0 && (
          <div className="mt-auto">
            <secondaryComponents.Pagination
              totalPageNumber={metaDetails?.last_page}
              handlePageClick={(value) => gotoPage(value?.selected + 1)}
              currentPage={metaDetails?.current_page}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SubscriptionTable;
