/* eslint-disable eqeqeq */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { useGetAllProductsList } from "../../../../hooks/api/queries/useInventory";
import { useGetProductCategoryList } from "../../../../hooks/api/queries/useInventory";

const useAllProducts = () => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { isLoading: isLoadingCategories, data: categories } =
    useGetProductCategoryList();
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();
  const [currentStore] = useState(
    (() => {
      if (search.get("store_id"))
        return storeList?.find((item) => item.id == search.get("store_id"))
          ?.name;
      else return storeList?.[0]?.name;
    })(),
  );
  const [currentCategory] = useState(search.get("category_id"));
  const [inventoryType] = useState(
    (() => {
      if (search.get("type") === "services") return "Services";
      else if (search.get("type") === "goods") return "Products";
    })(),
  );
  const [isProductWidget, setIsProductWidget] = useState(false);
  const [isProductBulkUpdate, setIsProductBulkUpdate] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const {
    isLoading: isFetchingProducts,
    data: products,
    refetch: handleFetchproducts,
  } = useGetAllProductsList({ queries });

  const filterProductByStore = (storeId) => {
    search.set("store_id", storeId);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterByCategory = (categoryId) => {
    if (categoryId === 0) search.delete("category_id");
    else search.set("category_id", categoryId);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const filterInventoryType = (type) => {
    if (type === "Services") search.set("type", "services");
    else if (type === "Products") search.set("type", "goods");
    else if (type == "All Inventory") search.delete("type");
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  const searchProduct = (value) => {
    if (value.length === 0) search.delete("query");
    else search.set("query", value);
    search.delete("page");
    setSearch(search, { replace: true });
  };

  const openSideModals = (type) => {
    search.set("sidemodal", type);
    setSearch(search, { replace: true });
  };

  const closeSideModals = () => {
    search.delete("sidemodal");
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (getUrlQuerysection() === "" && storeList?.length > 0) {
      const storeId = storeList?.[0].id;
      search.set("store_id", storeId);
      setSearch(search, { replace: true });
    }
  }, []);

  return {
    isFetchingProducts,
    storeList,
    currentStore,
    isLoadingCategories: isLoadingCategories,
    categories: categories || [],
    isProductWidget,
    products: products?.data || [],
    metaDetails: products?.meta || {},
    search,
    currentCategory,
    inventoryType,
    isProductBulkUpdate,
    selectedProducts,
    setSelectedProducts,
    setIsProductBulkUpdate,
    handleFetchproducts,
    filterProductByStore,
    filterByCategory,
    filterInventoryType,
    setIsProductWidget,
    gotoPage,
    searchProduct,
    closeSideModals,
    openSideModals,
  };
};
export default useAllProducts;
