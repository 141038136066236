import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object({
  customerPhoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
    .min(10, "Invalid phone number format")
    .max(10, "Invalid phone number format"),
});

export const useLinkCustomerValidation = ({ customerPhoneNumber }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { customerPhoneNumber },
  });
};
