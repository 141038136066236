import { useState, useContext, useRef } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import {
  createStore,
  getStoreCategoryList,
  updateStoreDetails,
} from "../../../services/inventory";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { cleanObject, getRandomIntInclusive } from "../../../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { pathConstants } from "../../../routes/pathContants";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";
import { useGetTimezones } from "../../../hooks/api/queries/useBusiness";

const useStoreForm = ({
  storeInfo,
  isEditMode,
  // closeWidget,
  states,
  countries,
}) => {
  const { business_phone_code, currency_code, currency_symbol } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [storeDetails, setStoreDetails] = useState({
    name: storeInfo?.name || "",
    address: storeInfo?.address || "",
    subaccount_label: storeInfo?.subaccount || "",
    store_category: storeInfo?.store_category?.id || "",
    store_category_label: storeInfo?.store_category?.name || "",
    is_warehouse: storeInfo?.is_warehouse || false,
    vat: storeInfo?.vat,
    allow_generate_bill: storeInfo?.allow_generate_bill || false,
    allow_table: storeInfo?.allow_table || false,
    allow_waybill: storeInfo?.allow_waybill || false,
    allow_prescription: storeInfo?.allow_prescription || false,
    allow_custom_price: storeInfo?.allow_custom_price || false,
    allow_section: storeInfo?.allow_section || false,
    phone_number: storeInfo?.phone_number?.substring(3) || "",
    phone_code: business_phone_code,
    credit_limit: storeInfo?.credit_limit || "",
    default_bank_account: storeInfo?.default_bank_account?.id || "",
    source: "user_entry",
    support_email: storeInfo?.support_email || "",
    currency_code: storeInfo?.currency_code || currency_code || "",
    currency_symbol: storeInfo?.currency_symbol || currency_symbol || "",
    timezone: storeInfo?.timezone?.id || "",
    country_ids: storeInfo?.countries?.map((country) => `${country?.id}`),
    state_ids: storeInfo?.states?.map((state) => state?.id) || [],
    order_reversal_window: storeInfo?.order_reversal_window,
    city_ids: (() => {
      const cities = storeInfo?.cities?.map((city) => city?.id);
      const newCities = {};
      storeInfo?.states?.forEach((saved_state) => {
        newCities[saved_state?.id] =
          states
            ?.find((state) => `${state?.id}` === `${saved_state?.id}`)
            ?.cities?.filter((city) => cities?.includes(city?.id))
            ?.map((city) => city?.id) || [];
      });
      return newCities;
    })(),
  });
  const [isLocation, setIsLocation] = useState(false);
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const stateInputRef = useRef();

  const { data: category_list } = useQuery(
    ["storeCategoryList"],
    () =>
      getStoreCategoryList().then((res) => {
        return res?.data?.data;
      }),
    { staleTime: Infinity },
  );
  const { data: timezones, isLoading: isLoadingTimezones } = useGetTimezones();

  // create a new store
  const { mutate: addStore, isLoading: isAddingStore } = useMutation((data) =>
    createStore(data),
  );
  const handleAddStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const newCityIds = Object.values({ ...storeDetails?.city_ids }).flat();
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails.phone_code}${storeDetails.phone_number}`
            : null,
        city_ids: newCityIds,
      },
      ["store_category_label", "subaccount_label"],
    );

    addStore(data, {
      onSuccess: () => onSuccess({ message: "Store creation successful" }),
      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // Edit store properties
  const { mutate: updateStore, isLoading: isEditingStore } = useMutation(
    (data) => updateStoreDetails(data),
  );
  const handleEditStore = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const newCityIds = Object.values({ ...storeDetails?.city_ids }).flat();
    const data = cleanObject(
      {
        ...storeDetails,
        phone_number:
          storeDetails?.phone_number !== ""
            ? `${storeDetails?.phone_code}${storeDetails.phone_number}`
            : null,
        city_ids: newCityIds,
      },
      ["store_category_label", "subaccount_label"],
    );
    updateStore(
      { data, id: storeInfo?.id },
      {
        onSuccess: () => onSuccess({ message: "Store update successful" }),
        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const submitStoreInfo = () => {
    if (isEditMode) handleEditStore();
    else handleAddStore();
  };

  const onSuccess = ({ message = "" }) => {
    triggerToast(message, "success");
    queryClient.invalidateQueries(["getStoreDetails"]);
    queryClient.invalidateQueries(["getStoreList"]);
    // closeWidget();
    navigate(pathConstants.INVENTORY_STORES);
  };

  const { isLoading: isLoadingBankAccountsList, data: bankAccount } =
    useGetAccountingBanksListWithSpreadFilter({ account_type: "bank_account" });

  const [selectedHours, setSelectedHours] = useState({
    value: "Hours",
    label: "Hours",
  });

  return {
    isAddingStore,
    isEditingStore,
    storeDetails,
    category_list: category_list || [],
    stateInputRef,
    bankAccount,
    isLoadingTimezones,
    timezones: timezones || [],
    isLoadingBankAccountsList,
    isLocation,
    currencyList: countries?.filter(
      (obj, index, self) =>
        index === self.findIndex((t) => t?.name === obj?.name),
    ),
    setIsLocation,
    setStoreDetails,
    submitStoreInfo,
    selectedHours,
    setSelectedHours,
  };
};

export default useStoreForm;
