import { useState } from "react";
import { postStoreCustomerDetails } from "../../../../services/sales-point";
import { useQuery, useQueryClient } from "react-query";
import { getOrderTables } from "../../../../services/inventory";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { cleanObject } from "../../../../helpers";
import { setTableDetails } from "../../../../store/modules/table";
import { useCreateKitchenOrderMutation } from "../../../../hooks/api/mutations/kitchen";
import { useGetSections } from "../../../../hooks/api/queries/useSection";
import useOrderflows from "../Orderflows/useOrderflows";

const useStoreCustomerForm = ({ orderId, resetView }) => {
  const dispatch = useDispatch();
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTable, setIsTable] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    customerPhoneCode: business_phone_code,
  });
  const [numberOfDiners, setNumberOfDiners] = useState(null);
  const [isAddCustomer, setIsAddCustomer] = useState(false);
  const { store_id } = useParams();
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { tableDetails } = useSelector((state) => state.tableReducer);
  const storeProperties = (() => {
    return storeList?.find((item) => `${item?.id}` === store_id);
  })();
  const showTables = storeProperties?.allow_table;
  const showKitchen = storeProperties?.allow_section;
  const { data: sections, isLoading: isLoadingSections } = useGetSections();
  const [sendToKitchen, setSendToKitchen] = useState(
    showKitchen ? true : false,
  );
  const [orderType, setOrderType] = useState("DINE_IN");
  const [notes, setNotes] = useState("");
  const { isLoading: isLoadingOrderDetails, orderDetails } = useOrderflows({
    orderId,
  });
  const createKitchenOrderMutation = useCreateKitchenOrderMutation();
  const queryClient = useQueryClient();

  const submitCustomerDetails = () => {
    const submitData = cleanObject({
      customer_phone_number: customerDetails?.customerPhoneNumber,
      customer_phone_code:
        customerDetails?.customerPhoneNumber === "" ||
        customerDetails?.customerPhoneNumber === undefined
          ? ""
          : customerDetails?.customerPhoneCode,
      customer_name: customerDetails?.customerName,
      customer_email: customerDetails?.customerEmail,
      order_id: orderDetails?.id,
      order_table_id: tableDetails?.id,
      diners: numberOfDiners,
      order_type: sendToKitchen && showKitchen ? orderType : null,
      order_notes: notes,
      send_to_kitchen: sendToKitchen,
    });

    setIsSubmitting(true);
    postStoreCustomerDetails(submitData)
      .then(() => {
        dispatch(setTableDetails(null));
        queryClient.invalidateQueries(["sectionOrders"]);
        queryClient.invalidateQueries(["orderDetails", orderId]);
        resetView("isTnxReceipt");
      })
      .catch(() => {
        setIsSubmitting(false);
      });
  };

  const createkitchenOrder = () => {
    createKitchenOrderMutation.mutate(
      { order_id: orderDetails?.id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["sectionOrders"]);
          queryClient.invalidateQueries(["orderDetails", orderId]);
          resetView("isTnxReceipt");
        },
      },
    );
  };

  const { isLoading: isFetchingOrderTables, data: orderTables } = useQuery(
    ["getOrderTables", `?store_id=${orderDetails?.store?.id}`],
    () =>
      getOrderTables(`?store_id=${orderDetails?.store?.id}`).then(
        (res) => res.data,
      ),
    { enabled: orderDetails && showTables ? true : false },
  );

  return {
    isSubmitting,
    isTable,
    tableDetails,
    isFetchingOrderTables,
    orderTables: orderTables?.data || [],
    showTables,
    numberOfDiners,
    isAddCustomer,
    customerDetails,
    sendToKitchen,
    isLoadingSections,
    sections,
    orderType,
    notes,
    isLoadingOrderDetails,
    orderDetails,
    showKitchen,
    setNotes,
    setOrderType,
    createkitchenOrder,
    setSendToKitchen,
    setIsAddCustomer,
    setCustomerDetails,
    setNumberOfDiners,
    submitCustomerDetails,
    setIsTable,
  };
};
export default useStoreCustomerForm;
