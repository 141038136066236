import styles from "./ReconcileStocks.module.scss";
import secondaryComponents from "../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../components/primaryComponents";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../../../assets/icons";
import useReconcileStocks from "./useReconcileStocks";
import { useStoreCurrency } from "../../../../../hooks/useStoreCurrency";

const ReconcileStocks = () => {
  const {
    storeName,
    isSubmitting,
    isLoading,
    isFetchingNextPage,
    products,
    reconciliedData,
    isSaving,
    isLoadingDiscrepancy,
    discrepancyReasons,
    isReconciliationPrompt,
    categoryList,
    filters,
    setFilters,
    autoScrollRef,
    submitReconciliation,
    saveDraft,
    setReconciliedData,
    setIsReconciliationPrompt,
  } = useReconcileStocks();
  const navigate = useNavigate();
  const { store_currency } = useStoreCurrency();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Reconciliations"
      description="Reconcile stocks"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <div className={styles.reconcileStocks}>
          <h3>Inventory</h3>
          <div className={styles.reconcileStocks__header}>
            <div
              className="d-flex align-items-center flex-wrap"
              style={{ gap: "20px" }}
            >
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => navigate(-1)}
                type="button"
                style={{ width: "max-content" }}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
              <div>
                <h4 className="mb-0">Stock Reconciliation - {storeName}</h4>
                <p className="small-text-size">
                  Please note that this page autosave every 5 mins
                </p>
              </div>
            </div>

            <div className="d-flex ml-auto">
              <primaryComponents.Button
                classNames="btn btn--primary mr-3"
                onClick={() => setIsReconciliationPrompt(true)}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                loadingText="Submitting..."
              >
                <FontAwesomeIcon icon="arrow-right" className="mr-2" />
                Submit
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => saveDraft(false)}
                isDisabled={isSaving}
              >
                <img src={Icon.saveIcon} alt="save" className="mr-2" />
                Save
              </primaryComponents.Button>
            </div>
          </div>

          {/* search section */}
          <div
            className="d-flex justify-content-between flex-wrap"
            style={{ gap: "15px" }}
          >
            <div className={styles.reconcileStocks__search}>
              <primaryComponents.SearchField
                placeholder="Search by product name"
                value={filters?.searchQuery}
                trigger={(value) =>
                  setFilters((prev) => ({ ...prev, searchQuery: value }))
                }
                triggerOnInput={true}
                showsearchBtn
              />
            </div>

            <div style={{ maxWidth: "350px", width: "100%" }}>
              <primaryComponents.MultipleSelectField
                name="categories"
                options={categoryList}
                idKey="id"
                nameKey="name"
                placeholder="Select Categories"
                onChange={(values) => {
                  let newValues = [];
                  values?.forEach((item) => {
                    newValues.push(item.value);
                  });
                  setFilters((prev) => ({ ...prev, categories: newValues }));
                }}
              />
            </div>
          </div>

          <div>
            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isLoading || isLoadingDiscrepancy}
            >
              <secondaryComponents.ReconcileStockEditView
                store_currency={store_currency}
                products={products}
                autoScrollRef={autoScrollRef}
                setReconciliedData={setReconciliedData}
                reconciliedData={reconciliedData}
                discrepancyReasons={discrepancyReasons}
              />
            </secondaryComponents.LoaderHelper>

            <secondaryComponents.LoaderHelper
              classNames="mt-4"
              isLoading={isFetchingNextPage && !isLoading}
            ></secondaryComponents.LoaderHelper>
          </div>

          <secondaryComponents.Modal
            isActive={isReconciliationPrompt}
            closeModal={() => setIsReconciliationPrompt(false)}
            width="500px"
          >
            <secondaryComponents.ReconciliationReviewPrompt
              closeModal={() => setIsReconciliationPrompt(false)}
              reconciliationType="firstSubmission"
              proceed={() => {
                submitReconciliation();
                setIsReconciliationPrompt(false);
              }}
            />
          </secondaryComponents.Modal>
        </div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default ReconcileStocks;
