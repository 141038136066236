import React from "react";

export const Two = () => {
  return (
    <svg
      width="70"
      height="70"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9999 25C10.9255 25 8.88655 24.4623 7.08195 23.4392C5.27736 22.4162 3.76879 20.9428 2.70343 19.1629C1.63808 17.383 1.05233 15.3573 1.00335 13.2834C0.954361 11.2096 1.44381 9.15852 2.42394 7.33028"
        stroke="#7647EE"
        strokeWidth="0.888883"
      />
      <path
        d="M12.9999 1.00006C15.615 1.00006 18.1585 1.85432 20.2434 3.43286C22.3283 5.0114 23.8405 7.2278 24.5499 9.74483C25.2592 12.2619 25.127 14.9417 24.1732 17.3767C23.2194 19.8116 21.4963 21.8683 19.2661 23.2339"
        stroke="#7647EE"
        strokeWidth="0.888883"
      />
      <circle
        cx="13.0005"
        cy="13"
        r="10.2856"
        transform="rotate(-90 13.0005 13)"
        fill="#1E016F"
      />
      <path
        d="M9.49655 17.7148V16.6726C10.1183 16.1789 10.7125 15.6806 11.2794 15.1777C11.8463 14.6749 12.3491 14.1812 12.788 13.6966C13.236 13.2029 13.588 12.7229 13.844 12.2566C14.1 11.7812 14.2279 11.324 14.2279 10.8852C14.2279 10.5926 14.1777 10.3183 14.0771 10.0623C13.9765 9.79718 13.812 9.5869 13.5834 9.43147C13.3548 9.2669 13.0485 9.18462 12.6645 9.18462C12.0885 9.18462 11.6543 9.36747 11.3617 9.73318C11.0783 10.0989 10.9411 10.5377 10.9503 11.0497H9.5377C9.54684 10.3732 9.68855 9.80633 9.96284 9.34919C10.2463 8.89205 10.6211 8.54462 11.0874 8.30691C11.5628 8.06919 12.0977 7.95034 12.692 7.95034C13.5971 7.95034 14.3194 8.20634 14.8588 8.71833C15.4074 9.22119 15.6817 9.92518 15.6817 10.8303C15.6817 11.3697 15.5491 11.9046 15.2839 12.4349C15.0279 12.9652 14.6897 13.4772 14.2691 13.9709C13.8577 14.4554 13.4142 14.9126 12.9388 15.3423C12.4634 15.772 12.0108 16.1606 11.5811 16.508H15.9697V17.7148H9.49655Z"
        fill="white"
      />
    </svg>
  );
};
