import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateStockTransfer } from "./useCreateTransfer";
import { TransferArrowIcon } from "../../../../../assets/svgIcons";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import useValidation from "./useValidation";

const CreateStockTransfer = () => {
  const navigate = useNavigate();
  const {
    transferData,
    isTransfering,
    setTransferData,
    setOtherTransferInfo,
    createTransferProducts,
  } = useCreateStockTransfer();
  const validation = useValidation({ transferData });
  const { handleSubmit } = validation;

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Stock Transfer"
      description="Create a stock transfer"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0, transition: { duration: 0.5 } }}
          className={globalStyles.pageColumnGaps}
        >
          <div className={globalStyles.pageHeaders}>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <primaryComponents.Button
                classNames="btn btn--outline"
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon
                  icon="angle-left"
                  className="mr-3"
                  style={{ fontSize: "18px" }}
                />
                <span>Back</span>
              </primaryComponents.Button>
              <h4 className="ml-3 mb-0">{"Transfer Stock"}</h4>
            </div>

            <primaryComponents.Button
              classNames={"btn btn--primary"}
              isLoading={isTransfering}
              isDisabled={isTransfering}
              loadingText={"Requesting..."}
              onClick={handleSubmit(createTransferProducts)}
            >
              <TransferArrowIcon />
              <span className="ml-2">Request Transfer</span>
            </primaryComponents.Button>
          </div>

          {/* content section */}
          <secondaryComponents.StockTransferForm
            transferData={transferData}
            setTransferData={setTransferData}
            setOtherTransferInfo={setOtherTransferInfo}
            validation={validation}
          />
          {/* table section */}
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default CreateStockTransfer;
