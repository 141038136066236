import { ToastContext } from "../../../hooks/context";
import { useContext } from "react";
import primaryComponents from "../../primaryComponents";
import { useDeleteBankMutation } from "../../../hooks/api/mutations/useAccounting";

const BankAccountDeletion = ({
  bankDetails,
  closeWidget,
  handleFetchBanks = () => {},
  selectedDeleteBank,
}) => {
  const deleteBank = useDeleteBankMutation();
  const triggerToast = useContext(ToastContext);

  const handleDeleteBankById = () => {
    deleteBank.mutate(
      { id: selectedDeleteBank },
      {
        onSuccess: () => {
          handleFetchBanks();
          closeWidget();
          triggerToast("Successfully deleted this bank", "success");
        },
      },
    );
  };

  return (
    <div className="py-3 px-3">
      <h4 className="text-center font-weight-semibold">
        Are you sure you want to delete this bank
      </h4>
      <div
        style={{
          backgroundColor: "#F3F1FC",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          borderRadius: "8px",
          margin: "20px 0",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span className="dark--text font-weight-semibold mb-1">
            Bank Name
          </span>
          <span>{bankDetails?.account_name || "N/A"}</span>
        </p>
      </div>

      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "20px" }}
      >
        <primaryComponents.Button
          onClick={closeWidget}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={handleDeleteBankById}
          classNames={"btn btn--danger cover"}
          isLoading={deleteBank.isLoading}
          isDisabled={deleteBank.isLoading}
          loadingText="Deleting..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default BankAccountDeletion;
