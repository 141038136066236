import React from "react";
import ManageRolesTable from "../../ManageRolesTable";
import secondaryComponents from "../../../../../../components/secondaryComponents";
import useDefaultRoles from "./useDefaultRoles";

const DefaultRoles = () => {
  const { isLoading, data, refetch } = useDefaultRoles();

  return (
    <secondaryComponents.LoaderHelper
      classNames="mt-5"
      isLoading={isLoading || !data}
    >
      <ManageRolesTable
        roleType="default"
        rolesData={data && data.filter((item) => item.is_default)}
        refetch={refetch}
      />
    </secondaryComponents.LoaderHelper>
  );
};

export default DefaultRoles;
