import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  activatePayInstore,
  activatePayOnDelivery,
  activatePayWithPaystack,
  activatePayWithStripe,
  getPaymentGateways,
} from "../../../../../../../../../services/inventory";
import { useContext, useState } from "react";
import { ToastContext } from "../../../../../../../../../hooks/context";
import { useParams, useSearchParams } from "react-router-dom";

export const usePayment = () => {
  const { menuId } = useParams();
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const menu_id = searchParams.get("menu_id");

  const [showSetupPaystack, setShowSetupPaystack] = useState(null);
  const [showSetupStripe, setShowSetupStripe] = useState(null);

  // useEffect(() => {
  //   queryClient.invalidateQueries(["getMenusDetails"]);
  // }, [queryClient]);

  const { data: dataPaymentMethods } = useQuery(
    ["payment-gateways"],
    () => getPaymentGateways().then((res) => res.data?.data),
    { staleTime: Infinity },
  );

  const { isLoading: isLoadingPayInstore, mutate: mutatePayInstore } =
    useMutation({
      mutationFn: () => activatePayInstore(menuId ? menuId : menu_id),
      onSuccess: (data) => {
        triggerToast(data.data.detail, "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const { isLoading: isLoadingPayOnDelivery, mutate: mutatePayOnDelivery } =
    useMutation({
      mutationFn: () => activatePayOnDelivery(menuId ? menuId : menu_id),
      onSuccess: (data) => {
        triggerToast(data.data.detail, "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const { isLoading: isLoadingPayWithPaystack, mutate: mutatePayWithPaystack } =
    useMutation({
      mutationFn: () => activatePayWithPaystack(menuId ? menuId : menu_id),
      onSuccess: (data) => {
        triggerToast(data.data.detail, "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  const { isLoading: isLoadingPayWithStripe, mutate: mutatePayWithStripe } =
    useMutation({
      mutationFn: () => activatePayWithStripe(menuId ? menuId : menu_id),
      onSuccess: (data) => {
        triggerToast(data.data.detail, "success");
        queryClient.invalidateQueries(["getMenusDetails"]);
      },
      onError: (error) => {
        triggerToast(`${error.response.data.message}`, "warning");
      },
    });

  return {
    dataPaymentMethods,
    isLoadingPayInstore,
    isLoadingPayOnDelivery,
    isLoadingPayWithPaystack,
    isLoadingPayWithStripe,
    showSetupPaystack,
    showSetupStripe,
    setShowSetupPaystack,
    setShowSetupStripe,
    mutatePayInstore,
    mutatePayOnDelivery,
    mutatePayWithPaystack,
    mutatePayWithStripe,
  };
};
