import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reArrangeBusinessList } from "../../../helpers";
import { useActiveSubscriptionQuery } from "../../../hooks/api/queries";
import { ToastContext } from "../../../hooks/context";
import {
  createFreePlan,
  createSubscriptionPaymentSession,
} from "../../../services/settings";
import { pathConstants } from "../../../routes/pathContants";

const useSubscriptionPay = ({
  upgradeDetails,
  subscriptionPeriod,
  pricingInfo,
  // setIsPayModal,
}) => {
  const {
    isLoading: isFetchingActiveSUbscription,
    data: activeSubscription,
    refetch: refetchActiveSubscription,
  } = useActiveSubscriptionQuery();
  const {
    profileDetails: { business_list },
    businessDetails: { has_wallet },
  } = useSelector((state) => state.profileDetailsReducer);
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [businessList, setBusinessList] = useState([]);
  const [walletDetails, setWalletDetails] = useState({
    wallet_id: "",
  });
  const [paymentMethod, setPaymentMethod] = useState(
    pricingInfo?.supported_payment_gateway?.includes("lumi_wallet") &&
      has_wallet
      ? "lumi_wallet"
      : pricingInfo?.supported_payment_gateway?.includes("paystack")
        ? "paystack"
        : "stripe",
  );
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    setBusinessList(
      reArrangeBusinessList(business_list, currency_code, "wallet_id"),
    );
  }, []);

  const subscibeTolumi = () => {
    createPaymentSession();
  };

  const { mutate: createSession, isLoading: isCreatingSession } = useMutation(
    (data) => createSubscriptionPaymentSession(data),
  );
  const createPaymentSession = () => {
    const pricing = upgradeDetails?.currentSubcription?.pricings?.find(
      (item) => item?.validity === subscriptionPeriod,
    );
    createSession(
      {
        wallet_id:
          paymentMethod === "lumi_wallet"
            ? walletDetails?.wallet_id
            : undefined,
        payment_method: paymentMethod,
        pricing_id: pricing?.id,
        callback_url:
          paymentMethod === "paystack" || paymentMethod === "stripe"
            ? `${window.location.origin}${pathConstants.SETTINGS_SUBSCRIPTIONS}`
            : undefined,
      },
      {
        onSuccess: (res) => {
          const data = res?.data?.data;
          if (paymentMethod === "lumi_wallet") {
            triggerToast(res?.data?.detail, "success");
            queryClient.invalidateQueries(["activeSubscription"]);
            queryClient.invalidateQueries(["getSubscriptionHistory"]);
            navigate(pathConstants.SETTINGS_SUBSCRIPTIONS);
          } else if (paymentMethod === "paystack") {
            window.open(data?.init_data?.authorization_url, "_self");
          } else if (paymentMethod === "stripe") {
            window.open(data?.init_data?.url, "_self");
          }
        },
      },
    );
  };

  const { mutate: proccedToFreePlan, isLoading: isCreatingFreePlan } =
    useMutation((data) => createFreePlan(data));

  const startFreePlan = () => {
    proccedToFreePlan(
      {},
      {
        onSuccess: async (res) => {
          triggerToast(res?.data?.detail, "success");
          queryClient.invalidateQueries(["getSubscriptionHistory"]);
          await refetchActiveSubscription();
          navigate(pathConstants.INVENTORY_PRODUCTS);
        },
      },
    );
  };

  return {
    has_wallet,
    isCreatingFreePlan,
    activeSubscription,
    isFetchingActiveSUbscription,
    businessList,
    walletDetails,
    triggerToast,
    isCreatingSession,
    paymentMethod,
    setWalletDetails,
    subscibeTolumi,
    createPaymentSession,
    setPaymentMethod,
    startFreePlan,
  };
};
export default useSubscriptionPay;
