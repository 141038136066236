import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import primaryComponents from "../../../../../components/primaryComponents";
import secondaryComponents from "../../../../../components/secondaryComponents";
import useStoreWalletsDetails from "./useStoreWalletDetails";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import styles from "./styles.module.scss";
import { DownloadStatementIcon } from "../../../../../assets/svgIcons";
import { defaultDateDisplay, formatCurrency } from "../../../../../helpers";

const StoreWalletDetails = () => {
  const navigate = useNavigate();
  const {
    storeWalletDetails,
    isLoadingStoreWalletDetails,
    isFundWallet,
    isDebitWallet,
    isLoadingStoreWalletTnx,
    storeWalletTransactions,
    metaDetails,
    isDownloadStatement,
    setIsDownloadStatement,
    setIsDebitWallet,
    setIsFundWallet,
    gotoPage,
  } = useStoreWalletsDetails();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Store Wallet Details"
      description="View the details of a store wallet"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper
            isLoading={isLoadingStoreWalletDetails}
            classNames="my-5"
          >
            <div className={globalStyles.pageHeaders}>
              <div>
                <h4
                  className="d-flex align-items-center font-weight-semibold"
                  style={{ textTransform: "capitalize" }}
                >
                  {storeWalletDetails?.customer?.full_name}{" "}
                  <span
                    className={`smallest-text-size ${storeWalletDetails?.is_active ? "success--squared" : "error--squared"} ml-3`}
                    style={{ padding: "4px 10px" }}
                  >
                    {storeWalletDetails?.is_active ? "Active" : "In Active"}
                  </span>
                </h4>

                <p>
                  Last active{" "}
                  {defaultDateDisplay(storeWalletDetails?.update_at)}
                </p>
              </div>
              <div className="d-flex flex-wrap" style={{ gap: "8px" }}>
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_credit_store_wallet"
                >
                  <primaryComponents.Button
                    type="button"
                    classNames={"btn btn--primary"}
                    onClick={() =>
                      setIsFundWallet({
                        customerEmail: storeWalletDetails?.customer?.email,
                        customerId: storeWalletDetails?.customer?.id,
                        customerName: storeWalletDetails?.customer?.full_name,
                        customerPhoneCode:
                          storeWalletDetails?.customer?.phone_code,
                        customerPhoneNumber:
                          storeWalletDetails?.customer?.phone,
                      })
                    }
                  >
                    Fund Wallet
                  </primaryComponents.Button>
                </secondaryComponents.NavigateWithPermission>
                <secondaryComponents.NavigateWithPermission
                  permissionParentKey={"Inventory Management"}
                  permissionChildKey="can_debit_store_wallet"
                >
                  <primaryComponents.Button
                    type="button"
                    classNames={"btn btn--outline"}
                    onClick={() =>
                      setIsDebitWallet({
                        customerEmail: storeWalletDetails?.customer?.email,
                        customerId: storeWalletDetails?.customer?.id,
                        customerName: storeWalletDetails?.customer?.full_name,
                        customerPhoneCode:
                          storeWalletDetails?.customer?.phone_code,
                        customerPhoneNumber:
                          storeWalletDetails?.customer?.phone,
                      })
                    }
                  >
                    Debit Wallet
                  </primaryComponents.Button>
                </secondaryComponents.NavigateWithPermission>
                <secondaryComponents.ToolTipV2
                  tipText="Download Statement of Account"
                  position="left"
                >
                  <primaryComponents.Button
                    classNames={"btn btn--outline"}
                    style={{ padding: "0 15px" }}
                    onClick={() => setIsDownloadStatement(true)}
                  >
                    <DownloadStatementIcon />
                  </primaryComponents.Button>
                </secondaryComponents.ToolTipV2>
              </div>
            </div>

            <div className={styles.walletSection}>
              <div>
                <h6>Current Balance</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: storeWalletDetails?.balance,
                    currencyCode:
                      storeWalletDetails?.parent_store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Total Funded</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: storeWalletDetails?.total_funded,
                    currencyCode:
                      storeWalletDetails?.parent_store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Total Spent</h6>
                <span className="gray--text">
                  {formatCurrency({
                    value: storeWalletDetails?.total_spent,
                    currencyCode:
                      storeWalletDetails?.parent_store?.currency_code,
                  })}
                </span>
              </div>
              <div>
                <h6>Phone Number</h6>
                <span className="gray--text">
                  ({storeWalletDetails?.customer?.phone_code}){" "}
                  {storeWalletDetails?.customer?.phone}
                </span>
              </div>
              <div>
                <h6>Email Address</h6>
                <span className="gray--text">
                  {" "}
                  {storeWalletDetails?.customer?.email || "N/A"}
                </span>
              </div>
            </div>
          </secondaryComponents.LoaderHelper>

          <div className="mt-4">
            <h5 className="font-weight-semibold">
              Store Wallet Usage Activity
            </h5>
            <secondaryComponents.LoaderHelper
              classNames="mt-5"
              isLoading={isLoadingStoreWalletTnx}
            >
              <secondaryComponents.StoreWalletTransactionTable
                metaDetails={metaDetails}
                storeWalletTransactions={storeWalletTransactions}
                gotoPage={gotoPage}
              />
            </secondaryComponents.LoaderHelper>
          </div>
        </motion.div>

        <secondaryComponents.SideModal
          isActive={isFundWallet !== null}
          closeModal={() => setIsFundWallet(null)}
          width={600}
        >
          <secondaryComponents.StoreWalletFund
            setIsFundWallet={setIsFundWallet}
            customerDetails={isFundWallet}
          />
        </secondaryComponents.SideModal>

        <secondaryComponents.SideModal
          isActive={isDebitWallet !== null}
          closeModal={() => setIsDebitWallet(null)}
          width={600}
        >
          <secondaryComponents.StoreWalletDebit
            setIsDebitWallet={setIsDebitWallet}
            customerDetails={isDebitWallet}
          />
        </secondaryComponents.SideModal>

        <secondaryComponents.SideModal
          isActive={isDownloadStatement}
          closeModal={() => setIsDownloadStatement(false)}
          // width={600}
        >
          <secondaryComponents.StoreWalletDownloadStatement
            closeWidget={() => setIsDownloadStatement(false)}
          />
        </secondaryComponents.SideModal>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default StoreWalletDetails;
