import DeleteTagDialogLogic from "./DeleteTagDialogLogic";
import primaryComponents from "../../primaryComponents";

const DeleteTagDialog = ({ closeModal, tagDetails }) => {
  const { handleDeleteTag, isDeletingTag } = DeleteTagDialogLogic({
    closeModal,
    tagDetails,
  });
  return (
    <div className="px-2 py-2">
      <h5 className="text-center font-weight-semibold">
        Are you sure you want to delete this tag? All associated data would be
        deleted as well.
      </h5>

      <p className="text-center">Tag name: {tagDetails?.name}</p>

      <div className={"d-flex mt-4"} style={{ gap: "15px" }}>
        <primaryComponents.Button
          onClick={() => closeModal()}
          classNames={"btn btn--outline cover"}
        >
          Cancel
        </primaryComponents.Button>
        <primaryComponents.Button
          onClick={() => handleDeleteTag()}
          classNames={"btn btn--danger cover"}
          isLoading={isDeletingTag}
          isDisabled={isDeletingTag}
          loadingText="Loading..."
        >
          Continue
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default DeleteTagDialog;
