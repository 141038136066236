import { useGetOrderDetails } from "../../../../hooks/api/queries/useOrder";

const useOrderflows = ({ orderId }) => {
  const {
    isLoading,
    data: orderDetails,
    refetch,
  } = useGetOrderDetails({ id: orderId });

  return {
    isLoading,
    orderDetails,
    handleRefetchOrder: refetch,
  };
};
export default useOrderflows;
