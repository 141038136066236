import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  hasVariants: yup.boolean(),
  storeProperties: yup.array().when("hasVariants", {
    is: false,
    then: yup.array().of(
      yup.object().shape({
        quantity: yup
          .number()
          .typeError("A valid stock quantity is required")
          .required("A valid stock quantity is required"),
        store_id: yup.string().required("Store is required").nullable(),
      }),
    ),
  }),
  storeVariantProperties: yup.array().when("hasVariants", {
    is: true,
    then: yup.array().of(
      yup.object().shape({
        store_id: yup.string().required("Store is required").nullable(),
        variants: yup
          .array()
          .min(1, "At least a variant is required")
          .of(
            yup.object().shape({
              stock_quantity: yup
                .number()
                .typeError("A valid stock quantity is required")
                .required("A valid stock quantity is required"),
            }),
          ),
      }),
    ),
  }),
});

export const Validation = ({
  hasVariants,
  storeProperties,
  storeVariantProperties,
}) => {
  return useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      hasVariants,
      storeProperties,
      storeVariantProperties,
    },
  });
};
