export const TransferIcon = ({ width = "28", height = "28" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6616_17322)">
        <path
          d="M10.1005 26.5997L11.7504 28.2496L13.4003 29.8995C13.8559 30.3551 14.5946 30.3551 15.0502 29.8995L23.2998 21.6499L27.4246 24.1248L28.2496 11.7504L15.8752 12.5754L18.3501 16.7002L10.1005 24.9497C9.64486 25.4054 9.64488 26.144 10.1005 26.5997Z"
          stroke="#19AE57"
          strokeWidth="2.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6616_17322">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(20 0.201019) rotate(45)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
