import "./ConfirmDialog.scss";
import secondaryComponents from "..";
import primaryComponents from "../../primaryComponents";

const ConfirmDialog = ({
  isOpen,
  setIsOpen,
  title = "",
  handler = () => {},
  isLoading = false,
  children,
}) => {
  return (
    <secondaryComponents.Modal
      isActive={isOpen}
      closeModal={() => setIsOpen(false)}
      width="450px"
    >
      <div className="confirm-dialog">
        <h4 className="text-center font-weight-semibold">{title}</h4>
        <div className="confirm-dialog__content">{children}</div>
        <div className="confirm-dialog__actions">
          <primaryComponents.Button
            onClick={() => setIsOpen(false)}
            classNames={"btn btn--outline"}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            onClick={() => handler()}
            classNames={"btn btn--danger"}
            isLoading={isLoading}
            isDisabled={isLoading}
            loadingText="Loading..."
          >
            Continue
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};

export default ConfirmDialog;
