import { useSearchParams } from "react-router-dom";

export const useUrlSearch = () => {
  const [search, setSearch] = useSearchParams();

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return { gotoPage };
};
