import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const CreditLimitSchema = yup.object({
  creditLimit: yup.string().required("Credit Limit is required"),
});

const Validation = (creditLimit) => {
  return useForm({
    resolver: yupResolver(CreditLimitSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      creditLimit,
    },
  });
};

export default Validation;
