import "./GiftCardWidget.scss";
import Icon from "../../../assets/icons";
import Images from "../../../assets/images";
import primaryComponents from "../../primaryComponents";
import { motion } from "framer-motion";
import { useRedeemGiftCardLogic } from "./GiftCardWidgetLogic";
import { useRedeemGiftCardFormValidation } from "./GiftCardWidgetValidation";
import { Controller } from "react-hook-form";
import { Fragment } from "react";
import { formatCurrency } from "../../../helpers";
import { useSelector } from "react-redux";

const GiftCardWidget = ({ closeWidget }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const {
    redemption_code,
    isSubmitting,
    step,
    giftcardDetails,
    setRedemptionCode,
    handleRedeemGiftCard,
  } = useRedeemGiftCardLogic({ closeWidget });

  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    register,
  } = useRedeemGiftCardFormValidation({ redemption_code });

  return (
    <div className="gift-card-widget">
      <motion.div
        initial={{ opacity: 0, marginRight: "-400px" }}
        animate={{
          opacity: 1,
          marginRight: "0px",
          transition: { duration: 0.3 },
        }}
        exit={{ opacity: 0 }}
        className="gift-card-widget__content"
      >
        <div
          className="gift-card-widget__content__close"
          onClick={() => closeWidget(true)}
        >
          <img style={{ cursor: "pointer" }} src={Icon.close} alt="" />
        </div>
        {step === 0 && (
          <Fragment>
            <div className="gift-card-widget__content__main">
              <div className="gift-card-widget__content__desc">
                <h4 className="font-weight-semibold">Gift card redemption</h4>
              </div>
              <form
                onSubmit={handleSubmit(handleRedeemGiftCard)}
                className="gift-card-widget__content__form"
                id="redemption-form"
              >
                <Controller
                  control={control}
                  name="redemtion_code"
                  defaultValue={redemption_code}
                  render={({ field: { onChange } }) => (
                    <primaryComponents.InputField
                      label="Enter gift card code"
                      isRequired={true}
                      placeholder="Enter the receipt or invoice of the item to apply discount on"
                      name="redemption_code"
                      value={redemption_code}
                      onChange={({ target: { value } }) => {
                        onChange(value);
                        setRedemptionCode(value);
                      }}
                      onKeyUp={() =>
                        errors.redemption_code !== undefined &&
                        trigger("redemption_code")
                      }
                      errorMessage={errors.redemption_code}
                      register={register}
                    />
                  )}
                />
              </form>
            </div>
            <div className="gift-card-widget__content__button">
              <primaryComponents.Button
                className="btn btn--primary w-100"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                loadingText={"Redeeming..."}
                type="submit"
                form="redemption-form"
              >
                Validate
              </primaryComponents.Button>
            </div>
          </Fragment>
        )}
        {step === 1 && (
          <Fragment>
            <div className="mt-4 d-flex flex-column h-100">
              <img
                width={"120"}
                height={"120"}
                src={Images.success_image}
                className="mx-auto mb-3"
                alt=""
              />
              <h4 className="font-weight-semibold text-center">
                Gift Card Redemption Successful
              </h4>
              <p className="text-center mt-2">
                Kindly Issue Product/Service to the customer with the details
                below
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginTop: "32px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <p>Name</p>
                  <p className="dark--text">
                    {giftcardDetails?.customer_name || "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Gender</p>
                  <p className="dark--text">
                    {giftcardDetails?.gender || "N/A"}
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <p>Phone Number</p>
                  <p className="dark--text">
                    {giftcardDetails?.phone || "N/A"}
                  </p>
                </div>
              </div>
              <div
                style={{ marginTop: "85px" }}
                className="d-flex flex-column align-items-center justify-content-center"
              >
                <h4 className="font-weight-normal big-text-size">
                  {giftcardDetails?.giftcard_name}
                </h4>
                <h3
                  style={{ color: "#47BE79" }}
                  className="font-weight-semibold"
                >
                  {formatCurrency({
                    value: giftcardDetails?.giftcard_naira_value,
                    currencyCode: currency_code,
                  }) || "N/A"}
                </h3>
              </div>
            </div>
          </Fragment>
        )}
      </motion.div>
    </div>
  );
};

export default GiftCardWidget;
