/* eslint-disable react/jsx-key */
import "./CustomerTransactionsTable.scss";
import useCustomerTransactionsTable from "./CustomerTransactionsTableLogic";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const CustomerTransactionsTable = ({ customerId }) => {
  const { instance, transactions, isFetchingTransactions, gotoPage } =
    useCustomerTransactionsTable(customerId);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className="customer-transactions__table">
      <secondaryComponents.LoaderHelper
        isLoading={isFetchingTransactions}
        classNames="mt-5"
      >
        {transactions.length > 0 ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        className="font-weight-semibold"
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps({ key: i })}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="text-center mt-5">
            <img
              width="250px"
              height="262px"
              src={Images.tnxEmptyState}
              alt="empty state"
            />
            <h4>You have no transactions</h4>
            <p>When you do, they would appear here.</p>
          </div>
        )}
      </secondaryComponents.LoaderHelper>

      {/* show pagination component only when data is available */}
      {transactions?.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={transactions?.meta?.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={transactions?.meta?.current_page}
        />
      )}
    </div>
  );
};

export default CustomerTransactionsTable;
