import React from "react";

export const UserSuccessIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="8" fill="#E8F7EE" />
      <path
        d="M22 24C24.2091 24 26 22.2091 26 20C26 17.7909 24.2091 16 22 16C19.7909 16 18 17.7909 18 20C18 22.2091 19.7909 24 22 24Z"
        stroke="#19AE57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 34V30C15 28.8954 15.8954 28 17 28H27C28.1046 28 29 28.8954 29 30V34"
        stroke="#19AE57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 16C29.8604 16.2203 30.623 16.7207 31.1676 17.4223C31.7122 18.1239 32.0078 18.9868 32.0078 19.875C32.0078 20.7632 31.7122 21.6261 31.1676 22.3277C30.623 23.0293 29.8604 23.5297 29 23.75"
        stroke="#19AE57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 28H33C34.1046 28 35 28.8954 35 30V34"
        stroke="#19AE57"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
