import MenuPage from "./MenuPage";
import SubscriptionView from "./SubscriptionView";
import AllRiderOrders from "./RiderOrders/AllRiderOrders";
import RiderOrderDetails from "./RiderOrders/RiderOrderDetails";
import OrderStatusTrack from "./OrderStatusTrack";

const publicPages = {
  MenuPage,
  SubscriptionView,
  AllRiderOrders,
  RiderOrderDetails,
  OrderStatusTrack,
};

export default publicPages;
