import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { pathConstants } from "../../../routes/pathContants";
import useCountryChecker from "../../../hooks/useCountryChecker";

const CountryChecker = ({ children }) => {
  const { isNigeria } = useCountryChecker();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isNigeria) return navigate(pathConstants.DASHBOARD_MAIN);
  });

  return children;
};
export default CountryChecker;
