import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
import {
  formatDate,
  formatNumberWithCommas,
  getPlatform,
} from "../../../helpers";
import { deleteOrderTable } from "../../../services/inventory";
import { useMutation } from "react-query";
import { platformHandler } from "../../../hooks/context";
import secondaryComponents from "..";

export const useRestaurantTable = ({ orderTables, handleFetchOrderTables }) => {
  const [isopenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [tableDetails, setTableDetails] = useState(null);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { platform } = useContext(platformHandler);
  const deleteOrderTableMutation = useMutation(
    ["deleteOrderTable"],
    deleteOrderTable,
  );

  const handleDeleteOrderTable = (table_id) => {
    deleteOrderTableMutation.mutate(table_id, {
      onSuccess: () => {
        handleFetchOrderTables();
        setIsOpenDeleteDialog(false);
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Table No.",
        accessor: "name",
      },
      {
        Header: "Store",
        accessor: "store",
        Cell: ({ cell }) => {
          const store = storeList?.find((store) => store?.id === cell?.value);
          return (
            <span
              style={{
                padding: "10px 12px",
                backgroundColor: "rgba(242, 237, 254, 1)",
                color: "rgba(118, 71, 238, 1)",
                fontSize: "10px",
                borderRadius: "8px",
              }}
            >
              {store?.name}
            </span>
          );
        },
      },
      {
        Header: "Table Capacity",
        accessor: "seat_capacity",
        Cell: ({ cell: { value } }) => formatNumberWithCommas(value),
      },
      {
        Header: "Last Updated on",
        accessor: "created_at",
        Cell: ({ cell }) => formatDate(cell?.value),
      },
      {
        Header: "Action",
        accessor: null,
        Cell: ({ cell: { row } }) => {
          return (
            <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_view_edit_and_create_tables"
              >
                <Link
                  to={`${getPlatform(platform)}/inventory/tables/${
                    row?.original?.id
                  }`}
                  style={{
                    backgroundColor: "rgba(118, 71, 238, 1)",
                    padding: "7px 24px",
                    borderRadius: "8px",
                    border: "none",
                    outline: "none",
                    fontSize: "12px",
                    color: "white",
                  }}
                >
                  Edit
                </Link>
                <button
                  onClick={() => {
                    setTableDetails(row?.original);
                    setIsOpenDeleteDialog(true);
                  }}
                  style={{
                    backgroundColor: "white",
                    padding: "7px 24px",
                    borderRadius: "8px",
                    border: "1px solid rgba(224, 32, 32, 1)",
                    outline: "none",
                    fontSize: "12px",
                    color: "rgba(224, 32, 32, 1)",
                  }}
                >
                  Delete
                </button>
              </secondaryComponents.NavigateWithPermission>
            </div>
          );
        },
      },
    ],
    [],
  );

  const instance = useTable({ columns, data: orderTables });

  return {
    instance,
    isopenDeleteDialog,
    isDeletingTable: deleteOrderTableMutation.isLoading,
    tableDetails,
    setIsOpenDeleteDialog,
    handleDeleteOrderTable,
  };
};
