import { motion } from "framer-motion";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./MarketDetails.module.scss";
import appsData from "../../../../localdata/marketApps";
import Error404 from "../../../ErrorPages/Error404";
import {
  AppConnectionIcon,
  AppDisconnectionIcon,
  Connected,
  GuardIcon,
  HelpIcon,
  PaperNotesIcon,
  SecurityIcon,
} from "../../../../assets/svgIcons";

const MarketDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const app = appsData.find((app) => app.id === id);

  if (!app) {
    return <Error404 />;
  }
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | App Market Details"
      description="View app Market Details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          className={globalStyles.pagePaddings}
        >
          <div className="d-flex" style={{ alignItems: "center" }}>
            <primaryComponents.Button
              classNames="btn btn--outline"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon
                icon="angle-left"
                className="mr-3"
                style={{ fontSize: "18px" }}
              />
              <span>Back</span>
            </primaryComponents.Button>
            {/* <h4 className="ml-3">Stock Transfer Review</h4> */}
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className={`${styles.marketDetails__card}`}>
              <div className={styles.marketDetails__header}>
                <div className="d-flex">
                  {app.id === "vfd" ? (
                    app.imageSrc
                  ) : app.id === "paystackPOS" ? (
                    app.imageSrc
                  ) : (
                    <img
                      src={app.imageSrc}
                      alt={app.altText}
                      width={100}
                      height={100}
                      className="rounded mr-2"
                    />
                  )}
                  <div
                    className="mt-2"
                    //style={{ flexDirection: "column", gap: "5px" }}
                  >
                    <h5>{app?.appName}</h5>
                    <span className="comingSoon--app">FREE TO USE</span> <br />
                    {app.status === "Connect" ? (
                      <span
                        className={`smallest-text-size p-1 mt-1 ${
                          app?.status === "Connect"
                            ? "comingSoon--connect"
                            : "comingSoon--rounded"
                        }`}
                      >
                        <div className="d-flex" style={{ gap: "4px" }}>
                          <Connected /> <span>Connected</span>
                        </div>
                      </span>
                    ) : null}
                  </div>
                </div>
                {app.status === "Connect" ? (
                  <div>
                    <primaryComponents.Button
                      classNames="btn comingSoon--disconnect"
                      onClick={() => {
                        window.open(app.route, "_blank", "noopener,noreferrer");
                      }}
                    >
                      <AppDisconnectionIcon />{" "}
                      <span className="small-text-size pl-1">Disconnect</span>
                    </primaryComponents.Button>
                  </div>
                ) : (
                  <div>
                    <primaryComponents.Button
                      classNames="btn btn--success"
                      onClick={() => {
                        window.open(app.route, "_blank", "noopener,noreferrer");
                      }}
                      isDisabled={app.status !== "Connect"}
                    >
                      <AppConnectionIcon />{" "}
                      <span className="small-text-size p-2">Connect</span>
                    </primaryComponents.Button>
                  </div>
                )}
              </div>
              <p className="default-text-size p-2">{app?.full_description}</p>
              <hr />
              {app?.videoUrl !== "" ? (
                <div
                  style={{
                    height: "400px",
                    overflow: "hidden",
                    width: "90%",
                    marginBottom: "30px",
                  }}
                >
                  <iframe
                    width="100%"
                    height="100%"
                    loading="lazy"
                    src={`https://www.youtube.com/embed/${app?.videoUrl}`}
                    title="title"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullScreen
                    style={{ borderRadius: "12px", overflow: "hidden" }}
                  ></iframe>
                </div>
              ) : null}
              <h5 className="default-text-size">Instructions</h5>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {app?.getting_started?.map((item, index) => (
                  <li key={index} className="small-text-size mt-2">
                    <strong>{item.step}:</strong>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </li>
                ))}
              </ul>
              <h5 className="default-text-size">Key Features</h5>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {app?.key_features?.map((item, index) => (
                  <li key={index} className="small-text-size mt-2">
                    <span dangerouslySetInnerHTML={{ __html: item }} />
                  </li>
                ))}
              </ul>
              <h5 className="default-text-size">Getting Started</h5>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {app?.getting_started?.map((item, index) => (
                  <li key={index} className="small-text-size mt-2">
                    {item?.step}.{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.marketDetails__sideCard}>
              <div>
                <h6 className="default-text-size">App Information</h6>
                <p className="small-text-size">
                  <strong>Developer:</strong> {app?.app_information?.developer}
                </p>
                <p className="small-text-size">
                  <strong>Category:</strong> {app?.app_information?.category}
                </p>
              </div>
              <div>
                <h6 className="default-text-size">Pricing Information</h6>
                <p className="small-text-size">
                  {app?.app_information?.pricing_information}
                </p>
              </div>
              <div>
                <h6 className="default-text-size">Support</h6>
                <div className="d-flex text-center p-1">
                  <GuardIcon />
                  <a className="smallest-text-size ml-2" href={app?.url}>
                    {app?.support?.user_guide}
                  </a>
                </div>
                <div className="d-flex text-center p-1">
                  <HelpIcon />
                  <a
                    className="smallest-text-size ml-2"
                    href={app?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {app?.support?.email}
                  </a>
                </div>
              </div>
              <div>
                <h6 className="default-text-size">Legal</h6>
                <div className="d-flex text-center p-1">
                  <PaperNotesIcon />
                  <a
                    className="smallest-text-size ml-2"
                    href={app?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {app?.legal?.terms_of_service}
                  </a>
                </div>
                <div className="d-flex text-center p-1">
                  <SecurityIcon />
                  <a
                    className="smallest-text-size ml-2"
                    href={app?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {app?.legal?.privacy_policy}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default MarketDetails;
