import { useSelector } from "react-redux";
import { useGetUserList } from "../../../../hooks/api/queries/useSettings";

const useEndOfDayForm = () => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const { data: userList } = useGetUserList();

  return {
    storeList,
    userList,
  };
};
export default useEndOfDayForm;
