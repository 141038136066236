import Images from "../../../assets/images";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { InventoryRevenueChartLogic } from "./InventoryRevenueChartLogic";
import { Fragment } from "react";
import styles from "./InventoryRevenueChart.module.scss";
import { formatCurrency } from "../../../helpers";
import InventoryRevenueChartTooltip from "./InventoryRevenueChartTooltip";

const InventoryRevenueChart = ({ data, store_currency_code }) => {
  const { tickFormatter, yAxisFormat, calcTotalRevenue } =
    InventoryRevenueChartLogic();

  if (data?.length <= 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <img
          width={"150px"}
          src={Images.transaction_empty_state}
          alt="No transactions"
        />
        <p className="font-weight-semibold mt-3 dark--text">
          No data available
        </p>
      </div>
    );
  }
  return (
    <Fragment>
      <div className={styles["chart-legend"]}>
        <div></div>
        <span>
          Revenue -{" "}
          {formatCurrency({
            value: calcTotalRevenue(data),
            currencyCode: store_currency_code,
          })}
        </span>
      </div>
      <ResponsiveContainer
        width={"100%"}
        aspect={2}
        className={"inventory-revenue-chart"}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <AreaChart data={data} height={250} width={"auto"}>
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              {/* <stop offset="5%" stopColor="#19AE57" stopOpacity={0.8} /> */}
              <stop offset="90%" stopColor="#19AE57" stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            dataKey="count"
            stroke="#19AE57"
            fillOpacity={0.2}
            fill="url(#colorUv)"
            // dot={{ stroke: "#7647EE", strokeWidth: 1, r: 2, strokeDasharray:""}}
          />
          <CartesianGrid
            stroke="#E8E9EB"
            strokeDasharray="5 0"
            horizontal={true}
            vertical={false}
          />
          <YAxis
            tick={{ fontSize: "10px" }}
            dataKey="count"
            tickLine={false}
            axisLine={false}
            tickFormatter={yAxisFormat}
            // label={{ value: "Revenue (₦)", angle: -90, position: "insideLeft", textAnchor: "middle", fontSize: "12px" }}
          />
          <XAxis
            dataKey="date"
            tickFormatter={tickFormatter}
            tickLine={false}
            tick={{ fontSize: "10px" }}
            axisLine={false}
            // label={{ value: "Day", offset: 0, position: "insideBottom", fontSize: "12px" }}
          />
          <Tooltip
            wrapperStyle={{ border: "none", outline: "none" }}
            content={<InventoryRevenueChartTooltip />}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Fragment>
  );
};

export default InventoryRevenueChart;
