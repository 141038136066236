import { useTable } from "react-table";
import { useEffect, useMemo, useState } from "react";
import { getOrders } from "../../../services/inventory";
import { formatCurrency } from "../../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "../../secondaryComponents";
import { useSelector } from "react-redux";
import { DeleteIcon } from "../../../assets/svgIcons";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

export const useBillsWidget = ({
  tableCardId,
  closeWidget,
  cancelCurrentOrder,
}) => {
  const navigate = useNavigate();
  const { store_id } = useParams();
  const [isOrderCancel, setIsOrderCancel] = useState(null);
  const [queryParams, setQueryParams] = useState({
    payment_method: "bill",
    store_id,
  });
  const { store_currency } = useStoreCurrency();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [billWasUpdated, setBillWasUpdated] = useState(null);
  const { isLoading: isFetchingBills, data: bills } = useQuery(
    ["getOrders", tableCardId, queryParams],
    () => getOrders(queryParams).then((res) => res?.data),
    // { staleTime: Infinity },
  );
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const storeProperties = (() => {
    return storeList?.find((item) => `${item?.id}` === store_id);
  })();
  const showKitchen = storeProperties?.allow_section;

  useEffect(() => {
    if (tableCardId !== null && tableCardId !== undefined && tableCardId !== "")
      setQueryParams((prev) => ({ ...prev, table_id: tableCardId }));
  }, [tableCardId]);

  const columns = useMemo(() => {
    const headers1 = [
      {
        Header: "Order Date/Time",
        accessor: "created_at",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined
              ? "N/A"
              : new Date(value).toLocaleString("en-us")}
          </span>
        ),
      },
      {
        Header: "Order Number",
        accessor: "id",
        Cell: ({ cell: { value } }) => (
          <span
            onClick={() => {
              setSelectedOrder(value);
            }}
            style={{
              color: "#7647EE",
              height: "28px",
              cursor: "pointer",
            }}
          >
            {value}
          </span>
        ),
      },
      {
        Header: "Customer Name",
        accessor: "customer",
        Cell: ({ cell: { value } }) =>
          value === null
            ? "N/A"
            : value?.first_name +
              (value?.last_name ? " " + value?.last_name : ""),
      },
      {
        Header: "Table",
        accessor: "table",
        Cell: ({ cell: { value } }) => (value === null ? "N/A" : value?.name),
      },
    ];
    const headers2 = [
      {
        Header: "Cashier",
        accessor: "cashier",
        Cell: ({ cell: { value } }) => (
          <span>
            {value === null || value === undefined ? "N/A" : value?.first_name}
          </span>
        ),
      },
      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: ({ cell: { value } }) => (
          <span>
            {formatCurrency({ value: value, currencyCode: store_currency })}
          </span>
        ),
      },
      {
        Header: "Actions",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span
                style={{
                  height: "12px",
                  width: "12px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(25, 174, 87, 1)",
                  visibility: row.original?.is_paid ? "visible" : "hidden",
                  display: "block",
                }}
              ></span>

              <primaryComponents.Button
                classNames="btn btn--primary smallBtn"
                isDisabled={row.original?.is_paid}
                onClick={() => {
                  navigate(
                    `/sell-mode/${store_id}?order_id=${row.original?.id}`,
                  );
                  closeWidget();
                }}
              >
                Checkout
              </primaryComponents.Button>
              <primaryComponents.Button
                classNames="btn btn--outline smallBtn"
                isDisabled={row.original?.is_paid}
                onClick={() => {
                  setBillWasUpdated(row?.original?.id);
                }}
              >
                Update
              </primaryComponents.Button>
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_cancel_bill"
              >
                <span
                  onClick={() => setIsOrderCancel(row?.original)}
                  style={{ cursor: "pointer" }}
                >
                  <DeleteIcon />
                </span>
              </secondaryComponents.NavigateWithPermission>
            </span>
          );
        },
      },
    ];

    let headers = [];
    if (showKitchen)
      headers = [
        ...headers1,
        {
          Header: "Docket Status",
          accessor: "is_section_items_printed",
          Cell: ({ cell: { value } }) =>
            !value ? (
              <span
                style={{ padding: "3px 5px", textWrap: "nowrap" }}
                className="smallest-text-size pending--squared"
              >
                Newly Updated
              </span>
            ) : (
              "N/A"
            ),
        },
        ...headers2,
      ];
    else headers = [...headers1, ...headers2];

    return headers;
  }, []);

  const instance = useTable({ columns, data: bills?.data || [] });

  const gotoPage = (pageNumber) => {
    const newQueryParams = { ...queryParams };
    newQueryParams.page = pageNumber;
    setQueryParams(newQueryParams);
  };

  const handleSearchOrders = (value) => {
    const newQueryParams = { ...queryParams };
    if (value?.length === 0) delete newQueryParams.query;
    else newQueryParams.query = value;
    delete newQueryParams.page;
    setQueryParams(newQueryParams);
  };

  const handleFilterByPayment = (value) => {
    const newQueryParams = { ...queryParams };
    delete newQueryParams.page;
    if (value === "") {
      delete newQueryParams.status;
      delete newQueryParams.payment_method;
    } else if (value === "bill") {
      delete newQueryParams.status;
      newQueryParams.payment_method = value;
    } else {
      delete newQueryParams.payment_method;
      newQueryParams.status = value;
    }
    setQueryParams(newQueryParams);
  };

  return {
    instance,
    bills: bills?.data || [],
    isFetchingBills,
    billPageDetails: bills?.meta || {},
    selectedOrder,
    setSelectedOrder,
    handleFilterByPayment,
    gotoPage,
    handleSearchOrders,
    isOrderCancel,
    setIsOrderCancel,
    cancelCurrentOrder,
    billWasUpdated,
    setBillWasUpdated,
  };
};
