import styles from "./BusinessForm.module.scss";
import Icon from "../../../../assets/icons";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import BusinessFormValidation from "./BusinessFormValidation";
import { Controller } from "react-hook-form";
import localData from "../../../../localdata";
import useBusinessForm from "./useBusinessForm";
import secondaryComponents from "../../../../components/secondaryComponents";

const BusinessForm = ({ SignupLogic }) => {
  const {
    handlePreviousStep,
    businessName,
    setBusinessName,
    businessSize,
    setBusinessSize,
    jobTitle,
    setJobTitle,
    serviceType,
    setServiceType,
    referral,
    setReferral,
    handleNextStep,
  } = SignupLogic;
  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    control,
  } = BusinessFormValidation(
    businessName,
    jobTitle,
    businessSize,
    serviceType,
    referral,
  );

  const { isLoading, serviceOfInterestOption } = useBusinessForm();

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0 }}
      className={styles.businessForm}
      onSubmit={handleSubmit(handleNextStep)}
    >
      <div>
        <h3 className="mb-0 font-weight-normal">Tell us about your business</h3>
        <p>Kindly provide the following details about your business.</p>
      </div>
      <div className={styles.businessForm__body}>
        <Controller
          name="businessName"
          control={control}
          defaultValue={businessName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label="Business Name"
              placeholder="Enter your business name"
              isRequired={true}
              type="text"
              name="businessName"
              errorMessage={errors.businessName}
              onChange={({ target: { value } }) => {
                onChange(value);
                setBusinessName(value);
              }}
              onKeyUp={() =>
                errors.businessName !== undefined && trigger("businessName")
              }
              register={register}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={businessSize}
          name="businessSize"
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Business size"
              isRequired={true}
              placeholder="Enter business size"
              value={
                businessSize === "" ? "Select business size..." : businessSize
              }
              options={localData.businessSizeOptions}
              errorMessage={errors.businessSize}
              onChange={(value) => {
                onChange(value);
                setBusinessSize(value);
              }}
              isSearchable={false}
            />
          )}
        />

        <Controller
          control={control}
          defaultValue={jobTitle}
          name="jobTitle"
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="Job title"
              isRequired={true}
              placeholder="Enter job title"
              name="jobTitle"
              value={jobTitle === "" ? "Select job title..." : jobTitle}
              errorMessage={errors.jobTitle}
              options={localData.jobTitleOptions}
              onChange={(value) => {
                onChange(value);
                setJobTitle(value);
              }}
              isSearchable={false}
            />
          )}
        />
        <secondaryComponents.LoaderHelper
          isLoading={isLoading}
          classNames="mt-2"
        >
          <Controller
            control={control}
            defaultValue={serviceType}
            name="serviceType"
            render={({ field: { onChange } }) => (
              <primaryComponents.MultipleSelectField
                label="Please indicate the services that you are particularly interested in:"
                isRequired={true}
                placeholder="Select service..."
                options={serviceOfInterestOption}
                errorMessage={errors.serviceType}
                idKey="id"
                nameKey="name"
                name="serviceType"
                onChange={(values) => {
                  onChange(values);
                  let newValues = [];
                  values?.forEach((item) => {
                    newValues.push(item.value);
                  });
                  setServiceType(newValues);
                }}
              />
            )}
          />
        </secondaryComponents.LoaderHelper>
        <Controller
          control={control}
          defaultValue={referral}
          name="referral"
          render={({ field: { onChange } }) => (
            <primaryComponents.SelectField
              label="How did you hear about us"
              isRequired={true}
              placeholder="Select referral..."
              value={referral === "" ? "Select referral..." : referral}
              options={localData.referralOptions}
              errorMessage={errors.referral}
              idKey="value"
              nameKey="label"
              onChange={(value) => {
                onChange(value);
                setReferral(value);
              }}
              isSearchable={false}
            />
          )}
        />
        <div className="mt-4 d-flex justify-content-between">
          {/* back button */}
          <primaryComponents.Button
            type="button"
            onClick={handlePreviousStep}
            classNames="btn btn--outline"
          >
            <img src={Icon.arrow_left} alt="" className="mr-2" width="15px" />
            <span>Previous</span>
          </primaryComponents.Button>
          {/* next button */}
          <primaryComponents.Button classNames="btn btn--primary" type="submit">
            <span>Next</span>
            <img src={Icon.arrow_right} alt="" width="15px" className="ml-2" />
          </primaryComponents.Button>
        </div>
      </div>
    </motion.form>
  );
};

export default BusinessForm;
