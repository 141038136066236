import React from "react";

export const TransferStockEmptyStateIcon = ({
  width = "180",
  height = "133",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 180 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.8435 132.699C129.274 132.699 158.827 102.999 158.827 66.2763C158.827 29.5537 129.274 0 92.8435 0C56.4135 0 26.8599 29.7 26.8599 66.4226C26.8599 103.145 56.4135 132.699 92.8435 132.699Z"
        fill="#EAEEF9"
      />
      <path
        d="M18.9554 57.3653C21.3776 57.3653 23.3411 55.4017 23.3411 52.9795C23.3411 50.5573 21.3776 48.5938 18.9554 48.5938C16.5332 48.5938 14.5696 50.5573 14.5696 52.9795C14.5696 55.4017 16.5332 57.3653 18.9554 57.3653Z"
        fill="#EAEEF9"
      />
      <path
        d="M14.5694 43.1397C16.2236 43.1397 17.5645 41.7988 17.5645 40.1446C17.5645 38.4904 16.2236 37.1494 14.5694 37.1494C12.9152 37.1494 11.5742 38.4904 11.5742 40.1446C11.5742 41.7988 12.9152 43.1397 14.5694 43.1397Z"
        fill="#EAEEF9"
      />
      <path
        d="M139.751 8.03916C141.405 8.03916 142.746 6.69818 142.746 5.04399C142.746 3.38981 141.405 2.04883 139.751 2.04883C138.096 2.04883 136.755 3.38981 136.755 5.04399C136.755 6.69818 138.096 8.03916 139.751 8.03916Z"
        fill="#EAEEF9"
      />
      <path
        d="M171.437 68.3983C174.509 68.3983 176.999 65.9079 176.999 62.8359C176.999 59.7638 174.509 57.2734 171.437 57.2734C168.365 57.2734 165.874 59.7638 165.874 62.8359C165.874 65.9079 168.365 68.3983 171.437 68.3983Z"
        fill="#EAEEF9"
      />
      <path
        d="M175.132 92.1105V92.111L175.142 92.11C176.386 91.9856 177.437 92.3588 178.176 93.0674C178.916 93.7767 179.352 94.8293 179.352 96.0765V111.578C179.352 113.81 177.618 115.544 175.386 115.544H4.61339C2.38148 115.544 0.647363 113.81 0.647363 111.578V96.0765C0.647363 93.8446 2.38148 92.1105 4.61339 92.1105H175.132Z"
        fill="white"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M169.412 113.251L169.412 113.251C167.262 115.128 164.437 116.258 161.357 116.258C158.276 116.258 155.452 115.128 153.301 113.233L153.301 113.233C150.731 110.991 149.109 107.693 149.109 104.011C149.109 97.2496 154.595 91.7639 161.357 91.7639C168.118 91.7639 173.604 97.2678 173.604 104.029C173.604 107.711 171.982 111.01 169.412 113.251Z"
        fill="#A3DFBC"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M171.249 96.3294C171.731 96.3294 172.121 95.9389 172.121 95.4572C172.121 94.9755 171.731 94.585 171.249 94.585C170.768 94.585 170.378 94.9755 170.378 95.4572C170.378 95.9389 170.768 96.3294 171.249 96.3294Z"
        fill="#EAEEF9"
      />
      <path
        d="M148.101 101.046C148.418 101.046 148.675 100.789 148.675 100.472C148.675 100.155 148.418 99.8984 148.101 99.8984C147.785 99.8984 147.528 100.155 147.528 100.472C147.528 100.789 147.785 101.046 148.101 101.046Z"
        fill="#EAEEF9"
      />
      <path
        d="M150.737 111.362C151.054 111.362 151.31 111.106 151.31 110.789C151.31 110.472 151.054 110.215 150.737 110.215C150.42 110.215 150.164 110.472 150.164 110.789C150.164 111.106 150.42 111.362 150.737 111.362Z"
        fill="#EAEEF9"
      />
      <path
        d="M153.801 111.362L161.846 108.841V98.5059L153.801 101.007V111.362Z"
        fill="#B7BFCD"
      />
      <path
        opacity="0.4"
        d="M169.892 111.362L161.846 108.841V98.5059L169.892 101.007V111.362Z"
        fill="#6D7C9A"
      />
      <path
        d="M153.801 111.362L161.846 108.841V98.5059L153.801 101.007V111.362Z"
        fill="url(#paint0_linear_7170_62453)"
      />
      <path
        d="M169.891 111.362L161.846 108.841V98.5059L169.891 101.007V111.362Z"
        fill="url(#paint1_linear_7170_62453)"
      />
      <path
        d="M161.782 103.615V113.815L153.866 111.334V101.134L161.782 103.615Z"
        fill="#EDF1F9"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M169.827 111.334L161.911 113.815V103.615L169.827 101.134V111.334Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        opacity="0.09"
        d="M160.024 112.08L153.801 111.382V101.046L161.846 103.567L160.024 112.08Z"
        fill="url(#paint2_linear_7170_62453)"
      />
      <path
        opacity="0.2"
        d="M163.669 110.257L169.892 111.382V101.046L161.846 103.567L163.669 110.257Z"
        fill="url(#paint3_linear_7170_62453)"
      />
      <path
        d="M150.031 98.4473L158.052 95.7469L161.706 98.4818L153.811 100.937L150.031 98.4473Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M169.882 100.937L161.987 98.4818L165.641 95.7469L173.662 98.4473L169.882 100.937Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M150.035 103.472L153.81 101.117L161.694 103.587L157.825 106.056L150.035 103.472Z"
        fill="#EFF4FD"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M173.657 103.472L165.868 106.056L161.998 103.587L169.883 101.117L173.657 103.472Z"
        fill="#EDF1F9"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M151.31 94.7339H150.811V94.2349H150.383V94.7339H149.884V95.1758H150.383V95.6748H150.811V95.1758H151.31V94.7339Z"
        fill="url(#paint4_linear_7170_62453)"
      />
      <path
        d="M152.406 107.253H151.907V106.754H151.479V107.253H150.98V107.695H151.479V108.194H151.907V107.695H152.406V107.253Z"
        fill="url(#paint5_linear_7170_62453)"
      />
      <path
        d="M174.22 107.282L173.609 106.974L173.918 106.363L173.394 106.099L173.086 106.709L172.475 106.401L172.202 106.942L172.813 107.25L172.505 107.861L173.028 108.125L173.336 107.514L173.947 107.823L174.22 107.282Z"
        fill="url(#paint6_linear_7170_62453)"
      />
      <path
        d="M10.2766 102.571H50.0194"
        stroke="#696677"
        strokeWidth="1.07413"
        strokeLinecap="round"
      />
      <path
        d="M10.2766 105.794H44.6487"
        stroke="#696677"
        strokeWidth="1.07413"
        strokeLinecap="round"
      />
      <path
        d="M175.132 57.4223V57.4228L175.142 57.4218C177.615 57.1745 179.352 59.1502 179.352 61.3883V76.8901C179.352 79.122 177.618 80.8561 175.386 80.8561H4.61339C2.38148 80.8561 0.647363 79.122 0.647363 76.8901V61.3883C0.647363 59.1564 2.38148 57.4223 4.61339 57.4223H175.132Z"
        fill="white"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M169.412 78.2512L169.412 78.2513C167.261 80.1283 164.437 81.2583 161.356 81.2583C158.276 81.2583 155.451 80.1284 153.301 78.2331L153.301 78.2329C150.731 75.9912 149.109 72.6926 149.109 69.0111C149.109 62.2496 154.595 56.7639 161.356 56.7639C168.118 56.7639 173.604 62.2678 173.604 69.0294C173.604 72.7109 171.982 76.0095 169.412 78.2512Z"
        fill="#FCDEB1"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M171.249 61.3294C171.73 61.3294 172.121 60.9389 172.121 60.4572C172.121 59.9755 171.73 59.585 171.249 59.585C170.768 59.585 170.378 59.9755 170.378 60.4572C170.378 60.9389 170.768 61.3294 171.249 61.3294Z"
        fill="#EAEEF9"
      />
      <path
        d="M148.101 66.0461C148.418 66.0461 148.674 65.7892 148.674 65.4723C148.674 65.1553 148.418 64.8984 148.101 64.8984C147.785 64.8984 147.528 65.1553 147.528 65.4723C147.528 65.7892 147.785 66.0461 148.101 66.0461Z"
        fill="#EAEEF9"
      />
      <path
        d="M150.737 76.3625C151.053 76.3625 151.31 76.1056 151.31 75.7887C151.31 75.4718 151.053 75.2148 150.737 75.2148C150.42 75.2148 150.163 75.4718 150.163 75.7887C150.163 76.1056 150.42 76.3625 150.737 76.3625Z"
        fill="#EAEEF9"
      />
      <path
        d="M153.801 76.3623L161.846 73.8414V63.5059L153.801 66.0073V76.3623Z"
        fill="#B7BFCD"
      />
      <path
        opacity="0.4"
        d="M169.891 76.3623L161.846 73.8414V63.5059L169.891 66.0073V76.3623Z"
        fill="#6D7C9A"
      />
      <path
        d="M153.801 76.3623L161.846 73.8414V63.5059L153.801 66.0073V76.3623Z"
        fill="url(#paint7_linear_7170_62453)"
      />
      <path
        d="M169.891 76.3623L161.846 73.8414V63.5059L169.891 66.0073V76.3623Z"
        fill="url(#paint8_linear_7170_62453)"
      />
      <path
        d="M161.781 68.6148V78.8148L153.866 76.3345V66.1344L161.781 68.6148Z"
        fill="#EDF1F9"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M169.827 76.3345L161.911 78.8148V68.6148L169.827 66.1344V76.3345Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        opacity="0.09"
        d="M160.023 77.0801L153.801 76.382V66.0464L161.846 68.5673L160.023 77.0801Z"
        fill="url(#paint9_linear_7170_62453)"
      />
      <path
        opacity="0.2"
        d="M163.669 75.2573L169.891 76.382V66.0464L161.846 68.5673L163.669 75.2573Z"
        fill="url(#paint10_linear_7170_62453)"
      />
      <path
        d="M150.03 63.4473L158.052 60.7469L161.706 63.4818L153.811 65.9366L150.03 63.4473Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M169.881 65.9366L161.986 63.4818L165.64 60.7469L173.662 63.4473L169.881 65.9366Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M150.035 68.4717L153.81 66.117L161.694 68.5874L157.824 71.0558L150.035 68.4717Z"
        fill="#EFF4FD"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M173.657 68.4717L165.868 71.0558L161.998 68.5874L169.882 66.117L173.657 68.4717Z"
        fill="#EDF1F9"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M151.31 59.7339H150.811V59.2349H150.383V59.7339H149.884V60.1758H150.383V60.6748H150.811V60.1758H151.31V59.7339Z"
        fill="url(#paint11_linear_7170_62453)"
      />
      <path
        d="M152.406 72.2534H151.907V71.7544H151.479V72.2534H150.98V72.6954H151.479V73.1944H151.907V72.6954H152.406V72.2534Z"
        fill="url(#paint12_linear_7170_62453)"
      />
      <path
        d="M174.22 72.2818L173.609 71.9735L173.917 71.363L173.394 71.0988L173.086 71.7093L172.475 71.4011L172.202 71.9418L172.813 72.2501L172.504 72.8606L173.028 73.1248L173.336 72.5143L173.947 72.8225L174.22 72.2818Z"
        fill="url(#paint13_linear_7170_62453)"
      />
      <path
        d="M10.2764 68.0654H48.945"
        stroke="#696677"
        strokeWidth="1.07413"
        strokeLinecap="round"
      />
      <path
        d="M10.2764 72.3613H62.9087"
        stroke="#696677"
        strokeWidth="1.07413"
        strokeLinecap="round"
      />
      <path
        d="M175.132 22.3637V22.3642L175.142 22.3632C177.615 22.116 179.352 24.0917 179.352 26.3298V41.8315C179.352 44.0634 177.618 45.7975 175.386 45.7975H4.61339C2.38148 45.7975 0.647363 44.0634 0.647363 41.8315V26.3298C0.647363 24.0978 2.38148 22.3637 4.61339 22.3637H175.132Z"
        fill="white"
        stroke="black"
        strokeWidth="0.2"
      />
      <path
        d="M169.412 43.2512L169.412 43.2513C167.262 45.1283 164.437 46.2583 161.357 46.2583C158.276 46.2583 155.452 45.1284 153.301 43.2331L153.301 43.2329C150.731 40.9912 149.109 37.6926 149.109 34.0111C149.109 27.2496 154.595 21.7639 161.357 21.7639C168.118 21.7639 173.604 27.2678 173.604 34.0294C173.604 37.7109 171.982 41.0095 169.412 43.2512Z"
        fill="#C8B5F8"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M171.249 26.3294C171.731 26.3294 172.121 25.9389 172.121 25.4572C172.121 24.9755 171.731 24.585 171.249 24.585C170.768 24.585 170.378 24.9755 170.378 25.4572C170.378 25.9389 170.768 26.3294 171.249 26.3294Z"
        fill="#EAEEF9"
      />
      <path
        d="M148.101 31.0461C148.418 31.0461 148.675 30.7892 148.675 30.4723C148.675 30.1553 148.418 29.8984 148.101 29.8984C147.785 29.8984 147.528 30.1553 147.528 30.4723C147.528 30.7892 147.785 31.0461 148.101 31.0461Z"
        fill="#EAEEF9"
      />
      <path
        d="M150.737 41.3625C151.054 41.3625 151.31 41.1056 151.31 40.7887C151.31 40.4718 151.054 40.2148 150.737 40.2148C150.42 40.2148 150.164 40.4718 150.164 40.7887C150.164 41.1056 150.42 41.3625 150.737 41.3625Z"
        fill="#EAEEF9"
      />
      <path
        d="M153.801 41.3623L161.846 38.8414V28.5059L153.801 31.0073V41.3623Z"
        fill="#B7BFCD"
      />
      <path
        opacity="0.4"
        d="M169.892 41.3623L161.846 38.8414V28.5059L169.892 31.0073V41.3623Z"
        fill="#6D7C9A"
      />
      <path
        d="M153.801 41.3623L161.846 38.8414V28.5059L153.801 31.0073V41.3623Z"
        fill="url(#paint14_linear_7170_62453)"
      />
      <path
        d="M169.891 41.3623L161.846 38.8414V28.5059L169.891 31.0073V41.3623Z"
        fill="url(#paint15_linear_7170_62453)"
      />
      <path
        d="M161.782 33.6148V43.8148L153.866 41.3345V31.1344L161.782 33.6148Z"
        fill="#EDF1F9"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M169.827 41.3345L161.911 43.8148V33.6148L169.827 31.1344V41.3345Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        opacity="0.09"
        d="M160.024 42.0801L153.801 41.382V31.0464L161.846 33.5673L160.024 42.0801Z"
        fill="url(#paint16_linear_7170_62453)"
      />
      <path
        opacity="0.2"
        d="M163.669 40.2573L169.892 41.382V31.0464L161.846 33.5673L163.669 40.2573Z"
        fill="url(#paint17_linear_7170_62453)"
      />
      <path
        d="M150.031 28.4473L158.052 25.7469L161.706 28.4818L153.811 30.9366L150.031 28.4473Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M169.882 30.9366L161.987 28.4818L165.641 25.7469L173.662 28.4473L169.882 30.9366Z"
        fill="#DAE1ED"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M150.035 33.4717L153.81 31.117L161.694 33.5874L157.825 36.0558L150.035 33.4717Z"
        fill="#EFF4FD"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M173.657 33.4717L165.868 36.0558L161.998 33.5874L169.883 31.117L173.657 33.4717Z"
        fill="#EDF1F9"
        stroke="black"
        strokeWidth="0.129343"
      />
      <path
        d="M151.31 24.7339H150.811V24.2349H150.383V24.7339H149.884V25.1758H150.383V25.6748H150.811V25.1758H151.31V24.7339Z"
        fill="url(#paint18_linear_7170_62453)"
      />
      <path
        d="M152.406 37.2534H151.907V36.7544H151.479V37.2534H150.98V37.6954H151.479V38.1944H151.907V37.6954H152.406V37.2534Z"
        fill="url(#paint19_linear_7170_62453)"
      />
      <path
        d="M174.22 37.2818L173.609 36.9735L173.918 36.363L173.394 36.0988L173.086 36.7093L172.475 36.4011L172.202 36.9418L172.813 37.2501L172.505 37.8606L173.028 38.1248L173.336 37.5143L173.947 37.8225L174.22 37.2818Z"
        fill="url(#paint20_linear_7170_62453)"
      />
      <path
        d="M10.2766 32.6006H48.9452"
        stroke="#696677"
        strokeWidth="1.07413"
        strokeLinecap="round"
      />
      <path
        d="M10.2766 36.8975H62.9089"
        stroke="#696677"
        strokeWidth="1.07413"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7170_62453"
          x1="159.307"
          y1="99.2544"
          x2="158.209"
          y2="103.823"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7170_62453"
          x1="159.307"
          y1="99.2544"
          x2="158.209"
          y2="103.823"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7170_62453"
          x1="155.605"
          y1="108.12"
          x2="157.538"
          y2="102.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#6C80AA" stopOpacity="0" />
          <stop offset="1" stopColor="#5D6A86" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7170_62453"
          x1="166.188"
          y1="109.611"
          x2="166.34"
          y2="102.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#314F91" stopOpacity="0" />
          <stop offset="1" stopColor="#324264" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7170_62453"
          x1="150.597"
          y1="94.2849"
          x2="150.597"
          y2="95.0931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7170_62453"
          x1="151.693"
          y1="106.804"
          x2="151.693"
          y2="107.613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7170_62453"
          x1="173.625"
          y1="106.292"
          x2="173.126"
          y2="107.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7170_62453"
          x1="159.306"
          y1="64.2544"
          x2="158.209"
          y2="68.8227"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7170_62453"
          x1="159.306"
          y1="64.2544"
          x2="158.209"
          y2="68.8227"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_7170_62453"
          x1="155.605"
          y1="73.12"
          x2="157.537"
          y2="67.6612"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#6C80AA" stopOpacity="0" />
          <stop offset="1" stopColor="#5D6A86" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_7170_62453"
          x1="166.188"
          y1="74.6111"
          x2="166.34"
          y2="67.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#314F91" stopOpacity="0" />
          <stop offset="1" stopColor="#324264" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_7170_62453"
          x1="150.597"
          y1="59.2849"
          x2="150.597"
          y2="60.0931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_7170_62453"
          x1="151.693"
          y1="71.8044"
          x2="151.693"
          y2="72.6127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_7170_62453"
          x1="173.625"
          y1="71.2922"
          x2="173.126"
          y2="72.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_7170_62453"
          x1="159.307"
          y1="29.2544"
          x2="158.209"
          y2="33.8227"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_7170_62453"
          x1="159.307"
          y1="29.2544"
          x2="158.209"
          y2="33.8227"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#606673" />
          <stop offset="1" stopColor="#373C47" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_7170_62453"
          x1="155.605"
          y1="38.12"
          x2="157.538"
          y2="32.6612"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#6C80AA" stopOpacity="0" />
          <stop offset="1" stopColor="#5D6A86" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_7170_62453"
          x1="166.188"
          y1="39.6111"
          x2="166.34"
          y2="32.684"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00289017" stopColor="#314F91" stopOpacity="0" />
          <stop offset="1" stopColor="#324264" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_7170_62453"
          x1="150.597"
          y1="24.2849"
          x2="150.597"
          y2="25.0931"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_7170_62453"
          x1="151.693"
          y1="36.8044"
          x2="151.693"
          y2="37.6127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_7170_62453"
          x1="173.625"
          y1="36.2922"
          x2="173.126"
          y2="37.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0BACC" />
          <stop offset="1" stopColor="#969EAE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
