import "./CustomerChartTooltip.scss";
import { formatCurrency, setToDateMonthYearInWords } from "../../../helpers";
import { useSelector } from "react-redux";

const CustomerChartTooltip = ({ active, payload, label, isMoney = false }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  if (active) {
    return (
      <div className="customer-chart-tooltip">
        <p className="customer-chart-tooltip__value purple--text">
          {isMoney
            ? formatCurrency({
                value: payload[0].value,
                currencyCode: currency_code,
              })
            : payload[0].value}
        </p>
        <p className="customer-chart-tooltip__label">
          {setToDateMonthYearInWords(label)}
        </p>
      </div>
    );
  }
  return null;
};

export default CustomerChartTooltip;
