import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  product: yup.string().required("product is required"),
  variant: yup.string().optional(),
  transferFrom: yup.string().required("transfer from is required"),
  transferTo: yup.string().required("transfer to is required"),
  quantity: yup
    .number()
    .positive()
    .typeError("Quantity must be a number")
    .required("Quantity is required")
    .min(0.01, "Minimum Quantiy is 0.01"),
});

export const useTransferStockFormValidation = (formData) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: formData,
  });
};
