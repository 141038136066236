import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const tagSchema = yup.object({
  tag_name: yup.string().required("tag name is required"),
});

const AddTransactionsTagValidation = (tag_name) => {
  return useForm({
    resolver: yupResolver(tagSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      tag_name,
    },
  });
};

export default AddTransactionsTagValidation;
