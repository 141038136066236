import states from "../localdata/nigerianStates.json";

const formatStates = () => {
  return Object.keys(states).map((state) => {
    return {
      value: state,
      ...states[state],
    };
  });
};

export const fetchStates = () => {
  return formatStates().map((state) => state.value);
};

export const getState = (state) => {
  if (state) {
    const _state = formatStates().find((s) => s.value === state);
    return states[_state.value];
  }
};

export const getLocalGovernments = (state) => {
  return getState(state)?.lgas;
};

export const getCities = (state) => {
  return getState(state)?.cities;
};
