import React from "react";
import styles from "./Accounting.module.scss";
import primaryComponents from "../../../../components/primaryComponents";
import secondaryComponents from "../../../../components/secondaryComponents";
import { useNavigate } from "react-router-dom";
import useAccounting from "./useAccounting";
import { pathConstants } from "../../../../routes/pathContants";

const AccountingOverview = () => {
  const navigate = useNavigate();
  const { isLoading, data } = useAccounting();

  return (
    <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-5">
      <div className={styles.cards_container}>
        <div className={styles.card}>
          <p className={`${styles.title} big-text-size`}> Profit And Loss </p>
          <primaryComponents.Button
            type="button"
            classNames={"btn btn--outline"}
            onClick={() =>
              navigate(
                pathConstants.ACCOUNTING_DETAILS({ id: "profit-and-loss" }),
              )
            }
          >
            View Report
          </primaryComponents.Button>
        </div>
        {data &&
          data?.map((item, index) => (
            <div key={index} className={styles.card}>
              <p className={`${styles.title} big-text-size`}>{item.title}</p>
              <primaryComponents.Button
                type="button"
                classNames={"btn btn--outline"}
                onClick={() =>
                  navigate(pathConstants.ACCOUNTING_DETAILS({ id: item.id }))
                }
              >
                View Report
              </primaryComponents.Button>
            </div>
          ))}
      </div>
    </secondaryComponents.LoaderHelper>
  );
};

export default AccountingOverview;
