import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    expenseName: yup.string().required("Expense name is required"),
    store: yup.string().required("Store is required"),
    amount: yup.string().required("Amount is required"),
    category: yup.string().required("Category is required"),
    paymentMethod: yup.string().required("Payment method is required"),
    expenseDate: yup.string().required("Expense date is required"),
    description: yup.string(),
    isDisbursmentForm: yup.boolean(),
    bankCode: yup.string().when("isDisbursmentForm", {
      is: true,
      then: yup.string().required("Bank is required"),
    }),
    accountNumber: yup.string().when("isDisbursmentForm", {
      is: true,
      then: yup.string().required("A valid account number is required"),
    }),
  })
  .required("All fields are required");

const Validation = ({ expenseDetails, isDisbursmentForm }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      expenseName: expenseDetails?.name,
      store: expenseDetails?.store,
      amount: expenseDetails?.amount,
      category: expenseDetails?.category,
      paymentMethod: expenseDetails?.payment_method,
      expenseDate: expenseDetails?.expense_date,
      description: expenseDetails?.description,
      bankCode: expenseDetails?.bank_code,
      accountNumber: expenseDetails?.account_number,
      isDisbursmentForm: isDisbursmentForm,
    },
  });
};

export default Validation;
