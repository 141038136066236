import { setToDateMonthYearInWords } from "../../../helpers";

const NewCustomersChartLogic = () => {
  const xAxisFormatter = (tick) => {
    return setToDateMonthYearInWords(tick);
  };

  return {
    xAxisFormatter,
  };
};

export default NewCustomersChartLogic;
