import styles from "./ItemsInputField.module.scss";
import globalStyles from "../../../assets/styles/base/globalPage.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const ItemsInputField = ({
  placeholder,
  type = "text",
  name = "",
  isRequired = false,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  onRemoveItem = () => {},
  register = () => {},
  onKeyUp = () => {},
  classNames = "",
  floatError = true,
  inputIcon = null,
  items = [],
  value,
  ...props
}) => {
  const [val, setValue] = useState(value || "");

  return (
    <div className={`${styles.itemsInputField}`}>
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div className={styles.itemsCover}>
        {inputIcon !== null ? <img src={inputIcon} alt="input-icon" /> : null}

        {items?.map((item, index) => (
          <span className={styles.itemsCover__item} key={item + index}>
            {item}
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="ml-2 white--text"
              style={{ cursor: "pointer" }}
              onClick={() => onRemoveItem(index)}
            />
          </span>
        ))}

        <input
          className={`${inputIcon === null ? classNames : ""} ${
            errorMessage !== undefined && "error-border"
          }`}
          type={type}
          name={name}
          placeholder={placeholder}
          id={name}
          {...register(name, { required: isRequired })}
          onChange={(e) => {
            const value = e.target.value;
            if (value?.slice(-1) === "," && value?.length > 1) {
              setValue("");
              return onChange(value.substring(0, value.length - 1));
            }
            setValue(value);
          }}
          disabled={isDisabled}
          onKeyUp={onKeyUp}
          value={val}
          {...props}
        />
      </div>

      {/* error message section */}
      {floatError || errorMessage !== undefined ? (
        <p
          className={`${globalStyles.errorMessage} text-danger ${
            errorMessage !== undefined ? globalStyles.show : ""
          }`}
        >
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            className="mr-2"
            style={{ fontSize: "13px" }}
          />
          {errorMessage !== undefined && <span>{errorMessage?.message}</span>}
        </p>
      ) : null}
    </div>
  );
};
export default ItemsInputField;
