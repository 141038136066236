import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  label: yup.string().required("Role name is required"),
});

const EditRoleVaidation = (roleName) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      label: roleName,
    },
  });
};

export default EditRoleVaidation;
