import "./CategoryForm.scss";
import primaryComponents from "../../primaryComponents";
import CategoryFormLogic from "./CategoryFormLogic";
import CategoryFormValidation from "./CategoryFormValidation";
import { Controller } from "react-hook-form";

const CategoryForm = ({ product }) => {
  const { handleNextStep, handlePreviousStep } = product;
  const {
    categoryDescription,
    categoryName,
    handleAddCategory,
    setCategoryDescripion,
    setCategoryName,
    isAddingCategory,
  } = CategoryFormLogic({ handleNextStep });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    trigger,
  } = CategoryFormValidation({ categoryDescription, categoryName });

  return (
    <div className="category-form">
      <h4 className="font-weight-semibold text-center">Add product category</h4>
      <form
        onSubmit={handleSubmit(handleAddCategory)}
        className="category-form__main"
      >
        <Controller
          name="categoryName"
          control={control}
          defaultValue={categoryName}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label={"Category Name"}
              isRequired={true}
              placeholder="Enter your category name"
              type="text"
              name="categoryName"
              errorMessage={errors.categoryName}
              onKeyUp={() =>
                errors.categoryName !== undefined && trigger("categoryName")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setCategoryName(value);
              }}
              register={register}
            />
          )}
        />
        <Controller
          name="categoryDescription"
          control={control}
          defaultValue={categoryDescription}
          render={({ field: { onChange } }) => (
            <primaryComponents.InputField
              label={"Category Description"}
              isRequired={true}
              placeholder="Enter your category description"
              type="text"
              name="categoryDescription"
              errorMessage={errors.categoryDescription}
              onKeyUp={() =>
                errors.categoryDescription !== undefined &&
                trigger("categoryDescription")
              }
              onChange={({ target: { value } }) => {
                onChange(value);
                setCategoryDescripion(value);
              }}
              register={register}
            />
          )}
        />
        <div className="d-flex align-items-center justify-content-between category-form__main__actions">
          <primaryComponents.Button
            type="button"
            onClick={handlePreviousStep}
            classNames={"btn btn--outline"}
          >
            Cancel
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isAddingCategory}
            isDisabled={isAddingCategory}
            loadingText="Adding category..."
          >
            Next
          </primaryComponents.Button>
        </div>
      </form>
    </div>
  );
};

export default CategoryForm;
