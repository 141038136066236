/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styles from "./SectionLayout.module.scss";
import primaryComponents from "../../components/primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSectionLayoutLogic from "./useSectionLayoutLogic";
import secondaryComponents from "../../components/secondaryComponents";
import { pathConstants } from "../../routes/pathContants";
import { formatDateYYYYMMDD } from "../../helpers";
import { Arrow45DegIcon } from "../../assets/svgIcons";

const SectionLayout = () => {
  const {
    storeList,
    search,
    isLoading,
    sections,
    filterByStoreId,
    handleSearchSection,
    filterByStatusAndDate,
  } = useSectionLayoutLogic();
  const { section_id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <secondaryComponents.LoaderHelper
        classNames={"mt-5"}
        isLoading={isLoading}
      >
        <div className={styles.kitchenLayout}>
          <div className={styles.kitchenLayout__header}>
            <div className={styles.kitchenLayout__header__top}>
              <div className="d-flex align-items-center">
                <primaryComponents.Button
                  className={"btn mr-2 btn--outline"}
                  style={{ width: "30px" }}
                  onClick={() => navigate(pathConstants.SECTIONS)}
                >
                  <FontAwesomeIcon
                    icon="angle-left"
                    className="purple--text"
                    style={{ fontSize: "16px" }}
                  />
                </primaryComponents.Button>

                <h4 className="white--text mb-0">
                  {sections?.find(
                    (section) => `${section?.id}` === `${section_id}`,
                  )?.name || ""}
                </h4>
              </div>

              <primaryComponents.SearchField
                placeholder="Search by queue number or order ID"
                triggerOnInput={true}
                showsearchBtn={true}
                classNames={`${styles.searchField}`}
                trigger={(value) => handleSearchSection(value)}
                value={search.get("query") !== null ? search.get("query") : ""}
              />

              <div className={styles.storeField}>
                <primaryComponents.SelectField
                  options={storeList}
                  idKey="id"
                  nameKey="name"
                  onChange={(value) => {
                    filterByStoreId(value);
                  }}
                  value={(() => {
                    if (search.get("store_id"))
                      return storeList?.find(
                        (item) => item.id == search.get("store_id"),
                      )?.name;
                    else return storeList?.[0]?.name;
                  })()}
                  floatError={false}
                />
              </div>
              <primaryComponents.Button
                classNames={"btn btn--outline"}
                style={{ color: "#000", padding: "0 10px" }}
                onClick={() => {
                  window.open(
                    pathConstants.SECTION_CUSTOMER_MODE({
                      store_id: search.get("store_id"),
                    }),
                    "_blank",
                  );
                }}
              >
                <span className="mr-2">Customer&apos;s View</span>
                <Arrow45DegIcon />
              </primaryComponents.Button>
            </div>
          </div>

          {/* bottom header */}
          <div className={styles.kitchenLayout__header__bottom}>
            <a
              onClick={() => filterByStatusAndDate({ status: "RECEIVED" })}
              className={`${styles.stage} ${
                search.get("status") === "RECEIVED" ? styles.active : ""
              } `}
            >
              New Oders
              {/* <div className={styles.circleGray}>4</div> */}
            </a>
            <div className={styles.vertical}></div>
            <a
              onClick={() => filterByStatusAndDate({ status: "IN_PROGRESS" })}
              className={`${styles.stage} ${
                search.get("status") === "IN_PROGRESS" ? styles.active : ""
              } `}
            >
              In-Progress
              {/* <div className={styles.circle}>4</div> */}
            </a>
            <div className={styles.vertical}></div>
            <a
              onClick={() =>
                filterByStatusAndDate({
                  status: "COMPLETED",
                  date: formatDateYYYYMMDD(new Date()),
                })
              }
              className={`${styles.stage} ${
                search.get("status") === "COMPLETED" ? styles.active : ""
              } `}
            >
              Done
              {/* <div className={styles.circle}>2</div> */}
            </a>
            <div className={styles.vertical}></div>
            <a
              onClick={() =>
                filterByStatusAndDate({
                  status: "CANCELLED",
                  date: formatDateYYYYMMDD(new Date()),
                })
              }
              className={`${styles.stage} ${
                search.get("status") === "CANCELLED" ? styles.active : ""
              } `}
            >
              Cancelled
              {/* <div className={styles.circle}>2</div> */}
            </a>
          </div>

          <div className={styles.kitchenLayout__content}>
            <Outlet />
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
    </>
  );
};

export default SectionLayout;
