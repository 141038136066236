import "./AccountDetailWidget.scss";
import primaryComponents from "../../primaryComponents";
import Icon from "../../../assets/icons";
import AccountDetailWidgetLogic from "./AccountDetailWidgetLogic";

const AccountDetailWidget = () => {
  const { account_name, bank_name, account_number } =
    AccountDetailWidgetLogic();

  return (
    <div className="account-detail-widget">
      <h4 className="font-weight-semibold account-detail-widget__title">
        The account number provided is unique to your Lumi account
      </h4>
      <p className="purple--text mt-1">
        Copy the account details provided below:{" "}
      </p>
      <div className="account-detail-widget__details">
        <div className="account-detail-widget__details__item">
          <p>Bank Name</p>
          <p className="dark--text font-weight-semibold">{bank_name}</p>
        </div>
        <div className="account-detail-widget__details__item flex">
          <div>
            <p>Bank Account Number</p>
            <p className="dark--text font-weight-semibold">{account_number}</p>
          </div>
          <primaryComponents.Button classNames="btn btn--outline widget--btn">
            <img src={Icon.copy} alt="" />
            <span className="ml-2">Copy</span>
          </primaryComponents.Button>
        </div>
        <div className="account-detail-widget__details__item">
          <p>Account Name</p>
          <p className="dark--text font-weight-semibold">{account_name}</p>
        </div>
      </div>
      <p className="dark--text font-weight-semibold">Powered by Kuda</p>
    </div>
  );
};

export default AccountDetailWidget;
