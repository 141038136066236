import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { pay } from "../../../services/credit";
import { ToastContext } from "../../../hooks/context";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";
import { cleanObject } from "../../../helpers";

const UpdateCreditWidgetLogic = ({ closeWidget, customer, onSuccess }) => {
  const [amount, setAmount] = useState(customer?.outstanding_balance);
  const [store, setStore] = useState("");
  const [note, setNote] = useState("");
  const [account, setAccount] = useState();
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [repaymentDate, setRepaymentDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const triggerToast = useContext(ToastContext);

  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: fetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter();

  const makeRepayment = () => {
    const data = {
      amount,
      payment_method: paymentMethod,
      store_id: store,
      ledger_account_id: account,
      note: note,
      repayment_date: repaymentDate,
    };
    setIsSubmitting(true);

    pay(customer.id, cleanObject(data))
      .then(() => {
        triggerToast("Payment successful", "success");
        onSuccess();
        closeWidget();
      })
      .catch((error) => {})
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    if (bankAccount?.data?.length > 0 && !account) {
      setAccount(bankAccount?.data[0]?.id);
    }
  });

  return {
    amount,
    note,
    setAmount,
    store,
    setStore,
    storeList,
    isSubmitting,
    setIsSubmitting,
    paymentMethod,
    setPaymentMethod,
    makeRepayment,
    bankAccount,
    isLoadingBankAccountsList,
    fetchBankAccountsList,
    account,
    repaymentDate,
    setRepaymentDate,
    setAccount,
    setNote,
  };
};

export default UpdateCreditWidgetLogic;
