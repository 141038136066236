import primaryComponents from "../../primaryComponents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TransactionsFilter.scss";
import { motion } from "framer-motion";
import TransactionsFilterLogic from "./TransactionsFilterLogic";

const TransactionsFilter = () => {
  const {
    showDropdown,
    setShowDropdown,
    paymentStatus,
    paymentMethod,
    removeValueFromPaymentStatus,
    addValueToPaymentStatus,
    addValueToPaymentMethod,
    removeValueFromPaymentMethod,
    setFilter,
    inputRef,
  } = TransactionsFilterLogic();

  return (
    <div className="transactions-filter" ref={inputRef}>
      <primaryComponents.Button
        classNames={`btn btn--outline-gray ${showDropdown && "active"}`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        Filter{" "}
        <FontAwesomeIcon
          className="ml-3"
          icon="angle-down"
          style={{ fontSize: "16px" }}
        />
      </primaryComponents.Button>

      {showDropdown ? (
        <motion.div className="transactions-filter__dropdown">
          <h5 className="font-weight-normal mb-4">Filter by</h5>

          {/* payment status */}
          <p className="dark--text mb-3">Status</p>
          <div className="d-flex flex-wrap">
            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("awaiting_settlement")}
                value="awaiting_settlement"
                onChange={(value) => {
                  if (value)
                    return addValueToPaymentStatus("awaiting_settlement");
                  removeValueFromPaymentStatus("awaiting_settlement");
                }}
                id="awaitingSettlement"
              />
              <label htmlFor="awaitingSettlement" className="ml-2 gray--text">
                Awaiting settlement
              </label>
            </div>

            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("settled")}
                value="settled"
                onChange={(value) => {
                  if (value) return addValueToPaymentStatus("settled");
                  removeValueFromPaymentStatus("settled");
                }}
                id="settled"
              />
              <label htmlFor="settled" className="ml-2 gray--text">
                Settled
              </label>
            </div>

            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("unpaid")}
                value="unpaid"
                id="unpaid"
                onChange={(value) => {
                  if (value) return addValueToPaymentStatus("unpaid");
                  removeValueFromPaymentStatus("unpaid");
                }}
              />
              <label htmlFor="unpaid" className="ml-2 gray--text">
                Not paid
              </label>
            </div>

            <div className="checked-item mb-1">
              <primaryComponents.Checkbox
                name="paymentStatus"
                isChecked={paymentStatus.includes("failed")}
                value="failed"
                id="failed"
                onChange={(value) => {
                  if (value) return addValueToPaymentStatus("failed");
                  removeValueFromPaymentStatus("failed");
                }}
              />
              <label htmlFor="failed" className="ml-2 gray--text">
                Failed
              </label>
            </div>
          </div>

          {/* payment method */}
          <p className="dark--text mt-3 mb-3">Payment Method</p>
          <div className="d-flex flex-wrap">
            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentMethod"
                id="lumiNairaWallet"
                isChecked={paymentMethod.includes("lumi_naira_wallet")}
                value="lumi_naira_wallet"
                onChange={(value) => {
                  if (value)
                    return addValueToPaymentMethod("lumi_naira_wallet");
                  removeValueFromPaymentMethod("lumi_naira_wallet");
                }}
              />
              <label htmlFor="lumiNairaWallet" className="ml-2 gray--text">
                Lumi naira wallet
              </label>
            </div>

            <div className="checked-item mr-4 mb-1">
              <primaryComponents.Checkbox
                name="paymentMethod"
                isChecked={paymentMethod.includes("lumi_rewards_wallet")}
                value="lumiRewardsWallet"
                id="lumi_rewards_wallet"
                onChange={(value) => {
                  if (value)
                    return addValueToPaymentMethod("lumi_rewards_wallet");
                  removeValueFromPaymentMethod("lumi_rewards_wallet");
                }}
              />
              <label htmlFor="lumiRewardsWallet" className="ml-2 gray--text">
                Lumi reward wallet
              </label>
            </div>

            <div className="checked-item mb-1">
              <primaryComponents.Checkbox
                name="paymentMethod"
                isChecked={paymentMethod.includes("bank_transfer")}
                value="bank_transfer"
                id="bankTransfer"
                onChange={(value) => {
                  if (value) return addValueToPaymentMethod("bank_transfer");
                  removeValueFromPaymentMethod("bank_transfer");
                }}
              />
              <label htmlFor="bankTransfer" className="ml-2 gray--text">
                Bank transfer
              </label>
            </div>
          </div>

          <div className="button-section">
            <primaryComponents.Button
              classNames="btn btn--primary cover mt-3"
              onClick={setFilter}
            >
              Apply
            </primaryComponents.Button>
          </div>
        </motion.div>
      ) : null}

      {showDropdown && (
        <div
          className="transactions-filter__overlay"
          onClick={() => setShowDropdown(false)}
        ></div>
      )}
    </div>
  );
};

export default TransactionsFilter;
