import styles from "./PrescriptionWidget.module.scss";
import primaryComponents from "../../../primaryComponents";
import Icon from "../../../../assets/icons";
import PrescriptionItem from "./PrescriptionItem";
import { Controller } from "react-hook-form";
import { usePrescription } from "./usePrescription";
import { usePrescriptionFormValidator } from "./Validation";

const PrescriptionWidget = ({ orderId }) => {
  const {
    prescriptionDetails,
    prescriptionItems,
    isGeneratingPrescription,
    prescriptionNote,
    setPrescriptionNote,
    setPrescriptionItems,
    onSubmit,
  } = usePrescription({
    orderId,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = usePrescriptionFormValidator({ note: prescriptionNote });

  return (
    <div className={styles.prescriptionWidget}>
      <div className={styles.prescriptionWidget__header}>
        <h4>Prescription</h4>
        <p>Apply appropriate prescription to items</p>
      </div>
      <div className={styles.prescriptionWidget__content}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p>Customer Name</p>
          <p>{prescriptionDetails?.customer?.full_name || "N/A"}</p>
        </div>
        <div className={styles.prescriptionWidget__items}>
          {prescriptionItems.map((prescription, index) => (
            <PrescriptionItem
              index={index}
              key={prescription?.item_id}
              prescription={prescription}
              prescriptionItems={prescriptionItems}
              setPrescriptionItems={setPrescriptionItems}
            />
          ))}
        </div>
      </div>
      <div className={styles.noteSection}>
        <h5 className="d-flex">
          <img className="mr-2" src={Icon.paperIcon} alt="" />
          <span>Note</span>
        </h5>
        <Controller
          name="note"
          control={control}
          defaultValue={prescriptionNote}
          render={({ field }) => (
            <primaryComponents.TextArea
              value={prescriptionNote}
              onChange={({ target: { value } }) => {
                field.onChange(value);
                setPrescriptionNote(value);
              }}
              errorMessage={errors.note}
              isRequired={true}
            />
          )}
        />
      </div>
      <div className={styles.prescriptionWidget__bottom}>
        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          isDisabled={isGeneratingPrescription}
          isLoading={isGeneratingPrescription}
          loadingText="Generating..."
          onClick={handleSubmit(onSubmit)}
        >
          Confirm
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default PrescriptionWidget;
