import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const customerWidgetSchema = yup.object({
  customerName: yup.string().required("Please input name"),
  customerPhoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, "Phone number must contain only digits")
    .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
    .min(10, "Invalid phone number format")
    .max(10, "Invalid phone number format"),
  customerEmail: yup.string().required("Email is required"),
  customerGender: yup.string().required("Please select gender"),
  dateOfBirth: yup.string().required("Date of birth is required"),
});

export const useCustomerWidgetValidation = ({
  customerName,
  customerEmail,
  customerGender,
  customerPhoneNumber,
  dateOfBirth,
}) => {
  return useForm({
    resolver: yupResolver(customerWidgetSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      customerName,
      customerEmail,
      customerGender,
      customerPhoneNumber,
      dateOfBirth,
    },
  });
};
