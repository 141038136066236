import secondaryComponents from "..";
import LoaderHelper from "../LoaderHelper/LoaderHelper";
import primaryComponents from "../../primaryComponents";
import styles from "./GetStartedVideo.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetStartedLogic from "./GetStartedLogic";
import { NavLink } from "react-router-dom";

const GetStartedVideo = ({
  closeWidget,
  url,
  title,
  isGetStarted,
  setupGuideCategories,
}) => {
  const {
    showDropdown,
    setShowDropdown,
    RefreshSetUpGuide,
    isFetchingSetUpGuide,
  } = GetStartedLogic();
  return (
    <div className="px-2">
      {isGetStarted === false ? (
        <>
          <div
            className={"d-flex justify-content-between align-items-center mb-3"}
          >
            <h4>{title}</h4>
            <primaryComponents.Button
              classNames="btn btn--outline smallBtn"
              type="button"
              onClick={closeWidget}
            >
              Close
            </primaryComponents.Button>
          </div>
          <div style={{ height: "400px" }}>
            <iframe
              width="100%"
              height="100%"
              loading="lazy"
              src={`https://www.youtube.com/embed/${url}`}
              title={title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullScreen
            ></iframe>
          </div>
        </>
      ) : (
        <div className={styles.card}>
          <div className={styles.header}>
            <h4>Setup Guide</h4>
          </div>

          {/* Progressive Bar */}
          <div className={styles.header}>
            <div className={styles.progressBar}>
              <div
                className={styles.progressBar__progressive}
                style={{
                  width: setupGuideCategories?.overall_percentage + "%",
                }}
              ></div>
            </div>
            <h6 className={"mb-1"}>
              {setupGuideCategories?.overall_percentage}%
            </h6>
          </div>

          {/* Setup guard items */}
          <div className={styles.guide}>
            {/* Inventory */}

            {setupGuideCategories?.setup_guide_data?.map((setUpGuideItems) => (
              <div key={setUpGuideItems.key}>
                <secondaryComponents.CountryItemPermission
                  dontCheckCountry={
                    setUpGuideItems?.title === "Loyalty" ||
                    setUpGuideItems?.title === "Payments"
                      ? false
                      : true
                  }
                >
                  <secondaryComponents.NavigateWithPermission
                    permissionParentKey={
                      setUpGuideItems?.setup_items?.permission
                        ?.permission_category?.label
                    }
                  >
                    <div
                      className={`mb-3 ${styles.guide__items}`}
                      key={setUpGuideItems?.id}
                    >
                      <div
                        className={styles.guide__collapse}
                        onClick={() => {
                          if (showDropdown === setUpGuideItems?.id) {
                            setShowDropdown(null);
                          } else {
                            setShowDropdown(setUpGuideItems?.id);
                          }
                        }}
                      >
                        <div className={styles.header}>
                          <img
                            src={setUpGuideItems?.icon_link}
                            height={50}
                            width={50}
                            alt={setUpGuideItems?.title}
                          />
                          <div className={"pl-3"}>
                            <h6>{setUpGuideItems?.title}</h6>
                            <label
                              className={"gray--text font-weight-semibold"}
                            >
                              {setUpGuideItems?.completed_setup_items_count} of{" "}
                              {setUpGuideItems?.setup_items_count} steps
                              completed
                            </label>
                          </div>
                        </div>
                        <primaryComponents.Button
                          classNames="btn btn--outline smallBtn"
                          style={{ width: "5px" }}
                        >
                          <FontAwesomeIcon
                            icon={
                              showDropdown === setUpGuideItems?.id
                                ? "angle-up"
                                : "angle-down"
                            }
                            style={{ fontSize: "12px" }}
                            className={"transform-icon"}
                          />
                        </primaryComponents.Button>
                      </div>

                      {showDropdown === setUpGuideItems?.id ? (
                        <div className={styles.dropDown}>
                          {/* 1 */}
                          {setUpGuideItems?.setup_items.map((setUpitems) => (
                            <>
                              <LoaderHelper
                                isLoading={isFetchingSetUpGuide}
                                key={setUpitems?.id}
                              >
                                <secondaryComponents.NavigateWithPermission
                                  permissionParentKey={
                                    setUpitems?.permission?.permission_category
                                      ?.label
                                  }
                                  permissionChildKey={
                                    setUpitems?.permission?.key
                                  }
                                >
                                  <div
                                    className={"ml-5 mt-3 mr-5"}
                                    key={setUpitems?.id}
                                  >
                                    <div
                                      className={"d-flex"}
                                      style={{ gap: "30px" }}
                                    >
                                      <h6>{setUpitems?.title}</h6>
                                    </div>
                                    <div className={styles.dropDown__content}>
                                      <div>
                                        <label
                                          className={
                                            "gray--text font-weight-semibold"
                                          }
                                        >
                                          {setUpitems?.description}
                                        </label>
                                      </div>
                                      <div
                                        className={
                                          "d-flex justify-content-between flex-wrap"
                                        }
                                      >
                                        <div
                                          className={styles.dropDown__content}
                                          key={setUpitems?.id}
                                        >
                                          <NavLink
                                            className={styles.subMenuRow}
                                            to={setUpitems?.cta_link}
                                          >
                                            <primaryComponents.Button classNames="btn btn--primary smallBtn mt-3  align-items-center">
                                              {setUpitems?.cta_text}
                                            </primaryComponents.Button>
                                          </NavLink>
                                          <div
                                            className={
                                              "d-flex align-items-center mt-3"
                                            }
                                            style={{
                                              gap: "5px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              RefreshSetUpGuide(setUpitems?.id)
                                            }
                                          >
                                            <primaryComponents.Checkbox
                                              isChecked={
                                                setUpitems?.item_completed
                                              }
                                            />

                                            <span
                                              className={
                                                setUpitems?.item_completed
                                                  ? "green--text mt-1"
                                                  : "purple--text mt-1"
                                              }
                                            >
                                              {setUpitems?.item_completed
                                                ? "Completed"
                                                : "Mark as completed"}
                                            </span>
                                          </div>
                                        </div>
                                        {setUpitems?.embedded_link !== "" &&
                                        setUpitems?.embedded_link !== null ? (
                                          <div className={styles.video}>
                                            <iframe
                                              width="100%"
                                              height="100%"
                                              loading="lazy"
                                              src={`https://www.youtube.com/embed/${setUpitems?.embedded_link}`}
                                              title={title}
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                              allowfullscreen="allowfullscreen"
                                              frameBorder="0"
                                            ></iframe>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                </secondaryComponents.NavigateWithPermission>
                              </LoaderHelper>
                            </>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </secondaryComponents.NavigateWithPermission>
                </secondaryComponents.CountryItemPermission>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default GetStartedVideo;
