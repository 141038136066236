import httpClient from "../axiosBase/axiosHandler";

export const getRiders = () => {
  return httpClient.get("/dispatch-riders/");
};

export const addRider = (data) => {
  return httpClient.post("/dispatch-riders/", data);
};

export const updateRider = ({ id, data }) => {
  return httpClient.patch(`/dispatch-riders/${id}/`, data);
};

export const removeRider = (id) => {
  return httpClient.delete(`/dispatch-riders/${id}/`);
};
