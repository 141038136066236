import React, { useState } from "react";
import styles from "./EditRole.module.scss";
import primaryComponents from "../../../../../../components/primaryComponents";

const PermissionCheckBox = ({
  perm,
  selectedPermissions,
  setSelectedPermissions,
}) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className={styles.permission_value}>
      <p>{perm.label}</p>
      <primaryComponents.Checkbox
        isChecked={
          selectedPermissions.find((item) => item === perm.id) ? true : false
        }
        id={perm.id}
        name={perm.key}
        onChange={() => {
          const permit = selectedPermissions.find((item) => item === perm.id);

          if (permit) {
            setSelectedPermissions(
              selectedPermissions.filter((item) => item !== perm.id),
            );
          } else {
            setSelectedPermissions([
              ...new Set([...selectedPermissions, perm.id]),
            ]);
          }
          setChecked(!checked);
        }}
      />
    </div>
  );
};

export default PermissionCheckBox;
