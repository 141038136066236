import React from "react";
import secondaryComponents from "../../../../../../components/secondaryComponents";
import primaryComponents from "../../../../../../components/primaryComponents";

const DeleteRoleWarning = ({
  showDeleteWarning,
  setShowDeleteWarning,
  isLoadingDelete,
  mutateDelete,
}) => {
  return (
    <secondaryComponents.Modal
      width="400px"
      isActive={showDeleteWarning ? true : false}
      closeModal={() => setShowDeleteWarning(null)}
    >
      <div className="py-4 px-2">
        <h5 className="mb-3">Are you sure you want to delete this role?</h5>
        <i className="gray--text default-font-size">
          NB: Deleting this role will revoke associated permissions and impact
          user access. Ensure you&apos;ve communicated changes to the team and
          consider potential workflow disruptions before proceeding.
        </i>

        <div className="mt-4 d-flex justify-content-between">
          <primaryComponents.Button
            classNames="btn btn--outline"
            isLoading={false}
            isDisabled={false}
            loadingText="Removing..."
            onClick={() => setShowDeleteWarning(null)}
          >
            Cancel
          </primaryComponents.Button>

          <primaryComponents.Button
            classNames="btn btn--danger"
            onClick={() => mutateDelete(showDeleteWarning.original.id)}
            isLoading={isLoadingDelete}
            isDisabled={isLoadingDelete}
            loadingText="Deleting..."
          >
            Continue
          </primaryComponents.Button>
        </div>
      </div>
    </secondaryComponents.Modal>
  );
};

export default DeleteRoleWarning;
