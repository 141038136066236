import secondaryComponents from "../../../../../components/secondaryComponents";
import ProfileLogic from "../ProfileLogic";
import { motion } from "framer-motion";

const UserProfile = () => {
  const { first_name, last_name } = ProfileLogic();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Settings - Profile"
      description="Edit your profile details"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
        >
          <div className="profile-page__section">
            <div className="profile-page__section__left justify-content-center">
              <div className="profile-page__section__left__avatar">
                {first_name?.charAt(0)}
                {last_name?.charAt(0)}
              </div>
              <p className="mt-4 dark--text">
                {first_name} {last_name}
              </p>
            </div>
            <div className="profile-page__section__right">
              <secondaryComponents.ProfilePersonalInfoForm />
            </div>
          </div>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default UserProfile;
