import { useContext, useState } from "react";
import primaryComponents from "../../primaryComponents";
import { ToastContext } from "../../../hooks/context";

const Discount = ({
  setDiscount,
  discount,
  currency_symbol,
  closeModal,
  totalAmount,
}) => {
  const triggerToast = useContext(ToastContext);
  const [discountVal, setDiscountVal] = useState(discount);
  return (
    <div
      className="p-3"
      style={{ display: "flex", flexDirection: "column", gap: "25px" }}
    >
      <h4>Apply Discount</h4>

      <div
        style={{
          border: "1px solid #ddd",
          padding: "8px",
          borderRadius: "4px",
        }}
      >
        <primaryComponents.InputField
          label={"Amount"}
          classNames="white"
          placeholder={`Enter discount amount e.g. ${currency_symbol}1000`}
          isRequired={false}
          value={discountVal}
          type="number"
          min="0"
          name="discount"
          floatError={false}
          onChange={({ target: { value } }) => {
            setDiscountVal(value);
          }}
        />
      </div>

      <primaryComponents.Button
        classNames={"btn btn--primary cover"}
        onClick={() => {
          if (Number(totalAmount) < Number(discountVal))
            return triggerToast(
              "Discount cannot be more than the total price of items",
            );
          setDiscount(discountVal);
          closeModal();
        }}
      >
        Apply
      </primaryComponents.Button>
    </div>
  );
};
export default Discount;
