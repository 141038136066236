import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const biodataSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .max(50, "Maximum of 50 characters is allowed"),

    phoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Phone number is required"),

    firstName: yup
      .string()
      .required("First name is required")
      .min(3, "Minimum of 3 characters is allowed")
      .max(30, "Maximum of 30 characters is allowed"),

    lastName: yup
      .string()
      .required("Last name is required")
      .min(3, "Minimum of 3 characters is allowed")
      .max(30, "Maximum of 30 characters is allowed"),

    country: yup.string().required("Country is required"),
    currency: yup.string().required("Currency is required"),
  })
  .required();

const BiodataFormValidation = (
  email,
  firstName,
  lastName,
  phoneNumber,
  country,
  currency,
) => {
  return useForm({
    resolver: yupResolver(biodataSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      country: country,
      currency: currency,
    },
  });
};

export default BiodataFormValidation;
