/* eslint-disable jsx-a11y/anchor-is-valid */
import secondaryComponents from "..";
import styles from "./StoreCart.module.scss";
import useStoreCart from "./useStoreCart";
import Images from "../../../assets/images";
import primaryComponents from "../../primaryComponents";
import { formatCurrency } from "../../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icon from "../../../assets/icons";
import { PlusCircle } from "../../../assets/svgIcons";
import DeliveryFee from "./DeliveryFee";
import Discount from "./Discount";
import Loyalty from "./Loyalty";
import CampaignEmpty from "./CampaignEmpty";
import useAllCampaigns from "../../../pages/appPages/Loyalty/Campaign/AllCampaigns/useAllCampaigns";
import { useStoreCurrency } from "../../../hooks/useStoreCurrency";

const StoreCart = ({
  setRefreshProducts = () => {},
  handleAddToCart,
  handleReduceCartItem,
  isActiveCart,
  setIsActiveCart,
  showGeneratBills,
  isRefreshCart,
  setIsRefreshCart = () => {},
  addCartItemToLocalStorage = () => {},
}) => {
  const { store_currency, store_currency_symbol } = useStoreCurrency();
  const { campaigns } = useAllCampaigns();

  const {
    tableDetails,
    isFetchingCartItems,
    cartItems,
    total_items,
    totalPrice,
    total_price,
    isCreatingOrder,
    discount,
    deliveryFee,
    vat_amount,
    vat,
    isGeneratingBill,
    isPendingSessions,
    isBulkAdding,
    discoutModals,
    loyaltyDetails,
    totalAmount,
    setLoyaltyDetails,
    resetDiscoutModals,
    bulkAddToCart,
    setDeliveryFee,
    setDiscount,
    clearCartItems,
  } = useStoreCart({
    setRefreshProducts,
    addCartItemToLocalStorage,
    isRefreshCart,
    setIsRefreshCart,
  });

  return (
    <>
      <div
        className={`${styles.storeCart} ${
          isActiveCart ? "" : styles.notActive
        }`}
      >
        {/* cart items section */}
        <secondaryComponents.LoaderHelper
          isLoading={isFetchingCartItems}
          classNames="mt-3"
        >
          {/* cart header */}
          <div
            className={styles.cartHeader}
            onClick={() => setIsActiveCart(!isActiveCart)}
          >
            <div className="d-flex align-items-end">
              <div style={{ position: "relative" }}>
                <img src={Icon.shoppingCart} alt="cart" />
                <span className={styles.cartCount}>{total_items}</span>
              </div>
              <h5 className="font-weight-semibold ml-4">
                {total_items} {total_items > 1 ? "items" : "item"} in cart
              </h5>
            </div>
            <FontAwesomeIcon
              icon={`${isActiveCart ? "angle-down" : "angle-up"}`}
              style={{ fontSize: "20px" }}
            />
          </div>

          {cartItems?.length > 0 ? (
            <primaryComponents.Button
              classNames="ml-auto btn btn--outline smallBtn mb-2"
              onClick={clearCartItems}
              style={{
                width: "fit-content",
                minHeight: "40px",
                fontSize: "14px",
              }}
            >
              Clear All
            </primaryComponents.Button>
          ) : null}

          {/* cart body */}
          {cartItems?.length > 0 ? (
            <div className={styles.storeCart__items}>
              {cartItems?.map((item, index) => (
                <secondaryComponents.ProductCarting
                  key={item?.id + index}
                  cartDetails={item}
                  handleAddToCart={handleAddToCart}
                  handleReduceCartItem={handleReduceCartItem}
                  cartIndex={index}
                  addCartItemToLocalStorage={addCartItemToLocalStorage}
                />
              ))}
            </div>
          ) : (
            <div style={{ marginTop: "40px" }} className="text-center">
              {tableDetails && (
                <div>
                  <p className={styles.tableOrderText}>
                    Start adding new order to{" "}
                    <span>Table {tableDetails.name}</span>
                  </p>
                </div>
              )}
              <img src={Images.productsEmptyState} alt="" width={"150px"} />
              <h5 className="mt-3 text-center">
                You have no products in your cart
              </h5>
              <p>When you select products, they would appear here.</p>
            </div>
          )}
        </secondaryComponents.LoaderHelper>

        <div className={styles.priceSummary}>
          <div className="d-flex justify-content-between mb-2">
            <p className="dark--text">Subtotal</p>
            <p className="dark--text">
              {formatCurrency({
                value: total_price,
                currencyCode: store_currency,
              })}
            </p>
          </div>
          {Number(loyaltyDetails.giftcard_amount) > 0 && (
            <div className="d-flex justify-content-between mb-2">
              <p className="dark--text">Gift Card</p>
              <p className="dark--text">
                {formatCurrency({
                  value: loyaltyDetails.giftcard_amount,
                  currencyCode: store_currency,
                })}
              </p>
            </div>
          )}
          {Number(loyaltyDetails.loyalty_point_amount) > 0 && (
            <div className="d-flex justify-content-between mb-2">
              <p className="dark--text">Loyalty Point</p>
              <p className="dark--text">
                {formatCurrency({
                  value: loyaltyDetails.loyalty_point_amount,
                  currencyCode: store_currency,
                })}
              </p>
            </div>
          )}
          <div className="d-flex justify-content-between mb-2">
            <p className="dark--text">Discount</p>
            <p className="dark--text">
              {discount === ""
                ? "-"
                : formatCurrency({
                    value: discount,
                    currencyCode: store_currency,
                  })}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p className="dark--text">Delivery Fee</p>
            <p className="dark--text">
              {deliveryFee === ""
                ? "-"
                : formatCurrency({
                    value: deliveryFee,
                    currencyCode: store_currency,
                  })}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p className="dark--text">VAT ({vat}%)</p>
            <p className="dark--text">
              {formatCurrency({
                value: vat_amount,
                currencyCode: store_currency,
              })}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-4">
            <p className="purple--text font-weight-semibold">Total</p>
            <p className="purple--text font-weight-semibold">
              {formatCurrency({
                value: totalPrice,
                currencyCode: store_currency,
              })}
            </p>
          </div>
        </div>

        <div
          className={`${styles.storeCart__bottom} ${
            !isActiveCart ? styles.active : ""
          }`}
        >
          <div className={styles.dicountSection}>
            <primaryComponents.Button
              classNames="btn cover"
              style={{
                background: "#E8F7EE",
                borderColor: "#47BE79",
                color: "#47BE79",
              }}
              onClick={() => resetDiscoutModals("isLoyalty", true)}
            >
              <PlusCircle />
              <span className="ml-2">Loyalty</span>
            </primaryComponents.Button>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_apply_discount"
            >
              <primaryComponents.Button
                classNames="btn cover"
                style={{
                  background: "#FEF7EB",
                  borderColor: "#F9BD63",
                  color: "#F9BD63",
                }}
                onClick={() => resetDiscoutModals("isDiscount", true)}
              >
                <PlusCircle />
                <span className="ml-2">Discount</span>
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_apply_delivery_fee"
            >
              <primaryComponents.Button
                classNames="btn cover"
                style={{
                  background: "#F2EDFE",
                  borderColor: "#7647EE",
                  color: "#7647EE",
                }}
                onClick={() => resetDiscoutModals("isDelivery", true)}
              >
                <PlusCircle />
                <span className="ml-2">Delivery</span>
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>
          <primaryComponents.Button
            isLoading={isCreatingOrder}
            isDisabled={isCreatingOrder || isBulkAdding || total_items === 0}
            loadingText="Creating order..."
            classNames="btn btn--primary cover"
            onClick={() => bulkAddToCart({ isCreatOrder: true })}
          >
            Checkout {total_items} {total_items > 1 ? "items" : "item"} (
            {formatCurrency({
              value: totalPrice,
              currencyCode: store_currency,
            })}
            )
          </primaryComponents.Button>
          <div className="d-flex" style={{ gap: "8px" }}>
            {showGeneratBills ? (
              <secondaryComponents.NavigateWithPermission
                permissionParentKey={"Inventory Management"}
                permissionChildKey="can_generate_bill"
              >
                <primaryComponents.Button
                  isLoading={isGeneratingBill}
                  isDisabled={
                    isGeneratingBill || total_items === 0 || isBulkAdding
                  }
                  loadingText="Generating bill..."
                  classNames="btn btn--outline cover"
                  onClick={() => bulkAddToCart({ isGenerateBill: true })}
                  primarySpinner={false}
                >
                  <img className="mr-2" src={Icon.paperIcon} alt="" />
                  Generate Bill
                </primaryComponents.Button>
              </secondaryComponents.NavigateWithPermission>
            ) : null}
            <primaryComponents.Button
              classNames="btn btn--outline cover"
              onClick={() => bulkAddToCart({ isPendCheckout: true })}
              isDisabled={
                isPendingSessions || total_items === 0 || isBulkAdding
              }
              isLoading={isPendingSessions}
              loadingText="Pending..."
            >
              <img className="mr-2" src={Icon.pauseIcon} alt="" />
              Pend Order
            </primaryComponents.Button>
          </div>
        </div>
      </div>

      <secondaryComponents.Modal
        width={550}
        isActive={discoutModals?.isDelivery}
        closeModal={() => resetDiscoutModals("isDelivery", false)}
      >
        <DeliveryFee
          setDeliveryFee={setDeliveryFee}
          deliveryFee={deliveryFee}
          currency_symbol={store_currency_symbol}
          closeModal={() => resetDiscoutModals("isDelivery", false)}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        width={550}
        isActive={discoutModals?.isDiscount}
        closeModal={() => resetDiscoutModals("isDiscount", false)}
      >
        <Discount
          setDiscount={setDiscount}
          discount={discount}
          currency_symbol={store_currency_symbol}
          closeModal={() => resetDiscoutModals("isDiscount", false)}
          totalAmount={totalAmount}
        />
      </secondaryComponents.Modal>

      <secondaryComponents.Modal
        width={700}
        isActive={discoutModals?.isLoyalty}
        closeModal={() => {
          resetDiscoutModals("isLoyalty", false);
          setLoyaltyDetails({
            loyaltyType: "GIFT_CARD",
            redemption_code: "",
            customer_id: "",
            giftcard_amount: "",
            loyalty_point_amount: "",
            customerDetails: {
              customerName: "",
              customerPhoneNumber: "",
              customerPhoneCode: "",
              customerEmail: "",
            },
          });
        }}
      >
        {campaigns && campaigns.length > 0 ? (
          <Loyalty
            setLoyaltyDetails={setLoyaltyDetails}
            loyaltyDetails={loyaltyDetails}
            currency_symbol={store_currency_symbol}
            closeModal={() => resetDiscoutModals("isLoyalty", false)}
          />
        ) : (
          <CampaignEmpty />
        )}
      </secondaryComponents.Modal>
    </>
  );
};
export default StoreCart;
