export const CreditWalletIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F3F1FC" />
      <path
        d="M16.6667 7.33334H7.33333C6.59695 7.33334 6 7.9303 6 8.66668V15.3333C6 16.0697 6.59695 16.6667 7.33333 16.6667H16.6667C17.403 16.6667 18 16.0697 18 15.3333V8.66668C18 7.9303 17.403 7.33334 16.6667 7.33334Z"
        stroke="#7647EE"
        strokeWidth="1.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 14C14.7363 14 15.3333 13.4031 15.3333 12.6667C15.3333 11.9303 14.7363 11.3333 14 11.3333C13.2636 11.3333 12.6666 11.9303 12.6666 12.6667C12.6666 13.4031 13.2636 14 14 14Z"
        stroke="#7647EE"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8819 11.6667C12.6468 11.4592 12.3381 11.3333 12 11.3333C11.2636 11.3333 10.6666 11.9303 10.6666 12.6667C10.6666 13.4031 11.2636 14 12 14C12.3381 14 12.6468 13.8741 12.8819 13.6667"
        stroke="#7647EE"
        strokeWidth="0.9"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
