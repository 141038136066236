import styles from "./SideModal.module.scss";
import ReactDOM from "react-dom";
import { motion } from "framer-motion";

const SideModal = ({
  children,
  width = 500,
  isActive = false,
  closeModal = () => {},
  position = "right",
  closeViaOverlay = true,
  isSellMode = false,
}) => {
  if (isActive === true) {
    return ReactDOM.createPortal(
      <div
        className={`${styles.sideModal} ${
          position === "right" ? styles.positionRight : styles.positionLeft
        } ${isSellMode ? styles.sellMode : ""}`}
      >
        {/* back layer */}
        <div
          onClick={() => {
            if (closeViaOverlay) closeModal();
          }}
          className={styles.sideModal__backdrop}
        ></div>
        {/* modal section */}
        <motion.div
          initial={{
            opacity: 0,
            marginRight: `${position === "right" ? `-${width}px` : "0px"}`,
            marginLeft: `${position === "left" ? `-${width}px` : "0px"}`,
          }}
          animate={{
            opacity: 1,
            marginRight: "0px",
            marginLeft: "0px",
            transition: { duration: 0.3 },
          }}
          exit={{ opacity: 0 }}
          className={styles.sideModal__container}
          style={{ width: width }}
        >
          {children}
        </motion.div>
      </div>,
      document.getElementById("sidemodal-root"),
    );
  }
};

export default SideModal;
