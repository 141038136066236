import React from "react";

const Completed = () => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.5" y="1" width="30" height="30" rx="15" fill="#19AE57" />
      <rect
        x="1.5"
        y="1"
        width="30"
        height="30"
        rx="15"
        stroke="#A3DFBC"
        strokeWidth="2"
      />
      <path
        d="M9.2998 16L14.0998 20.8L23.6998 11.2"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Completed;
