import styles from "./Signup.module.scss";
import { Link } from "react-router-dom";
import layouts from "../../../layouts";
import BiodataForm from "./BiodataForm";
import BusinessForm from "./BusinessForm";
import PasswordCreationForm from "./PasswordCreation";
import { motion } from "framer-motion";
import SignupLogic from "./SignupLogic";
import secondaryComponents from "../../../components/secondaryComponents";

const Sidebar = () => {
  return (
    <div className={styles.signupPage__sidebar}>
      <div className={styles.signupPage__sidebar__content}>
        <h2
          className={`${styles.signupPage__sidebar__content__title} white--text`}
        >
          A suite of tools built just for
          <em style={{ fontWeight: "bold" }}> you</em>
        </h2>
        <p className="big-text-size white--text mt-5">
          Accept offline and online payments, offer loyalty and track your
          business health
        </p>
      </div>
    </div>
  );
};

const Signup = () => {
  const signupLogic = SignupLogic();
  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Sign up"
      description="Sign up on lumi merchant"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          exit={{ opacity: 0 }}
          data-testid="signup-page"
        >
          <layouts.SignupLayout sidebar={<Sidebar />}>
            <div className={styles.signupPage}>
              <p className="text-right">
                Already an account?{" "}
                <Link
                  className="purple--text"
                  to="/login"
                  data-testid="login-btn"
                >
                  Log In
                </Link>
              </p>
              <div className={styles.signupPage__content}>
                {signupLogic.index === 0 && (
                  <BiodataForm SignupLogic={signupLogic} />
                )}
                {signupLogic.index === 1 && (
                  <BusinessForm SignupLogic={signupLogic} />
                )}
                {signupLogic.index === 2 && (
                  <PasswordCreationForm SignupLogic={signupLogic} />
                )}
              </div>
            </div>
          </layouts.SignupLayout>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default Signup;
