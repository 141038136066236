import { useState } from "react";
import { useQuery } from "react-query";
import { getPendingSessions } from "../services/sales-point";

const usePendingCheckoutSession = () => {
  const [queryParams, setQueryParams] = useState({});

  const {
    isLoading: isFetchingSessions,
    data,
    refetch: refetchPendingSessions,
  } = useQuery(
    ["getPendingSessions", queryParams],
    () =>
      getPendingSessions(queryParams).then((res) => {
        return res?.data;
      }),
    {
      staleTime: Infinity,
    },
  );

  const gotoPage = (pageNumber) => {
    const newQueryParams = { ...queryParams };
    newQueryParams.page = pageNumber;
    setQueryParams(newQueryParams);
  };

  return {
    isFetchingSessions,
    pendingSessions: data?.data || [],
    metaDetails: data?.meta || {},
    gotoPage,
    refetchPendingSessions,
  };
};
export default usePendingCheckoutSession;
