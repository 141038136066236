import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const createSubAccountSchema = yup
  .object({
    bankProvider: yup
      .mixed()
      .oneOf(["vbank", "kuda"], "Invalid provider. Select a valid provider")
      .required("Select your preferred bank provider"),
    subAccountName: yup.string().required("Enter your Sub Account name"),
    subAccountDescription: yup
      .string()
      .required("Account description is required"),
    subAccountPhoneNumber: yup
      .string()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .test("num", "Invalid phone number format", (val) => !val.startsWith("0"))
      .min(10, "Invalid phone number format")
      .max(10, "Invalid phone number format")
      .required("Phone number is required"),
    subAccountEmail: yup.string().required("Email is required"),
  })
  .required();

const CreateSubAccountValidation = ({
  bankProvider,
  subAccountName,
  subAccountDescription,
  subAccountPhoneNumber,
  subAccountEmail,
}) => {
  return useForm({
    resolver: yupResolver(createSubAccountSchema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      bankProvider: bankProvider,
      subAccountName: subAccountName,
      subAccountPhoneNumber: subAccountPhoneNumber,
      subAccountEmail: subAccountEmail,
      subAccountDescription: subAccountDescription,
    },
  });
};

export default CreateSubAccountValidation;
