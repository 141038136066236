import { useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ToastContext } from "../../../hooks/context";
import { returnStock } from "../../../services/inventory";
import { cleanObject } from "../../../helpers";
import { useGetAccountingBanksListWithSpreadFilter } from "../../../hooks/api/queries";

export const useReturnStock = ({ closeWidget }) => {
  const triggerToast = useContext(ToastContext);
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    order_id: "",
    store_id: "",
    remarks: "",
    items: [],
    return_to_store_wallet: false,
    ledger_account_id: "",
  });

  const {
    isLoading: isLoadingBankAccountsList,
    data: bankAccount,
    refetch: fetchBankAccountsList,
  } = useGetAccountingBanksListWithSpreadFilter();

  const isOrderItemsSelected =
    formData?.items
      ?.map((item) => item?.isReturning === true)
      ?.filter((item) => item === true)?.length >= 1;

  const returnStockMutation = useMutation({
    mutationFn: (data) => returnStock(data),
    onSuccess: () => {
      triggerToast("Product returned successfully", "success");
      closeWidget();
    },
  });

  const handleReturnStock = () => {
    const customerId = formData?.customer?.customerId;
    if (formData?.return_to_store_wallet && !customerId)
      return triggerToast("Please select the customer you wish to credit");

    returnStockMutation.mutate(
      cleanObject(
        {
          ...formData,
          items: formData?.items?.filter((item) => item?.isReturning !== false),
          remarks: formData.remarks ? formData.remarks : undefined,
          customer_id: customerId,
        },
        ["customer"],
      ),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["getStoreWalletTransactionDetails"]);
        },
      },
    );
  };

  useEffect(() => {
    if (bankAccount?.data?.length > 0 && !formData?.ledger_account_id) {
      setFormData((prev) => ({
        ...prev,
        ledger_account_id: bankAccount?.data[0]?.id,
      }));
    }
  }, []);

  return {
    formData,
    isReturningStock: returnStockMutation.isLoading,
    stores: storeList,
    isOrderItemsSelected,
    isLoadingBankAccountsList,
    bankAccount,
    handleReturnStock,
    setFormData,
    fetchBankAccountsList,
  };
};
