import "./ApiKeys.scss";
import Icon from "../../../../assets/icons";
import primaryComponents from "../../../../components/primaryComponents";
import ApiKeysLogic from "./ApiKeysLogic";
import { CopyToClipboard } from "react-copy-to-clipboard";
import secondaryComponents from "../../../../components/secondaryComponents";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";

const ApiKeys = () => {
  const {
    maskKeys,
    generateApiKey,
    isGeneratingKeys,
    isFetchingSecretKeys,
    triggerToast,
    isRevealing,
    revealKey,
  } = ApiKeysLogic();

  return (
    <div className={`api-keys-page ${globalStyles.pageColumnGaps}`}>
      <div>
        <h4 className="mb-0">API</h4>
        <p>Generate API keys</p>
      </div>
      <div className="api-keys-page__nav">
        <div className="d-flex ml-auto">
          <primaryComponents.Button
            classNames={"btn btn--outline mr-2"}
            isLoading={isRevealing}
            isDisabled={isRevealing}
            loadingText="Revealing..."
            onClick={revealKey}
          >
            Reveal Key
          </primaryComponents.Button>
          <primaryComponents.Button
            classNames={"btn btn--primary"}
            isLoading={isGeneratingKeys}
            isDisabled={isGeneratingKeys}
            loadingText="Generating..."
            onClick={generateApiKey}
          >
            Generate New Key
          </primaryComponents.Button>
        </div>
      </div>

      <secondaryComponents.LoaderHelper
        isLoading={isFetchingSecretKeys}
        classNames="mt-5"
      >
        {maskKeys.masked_secret_key ? (
          <div className="api-keys-page__content">
            <div className="api-keys-page__content__section">
              <h5 className="api-keys-page__content__section__title">
                Secret Key
              </h5>
              <div className="api-keys-page__content__section__key">
                <span className="api-keys-page__content__section__key__label">
                  {isFetchingSecretKeys
                    ? "Loading..."
                    : maskKeys?.masked_secret_key}
                </span>
                <CopyToClipboard
                  onCopy={() => triggerToast("Copied secret key", "success")}
                  text={maskKeys?.masked_secret_key}
                >
                  <primaryComponents.Button classNames={"btn btn--outline "}>
                    <img className="mr-2" src={Icon.copy} alt="" />
                    <span>Copy</span>
                  </primaryComponents.Button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        ) : (
          <h5 className="mt-5 text-center">
            Click &quot;Generate New Key&quot; button above to generate an API
            key
          </h5>
        )}
      </secondaryComponents.LoaderHelper>
    </div>
  );
};

export default ApiKeys;
