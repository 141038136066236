import { useContext, useState } from "react";
import { useMutation } from "react-query";
import { ToastContext } from "../../../../hooks/context";
import { checkoutwithCard } from "../../../../services/sales-point";
import { cleanObject } from "../../../../helpers";

const CardPaymentLogic = ({ closePaymentSession, selectBank }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const orderId = queryParams.get("order_id");
  const triggerToast = useContext(ToastContext);
  const [selectedCardPayments, setSelectedCardPayments] = useState("");
  const [isEnabled, setIsEnabled] = useState(true);
  // const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
  // const fetchCardPayments = async () => {
  //   return await getTransactionViaCard().then((response) => response.data.data);
  // };
  // const { data: cardPayments, isLoading: isLoadingcardPayments } = useQuery(
  //   "cards",
  //   fetchCardPayments,
  //   {
  //     enabled: isEnabled,
  //     onSuccess: () => {
  //       setIsEnabled(false);
  //     },
  //
  //   }
  // );

  // const payViaCard = () => {
  //   setIsConfirmingPayment(true);
  //   checkoutwithCard({ order_id: orderId, reference: selectedCardPayments })
  //     .then(() => {
  //       setIsConfirmingPayment(false);
  //       triggerToast("Payment confirmation successful", "success");
  //       closePaymentSession();
  //     })
  //     .catch((error) => {
  //       setIsConfirmingPayment(false);
  //       if (error?.response?.status === 400)
  //         triggerToast(error?.response?.data?.detail, "warning");
  //     });
  // };

  const { isLoading: loadingExternal, mutate: paidExternally } = useMutation(
    (data) => checkoutwithCard(data),
  );

  const payViaExternal = () => {
    paidExternally(
      cleanObject({
        order_id: orderId,
        reference: null,
        ledger_account_id: selectBank,
      }),
      {
        onSuccess: () => {
          triggerToast("Payment linked successful", "success");
          closePaymentSession();
        },
      },
    );
  };

  return {
    // cardPayments,
    // isLoadingcardPayments,
    selectedCardPayments,
    isEnabled,
    // isConfirmingPayment,
    loadingExternal,
    setSelectedCardPayments,
    setIsEnabled,
    // payViaCard,
    payViaExternal,
  };
};
export default CardPaymentLogic;
