/* eslint-disable react/jsx-key */
import styles from "./TransactionsTable.module.scss";
import useTransactionsTable from "./TransactionTableLogic";
import secondaryComponents from "..";
import Images from "../../../assets/images";

const TransactionsTable = ({ transactions, metaDetails, gotoPage }) => {
  const { instance } = useTransactionsTable({ transactions });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    instance;

  return (
    <div className={styles.transactionsTable}>
      {/* table section */}
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      >
        <div className="text-center mt-5">
          <img
            width="250px"
            height="262px"
            src={Images.tnxEmptyState}
            alt="empty state"
          />
          <h4>You have no transactions</h4>
          <p>When you do, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>

      {/* pagination section */}
      {transactions.length > 0 && (
        <secondaryComponents.Pagination
          totalPageNumber={metaDetails.last_page}
          handlePageClick={(value) => gotoPage(value?.selected + 1)}
          currentPage={metaDetails.current_page}
        />
      )}
    </div>
  );
};

export default TransactionsTable;
