import styles from "./MenuPageLayout.module.scss";
import { Outlet } from "react-router-dom";
import Logo from "../../assets/logo";
import primaryComponents from "../../components/primaryComponents";
import secondaryComponents from "../../components/secondaryComponents";
import { useMenuPageLayout } from "./useMenuPageLayout";
import { CartIcon, Location } from "../../assets/svgIcons";
import Icon from "../../assets/icons";
import { IoIosArrowDown } from "react-icons/io";

const MenuPageLayout = () => {
  const {
    primaryColor,
    countryFlag,
    isLoadingMenuObject,
    isLoadingMenuObjectDetail,
    menuCartItems,
    menuSearchText,
    showLocationChange,
    tempMenuId,
    showCartItems,
    menuObject,
    menuObjectDetail,
    isActiveCart,
    setIsActiveCart,
    setMenuSearchText,
    setShowCartItems,
    setShowLocationChange,
  } = useMenuPageLayout();

  return (
    <>
      <secondaryComponents.LoaderHelper
        isLoading={
          isLoadingMenuObject ||
          isLoadingMenuObjectDetail ||
          !menuObject ||
          !menuObjectDetail
        }
        classNames={"mt-5"}
      >
        <div className={styles.menuLayout}>
          <div
            style={{
              background: "white",
            }}
            className={styles.navbar}
          >
            {menuObjectDetail?.logo_url && (
              <img
                src={menuObjectDetail?.logo_url}
                style={{ maxWidth: "140px", maxHeight: "80px", width: "100%" }}
                alt="logo"
                className={styles.logo}
              />
            )}

            <primaryComponents.SearchField
              value={""}
              placeholder={"Search by category, food and drinks etc"}
              triggerOnInput={true}
              showsearchBtn={false}
              trigger={(value) => setMenuSearchText(value)}
              classNames={`${styles.search}`}
              borderColor={primaryColor}
            />

            <div className={styles.navbar__left}>
              {menuObjectDetail?.logo_url && (
                <img
                  src={menuObjectDetail?.logo_url}
                  style={{
                    maxWidth: "120px",
                    maxHeight: "50px",
                    width: "100%",
                  }}
                  alt="logo"
                  className={styles.logo2}
                />
              )}
              <div className={styles.navbar__right}>
                <div
                  onClick={() => setIsActiveCart(!isActiveCart)}
                  className={styles.rightItem}
                  style={{ position: "relative" }}
                >
                  <CartIcon color={primaryColor} />
                  <p>Cart</p>
                  <span
                    style={{ background: primaryColor + "E6" }}
                    className={styles.cartCount}
                  >
                    {menuCartItems?.length}
                  </span>
                </div>
                <div
                  onClick={() => setShowLocationChange(true)}
                  className={styles.rightItem}
                >
                  <div style={{ fontSize: 16 }}>
                    {menuObjectDetail?.store?.countries[0]?.emoji ||
                      countryFlag}
                  </div>
                  <p style={{ textDecoration: "underline" }}>
                    {menuObjectDetail?.store?.countries[0]?.name ||
                      menuObjectDetail?.store?.country}{" "}
                    <img src={Icon.arrow_down_alt} alt="arrow" />
                  </p>
                </div>
              </div>
              <div className={styles.btnContainer}>
                <primaryComponents.Button
                  classNames={"btn"}
                  style={{
                    borderColor: primaryColor,
                    background: "#fff",
                    color: primaryColor,
                  }}
                  onClick={() => setShowLocationChange(true)}
                >
                  {" "}
                  <Location color={primaryColor} />
                  <span className="dark--text ml-2 mr-3">
                    {menuObjectDetail?.store?.countries[0]?.name ||
                      menuObjectDetail?.store?.country}
                  </span>
                  <IoIosArrowDown
                    color={primaryColor ? primaryColor : "#7647ee"}
                    size={20}
                  />
                </primaryComponents.Button>
              </div>

              {menuObjectDetail?.allow_payment &&
              (menuObjectDetail?.allow_in_store_checkout ||
                menuObjectDetail?.allow_delivery) ? (
                <primaryComponents.Button
                  classNames={`btn ${styles.cartBtn}`}
                  style={{
                    borderColor: primaryColor,
                    background: "#fff",
                    color: primaryColor,
                    padding: "0 15px",
                    overflow: "visible",
                  }}
                  onClick={() => setShowCartItems(true)}
                >
                  <CartIcon color={primaryColor ? primaryColor : "#7647ee"} />
                  <span
                    className={`${styles.cart} small-text-size`}
                    style={{
                      background: primaryColor,
                      color: "#fff",
                    }}
                  >
                    {menuCartItems?.length}
                  </span>
                </primaryComponents.Button>
              ) : null}
            </div>
          </div>

          <div className={styles.menuOutlet}>
            <Outlet
              context={{
                menuSearchText,
                showCartItems,
                menuObject,
                menuObjectDetail,
                isActiveCart,
                setIsActiveCart,
                setShowCartItems,
              }}
            />
            <div className={styles.bottomContent}>
              <div className={styles.storefrontLoc}>
                <div style={{ marginBottom: 4 }}>
                  <div className={styles.address}>
                    <img src={Icon.StorefrontLocation} alt="" />
                    <p>Address</p>
                  </div>
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${menuObjectDetail?.store?.address
                      .split(" ")
                      .join("+")}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {menuObjectDetail?.store?.address}
                  </a>
                </div>
                <div>
                  <div className={styles.address}>
                    <img src={Icon.StorefrontPhone} alt="" />
                    <p>Phone</p>
                  </div>
                  <p className={styles.place}>
                    +{menuObjectDetail?.store?.phone_number}
                  </p>
                </div>
              </div>
              <div className={styles.lumiPoweredBy}>
                <img src={Logo.powered_logo} alt="logo" />
                <div>
                  <p className={"small-text-size dark--text"}>Powered by</p>
                  <h4 className={"purple--text"}>Lumi Business</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </secondaryComponents.LoaderHelper>
      <secondaryComponents.Modal
        isActive={showLocationChange}
        closeModal={() => setShowLocationChange(false)}
        width={1000}
        closeViaOverlay={tempMenuId ? true : false}
      >
        <secondaryComponents.StoreFrontLocationChangeModal
          primarColor={primaryColor}
        />
      </secondaryComponents.Modal>
    </>
  );
};

export default MenuPageLayout;
