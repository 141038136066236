import primaryComponents from "../../../primaryComponents";

const PrintTypeOption = ({
  printOnDeviceAction,
  printOnDevicePOS,
  closeModal,
}) => {
  return (
    <div className="px-4 py-2">
      <h5 className="text-center mb-3">Select print option</h5>
      <div className="d-flex" style={{ gap: "10px" }}>
        <primaryComponents.Button
          classNames={"btn btn--primary cover"}
          onClick={() => {
            printOnDeviceAction();
            closeModal();
          }}
        >
          Print On Device
        </primaryComponents.Button>
        <primaryComponents.Button
          classNames={"btn btn--outline cover"}
          onClick={() => {
            printOnDevicePOS();
            closeModal();
          }}
        >
          Print On POS
        </primaryComponents.Button>
      </div>
    </div>
  );
};
export default PrintTypeOption;
