import React, { Fragment } from "react";
import styles from "./styles.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
// import Icon from "../../../../../assets/icons";
import { useSelector } from "react-redux";
import { PRODUCT_DEFAULT_ICONS } from "../../../../../helpers/constants";
import {
  formatCurrency,
  getPriceWithDiscout,
  getPriceWithoutDiscout,
  getVariantPriceWithDiscout,
  getVariantPriceWithoutDiscout,
} from "../../../../../helpers";
import {
  ChevronLeftArrow,
  FreeDeliveryCheckoutIcon,
} from "../../../../../assets/svgIcons";

const OrderSummaryModal = ({
  setShowOrderSummaryModal,
  setShowCartItems,
  setShowPayForOrder,
  showOrderSummary,
  menuObjectDetail,
}) => {
  const { menuCartItems } = useSelector((state) => state.menuCartReducer);
  const store = menuObjectDetail?.store;
  const shippingRate = Number(
    showOrderSummary?.data?.data?.delivery_fee_amount || 0,
  );

  const total = Number(
    menuCartItems.reduce(
      (sum, product) =>
        sum +
        (product?.is_variant
          ? getVariantPriceWithDiscout(product) * product.quantity
          : getPriceWithDiscout(product) * product.quantity),
      0,
    ) + shippingRate,
  );

  const primaryColor = menuObjectDetail?.primary_colour
    ? menuObjectDetail?.primary_colour
    : "#7647ee";

  return (
    <div className={styles.storeCustomerForm}>
      <div
        onClick={() => {
          setShowOrderSummaryModal(false);
          setShowCartItems(true);
        }}
        className={styles.back_icon_container}
        style={{ borderColor: primaryColor }}
      >
        <ChevronLeftArrow color={primaryColor} />
      </div>

      <div className={styles.checkout_heading_container}>
        <h4>Order Summary</h4>
        <p>Confirm your order before proceeding to payment</p>
      </div>

      <div className={styles.order_summary}>
        <p className={styles.cart_length}>
          {menuCartItems.length} item{menuCartItems.length > 1 ? "s" : ""}
        </p>
        <div className={styles.divider} />
        <div className={styles.cart_items}>
          {menuCartItems.map((product, index) => (
            <Fragment key={index}>
              <div className={styles.cart_item}>
                <div className={styles.cart_item_content}>
                  <span className={styles.name}>{product.name}</span>
                  <p className={styles.qty}>Qty: {product.quantity}</p>
                  <h4 className={styles.price}>
                    {product.store?.currency_symbol}{" "}
                    {(product?.is_variant
                      ? getVariantPriceWithDiscout(product) * product.quantity
                      : getPriceWithDiscout(product) * product.quantity
                    ).toLocaleString()}{" "}
                    {(product?.is_variant
                      ? product.store_variant_properties[0]?.pricings?.find(
                          (item) => item.pricing_type.id === 1,
                        )?.discount_rate > 0
                      : product?.is_service
                        ? product.store_service_properties[0]?.pricings?.find(
                            (item) => item.pricing_type.id === 1,
                          )?.discount_rate > 0
                        : product.store_product_properties[0]?.pricings?.find(
                            (item) => item.pricing_type.id === 1,
                          )?.discount_rate > 0) && (
                      <>
                        <span className={styles.cancelled_price}>
                          {product.store?.currency_symbol}{" "}
                          {(product?.is_variant
                            ? getVariantPriceWithoutDiscout(product) *
                              product.quantity
                            : getPriceWithoutDiscout(product) * product.quantity
                          ).toLocaleString()}
                        </span>{" "}
                        <span className={styles.off_price}>
                          {product?.is_variant
                            ? product.store_variant_properties[0]?.pricings?.find(
                                (item) => item.pricing_type.id === 1,
                              )?.discount_rate > 0
                            : product?.is_service
                              ? product.store_service_properties[0]?.pricings?.find(
                                  (item) => item.pricing_type.id === 1,
                                )?.discount_rate > 0
                              : product.store_product_properties[0]?.pricings?.find(
                                  (item) => item.pricing_type.id === 1,
                                )?.discount_rate > 0}
                          % OFF
                        </span>
                      </>
                    )}
                  </h4>
                </div>
                <div className={styles.cart_item_img}>
                  <img
                    src={product?.image_url || PRODUCT_DEFAULT_ICONS[index % 5]}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.divider} />
            </Fragment>
          ))}
        </div>
        <div className="d-flex flex-wrap" style={{ gap: "5px" }}>
          {shippingRate === 0 ? (
            ""
          ) : (
            <p className={styles.free_delivery} style={{ color: primaryColor }}>
              You will receive your item(s) in{" "}
              <b>
                {showOrderSummary?.data?.data?.shipping_group?.delivery_window
                  ? showOrderSummary.data.data.shipping_group.delivery_window +
                    (showOrderSummary.data.data.shipping_group.delivery_window >
                    1
                      ? " Working days"
                      : " Working day")
                  : ""}{" "}
              </b>
            </p>
          )}
          {shippingRate === 0 ? (
            <p
              className={`d-flex align-items-center ${styles.free_delivery}`}
              style={{ gap: "5px", color: primaryColor }}
            >
              <span className="font-weight-bold">Free shipping </span>
              <FreeDeliveryCheckoutIcon />{" "}
            </p>
          ) : (
            ""
          )}
        </div>
        <div className={styles.subtotal_container}>
          <div className={styles.subtotal}>
            <p className={styles.title}>Subtotal</p>
            <p className={styles.amount}>
              {formatCurrency({
                value: menuCartItems.reduce(
                  (sum, product) =>
                    sum +
                    (product?.is_variant
                      ? getVariantPriceWithDiscout(product) * product.quantity
                      : getPriceWithDiscout(product) * product.quantity),
                  0,
                ),
                currencyCode: store?.currency_code,
              })}
            </p>
          </div>
          <div className={styles.subtotal}>
            <p className={styles.title}>Delivery fee:</p>
            <p className={styles.amount}>
              {formatCurrency({
                value: shippingRate,
                currencyCode: store?.currency_code,
              })}
            </p>
          </div>
          <div className={styles.divider} />
          <div className={styles.subtotal}>
            <p className={styles.total}>Total:</p>
            <p style={{ color: primaryColor }} className={styles.total_amount}>
              {formatCurrency({
                value: Number(total),
                currencyCode: store?.currency_code,
              })}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.storeCustomerForm__bottom}>
        <primaryComponents.Button
          classNames="btn btn--primary cover"
          isDisabled={false}
          isLoading={false}
          loadingText="Submitting"
          onClick={() => {
            setShowOrderSummaryModal(false);
            setShowPayForOrder(true);
          }}
          style={{ background: primaryColor }}
        >
          Proceed to Payment
        </primaryComponents.Button>
      </div>
    </div>
  );
};

export default OrderSummaryModal;
