import { useState } from "react";
import { useCreateProductUnit } from "../../../hooks/api/mutations/useInventory";
import primaryComponents from "../../primaryComponents";
import { useValidation } from "./useValidation";

const ProductUnitForm = ({ closeWidget }) => {
  const [unitDetails, setUnitDetails] = useState({
    name: "",
    unit: "",
  });
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    trigger,
  } = useValidation({
    unitDetails,
  });

  const createUnit = useCreateProductUnit();
  const handleUnitCreation = () => {
    createUnit.mutate(unitDetails, { onSuccess: () => closeWidget() });
  };

  return (
    <form className="p-3" onSubmit={handleSubmit(handleUnitCreation)}>
      <h4 className="text-center mb-3">Create a new unit</h4>

      <primaryComponents.InputFieldV2
        name="name"
        control={control}
        register={register}
        placeholder={"Enter unit name"}
        classNames="white mb-1"
        label="Unit Name"
        value={unitDetails.name}
        isRequired
        errorMessage={errors?.name}
        onKeyUp={() => errors?.name && trigger("name")}
        onChange={({ target: { value } }) => {
          setUnitDetails((prevState) => ({
            ...prevState,
            name: value,
          }));
        }}
      />

      <primaryComponents.InputFieldV2
        name="unit"
        control={control}
        register={register}
        placeholder={"Enter unit"}
        classNames="white"
        label="Unit"
        value={unitDetails.unit}
        isRequired
        errorMessage={errors?.unit}
        onKeyUp={() => errors?.unit && trigger("unit")}
        onChange={({ target: { value } }) => {
          setUnitDetails((prevState) => ({
            ...prevState,
            unit: value,
          }));
        }}
      />

      <primaryComponents.Button
        classNames={"btn btn--primary mt-3 w-100"}
        type="submit"
        isDisabled={createUnit?.isLoading}
        isLoading={createUnit?.isLoading}
        loadingText={"Creating..."}
      >
        Create
      </primaryComponents.Button>
    </form>
  );
};
export default ProductUnitForm;
