import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const soleProprietorSchema = yup.object({
  business_category: yup.string().required("Business category is required"),
  business_description: yup
    .string()
    .required("Business description is required"),
  brand_name: yup.string().required("brand name is required"),
  business_state: yup.string().required("State is required"),
  business_lga: yup.string().required("Local government is required"),
  business_address: yup.string().required("Business address is required"),
  cac_reg_number: yup
    .number()
    .typeError("Invalid cac number format")
    .required("cac number is required"),
  tax_id_number: yup.string().required("tax identification number is required"),
  directors_bvn: yup.string().required("BVN is required"),
  agree_terms: yup
    .boolean()
    .typeError("invalid format")
    .required("Please accept terms and conditions"),
  inc_date: yup
    .string()
    .test(
      "invalid date",
      "Date selected is greater than today's date",
      (value) => {
        if (value) {
          let today = new Date();
          let date = new Date(value);
          return date <= today;
        }
        return true;
      },
    )
    .required("Incorporation date is required"),
});

const useSolePropretorKybFormValidation = (fields) => {
  return useForm({
    resolver: yupResolver(soleProprietorSchema),
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: { ...fields },
  });
};

export default useSolePropretorKybFormValidation;
