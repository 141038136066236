import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getExpenseDetails } from "../../../../services/expenses";

const useExpenseDetails = () => {
  const params = useParams();
  const [isReviewExpense, setIsReviewExpense] = useState(false);

  const { isLoading: isFetchingExpenseDetails, data: expenseDetails } =
    useQuery(["getExpenseDetails", params.id], () =>
      getExpenseDetails(params.id).then((res) => res?.data?.data),
    );

  return {
    isFetchingExpenseDetails,
    expenseDetails,
    isReviewExpense,
    setIsReviewExpense,
  };
};
export default useExpenseDetails;
