import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getUrlQuerysection } from "../../../../helpers";
import { getStockHistory } from "../../../../services/inventory";

const useProductStockHistory = () => {
  const { storeList } = useSelector((state) => state.profileDetailsReducer);
  const [currentStore, setCurrentStore] = useState(null);
  const [isDownLoadWidget, setIsDownLoadWidget] = useState(false);
  const [search, setSearch] = useSearchParams();
  const queries = getUrlQuerysection();

  const {
    isLoading: isFetchingStockHistory,
    data: stockHistory,
    refetch: handleFetchStockHistory,
  } = useQuery(["getStockHistory", queries], () =>
    getStockHistory(queries).then((res) => res.data),
  );

  const gotoPage = (pageNumber) => {
    search.set("page", pageNumber);
    setSearch(search, { replace: true });
  };

  return {
    search,
    isFetchingStockHistory,
    storeList,
    currentStore,
    metaDetails: stockHistory?.meta || {},
    isDownLoadWidget,
    stockHistory: stockHistory?.data || [],
    handleFetchStockHistory,
    setIsDownLoadWidget,
    setCurrentStore,
    gotoPage,
  };
};
export default useProductStockHistory;
