import { useInfiniteQuery } from "react-query";
import { getUploadedImage } from "../../../services/media";
import { useInView } from "react-intersection-observer";
import { cleanObject } from "../../../helpers";
import { useEffect } from "react";

export const useGetUploadedImages = () => {
  const { ref: autoScrollRef, inView } = useInView();
  const uploadedImagesQueries = useInfiniteQuery(
    ["getUploadedImage"],
    ({ pageParam }) =>
      getUploadedImage(
        cleanObject({
          page: pageParam,
        }),
      ).then((res) => {
        return {
          data: res?.data?.data,
          meta: res?.data?.meta,
        };
      }),
    {
      staleTime: Infinity,
      getNextPageParam: ({ meta: { next_page } }) =>
        next_page === null ? undefined : next_page,
    },
  );
  const { data, hasNextPage, fetchNextPage } = uploadedImagesQueries;
  const uploadedImages = new Map();
  // eslint-disable-next-line array-callback-return
  data?.pages?.map((page) => {
    uploadedImages.set(`${page.meta.current_page}`, page?.data);
  });
  const uploadedImagesList = [].concat(...uploadedImages.values());

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, fetchNextPage, hasNextPage]);

  return {
    ...uploadedImagesQueries,
    autoScrollRef,
    data: uploadedImagesList,
  };
};
