import "./InviteUser.scss";
import primaryComponents from "../../primaryComponents";
import secondaryComponents from "..";
import InviteUserLogic from "./InviteUserLogic";
import InviteValidation from "./InviteUserValidation";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const InviteUser = ({ showInviteModal, setShowInviteModal }) => {
  const {
    email,
    setEmail,
    role,
    setRole,
    errorMessage,
    isInviting,
    inviteUser,
    resetForm,
    userRoles,
    subAccounts,
    businessId,
    setBusinessId,
    storeList,
    storeId,
    setStoreId,
  } = InviteUserLogic(setShowInviteModal);
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    reset,
    formState: { errors },
    trigger,
    setError,
  } = InviteValidation(email, role, businessId, storeId);

  useEffect(() => {
    window.setInviteError = setError;
    window.clearInviteError = clearErrors;
    window.resetInviteForm = reset;
  }, []);

  return (
    <secondaryComponents.Modal
      width="550px"
      isActive={showInviteModal}
      closeModal={() => {
        resetForm();
        setShowInviteModal(false);
      }}
    >
      <div className="invite-user__modal" data-testid="invite-new-user">
        <h3>Invite New User</h3>
        <p>Invite team members and assign them a role</p>
        {/* error message */}
        {errorMessage !== null && (
          <p className="text-danger text-center mb-1">{errorMessage}</p>
        )}
        <form
          onSubmit={handleSubmit(inviteUser)}
          className="invite-user__modal__form"
        >
          {/* email address */}
          <div>
            <Controller
              name="email"
              control={control}
              defaultValue={email}
              render={({ field: { onChange } }) => (
                <primaryComponents.InputField
                  label="Email Address"
                  placeholder="Enter email address of team member"
                  isRequired={true}
                  type="email"
                  name="email"
                  errorMessage={errors.email}
                  onKeyUp={() => errors.email !== undefined && trigger("email")}
                  onChange={({ target: { value } }) => {
                    onChange(value);
                    setEmail(value);
                  }}
                  register={register}
                />
              )}
            />
          </div>

          {/* user sub accounts */}
          <div className="mt-2">
            <Controller
              control={control}
              defaultValue={businessId}
              name="businessId"
              render={({ field: { onChange } }) => (
                <primaryComponents.MultipleSelectField
                  label="Assign Subaccounts"
                  isRequired={true}
                  placeholder="Select subaccount"
                  name="businessId"
                  errorMessage={errors.businessId}
                  options={subAccounts}
                  idKey="id"
                  nameKey="tag"
                  onChange={(values) => {
                    onChange(values);
                    let newValues = [];
                    values?.forEach((item) => {
                      newValues.push(item.value);
                    });
                    setBusinessId(newValues);
                  }}
                />
              )}
            />
          </div>

          <div>
            <Controller
              control={control}
              defaultValue={storeId}
              name="storeId"
              render={({ field: { onChange } }) => (
                <primaryComponents.MultipleSelectField
                  label="Assign store"
                  isRequired={true}
                  placeholder="Select store"
                  name="storeId"
                  errorMessage={errors.storeId}
                  options={storeList}
                  idKey="id"
                  nameKey="name"
                  onChange={(values) => {
                    onChange(values);
                    let newValues = [];
                    values?.forEach((item) => {
                      newValues.push(String(item.value));
                    });
                    setStoreId(newValues);
                  }}
                />
              )}
            />
          </div>

          {/* users role */}
          <div>
            <Controller
              control={control}
              defaultValue={role}
              name="role"
              render={({ field: { onChange } }) => (
                <primaryComponents.SelectField
                  label="Role"
                  isRequired={true}
                  placeholder="Select role"
                  name="role"
                  value={role === "" ? "Select role..." : role}
                  errorMessage={errors.role}
                  options={userRoles || []}
                  idKey="id"
                  nameKey="label"
                  onChange={(value) => {
                    onChange(
                      userRoles?.find((item) => item.id === value).label,
                    );
                    setRole(value);
                  }}
                />
              )}
            />
          </div>

          {/* TODO: display sub-account list here... */}
          <div className="invite-user__modal__actions">
            {/* Invite button */}
            <primaryComponents.Button
              classNames={"btn btn--primary btnInvite"}
              isLoading={isInviting}
              isDisabled={isInviting}
              loadingText="Inviting..."
              type="submit"
            >
              Invite new user
            </primaryComponents.Button>
          </div>
        </form>
      </div>
    </secondaryComponents.Modal>
  );
};

export default InviteUser;
