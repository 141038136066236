const referralOptions = [
  { value: "FROM_A_FRIEND", label: "From a friend" },
  { value: "FACEBOOK", label: "Facebook" },
  { value: "INSTAGRAM", label: "Instagram" },
  { value: "TWITTER", label: "Twitter" },
  { value: "GOOGLE", label: "Google" },
  { value: "YOUTUBE", label: "Youtube" },
  { value: "AFFILIATE", label: "Affiliate" },
  { value: "REFERRAL", label: "Referral" },
  { value: "OTHER", label: "Other" },
];
export default referralOptions;
