import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getInvoiceDetails } from "../../../../services/inventory";
import { useGetSuppliers } from "../../../../hooks/api/queries";

export const useInvoiceDetails = () => {
  const params = useParams();
  const invoice_id = params.id;

  const { data: suppliers, isLoading: isFetchingSupplier } = useGetSuppliers();

  const { isLoading: isFetchingInvoiceDetails, data: invoiceDetails } =
    useQuery(["invoiceDetails", invoice_id], () =>
      getInvoiceDetails(invoice_id).then((res) => res.data?.data),
    );

  return {
    isFetchingInvoiceDetails,
    isFetchingSupplier,
    suppliers,
    invoiceDetails,
  };
};
