import styles from "./GiftCardItem.module.scss";
import primaryComponents from "../../primaryComponents";
import { formatCurrency } from "../../../helpers";
import { useGiftCardItemLogic } from "./GiftCardLogic";
import Images from "../../../assets/images";
import { useSelector } from "react-redux";

const GiftCardItem = ({ giftcardDetails }) => {
  const { currency_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );
  const { setStatus, status, toggleGiftCardStatus } = useGiftCardItemLogic({
    giftcard_id: giftcardDetails?.id,
    giftcard_status: giftcardDetails?.status,
  });

  return (
    <div className={styles.giftcardItem}>
      <div className="d-flex align-items-center justify-content-between">
        <p className="dark--text small-text-size">Validity Period</p>
        <p className="error--text small-text-size">
          {giftcardDetails?.redemption_period} days
        </p>
      </div>
      <div className={styles.giftcardItem__container}>
        <img
          className={styles.giftcardIcon}
          src={Images.giftcard_image}
          alt="giftcard-icon"
        />

        <h4 className="font-weight-semibold">{giftcardDetails?.name}</h4>
        <div className={styles.giftcardItem__container__points}>
          {/* <span>
            {formatCurrency({
              value: giftcardDetails?.amount_in_naira,
              currencyCode: currency_code,
            }) || "N/A"}
          </span> */}
          <span>
            {formatCurrency({
              value: giftcardDetails?.monetary_value,
              currencyCode: currency_code,
            }) || "N/A"}
            {/* {giftcardDetails?.amount_in_points?.toLocaleString("en-US") ||
              "N/A"}{" "} */}
          </span>
        </div>

        <div className={styles.customSwitch}>
          <primaryComponents.Switch
            name={giftcardDetails?.id}
            id={giftcardDetails?.id}
            isChecked={giftcardDetails?.status === "ACTIVE"}
            onChange={() =>
              setStatus((prev) => !prev, toggleGiftCardStatus(status))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GiftCardItem;
