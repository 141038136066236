import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import useAllShippings from "./useAllShippings";
import styles from "./AllShippings.module.scss";

const AllShippings = () => {
  const {
    isLoadingShippings,
    shippings,
    metaDetails,
    isShippingForm,
    search,
    setIsShippingForm,
    gotoPage,
    handleFetchShippings,
    searchShipping,
  } = useAllShippings();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Shippings"
      description={"View and Manage all shipping"}
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div className={globalStyles.pageHeaders}>
            <p>{metaDetails?.total} Shipping zones/groups</p>
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() => setIsShippingForm(true)}
            >
              + Add Shipping Zone
            </primaryComponents.Button>
          </div>
          <div className={styles.content}>
            <div className={`${globalStyles.tableHeader}`}>
              <primaryComponents.SearchField
                value={search.get("query") || ""}
                placeholder={"Search for a storefront Shipping"}
                triggerOnInput={true}
                showsearchBtn={true}
                trigger={(value) => searchShipping(value)}
                classNames={`${globalStyles.searchField}`}
              />
            </div>

            <secondaryComponents.LoaderHelper isLoading={isLoadingShippings}>
              <secondaryComponents.AllShippingsList
                shippings={shippings}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                handleFetchShippings={handleFetchShippings}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          <secondaryComponents.Modal
            isActive={isShippingForm}
            closeModal={() => setIsShippingForm(false)}
            width={850}
          >
            <secondaryComponents.CreateShippingForm
              refetch={handleFetchShippings}
              closeWidget={() => setIsShippingForm(false)}
              shippingInfo={shippings}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllShippings;
