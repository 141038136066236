import React, { useState } from "react";
import CheckItem from "../CheckItem";
import SubscriptionFeatureTable from "../SubscriptionFeatureTable";
import TableColumn from "../TableColumn";
import TableRow from "../TableRow";
import secondaryComponents from "../..";
import { useSubscriptionFeatures } from "../../../../hooks/useSubscriptionFeatures";

const PlanInventory = ({ subscriptionPeriod, subscription }) => {
  const [isActive, setIsActive] = useState(["inventory Plan"]);
  const tierPriceProperties = subscription?.pricings?.find(
    (item) => item?.validity === subscriptionPeriod,
  );
  const { subscriptionFeatures } = useSubscriptionFeatures({
    tierCurrency: tierPriceProperties?.currency,
  });

  return (
    <>
      {subscriptionFeatures?.map((objectItem) => {
        return (
          <React.Fragment key={Date.now() + Math.random()}>
            <secondaryComponents.CountryItemPermission
              dontCheckCountry={
                objectItem?.dontCheckCountry !== undefined
                  ? objectItem?.dontCheckCountry
                  : true
              }
            >
              <SubscriptionFeatureTable
                title={objectItem?.name}
                toggleView={() => {
                  const newArray = [...isActive];
                  if (newArray.includes(objectItem?.name)) {
                    const indexOfitem = newArray.indexOf(objectItem?.[0]);
                    newArray.splice(indexOfitem, 1);
                  } else {
                    newArray.push(objectItem?.name);
                  }
                  setIsActive(newArray);
                }}
                isActive={isActive?.includes(objectItem?.name)}
              >
                {objectItem?.[objectItem?.name]?.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableColumn>
                        <p>{item?.name}</p>
                      </TableColumn>
                      {Object.entries(item?.tiers).map((tierItem) => {
                        return (
                          <React.Fragment key={Date.now() + Math.random(10)}>
                            <TableColumn>
                              {tierItem?.[1]?.checked ? (
                                <CheckItem>
                                  {" "}
                                  <p>{tierItem?.[1]?.value}</p>
                                </CheckItem>
                              ) : (
                                <p>{tierItem?.[1]?.value}</p>
                              )}
                            </TableColumn>
                          </React.Fragment>
                        );
                      })}
                    </TableRow>
                  </React.Fragment>
                ))}
              </SubscriptionFeatureTable>
            </secondaryComponents.CountryItemPermission>
          </React.Fragment>
        );
      })}
    </>
  );
};
export default PlanInventory;
