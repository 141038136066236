import { useState, useContext } from "react";
import { RequestLoader, ToastContext } from "../../../hooks/context";
import { createSupplier, updateSupplier } from "../../../services/suppliers";
import { useMutation } from "react-query";
import { getRandomIntInclusive } from "../../../helpers";
import { useSelector } from "react-redux";

const useSupplierForm = ({
  supplierInfo,
  handleFetchSuppliers,
  isEditMode,
  closeWidget,
}) => {
  const { business_phone_code } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const [supplierDetails, setSupplierDetails] = useState({
    name: supplierInfo?.name || "",
    phone_number: supplierInfo?.phone_number || "",
    phone_code: supplierInfo?.phone_code || business_phone_code || "",
    contact_name: supplierInfo?.contact_name || "",
    address: supplierInfo?.address || "",
  });
  const { setRequestLoaderProgress } = useContext(RequestLoader);
  const triggerToast = useContext(ToastContext);

  // create a new supplier
  const { mutate: addSupplier, isLoading: isAddingSupplier } = useMutation(
    (data) => createSupplier(data),
  );
  const handleAddSupplier = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...supplierDetails };
    addSupplier(data, {
      onSuccess: () => {
        handleFetchSuppliers();
        triggerToast("Supplier added successfully", "success");
        closeWidget();
      },

      onSettled: () => setRequestLoaderProgress(100),
    });
  };

  // update supplier properties
  const { mutate: updateSupplierDetails, isLoading: isEditingSupplier } =
    useMutation((data) => updateSupplier(data));
  const handleEditSupplier = () => {
    setRequestLoaderProgress(getRandomIntInclusive(10, 50));
    const data = { ...supplierDetails };
    updateSupplierDetails(
      { data, id: supplierInfo?.id },
      {
        onSuccess: () => {
          handleFetchSuppliers();
          triggerToast("Supplier updated successfully", "success");
          closeWidget();
        },

        onSettled: () => setRequestLoaderProgress(100),
      },
    );
  };

  const submitSupplierInfo = () => {
    if (isEditMode) handleEditSupplier();
    else handleAddSupplier();
  };

  return {
    isAddingSupplier,
    isEditingSupplier,
    supplierDetails,
    setSupplierDetails,
    submitSupplierInfo,
  };
};

export default useSupplierForm;
