import secondaryComponents from "../../../../components/secondaryComponents";
import primaryComponents from "../../../../components/primaryComponents";
import { motion } from "framer-motion";
import { useInventoryTableLogic } from "./useInventoryTableLogic";
import globalStyles from "../../../../assets/styles/base/globalPage.module.scss";
import styles from "./Tables.module.scss";
import Icon from "../../../../assets/icons";

const InventoryTables = () => {
  const {
    isAddNewTable,
    isFetchingOrderTables,
    orderTables,
    metaDetails,
    gotoPage,
    handleFetchOrderTables,
    setIsAddNewTable,
  } = useInventoryTableLogic();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory Tables"
      description="View of all Tables"
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`${styles.allTables} ${globalStyles.pageColumnGaps}`}
        >
          <div>
            <h4 className="mb-0">Tables</h4>
            <p>
              Create, edit, and view your restaurant tables on the floor plan
              here.
            </p>
          </div>
          <div className={styles.allTables__header}>
            <p className="font-weight-semibold">
              {metaDetails?.total} Table(s)
            </p>
            <secondaryComponents.NavigateWithPermission
              permissionParentKey={"Inventory Management"}
              permissionChildKey="can_view_edit_and_create_tables"
            >
              <primaryComponents.Button
                onClick={() => setIsAddNewTable(true)}
                classNames={"btn btn--primary"}
              >
                <img className="mr-1" src={Icon.add} alt="add-stores" />
                Add new table
              </primaryComponents.Button>
            </secondaryComponents.NavigateWithPermission>
          </div>
          <div className={styles.allTables__content}>
            <secondaryComponents.LoaderHelper
              isLoading={isFetchingOrderTables}
              classNames={"mt-5"}
            >
              <secondaryComponents.RestaurantTable
                orderTables={orderTables}
                metaDetails={metaDetails}
                gotoPage={gotoPage}
                handleFetchOrderTables={handleFetchOrderTables}
              />
            </secondaryComponents.LoaderHelper>
          </div>

          <secondaryComponents.Modal
            isActive={isAddNewTable}
            closeModal={() => setIsAddNewTable(false)}
            width={600}
          >
            <secondaryComponents.AddOrderTableForm
              closeModal={() => setIsAddNewTable(false)}
              handleFetchOrderTables={handleFetchOrderTables}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};

export default InventoryTables;
