import globalStyles from "../../assets/styles/base/globalPage.module.scss";
import { Outlet } from "react-router-dom";

const TransfersLayout = () => {
  return (
    <div className={globalStyles?.pagePaddings}>
      <Outlet />
    </div>
  );
};
export default TransfersLayout;
