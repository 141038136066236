import { useState } from "react";
import { useSearchParams } from "react-router-dom";

const useSalesModal = () => {
  const [search, setSearch] = useSearchParams();
  const orderId = search.get("orderId");

  const [openSalesTransactionWidget, setOpenSalesTransactionWidget] =
    useState(false);
  const [isTnxRecipt, setIsTnxRecipt] = useState(true);
  const [isTerminal, setIsTerminal] = useState(false);
  const [isSendRecipt, setIsSendRecipt] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [selectedOrder, setSelectedOrder] = useState("");

  const closeWidget = () => {
    if (orderId) {
      search.delete("orderId");
      setSearch(search, { replace: true });
    }
    setIsTnxRecipt(true);
    setOpenSalesTransactionWidget(false);
  };

  const gotoTransactionDetails = () => {
    setIsTnxRecipt(true);
    setIsTerminal(false);
    setIsSendRecipt(false);
  };

  const gotoReceiptTerminals = () => {
    setIsTerminal(true);
    setIsTnxRecipt(false);
  };

  const openSendReceipt = () => {
    setIsSendRecipt(true);
    setIsTnxRecipt(false);
  };

  return {
    isTnxRecipt,
    isSendRecipt,
    isTerminal,
    openSalesTransactionWidget,
    selectedOrder,
    orderDetails,
    setSelectedOrder,
    setOrderDetails,
    gotoTransactionDetails,
    setOpenSalesTransactionWidget,
    closeWidget,
    openSendReceipt,
    gotoReceiptTerminals,
  };
};

export default useSalesModal;
