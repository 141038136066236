import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    pricingName: yup.string().required("Pricing name is required"),
  })
  .required("All fields are required");

const Validation = ({ pricingName }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: { pricingName },
  });
};

export default Validation;
