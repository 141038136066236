import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { uploadProductImage } from "../../../../services/inventory";

export const useMultipleImageUpload = () => {
  const params = useParams();

  // Image upload function
  const { mutate: uploadImage, isLoading: isUploadingImage } = useMutation(
    (data) => uploadProductImage(data),
  );

  const handleUploadMultipleMediaFiles = ({
    item_type,
    productId,
    media_files,
  }) => {
    return new Promise((resolve, reject) => {
      media_files
        ?.filter((item) => item?.file)
        ?.forEach((media) => {
          const formData = new FormData();
          formData.append("file", media?.file);
          formData.append("item_id", productId ? productId : `${params?.id}`);
          formData.append("item_type", item_type);

          uploadImage(formData, {
            onSuccess: () => resolve(),
            onError: () => reject(),
          });
        });
    });
  };

  return {
    handleUploadMultipleMediaFiles,
    isUploadingImage,
  };
};
