export const UpDownArrow = () => {
  return (
    <svg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30208 1V11.4375M3.30208 1L1 3.875M3.30208 1L5.60417 3.875M3.30208 11.4375L1 8.51042M3.30208 11.4375L5.60417 8.51042"
        stroke="#7647EE"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 3.25H20.5M8 6.375H17.375M8 9.5H14.875"
        stroke="#7647EE"
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
