import { useContext } from "react";
import { useMutation } from "react-query";
import { ToastContext } from "../../../hooks/context";
import { deleteSupplier } from "../../../services/suppliers";

const useSupplierDeletion = ({
  supplierDetails,
  handleFetchSuppliers,
  closeWidget,
}) => {
  const triggerToast = useContext(ToastContext);
  const { mutate: removeSupplier, isLoading: isDeletingSupplier } = useMutation(
    (data) => deleteSupplier(data),
  );

  const handleDeleteSupplier = () => {
    removeSupplier(
      {
        id: supplierDetails?.id,
      },
      {
        onSuccess: () => {
          handleFetchSuppliers();
          triggerToast("Supplier Deleted successfully", "success");
          closeWidget();
        },
      },
    );
  };

  return {
    isDeletingSupplier,
    handleDeleteSupplier,
  };
};
export default useSupplierDeletion;
