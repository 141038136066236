export const ImagePlaceHolder = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9568_76001)">
        <rect x="1" y="1" width="56" height="56" rx="4" fill="white" />
        <rect x="1" y="1" width="56" height="56" rx="4" fill="#DADADA" />
        <g filter="url(#filter0_d_9568_76001)">
          <path
            d="M46.3278 49.6288L11.5895 49.5906C11.5895 49.5906 11.5894 49.5906 11.5893 49.5906C9.72485 49.5834 8.21652 48.0695 8.20649 46.2198C8.20649 46.2196 8.20649 46.2194 8.20649 46.2191L8.21238 13.0284C8.21238 13.0283 8.21238 13.0282 8.21238 13.0281C8.21756 11.1659 9.73132 9.66135 11.5829 9.65351C11.5831 9.65351 11.5833 9.65351 11.5835 9.65351L46.3217 9.69177C48.1863 9.69886 49.6947 11.2126 49.7048 13.0623C49.7048 13.0626 49.7048 13.0629 49.7048 13.0632L49.6989 46.2539C49.6939 48.1163 48.1801 49.6209 46.3284 49.6288C46.3282 49.6288 46.328 49.6288 46.3278 49.6288Z"
            fill="white"
            stroke="#C5C5C5"
            strokeWidth="0.509848"
          />
          <path
            d="M24.1776 25.3843C25.0646 24.6351 25.1749 23.3088 24.4238 22.422C23.6727 21.5351 22.3447 21.4235 21.4577 22.1727C20.5707 22.9219 20.4604 24.2482 21.2115 25.1351C21.9626 26.0219 23.2906 26.1335 24.1776 25.3843Z"
            fill="#7647EE"
          />
          <path
            d="M29.6911 34.4886L18.6709 34.4758L21.4255 31.3709L22.8028 29.8185L24.1802 28.266L25.5579 29.8217L26.9356 31.3773L29.6911 34.4886Z"
            fill="#FACE8A"
          />
          <path
            d="M24.7737 28.9287L24.4845 28.7599L24.319 28.9099L23.9351 28.7919L23.5926 28.9273L23.8879 28.5946L24.0355 28.4282L24.1831 28.2618L24.3307 28.4285L24.4784 28.5953L24.7737 28.9287Z"
            fill="white"
          />
          <path
            d="M38.9712 34.5035L22.9115 34.4848L26.9258 29.9601L28.933 27.6978L30.9401 25.4354L32.9479 27.7024L34.9557 29.9695L38.9712 34.5035Z"
            fill="#A3DFBC"
          />
          <path
            d="M31.8034 26.4095L31.382 26.1635L31.1408 26.3821L30.5813 26.2102L30.0822 26.4075L30.5124 25.9226L30.7276 25.6801L30.9427 25.4377L31.1579 25.6806L31.373 25.9236L31.8034 26.4095Z"
            fill="white"
          />
        </g>
      </g>
      <rect
        x="0.666667"
        y="0.666667"
        width="56.6667"
        height="56.6667"
        rx="4.33333"
        stroke="#979797"
        strokeWidth="0.666667"
        strokeDasharray="4 4"
      />
      <defs>
        <filter
          id="filter0_d_9568_76001"
          x="2.33219"
          y="3.66667"
          width="53.3334"
          height="51.957"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.66667" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9568_76001"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9568_76001"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_9568_76001">
          <rect x="1" y="1" width="56" height="56" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
