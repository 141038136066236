import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  editRole,
  getPermissionCategories,
  getSingleRole,
} from "../../../../../../services/settings";
import { ToastContext } from "../../../../../../hooks/context";

const useEditRole = ({ showEditRoleModal, setShowEditRoleModal }) => {
  const triggerToast = useContext(ToastContext);
  const queryClient = useQueryClient();

  const [showPermission, setShowPermission] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const [selectAllAccounting, setSelectAllAccounting] = useState(false);
  const [selectAllCustomers, setSelectAllCustomers] = useState(false);
  const [selectAllExpense, setSelectAllExpense] = useState(false);
  const [selectAllInventoryManagement, setSelectAllInventoryManagement] =
    useState(false);
  const [selectAllLoyalty, setSelectAllLoyalty] = useState(false);
  const [selectAllPayments, setSelectAllPayments] = useState(false);
  const [selectAllSettings, setSelectAllSettings] = useState(false);
  const [roleName, setRoleName] = useState("");

  const { isLoading: isLoadingSingleRole, data: dataSingleRole } = useQuery(
    ["single-role", showEditRoleModal],
    () => getSingleRole(showEditRoleModal).then((res) => res.data?.data),
  );

  const {
    isLoading: isLoadingPermissionCategory,
    data: dataPermissionsCategory,
  } = useQuery(["permissions-categories"], () =>
    getPermissionCategories().then((res) => res.data?.data),
  );

  const { isLoading: isLoadingMutate, mutate } = useMutation({
    mutationFn: (data) => editRole(showEditRoleModal, data),
    onSuccess: (data) => {
      queryClient.refetchQueries("roles-custom");
      triggerToast(data.data.detail, "success");
      setShowEditRoleModal(false);
    },
    onError: (error) => {
      triggerToast(`${error.response.data.message}`, "warning");
    },
  });

  const hanldeEditRole = () => {
    if (selectedPermissions.length === 0) {
      triggerToast("Enter at least one permission", "warning");
      return;
    }

    mutate({
      label: roleName,
      permission_ids: selectedPermissions.map((item) => String(item)),
    });
  };

  useEffect(() => {
    if (dataSingleRole) {
      const permissions = dataSingleRole.role_permissions.map(
        (item) => item.permission.id,
      );
      const permission_cat = dataSingleRole.role_permissions.map(
        (item) => item.permission.permission_category.label,
      );

      setRoleName(dataSingleRole.label);
      setSelectedPermissions((prev) => [...new Set([...prev, ...permissions])]);
      setShowPermission(permission_cat);
    }
  }, [dataSingleRole]);

  return {
    isLoadingSingleRole,
    dataSingleRole,
    isLoadingPermissionCategory,
    isLoadingMutate,
    dataPermissionsCategory,
    roleName,
    showPermission,
    selectedPermissions,
    selectAllAccounting,
    selectAllCustomers,
    selectAllExpense,
    selectAllInventoryManagement,
    selectAllLoyalty,
    selectAllPayments,
    selectAllSettings,
    setRoleName,
    hanldeEditRole,
    setShowPermission,
    setSelectedPermissions,
    setSelectAllAccounting,
    setSelectAllCustomers,
    setSelectAllExpense,
    setSelectAllInventoryManagement,
    setSelectAllLoyalty,
    setSelectAllPayments,
    setSelectAllSettings,
  };
};

export default useEditRole;
