import { useSelector } from "react-redux";
import "./PhoneNumberInputField.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import countriesList from "../../../localdata/worldCountries.json";
// import Icon from "../../../assets/icons";
import Select from "react-select";
import { useEffect, useState } from "react";

const PhoneNumberInputField = ({
  placeholder = "",
  name = "",
  isRequired,
  label = null,
  isDisabled = false,
  errorMessage = undefined,
  onChange = () => {},
  onChangePhoneCode = () => {},
  register = () => {},
  onKeyUp = () => {},
  classNames = "",
  countryCode = undefined,
  flag = undefined, //"🇳🇬"
  phoneCodeEditable = false,
}) => {
  const { business_phone_code, country } = useSelector(
    (state) => state.profileDetailsReducer.businessDetails,
  );

  const mainFlag = countriesList
    .filter((item) => item.countryCode !== "undefined")
    .find((item) => item.countryCode === countryCode)?.countryFlag;

  const localFlag = countriesList.find(
    (item) => item.name === country,
  )?.countryFlag;

  const countriesCode = countriesList.map((item) => ({
    value: item.countryCode,
    label: `${item.countryFlag} +${item.countryCode}`,
  }));

  const [selectedOption, setSelectedOption] = useState({
    value: countryCode || business_phone_code,
    label: `${mainFlag || localFlag} +${countryCode || business_phone_code}`,
  });

  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
      backgroundColor: "transparent",
      paddingTop: "5px",
      marginLeft: "-20px",
      width: "120px !important",
      height: "100%",
    }),

    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#7647ee" : null,
        color: isFocused ? "#fff" : null,
      };
    },
  };

  useEffect(() => {
    setSelectedOption(
      countriesCode?.find(
        (item) =>
          item?.label ===
          `${flag || localFlag} +${countryCode || business_phone_code}`,
      ),
    );
  }, [countryCode, business_phone_code, flag, localFlag]);

  return (
    <div className="phone-container">
      {label !== null && (
        <label htmlFor={name} data-testid="label">
          {isRequired && <span className="text-danger">*</span>}
          <span>{label}</span>
        </label>
      )}
      <div
        className={`phone-container__input-section ${
          errorMessage !== undefined && "error-border"
        } ${classNames}`}
      >
        {phoneCodeEditable && (
          <Select
            defaultValue={selectedOption}
            onChange={(val) => {
              setSelectedOption(val);
              onChangePhoneCode(val.value);
            }}
            options={countriesCode}
            styles={customStyles}
            placeholder={"🇳🇬 +234"}
            className="select-code"
            value={selectedOption}
          />
        )}
        <div style={{ width: "100%" }} className="d-flex pt-1">
          {!phoneCodeEditable && (
            <span className="d-flex align-items-center mr-1">
              <span>{flag || localFlag}</span>
              <span>+{countryCode || business_phone_code}</span>
            </span>
          )}
          <input
            type="tel"
            inputMode="numeric"
            name={name}
            placeholder={placeholder}
            id={name}
            disabled={isDisabled}
            {...register(name, { required: isRequired })}
            onChange={(event) => {
              const value = event.target.value;
              // remove 0 at the start of the phone number to correctly format it
              if (value && value.startsWith(0) && value.length > 10)
                event.target.value = value.slice(1);
              onChange(event);
            }}
            onKeyUp={onKeyUp}
            maxLength={10}
          />
        </div>
      </div>
      <p
        className={`error-message text-danger ${
          errorMessage !== undefined ? "show" : ""
        }`}
        data-testid="error-message"
      >
        <FontAwesomeIcon
          icon={["fas", "exclamation-circle"]}
          className="mr-2"
          style={{ fontSize: "13px" }}
        />
        {errorMessage !== undefined && <span>{errorMessage.message}</span>}
      </p>
    </div>
  );
};

export default PhoneNumberInputField;
