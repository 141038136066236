import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  name: yup.string().required("table name is required"),
  store: yup.string().required("please select a store"),
  seat_capacity: yup.string().required("please table capacity"),
});

export const useAddOrderTableValidation = (formData) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      name: formData.name,
      store: formData.store,
      seat_capacity: formData.seat_capacity,
    },
  });
};
