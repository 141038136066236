import React from "react";
import primaryComponents from "../../../../../../../components/primaryComponents";
import styles from "../../CreateFoodMenu.module.scss";
import globalStyles from "../../../../../../../assets/styles/base/globalPage.module.scss";
import { useGetPricingTypes } from "../../../../../../../hooks/api/queries";
import { useParams } from "react-router-dom";

const MenuDetails = ({
  menuDetails,
  setMenuDetails,
  // menuProperties,
  storeList,
  selectedMenuItems,
  searchParams,
  filterProductByStore,
  handleSearchMenuItems,
  selectedPricingType,
  setSelectedPricingType,
  control,
  errors,
}) => {
  const { data: pricingTypes } = useGetPricingTypes();
  const { menuId } = useParams();

  return (
    <>
      <div className={styles.contentHeader}>
        <primaryComponents.InputFieldV2
          control={control}
          name="menuName"
          value={menuDetails?.menuName}
          classNames="white"
          label={"Storefront Name"}
          placeholder={"Enter storefront name"}
          onChange={({ target: { value } }) => {
            setMenuDetails((prev) => ({ ...prev, menuName: value }));
          }}
          isRequired
          errorMessage={errors.menuName}
        />

        <primaryComponents.SelectFieldV2
          control={control}
          name="store"
          label={"Store"}
          placeholder={"Select store(s) to pick items from"}
          options={storeList}
          value={
            menuDetails?.store !== ""
              ? // eslint-disable-next-line eqeqeq
                storeList?.find((item) => item.id == menuDetails?.store)?.name
              : "Select store"
          }
          isDisabled={menuId ? true : false}
          idKey={"id"}
          nameKey={"name"}
          isRequired
          onChange={(value) => {
            setMenuDetails((prev) => ({ ...prev, store: value }));
            filterProductByStore(value);
          }}
          errorMessage={errors.store}
        />

        <primaryComponents.SelectFieldV2
          control={control}
          name="pricingType"
          label={"Select Multiple Products' pricing type"}
          placeholder={"Select pricing type"}
          options={pricingTypes}
          value={selectedPricingType || "Select pricing type"}
          idKey={"id"}
          nameKey={"label"}
          onChange={(value) => {
            // console.log(pricingTypes.find((item) => item.id === value));
            setSelectedPricingType(
              pricingTypes.find((item) => item.id === value),
            );
          }}
        />
      </div>

      {/* <hr /> */}
      <div className={styles.clickSelectContainer}>
        <h5 className="mb-1">Select items to display on your storefront</h5>
        <p>
          Click the check box next to your product name to select an item or{" "}
          <span
            onClick={() =>
              window.open("/app/inventory/products/add/single", "_blank")
            }
          >
            Create a new product
          </span>
        </p>
      </div>

      <div className={styles.searchContainer}>
        <primaryComponents.SearchField
          placeholder="Search by barcode scanning or product name, ID & SKU"
          value={searchParams.get("query") ? searchParams.get("query") : ""}
          triggerOnInput={true}
          isScan={true}
          trigger={(value) => handleSearchMenuItems(value)}
          classNames={`${globalStyles.searchField}`}
        />
        <h4>{Object?.keys(selectedMenuItems)?.length} items selected</h4>
      </div>
    </>
  );
};

export default MenuDetails;
