import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object({
  menuName: yup.string().required("Menu name is required"),
  store: yup.string().required("Please select a store to continue"),
});

export const Validation = ({ menuDetails }) => {
  return useForm({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: "onBlur",
    defaultValues: {
      menuName: menuDetails?.menuName,
      store: menuDetails?.store,
    },
  });
};
