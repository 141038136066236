import styles from "./ToolTipV2.module.scss";
const ToolTipV2 = ({ children, tipText = "", position = "top" }) => {
  return (
    <>
      <span className={styles.tooltipCover}>
        <span className="d-block" style={{ position: "relative" }}>
          {tipText !== "" && (
            <span
              className={`${
                position === "right"
                  ? styles.tooltipTextRight
                  : position === "left"
                    ? styles.tooltipTextLeft
                    : position === "bottom"
                      ? styles.tooltipTextBottom
                      : styles.tooltipTextTop
              }`}
              dangerouslySetInnerHTML={{ __html: tipText }}
            />
          )}
        </span>
        {children}
      </span>
    </>
  );
};

export default ToolTipV2;
