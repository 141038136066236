import secondaryComponents from "../../../../../components/secondaryComponents";
import { motion } from "framer-motion";
import globalStyles from "../../../../../assets/styles/base/globalPage.module.scss";
import primaryComponents from "../../../../../components/primaryComponents";
import useAllRiders from "./useAllRiders";

const AllRiders = () => {
  const {
    isLoadingRiders,
    riders,
    metaDetails,
    isRiderForm,
    setIsRiderForm,
    gotoPage,
    handleFetchRiders,
  } = useAllRiders();

  return (
    <secondaryComponents.MetaSetter
      title="Lumi Merchant | Inventory - Dispatch Riders"
      description={"View and Manage all dispatch"}
    >
      <secondaryComponents.RequestLoaderTrigger>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.3 } }}
          className={`${globalStyles.pageColumnGaps}`}
        >
          <div className={globalStyles.pageHeaders}>
            <p>{metaDetails?.total} Dispatch Riders</p>
            <primaryComponents.Button
              classNames={"btn btn--primary"}
              onClick={() => setIsRiderForm(true)}
            >
              + Add Dispatch Rider
            </primaryComponents.Button>
          </div>

          <secondaryComponents.LoaderHelper isLoading={isLoadingRiders}>
            <secondaryComponents.RidersTable
              riders={riders}
              metaDetails={metaDetails}
              gotoPage={gotoPage}
              handleFetchRiders={handleFetchRiders}
            />
          </secondaryComponents.LoaderHelper>

          <secondaryComponents.Modal
            isActive={isRiderForm}
            closeModal={() => setIsRiderForm(false)}
            width={550}
          >
            <secondaryComponents.RidersForm
              handleFetchRiders={handleFetchRiders}
              closeModal={() => setIsRiderForm(false)}
            />
          </secondaryComponents.Modal>
        </motion.div>
      </secondaryComponents.RequestLoaderTrigger>
    </secondaryComponents.MetaSetter>
  );
};
export default AllRiders;
