import "./LoginLayout.scss";
import Logo from "../../assets/logo";
import Images from "../../assets/images";
import { Link } from "react-router-dom";

const LoginLayout = ({ children, linkedPage }) => {
  return (
    <div className="login-layout">
      <div className="login-layout__nav">
        <Link className="login-layout__nav__logo" to="/">
          <img src={Logo.secondary} alt="enlumi merchant" />
        </Link>
        <div className="signup-link">{linkedPage}</div>
      </div>
      <div className="login-layout__content">
        <div className="login-layout__content__container">{children}</div>
        <div className="login-layout__content__image">
          <img src={Images.enlumi_arrows} alt="" />
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
