import { useSelector } from "react-redux";
import "./SubAccountsList.scss";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const SubAccountsList = ({ classNames = "" }) => {
  const [search, setSearch] = useSearchParams();
  const { business_list } = useSelector(
    (state) => state.profileDetailsReducer.profileDetails,
  );

  const filterBaseOnAccount = (businessId) => {
    if (businessId === "true") {
      search.set("all_business", "true");
      search.delete("business_id");
    } else {
      search.set("business_id", businessId);
      search.delete("all_business");
    }
    setSearch(search, { replace: true });
  };

  useEffect(() => {
    if (!search.get("all_business") && !search.get("business_id")) {
      search.set("all_business", true);
      setSearch(search, { replace: true });
    }
  }, []);

  return (
    <>
      {business_list?.length > 1 && (
        <div className={`all-subaccounts-list ${classNames}`}>
          <div
            className={`account ${search?.get("all_business") ? "active" : ""}`}
            onClick={() => filterBaseOnAccount("true")}
          >
            <span>All accounts</span>
          </div>
          {business_list?.map((item) => (
            <div
              key={item?.id}
              className={`account ${
                search?.get("business_id") === `${item?.id}` ? "active" : ""
              }`}
              onClick={() => filterBaseOnAccount(item?.id)}
            >
              <span>{item?.tag}</span>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default SubAccountsList;
