import { useContext, useEffect } from "react";
import { MetaHandler } from "../../../hooks/context";

const MetaSetterLogic = ({ title, description }) => {
  const { setMetaDetails } = useContext(MetaHandler);

  useEffect(() => {
    setMetaDetails({
      title: title !== undefined ? title : "Lumi Merchant",
      description: description !== undefined ? description : "Lumi Merchant",
    });
  }, []);

  return null;
};

export default MetaSetterLogic;
