import { Controller } from "react-hook-form";
import PhoneNumberInputField from "../PhoneNumberInputField/PhoneNumberInputField";

const PhoneNumberInputFieldV2 = ({
  onChange = () => {},
  name = "",
  control,
  phoneCodeEditable = false,
  onChangePhoneCode = () => {},
  countryCode = undefined,
  defaultValue = "",
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange: onControllerChange } }) => (
        <PhoneNumberInputField
          name={name}
          onChange={(e) => {
            onControllerChange(e?.target?.value);
            onChange(e);
          }}
          {...props}
          phoneCodeEditable={phoneCodeEditable}
          onChangePhoneCode={onChangePhoneCode}
          countryCode={countryCode}
        />
      )}
    />
  );
};

export default PhoneNumberInputFieldV2;
