const CampaignStepperLogic = () => {
  return {
    steps: [
      {
        id: 0,
        title: "Campaign",
        label: "Campaign",
      },
      {
        id: 1,
        title: "Brand",
        label: "Brand",
      },
      {
        id: 2,
        title: "Offer",
        label: "Offer",
      },
    ],
  };
};

export default CampaignStepperLogic;
