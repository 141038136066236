import { useMutation } from "react-query";
import { markAllNotificationAsRead } from "../../../services/notifications";
import { useEffect } from "react";
import { useGetOrderNofication } from "../../../hooks/api/queries/useDashboard";

export const useNotifications = () => {
  const {
    isLoading,
    data: dataNotifications,
    refetch,
  } = useGetOrderNofication();

  const { mutate: mutateMarkAllAsRead } = useMutation({
    mutationFn: () => markAllNotificationAsRead(),
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    mutateMarkAllAsRead();
  }, []);

  return {
    isLoading,
    dataNotifications,
    mutateMarkAllAsRead,
  };
};
