import { truncateString } from "../../../helpers";
import styles from "./ConcatenateWithTooltip.module.scss";

const ConcatenateWithTooltip = ({
  children,
  value,
  noOfCharacters,
  useChild = false,
}) => {
  return (
    <span className={styles.concatenateWithTooltip}>
      {useChild ? (
        children
      ) : (
        <span>{truncateString(value, noOfCharacters)}</span>
      )}
      {value?.length > noOfCharacters ? (
        <span className={styles.toolTipIcon}>
          <span>...</span>
        </span>
      ) : null}

      <span className={styles.toolTipContent}>{value}</span>
    </span>
  );
};
export default ConcatenateWithTooltip;
