import "./AccountTypeButtonGroup.scss";
import Icon from "../../../assets/icons";

const AccountTypeButtonGroup = ({
  accountType = "business",
  onChange = () => {},
}) => {
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div className="account-type-button-group mt-4">
      {/* <label
                htmlFor="personal"
                className={
                    "account-type-button-group__button" +
                    (accountType === "personal" ? " active" : "")
                }
            >
                <input
                    onChange={handleChange}
                    type="radio"
                    name="accountType"
                    id="personal"
                    value="personal"
                    checked={accountType === "personal"}
                    disabled={true}
                />
                <div className="account-type-button-group__button__icon">
                    <img src={Icon.user} alt="personal account" />
                </div>
                <h4 className="mt-3 font-weight-semibold">Personal</h4>
            </label> */}
      <label
        htmlFor="business"
        className={
          "account-type-button-group__button" +
          (accountType === "business" ? " active" : "")
        }
      >
        <input
          onChange={handleChange}
          type="radio"
          name="accountType"
          id="business"
          value="business"
          checked={accountType === "business"}
        />
        <div className="account-type-button-group__button__icon">
          <img src={Icon.shop} alt="business account" />
        </div>
        <h4 className="mt-3 font-weight-semibold">Business</h4>
      </label>
    </div>
  );
};

export default AccountTypeButtonGroup;
