import { Controller } from "react-hook-form";
import TimeInput from "../TimeInput/TimeInput";

const TimeInputV2 = ({ onChange = () => {}, name = "", control, ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onControllerChange } }) => (
        <TimeInput
          name={name}
          onChange={(e) => {
            onControllerChange(e?.target?.value);
            onChange(e);
          }}
          {...props}
        />
      )}
    />
  );
};

export default TimeInputV2;
