import { useState } from "react";

const SubAccountCardLogic = () => {
  const [openEditSubAccountWidget, setOpenEditSubAccountWidget] =
    useState(false);

  return {
    openEditSubAccountWidget,
    setOpenEditSubAccountWidget,
  };
};

export default SubAccountCardLogic;
