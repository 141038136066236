import secondaryComponents from "../../../../../components/secondaryComponents";
import useEditProductBatch from "./useEditProductBatch";
const EditProductsBatch = () => {
  const { batchInfo, isLoading } = useEditProductBatch();
  return (
    <secondaryComponents.LoaderHelper isLoading={isLoading} classNames="mt-4">
      <secondaryComponents.ProductBatchForm batchInfo={batchInfo} />
    </secondaryComponents.LoaderHelper>
  );
};

export default EditProductsBatch;
