import "./PerformingProductsTable.scss";
import { usePerformingProductsLogic } from "./PerformingProductsLogic";
import Images from "../../../assets/images";
import secondaryComponents from "..";

const PerformingProductsTable = ({ products }) => {
  const { instance } = usePerformingProductsLogic({
    products,
  });
  const { getTableBodyProps, getTableProps, headerGroups, rows, prepareRow } =
    instance;

  if (products?.length <= 0) {
    return (
      <div className="text-center mt-5 mb-5">
        <img src={Images.productsEmptyState} width="220px" alt="" />
        <h4 className="font-weight-semibold text-center mt-4">
          You have not made any sale
        </h4>
        <p className="text-center">When you do, they would appear here.</p>
      </div>
    );
  }

  return (
    <div className="performingProductsTable">
      <secondaryComponents.TableHelper
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        minHeight={"auto"}
      >
        <div className="text-center mt-5 ">
          <img src={Images.productsEmptyState} width="220px" alt="" />
          <h4>You have not made any sale</h4>
          <p className="text-center">When you do, they would appear here.</p>
        </div>
      </secondaryComponents.TableHelper>
    </div>
  );
};

export default PerformingProductsTable;
