export const StoreWalletIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#F3F1FC" />
      <path
        d="M17.3333 10.4762V8.19046C17.3333 7.34889 16.7364 6.66666 16 6.66666H6.66665C5.93027 6.66666 5.33331 7.34889 5.33331 8.19046V15.8095C5.33331 16.6511 5.93027 17.3333 6.66665 17.3333H16C16.7364 17.3333 17.3333 16.6511 17.3333 15.8095V13.5238V10.4762ZM18.6666 10.4762H14.6666C13.9302 10.4762 13.3333 11.1584 13.3333 12C13.3333 12.8416 13.9302 13.5238 14.6666 13.5238H18.6666V10.4762Z"
        stroke="#7647EE"
        strokeWidth="1.57"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
